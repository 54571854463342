import React from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  Box,
  TextField,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import { useSelector, useDispatch } from 'react-redux';
import * as utility from '../../redux/Utility/actions';
import * as client from '../../redux/ClientVendor/actions';
import * as reportAction from '../../redux/Report/actions';
import sortBy from 'lodash/sortBy';

// Components
import Card from './components/Card';
import BarChart from './components/BarChart';
import SortArrow from '../SortArrow';
import TableData from './components/TableData';
import { CircleLoadingSecondary } from '../Loading/CircleLoading';
import { formatRupiah } from 'src/utils/formatter';

// Icons
import { DownArrow } from '../../assets/Icons/svg/DownArrow';
import Search from '../../assets/Icons/Search.png';
import Trash from '../../assets/Icons/trash.png';
import Export from '../../assets/Icons/export.png';
import ArrowDown from '../../assets/Icons/downArrow.png';
import { get, isEmpty } from 'lodash';
import moment from 'moment';

let searchTimeOut;

const ReportForm = () => {
  const classes = useStyles();
  const dispacth = useDispatch();

  // Global Variable
  const { access_token } = useSelector((state) => state.auth);
  const { department_data, entity_data } = useSelector(
    (state) => state.utility
  );
  const { data, searchData, searchIsLoading } = useSelector(
    (state) => state.clientVendor
  );
  const {
    report_isLoading,
    data_report,
    data_reportGraph,
    report_exportIsLoading,
  } = useSelector((state) => state.report);

  const [rowData, setRowData] = React.useState([]);
  const [sortingData, setSortingData] = React.useState([]);

  // Create format for table and pagination
  const [page, setPage] = React.useState(1);
  const [pagination, setPagination] = React.useState(1);
  const [fromRow, setFromRow] = React.useState(1);
  const [toRow, setToRow] = React.useState(1);
  const [TotalRow, setTotalRow] = React.useState(1);
  const [selectRow, setSelectRow] = React.useState(0);

  const [isNotFound, setNotFound] = React.useState(false);
  const [isLoaded, setLoaded] = React.useState(false);

  // state value for graph
  const [dataOnTrack, setDataOnTrack] = React.useState(0);
  const [dataOffTrack, setDataOffTrack] = React.useState(0);
  const [dataIDR, setDataIDR] = React.useState(0);
  const [dataUSD, setDataUSD] = React.useState(0);
  const [barOnTrack, setBarOnTrack] = React.useState(null);
  const [barOffTrack, setBarOffTrack] = React.useState(null);

  // state for filter choosing
  const [entityFilter, setEntityFilter] = React.useState(1);
  const [departementFilter, setDepartmentFilter] = React.useState(0);
  const [programFilter, setProgramFilter] = React.useState('PO');
  const [statusFilter, setStatusFilter] = React.useState(0);
  const [yearFilter, setYearFilter] = React.useState(0);
  const [showPeriode, setShowPeriode] = React.useState(false);
  const [isFocusPeriode, setIsFocusPeriode] = React.useState(false);
  const [isOP, setIsOP] = React.useState(false)

  //Table title
  const [firstTableitle, setFirstTableTitle] = React.useState('PO');

  const [periodeFilter, setPeriodeFilter] = React.useState(null);

  // handle for onChange filter
  const handleChangeEntity = (event) => {
    setEntityFilter(event.target.value);
  };
  const handleChangeDepartment = (event) => {
    setDepartmentFilter(event.target.value);
  };
  const handleChangeStatus = (event) => {
    setStatusFilter(event.target.value);
  };
  const handleChangeProgram = (event) => {
    setProgramFilter(event.target.value);
  };
  const handleChangeRow = (event) => {
    setSelectRow(event.target.value);
  };
  const handleChangeYear = (event) => {
    setYearFilter(event.target.value);
  };

  const handleChangePagination = (event, value) => {
    setPagination(value);
  };

  const handleChoosingPeriode = (data) => {
    setPeriodeFilter(data);
    // setShowPeriode(false);
  };

  // configure for get initial date year
  const [tempYearCol, setTempYearCol] = React.useState([]);

  React.useEffect(() => {
    const d = new Date();
    const yearNow = d.getFullYear();
    let yearBox = [];

    for (let i = 1990; i <= yearNow; i++) {
      yearBox.push(i);
    }

    setTempYearCol([...yearBox.reverse()]);
  }, []);

  React.useEffect(() => {
    setYearFilter(moment().format('YYYY'));
  }, [tempYearCol]);

  // for handle search input vendor

  const [deleteHover, setDeleteHover] = React.useState(false);
  const [isVendorFocus, setIsVendorFocus] = React.useState(false);
  const [showVendor, setShowVendor] = React.useState(false);

  const handleChooseVandor = (data, index) => {
    selectVendor(data, index);
    setIsVendorFocus(false);
    setShowVendor(false);
  };

  const [vendor, setVendor] = React.useState(null);
  // const [isVendorFocus, setIsVendorFocus] = React.useState(false);
  // const [showVendor, setShowVendor] = React.useState(false);
  const [vendorData, setVendorData] = React.useState(null);
  const [searchText, setSearchText] = React.useState('');

  const selectVendor = (data, index) => {
    setVendor(data);
    setSearchText('');
  };

  React.useEffect(() => {
    
    clearTimeout(searchTimeOut)

    searchTimeOut = setTimeout(()=> {
      if (searchText !== '') {
        let data = { name: searchText };
        dispacth(client.searchClientVendor(access_token, data));
      } else {
        setVendorData(data?.client?.data);
      }
    }, 700)
  }, [searchText]);

  React.useEffect(() => {
    dispacth(client.getClientVendor(access_token, { limit: 100 }));
  }, []);

  React.useEffect(() => {
    setVendorData(data?.client?.data);
  }, [data]);

  React.useEffect(() => {
    setVendorData(searchData?.client);
  }, [searchData]);

  // end handle serach vendor

  // handle Sorting function

  const sortDocNumAsc = (tempData) => {
    let sortDoc = sortBy(tempData, 'doc_entry');

    setSortingData(sortDoc);
  };

  const sortDocNumDsc = (tempData) => {
    let sortDoc = sortBy(tempData, 'doc_entry').reverse();

    setSortingData(sortDoc);
  };

  const sortDepratmentAsc = (tempData) => {
    let sortItem = sortBy(tempData, 'department');

    setSortingData(sortItem);
  };

  const sortDepartmentDsc = (tempData) => {
    let sortItem = sortBy(tempData, 'department').reverse();

    setSortingData(sortItem);
  };

  const sortActivityAsc = (tempData) => {
    let sortItem = sortBy(tempData, 'act_name');

    setSortingData(sortItem);
  };

  const sortVendorAsc = (tempData) => {
    let sortItem = sortBy(tempData, 'act_name');

    setSortingData(sortItem);
  };

  const sortVendorDsc = (tempData) => {
    let sortItem = sortBy(tempData, 'act_name').reverse();

    setSortingData(sortItem);
  };

  const sortActivityDsc = (tempData) => {
    let sortItem = sortBy(tempData, 'act_name').reverse();

    setSortingData(sortItem);
  };

  const sortStatusAsc = (tempData) => {
    let sortItem = sortBy(tempData, 'status');

    setSortingData(sortItem);
  };

  const sortStatusDsc = (tempData) => {
    let sortItem = sortBy(tempData, 'status').reverse();

    setSortingData(sortItem);
  };

  const sortAmountAsc = (tempData) => {
    let sortItem = sortBy(tempData, 'amount');

    setSortingData(sortItem);
  };

  const sortAmountDsc = (tempData) => {
    let sortItem = sortBy(tempData, 'amount').reverse();

    setSortingData(sortItem);
  };

  const sortLeadAsc = (tempData) => {
    let sortItem = sortBy(tempData, 'time_lead');

    setSortingData(sortItem);
  };

  const sortLeadDsc = (tempData) => {
    let sortItem = sortBy(tempData, 'time_lead').reverse();

    setSortingData(sortItem);
  };

  React.useEffect(() => {
    setRowData(sortingData);
  }, [sortingData]);

  // end handle Sorting function

  React.useEffect(() => {
    setRowData([]);
    dispacth(reportAction.setBlankData());
  }, []);

  const handleExportCsv = () => {
    let params = {
      doc_type: programFilter,
      entity_id: entityFilter,
      department_id: departementFilter,
      year: yearFilter,
      period: periodeFilter?.code,
      status: statusFilter,
      vendor_id: vendor?.id,
    };

    if (departementFilter === 0) delete params.department_id;
    if (!periodeFilter) delete params.period;
    if (statusFilter === 0) delete params.status;
    if (!vendor) delete params.vendor_id;

    // console.log(params);
    dispacth(reportAction.reportExportCSV(access_token, params));
  };

  const handleGenerateReport = () => {
    setLoaded(true)
    let params = {
      doc_type: programFilter,
      entity_id: entityFilter,
      department_id: departementFilter,
      year: yearFilter,
      period: periodeFilter?.code,
      status: statusFilter,
      vendor_id: vendor?.id,
      // page: page,
    };

    setIsOP(programFilter === 'OP')
    
    if (departementFilter === 0) delete params.department_id;
    if (!periodeFilter) delete params.period;
    if (statusFilter === 0) delete params.status;
    if (!vendor) delete params.vendor_id;

    // console.log('params', params);
    dispacth(reportAction.getReportData(access_token, params));

    setFirstTableTitle(programFilter)
  };

  // for setting up data for table
  React.useEffect(() => {
    let tempArr = [];

    if (programFilter === 'PO') {
      data_report?.table?.data?.map((item, index) => {
        let tempData = {
          id: item?.id,
          doc_entry: item?.doc_num,
          status: item?.status_label,
          amount: item?.doc_total_amount,
          time_lead: item?.time_lead,
          exceed: item?.exceed,
          open_date: item?.sap_open_at,
          close_date: item?.sap_close_at,
          department: item?.pr?.department?.code,
          act_name: item?.pr?.items[0]?.activity_name,
          approval_one: {
            name: item?.approval_transactions[0]?.user?.name,
            position: item?.approval_transactions[0]?.user?.positions?.name,
            create_at: item?.approval_transactions[0]?.created_at,
            update_at: item?.approval_transactions[0]?.updated_at,
            time_lead: item?.approval_transactions[0]?.time_lead,
            exceed: item?.approval_transactions[0]?.exceed,
          },
          approval_two: {
            name: item?.approval_transactions[1]?.user?.name,
            position: item?.approval_transactions[1]?.user?.positions?.name,
            create_at: item?.approval_transactions[1]?.created_at,
            update_at: item?.approval_transactions[1]?.updated_at,
            time_lead: item?.approval_transactions[1]?.time_lead,
            exceed: item?.approval_transactions[1]?.exceed,
          },
          approval_three: {
            name: item?.approval_transactions[2]?.user?.name,
            position: item?.approval_transactions[2]?.user?.positions?.name,
            create_at: item?.approval_transactions[2]?.created_at,
            update_at: item?.approval_transactions[2]?.updated_at,
            time_lead: item?.approval_transactions[2]?.time_lead,
            exceed: item?.approval_transactions[2]?.exceed,
          },
        };
        tempArr.push(tempData);
      });
    }

    if (programFilter === 'PR') {
      data_report?.table?.data?.map((item, index) => {
        let tempData = {
          id: item?.id,
          doc_entry: item?.doc_num,
          status: item?.status_label,
          amount: item?.grand_total,
          time_lead: item?.time_lead,
          exceed: item?.exceed,
          open_date: item?.sap_open_at,
          close_date: item?.sap_close_at,
          department: item?.department?.code,
          act_name: item?.items[0]?.activity_name,
          approval_one: {
            name: item?.approval_transactions[0]?.user?.name,
            position: item?.approval_transactions[0]?.user?.positions?.name,
            create_at: item?.approval_transactions[0]?.created_at,
            update_at: item?.approval_transactions[0]?.updated_at,
            time_lead: item?.approval_transactions[0]?.time_lead,
            exceed: item?.approval_transactions[0]?.exceed,
          },
          approval_two: {
            name: item?.approval_transactions[1]?.user?.name,
            position: item?.approval_transactions[1]?.user?.positions?.name,
            create_at: item?.approval_transactions[1]?.created_at,
            update_at: item?.approval_transactions[1]?.updated_at,
            time_lead: item?.approval_transactions[1]?.time_lead,
            exceed: item?.approval_transactions[1]?.exceed,
          },
          approval_three: {
            name: item?.approval_transactions[2]?.user?.name,
            position: item?.approval_transactions[2]?.user?.positions?.name,
            create_at: item?.approval_transactions[2]?.created_at,
            update_at: item?.approval_transactions[2]?.updated_at,
            time_lead: item?.approval_transactions[2]?.time_lead,
            exceed: item?.approval_transactions[2]?.exceed,
          },
          approval_four: {
            name: item?.approval_transactions[3]?.user?.name,
            position: item?.approval_transactions[3]?.user?.positions?.name,
            create_at: item?.approval_transactions[3]?.created_at,
            update_at: item?.approval_transactions[3]?.updated_at,
            time_lead: item?.approval_transactions[3]?.time_lead,
            exceed: item?.approval_transactions[3]?.exceed,
          },
        };
        tempArr.push(tempData);
      });
    }

    if (programFilter == 'GRPO') {
      data_report?.table?.data?.map((item, index) => {
        let tempData = {
          id: item?.id,
          doc_entry: item?.doc_num,
          status: item?.status_label,
          amount: item?.doc_total_amount,
          time_lead: item?.time_lead,
          exceed: item?.exceed,
          open_date: item?.sap_open_at,
          close_date: item?.sap_close_at,
          department: item?.department?.code ?? '-',
          act_name: item?.po?.pr?.items[0]?.activity_name ?? '-',
          approval_one: {
            name: item?.approval_transactions[0]?.user?.name,
            position: item?.approval_transactions[0]?.user?.positions?.name,
            create_at: item?.approval_transactions[0]?.created_at,
            update_at: item?.approval_transactions[0]?.updated_at,
            time_lead: item?.approval_transactions[0]?.time_lead,
            exceed: item?.approval_transactions[0]?.exceed,
          },
          approval_two: {
            name: item?.approval_transactions[1]?.user?.name,
            position: item?.approval_transactions[1]?.user?.positions?.name,
            create_at: item?.approval_transactions[1]?.created_at,
            update_at: item?.approval_transactions[1]?.updated_at,
            time_lead: item?.approval_transactions[1]?.time_lead,
            exceed: item?.approval_transactions[1]?.exceed,
          },
          approval_three: {
            name: item?.approval_transactions[2]?.user?.name,
            position: item?.approval_transactions[2]?.user?.positions?.name,
            create_at: item?.approval_transactions[2]?.created_at,
            update_at: item?.approval_transactions[2]?.updated_at,
            time_lead: item?.approval_transactions[2]?.time_lead,
            exceed: item?.approval_transactions[2]?.exceed,
          },
        };
        tempArr.push(tempData);
      });
    }

    if (programFilter == 'AP') {
      data_report?.table?.data?.map((item, index) => {
        let tempData = {
          id: item?.id,
          doc_entry: item?.doc_num,
          status: item?.status_label,
          amount: item?.doc_total_amount,
          time_lead: item?.time_lead,
          exceed: item?.exceed,
          open_date: item?.sap_open_at,
          close_date: item?.sap_close_at,
          department: item?.department?.code ?? '-',
          act_name: item?.po?.pr?.items[0]?.activity_name ?? '-',
          approval_one: {
            name: item?.approval_transactions[0]?.user?.name,
            position: item?.approval_transactions[0]?.user?.positions?.name,
            create_at: item?.approval_transactions[0]?.created_at,
            update_at: item?.approval_transactions[0]?.updated_at,
            time_lead: item?.approval_transactions[0]?.time_lead,
            exceed: item?.approval_transactions[0]?.exceed,
          },
          approval_two: {
            name: item?.approval_transactions[1]?.user?.name,
            position: item?.approval_transactions[1]?.user?.positions?.name,
            create_at: item?.approval_transactions[1]?.created_at,
            update_at: item?.approval_transactions[1]?.updated_at,
            time_lead: item?.approval_transactions[1]?.time_lead,
            exceed: item?.approval_transactions[1]?.exceed,
          },
          approval_three: {
            name: item?.approval_transactions[2]?.user?.name,
            position: item?.approval_transactions[2]?.user?.positions?.name,
            create_at: item?.approval_transactions[2]?.created_at,
            update_at: item?.approval_transactions[2]?.updated_at,
            time_lead: item?.approval_transactions[2]?.time_lead,
            exceed: item?.approval_transactions[2]?.exceed,
          },
        };
        tempArr.push(tempData);
      });
    }

    if (programFilter == 'APDP') {
      data_report?.table?.data?.map((item, index) => {
        let tempData = {
          id: item?.id,
          doc_entry: item?.doc_num,
          status: item?.status_label,
          amount: item?.doc_total_amount,
          time_lead: item?.time_lead,
          exceed: item?.exceed,
          open_date: item?.sap_open_at,
          close_date: item?.sap_close_at,
          department: item?.department?.code ?? '-',
          act_name: item?.po?.pr?.items[0]?.activity_name ?? '-',
          approval_one: {
            name: item?.approval_transactions[0]?.user?.name,
            position: item?.approval_transactions[0]?.user?.positions?.name,
            create_at: item?.approval_transactions[0]?.created_at,
            update_at: item?.approval_transactions[0]?.updated_at,
            time_lead: item?.approval_transactions[0]?.time_lead,
            exceed: item?.approval_transactions[0]?.exceed,
          },
          approval_two: {
            name: item?.approval_transactions[1]?.user?.name,
            position: item?.approval_transactions[1]?.user?.positions?.name,
            create_at: item?.approval_transactions[1]?.created_at,
            update_at: item?.approval_transactions[1]?.updated_at,
            time_lead: item?.approval_transactions[1]?.time_lead,
            exceed: item?.approval_transactions[1]?.exceed,
          },
          approval_three: {
            name: item?.approval_transactions[2]?.user?.name,
            position: item?.approval_transactions[2]?.user?.positions?.name,
            create_at: item?.approval_transactions[2]?.created_at,
            update_at: item?.approval_transactions[2]?.updated_at,
            time_lead: item?.approval_transactions[2]?.time_lead,
            exceed: item?.approval_transactions[2]?.exceed,
          },
        };
        tempArr.push(tempData);
      });
    }

    if (programFilter == 'OP') {
      data_report?.table?.data?.map((item, index) => {
        let tempData = {
          id: item?.id,
          doc_entry: item?.doc_entry,
          status: item?.status_label,
          amount: item?.total_amount,
          time_lead: item?.time_lead,
          exceed: item?.exceed,
          open_date: item?.sap_open_at,
          close_date: item?.sap_close_at,
          vendor_name: item?.to_order_of_vendor_name ?? '-',
          // act_name: item?.pr?.items[0]?.activity_name ?? '-',
          approval_one: {
            name: item?.approval_transactions[0]?.user?.name,
            position: item?.approval_transactions[0]?.user?.positions?.name,
            create_at: item?.approval_transactions[0]?.created_at,
            update_at: item?.approval_transactions[0]?.updated_at,
            time_lead: item?.approval_transactions[0]?.time_lead,
            exceed: item?.approval_transactions[0]?.exceed,
          },
          approval_two: {
            name: item?.approval_transactions[1]?.user?.name,
            position: item?.approval_transactions[1]?.user?.positions?.name,
            create_at: item?.approval_transactions[1]?.created_at,
            update_at: item?.approval_transactions[1]?.updated_at,
            time_lead: item?.approval_transactions[1]?.time_lead,
            exceed: item?.approval_transactions[1]?.exceed,
          },
          approval_three: {
            name: item?.approval_transactions[2]?.user?.name,
            position: item?.approval_transactions[2]?.user?.positions?.name,
            create_at: item?.approval_transactions[2]?.created_at,
            update_at: item?.approval_transactions[2]?.updated_at,
            time_lead: item?.approval_transactions[2]?.time_lead,
            exceed: item?.approval_transactions[2]?.exceed,
          },
        };
        tempArr.push(tempData);
      });
    }

    let countPage = Math.ceil(data_report?.table?.total / get(data_report, ['table', 'per_page'], 7));

    setSelectRow(get(data_report, ['table', 'per_page'], 7))
    setPage(countPage);
    setFromRow(data_report?.table?.from);
    setToRow(data_report?.table?.to);
    setTotalRow(data_report?.table?.total);
    setRowData(tempArr);
    
    if(isLoaded){
      if(isEmpty(get(data_report, ['table', 'data']))){
        setNotFound(true)
      } else {
        setNotFound(false)
      }
    }
  }, [data_report]);

  React.useEffect(() => {
    setDataOnTrack(data_reportGraph?.dataOnTrack);
    setDataOffTrack(data_reportGraph?.dataOutTrack);
    setDataIDR(data_reportGraph?.dataIDR);
    setDataUSD(data_reportGraph?.dataUSD);

    let tempDataOn = [];
    let tempDataOff = [];
    if (data_reportGraph) {
      let keys = Object.keys(data_reportGraph?.month);
      keys.forEach(function (key) {
        tempDataOn.push(data_reportGraph.month[key].dataOnTrack);
        tempDataOff.push(data_reportGraph.month[key].dataOutTrack);
      });
      setBarOffTrack(tempDataOff);
      setBarOnTrack(tempDataOn);
    }
  }, [data_reportGraph]);

  React.useEffect(() => {
    let params = {
      doc_type: programFilter,
      entity_id: entityFilter,
      department_id: departementFilter,
      year: yearFilter,
      period: periodeFilter?.code,
      status: statusFilter,
      vendor_id: vendor?.id,
      page: pagination,
    };

    if (departementFilter === 0) delete params.department_id;
    if (!periodeFilter) delete params.period;
    if (statusFilter === 0) delete params.status;
    if (!vendor) delete params.vendor_id;

    dispacth(reportAction.getReportData(access_token, params));
  }, [pagination]);

  // React.useEffect(() => {
  //   setRowData(dataFaker);
  // }, []);

  React.useEffect(() => {
    dispacth(utility.getDepartment(access_token));
    dispacth(utility.getEntity(access_token));
  }, []);

  return (
    <>
      <Grid md={12}>
        {/* {console.log(data_reportGraph.month[1].dataOnTrack)} */}
        <Grid container md={12} className={classes.titleSection}>
        <Grid md={9} xs={12} sm={12} className={classes.wrapperFilter}>
          <Typography className={classes.textSelect}>Select</Typography>
          <Box className={classes.filterEntityContent}>
            <FormControl className={classes.formControl}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={entityFilter}
                onChange={handleChangeEntity}
                IconComponent={() => <DownArrow />}
                MenuProps={{
                  disableScrollLock: true,
                }}
              >
                {entity_data?.map((ent, index) => (
                  <MenuItem key={index} value={ent.id}>
                    <Typography
                      className={classes.bodyDataText}
                      variant="body1"
                      component="p"
                    >
                      {ent.code}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.filterDepartment}>
            <FormControl className={classes.formControl}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={departementFilter}
                onChange={handleChangeDepartment}
                IconComponent={() => <DownArrow />}
                MenuProps={{
                  disableScrollLock: true,
                }}
              >
                <MenuItem value={0}>All Department</MenuItem>
                {department_data?.map((dep, index) => (
                  <MenuItem key={index} value={dep.id}>
                    <Box className={classes.boxDepartment}>
                      <Typography
                        className={classes.bodyDataText}
                        variant="body1"
                        component="p"
                      >
                        {dep.code}
                      </Typography>
                      <Typography
                        className={classes.bodyDataSubText}
                        variant="body1"
                        component="p"
                      >
                        {`- ${dep.name}`}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.filterYear}>
            <Box className={classes.wrapperYearInput}>
              <TextField
                className={classes.selectYear}
                select
                icon
                value={yearFilter}
                onChange={handleChangeYear}
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                  }
                }}
              >
                {tempYearCol.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <Box className={classes.periodeIcon}>
                <DownArrow />
              </Box>
            </Box>
          </Box>
          <Box className={classes.filterPeriode}>
            <Box className={classes.wrapperInputPeriode}>
              <input
                type="text"
                placeholder="Periode"
                value={periodeFilter?.name ?? 'Periode'}
                className={classes.filterPeriodeInput}
                onFocus={() => setShowPeriode(true)}
                onBlur={() => setShowPeriode(false)}
              />
              {periodeFilter ? null : (
                <Box className={classes.periodeIcon}>
                  <DownArrow />
                </Box>
              )}
            </Box>
            {periodeFilter ? (
              <Box
                className={classes.iconTrashPeride}
                onClick={() => setPeriodeFilter(null)}
              >
                <img src={Trash} />
              </Box>
            ) : null}

            {showPeriode || isFocusPeriode ? (
              <Box
                className={classes.periodeDropDown}
                onMouseOver={() => setIsFocusPeriode(true)}
                onMouseOut={() => setIsFocusPeriode(false)}
              >
                {Periode.map((ped, index) => (
                  <>
                    <Box
                      key={ped.id}
                      className={classes.periodeItem}
                      onClick={() => handleChoosingPeriode(ped)}
                    >
                      <Typography>{ped.name}</Typography>
                    </Box>
                  </>
                ))}
              </Box>
            ) : null}
          </Box>
          <Box className={classes.filterMainMenu}>
            <FormControl className={classes.formControl}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={programFilter}
                onChange={handleChangeProgram}
                IconComponent={() => <DownArrow />}
                MenuProps={{
                  disableScrollLock: true,
                }}
              >
                <MenuItem value={'PO'}>PO</MenuItem>
                <MenuItem value={'PR'}>PR</MenuItem>
                <MenuItem value={'GRPO'}>GRPO</MenuItem>
                <MenuItem value={'AP'}>AP</MenuItem>
                <MenuItem value={'APDP'}>APDP</MenuItem>
                <MenuItem value={'OP'}>OP</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.filterStatus}>
            <FormControl className={classes.formControl}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={statusFilter}
                onChange={handleChangeStatus}
                IconComponent={() => <DownArrow />}
                MenuProps={{
                  disableScrollLock: true,
                }}
              >
                <MenuItem value={0}>All Status</MenuItem>
                <MenuItem value={'open'}>Open</MenuItem>
                <MenuItem value={'close'}>Close</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box
            className={classes.filterVendorContent}
            // onBlur={() => setIsVendorFocus(false)}
          >
            <Box className={classes.wrapperInputVendor}>
              <input
                className={classes.inputVendor}
                value={vendor?.name ?? 'All Vendor'}
                autoComplete="on"
                onFocus={() => setIsVendorFocus(true)}
                placeholder="All Vendor"
                onBlur={() => setIsVendorFocus(false)}
              />
              {vendor?.name ? null : (
                <Box className={classes.iconDownArrow}>
                  <DownArrow />
                </Box>
              )}
            </Box>
            {vendor?.name ? (
              <Box
                className={classes.iconTrash}
                onClick={() => setVendor(null)}
              >
                <img src={Trash} />
              </Box>
            ) : null}
            {/* render for select vendor name */}
            {isVendorFocus || showVendor ? (
              <Box
                onBlur={() => setIsVendorFocus(true)}
                className={classes.contentDataVendor}
                onMouseOver={() => setShowVendor(true)}
                onMouseOut={() => setShowVendor(false)}
              >
                <Box className={classes.contentVendorSearch}>
                  <input
                    className={classes.searchVendor}
                    autoComplete="on"
                    onFocus={() => setIsVendorFocus(true)}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder="Search"
                    // onBlur={() => setIsVendorFocus(false)}
                  />
                  <img
                    className={classes.serachIcon}
                    src={Search}
                    alt="image not found"
                  />
                </Box>
                <Box className={classes.listVendorName}>
                  {vendorData?.map((data, vendorIndex) => (
                    <Box
                      key={vendorIndex}
                      onClick={() => handleChooseVandor(data, vendorIndex)}
                      className={classes.vendorBox}
                    >
                      <Typography className={classes.TitleVendor}>
                        {`${data.name}`}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            ) : null}
          </Box>
          {/* <Box className={classes.filterBlankArea}>black area</Box> */}
        </Grid>
        <Grid md={3} sm={4} xs={6} className={classes.generateColumn}>
        <Box
            className={classes.BtnGenerateCotent}
            onClick={report_isLoading ? null : handleGenerateReport}
          >
            <div className={report_isLoading ? classes.BtnGenerateFalse : classes.BtnGenerate}>
              <Typography
                className={classes.BtnGenerateText}
                variant="body1"
                component="p"
              >
                Generate
              </Typography>
            </div>
          </Box>
        </Grid>
        </Grid>
        {isNotFound && (<div className={classes.notFoundText}>Data Not Found</div>)}
        {data_report && rowData.length > 1 ? (
          <Grid md={12} className={classes.wrapperBody}>
            <Grid md={12} className={classes.wrapperHeader}>
              <Typography className={classes.titelReport}>REPORT</Typography>
              <div className={classes.btnExportBlue} onClick={handleExportCsv}>
                {report_exportIsLoading ? (
                  <Box className={classes.loadingExport}>
                    <CircleLoadingSecondary />
                  </Box>
                ) : (
                  <img src={Export} alt="image not found" />
                )}
                <Typography
                  className={classes.btnText}
                  variant="body1"
                  component="p"
                >
                  Export .csv
                </Typography>
              </div>
            </Grid>
            <Box className={classes.sizeBoder} />
            <Grid md={12} container className={classes.wrapperCard}>
              <Grid md={3}>
                <Card
                  index={1}
                  title="Total Document"
                  subtitle="On Track"
                  value={dataOnTrack}
                />
              </Grid>
              <Grid md={3}>
                <Card
                  index={2}
                  title="Total Document"
                  subtitle="Off Track"
                  value={dataOffTrack}
                />
              </Grid>
              <Grid md={3}>
                <Card
                  index={3}
                  title="Total Amount"
                  subtitle="(IDR)"
                  value={`RP${formatRupiah(dataIDR || 0)}`}
                />
              </Grid>
              <Grid md={3}>
                <Card
                  index={4}
                  title="Total Amount"
                  subtitle="(USD)"
                  value={dataUSD}
                />
              </Grid>
            </Grid>
            <Grid md={12} className={classes.wrapperBar}>
              <BarChart barOnTrack={barOnTrack} barOffTrack={barOffTrack} />
            </Grid>
            <div style={{ width: '100%', padding: 20 }}>
              <Grid md={12} className={classes.wrapperTable}>
                <TableContainer className={classes.tableContainer}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Box className={classes.wrapperTableHeader}>
                            {`${firstTableitle} No`}
                            <SortArrow
                              pressUp={() => sortDocNumAsc(rowData)}
                              pressDown={() => sortDocNumDsc(rowData)}
                            />
                          </Box>
                        </TableCell>
                        { !isOP && (
                        <TableCell>
                          <Box className={classes.wrapperTableHeader}>
                            Department
                            <SortArrow
                              pressUp={() => sortDepratmentAsc(rowData)}
                              pressDown={() => sortDepartmentDsc(rowData)}
                            />
                          </Box>
                        </TableCell>)}
                        { !isOP && (
                        <TableCell>
                          <Box className={classes.wrapperTableHeader}>
                            Activity
                            <SortArrow
                              pressUp={() => sortActivityAsc(rowData)}
                              pressDown={() => sortActivityDsc(rowData)}
                            />
                          </Box>
                        </TableCell>)}
                        { isOP && (
                        <TableCell>
                          <Box className={classes.wrapperTableHeader}>
                            Vendor
                            <SortArrow
                              pressUp={() => sortVendorAsc(rowData)}
                              pressDown={() => sortVendorDsc(rowData)}
                            />
                          </Box>
                        </TableCell>)}
                        <TableCell>
                          <Box className={classes.wrapperTableHeader}>
                            Status
                            <SortArrow
                              pressUp={() => sortStatusAsc(rowData)}
                              pressDown={() => sortStatusDsc(rowData)}
                            />
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box className={classes.wrapperTableHeader}>
                            Amount
                            <SortArrow
                              pressUp={() => sortAmountAsc(rowData)}
                              pressDown={() => sortAmountDsc(rowData)}
                            />
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box className={classes.wrapperTableHeader}>
                            Lead Time
                            <SortArrow
                              pressUp={() => sortLeadAsc(rowData)}
                              pressDown={() => sortLeadDsc(rowData)}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowData?.map((row, index) => (
                        <TableData
                          key={index}
                          index={index}
                          isOP={isOP}
                          row={row}
                          programFilter={programFilter}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </div>
            <Grid
              xs={12}
              md={12}
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ marginTop: 15, paddingLeft: 20, paddingRight: 20 }}
            >
              <Grid xs={6} md={6}>
                <div className={classes.descTable}>
                  <Typography
                    className={classes.descTableText}
                    variant="body1"
                    component="p"
                  >
                    {`Showing ${fromRow} to ${toRow} of ${TotalRow} entries`}
                  </Typography>
                </div>
              </Grid>
              <Grid xs={6} md={6} className={classes.wrapperSidePageRight}>
                <Box className={classes.wrapperSelectRow}>
                  <Typography className={classes.textSelectRow}>
                    Row per page
                  </Typography>
                  <Box className={classes.filterRow}>
                    <FormControl className={classes.formControlRow}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectRow}
                        onChange={handleChangeRow}
                        IconComponent={() => <DownArrow />}
                      >
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <div className={classes.paginationContent}>
                  <Pagination
                    className={classes.pagination}
                    count={page}
                    color={'standard'}
                    shape="rounded"
                    onChange={handleChangePagination}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  notFoundText: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '45px',
    color: 'grey',
    marginTop: '100px',
    fontWeight: '800',
  },
  titleSection: {
    backgroundColor: '#fff',
    borderRadius: 8,
  },
  generateColumn: {
    padding: '20px 0px 20px 20px',
    display: 'flex',
    justifyContent: 'end',
    zIndex: '999999',
    backgroundColor: '#ffffff',
  },
  wrapperFilter: {
    padding: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // position: 'relative',
    width: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
  },
  textSelect: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
  },
  // styling Form select
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 3,
    paddingBottom: 5,
    width: '100%',
    maxHeight: 40,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      // marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#1B2030',
      paddingRight: 2,
      background: 'none',
    },
  },
  filterEntityContent: {
    paddingLeft: 8,
    paddingRight: 8,
    width: '100%',
    maxWidth: 116,
    minWidth: 116,
    // display: 'inline-block',
  },
  filterDepartment: {
    paddingLeft: 8,
    paddingRight: 8,
    width: '100%',
    maxWidth: 153,
    // display: 'inline-block',
  },
  filterYear: {
    paddingLeft: 8,
    paddingRight: 8,
    width: '100%',
    maxWidth: 122,
    minWidth: 122,
    // display: 'inline-block',
  },
  filterPeriode: {
    paddingLeft: 8,
    paddingRight: 8,
    width: '100%',
    maxWidth: 142,
    // position: 'absolute',
    // display: 'inline-block',
  },
  filterPeriodeInput: {
    width: 126,
    height: 40,
    backgroundColor: '#F8FAFB',
    paddingLeft: 10,
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    outline: 'none',
    '&.focus-visible': {
      border: '1px solid #E7E7E8',
      outline: 'none',
    },
  },
  wrapperInputPeriode: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  periodeIcon: {
    position: 'relative',
    zIndex: 90,
    marginTop: 10,
    marginLeft: -30,
  },
  periodeDropDown: {
    position: 'absolute',
    minWidth: 516,
    maxWidth: 516,
    height: 339,
    borderRadius: 8,
    border: '1px solid #E7E7E8',
    zIndex: 90,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    backgroundColor: '#fff',
  },
  periodeItem: {
    minHeight: 46,
    minWidth: 196,
    paddingLeft: 10,
    paddingTop: 11,
    paddingBottom: 11,
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
    cursor: 'pointer',
  },
  iconTrashPeride: {
    position: 'absolute',
    zIndex: 100,
    marginTop: -30,
    marginLeft: 90,
    cursor: 'pointer',
  },
  filterMainMenu: {
    paddingLeft: 8,
    paddingRight: 8,
    width: '100%',
    maxWidth: 116,
    // display: 'inline-block',
  },
  filterStatus: {
    paddingLeft: 8,
    paddingRight: 8,
    width: '100%',
    maxWidth: 126,
    // display: 'inline-block',
  },
  inputVendor: {
    width: '100%',
    height: 40,
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    paddingLeft: 15,
    '&.focus-visible': {
      border: '1px solid #E7E7E8',
      outline: 'none',
    },
  },
  filterVendorContent: {
    paddingLeft: 5,
    paddingRight: 5,
    width: '100%',
    maxWidth: 250,
    minWidth: 250,
    // position: 'relative',
  },
  wrapperInputVendor: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  iconDownArrow: {
    position: 'relative',
    zIndex: 90,
    marginTop: 10,
    marginLeft: -30,
  },
  iconTrash: {
    position: 'absolute',
    zIndex: 100,
    marginTop: -30,
    marginLeft: 200,
    cursor: 'pointer',
  },
  contentDataVendor: {
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid #E7E7E8',
    minHeight: 202,
    maxHeight: 202,
    position: 'absolute',
    maxWidth: 250,
    borderRadius: 8,
    padding: 6,
    zIndex: 99999,
  },
  contentVendorSearch: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    width: '100%',
    border: '1px solid #E7E7E8',
    paddingRight: 15,
    borderRadius: 8,
  },
  searchVendor: {
    flex: 1,
    height: 40,
    border: 'none',
    outline: 'none',
    paddingLeft: 12,
  },
  listVendorName: {
    marginTop: 8,
    minHeight: `calc(202px - 56px)`,
    maxHeight: `calc(202px - 56px)`,
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.4em',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
      borderRadius: 4,
    },
  },
  TitleVendor: {
    height: 46,
    marginTop: 2,
    marginBottom: 2,
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
    cursor: 'pointer',
  },
  filterBlankArea: {
    minWidth: 150,
  },
  BtnGenerateCotent: {
    width: '100%',
    maxWidth: 164,
    minWidth: 164,
    // position: 'absolute',
    // right: 0,
    // zIndex: 850,
    // marginRight: 20,
    paddingRight: 20,
  },
  BtnGenerate: {
    backgroundColor: '#1B2030',
    height: 40,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  BtnGenerateFalse: {
    backgroundColor: '#949598',
    height: 40,
    cursor: 'not-allowed',
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  BtnGenerateText: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '21px',
    color: '#fff',
  },
  wrapperBody: {
    borderRadius: 8,
    backgroundColor: '#fff',
  },
  wrapperHeader: {
    marginTop: 18,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 20,
    paddingTop: 20,
    paddingLeft: 18,
    paddingBottom: 20,
  },
  titelReport: {
    fontSize: 18,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  btnExportBlue: {
    backgroundColor: '#127EEE',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 14px',
    borderRadius: 8,
    cursor: 'pointer',
    maxHeight: 40,
    maxWidth: 154,
  },
  btnText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
    marginLeft: 8,
  },
  sizeBoder: {
    borderBottom: '1px solid #E7E7E8',
  },
  wrapperCard: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  wrapperBar: {
    paddingLeft: 20,
    paddingRight: 40,
    height: 350,
  },
  wrapperTable: {
    marginTop: 20,
  },
  tableContainer: {
    // maxHeight: '400px',
    Width: '100%',
    border: 8,
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
    '& .MuiTableCell-root.MuiTableCell-head': {
      borderBottom: 'none',
    },
    '& .MuiTableCell-root.MuiTableCell-head:nth-child(1)': {
      borderTopLeftRadius: 8,
    },
    '& .MuiTableRow-root.MuiTableRow-head': {
      borderRight: '1px solid #E7E7E8',
      borderTop: '1px solid #E7E7E8',
      borderLeft: '1px solid #E7E7E8',
      height: 60,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
    '& .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body': {
      borderBottom: 0,
    },
    '& .MuiTableBody-root .MuiTableRow-root:nth-last-child(1)': {
      borderBottom: '1px solid #E7E7E8',
    },
  },
  cell: {
    minWidth: '100px',
  },
  wrapperTableHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  // Pagination styling
  descTable: {
    paddingTop: 11.5,
    paddingBottom: 11.5,
  },
  descTableText: {
    color: '#969BAB',
    fontSize: 16,
    lineHeight: '21px',
  },
  paginationContent: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pagination: {
    borderRadius: 0,
    '&.MuiPagination-root .MuiPagination-ul .MuiPaginationItem-root': {
      margin: 0,
    },
    borderRadius: 0,
    '&.MuiPagination-root .MuiPagination-ul .MuiPaginationItem-rounded': {
      borderRadius: 0,
    },
    '&.MuiPagination-root .MuiPagination-ul .MuiButtonBase-root': {
      backgroundColor: '#fff',
      color: '#969BAB',
    },
    '&.MuiPagination-root .MuiPagination-ul .MuiButtonBase-root.Mui-selected': {
      backgroundColor: '#1B2030',
      color: '#fff',
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-child(1)': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-child(1) button': {
      color: '#1B2030',
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-last-child(1)': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-last-child(1) button': {
      color: '#1B2030',
    },
  },
  formControlRow: {
    backgroundColor: '#fff',
    paddingLeft: 8,
    paddingRight: 4,
    width: '100%',
    maxHeight: 29,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      // marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      paddingRight: 2,
      background: 'none',
    },
  },
  wrapperSidePageRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  wrapperSelectRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  textSelectRow: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
  },
  // end styling pagination
  selectYear: {
    width: '100%',
    height: 40,
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '& .MuiSvgIcon-root.MuiSelect-icon': {
      display: 'none',
    },
    '& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl':
      {
        height: 40,
        borderRadius: 8,
        border: '1px solid #E7E7E8',
        paddingTop: 6,
      },
    '& .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input':
      {
        height: 27,
        paddingLeft: 10,
        borderRadius: 8,
        backgroundColor: 'transparent',
        border: '1px solid #E7E7E',
      },
  },
  wrapperYearInput: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  boxDepartment: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  bodyDataSubText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    marginLeft: 4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const Periode = [
  {
    id: 1,
    code: 'one_year',
    name: '1 year',
  },
  {
    id: 2,
    code: 'semester_1',
    name: 'Semester 1',
  },
  {
    id: 3,
    code: 'semester_2',
    name: 'Semester 2',
  },
  {
    id: 4,
    code: 'quarter_1',
    name: 'Quarter 1',
  },
  {
    id: 5,
    code: 'quarter_2',
    name: 'Quarter 2',
  },
  {
    id: 6,
    code: 'quarter_3',
    name: 'Quarter 3',
  },
  {
    id: 7,
    code: 'quarter_4',
    name: 'Quarter 4',
  },
  {
    id: 8,
    code: 'jan',
    name: 'January',
  },
  {
    id: 9,
    code: 'feb',
    name: 'Februari',
  },
  {
    id: 10,
    code: 'mar',
    name: 'March',
  },
  {
    id: 11,
    code: 'apr',
    name: 'April',
  },
  {
    id: 12,
    code: 'may',
    name: 'May',
  },
  {
    id: 13,
    code: 'jun',
    name: 'June',
  },
  {
    id: 14,
    code: 'jul',
    name: 'July',
  },
  {
    id: 15,
    code: 'agt',
    name: 'August',
  },
  {
    id: 16,
    code: 'sep',
    name: 'September',
  },
  {
    id: 17,
    code: 'oct',
    name: 'October',
  },
  {
    id: 18,
    code: 'nov',
    name: 'November',
  },
  {
    id: 19,
    code: 'dec',
    name: 'Desember',
  },
];

const vendorRows = [
  { id: 1, name: 'Citra Titipan' },
  { id: 2, name: 'Cahaya Impian' },
  { id: 3, name: 'Triangle Sinar' },
  { id: 4, name: 'Sinar Mas' },
  { id: 5, name: 'Sukamaju Bali' },
  { id: 6, name: 'Sri Raha Wangi' },
  { id: 7, name: 'Sri Raha Wangi' },
  { id: 8, name: 'Sri Raha Wangi' },
  { id: 9, name: 'Sri Raha Wangi' },
  { id: 10, name: 'Sri Raha Wangi' },
];

const dataFaker = [
  {
    id: 1,
    doc_num: 23891,
    department: 'MKT',
    activity: 'TV Commercial',
    status: 'close',
    amount: 20000,
    lead_time: '1 Days 3 Hours',
    open_date: '2021-11-09 09:00:00',
    close_data: '2021-11-10 11:00:00',
    approval_history: [
      {
        id: 1,
        name: 'ahmad',
        position: 'Communication Manager',
        create_at: '2021-11-09 01:00',
        update_at: '2021-11-09 04:00',
      },
      {
        id: 2,
        name: 'Rizky',
        position: 'Corporate Communication Manager',
        create_at: '2021-11-09 01:00',
        update_at: '2021-11-09 04:00',
      },
      {
        id: 3,
        name: 'Margareta',
        position: 'Presiden Director',
        create_at: '2021-11-09 01:00',
        update_at: '2021-11-09 04:00',
      },
    ],
  },
  {
    id: 2,
    doc_num: 23891,
    department: 'MKT',
    activity: 'TV Commercial',
    status: 'close',
    amount: 20000,
    lead_time: '1 Days 3 Hours',
    open_date: '2021-11-09 09:00:00',
    close_data: '2021-11-10 11:00:00',
    approval_history: [
      {
        id: 1,
        name: 'ahmad',
        position: 'Communication Manager',
        create_at: '2021-11-09 01:00',
        update_at: '2021-11-09 04:00',
      },
      {
        id: 2,
        name: 'Rizky',
        position: 'Corporate Communication Manager',
        create_at: '2021-11-09 01:00',
        update_at: '2021-11-09 04:00',
      },
      {
        id: 3,
        name: 'Margareta',
        position: 'Presiden Director',
        create_at: '2021-11-09 01:00',
        update_at: '2021-11-09 04:00',
      },
    ],
  },
];

export default ReportForm;
