// get data proforma
export const PROFORMA_DATA_LOADING = 'PROFORMA/PROFORMA_DATA_LOADING';
export const PROFORMA_DATA_MESSAGE = 'PROFORMA/PROFORMA_DATA_MESSAGE';
export const PROFORMA_SET_DATA = 'PROFORMA/PROFORMA_SET_DATA';

// get proforma by id
export const PROFORMA_DETAIL_LOADING = 'PROFORMA/PROFORMA_DETAIL_LOADING';
export const PROFORMA_DETAIL_MESSAGE = 'PROFORMA/PROFORMA_DETAIL_MESSAGE';
export const PROFORMA_DETAIL_DATA = 'PROFORMA/PROFORMA_DETAIL_DATA';

// delete Pr
export const PROFORMA_DELETE_LOADING = 'PROFORMA/PROFORMA_DELETE_LOADING';
export const PROFORMA_DELETE_MESSAGE = 'PROFORMA/PROFORMA_DELETE_MESSAGE';

// Add Proforma
export const PROFORMA_ADD_LOADING = 'PROFORMA/PROFORMA_ADD_LOADING';
export const PROFORMA_ADD_MESSAGE = 'PROFORMA/PROFORMA_ADD_MESSAGE';
export const PROFORMA_ADD_PO_MESSAGE = 'PROFORMA/PROFORMA_ADD_PO_MESSAGE';
export const PROFORMA_ADD_CLIENT_MESSAGE =
  'PROFORMA/PROFORMA_ADD_CLIENT_MESSAGE';

// revise Proforma
export const PROFORMA_REVISE_LOADING = 'PROFORMA/PROFORMA_REVISE_LOADING';
export const PROFORMA_REVISE_MESSAGE = 'PROFORMA/PROFORMA_REVISE_MESSAGE';

// update proforma
export const PROFORMA_UPDATE_LOADING = 'PROFORMA/PROFORMA_UPDATE_LOADING';
export const PROFORMA_UPDATE_MESSAGE = 'PROFORMA/PROFORMA_UPDATE_MESSAGE';

// cancel Proforma
export const PROFORMA_CANCEL_LOADING = 'PROFORMA/PROFORMA_CANCEL_LOADING';
export const PROFORMA_CANCEL_MESSAGE = 'PROFORMA/PROFORMA_CANCEL_MESSAGE';

// for update vendor
export const PROFORMA_UPDATE_VENDOR_LOADING =
  'PROFORMA/PROFORMA_UPDATE_VENDOR_LOADING';
export const PROFORMA_UPDATE_VENDOR_MESSAGE =
  'PROFORMA/PROFORMA_UPDATE_VENDOR_MESSAGE';

// for approval proforma invoice
export const PROFORMA_APPROVAL_LOADING = 'PROFORMA/PROFORMA_APPROVAL_LOADING';
export const PROFORMA_APPROVAL_MESSAGE = 'PROFORMA/PROFORMA_APPROVAL_MESSAGE';

// for upload file dkt
export const PROFORMA_UPLOAD_LOADING = 'PROFORMA/PROFORMA_UPLOAD_LOADING';
export const PROFORMA_UPLOAD_MESSAGE = 'PROFORMA/PROFORMA_UPLOAD_MESSAGE';

// for approval bast
export const PROFORMA_APPROVAL_BAST_LOADING =
  'PROFORMA/PROFORMA_APPROVAL_BAST_LOADING';
export const PROFORMA_APPROVAL_BAST_MESSAGE =
  'PROFORMA/PROFORMA_APPROVAL_BAST_MESSAGE';

// process grpo
export const PROFORMA_PROCESS_GRPO_LOADING =
  'PROFORMA/PROFORMA_PROCESS_GRPO_LOADING';
export const PROFORMA_PROCESS_GRPO_MESSAGE =
  'PROFORMA/PROFORMA_PROCESS_GRPO_MESSAGE';
