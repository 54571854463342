import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { DataGrid } from '@material-ui/data-grid';

// Svg
import { SortArrow } from '../../assets/Icons/svg/SortArrow';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    borderRadius: 8,
    width: '100%',
    maxWidth: 1071,
  },
  titleTableContent: {
    paddingTop: 26,
    paddingBottom: 28,
    paddingLeft: 20,
  },
  titleTable: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  tableContent: {
    backgroundColor: '#fff',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    display: 'block',
    overflowX: 'auto',
    maxHeight: 400,
    border: 'none',
    '&::-webkit-scrollbar': {
      width: '5px',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell': {
      borderBottom: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-row:nth-child(odd)': {
      backgroundColor: '#F8FAFB',
      paddingLeft: 8,
    },
    '&.MuiDataGrid-root .MuiDataGrid-row:nth-child(even)': {
      backgroundColor: '#FFFFFF',
      paddingLeft: 8,
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#000000 !important',
      fontWeight: 'bold',
    },
    '&.MuiDataGrid-root .MuiDataGrid-iconButtonContainer .MuiButtonBase-root': {
      // backgroundColor: 'red',
      width: 20,
    },
    '&.MuiDataGrid-root .MuiDataGrid-iconButtonContainer .MuiButtonBase-root .MuiSvgIcon-root':
      {
        paddingTop: 5,
        paddingLeft: 5,
      },
    '&.MuiDataGrid-root .MuiDataGrid-cell--textLeft': {
      // fontSize: 16,
      color: '#000000',
      fontWeight: 'Normal',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnsContainer': {
      borderBottom: 'none',
      borderTop: '1px solid #E7E7E8',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .open div p': {
      backgroundColor: '#127EEE',
      color: '#fff',
      paddingLeft: 12,
      paddingRight: 12,
      borderRadius: 100,
    },
    '& .closed div p': {
      backgroundColor: '#969BAB',
      color: '#fff',
      paddingLeft: 12,
      paddingRight: 12,
      borderRadius: 100,
    },
    '& .approval div p': {
      backgroundColor: '#27AE60',
      color: '#fff',
      paddingLeft: 12,
      paddingRight: 12,
      borderRadius: 100,
      verticalAlign: 'top',
    },
    '& .reject div p': {
      backgroundColor: '#F12B2C',
      color: '#fff',
      paddingLeft: 12,
      paddingRight: 12,
      borderRadius: 100,
    },
  },
  borderLine: {
    border: '1px solid #E7E7E8',
  },
  btnContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 20,
    paddingTop: 17,
    paddingBottom: 14,
  },
  btnSubmit: {
    width: 183,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1B2030',
    borderRadius: 8,
    cursor: 'pointer',
  },
  btnSubmitText: {
    fontSize: 16,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#fff',
  },
  btnCancel: {
    width: 183,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 32,
    borderRadius: 8,
    cursor: 'pointer',
  },
  btnCancelText: {
    fontSize: 16,
    lineHeight: '27px',
    color: '#1B2030',
  },
}));

export default function ModalSyncSAP({
  open,
  handleCloseModal,
  handleShowConfirm,
  data,
  title,
  overideTitle,
  onPress,
}) {
  const classes = useStyles();

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Grid container>
              <Grid md={12}>
                <div className={classes.titleTableContent}>
                  <Typography
                    className={classes.titleTable}
                    variant="body1"
                    component="p"
                  >
                    {overideTitle ? overideTitle : `${title ?? 'PR'} List Preview`}
                  </Typography>
                </div>
                <div className={classes.borderLine}></div>
                <Grid md={12}>
                  <DataGrid
                    rows={data}
                    columns={columns}
                    // pageSize={10}
                    className={classes.tableContent}
                    disableSelectionOnClick
                    disableColumnMenu={true}
                    hideFooter={true}
                    autoHeight={true}
                    components={{
                      ColumnSortedAscendingIcon: () => <SortArrow />,
                      ColumnSortedDescendingIcon: () => <SortArrow />,
                    }}
                  />
                </Grid>
                <div className={classes.borderLine}></div>
                <div className={classes.btnContent}>
                  <div
                    className={classes.btnSubmit}
                    onClick={handleShowConfirm}
                  >
                    <Typography
                      className={classes.btnSubmitText}
                      variant="body1"
                      component="p"
                    >
                      Submit
                    </Typography>
                  </div>
                  <div className={classes.btnCancel} onClick={handleCloseModal}>
                    <Typography
                      className={classes.btnCancelText}
                      variant="body1"
                      component="p"
                    >
                      Cancel
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

const columns = [
  { field: 'noPr', headerName: 'No', width: 90 },
  {
    field: 'purpose_of_request',
    headerName: 'Purpose of Request',
    width: 200,
  },
  {
    field: 'department',
    headerName: 'Department',
    width: 150,
  },
  {
    field: 'entity',
    headerName: 'Entity',
    width: 130,
  },
  {
    field: 'activity',
    headerName: 'Activity',
    width: 230,
  },
  {
    field: 'total',
    headerName: 'Grand Total',
    width: 150,
  },
];

const rows = [
  {
    id: 1,
    Name: 'Dolor at amet duis vestibulum',
    Total: '2930010',
    Activity: 'TV Commercial',
  },
  {
    id: 2,
    Name: 'Dolor at amet duis vestibulum',
    Total: '2930010',
    Activity: 'Radio Ads',
  },
  {
    id: 3,
    Name: 'Dolor at amet duis vestibulum',
    Total: '2930010',
    Activity: 'Magazine',
  },
  {
    id: 4,
    Name: 'Dolor at amet duis vestibulum',
    Total: '2930010',
    Activity: 'TV Commercial',
  },
  {
    id: 5,
    Name: 'Dolor at amet duis vestibulum',
    Total: '2930010',
    Activity: 'TV Commercial',
  },
];
