import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box } from '@material-ui/core';
// import { dateFormater } from 'src/utils/formatter';
import moment from 'moment';

const History = ({ approval, title }) => {
  const classes = useStyles();

  const _renderStatusApprove = (approve, reject) => {
    if (approve == 1 && reject == 0) {
      return (
        <Typography className={classes.bodyStatusApprove}>approved</Typography>
      );
    } else if (approve == 0 && reject == 1) {
      return (
        <Typography className={classes.bodyStatusReject}>reject</Typography>
      );
    } else {
      return (
        <Typography className={classes.bodyStatusWaiting}>
          Waiting Approval
        </Typography>
      );
    }
  };

  const _renderStatusApproveMessage = (approve, reject, message) => {
    if (approve == 0 && reject == 1) {
      return (
        <Typography className={classes.bodyMessageReject}>
          note : {message}
        </Typography>
      );
    } else {
      return null;
    }
  };

  const _renderPersonList = (title, index) => {
    if (title === 'PR') {
      if (index > 3) return classes.personHide;
    } else if (title === 'PO') {
      if (index > 2) return classes.personHide;
    }
  };

  return (
    <Box className={classes.wrapperBody}>
      {approval?.map((app, index) => (
        <>
          <Grid
            key={index}
            container
            className={_renderPersonList(title, index)}
          >
            <Grid
              xs={12}
              md={12}
              container
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid xs={12} md={6}>
                <Typography
                  className={classes.bodyTitle}
                  variant="body1"
                  component="p"
                >
                  {`Approval ${index + 1}`}
                </Typography>
                <Typography
                  className={classes.bodyName}
                  variant="body1"
                  component="p"
                >
                  {app?.user?.name}
                </Typography>
                <Typography
                  className={classes.bodyCatergory}
                  variant="body1"
                  component="p"
                >
                  {app?.user?.positions?.name ?? '-'}
                </Typography>
              </Grid>
              <Grid xs={12} md={6} className={classes.wrapperStatusRigth}>
                <Box className={classes.wrapperStatus}>
                  {_renderStatusApprove(app?.approve, app?.reject)}
                  <Typography
                    className={classes.bodyDate}
                    variant="body1"
                    component="p"
                  >
                    {app?.approve || app?.reject
                      ? moment(app?.updated_at).format('DD-MM-YYYY HH:mm')
                      : null}
                  </Typography>
                </Box>
                {_renderStatusApproveMessage(
                  app?.approve,
                  app?.reject,
                  app?.message
                )}
              </Grid>
            </Grid>
          </Grid>
          <Box className={classes.sizebox} />
        </>
      ))}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  personHide: {
    display: 'none',
  },
  wrapperBody: {
    paddingTop: 27,
    paddingLeft: 20,
    paddingRight: 20,
    // paddingBottom: 66,
    // maxHeight: 412,
    minHeight: 412,
    [theme.breakpoints.down('md')]: {
      minHeight: '78vh',
    },
  },
  bodyTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  bodyName: {
    marginTop: 15,
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#1B2030',
  },
  bodyCatergory: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#707279',
  },
  sizebox: {
    marginTop: 20,
  },
  wrapperStatus: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  bodyStatusApprove: {
    paddingLeft: 12,
    paddingRight: 12,
    backgroundColor: '#27AE60',
    borderRadius: 100,
    color: '#fff',
    fontSize: 12,
    lineHeight: '26px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  bodyStatusReject: {
    paddingLeft: 12,
    paddingRight: 12,
    backgroundColor: '#FD453A',
    borderRadius: 100,
    color: '#fff',
    fontSize: 12,
    lineHeight: '26px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  bodyStatusWaiting: {
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    color: ' #969BAB',
    fontSize: 14,
    lineHeight: '26px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
  },
  bodyDate: {
    fontSize: 14,
    lineHeight: '26px',
    color: '#1B203',
    marginLeft: 8,
  },
  bodyMessageReject: {
    fontSize: 14,
    lineHeight: '26px',
    color: '#1B203',
    marginLeft: 8,
  },
  bodyWaiting: {
    fontSize: 14,
    lineHeight: '26px',
    color: '#969BAB',
  },
  wrapperStatusRigth: {
    marginTop: 40,
  },
}));

export default History;
