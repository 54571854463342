import React from 'react';
import { makeStyles } from '@material-ui/core';

import { getDeviceType } from '../../utils/get_device_type';

// Components
import LayoutDashboard from '../../components/LayoutDashboard';
import ChecklistCreate from '../../components/MasterData/Components/Create/ChecklistCreate';

export const ChecklistCreatePage = () => {
  const classes = useStyles();

  const [deviceType, setDeviceType] = React.useState('');

  React.useEffect(() => {
    const deviceType = getDeviceType();

    setDeviceType(deviceType);
  });

  return (
    <LayoutDashboard page="Master Data" Dropdown={true} deviceType={deviceType}>
      <div className={classes.pageContainer}>
        <ChecklistCreate />
      </div>
    </LayoutDashboard>
  );
};

const useStyles = makeStyles({
  pageContainer: {
    marginBottom: 30,
    paddingTop: 97 - 24,
    minHeight: '85vh',
  },
  pageContainerMobile: {
    maxWidth: '100vw',
    marginBottom: 30,
    paddingTop: 97 - 24,
  },
  sizebox: {
    marginTop: 20,
  },
  sizeboxMenu: {
    marginTop: 27,
  },
});

export default ChecklistCreatePage;
