import React from 'react';
import { DatePicker } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import { makeStyles } from '@material-ui/core';

const DatePickerSingle = ({ placeholder, onDatePick, value }) => {
  const classes = useStyles();

  const onDateChange = (props) => {
    onDatePick({ start: props });
    // console.log(props);
  };

  let extraProps = {}

  if(value){
    extraProps = { value }
  }

  return (
    <>
      <DatePicker
        format="YYYY-MM-DD"
        suffixIcon={null}
        onChange={onDateChange}
        placeholder={placeholder}
        className={classes.dateContent}
        {...extraProps}
      />
    </>
  );
};

const useStyles = makeStyles({
  dateContent: {
    height: 50,
    width: '100%',
    // flex: 1,
    color: 'red',
    '& .rs-btn.rs-btn-default.rs-picker-toggle': {
      backgroundColor: '#F8FAFB !important',
      height: 50,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      paddingRight: 10,
      paddingTop: 15,
      color: '#969BAB',
      width: '100%',
    },
    '& .rs-picker-toggle .rs-picker-toggle-value': {
      backgroundColor: '#F8FAFB !important',
      maxWidth: '98%',
      color: '#969BAB',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    '& .rs-calendar-table-cell-selected .rs-calendar-table-cell-content': {
      color: '#fff',
      backgroundColor: 'black',
    },
    '& .rs-picker-toggle .rs-picker-toggle-clean': {
      postion: 'absolute',
      right: 0,
      paddingTop: 8,
      paddingRight: 10,
      // display: 'none',
    },
    '& .rs-picker-toggle-caret': {
      display: 'none',
    },
  },
});

export default DatePickerSingle;
