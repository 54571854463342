import React from 'react';
import {
  makeStyles,
  Grid,
  Box,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import classNames from 'classnames';

// Component
import Lib from 'src/utils/Lib';
import { ContentText } from '../../../../libs/Typography';
import { ButtonPrimary } from '../../../../libs/ButtonUpload';

// Icons
import Trash from '../../../../assets/Icons/trash.png';
import Paper from '../../../../assets/Icons/paper.png';
import Plus from '../../../../assets/Icons/plus.png';
import { get } from 'lodash';
import { formatRupiah } from 'src/utils/formatter';

const RenderItemFile = ({
  file,
  index,
  lastIndex,
  categoryIndex,
  handleRemoveFile,
  handleAddMoreFile,
  detail,
  dktfile,
}) => {
  const classes = useStyles();

  const handleSeeLink = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Box key={index}>
      <Box key={index} className={classes.fileContent}>
        {dktfile ? (
          <Box
            className={detail ? classes.fileDescLink : classes.fileDesc}
            onClick={detail ? () => handleSeeLink(file?.url) : null}
          >
            <img src={Paper} alt="none" />
            <ContentText
              styling={detail ? classes.filenameLink : classes.filename}
              content={detail ? file?.file_name : file?.name}
            />
          </Box>
        ) : (
          <Box
            className={detail ? classes.fileDescLink : classes.fileDesc}
            onClick={detail ? () => handleSeeLink(file.files[0]?.url) : null}
          >
            <img src={Paper} alt="none" />
            <ContentText
              styling={detail ? classes.filenameLink : classes.filename}
              content={detail ? Lib.ellipsis(file?.files[0]?.file_name, 20) : Lib.ellipsis(file?.name, 20)}
            />
          </Box>
        )}

        {detail ? null : (
          <Box className={dktfile ? classes.fileActionDKT : classes.fileAction}>
            <Box
              className={classes.pointer}
              onClick={() => handleRemoveFile(categoryIndex, index)}
            >
              <img src={Trash} alt="none" />
            </Box>
            {dktfile
              ? null
              : index + 1 === lastIndex && (
                  <form>
                    <input
                      className={classes.input}
                      style={{ display: 'none' }}
                      id={`upload-more-${categoryIndex}-${index}`}
                      name={`${categoryIndex},${index}`}
                      onChange={handleAddMoreFile}
                      type="file"
                    />
                    <label htmlFor={`upload-more-${categoryIndex}-${index}`}>
                      <Box className={classes.pointer}>
                        <img src={Plus} alt="none" />
                      </Box>
                    </label>
                  </form>
                )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const arrStatus = ['Process BAST', 'Closed']

const ReportDoc = (props) => {
  const classes = useStyles();
  const {
    tempCategory,
    handleAddFile,
    handleRemoveFile,
    handleAddMoreFile,
    tempFileDKT,
    detail,
    dataUser,
    handleChangeCheckBox,
    handleChangeComment,
    status,
    handleAddDktFile,
    handleRemoveDktFile,
    noInvoice,
    totalWork,
    detail_data,
    detailFile,
    handleChangeCheckBoxDkt,
    canReviewDKT,
    showSectionDKT,
    setInvoiceField,
    invoiceField,
  } = props;
  const invoicePrice = get(detail_data, ['bast', 'invoice_price'])

  const canEditInvoice = !get(detail_data, ['bast', 'invoice_code']) && detail_data?.user_ids.includes(dataUser?.user?.id) && dataUser?.user?.department?.code !== 'FIN'
  
  const renderInvoice = () => {
    if(canEditInvoice){
      return(
        <input
          type="text"
          placeholder="Input text"
          onChange={(e)=> {
            invoiceField['invoice_no'] = e.target.value
            setInvoiceField(invoiceField)
          }}
          className={`${classes.inputInvoice} inpustyle`}
        />
      )
    }
    return(
      <ContentText
        styling={classes.inputDescTextContent}
        content={`${get(detail_data, ['bast', 'invoice_code']) || '-'}`}
      />
      )
  }

  return (
    <Grid xs={12} md={12} className={classes.mainContainer}>
      <ContentText
        styling={classes.titleMenu}
        content={`Report Document Checklist`}
      />
      <Box className={classes.contentUpload}>
        {tempCategory.map((item, index) => (
          <>
            <Box
              key={index}
              className={classNames(
                classes.categoryBox,
                index % 2 == 0 ? classes.backWhite : classes.backGWhite,
                index === 0 ? classes.bordeTopRad : null,
                index + 1 === tempCategory.length ? classes.bordeBotRad : null
              )}
            >
              <Box className={classes.categoryContent}>
                <Grid md={12} container className={classes.verifiedContent}>
                  <Grid item md={3} sm={4}>
                    <ContentText
                      styling={classes.categoryTitle}
                      content={item.name}
                    />
                    <ContentText
                      styling={classes.categoryText}
                      content={item.desc}
                    />
                  </Grid>
                  { showSectionDKT && (
                  <Grid md={3} sm={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={item?.isCheck}
                          className={classes.checkContent}
                          color="secondary"
                          value={item?.isCheck}
                          disabled={!item?.allowToEdit}
                          onChange={handleChangeCheckBox}
                          name={`${item.id}`}
                          inputProps={{
                            'aria-label': 'checkbox with default color',
                          }}
                        />
                      }
                      label="Checked"
                    />
                  </Grid>)}
                  {!item?.isCheck && showSectionDKT &&
                    dataUser?.user?.department?.code === 'FIN' && (
                      <Grid md={3} sm={4} className={classes.commentContent}>
                        <ContentText
                          content={'Note :'}
                          styling={classes.commentLabel}
                        />
                        <input
                          type="text"
                          placeholder="Input text"
                          name={`${item.id}`}
                          onChange={handleChangeComment}
                          className={classes.inputComment}
                        />
                      </Grid>
                    )}
                    <Grid md={3} sm={4}>
                    {item?.file?.length < 1 ? (
                  <Box className={classes.btnAction}>
                    <form>
                      <input
                        className={classes.input}
                        style={{ display: 'none' }}
                        id={`upload-${index}`}
                        name={`${index}`}
                        onChange={handleAddFile}
                        type="file"
                      />
                      <label htmlFor={`upload-${index}`}>
                        <ButtonPrimary />
                      </label>
                    </form>
                  </Box>
                ) : (
                  <Box>
                    {item?.file?.map((file, fileIndex) =>
                      detail ? (
                        <RenderItemFile
                          key={fileIndex}
                          file={file}
                          index={fileIndex}
                          lastIndex={item?.file.length}
                          categoryIndex={index}
                          handleRemoveFile={handleRemoveFile}
                          handleAddMoreFile={handleAddMoreFile}
                          detail
                        />
                      ) : (
                        <RenderItemFile
                          key={fileIndex}
                          file={file}
                          index={fileIndex}
                          lastIndex={item?.file.length}
                          categoryIndex={index}
                          handleRemoveFile={handleRemoveFile}
                          handleAddMoreFile={handleAddMoreFile}
                        />
                      )
                    )}
                  </Box>
                )}
                    </Grid>
                </Grid>

                
              </Box>
            </Box>
          </>
        ))}
        
        { showSectionDKT && (
          <>
        <Box className={classes.uploadInfo}>
          <ContentText
            content={`Bagian dibawah diisi oleh pihak DKT`}
            styling={classes.textinfo}
          />
          <Box className={classes.border} />
        </Box>
        <Box className={classes.uploadInfoContent}>
          {tempFileDKT?.map((item, index) => (
            <>
              <Box
                key={index}
                className={classNames(
                  classes.uploadInfoBox,
                  index % 2 == 0 ? classes.backWhite : classes.backGWhite,
                  index === 0 ? classes.bordeTopRad : null,
                  index + 1 === tempCategory.length ? classes.bordeBotRad : null
                )}
              >
                <Box className={classes.secitonLabel}>
                  <ContentText
                    styling={classes.labelInfo}
                    content={item.name}
                  />
                  {item?.note !== '' && (
                    <ContentText
                      styling={classes.labelSubInfo}
                      content={item.note}
                    />
                  )}
                  {item?.type === 'value' && (
                    <Box className={classes.inputInfo}>
                      <Box className={classes.inputDesc}>
                        <ContentText
                          styling={classes.inputDescLabel}
                          content={`Nomor Invoice`}
                        />
                        <Box className={classes.inputDescTextInvoice}>
                          {renderInvoice()}
                        </Box>
                        <Box className={classes.distLeft} />
                        
                        <ContentText
                          styling={classes.inputDescLabel}
                          content={`Jumlah yang ditagihkan`}
                        />
                        <Box className={classes.inputDescText}>
                          { canEditInvoice ?
                          (<input
                            type="number"
                            min={0}
                            placeholder="Input amount"
                            onChange={(e)=> {
                              invoiceField['invoice_price'] = e.target.value
                              setInvoiceField(invoiceField)
                            }}
                            className={`${classes.inputInvoice} no-spin inpustyle`}
                          />) :
                          (<ContentText
                            styling={classes.inputDescTextContent}
                            content={(invoicePrice || invoicePrice === 0) ? formatRupiah(get(detail_data, ['bast', 'invoice_price']) || 0) : ''}
                          />)}
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>

                {item?.file?.length < 1 ? (
                  status === 'Process BAST' &&
                    detail_data?.user_ids.includes(dataUser?.user?.id) ? (
                    <Box className={classes.btnAction}>
                      <form>
                        <input
                          className={classes.input}
                          style={{ display: 'none' }}
                          id={`upload-${index}`}
                          name={`${index}`}
                          onChange={handleAddDktFile}
                          type="file"
                        />
                        <label htmlFor={`upload-${index}`}>
                          <ButtonPrimary />
                        </label>
                      </form>
                    </Box>
                  ) : (
                    <Box className={classes.btnAction}>
                      <ButtonPrimary disable={true} />
                    </Box>
                  )
                ) : (
                  item?.file?.map((file, fileIndex) =>
                    detailFile ? (
                      <RenderItemFile
                        key={fileIndex}
                        file={file}
                        index={fileIndex}
                        lastIndex={item?.file.length}
                        categoryIndex={index}
                        handleRemoveFile={handleRemoveDktFile}
                        handleAddMoreFile={handleAddMoreFile}
                        dktfile
                        detail
                      />
                    ) : (
                      <RenderItemFile
                        key={fileIndex}
                        file={file}
                        index={fileIndex}
                        lastIndex={item?.file.length}
                        categoryIndex={index}
                        handleRemoveFile={handleRemoveDktFile}
                        handleAddMoreFile={handleAddMoreFile}
                        dktfile
                      />
                    )
                  )
                )}
                {item?.file?.length > 0 && canReviewDKT && (
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={item?.isCheck}
                          className={classes.checkContent}
                          color="secondary"
                          value={item?.isCheck}
                          onChange={handleChangeCheckBoxDkt}
                          name={`${item.id}`}
                          inputProps={{
                            'aria-label': 'checkbox with default color',
                          }}
                        />
                      }
                      label="Checked"
                    />
                  </Box>
                )}
              </Box>
            </>
          ))}
        </Box>
        </>)}
      </Box>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingTop: 34,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: '21.4px',
  },
  titleMenu: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '21px',
    color: '#969BAB',
  },
  contentUpload: {
    marginTop: 25,
  },
  categoryText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 400,
    marginBottom: 15,
  },
  categoryTitle: {
    fontWeight: '700',
  },
  categoryBox: {
    padding: '14px 20px',
    border: '1px solid #E7E7E8',
  },
  uploadInfoBox: {
    padding: '14px 20px',
    border: '1px solid #E7E7E8',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  categoryContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  btnAction: {
    marginRight: 20,
  },
  backWhite: {
    background: '#fff',
  },
  backGWhite: {
    background: '#F8FAFB',
  },
  bordeTopRad: {
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  },
  bordeBotRad: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  fileContent: {
    padding: '0px 20px',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  pointer: {
    cursor: 'pointer',
  },
  fileDesc: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  fileDescLink: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
  },
  filename: {
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 500,
    marginRight: 18,
    marginLeft: 12,
  },
  filenameLink: {
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 500,
    marginRight: 18,
    marginLeft: 12,
    textDecoration: 'underline',
  },
  fileAction: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 65,
  },
  fileActionDKT: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textinfo: {
    fontSize: 14,
    lineHeight: '19px',
    fontWeight: 400,
    fontStyle: 'italic',
    color: '#969BAB',
    marginRight: 20,
  },
  border: {
    flex: 1,
    border: '1px dashed #E7E7E8',
  },
  uploadInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 17,
    paddingBottom: 21,
  },
  labelInfo: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 400,
    color: '#000000',
  },
  labelSubInfo: {
    marginTop: 11,
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 400,
    color: '#85878E',
  },
  inputInfo: {
    paddingTop: 24,
    paddingBottom: 21,
    paddingLeft: 40,
    paddingRight: 20,
  },
  inputDesc: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  inputDescLabel: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 700,
    color: '#000000',
  },
  inputDescText: {
    background: '#EBEFF0',
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    height: 34,
    width: 234,
    marginLeft: 10,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 4,
  },
  inputDescTextInvoice: {
    background: '#EBEFF0',
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    height: 34,
    width: 234,
    marginLeft: 10,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 8,
  },
  inputDescTextContent: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 400,
    color: '#000000',
  },
  distLeft: {
    marginLeft: 10,
  },
  verifiedContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  checkContent: {
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#2B3595',
      padding: 0,
    },
    '&.MuiButtonBase-root.MuiIconButton-root ': {
      padding: 0,
    },
  },
  commentContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  commentLabel: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 700,
    color: '#000000',
    marginRight: 12,
  },
  inputComment: {
    flex: 1,
    maxWidth: 300,
    height: 34,
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    background: '#F8FAFB',
    padding: '0px 6px',
  },
  inputInvoice: {
    flex: 1,
    maxWidth: 300,
    // height: 34,
    border: '0px',
    borderRadius: 8,
    background: 'transparent',
    marginLeft: 10,
    '&.inpustyle:focus': {
      border: '0px',
      outline: 'none',
      boxShadow: 'transparent',
      borderRadius: 8,
    },
  },
  secitonLabel: {
    flex: 1,
  },
}));

export default ReportDoc;
