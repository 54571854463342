import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { useRouter } from '../../utils/useRouter';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../redux/Auth/actions';
import * as actions_2 from '../../redux/Drawer/actions';

import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Grid } from '@material-ui/core';
// Icons
import ReportOn from '../../assets/Icons/reportOn.png';
import ReportOff from '../../assets/Icons/reportOff.png';
import DashboardOn from '../../assets/Icons/dashboardOn.png';
import DashboardOff from '../../assets/Icons/dashboardOff.png';
import PrOn from '../../assets/Icons/prOn.png';
import PrOff from '../../assets/Icons/prOff.png';
import PoOn from '../../assets/Icons/poOn.png';
import PoOff from '../../assets/Icons/poOff.png';
import InvoiceOn from '../../assets/Icons/invoiceOn.png';
import InvoiceOff from '../../assets/Icons/invoiceOff.png';
import UserOn from '../../assets/Icons/userOn.png';
import UserOff from '../../assets/Icons/userOff.png';
import ApprovalOff from '../../assets/Icons/approvalOff.png';
import ApprovalOn from '../../assets/Icons/approvalOn.png';
import Menu from '../../assets/Icons/menu.png';
import Avatar from '../../assets/Images/avatar.png';
import Logo from '../../assets/Icons/logo.png';
import Indonesia from '../../assets/Icons/indonesia.png';
import MenuCross from '../../assets/Icons/menuCross.png';
import SettingOn from '../../assets/Icons/settingOn.png';
import SettingOff from '../../assets/Icons/settingOff.png';
import GrpoOn from '../../assets/Icons/grpoOn.png';
import GrpoOff from '../../assets/Icons/grpoOff.png';
import PerformaOn from '../../assets/Icons/performaOn.png';
import PerformaOff from '../../assets/Icons/performaOff.png';
import ApdpOn from '../../assets/Icons/apdpOn.png';
import ApdpOff from '../../assets/Icons/apdpOff.png';
import OpOn from '../../assets/Icons/opOn.png';
import OpOff from '../../assets/Icons/opOff.png';
import ClientOn from '../../assets/Icons/clientOn.png';
import ClientOff from '../../assets/Icons/clientOff.png';
import ProfileIcon from '../../assets/Icons/profileIcon.png';

// Components
import DropdownUser from '../DropdownUser';
import PageItem from './components/PageItem';

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerOpenMobile: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    position: 'fixed',
    zIndex: 999,
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '89px !important',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  drawerCloseMobile: {
    overflowX: 'hidden',
    width: '0px !important',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    height: '96.5px !important',
  },
  content: {
    flexGrow: 1,
    paddingTop: 24,
    paddingLeft: 24,
    paddingRight: 24,
    // padding: theme.spacing(3),
    backgroundColor: '#F3F5F6',
    maxWidth: `calc(100% - 89px)`,
  },
  contentOpen: {
    flexGrow: 1,
    paddingTop: 24,
    paddingLeft: 24,
    paddingRight: 24,
    maxWidth: `calc(100% - ${drawerWidth}px)`,
    // padding: theme.spacing(3),
    backgroundColor: '#F3F5F6',
  },
  contentMobile: {
    flexGrow: 1,
    paddingTop: 24,
    paddingLeft: 15,
    paddingRight: 15,
    maxWidth: '99vw',
    // padding: theme.spacing(3),
    backgroundColor: '#F3F5F6',
  },
  titlePage: {
    fontSize: '16px !important',
    lineHeight: '21px !important',
    fontWeight: 'bold !important',
    color: '#1B2030 !important',
  },
  iconContentLogoOpen: {
    minWidth: 46,
    height: 80,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: -16,
    marginBottom: -16,
  },
  iconContentOpen: {
    minWidth: 46,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: -6,
    marginBottom: -6,
  },
  iconLogoContent: {
    minWidth: 89,
    height: 80,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: -16,
    marginTop: -16,
    marginBottom: -16,
  },
  iconContent: {
    // backgroundColor: 'red',
    minWidth: 89,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: -16,
    marginTop: -6,
    marginBottom: -6,
    // marginRight: 10,
  },
  iconImage: {
    height: 20,
    width: 20,
  },
  maintTop: {
    height: 96.5,
    top: 0,
    width: '90%',
    paddingTop: 3.5,
  },
  setPadding: {},
  wrapperHeader: {
    marginTop: -24,
    paddingTop: 24,
    height: 97,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  wrapperUser: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  avatarImage: {
    marginRight: 20,
  },
  role: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#9CA9B7',
  },
  iconLogoTextImage: {
    marginTop: 35,
    marginLeft: 10,
  },
  mainFooter: {
    height: 63,
    backgroundColor: '#fff',
    marginLeft: -24,
    marginRight: -24,
    paddingLeft: 30,
    display: 'flex',
    alignItems: 'center',
  },
  mainFooterMobile: {
    height: 63,
    backgroundColor: '#fff',
    marginLeft: -24,
    marginRight: -24,
    paddingLeft: 30,
    display: 'flex',
    alignItems: 'center',
  },
  headerContentClose: {
    paddingTop: 24,
    paddingRight: 24,
    paddingLeft: 89 + 24,
    width: '100%',
    marginTop: -24,
    position: 'fixed',
    backgroundColor: '#F3F5F6',
    zIndex: 999,
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  headerContentCloseMobile: {
    paddingTop: 24,
    paddingRight: 24,
    paddingLeft: 24,
    width: '100%',
    maxWidth: '100vw',
    marginTop: -24,
    position: 'fixed',
    backgroundColor: '#F3F5F6',
    zIndex: 999,
  },
  headerContent: {
    paddingLeft: drawerWidth + 24,
    paddingTop: 24,
    paddingRight: 24,
    width: '100%',
    marginTop: -24,
    position: 'fixed',
    backgroundColor: '#F3F5F6',
    zIndex: 999,
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  headerContentMobile: {
    // paddingLeft: drawerWidth + 24,
    paddingTop: 24,
    paddingRight: 24,
    width: '100%',
    maxWidth: '100vw',
    marginTop: -24,
    position: 'fixed',
    backgroundColor: '#F3F5F6',
    // zIndex: 9999,
  },
  logoutBtn: {
    cursor: 'pointer',
  },
  textDefault: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
  },
  linkActive: {
    borderRight: '4px solid #1B2030',
    paddingTop: 6,
    paddingBottom: 6,
  },
  linkDisable: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  hideMenu: {
    position: 'fixed',
    zIndex: 9999,
    paddingTop: 10,
    marginLeft: drawerWidth + 19,
  },
  logoSideBar: {
    position: 'absolute',
    height: 83,
  },
  listSideBar: {
    marginTop: 83,
    maxHeight: `calc(100vh - 83px)`,
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.4em',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
      borderRadius: 4,
    },
  },
}));

export default function LayoutDashboard({ children, page, deviceType }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const router = useRouter();
  const [open, setOpen] = React.useState(true);
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [pageList, setPageList] = React.useState([]);
  const [iconArr, setIconArr] = React.useState([]);
  const [iconArrDisable, setIconArrDisable] = React.useState([]);
  // gobal Variable
  const { access_token, data } = useSelector((state) => state.auth);
  const { isOpen } = useSelector((state) => state.drawer);

  // let pageList = ['Dashboard', 'Purchase Order', 'Invoice', 'Claim'];

  // let iconArr = [DashboardOn, PoOn, OpOn, PerformaOn];
  // let iconArrDisable = [DashboardOff, PoOff, OpOff, PerformaOff];

  React.useEffect(() => {
    let pageListDistributor = ['Purchase Order', 'Claim'];
    let pageListSupplier = ['Purchase Order', 'Invoice'];

    let iconArrDistributor = [PoOn, PerformaOn];
    let iconArrSupplier = [PoOn, OpOn];

    let iconArrDisableDistibutor = [PoOff, PerformaOff];
    let iconArrDisableSupplier = [PoOff, OpOff];

    if (data?.user?.distributor_id) {
      setPageList(pageListDistributor);
      setIconArr(iconArrDistributor);
      setIconArrDisable(iconArrDisableDistibutor);
    } else {
      setPageList(pageListSupplier);
      setIconArr(iconArrSupplier);
      setIconArrDisable(iconArrDisableSupplier);
    }
  }, []);

  const handleDrawer = () => {
    // setOpen(!open);
    dispatch(actions_2.setDrawer(!isOpen));
  };

  const handleLink = (text) => {
    if (text === 'Dashboard') {
      router.push('/');
    } else if (text === 'Purchase Order') {
      router.push('/purchase-order-external');
    } else if (text === 'Invoice') {
      router.push('/invoice');
    } else if (text === 'Claim') {
      router.push('/claim');
    }
  };

  const handleCloseDropdown = () => {
    setShowDropdown(false);
  };

  const handleLogout = () => {
    dispatch(actions.logout(access_token));
    router.push('/login');
  };

  return (
    <div className={classes.root}>
      <DropdownUser
        open={showDropdown}
        close={handleCloseDropdown}
        handleLogout={handleLogout}
        deviceType={deviceType}
        data={data}
      />
      <div
        className={
          isOpen
            ? deviceType === 'mobile'
              ? classes.headerContentMobile
              : classes.headerContent
            : deviceType === 'mobile'
            ? classes.headerContentCloseMobile
            : classes.headerContentClose
        }
      >
        <Grid container>
          <Grid xs={12}>
            <div className={classes.wrapperHeader}>
              <div onClick={handleDrawer} className={classes.btnDrawer}>
                <img src={Menu} alt="image not found" />
              </div>

              <div className={classes.wrapperUser}>
                <div className={classes.avatarImage}>
                  {data?.user?.avatar_base !== '' ? (
                    <img
                      style={{ width: 44, height: 44 }}
                      src={`data:image/jpeg;base64,${data?.user?.avatar_base}`}
                      alt="image not found"
                    />
                  ) : (
                    <img src={Avatar} alt="image not found" />
                  )}
                </div>

                <div
                  className={classes.logoutBtn}
                  onClick={() => setShowDropdown(true)}
                >
                  <img src={ProfileIcon} alt="image not found" />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <CssBaseline />

      {deviceType === 'mobile' && isOpen ? (
        <div className={classes.hideMenu} onClick={handleDrawer}>
          <img src={MenuCross} alt="image not found" />
        </div>
      ) : null}

      <Drawer
        variant="permanent"
        className={
          deviceType === 'mobile'
            ? clsx(classes.drawer, {
                [classes.drawerOpenMobile]: isOpen,
                [classes.drawerCloseMobile]: !isOpen,
              })
            : clsx(classes.drawer, {
                [classes.drawerOpen]: isOpen,
                [classes.drawerClose]: !isOpen,
              })
        }
        classes={{
          paper:
            deviceType === 'mobile'
              ? clsx({
                  [classes.drawerOpenMobile]: isOpen,
                  [classes.drawerCloseMobile]: !isOpen,
                })
              : clsx({
                  [classes.drawerOpen]: isOpen,
                  [classes.drawerClose]: !isOpen,
                }),
        }}
      >
        <List className={classes.logoSideBar}>
          {['logo'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon
                className={
                  isOpen ? classes.iconContentLogoOpen : classes.iconLogoContent
                }
              >
                <img
                  className={classes.iconLogoImage}
                  src={Logo}
                  alt="image not found"
                />
              </ListItemIcon>
              <div className={classes.imageText}>
                <img
                  className={classes.iconLogoTextImage}
                  src={Indonesia}
                  alt="image not found"
                />
              </div>
            </ListItem>
          ))}
        </List>
        {/* rendering list page side bar */}
        <List className={classes.listSideBar}>
          {pageList.map((text, index) => (
            <PageItem
              page={page}
              text={text}
              handleLink={handleLink}
              isOpen={isOpen}
              iconEnable={iconArr[index]}
              iconDisbale={iconArrDisable[index]}
            />
          ))}
        </List>
      </Drawer>
      <main
        className={
          deviceType === 'mobile'
            ? classes.contentMobile
            : isOpen
            ? classes.contentOpen
            : classes.content
        }
      >
        {children}
        <Grid container>
          <Grid item xs={12}>
            <div
              className={
                deviceType === 'mobile'
                  ? classes.mainFooterMobile
                  : classes.mainFooter
              }
            >
              <Typography
                className={classes.footer}
                variant="body1"
                component="p"
              >
                © DKT Indonesia 2021. All right reserved.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </main>
    </div>
  );
}
