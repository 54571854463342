import React from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  withStyles,
  Box,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { get } from 'lodash';

import { dateFormater, formatRupiah } from '../../utils/formatter';
import { useRouter } from '../../utils/useRouter';
import Pagination from '@material-ui/lab/Pagination';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../redux/GRPO/actions';
import * as actions_2 from '../../redux/Utility/actions';
import { SortingASC, SortingDESC } from '../../utils/SortFunc';
import SortArrow from '../SortArrow';

// compontent
import { Row } from './TableCustom/index';
import DatePicker from '../DatePicker';
import { TabelLoading, TableNoData } from '../Loading/TableLoading';
import { CircleLoadingSecondary } from '../Loading/CircleLoading';
import ModalDeleteItem from '../ModalDeleteItem';
import CustomizedSnackbars from '../PopupMessage';
import DesktopPagination from '../Pagination/DesktopPagination';
import Lib from 'src/utils/Lib';
import TitleWithSearchNo from '../TableData-Common/titleWithSearchNo';

// Icons
import TandaSeru from '../../assets/Icons/tandaSeru.png';
import Asnyc from '../../assets/Icons/async.png';
import DateIcon from '../../assets/Icons/date.png';
import Export from '../../assets/Icons/export.png';

// Svg
import { DownArrow } from '../../assets/Icons/svg/DownArrow';

// generate fixed table
const StickyTableCell = withStyles((theme) => ({
  head: {
    right: 0,
    // position: 'sticky',
    // zIndex: theme.zIndex.appBar + 2,
  },
  body: {
    backgroundColor: '#ddd',
    minWidth: '50px',
    right: 0,
    position: 'sticky',
    zIndex: 99,
  },
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const GRPOTableData = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispacth = useDispatch();

  // Create format for table and pagination
  const [page, setPage] = React.useState(1);
  const [pagination, setPagination] = React.useState(1);
  const [fromRow, setFromRow] = React.useState(1);
  const [toRow, setToRow] = React.useState(1);
  const [TotalRow, setTotalRow] = React.useState(1);
  const [limit, setLimit] = React.useState(10);

  // initial state
  const [rowData, setRowData] = React.useState([]);
  const [sortingData, setSortingData] = React.useState([]);
  const [activityFilter, setActivityFilter] = React.useState('All Activity');
  const [status, setStatus] = React.useState('All_Status');
  const [departementFilter, setDepartementFilter] = React.useState(0);
  const [entityFilter, setEntityFilter] = React.useState(0);
  const [currencyFilter, setCurrentcyFilter] = React.useState('All Currency');
  const [categoryFilter, setCategoryFilter] = React.useState('All Category');

  const [tempId, setTempId] = React.useState(null);
  const [entityList, setEntityList] = React.useState([])
  const [buttonSyncClicked, setButtonSyncClicked] = React.useState(false);

  const [docNumParam, setDocNumParam] = React.useState('');

  const [date, setDate] = React.useState({
    start: null,
    end: null,
  });

  // handle message
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };

  // end handle Message

  // Global Variable
  const { access_token, data } = useSelector((state) => state.auth);
  const {
    notifLoading,
    notfiData,
    data_grpo,
    isLoading,
    grpo_sap,
    del_grpoMessage,
    del_grpoLoading,
    publish_grpoMessage,
    publish_grpoLoading,
  } = useSelector((state) => state.grpo);

  const {
    department_data,
    entity_data,
    activity_data,
    csv_loading,
    category_data,
    department_idData,
  } = useSelector((state) => state.utility);

  // handle Delete Item
  const [showModalDelete, setShowModalDetele] = React.useState(false);
  const [tempDeleteId, setTempDeleteId] = React.useState(0);

  const handleChangeDepartement = (event) => {
    setDepartementFilter(event.target.value);
  };
  const handleChangeEntity = (event) => {
    setEntityFilter(event.target.value);
  };

  const handleChangeActivity = (event) => {
    setActivityFilter(event.target.value);
  };

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
  };

  const handleChangeCurrency = (event) => {
    setCurrentcyFilter(event.target.value);
  };

  const handleChangeCategory = (event) => {
    setCategoryFilter(event.target.value);
  };

  const handleChangePagination = (event, value) => {
    setPagination(value);
  };

  const handleDetailData = (id) => {
    router.push(`/grpo-detail/grpoId=${id}`);
  };

  // Functiong Request

  const handleGETSyncSAP = () => {
    let params = { is_draft: 1 };

    dispacth(actions.getGRPOSAPdata(access_token, params));
    setButtonSyncClicked(true);
  };

  React.useEffect(() => {
    if (buttonSyncClicked && tempId.length) {
      handleSubmitSAP();
      setButtonSyncClicked(false);
    }
  }, [tempId]);

  const handleDelete = (id) => {
    if (data?.user?.role.length > 0) {
      if (data?.user?.role[0] == 'admin' || data?.user?.role[0] == 'staff') {
        setType('error');
        setShow(true);
        setNotifText('you not have permission to take this action');
      } else {
        setTempDeleteId(id);
        setShowModalDetele(true);
      }
    }
  };

  const handleDeleteGRPO = () => {
    dispacth(actions.deleteGRPO(access_token, tempDeleteId));
  };

  const handleSubmitSAP = () => {
    let data = {
      ids: JSON.stringify(tempId),
    };

    dispacth(actions.publishGRPO(access_token, data));
  };

  const handleExportCsv = () => {
    let params = {
      doc_type: 'GRPO',
      date_start: dateFormater(date.start, 'YYYY-MM-DD'),
      date_end: dateFormater(date.end, 'YYYY-MM-DD'),
      status: status,
      entity_id: entityFilter,
      currency: currencyFilter,
    };

    if (!date.start || !date.end) {
      delete params.date_start;
      delete params.date_end;
    }

    if (status === 'All_Status') delete params.status;

    if (entityFilter === 0) delete params.entity_id;

    if (currencyFilter === 'All Currency') delete params.currency;
    dispacth(actions_2.exportCSV(access_token, params));
  };

  const handleGETGRPOdata = () => {
    let params = {
      date_start: dateFormater(date.start, 'YYYY-MM-DD'),
      date_end: dateFormater(date.end, 'YYYY-MM-DD'),
      status: status,
      department_id: departementFilter,
      entity_id: entityFilter,
      activity: activityFilter,
      page: pagination,
      currency: currencyFilter,
      purchase_type: categoryFilter,
      is_draft: 0,
      order_dir: 'desc',
      order_by: 'id',
      limit: limit,
    };

    if (docNumParam.length) params.doc_num = docNumParam;

    if (!date.start || !date.end) {
      delete params.date_start;
      delete params.date_end;
    }

    if (status === 'All_Status') delete params.status;

    if (departementFilter === 0) delete params.department_id;

    if (entityFilter === 0) delete params.entity_id;

    if (activityFilter === 'All Activity') delete params.activity;

    if (currencyFilter === 'All Currency') delete params.currency;

    if (categoryFilter === 'All Category') delete params.purchase_type;

    dispacth(actions.getGRPOdata(access_token, params));
  };

  React.useEffect(() => {
    dispacth(actions.getSAPnotification(access_token));
  }, []);

  React.useEffect(() => {
    // dispacth(actions_2.getDepartment(access_token));
    if(!Lib.isSuperAdmin(data)) {
      setEntityList(get(data, ['user', 'entity'], []))
    } else {
      dispacth(actions_2.getEntity(access_token));
    }
    dispacth(actions_2.getActivity(access_token));
    dispacth(actions_2.getCategory(access_token));
    dispacth(actions_2.getDepartment(access_token));
  }, []);

  React.useEffect(()=> {
    if(entity_data && Lib.isSuperAdmin(data)){
      setEntityList(entity_data)
    }
  }, [entity_data])

  React.useEffect(() => {
    setRowData(sortingData);
  }, [sortingData]);

  React.useEffect(() => {
    if (entityFilter !== 0) {
      let params = {
        entity_id: entityFilter,
      };
      dispacth(actions_2.getDepartmentById(access_token, params));
    } else {
      dispacth(actions_2.setNullDepartmentById());
    }
  }, [entityFilter]);

  // React.useEffect(() => {
  //   let params = {
  //     is_draft: 0,
  //     order_dir: 'desc',
  //     order_by: 'id',
  //     page: 1,
  //     limit: limit,
  //   };

  //   dispacth(actions.getGRPOdata(access_token, params));
  // }, []);

  React.useEffect(() => {
    handleGETGRPOdata();
  }, [
    date,
    status,
    departementFilter,
    entityFilter,
    pagination,
    activityFilter,
    currencyFilter,
    categoryFilter,
    limit,
  ]);

  React.useEffect(() => {
    let tempArrId = [];

    grpo_sap?.data?.map((data, index) => {
      if (data?.id) tempArrId.push(data.id);
    });

    setTempId(tempArrId);
  }, [grpo_sap]);

  React.useEffect(() => {
    let tempArr = [];
    data_grpo?.data?.map((data, index) => {
      let tempData = {
        id: index,
        grpoId: data?.id,
        noGrpo: data?.doc_num,
        noPO: data?.po?.doc_num,
        name: data?.po?.pr?.purpose_of_request,
        department: data?.po?.pr?.department?.code,
        entity: data?.entity?.code,
        activity:
          data?.po?.pr?.items?.length > 0
            ? data?.po?.pr?.items[0]?.activity_name ?? '-'
            : '-',
        total: data?.doc_total_amount,
        currency: data?.currency,
        status: data?.status,
        date: `${data?.doc_date} ${data?.doc_time}`,
        qr_url: data?.qr_code,
        category: data?.purchase_type?.description ?? '-',

        approval_one: {
          name: data?.approval_transactions[0]?.user?.name,
          position: data?.approval_transactions[0]?.user?.positions?.name,
          approve: data?.approval_transactions[0]?.approve,
          reject: data?.approval_transactions[0]?.reject,
          date: data?.approval_transactions[0]?.created_at,
        },
        approval_two: {
          name: data?.approval_transactions[1]?.user?.name,
          position: data?.approval_transactions[1]?.user?.positions?.name,
          approve: data?.approval_transactions[1]?.approve,
          reject: data?.approval_transactions[1]?.reject,
          date: data?.approval_transactions[1]?.created_at,
        },
        approval_three: {
          name: data?.approval_transactions[2]?.user?.name,
          position: data?.approval_transactions[2]?.user?.positions?.name,
          approve: data?.approval_transactions[2]?.approve,
          reject: data?.approval_transactions[2]?.reject,
          date: data?.approval_transactions[2]?.created_at,
        },
        data,
      };
      tempArr.push(tempData);
    });

    let countPage = Math.ceil(data_grpo?.total / limit);
    setPage(countPage);
    if (pagination > countPage) {
      setPagination(countPage);
    }
    setFromRow(data_grpo?.from);
    setToRow(data_grpo?.to);
    setTotalRow(data_grpo?.total);
    setRowData(tempArr);
  }, [data_grpo]);

  React.useEffect(() => {
    if (del_grpoMessage) {
      if (del_grpoMessage === 'Delete Goods Receipt Purchase Order Success') {
        handleGETGRPOdata();
        setShowModalDetele(false);
      } else if (
        del_grpoMessage !== '' &&
        del_grpoMessage !== 'Delete Goods Receipt Purchase Order Success'
      ) {
        setType('error');
        setShow(true);
        setNotifText(del_grpoMessage);
      }
      dispacth(actions.setBlankDelete());
    }
  }, [del_grpoMessage]);

  React.useEffect(() => {
    if (publish_grpoMessage) {
      if (
        publish_grpoMessage ===
        'Goods receipt purchase order published successfully.'
      ) {
        handleGETGRPOdata();
        dispacth(actions.getSAPnotification(access_token));
      }
      dispacth(actions.setBlankPublish());
    }
  }, [publish_grpoMessage]);

  return (
    <>
      <Grid container md={12} style={{ maxWidth: '100vw' }}>
        <Grid md={12}>
          <CustomizedSnackbars
            show={show}
            handleClose={handleClose}
            message={notifText}
            type={type}
          />
          <ModalDeleteItem
            open={showModalDelete}
            close={() => setShowModalDetele(false)}
            onPress={handleDeleteGRPO}
            isLoading={del_grpoLoading}
          />
          <div className={classes.titleTableContent}>
            <TitleWithSearchNo
              Title={<Typography
                className={classes.titleTable}
                variant="body1"
                component="p"
              >
                GRPO - Good Receipt Puchase Order
              </Typography>}
              docNumParam={docNumParam}
              setDocNumParam={setDocNumParam}
              sendApiQuery={handleGETGRPOdata}
            />

            {false /*get(data, ['user', 'grpo_sync'])*/ ? (
            <div className={classes.contentRigth}>
              <div className={classes.notifContent}>
                {notifLoading ? (
                  <Typography
                    className={classes.notifText}
                    variant="body1"
                    component="p"
                  >
                    Loading Read Data
                  </Typography>
                ) : notfiData && notfiData > 0 ? (
                  <>
                    <img src={TandaSeru} alt="image not found" />
                    <Typography
                      className={classes.notifText}
                      variant="body1"
                      component="p"
                    >
                      {`${notfiData} new data are available`}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography
                      className={classes.notifTextNull}
                      variant="body1"
                      component="p"
                    >
                      No new data
                    </Typography>
                  </>
                )}
              </div>
              <div
                className={
                  notfiData > 0 ? classes.btnAsnyc : classes.btnAsnycDisable
                }
                onClick={notfiData > 0 && !publish_grpoLoading ? handleGETSyncSAP : null}
              >
                <img src={Asnyc} alt="image not found" />
                <Typography
                  className={classes.btnText}
                  variant="body1"
                  component="p"
                >
                  Sync SAP Data
                </Typography>
              </div>
            </div>) : null}
          </div>
        </Grid>
        <Box className={classes.filterContent}>
          <Box className={classes.dateContent}>
            {/* <Box className={classes.wrapperdateContent}> */}
            <div className={classes.dateRangeIcon}>
              <img
                className={classes.iconDate}
                src={DateIcon}
                alt="image not found"
              />
            </div>
            <div className={classes.containerDate}>
              <DatePicker
                onDatePick={(pickedDay) => {
                  setDate(pickedDay);
                }}
                placeholder="Select Date Range"
              />
            </div>
            {/* </Box> */}
          </Box>
          <Box className={classes.filterCategoryContent}>
            <FormControl className={classes.formControl}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={categoryFilter}
                onChange={handleChangeCategory}
                IconComponent={() => <DownArrow />}
              >
                <MenuItem value={'All Category'}>All Category</MenuItem>
                {category_data?.map((cat, index) => (
                  <MenuItem key={index} value={cat.id}>
                    <Typography
                      className={classes.bodyDataText}
                      variant="body1"
                      component="p"
                    >
                      {cat.description}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.filterEntityContent}>
            <FormControl className={classes.formControl}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={entityFilter}
                onChange={handleChangeEntity}
                IconComponent={() => <DownArrow />}
              >
                <MenuItem value={0}>All Entity</MenuItem>
                {entityList?.map((ent, index) => (
                  <MenuItem key={index} value={ent.id}>
                    <Typography
                      className={classes.bodyDataText}
                      variant="body1"
                      component="p"
                    >
                      {ent.code}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.filterDepartmentContent}>
            <FormControl className={classes.formControl}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={departementFilter}
                onChange={handleChangeDepartement}
                IconComponent={() => <DownArrow />}
              >
                <MenuItem value={0}>All Department</MenuItem>
                {department_data?.map((dep, index) => (
                  <MenuItem key={index} value={dep.id}>
                    <Box className={classes.boxDepartment}>
                      <Typography
                        className={classes.bodyDataText}
                        variant="body1"
                        component="p"
                      >
                        {dep.code}
                      </Typography>
                      <Typography
                        className={classes.bodyDataSubText}
                        variant="body1"
                        component="p"
                      >
                        {`- ${dep.name}`}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.filterActivityContent}>
            <FormControl className={classes.formControl}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={activityFilter}
                IconComponent={() => <DownArrow />}
                onChange={handleChangeActivity}
              >
                <MenuItem value={'All Activity'}>All Activity</MenuItem>
                {activity_data?.map((act, index) => (
                  <MenuItem key={index} value={act.activity_name}>
                    <Typography
                      className={classes.bodyDataText}
                      variant="body1"
                      component="p"
                    >
                      {act.activity_name}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.filterCurrencyContent}>
            <FormControl className={classes.formControl}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currencyFilter}
                IconComponent={() => <DownArrow />}
                onChange={handleChangeCurrency}
              >
                <MenuItem value={'All Currency'}>All Currecy</MenuItem>

                <MenuItem value={'IDR'}>
                  <Typography
                    className={classes.bodyDataText}
                    variant="body1"
                    component="p"
                  >
                    IDR
                  </Typography>
                </MenuItem>
                <MenuItem value={'USD'}>
                  <Typography
                    className={classes.bodyDataText}
                    variant="body1"
                    component="p"
                  >
                    USD
                  </Typography>
                </MenuItem>
                <MenuItem value={'CNY'}>
                  <Typography
                    className={classes.bodyDataText}
                    variant="body1"
                    component="p"
                  >
                    CNY
                  </Typography>
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.filterStatusContent}>
            <FormControl className={classes.formControl}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                IconComponent={() => <DownArrow />}
                value={status}
                onChange={handleChangeStatus}
              >
                <MenuItem value={'All_Status'}>All Status</MenuItem>
                <MenuItem value={'open'}>Open</MenuItem>
                <MenuItem value={'need_confirm'}>Confirm Approval</MenuItem>
                <MenuItem value={'waiting'}>Waiting Approval</MenuItem>
                <MenuItem value={'approved'}>Approved</MenuItem>
                <MenuItem value={'rejected'}>Rejected</MenuItem>
                <MenuItem value={'cancelled'}>Cancelled</MenuItem>
                <MenuItem value={'closed'}>Closed</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.filterBlankArea}>{/* black area */}</Box>
          <Box className={classes.exportContent}>
            <div className={classes.btnExportBlue} onClick={handleExportCsv}>
              {csv_loading ? (
                <Box className={classes.loadingExport}>
                  <CircleLoadingSecondary />
                </Box>
              ) : (
                <img src={Export} alt="image not found" />
              )}
              <Typography
                className={classes.btnText}
                variant="body1"
                component="p"
              >
                Export .csv
              </Typography>
            </div>
          </Box>
        </Box>

        <div style={{ width: '100%' }}>
          <Grid md={12}>
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        No.
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'noGrpo', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'noGrpo', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        PO No.
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'noPO', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'noPO', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        Purpose of Request
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'name', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'name', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        Department
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'department', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'department', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        Entity
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'entity', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'entity', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        Activity
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'activity', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'activity', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        Category
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'category', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'category', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        Currency
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'currency', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'currency', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        Total Quantity
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'total', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'total', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        Status
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'status', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'status', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        Date
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'date', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'date', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StickyTableCell className={classes.head}>
                      <StyledTableCell className={classes.head} numeric>
                        Action
                      </StyledTableCell>
                    </StickyTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? null : rowData.length > 0 ? (
                    rowData?.map((row, index) => (
                      <Row
                        key={index}
                        index={index}
                        row={row}
                        handleDetailData={handleDetailData}
                        handleDeleteGRPO={handleDelete}
                      />
                    ))
                  ) : (
                    <TableNoData colspan={12} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {isLoading ? <TabelLoading /> : null}
          </Grid>
        </div>
        {isLoading ? null : rowData?.length > 0 ? (
          <DesktopPagination
            toRow={toRow}
            fromRow={fromRow}
            TotalRow={TotalRow}
            page={page}
            handleChangePagination={handleChangePagination}
            limit={limit}
            handleChangeLimit={handleChangeLimit}
            pagination={pagination}
          />
        ) : null}
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  // cutome table
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  wrapperTableHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  wrapperTableHead: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  iconSort: {
    paddingTop: 15,
    width: 10,
    marginLeft: 5,
  },
  head: {
    backgroundColor: '#fff',
    fontSize: 16,
    lineHeight: '30px',
    fontWeight: 'bold',
    color: '#000000',
    borderBottom: 'none',
    '&:nth-child(1)': {
      minWidth: 80,
      borderBottom: 'none',
    },
    '&:nth-child(2)': {
      minWidth: 120,
    },
    '&:nth-child(3)': {
      minWidth: 250,
    },
    '&:nth-child(4)': {
      minWidth: 130,
    },
    '&:nth-child(5)': {
      minWidth: 140,
    },
    '&:nth-child(6)': {
      minWidth: 140,
    },
    '&:nth-child(7) ': {
      minWidth: 150,
    },
    '&:nth-child(8)': {
      minWidth: 110,
    },
    '&:nth-child(9)': {
      minWidth: 150,
    },
    '&:nth-child(10)': {
      minWidth: 180,
    },
    '&:nth-child(11)': {
      minWidth: 210,
    },
  },
  tableContainer: {
    // maxHeight: '400px',
    Width: '100%',
    '&.MuiTableContainer-root .MuiTableRow-root .MuiTableCell-root.MuiTableCell-head':
      {
        paddingLeft: 8,
      },
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
  },
  cell: {
    minWidth: '100px',
  },
  // end custom table
  titleTableContent: {
    paddingRight: 22,
    paddingLeft: 22,
    paddingTop: 27,
    paddingBottom: 27,
    backgroundColor: '#ffff',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleTable: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  contentRigth: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  btnAsnyc: {
    backgroundColor: '#1B2030',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '14px 20px',
    borderRadius: 8,
    cursor: 'pointer',
  },
  btnExport: {
    backgroundColor: '#127EEE',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '14px 20px',
    borderRadius: 8,
    cursor: 'pointer',
  },
  btnExportBlue: {
    backgroundColor: '#127EEE',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 14px',
    borderRadius: 8,
    cursor: 'pointer',
    maxHeight: 40,
    maxWidth: 154,
  },
  btnText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
    marginLeft: 8,
  },
  notifContent: {
    marginRight: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  notifText: {
    fontWeight: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#1B2030',
    marginLeft: 13,
  },

  iconAction: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 20,
  },
  Action: {
    cursor: 'pointer',
  },
  tableContent: {
    backgroundColor: '#fff',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderBottom: 'none !important',
    position: 'relative',
    maxWidth: 1440,
  },
  tableHead: {
    fontSize: 16,
    lineHeight: '30px',
    color: '#000000',
    fontWeight: 'bold',
    // position: 'relative',
    // maxWidth: 1440,
    overflowX: 'scroll',

    '&.MuiTableRow-root .MuiTableCell-root': {
      borderBottom: 'none',
      fontSize: 16,
      lineHeight: '30px',
      color: '#000000',
      fontWeight: 'bold',
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 16,
      paddingBottom: 16,
    },
    '&.MuiTableRow-root .MuiTableCell-head:nth-last-child(1)': {
      position: '-webkit-sticky',
      position: 'sticky',
      background: '#fff',
      left: 0,
      zIndex: 1,
    },
  },
  // Pagination styling
  descTable: {
    paddingTop: 11.5,
    paddingBottom: 11.5,
  },
  descTableText: {
    color: '#969BAB',
    fontSize: 16,
    lineHeight: '21px',
  },
  paginationContent: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pagination: {
    borderRadius: 0,
    '&.MuiPagination-root .MuiPagination-ul .MuiPaginationItem-root': {
      margin: 0,
    },
    borderRadius: 0,
    '&.MuiPagination-root .MuiPagination-ul .MuiPaginationItem-rounded': {
      borderRadius: 0,
    },
    '&.MuiPagination-root .MuiPagination-ul .MuiButtonBase-root': {
      backgroundColor: '#fff',
      color: '#969BAB',
    },
    '&.MuiPagination-root .MuiPagination-ul .MuiButtonBase-root.Mui-selected': {
      backgroundColor: '#1B2030',
      color: '#fff',
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-child(1)': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-child(1) button': {
      color: '#1B2030',
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-last-child(1)': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-last-child(1) button': {
      color: '#1B2030',
    },
  },

  // Filter Content
  filterContent: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: '#fff',
    borderTop: '1px solid #E7E7E8',
    borderBottom: '1px solid #E7E7E8',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
    // position: 'relative',
  },
  dateContent: {
    width: '100%',
    maxWidth: 235,
    display: 'inline-block',
  },
  wrapperdateContent: {
    paddingRight: 5,
  },
  dateRangeIcon: {
    width: 58,
    height: 40,
    display: 'inline-block',
    backgroundColor: '#E7E7E8',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    verticalAlign: 'text-top',
  },
  iconDate: {
    paddingLeft: 20,
    paddingTop: 10,
  },
  containerDate: {
    display: 'inline-block',
    width: `100%`,
    maxWidth: `calc(100% - 58px)`,
    verticalAlign: 'text-top',
  },
  // styling Form select
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 5,
    paddingBottom: 5,
    width: '100%',
    maxHeight: 40,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      // marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#969BAB',
      paddingRight: 2,
      background: 'none',
    },
  },
  filterCategoryContent: {
    maxWidth: 182,
    width: 182,
    paddingLeft: 5,
    paddingRight: 5,
    display: 'inline-block',
  },
  filterDepartmentContent: {
    width: '100%',
    maxWidth: 182,
    paddingLeft: 5,
    paddingRight: 5,
    display: 'inline-block',
  },
  filterEntityContent: {
    paddingLeft: 5,
    paddingRight: 5,
    width: '100%',
    maxWidth: 150,
    display: 'inline-block',
  },
  filterActivityContent: {
    paddingLeft: 5,
    paddingRight: 5,
    width: '100%',
    maxWidth: 133,
    minWidth: 130,
    display: 'inline-block',
  },
  filterCurrencyContent: {
    paddingLeft: 5,
    paddingRight: 5,
    width: '100%',
    maxWidth: 133,
    minWidth: 130,
    display: 'inline-block',
  },
  filterStatusContent: {
    paddingLeft: 5,
    paddingRight: 5,
    width: '100%',
    maxWidth: 133,
    minWidth: 130,
    display: 'inline-block',
  },
  exportContent: {
    marginRight: 24,
    paddingRight: 20,
    width: '100%',
    maxWidth: 164,
    minWidth: 154,
    position: 'absolute',
    right: 0,
    zIndex: 90,
    backgroundColor: '#fff',
    display: 'inline-block',
  },
  filterBlankArea: {
    minWidth: 150,
    display: 'inline-block',
  },
  // styling menu select
  boxDepartment: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  bodyDataSubText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    marginLeft: 4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  btnAsnycDisable: {
    backgroundColor: '#969BAB',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '14px 20px',
    borderRadius: 8,
  },
}));

const rows = [
  {
    id: 12321,
    grpoId: 54251,
    poId: 23891,
    name: 'Citra Van Titipan, PT',
    department: 'FINC',
    entity: 'DKTI',
    activity: 'TV Commercial',
    category: 'Commodity - Local',
    currency: 'IDR',
    total: '3150000',
    status: 'Open',
    date: '12/07/2021 09:34',
  },
  {
    id: 12322,
    grpoId: 54252,
    poId: 23892,
    name: 'Citra Van Titipan, PT',
    department: 'FINC',
    entity: 'DKTI',
    activity: 'TV Commercial',
    category: 'Commodity - Local',
    currency: 'IDR',
    total: '3150000',
    status: 'Closed',
    date: '12/07/2021 09:34',
  },
  {
    id: 12323,
    grpoId: 54253,
    poId: 23892,
    name: 'Citra Van Titipan, PT',
    department: 'FINC',
    entity: 'DKTI',
    activity: 'TV Commercial',
    category: 'Commodity - Local',
    currency: 'IDR',
    total: '3150000',
    status: 'Reject',
    date: '12/07/2021 09:34',
  },
  {
    id: 12324,
    grpoId: 54254,
    poId: 23892,
    name: 'Citra Van Titipan, PT',
    department: 'FINC',
    entity: 'DKTI',
    activity: 'TV Commercial',
    category: 'Commodity - Local',
    currency: 'IDR',
    total: '3150000',
    status: 'Need approval',
    date: '12/07/2021 09:34',
  },
  {
    id: 12325,
    grpoId: 54255,
    poId: 23892,
    name: 'Citra Van Titipan, PT',
    department: 'FINC',
    entity: 'DKTI',
    activity: 'TV Commercial',
    category: 'Commodity - Local',
    currency: 'USD',
    total: '3150000',
    status: 'Open',
    date: '12/07/2021 09:34',
  },
  {
    id: 12325,
    grpoId: 54255,
    poId: 23892,
    name: 'Citra Van Titipan, PT',
    department: 'FINC',
    entity: 'DKTI',
    activity: 'TV Commercial',
    category: 'Commodity - Local',
    currency: 'USD',
    total: '3150000',
    status: 'Cancelled',
    date: '12/07/2021 09:34',
  },
  {
    id: 12325,
    grpoId: 54255,
    poId: 23892,
    name: 'Citra Van Titipan, PT',
    department: 'FINC',
    entity: 'DKTI',
    activity: 'TV Commercial',
    category: 'Commodity - Local',
    currency: 'USD',
    total: '3150000',
    status: 'Approved',
    date: '12/07/2021 09:34',
  },
];
export default GRPOTableData;
