// Section PR
// get Approval PR
export const APPROVAL_LOADING = 'APPROVAL/APPROVAL_LOADING';
export const APPROVAL_DATA = 'APPROVAL/APPROVAL_DATA';
export const APPROVAL_MESSAGE = 'APPROVAL/APPROVAL_MESSAGE';

// Get approval PR id
export const PR_DETAIL_LOADING = 'APPROVAL/PR_DETAIL_LOADING';
export const PR_DETAIL_MESSAGE = 'APPROVAL/PR_DETAIL_MESSAGE';
export const PR_DETAIL_DATA = 'APPROVAL/PR_DETAIL_DATA';

// update approval pr
export const PR_UPDATE_LOADING = 'APPROVAL/PR_UPDATE_LOADING';
export const PR_UPDATE_MESSAGE = 'APPROVAL/PR_UPDATE_MESSAGE';

// submit approvale pr
export const PR_SUBMIT_LOADING = 'APPROVAL/PR_SUBMIT_LOADING';
export const PR_SUBMIT_MESSAGE = 'APPROVAL/PR_SUBMIT_MESSAGE';

// Section CA
// get approval CA
export const CA_APPROVAL_LOADING = 'APPROVAL/CA_APPROVAL_LOADING';
export const CA_APPROVAL_DATA = 'APPROVAL/CA_APPROVAL_DATA';
export const CA_APPROVAL_MESSAGE = 'APPROVAL/CA_APPROVAL_MESSAGE';

// Get approval CA id
export const CA_DETAIL_LOADING = 'APPROVAL/CA_DETAIL_LOADING';
export const CA_DETAIL_MESSAGE = 'APPROVAL/CA_DETAIL_MESSAGE';
export const CA_DETAIL_DATA = 'APPROVAL/CA_DETAIL_DATA';

// update approval ca
export const CA_UPDATE_LOADING = 'APPROVAL/CA_UPDATE_LOADING';
export const CA_UPDATE_MESSAGE = 'APPROVAL/CA_UPDATE_MESSAGE';

// submit approval ca
export const CA_SUBMIT_LOADING = 'APPROVAL/CA_SUBMIT_LOADING';
export const CA_SUBMIT_MESSAGE = 'APPROVAL/CA_SUBMIT_MESSAGE';

// Section PO
// get approval PO
export const PO_APPROVAL_LOADING = 'APPROVAL/PO_APPROVAL_LOADING';
export const PO_APPROVAL_DATA = 'APPROVAL/PO_APPROVAL_DATA';
export const PO_APPROVAL_MESSAGE = 'APPROVAL/PO_APPROVAL_MESSAGE';

// Get approval po id
export const PO_DETAIL_LOADING = 'APPROVAL/PO_DETAIL_LOADING';
export const PO_DETAIL_MESSAGE = 'APPROVAL/PO_DETAIL_MESSAGE';
export const PO_DETAIL_DATA = 'APPROVAL/PO_DETAIL_DATA';

// update approval po
export const PO_UPDATE_LOADING = 'APPROVAL/PO_UPDATE_LOADING';
export const PO_UPDATE_MESSAGE = 'APPROVAL/PO_UPDATE_MESSAGE';

// submit approvale po
export const PO_SUBMIT_LOADING = 'APPROVAL/PO_SUBMIT_LOADING';
export const PO_SUBMIT_MESSAGE = 'APPROVAL/PO_SUBMIT_MESSAGE';

// Section GRPO
// get approval GRPO
export const GRPO_APPROVAL_LOADING = 'APPROVAL/GRPO_APPROVAL_LOADING';
export const GRPO_APPROVAL_DATA = 'APPROVAL/GRPO_APPROVAL_DATA';
export const GRPO_APPROVAL_MESSAGE = 'APPROVAL/GRPO_APPROVAL_MESSAGE';

// Get approval grpo id
export const GRPO_DETAIL_LOADING = 'APPROVAL/GRPO_DETAIL_LOADING';
export const GRPO_DETAIL_MESSAGE = 'APPROVAL/GRPO_DETAIL_MESSAGE';
export const GRPO_DETAIL_DATA = 'APPROVAL/GRPO_DETAIL_DATA';

// update approval grpo
export const GRPO_UPDATE_LOADING = 'APPROVAL/GRPO_UPDATE_LOADING';
export const GRPO_UPDATE_MESSAGE = 'APPROVAL/GRPO_UPDATE_MESSAGE';

// submit approvale grpo
export const GRPO_SUBMIT_LOADING = 'APPROVAL/GRPO_SUBMIT_LOADING';
export const GRPO_SUBMIT_MESSAGE = 'APPROVAL/GRPO_SUBMIT_MESSAGE';

// Section GENERAL
// get approval GENERAL
export const GENERAL_APPROVAL_LOADING = 'APPROVAL/GENERAL_APPROVAL_LOADING';
export const GENERAL_APPROVAL_DATA = 'APPROVAL/GENERAL_APPROVAL_DATA';
export const GENERAL_APPROVAL_MESSAGE = 'APPROVAL/GENERAL_APPROVAL_MESSAGE';

// Get approval GENERAL id
export const GENERAL_DETAIL_LOADING = 'APPROVAL/GENERAL_DETAIL_LOADING';
export const GENERAL_DETAIL_MESSAGE = 'APPROVAL/GENERAL_DETAIL_MESSAGE';
export const GENERAL_DETAIL_DATA = 'APPROVAL/GENERAL_DETAIL_DATA';

// update approval GENERAL
export const GENERAL_UPDATE_LOADING = 'APPROVAL/GENERAL_UPDATE_LOADING';
export const GENERAL_UPDATE_MESSAGE = 'APPROVAL/GENERAL_UPDATE_MESSAGE';

// submit approvale GENERAL
export const GENERAL_SUBMIT_LOADING = 'APPROVAL/GENERAL_SUBMIT_LOADING';
export const GENERAL_SUBMIT_MESSAGE = 'APPROVAL/GENERAL_SUBMIT_MESSAGE';
