const globalConfig = {
  message: {
    requiredField: `This field is required`,
  },
  cancelButton: {
    //use on purchase order detail, Purchase Requisition detail, grpo  detail, A/P Invoice detail
    hideOnStatus: ["approved"],
    showForLevel: ["superadmin"],
  },
  proforma: {
    checklistExclude: [
      "Berita Acara Serah Terima",
      "Invoice",
      "Bukti Potong Pajak",
    ],
    checklistInclude: [
      "Berita Acara Serah Terima",
      "Invoice",
      "Bukti Potong Pajak",
    ],
    checklistItemExclude: [
      "Berita Acara Serah Terima",
      "Bukti Potong Pajak"
    ],
    ignoreChecklist: ['Other'],
    dkt_checklist: {
      "Berita Acara Serah Terima": {
        id: 1,
        name: "Berita Acara Serah Terima",
        desc: "Berita Acara Serah Terima",
        note: "Dokumen BAST yang sudah ditandatangani kedua pihak",
        file: [],
      },
      Invoice: {
        id: 2,
        name: "Invoice",
        desc: "Invoice",
        file: [],
        type: "value",
      },
      "Bukti Potong Pajak": {
        id: 3,
        name: "Bukti Potong Pajak",
        desc: "Bukti Potong Pajak",
        file: [],
      },
    },
  },
  entity: {
    PDI: {
      image: 'logo-PDI.png'
    },
    YDI: {
      image: 'logo-YDI.png'
    },
    PDA: {
      image: 'logo-PDA.png'
    },
  }
};

export default globalConfig;