import * as types from './types';

// Just Example
export const utilityReducers = (
  state = {
    department_data: null,
    department_loading: false,
    department_idIsloading: false,
    department_idData: null,
    entity_data: null,
    entity_loading: false,
    level_data: null,
    level_loading: null,
    role_loading: false,
    role_data: null,
    position_loading: false,
    position_data: null,
    activity_loading: false,
    activity_data: null,
    category_loading: false,
    category_data: null,
    qrcode_data: null,
    qrcode_loading: false,
    upload_message: '',
    upload_loading: false,
    read_message: '',
    read_loading: false,
    read_data: null,
    read_message_second: '',
    read_loading_second: false,
    read_data_second: null,
    read_message_third: '',
    read_loading_third: false,
    read_data_third: null,
    del_file_isLoading: false,
    del_file_message: '',
    csv_loading: false,
    csv_message: '',
    csv_data: null,
    groupcode_loading: false,
    groupcode_data: null,
    supcode_loading: false,
    supcode_data: null,
    detail_supplierIsLoading: false,
    detail_suppplierData: null,
    bank_loading: false,
    bank_data: null,
    payment_vcIsLoading: false,
    payment_vc: null,
    all_bank_isLoading: false,
    all_bank_data: null,
  },
  action
) => {
  switch (action.type) {
    // Department
    case types.SET_DEPARTMENT_DATA:
      return {
        ...state,
        department_data: action.payload,
      };
    case types.SET_DEPARTMENT_LOADING:
      return {
        ...state,
        department_loading: action.payload,
      };
    // Department by entity id
    case types.SET_DEPARTMENT_ID_LOADING:
      return {
        ...state,
        department_idIsloading: action.payload,
      };
    case types.SET_DEPARTMENT_ID_DATA:
      return {
        ...state,
        department_idData: action.payload,
      };

    // Entity
    case types.SET_ENTITY_DATA:
      return {
        ...state,
        entity_data: action.payload,
      };
    case types.SET_ENTITY_LOADING:
      return {
        ...state,
        entity_loading: action.payload,
      };
    // Role
    case types.SET_ROLE_LOADING:
      return {
        ...state,
        role_loading: action.payload,
      };
    case types.SET_ROLE_DATA:
      return {
        ...state,
        role_data: action.payload,
      };

    // Position
    case types.SET_POSITION_LOADING:
      return {
        ...state,
        position_loading: action.payload,
      };
    case types.SET_POSITION_DATA:
      return {
        ...state,
        position_data: action.payload,
      };
    // Activity
    case types.SET_ACTIVITY_LOADING:
      return {
        ...state,
        activity_loading: action.payload,
      };
    case types.SET_ACTIVITY_DATA:
      return {
        ...state,
        activity_data: action.payload,
      };

    // category
    case types.SET_CATEGORY_LOADING:
      return {
        ...state,
        category_loading: action.payload,
      };
    case types.SET_CATEGORY_DATA:
      return {
        ...state,
        category_data: action.payload,
      };

    // Qrccode
    case types.GET_QRCODE:
      return {
        ...state,
        qrcode_data: action.payload,
      };
    case types.GET_QRCODE_LOADING:
      return {
        ...state,
        qrcode_loading: action.payload,
      };
    // Upload File
    case types.FILE_UPLOAD_LOADING:
      return {
        ...state,
        upload_loading: action.payload,
      };
    case types.FILE_UPLOAD_MESSAGE:
      return {
        ...state,
        upload_message: action.payload,
      };
    case types.FILE_UPLOAD_COUNT_TOTAL:
      return {
        ...state,
        upload_count_total: action.payload,
      };
    case types.FILE_UPLOAD_COUNT_UPLODED:
      return {
        ...state,
        upload_count_uploaded: action.payload,
      };
    case types.FILE_UPLOAD_COUNT_PERCENT:
      return {
        ...state,
        upload_count_percent: action.payload,
      };

    // Read file
    case types.FILE_READ_LOADING:
      return {
        ...state,
        read_loading: action.payload,
      };
    case types.FILE_READ_MESSAGE:
      return {
        ...state,
        read_message: action.payload,
      };
    case types.FILE_READ_DATA:
      return {
        ...state,
        read_data: action.payload,
      };

    // Read file second
    case types.FILE_READ_LOADING_SECOND:
      return {
        ...state,
        read_loading_second: action.payload,
      };
    case types.FILE_READ_MESSAGE_SECOND:
      return {
        ...state,
        read_message_second: action.payload,
      };
    case types.FILE_READ_DATA_SECOND:
      return {
        ...state,
        read_data_second: action.payload,
      };

    // Read file third
    case types.FILE_READ_LOADING_THIRD:
      return {
        ...state,
        read_loading_third: action.payload,
      };
    case types.FILE_READ_MESSAGE_THIRD:
      return {
        ...state,
        read_message_third: action.payload,
      };
    case types.FILE_READ_DATA_THIRD:
      return {
        ...state,
        read_data_third: action.payload,
      };

    // del file
    case types.FILE_DELETE_LOADING:
      return {
        ...state,
        del_file_isLoading: action.payload,
      };
    case types.FILE_DELETE_MESSAGE:
      return {
        ...state,
        del_file_message: action.payload,
      };

    // export CSV
    case types.LOADING_CSV:
      return {
        ...state,
        csv_loading: action.payload,
      };
    case types.MESSAGE_CSV:
      return {
        ...state,
        csv_message: action.payload,
      };
    case types.DATA_CSV:
      return {
        ...state,
        csv_data: action.payload,
      };

    // Group Code
    case types.GROUP_CODE_LOADING:
      return {
        ...state,
        groupcode_loading: action.payload,
      };
    case types.GROUP_CODE_DATA:
      return {
        ...state,
        groupcode_data: action.payload,
      };

    // Suppiler group Code
    case types.SUPPLIER_GROUP_CODE_LOADING:
      return {
        ...state,
        supcode_loading: action.payload,
      };
    case types.SUPPLIER_GROUP_CODE_DATA:
      return {
        ...state,
        supcode_data: action.payload,
      };

    // Suppiler get by id
    case types.SUPPLIER_GET_ID_LOADING:
      return {
        ...state,
        detail_supplierIsLoading: action.payload,
      };
    case types.SUPPLIER_GET_ID_DATA:
      return {
        ...state,
        detail_suppplierData: action.payload,
      };

    // Bank
    case types.BANK_LOADING:
      return {
        ...state,
        bank_loading: action.payload,
      };
    case types.BANK_DATA:
      return {
        ...state,
        bank_data: action.payload,
      };
    // get payment info by vendor code
    case types.GET_PAYMENT_CODE_LOADING:
      return {
        ...state,
        payment_vcIsLoading: action.payload,
      };
    case types.GET_PAYMENT_CODE_DATA:
      return {
        ...state,
        payment_vc: action.payload,
      };

    // get all bank for all user
    case types.GET_ALL_BANK_LOADING:
      return {
        ...state,
        all_bank_isLoading: action.payload,
      };
    case types.GET_ALL_BANK_DATA:
      return {
        ...state,
        all_bank_data: action.payload,
      };

    default:
      return state;
  }
};
