import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextareaAutosize, Box } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { ContentText } from '../../../../libs/Typography';

// images
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: '100%',
    maxWidth: 467,
    borderRadius: 18,
  },
  mainContainer: {
    paddingTop: 44,
    paddingLeft: 31,
    paddingRight: 32,
  },
  inputMenuTextArea: {
    marginTop: 19,
    width: '100% !important',
    paddingLeft: 15,
    paddingTop: 7,
    paddingRight: 21,
    borderRadius: 8,
    border: '1px solid #E7E7E8',
    backgroundColor: '#F8FAFB',
    outline: 'none',
    '&:focus': {
      border: '1.5px solid #F2C94C',
      outline: 'none',
      boxShadow: '0px 0px 0px 4px rgba(242, 201, 76, 0.17);',
      borderRadius: 8,
    },
  },
  mainTitle: {
    fontSize: 26,
    lineHeight: '36px',
    fontWeight: 700,
    textAlign: 'center',
  },
  sectionDesc: {
    marginTop: 55,
    width: '100%',
  },
  label: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 700,
  },
  sectionValue: {
    marginTop: 19,
  },
  inputNum: {
    textAlign: 'right',
    marginTop: 12,
    background: '#F8FAFB',
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    height: 50,
    width: '100%',
    '&:focus': {
      border: '1.5px solid #F2C94C',
      outline: 'none',
      boxShadow: '0px 0px 0px 4px rgba(242, 201, 76, 0.17);',
      borderRadius: 8,
    },
  },
  sizeBorder: {
    marginTop: 63,
    border: ' 1px solid #E7E7E8',
  },
  sectionBtn: {
    paddingTop: 24,
    paddingBottom: 30,
    paddingLeft: 43,
    paddingRight: 43,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnCancel: {
    height: 50,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: 180,
    marginRight: 15,
  },
  cancelText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 400,
  },
  btnSubmit: {
    height: 50,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#1B2030',
    cursor: 'pointer',
    width: 180,
    marginLeft: 15,
  },
  submitText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 700,
    color: '#fff',
  },
}));

export default function ModalBAST({
  open,
  title,
  close,
  onPress,
  onEdit,
  data,
}) {
  const classes = useStyles();
  const [description, setDescription] = React.useState('');
  const [ppn, setPpn] = React.useState(0);
  const [isEdit, setIsEdit] = React.useState(false);

  const handleOnpress = () => {
    if (isEdit) {
      onEdit({ description, ppn });
      setDescription('');
      setPpn(0);
      close();
    } else {
      onPress({ description, ppn });
      setDescription('');
      setPpn(0);
      close();
    }
  };

  const handleCancel = () => {
    setDescription('');
    setPpn(0);
    close();
  };

  React.useEffect(() => {
    if (data) {
      setDescription(data?.description);
      setPpn(data?.value);
      setIsEdit(true);
    }
  }, [data]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={close}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Box className={classes.mainContainer}>
              <ContentText
                content={`${title} Item Nilai BAST`}
                styling={classes.mainTitle}
              />

              <Box className={classes.sectionDesc}>
                <ContentText content={`Description`} styling={classes.label} />
                <TextareaAutosize
                  className={classes.inputMenuTextArea}
                  // aria-label="empty textarea"
                  placeholder="Write text"
                  minRows={5}
                  cols={12}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Box>
              <Box className={classes.sectionValue}>
                <ContentText
                  content={`Nilai Sebelum PPN`}
                  styling={classes.label}
                />
                <input
                  type="number"
                  value={ppn}
                  onChange={(e) => setPpn(e.target.value)}
                  className={classes.inputNum}
                />
              </Box>
            </Box>
            <Box className={classes.sizeBorder} />
            <Box className={classes.sectionBtn}>
              <Box className={classes.btnCancel} onClick={handleCancel}>
                <ContentText content={'Cancel'} styling={classes.cancelText} />
              </Box>
              <Box className={classes.btnSubmit} onClick={handleOnpress}>
                <ContentText content={'Submit'} styling={classes.submitText} />
              </Box>
            </Box>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
