import React from 'react';
import { Container, Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useRouter } from '../../utils/useRouter';

import Layout from '../../components/Layout';
import ResetForm from '../../components/ResetForm';
import Footer from '../../components/Footer';
import FooterLine from '../../components/FooterLine';

// image
import Logo from '../../assets/Images/logo.png';
import BaseImage from '../../assets/Images/bgBase.png';

const ResetPassword = (props) => {
  const classes = useStyles();
  const router = useRouter();
  const { token, email } = router.query;

  return (
    <>
      <Layout>
        <Box className={classes.wrapperBase}>
          <Container maxWidth="sm">
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              className={classes.wrapperContent}
            >
              <img
                className={classes.imageLogo}
                src={Logo}
                alt="image not found"
              />
              <ResetForm token={token} email={email} />
              <Footer />
            </Grid>
          </Container>
        </Box>
        <FooterLine />
      </Layout>
    </>
  );
};

const useStyles = makeStyles({
  root: {
    backgroundColor: 'red',
    color: (props) => props.color,
    height: 100,
    width: 100,
  },
  wrapperContent: {
    paddingTop: 34.9,
    minHeight: '98.4vh',
  },
  imageLogo: {
    width: 136,
    height: 107,
  },
  wrapperBase: {
    backgroundImage: `url(${BaseImage})`,
    backgroundColor: 'red',
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
  },
});

export default ResetPassword;
