import * as type from './types';
import request from '../../utils/request';

export const getCAR = (token, params) => (dispatch) => {
  dispatch({ type: type.CAR_GET_LOADING, payload: true });

  request({
    method: 'get',
    url: `cash-advance`,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: type.CAR_GET_DATA,
        payload: res?.data,
      });
      dispatch({ type: type.CAR_GET_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get car all', err);
      dispatch({ type: type.CAR_GET_LOADING, payload: false });
    });
};

export const saveCAR = (token, data) => (dispatch) => {
  dispatch({ type: type.CAR_SAVE_LOADING, payload: true });

  request({
    method: 'post',
    url: `cash-advance`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: type.CAR_SAVE_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: type.CAR_SAVE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error save car ', err);
      dispatch({ type: type.CAR_SAVE_LOADING, payload: false });
    });
};

export const setBlankSave = () => (dispatch) => {
  dispatch({
    type: type.CAR_SAVE_MESSAGE,
    payload: '',
  });
};

export const getApprovalCAR = (token, params) => (dispatch) => {
  dispatch({ type: type.CAR_APPROVAL_LOADING, payload: true });

  request({
    method: 'get',
    url: `cash-advance/get-approval`,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: type.CAR_APRROVAL_DATA,
        payload: res?.data,
      });
      dispatch({ type: type.CAR_APPROVAL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get approval car ', err);
      dispatch({ type: type.CAR_APPROVAL_LOADING, payload: false });
    });
};

export const getCARDetail = (token, id) => (dispatch) => {
  dispatch({ type: type.CAR_DETAIL_LOADING, payload: true });

  request({
    method: 'get',
    url: `cash-advance/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: type.CAR_DETAIL_DATA,
        payload: res?.data,
      });
      dispatch({ type: type.CAR_DETAIL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get car detail', err);
      dispatch({ type: type.CAR_DETAIL_LOADING, payload: false });
    });
};

// this update for cancel CAR
export const updateCAR = (token, id, data) => (dispatch) => {
  dispatch({ type: type.CAR_UPDATE_LOADING, payload: true });

  request({
    method: 'post',
    url: `cash-advance/${id}/update`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: type.CAR_UPDATE_MESSAGE,
        payload: res?.message,
      });
      dispatch({
        type: type.CAR_UPDATE_DATA,
        payload: res?.data,
      });
      dispatch({ type: type.CAR_UPDATE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error update car ', err);
      dispatch({ type: type.CAR_UPDATE_LOADING, payload: false });
    });
};

export const setBlankUpdate = () => (dispatch) => {
  dispatch({
    type: type.CAR_UPDATE_MESSAGE,
    payload: '',
  });
};

export const deleteCAR = (token, id) => (dispatch) => {
  dispatch({ type: type.CAR_DELETE_LOADING, payload: true });

  request({
    method: 'post',
    url: `cash-advance/${id}/delete`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: type.CAR_DELETE_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: type.CAR_DELETE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error delete car ', err);
      dispatch({ type: type.CAR_DELETE_LOADING, payload: false });
    });
};

export const setBlankDel = () => (dispatch) => {
  dispatch({
    type: type.CAR_DELETE_MESSAGE,
    payload: '',
  });
};
