import React from 'react';
import { Grid, makeStyles, Box, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useRouter } from '../../../../utils/useRouter';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useSelector, useDispatch } from 'react-redux';
import * as settingAction from '../../../../redux/Settings/actions';

// Components
import InputGroup from '../../../inputGroup';
import ModalConfirm from '../ModalComfirm';
import ModalSuccess from '../../../ModalSuccess';
import CustomizedSnackbars from '../../../PopupMessage';
import SortArrow from '../../../SortArrow';
import { CircleLoading } from '../../../Loading/CircleLoading';

// Svg
import { DownArrow } from '../../../../assets/Icons/svg/DownArrow';

const schema = yup.object().shape({
  GroupCodeName: yup.string().required(),
});

const GroupCodeCreate = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispacth = useDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Global Variable
  const { access_token } = useSelector((state) => state.auth);
  const {
    supplier_createIsLoading,
    supplier_createMessage,
    distributor_createIsLoading,
    distributor_createMessage,
  } = useSelector((state) => state.settings);

  const onSubmit = (data) => {
    const { GroupCodeName, Description } = data;

    if (clientCategoty === 'Select') {
      setShow(true);
      setType('error');
      setNotifText('Please select Client Category');
    } else {
      let sendData = {
        name: GroupCodeName,
      };

      setTempSendData(sendData);
      setShowModalConfirm(true);
    }
  };

  // fot initial state
  const [clientCategoty, setClientCategory] = React.useState('Select');

  const [dataInput, setDataInput] = React.useState(null);
  const [tempSendData, setTempSendData] = React.useState(null);

  const handleChangeClientCategory = (event) => {
    setClientCategory(event.target.value);
  };

  // state for modal
  const [showModalConfirm, setShowModalConfirm] = React.useState(false);
  const [showModalSuccess, setShowModalSuccess] = React.useState(false);

  // handle message
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };
  //  end handle Message

  // function register
  const handleCreateGroupCode = () => {
    if (clientCategoty === 'distributor') {
      dispacth(settingAction.createDistributor(access_token, tempSendData));
    } else {
      dispacth(settingAction.createSupplier(access_token, tempSendData));
    }
  };

  // for wacth value inside useform
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      // console.log(value, name, type)
      setDataInput(value)
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  React.useEffect(() => {
    if (distributor_createMessage) {
      if (distributor_createMessage === 'Save distributor group code Success') {
        setShowModalSuccess(true);
      } else if (
        distributor_createMessage !== '' &&
        distributor_createMessage !== 'Save distributor group code Success'
      ) {
        setShow(true);
        setType('error');
        setNotifText(distributor_createMessage);
      }
      dispacth(settingAction.setBlankCreateDistributor());
    }
  }, [distributor_createMessage]);

  React.useEffect(() => {
    if (supplier_createMessage) {
      if (supplier_createMessage === 'Save Supplier group code Success') {
        setShowModalSuccess(true);
      } else if (
        supplier_createMessage !== '' &&
        supplier_createMessage !== 'Save Supplier group code Success'
      ) {
        setShow(true);
        setType('error');
        setNotifText(supplier_createMessage);
      }
      dispacth(settingAction.setBlankCreateSupplier());
    }
  }, [supplier_createMessage]);

  return (
    <>
      <CustomizedSnackbars
        show={show}
        handleClose={handleClose}
        message={notifText}
        type={type}
      />
      <ModalConfirm
        open={showModalConfirm}
        handleCloseModalConfirm={() => setShowModalConfirm(false)}
        onPress={handleCreateGroupCode}
        isLoading={
          distributor_createIsLoading || supplier_createIsLoading ? true : false
        }
      />
      <ModalSuccess
        open={showModalSuccess}
        onPress={() => router.push('/master-data')}
      />
      <Grid md={12} className={classes.wrapperForm}>
        <Box className={classes.sectionHeader}>
          <Typography className={classes.headerTitle}>
            Create Group Code
          </Typography>
        </Box>
        <Box className={classes.sizeBorder} />
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Grid md={12} className={classes.wrapperBody}>
            <InputGroup
              label="GroupCodeName"
              placeholder="input Text"
              register={register}
              required
              type="text"
              isRequire={true}
            />
            <p className={classes.errorText}>{errors.GroupCodeName?.message}</p>
            <Box className={classes.sizeTop} />
            <Box>
              <Typography className={classes.labelEntity}>
                Client Category*
              </Typography>
              <FormControl className={classes.formControl}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={clientCategoty}
                  onChange={handleChangeClientCategory}
                  IconComponent={() => <DownArrow />}
                >
                  <MenuItem value={'Select'}>Select</MenuItem>
                  <MenuItem value={'distributor'}>
                    <Typography className={classes.bodyDataText}>
                      Distributor
                    </Typography>
                  </MenuItem>
                  <MenuItem value={'supplier'}>
                    <Typography className={classes.bodyDataText}>
                      Supplier
                    </Typography>
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box className={classes.sizeTop} />
            <InputGroup
              label="Description"
              placeholder="input Text"
              register={register}
              required
              type="text"
              inputProps={{ maxLength: 255 }}
            />
            <p className={classes.errorText}>{errors.Description?.message}</p>
          </Grid>
          <Box className={classes.sizeBorder} />
          <Grid md={12} className={classes.wrapperFooter}>
            <input
              className={
                dataInput?.GroupCodeName
                  ? classes.submitBtn
                  : classes.submitBtnFalse
              }
              type="submit"
              value="Submit"
            />
            {supplier_createIsLoading || distributor_createIsLoading ? (
              <Box className={classes.loadingContent}>
                <CircleLoading />
              </Box>
            ) : (
              <Box
                className={classes.btnCancel}
                onClick={() => router.push('/master-data')}
              >
                <Typography>Cancel</Typography>
              </Box>
            )}
          </Grid>
        </form>
      </Grid>
    </>
  );
};
const useStyles = makeStyles({
  wrapperForm: {
    width: '100%',
    maxWidth: 605,
    backgroundColor: '#fff',
    margin: 'auto',
    borderRadius: 8,
  },
  sectionHeader: {
    padding: 20,
  },
  headerTitle: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  sizeBorder: {
    borderBottom: '1px solid #E7E7E8',
  },
  wrapperBody: {
    padding: 20,
  },
  sizeTop: {
    marginTop: 20,
  },
  wrapperFooter: {
    padding: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  submitBtn: {
    width: 193,
    height: 50,
    borderRadius: 8,
    backgroundColor: '#1B2030',
    color: '#fff',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  submitBtnFalse: {
    width: 193,
    height: 50,
    borderRadius: 8,
    backgroundColor: '#969BAB',
    color: '#fff',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  btnCancel: {
    marginLeft: 20,
    width: 193,
    height: 50,
    borderRadius: 8,
    backgroundColor: '#fff',
    color: '#1B2030',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  // styling Form select
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 5,
    paddingBottom: 5,
    // maxWidth: 220,
    width: '100%',
    // minWidth: 160,
    height: 50,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      // marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#969BAB',
      paddingRight: 2,
      background: 'none',
    },
  },
  labelEntity: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
    marginBottom: 12,
  },
  errorText: {
    color: 'red',
  },
});

export default GroupCodeCreate;
