import React from 'react';
import { makeStyles, Box, Grid, Typography } from '@material-ui/core';
import { getDeviceType } from '../../utils/get_device_type';
import { useSelector, useDispatch } from 'react-redux';
import * as actions_2 from '../../redux/Utility/actions';
import { useRouter } from '../../utils/useRouter';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { get } from 'lodash';

import LayoutDashboard from '../../components/LayoutDashboard';
import ApprovalPO from '../../components/ApprovalPO';
import ApprovalPR from '../../components/ApprovalPR';
import ApprovalGRPO from '../../components/ApprovalGrpo';
import ApprovalAP from '../../components/ApprovalAP';
import ApprovalAPDP from '../../components/ApprovalAPDP';
import ApprovalCA from '../../components/ApprovalCA';
import request from 'src/utils/request';

//css
import './style.css'

// Svg
import { DownArrow } from '../../assets/Icons/svg/DownArrow';

const Approval = () => {
  const classes = useStyles();
  const dispacth = useDispatch();
  const router = useRouter();

  // Global Variable
  const { access_token, data } = useSelector((state) => state.auth);
  const { entity_data, department_data } = useSelector((state) => state.utility);

  const [index, setIndex] = React.useState(1);
  const [summary, setSummary] = React.useState({});

  const [deviceType, setDeviceType] = React.useState('');

  const handleChangeIndex = (event) => {
    setIndex(event.target.value);
  };

  const getSummary = () => {
    request({
      method: 'get',
      url: 'approval-transaction/myapproval/summary',
      params: {},
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }).then((res) => {
      setSummary(res.data)
    }).catch(err => console.log(err))
  }

  React.useEffect(() => {
    const deviceType = getDeviceType();

    setDeviceType(deviceType);
  });

  React.useEffect(() => {
    getSummary()
    dispacth(actions_2.getEntity(access_token));
    dispacth(actions_2.getDepartment(access_token));
  }, []);

  const _renderTabContent = (index) => {
    if (index === 2) {
      return (
        <ApprovalPO
          entity_data={entity_data}
          deviceType={deviceType}
          access_token={access_token}
          router={router}
          userData={data}
        />
      );
    } else if (index === 3) {
      return (
        <ApprovalGRPO
          entity_data={entity_data}
          deviceType={deviceType}
          access_token={access_token}
          router={router}
          userData={data}
        />
      );
    } else if (index === 4) {
      return (
        <ApprovalAP
          entity_data={entity_data}
          deviceType={deviceType}
          access_token={access_token}
          router={router}
          userData={data}
        />
      );
    } else if (index === 5) {
      return (
        <ApprovalAPDP
          entity_data={entity_data}
          deviceType={deviceType}
          access_token={access_token}
          router={router}
          userData={data}
        />
      );
    } else if (index === 6) {
      return (
        <ApprovalCA
          entity_data={entity_data}
          deviceType={deviceType}
          access_token={access_token}
          router={router}
          userData={data}
        />
      );
    } else {
      return (
        <ApprovalPR
          entity_data={entity_data}
          deviceType={deviceType}
          access_token={access_token}
          router={router}
          userData={data}
        />
      );
    }
  };

  const _renderDesktop = () => {
    return (
      <>
        <Box className={classes.tabContent}>
          <Box className={classes.pointer} onClick={() => setIndex(1)}>
            <Typography
              className={
                index === 1 ? classes.tabTitleActive : classes.tabTitle
              }
              variant="body1"
              component="p"
            >
              Purchase Requisition (PR) <span className={get(summary, ['pr'], 0) ? 'badge-count' : 'zero-count-badge'}>{get(summary, ['pr'], 0)}</span>
            </Typography>
            
          </Box>
          <Box className={classes.pointer} onClick={() => setIndex(2)}>
            <Typography
              className={
                index === 2 ? classes.tabTitleActive : classes.tabTitle
              }
              variant="body1"
              component="p"
            >
              Purchase Order (PO) <span className={get(summary, ['po'], 0) ? 'badge-count' : 'zero-count-badge'}>{get(summary, ['po'], 0)}</span>
            </Typography>
          </Box>
          <Box className={classes.pointer} onClick={() => setIndex(3)}>
            <Typography
              className={
                index === 3 ? classes.tabTitleActive : classes.tabTitle
              }
              variant="body1"
              component="p"
            >
              Good Receipt (GRPO) <span className={get(summary, ['grpo'], 0) ? 'badge-count' : 'zero-count-badge'}>{get(summary, ['grpo'], 0)}</span>
            </Typography>
          </Box>
          <Box className={classes.pointer} onClick={() => setIndex(4)}>
            <Typography
              className={
                index === 4 ? classes.tabTitleActive : classes.tabTitle
              }
              variant="body1"
              component="p"
            >
              AP Invoice <span className={get(summary, ['ap_invoice'], 0) ? 'badge-count' : 'zero-count-badge'}>{get(summary, ['ap_invoice'], 0)}</span>
            </Typography>
          </Box>
          <Box className={classes.pointer} onClick={() => setIndex(5)}>
            <Typography
              className={
                index === 5 ? classes.tabTitleActive : classes.tabTitle
              }
              variant="body1"
              component="p"
            >
              APDP <span className={get(summary, ['ap_dp'], 0) ? 'badge-count' : 'zero-count-badge'}>{get(summary, ['ap_dp'], 0)}</span>
            </Typography>
          </Box>
          <Box className={classes.pointer} onClick={() => setIndex(6)}>
            <Typography
              className={
                index === 6 ? classes.tabTitleActive : classes.tabTitle
              }
              variant="body1"
              component="p"
            >
              CA Report <span className={get(summary, ['ca'], 0) ? 'badge-count' : 'zero-count-badge'}>{get(summary, ['ca'], 0)}</span>
            </Typography>
          </Box>
        </Box>
      </>
    );
  };

  const _renderMobile = () => {
    return (
      <>
        <Grid xs={12} className={classes.wrapperFilter}>
          <FormControl className={classes.formControl}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={index}
              onChange={handleChangeIndex}
              IconComponent={() => <DownArrow />}
            >
              <MenuItem value={1}>Purchase Requisition (PR)</MenuItem>
              <MenuItem value={2}>Purchase Order (PO)</MenuItem>
              <MenuItem value={3}>Good Receipt (GRPO)</MenuItem>
              <MenuItem value={4}>AP Invoice</MenuItem>
              <MenuItem value={5}>APDP</MenuItem>
              <MenuItem value={6}>CA Report</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </>
    );
  };

  return (
    <LayoutDashboard page="My Approval" deviceType={deviceType}>
      <div className={classes.pageContainer}>
        <Grid container>
          <Grid xs={12} md={12}>
            <Box className={classes.titleHeader}>
              <Typography
                className={classes.titleTable}
                variant="body1"
                component="p"
              >
                Approval List
              </Typography>
            </Box>
            {deviceType === 'mobile' ? _renderMobile() : _renderDesktop()}
            {_renderTabContent(index)}
          </Grid>
        </Grid>
      </div>
    </LayoutDashboard>
  );
};

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    marginBottom: 30,
    paddingTop: 97 - 24,
    minHeight: '84vh',
  },
  sizebox: {
    marginTop: 20,
  },
  titleHeader: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 16,
    borderBottom: '1px solid #E7E7E8',
    backgroundColor: '#fff',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  titleTable: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  tabContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderBottom: '1px solid #E7E7E8',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      overflow: 'auto',
      alignItems: 'center',
    },
  },
  tabTitleActive: {
    paddingTop: 20,
    paddingBottom: 23,
    paddingRight: 20,
    paddingLeft: 20,
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#127EEE',
    borderBottom: '3px solid #127EEE',
  },
  tabTitleActiveMobile: {
    paddingTop: 20,
    paddingBottom: 23,
    paddingRight: 20,
    paddingLeft: 20,
    fontSize: 16,
    minWidth: 250,
    textAlign: 'center',
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#127EEE',
    borderBottom: '3px solid #127EEE',
  },
  tabTitle: {
    paddingTop: 20,
    paddingBottom: 23,
    paddingRight: 20,
    paddingLeft: 20,
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  tabTitleMobile: {
    paddingTop: 20,
    paddingBottom: 23,
    paddingRight: 20,
    paddingLeft: 20,
    fontSize: 16,
    minWidth: 250,
    textAlign: 'center',
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  pointer: {
    cursor: 'pointer',
  },
  wrapperFilter: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 15,
    paddingBottom: 15,
    background: '#fff',
    borderBottom: '1px solid #E7E7E8',
  },
  // select
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 5,
    paddingBottom: 5,
    width: '100%',
    maxHeight: 40,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      // marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#127EEE',
      paddingRight: 2,
      background: 'none',
    },
  },
}));

export default Approval;
