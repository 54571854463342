import * as types from './types';

// Just Example
export const apdpReducers = (
  state = {
    notifLoading: false,
    notifMesage: '',
    notfiData: null,
    apdp_dashboardNotif: null,
    data_apdp: null,
    isLoading: false,
    message: '',
    apdp_detail: null,
    detail_message: '',
    detail_isLoading: false,
    apdp_sap: null,
    apdp_sapLoading: false,
    apdp_sapMessage: '',
    del_apdpLoading: false,
    del_apdpMessage: '',
    publish_apdpLoading: false,
    publish_apdpMessage: '',
    cancel_apdpLoading: false,
    cancel_apdpMessage: '',
    assignment_apdploading: false,
    assigment_apdpmessage: '',
  },
  action
) => {
  switch (action.type) {
    // notif APDP
    case types.APDP_NOTIF_LOADING:
      return { ...state, notifLoading: action.payload };

    case types.APDP_NOTIF_MESSAGE:
      return { ...state, notifMesage: action.payload };

    case types.APDP_NOTIF_DATA:
      return { ...state, notfiData: action.payload };

    case types.APDP_NOTIF_DASHBOARD_DATA:
      return { ...state, apdp_dashboardNotif: action.payload };

    // apdp data
    case types.APDP_SET_DATA:
      return { ...state, data_apdp: action.payload };

    case types.APDP_DATA_MESSAGE:
      return { ...state, message: action.payload };

    case types.APDP_DATA_LOADING:
      return { ...state, isLoading: action.payload };

    // detail apdp
    case types.APDP_DETAIL_DATA:
      return { ...state, apdp_detail: action.payload };

    case types.APDP_DETAIL_MESSAGE:
      return { ...state, detail_message: action.payload };

    case types.APDP_DETAIL_LOADING:
      return { ...state, detail_isLoading: action.payload };

    // Sync SAP apdp
    case types.APDP_SYNC_DATA:
      return { ...state, apdp_sap: action.payload };

    case types.APDP_SYNC_MESSAGE:
      return { ...state, apdp_sapMessage: action.payload };

    case types.APDP_SYNC_LOADING:
      return { ...state, apdp_sapLoading: action.payload };

    // delete apdp
    case types.APDP_DELETE_LOADING:
      return { ...state, del_apdpLoading: action.payload };

    case types.APDP_DELETE_MESSAGE:
      return { ...state, del_apdpMessage: action.payload };

    // publish apdp
    case types.APDP_PUBLISH_LOADING:
      return { ...state, publish_apdpLoading: action.payload };

    case types.APDP_PUBLISH_MESSAGE:
      return { ...state, publish_apdpMessage: action.payload };

    // Cancel apdp
    case types.APDP_CANCEL_LOADING:
      return { ...state, cancel_apdpLoading: action.payload };

    case types.APDP_CANCEL_MESSAGE:
      return { ...state, cancel_apdpMessage: action.payload };

    // Assignment APDP
    case types.APDP_ASSIGNMENT_LOADING:
      return { ...state, assignment_apdploading: action.payload };

    case types.APDP_ASSIGNMENT_MESSAGE:
      return { ...state, assigment_apdpmessage: action.payload };

    default:
      return state;
  }
};
