// internal
export const SET_INTERNAL_USER = 'USER_MANAGEMENT/SET_INTERNAL_USER';
export const SET_INTERNAL_LOADING = 'USER_MANAGEMENT/SET_INTERNAL_LOADING';
export const SET_INTERNAL_MESSAGE = 'USER_MANAGEMENT/SET_INTERNAL_MESSAGE';

export const SET_INTERNAL_CREATE = 'USER_MANAGEMENT/SET_INTERNAL_CREATE';
export const SET_INTERNAL_CREATE_LOADING =
  'USER_MANAGEMENT/SET_INTERNAL_CREATE_LOADING';
export const SET_INTERNAL_CREATE_DATA =
  'USER_MANAGEMENT/SET_INTERNAL_CREATE_DATA';
export const SET_INTERNAL_CREATE_MESSAGE =
  'USER_MANAGEMENT/SET_INTERNAL_CREATE_MESSAGE';

// Admin
export const SET_ADMIN_USER = 'USER_MANAGEMENT/SET_ADMIN_USER';
export const SET_ADMIN_LOADING = 'USER_MANAGEMENT/SET_ADMIN_LOADING';
export const SET_ADMIN_CREATE_LOADING =
  'USER_MANAGEMENT/SET_ADMIN_CREATE_LOADING';
export const SET_ADMIN_CREATE_DATA = 'USER_MANAGEMENT/SET_ADMIN_CREATE_DATA';
export const SET_ADMIN_MESSAGE = 'USER_MANAGEMENT/SET_ADMIN_MESSAGE';
export const SET_ADMIN_PROFILE = 'USER_MANAGEMENT/SET_ADMIN_PROFILE';
export const SET_ADMIN_PROFILE_MESSAGE =
  'USER_MANAGEMENT/SET_ADMIN_PROFILE_MESSAGE';
export const SET_ADMIN_PROFILE_LOADING =
  'USER_MANAGEMENT/SET_ADMIN_PROFILE_LOADING';

// General
export const MESSAGE = 'USER_MANAGEMENT/MESSAGE';
export const LOADING = 'USER_MANAGEMENT/LOADING';

// External Create
export const SET_EXTERNAL_DATA = 'USER_MANAGEMENT/SET_EXTERNAL_DATA';
export const SET_EXTERNAL_LOADING = 'USER_MANAGEMENT/SET_EXTERNAL_LOADING';
export const SET_EXTERNAL_MESSAGE = 'USER_MANAGEMENT/SET_EXTERNAL_MESSAGE';

// get external user
export const GET_EXTERNAL_USER_DATA = 'USER_MANAGEMENT/GET_EXTERNAL_USER_DATA';
export const GET_EXTERNAL_USER_LOADING =
  'USER_MANAGEMENT/GET_EXTERNAL_USER_LOADING';
export const GET_EXTERNAL_USER_MESSAGE =
  'USER_MANAGEMENT/GET_EXTERNAL_USER_MESSAGE';

// delete external
export const DEL_EXTERNAL_USER_MESSAGE =
  'USER_MANAGEMENT/DEL_EXTERNAL_USER_MESSAGE';
export const DEL_EXTERNAL_USER_LOADING =
  'USER_MANAGEMENT/DEL_EXTERNAL_USER_LOADING';

// get profile external
export const GET_EXTERNAL_DETAIL = 'USER_MANAGEMENT/GET_EXTERNAL_USER_DETAIL';
export const GET_EXTERNAL_DETAIL_LOADING =
  'USER_MANAGEMENT/GET_EXTERNAL_USER_LOADING';
export const GET_EXTERNAL_DETAIL_MESSAGE =
  'USER_MANAGEMENT/GET_EXTERNAL_USER_MESSAGE';

// get all user internal for approval list
export const GET_ALL_INTERNAL_DATA = 'USER_MANAGEMENT/GET_ALL_INTERNAL_DATA';
export const GET_ALL_INTERNAL_LOADING =
  'USER_MANAGEMENT/GET_ALL_INTERNAL_LOADING';
