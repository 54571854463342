import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import PrivateExternalRoute from './componentsExternal/PrivateExternalRoute';
// import FinancePrivateRoute from './components/PrivateRoute/FinanceRouter';
// Internal and admin
import Signin from './pages/Signin';
import ForgotPassword from './pages/ForgotPassword';
import Register from './pages/Register';
import BAST from './pages/BAST';
import ResetPassword from './pages/ResetPassword';
import Verify from './pages/Verify';
import DashboardHome from './pages/DashboardHome';
import PurchaseRequistor from './pages/PurchaseRequistor';
import PurchaseRequistorDetail from './pages/PurchaseRequistorDetail';
import PurchaseOrder from './pages/PurchaseOrder';
import PurchaseOrderDetail from './pages/PurchaseOrderDetail';
import Approval from './pages/Approval';
import UserManagement from './pages/UserManagement';
import AdminCreatePage from './pages/AdminCreate';
import AdminEditPage from './pages/AdminEdit';
import InternalCreatePage from './pages/InternalCreate';
import InternalEditPage from './pages/InternalEdit';
import ExternalCreatePage from './pages/ExternalCreate';
import ExternalEditPage from './pages/ExternalEdit';
import APInvoice from './pages/APInvoice';
import APInvoiceDetail from './pages/APInvoiceDetail';
import GRPOPage from './pages/GRPO';
import GRPODetail from './pages/GRPODetail';
import OutgoingPayemnt from './pages/OutgoingPayment';
import OutgoingPaymentDetail from './pages/OutgoingPaymentDetail';
import ClientVendor from './pages/ClientVendor';
import ClientVendorCreate from './pages/ClientVendorCreate';
import ClientVendorEdit from './pages/ClientVendorEdit';
import APDPpage from './pages/APDP';
import APDPDetail from './pages/APDPDetail';
import ProformaInvoice from './pages/ProformaInvoice';
import ProformaInvoiceCreate from './pages/ProformaInvoiceCreate';
import ProformaInvoiceDetail from './pages/ProformaInvoiceDetail';
import CashReport from './pages/CashReport';
import CashReportCreate from './pages/CashReportCreate';
import CashReportDetail from './pages/CashReportDetail';
import ReportPage from './pages/ReportPage';
import OrganizeManagement from './pages/OrganizeManagement';
import EntityCreatePage from './pages/EntityCreatePage';
import EntityEditPage from './pages/EntityEditPage';
import DepartmentCreatePage from './pages/DepartmentCreatePage';
import DepartmentEditPage from './pages/DepartmentEditPage';
import PositionCreatePage from './pages/PositionCreatePage';
import PositionEditPage from './pages/PositionEditPage';
import LevelCreatePage from './pages/LevelCreatePage';
import LevelEditPage from './pages/LevelEditPage';
import MasterDataManagement from './pages/MasterDataManagement';
import ChecklistCreatePage from './pages/ChecklistCreatePage';
import ChecklistEditPage from './pages/ChecklistEditPage';
import ActivityCreatePage from './pages/ActivityCreatePage';
import ActivityEditPage from './pages/ActivityEditPage';
import ReportEditPage from './pages/ReportEditPage';
import GroupCodeCreatePage from './pages/GroupCodeCreatePage';
import GroupCodeEditPage from './pages/GroupCodeEditPage';
import ClientCategoryCreatePage from './pages/ClientCategoryCreatePage';
import ClientCategoryEditPage from './pages/ClientCategoryEditPage';
import PurchaseTypeCreatePage from './pages/PurchaseTypeCreatePage';
import PurchaseTypeEditPage from './pages/PurchaseTypeEditPage';
// external
import DashboardExternalHome from './pagesExternal/DashboardHome';
import PurchaseOrderExternal from './pagesExternal/PurchaseOrder';
import PurhcaseOrderDetailExternal from './pagesExternal/PurchaseOrderDetail';
import InvoiceExternal from './pagesExternal/Invoice';
import InvoiceCreateExternal from './pagesExternal/InvoiceCreate';
import InvoiceDetailExternal from './pagesExternal/InvoiceDetail';
import ClaimExternal from './pagesExternal/Claims';
import AsyncStorage from '@callstack/async-storage';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from './redux/Auth/actions';

function App() {
  const dispatch = useDispatch();

  React.useEffect(async () => {
 
    if (localStorage.token) {
    
      // let token = await AsyncStorage.getItem('token');
    
      dispatch(actions.cekTokenProfile(localStorage.token));
      
    } else {
      // console.log('token gk ada');
    }
  }, []);

  // for automatic logout when browser closed
  // React.useEffect(() => {
  //   window.addEventListener('beforeunload', handleTabClosing);
  //   return () => {
  //     window.removeEventListener('beforeunload', handleTabClosing);
  //   };
  // });

  // const handleTabClosing = async (e) => {
  //   await localStorage.clear();
  //   dispatch(actions.logout(access_token));
  // };

  return (
    <>
      <Router>
        <Switch>
          <PrivateRoute path="/" exact component={DashboardHome} />
          {/* PR */}
          <PrivateRoute
            path="/purchase-requistor"
            component={PurchaseRequistor}
          />
          <PrivateRoute
            path="/purchase-requistor-detail/prId=:id"
            component={PurchaseRequistorDetail}
          />
          {/* PO */}
          <PrivateRoute path="/purchase-order" component={PurchaseOrder} />
          <PrivateRoute
            path="/purchase-order-detail/poId=:id"
            component={PurchaseOrderDetail}
          />
          {/* Approval */}
          <PrivateRoute path="/approval" component={Approval} />
          {/* User management */}
          <PrivateRoute path="/user-management" component={UserManagement} />
          <PrivateRoute path="/create-admin" component={AdminCreatePage} />
          <PrivateRoute path="/edit-admin/:id" component={AdminEditPage} />
          <PrivateRoute
            path="/create-internal"
            component={InternalCreatePage}
          />
          <PrivateRoute
            path="/edit-internal/:id"
            component={InternalEditPage}
          />
          <PrivateRoute
            path="/create-external"
            component={ExternalCreatePage}
          />
          <PrivateRoute
            path="/edit-external/:category/:id"
            component={ExternalEditPage}
          />

          {/* Proforma Invoice */}
          <PrivateRoute path="/proforma-invoice" component={ProformaInvoice} />
          <PrivateRoute
            path="/proforma-invoice-create"
            component={ProformaInvoiceCreate}
          />
          <PrivateRoute
            path="/proforma-invoice-detail/proforma=:id"
            component={ProformaInvoiceDetail}
          />

          {/* GRPO */}
          <PrivateRoute path="/grpo" component={GRPOPage} />
          <PrivateRoute path="/grpo-detail/grpoId=:id" component={GRPODetail} />
          {/* AP Ivoice */}
          <PrivateRoute path="/ap-invoice" component={APInvoice} />
          <PrivateRoute
            path="/ap-invoice-detail/apInvoice=:id"
            component={APInvoiceDetail}
          />
          {/* AP DP Page */}
          <PrivateRoute path="/ap-downpayment" component={APDPpage} />
          <PrivateRoute
            path="/ap-downpayment-detail/apdp=:id"
            component={APDPDetail}
          />
          {/* Outgoing Payemnt */}
          <PrivateRoute
            path="/outgoing-payment"
            component={OutgoingPayemnt}
          />
          <PrivateRoute
            path="/outgoing-payment-detail/opId=:id"
            component={OutgoingPaymentDetail}
          />
          {/* Cash Advance Report */}
          <PrivateRoute path="/cash-report" component={CashReport} />
          <PrivateRoute
            path="/cash-report-create"
            component={CashReportCreate}
          />
          <PrivateRoute
            path="/cash-report-detail/cash=:id"
            component={CashReportDetail}
          />

          {/*Client Vendor  */}
          <PrivateRoute path="/client-vendor" component={ClientVendor} />
          <PrivateRoute
            path="/client-vendor-create"
            component={ClientVendorCreate}
          />
          <PrivateRoute
            path="/client-vendor-edit/vendor=:id"
            component={ClientVendorEdit}
          />
          {/* Report  */}
          <PrivateRoute path="/report" component={ReportPage} />

          {/* Organize Management */}
          <PrivateRoute exact path="/organize" component={OrganizeManagement} />
          <PrivateRoute
            path="/organize/create-entity"
            component={EntityCreatePage}
          />
          <PrivateRoute
            path="/organize/entity/:id/edit"
            component={EntityEditPage}
          />
          <PrivateRoute
            path="/organize/create-department"
            component={DepartmentCreatePage}
          />
          <PrivateRoute
            path="/organize/department/:id/edit"
            component={DepartmentEditPage}
          />
          <PrivateRoute
            path="/organize/create-position"
            component={PositionCreatePage}
          />
          <PrivateRoute
            path="/organize/position/:id/edit"
            component={PositionEditPage}
          />
          <PrivateRoute
            path="/organize/create-level"
            component={LevelCreatePage}
          />
          <PrivateRoute
            path="/organize/level/:id/edit"
            component={LevelEditPage}
          />

          {/* Master data */}
          <PrivateRoute
            exact
            path="/master-data"
            component={MasterDataManagement}
          />
          <PrivateRoute
            path="/master-data/create-checklist"
            component={ChecklistCreatePage}
          />
          <PrivateRoute
            path="/master-data/checklist/:id/edit"
            component={ChecklistEditPage}
          />
          <PrivateRoute
            path="/master-data/create-activity"
            component={ActivityCreatePage}
          />
          <PrivateRoute
            path="/master-data/activity/:id/edit"
            component={ActivityEditPage}
          />
          <PrivateRoute
            path="/master-data/report/:id/edit"
            component={ReportEditPage}
          />
          <PrivateRoute
            path="/master-data/create-groupCode"
            component={GroupCodeCreatePage}
          />
          <PrivateRoute
            path="/master-data/groupCode/:id/:category/edit"
            component={GroupCodeEditPage}
          />
          <PrivateRoute
            path="/master-data/create-client-category"
            component={ClientCategoryCreatePage}
          />

          <PrivateRoute
            path="/master-data/client-category/:id/edit"
            component={ClientCategoryEditPage}
          />
          <PrivateRoute
            path="/master-data/create-purchase-type"
            component={PurchaseTypeCreatePage}
          />
          <PrivateRoute
            path="/master-data/purchase-type/:id/edit"
            component={PurchaseTypeEditPage}
          />

          {/* for register url External user */}
          {/* <PrivateExternalRoute
            path="/dashboard"
            exact
            component={DashboardExternalHome}
          /> */}
          <PrivateExternalRoute
            path="/purchase-order-external"
            exact
            component={PurchaseOrderExternal}
          />
          <PrivateExternalRoute
            path="/purchase-order-external/poId=:id"
            exact
            component={PurhcaseOrderDetailExternal}
          />
          <PrivateExternalRoute
            path="/invoice"
            exact
            component={InvoiceExternal}
          />
          <PrivateExternalRoute
            path="/invoice-create"
            exact
            component={InvoiceCreateExternal}
          />
          <PrivateExternalRoute
            path="/invoice/:id/detail"
            exact
            component={InvoiceDetailExternal}
          />
          <PrivateExternalRoute path="/claim" exact component={ClaimExternal} />

          {/* other */}
          <Route path="/bast/:id?/:preview" component={BAST} />
          {/* Auth */}
          <Route path="/register" component={Register} />
          <Route path="/login" component={Signin} />
          <Route path="/forgot" component={ForgotPassword} />
          <Route path="/reset-password/:token" component={ResetPassword} />
          <Route path="/verify/:email" component={Verify} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
