import React from 'react';
import { Grid, makeStyles, Box, Typography } from '@material-ui/core';
import ReactColorPicker from '@super-effective/react-color-picker';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useRouter } from '../../../../utils/useRouter';
import { useSelector, useDispatch } from 'react-redux';
import * as settingAction from '../../../../redux/Settings/actions';

// Components
import InputGroup from '../../../inputGroup';
import ModalConfirm from '../ModalComfirm';
import ModalSuccess from '../../../ModalSuccess';
import CustomizedSnackbars from '../../../PopupMessage';
import { CircleLoading } from '../../../Loading/CircleLoading';

const schema = yup.object().shape({
  Code: yup.string().required(),
  EntityName: yup.string().required(),
});

const EntityEdit = () => {
  const classes = useStyles();
  const router = useRouter();
  const { id } = router.query;
  const dispacth = useDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Global Variable
  const { access_token } = useSelector((state) => state.auth);
  const { entity_createIsLoading, entity_createMessage, entity_detail } =
    useSelector((state) => state.settings);

  // for initial state
  const [dataInput, setDataInput] = React.useState(null);
  const [tempSendData, setTempSendData] = React.useState(null);
  const [color, setColor] = React.useState('');

  // state for modal
  const [showModalConfirm, setShowModalConfirm] = React.useState(false);
  const [showModalSuccess, setShowModalSuccess] = React.useState(false);

  // handle message
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  //submit
  const onSubmit = (data) => {
    const { Code, EntityName } = data;

    let sendData = {
      code: Code,
      name: EntityName,
      color,
    };

    setTempSendData(sendData);
    setShowModalConfirm(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };
  //  end handle Message

  // for wacth value inside useform
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      // console.log(value, name, type)
      setDataInput(value)
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  React.useEffect(() => {
    dispacth(settingAction.getDetailEntity(access_token, id));
  }, []);

  React.useEffect(() => {
    setValue('Code', entity_detail?.entities?.code);
    setValue('EntityName', entity_detail?.entities?.name);
    setColor(entity_detail?.entities?.color);
  }, [entity_detail]);

  React.useEffect(() => {
    if (entity_createMessage) {
      if (entity_createMessage === 'Save Entities Success') {
        setShowModalSuccess(true);
      } else if (entity_createMessage !== '') {
        setShow(true);
        setType('error');
        setNotifText(entity_createMessage);
      }
      dispacth(settingAction.setBlankCreateEntity());
    }
  }, [entity_createMessage]);
  
  return (
    <>
      <CustomizedSnackbars
        show={show}
        handleClose={handleClose}
        message={notifText}
        type={type}
      />

      <ModalConfirm
        open={showModalConfirm}
        handleCloseModalConfirm={() => setShowModalConfirm(false)}
        onPress={() =>
          dispacth(settingAction.createEntity(access_token, tempSendData))
        }
      />
      <ModalSuccess
        open={showModalSuccess}
        onPress={() => router.push('/organize')}
      />
      <Grid md={12} className={classes.wrapperForm}>
        <Box className={classes.sectionHeader}>
          <Typography className={classes.headerTitle}>Edit Entity</Typography>
        </Box>
        <Box className={classes.sizeBorder} />
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Grid md={12} className={classes.wrapperBody}>
            <InputGroup
              label="Code"
              placeholder="input Text"
              register={register}
              required
              type="text"
              isRequire={true}
            />
            <p className={classes.errorText}>{errors.Code?.message}</p>
            <Box className={classes.sizeTop} />
            <InputGroup
              label="EntityName"
              placeholder="input Text"
              register={register}
              required
              type="text"
              isRequire={true}
            />
            <p className={classes.errorText}>{errors.EntityName?.message}</p>
            <Box className={classes.sizeTop} />
            <label className={classes.labelInputColor}>Color*</label>
            <ReactColorPicker 
              color={color} 
              onChange={setColor} 
            />
          </Grid>
          <Box className={classes.sizeBorder} />
          <Grid md={12} className={classes.wrapperFooter}>
            <input
              className={
                dataInput?.Code && dataInput?.EntityName
                  ? classes.submitBtn
                  : classes.submitBtnFalse
              }
              type="submit"
              value="Submit"
            />
            {entity_createIsLoading ? (
              <Box className={classes.loadingContent}>
                <CircleLoading />
              </Box>
            ) : (
              <Box
                className={classes.btnCancel}
                onClick={() => router.push('/organize')}
              >
                <Typography>Cancel</Typography>
              </Box>
            )}
          </Grid>
        </form>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  labelInputColor: {
    color: '#1B2030',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '21px',
    marginBottom: '12px',
  },
  wrapperForm: {
    width: '100%',
    maxWidth: 605,
    backgroundColor: '#fff',
    margin: 'auto',
    borderRadius: 8,
  },
  sectionHeader: {
    padding: 20,
  },
  headerTitle: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  sizeBorder: {
    borderBottom: '1px solid #E7E7E8',
  },
  wrapperBody: {
    padding: 20,
  },
  sizeTop: {
    marginTop: 20,
  },
  wrapperFooter: {
    padding: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  submitBtn: {
    width: 193,
    height: 50,
    borderRadius: 8,
    backgroundColor: '#1B2030',
    color: '#fff',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  submitBtnFalse: {
    width: 193,
    height: 50,
    borderRadius: 8,
    backgroundColor: '#969BAB',
    color: '#fff',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  btnCancel: {
    marginLeft: 20,
    width: 193,
    height: 50,
    borderRadius: 8,
    backgroundColor: '#fff',
    color: '#1B2030',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  errorText: {
    color: 'red',
  },
  loadingContent: {
    marginLeft: 12,
  },
});

export default EntityEdit;
