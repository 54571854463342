import * as types from './types';

// Just Example
export const reportReducers = (
  state = {
    report_isLoading: false,
    report_message: '',
    data_report: null,
    data_reportGraph: null,
    report_exportIsLoading: false,
    report_exportMessage: '',
  },
  action
) => {
  switch (action.type) {
    // get report
    case types.REPORT_GET_LOADING:
      return { ...state, report_isLoading: action.payload };

    case types.REPORT_GET_MESSAGE:
      return { ...state, report_message: action.payload };

    case types.REPORT_GET_DATA:
      return { ...state, data_report: action.payload };

    case types.REPORT_GET_DATA_GRAPH:
      return { ...state, data_reportGraph: action.payload };

    // export csv

    case types.REPORT_EXPORT_LOADING:
      return { ...state, report_exportIsLoading: action.payload };

    case types.REPORT_EXPORT_MESSAGE:
      return { ...state, report_exportMessage: action.payload };

    default:
      return state;
  }
};
