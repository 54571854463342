import React, { useState, useEffect } from 'react';
import { makeStyles, Box, Grid, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../../redux/UserManagement/actions';
import { SortingASC, SortingDESC } from '../../../../utils/SortFunc';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Icons
import Avatar from '../../../../assets/Images/avatar.png';
import Trash from '../../../../assets/Icons/trash.png';
import Pen from '../../../../assets/Icons/pen.png';

// Components
import CustomizedSnackbars from '../../../PopupMessage';
import { TabelLoading, TableNoData } from '../../../Loading/TableLoading';
import ModalDeleteItem from '../../../ModalDeleteItem';
import DesktopPagination from '../../../Pagination/DesktopPagination';
import SortArrow from '../../../SortArrow';

const ListAdminUser = ({ router }) => {
  const classes = useStyles();
  const dispacth = useDispatch();

  // Global Variable
  const { access_token } = useSelector((state) => state.auth);
  const { admin_data, isAdminLoading, message, isInternalLoading } =
    useSelector((state) => state.userManagement);

  // Create format for table and pagination
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(1);
  const [fromRow, setFromRow] = useState(1);
  const [toRow, setToRow] = useState(1);
  const [TotalRow, setTotalRow] = useState(1);
  const [limit, setLimit] = useState(10);

  const [data, setData] = useState([]);

  const [sortingData, setSortingData] = useState([]);

  const [showModalDelete, setShowModalDelete] = useState(false);
  const [tempDelData, setTempDelData] = useState(null);

  const handleChangePagination = (event, value) => {
    setPagination(value);
  };

  const init = () => {
    let params = {
      type: 'admin',
      page: page,
      limit: limit,
    };
    dispacth(actions.getAdminUser(access_token, params));
  };

  useEffect(() => {
    setData([]);
    init();
  }, [pagination, limit]);

  useEffect(() => {
    let tempArray = [];
    admin_data?.data?.map((item, index, wholeArray) => {
      let tempData = {
        id: index,
        userId: item?.id,
        username: item?.username,
        email: item?.email,
        is_logged_in: item?.is_logged_in,
        last_login: item?.last_login,
        status: item?.status,
        avatar: item?.avatar_base,
      };
      tempArray.push(tempData);
    });
    setData(tempArray);
    let countPage = Math.ceil(admin_data?.total / limit);
    setPage(countPage);
    if (pagination > countPage) {
      setPagination(countPage);
    }
    setFromRow(admin_data?.from);
    setToRow(admin_data?.to);
    setTotalRow(admin_data?.total);
  }, [admin_data]);

  const handleEdit = (id) => {
    router.push(`/edit-internal/${id}`);
  };

  const handleDelete = (id) => {
    setShowModalDelete(true);
    setTempDelData({ id });
  };

  const handleActionDelete = () => {
    dispacth(actions.deleteUser(access_token, tempDelData.id));
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
  };

  // handle message
  const [show, setShow] = useState(false);
  const [type, setType] = useState('');
  const [notifText, setNotifText] = useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };

  useEffect(() => {
    if (message) {
      if (message == 'Delete User Success') {
        setType('success');
        setNotifText(message);
        setShow(true);
        setShowModalDelete(false);
        setData([]);
        dispacth(actions.getAdminUser(access_token, 1));
      }
    }
    dispacth(actions.setDefaultMessage());
  }, [message]);

  return (
    <>
      <CustomizedSnackbars
        show={show}
        handleClose={handleClose}
        message={notifText}
        type={type}
      />
      <ModalDeleteItem
        open={showModalDelete}
        close={() => setShowModalDelete(false)}
        onPress={handleActionDelete}
        isLoading={isInternalLoading}
      />
      <Grid md={12}>
        <div style={{ width: '100%' }}>
          <TableContainer className={classes.tableContainer}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Box className={classes.wrapperTableHead} >
                      Username
                      <SortArrow
                        pressUp={() =>
                          SortingASC(data, 'username', setData)
                        }
                        pressDown={() =>
                          SortingDESC(data, 'username', setData)
                        }
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className={classes.wrapperTableHead} style={{ minWidth: 150 }} >
                      Is Logged In?
                      <SortArrow
                        pressUp={() =>
                          SortingASC(data, 'is_logged_in', setData)
                        }
                        pressDown={() =>
                          SortingDESC(data, 'is_logged_in', setData)
                        }
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className={classes.wrapperTableHead}>
                      Last Login
                      <SortArrow
                        pressUp={() =>
                          SortingASC(data, 'last_login', setData)
                        }
                        pressDown={() =>
                          SortingDESC(data, 'last_login', setData)
                        }
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className={classes.wrapperTableHead}>
                      Status
                      <SortArrow
                        pressUp={() =>
                          SortingASC(data, 'status', setData)
                        }
                        pressDown={() =>
                          SortingDESC(data, 'status', setData)
                        }
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className={classes.wrapperTableHead}>
                      <Box>Actions</Box>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isAdminLoading ? null : data?.length > 0 ? (
                  data?.map((row, index) => (
                    <TableRow key={index} className={classes.tableRowData}>
                      <TableCell>
                        <Box className="usernameContent">
                          <Box>
                            <img
                              src={
                                `${row?.avatar}` !== ''
                                  ? `data:image/jpeg;base64,${row?.avatar}`
                                  : Avatar
                              }
                              className="imageAvatar"
                              alt="image not found"
                            />
                          </Box>
                          <Box className="content-Text">
                            <Typography
                              className="userName"
                              variant="body1"
                              component="p"
                            >
                              {`${row?.username}`}
                            </Typography>
                            <Typography
                              className="userEmail"
                              variant="body1"
                              component="p"
                            >
                              {`${row?.email}`}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <div>
                          <Typography
                            className={`isLogin-${
                              row?.is_logged_in === 0 ? 'no' : 'yes'
                            }`}
                          >
                            {row?.is_logged_in === 0 ? 'NO' : 'YES'}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div>
                          {row?.last_login === null ? '-' : row.last_login}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div>
                          <Typography className={`status-${row?.status}`}>
                            {row?.status === '' ? '-' : row?.status}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell>
                        <Box className="actions-Content">
                          <Box
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleEdit(row.userId)}
                          >
                            <BootstrapTooltip title="Edit">
                              <img
                                src={Pen}
                                className="imagePen"
                                alt="image not found"
                              />
                            </BootstrapTooltip>
                          </Box>
                          <Box
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleDelete(row.userId)}
                          >
                            <BootstrapTooltip title="Delete">
                              <img
                                src={Trash}
                                className="imageTrash"
                                alt="image not found"
                              />
                            </BootstrapTooltip>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableNoData colspan={5} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {isAdminLoading ? <TabelLoading /> : null}
        </div>
      </Grid>

      {isAdminLoading ? null : data?.length > 0 ? (
        <DesktopPagination
          toRow={toRow}
          fromRow={fromRow}
          TotalRow={TotalRow}
          page={page}
          handleChangePagination={handleChangePagination}
          limit={limit}
          handleChangeLimit={handleChangeLimit}
          pagination={pagination}
        />
      ) : null}
    </>
  );
};

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

const useStyles = makeStyles({
  tableContent: {
    backgroundColor: '#fff',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    display: 'block',
    overflowX: 'auto',
    border: 'none',
    '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer': {
      whiteSpace: 'normal',
      overflow: 'unset',
      padding: 0,
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:nth-last-child(1) .MuiDataGrid-iconButtonContainer':
      {
        display: 'none',
      },
    '&.MuiDataGrid-root .MuiDataGrid-cell': {
      borderBottom: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-row:nth-child(odd)': {
      backgroundColor: '#F8FAFB',
    },
    '&.MuiDataGrid-root .MuiDataGrid-row:nth-child(even)': {
      backgroundColor: '#FFFFFF',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#000000 !important',
      fontWeight: 'bold',
    },
    '&.MuiDataGrid-root .MuiDataGrid-iconButtonContainer .MuiButtonBase-root': {
      // backgroundColor: 'red',
      width: 20,
    },
    '&.MuiDataGrid-root .MuiDataGrid-iconButtonContainer .MuiButtonBase-root .MuiSvgIcon-root':
      {
        paddingTop: 5,
        paddingLeft: 5,
      },
    '&.MuiDataGrid-root .MuiDataGrid-cell--textLeft': {
      // fontSize: 16,
      color: '#000000',
      fontWeight: 'Normal',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnsContainer': {
      borderBottom: 'none',
      borderTop: '1px solid #E7E7E8',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '&.MuiDataGrid-root .usernameContent': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingLeft: 8,
    },
    '&.MuiDataGrid-root .usernameContent .imageAvatar': {
      width: 36,
      height: 36,
      borderRadius: 50,
    },
    '&.MuiDataGrid-root .usernameContent .content-Text': {
      marginLeft: 20,
    },
    '&.MuiDataGrid-root .usernameContent .content-Text .userName': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#000000',
    },
    '&.MuiDataGrid-root .usernameContent .content-Text .userEmail': {
      fontSize: 14,
      lineHeight: '19px',
      color: '#969BAB',
    },
    '&.MuiDataGrid-root .actions-Content': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 8,
      paddingRight: 20,
      width: '100%',
    },
    '&.MuiDataGrid-root .isLogin-yes': {
      paddingLeft: 8,
      paddingRight: 8,
      backgroundColor: '#27AE60',
      color: '#fff',
      borderRadius: 100,
      fontSize: 12,
      lineHeight: '26px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    '&.MuiDataGrid-root .isLogin-no': {
      paddingLeft: 8,
      paddingRight: 8,
      backgroundColor: '#F12B2C',
      color: '#fff',
      borderRadius: 100,
      fontSize: 12,
      lineHeight: '26px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    '&.MuiDataGrid-root .status-active': {
      paddingLeft: 8,
      paddingRight: 8,
      backgroundColor: '#27AE60',
      color: '#fff',
      borderRadius: 100,
      fontSize: 12,
      lineHeight: '26px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    '&.MuiDataGrid-root .status-nonactive': {
      paddingLeft: 8,
      paddingRight: 8,
      backgroundColor: '#969BAB',
      color: '#fff',
      borderRadius: 100,
      fontSize: 12,
      lineHeight: '26px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
  },
  // Styling Re Render Table
  tableContainer: {
    backgroundColor: '#fff',
    width: '100%',
    overflow: 'auto',
    '& .MuiTableRow-root .MuiTableCell-root': {
      borderBottom: 'none',
    },
    '& .MuiTableRow-head .MuiTableCell-head:nth-child(1)': {
      // minWidth: 400,
      flex: 1,
    },
    '& .MuiTableRow-head .MuiTableCell-head:nth-child(2)': {
      minWidth: 100,
    },
    '& .MuiTableRow-head .MuiTableCell-head:nth-child(3)': {
      minWidth: 120,
    },
    '& .MuiTableRow-head .MuiTableCell-head:nth-child(4)': {
      minWidth: 100,
    },
    '& .MuiTableRow-head .MuiTableCell-head:nth-last-child(1)': {
      width: 120,
    },
  },
  wrapperTableHead: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: 16,
    lineHeight: '30px',
    color: '#000000',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  tableRowData: {
    '&.MuiTableRow-root:nth-child(odd)': {
      backgroundColor: '#F8FAFB',
    },
    '&.MuiTableRow-root:nth-child(even)': {
      backgroundColor: '#fff',
    },
    '&.MuiTableRow-root .MuiTableCell-root': {
      borderBottom: 'none',
    },
    '& .usernameContent': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingLeft: 8,
    },
    '& .usernameContent .imageAvatar': {
      width: 36,
      height: 36,
      borderRadius: 50,
    },
    '& .usernameContent .content-Text': {
      marginLeft: 20,
    },
    '& .usernameContent .content-Text .userName': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#000000',
    },
    '& .usernameContent .content-Text .userEmail': {
      fontSize: 14,
      lineHeight: '19px',
      color: '#969BAB',
    },
    '& .actions-Content': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 8,
      paddingRight: 20,
      width: '100%',
    },
    '& .isLogin-yes': {
      paddingLeft: 8,
      paddingRight: 8,
      backgroundColor: '#27AE60',
      color: '#fff',
      borderRadius: 100,
      maxWidth: 39,
      fontSize: 12,
      textAlign: 'center',
      lineHeight: '26px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    '& .isLogin-no': {
      paddingLeft: 8,
      paddingRight: 8,
      backgroundColor: '#F12B2C',
      color: '#fff',
      borderRadius: 100,
      maxWidth: 39,
      fontSize: 12,
      textAlign: 'center',
      lineHeight: '26px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    '& .status-active': {
      paddingLeft: 8,
      paddingRight: 8,
      backgroundColor: '#27AE60',
      color: '#fff',
      borderRadius: 100,
      maxWidth: 60,
      textAlign: 'center',
      fontSize: 12,
      lineHeight: '26px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    '& .status-nonactive': {
      paddingLeft: 8,
      paddingRight: 8,
      backgroundColor: '#969BAB',
      color: '#fff',
      borderRadius: 100,
      maxWidth: 85,
      textAlign: 'center',
      fontSize: 12,
      lineHeight: '26px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
  },
  // Pagination styling
  descTable: {
    paddingTop: 11.5,
    paddingBottom: 11.5,
  },
  descTableText: {
    color: '#969BAB',
    fontSize: 16,
    lineHeight: '21px',
  },
  paginationContent: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pagination: {
    borderRadius: 0,
    '&.MuiPagination-root .MuiPagination-ul .MuiPaginationItem-root': {
      margin: 0,
    },
    borderRadius: 0,
    '&.MuiPagination-root .MuiPagination-ul .MuiPaginationItem-rounded': {
      borderRadius: 0,
    },
    '&.MuiPagination-root .MuiPagination-ul .MuiButtonBase-root': {
      backgroundColor: '#fff',
      color: '#969BAB',
    },
    '&.MuiPagination-root .MuiPagination-ul .MuiButtonBase-root.Mui-selected': {
      backgroundColor: '#1B2030',
      color: '#fff',
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-child(1)': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-child(1) button': {
      color: '#1B2030',
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-last-child(1)': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-last-child(1) button': {
      color: '#1B2030',
    },
  },
});

export default ListAdminUser;
