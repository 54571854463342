import React from 'react';
import { makeStyles, Grid, Box, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

// Components
import ActivityData from './TableData/ActivityData';
import SortArrow from '../../../SortArrow';
import CustomizedSnackbars from '../../../PopupMessage';
import { TabelLoading, TableNoData } from '../../../Loading/TableLoading';
import ModalDeleteItem from '../ModalDeleteItem';
import ModalSuccess from '../../../ModalSuccess';

const ApproverListing = () => {
  const classes = useStyles();

  // for initial state
  const [rowData, setRowData] = React.useState([]);

  // state for modal
  const [showModalDelete, setShowModalDelete] = React.useState(false);
  const [showModalSuccess, setShowModalSuccess] = React.useState(false);

  // handle message
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };
  //  end handle Message

  React.useEffect(() => {
    setRowData(activityFake);
  }, []);

  return (
    <>
      <CustomizedSnackbars
        show={show}
        handleClose={handleClose}
        message={notifText}
        type={type}
      />
      <ModalDeleteItem
        open={showModalDelete}
        close={() => setShowModalDelete(false)}
        onPress={() => console.log('delete')}
      />
      <ModalSuccess
        open={showModalSuccess}
        onPress={() => console.log('success')}
      />
      <div style={{ width: '100%' }}>
        <Grid md={12} classes={classes.wrapperTable}>
          <TableContainer className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Box className={classes.wrapperTableHeader}>
                      <Typography className={classes.headeText}>ID</Typography>
                      <SortArrow />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className={classes.wrapperTableHeader}>
                      <Typography className={classes.headeText}>
                        Code
                      </Typography>
                      <SortArrow />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className={classes.wrapperTableHeader}>
                      <Typography className={classes.headeText}>
                        Activity Name
                      </Typography>
                      <SortArrow />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className={classes.wrapperTableHeader}>
                      <Typography className={classes.headeText}>
                        Description
                      </Typography>
                      <SortArrow />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className={classes.wrapperTableHeader}>
                      <Typography className={classes.headeText}>
                        Total Checklist
                      </Typography>
                      <SortArrow />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className={classes.wrapperTableHeader}>
                      <Typography className={classes.headeText}>
                        Action
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData.map((row, index) => (
                  <ActivityData
                    row={row}
                    index={index}
                    key={index}
                    handleEditItem={(e) => console.log(e)}
                    handleDeleteItem={(s) => console.log(s)}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapperTableHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headeText: {
    fontSize: 16,
    lineHeight: '30px',
    fontWeight: 'bold',
    color: '#000000',
  },
  tableContainer: {
    '& .MuiTableRow-root.MuiTableRow-head': {
      backgroundColor: '#fff',
    },
    '& .MuiTableRow-head .MuiTableCell-head': {
      borderBottom: 'none',
    },
    '& .MuiTableRow-head .MuiTableCell-head:nth-child(1)': {
      width: 91,
    },
    '& .MuiTableRow-head .MuiTableCell-head:nth-child(2)': {
      width: 185,
    },
    '& .MuiTableRow-head .MuiTableCell-head:nth-child(3)': {
      width: 200,
    },
    '& .MuiTableRow-head .MuiTableCell-head:nth-child(5)': {
      width: 160,
    },
    '& .MuiTableRow-head .MuiTableCell-head:nth-child(6)': {
      width: 100,
      paddingLeft: 20,
      paddinRigth: 20,
    },
  },
}));

const activityFake = [
  {
    id: 1,
    transaction_type: 'PR',
    owner: 'TV Commercial',
    desc: 'lorem ipsum',
    total_checklist: 2,
  },
];

export default ApproverListing;
