import React from 'react';
import { Grid, makeStyles, Box, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useRouter } from '../../../../utils/useRouter';
import { useSelector, useDispatch } from 'react-redux';
import * as settingAction from '../../../../redux/Settings/actions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import BootstrapTooltip from '../../../Tooltip';

// Components
import InputGroup from '../../../inputGroup';
import ModalConfirm from '../ModalComfirm';
import ModalSuccess from '../../../ModalSuccess';
import CustomizedSnackbars from '../../../PopupMessage';
import { CircleLoading } from '../../../Loading/CircleLoading';
import SortArrow from '../../../SortArrow';
import ListItem from '../subComponents/ListItem';

// icons
import TandaSeruGrey from '../../../../assets/Icons/tandaSeruGrey.png';
import TandaSeruBlue from '../../../../assets/Icons/tandaSeruBlue.png';

const schema = yup.object().shape({
  Code: yup.string().required(),
  ActivityName: yup.string().required(),
  Description: yup.string().required(),
});

const ActivityCreate = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispacth = useDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const { ActivityName, Description, Code } = data;

    let CheckData = rowCheckData?.filter((dt, index) => dt.isSelect === true);

    let tempArr = [];
    CheckData.map((item, index) => {
      let id = item.id;
      tempArr.push(id);
    });
    let permissionText = tempArr.join(';');

    let sendData = {
      code: Code,
      name: ActivityName,
      description: Description,
      checklist: permissionText,
    };

    // console.log(sendData);

    setTempSendData(sendData);
    setShowModalConfirm(true);
  };

  // Global Variable
  const { access_token } = useSelector((state) => state.auth);
  const {
    checklist_isLoading,
    checklist_data,
    activities_createIsLoading,
    activities_createMessage,
  } = useSelector((state) => state.settings);

  // for initial state
  const [dataInput, setDataInput] = React.useState(null);
  const [tempSendData, setTempSendData] = React.useState(null);
  const [isMandatory, setIsMandatory] = React.useState(false);
  const [isIconHover, setIsIconHover] = React.useState(false);
  const [rowCheckData, setRowCheckData] = React.useState(null);
  const [selectNumber, setSelectNumber] = React.useState(0);

  // state for modal
  const [showModalConfirm, setShowModalConfirm] = React.useState(false);
  const [showModalSuccess, setShowModalSuccess] = React.useState(false);

  // handle message
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };
  //  end handle Message

  const handleCheckPermission = (e) => {
    const { checked, name } = e.target;
    let tempData = rowCheckData.map((row, index) =>
      row.id == name ? { ...row, isSelect: checked } : row
    );
    setRowCheckData(tempData);
  };

  // for wacth value inside useform
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      // console.log(value, name, type)
      setDataInput(value)
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  React.useEffect(() => {
    dispacth(settingAction.getChecklist(access_token));
  }, []);

  React.useEffect(() => {
    let tempArr = [];

    checklist_data?.ChecklistAttachment?.map((check, index) => {
      let tempData = {
        id: check?.id,
        name: check?.name,
        desc: check?.description,
        mandatory: true,
        isSelect: false,
      };
      tempArr.push(tempData);
    });

    setRowCheckData(tempArr);
  }, [checklist_data]);

  React.useEffect(() => {
    if (rowCheckData) {
      let dataLenght = rowCheckData?.filter(
        (dt, index) => dt.isSelect === true
      ).length;

      setSelectNumber(dataLenght);
    }
  }, [rowCheckData]);

  React.useEffect(() => {
    if (activities_createMessage) {
      if (activities_createMessage === 'Save Activities Success') {
        setShowModalSuccess(true);
      } else if (
        activities_createMessage !== '' &&
        activities_createMessage !== 'Save Activities Success'
      ) {
        setShow(true);
        setType('error');
        setNotifText(activities_createMessage);
      }
      dispacth(settingAction.setBlankCreateActivities());
    }
  }, [activities_createMessage]);

  return (
    <>
      <CustomizedSnackbars
        show={show}
        handleClose={handleClose}
        message={notifText}
        type={type}
      />
      <ModalConfirm
        open={showModalConfirm}
        handleCloseModalConfirm={() => setShowModalConfirm(false)}
        onPress={() =>
          dispacth(settingAction.createActivities(access_token, tempSendData))
        }
        isLoading={activities_createIsLoading}
      />
      <ModalSuccess
        open={showModalSuccess}
        onPress={() => router.push('/master-data')}
      />
      <Grid md={12} className={classes.wrapperForm}>
        <Box className={classes.sectionHeader}>
          <Typography className={classes.headerTitle}>
            Create Activities
          </Typography>
        </Box>
        <Box className={classes.sizeBorder} />
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Grid md={12} className={classes.wrapperBody}>
            <InputGroup
              label="Code"
              placeholder="input Text"
              register={register}
              required
              type="text"
              isRequire={true}
            />
            <p className={classes.errorText}>{errors.Code?.message}</p>
            <Box className={classes.sizeTop} />
            <InputGroup
              label="ActivityName"
              placeholder="input Text"
              register={register}
              required
              type="text"
              isRequire={true}
            />
            <p className={classes.errorText}>{errors.ActivityName?.message}</p>
            <Box className={classes.sizeTop} />
            <InputGroup
              label="Description"
              placeholder="input Text"
              register={register}
              required
              type="text"
              isRequire={true}
              inputProps={{ maxLength: 255 }}
            />
            <p className={classes.errorText}>{errors.Description?.message}</p>
            <Box className={classes.sizeTop} />
            <Box className={classes.headerChecklist}>
              <Box className={classes.wrapperLabelTable}>
                <Typography className={classes.label}>Checklist</Typography>
                <BootstrapTooltip
                  title="You can add new checklist on Master Data_Checklist "
                  placement="right"
                >
                  <img
                    onMouseEnter={() => setIsIconHover(true)}
                    onMouseLeave={() => setIsIconHover(false)}
                    className={classes.iconsTandaSeru}
                    src={isIconHover ? TandaSeruBlue : TandaSeruGrey}
                    alt="not"
                  />
                </BootstrapTooltip>
              </Box>
              <Typography
                className={classes.labelGrey}
              >{`${selectNumber} items selected`}</Typography>
            </Box>
            <Box className={classes.sizeTop} />
            <Grid md={12} container className={classes.wrapperHeader}>
              <Grid md={4} className={classes.wrapperHeaderList}>
                <Typography className={classes.label}>
                  Checklist Name
                </Typography>
                <SortArrow />
              </Grid>
              <Grid md={6} className={classes.wrapperHeaderList}>
                <Typography className={classes.label}>Description</Typography>
                <SortArrow />
              </Grid>
              {/* <Grid md={3} className={classes.wrapperHeaderList}>
                <Typography className={classes.label}>Mandatory</Typography>
              </Grid> */}
              <Grid md={2} className={classes.wrapperHeaderList}>
                <Typography className={classes.label}>Select</Typography>
              </Grid>
            </Grid>
            <Grid md={12} className={classes.wrapperListItem}>
              {rowCheckData?.map((item, index) => (
                <ListItem
                  item={item}
                  index={index}
                  key={index}
                  handleCheckPermission={handleCheckPermission}
                />
              ))}
            </Grid>
          </Grid>

          <Box className={classes.sizeBorder} />
          <Grid md={12} className={classes.wrapperFooter}>
            <input
              className={
                dataInput?.Code &&
                dataInput?.ActivityName &&
                dataInput?.Description
                  ? // checklist_createIsLoading
                    classes.submitBtn
                  : classes.submitBtnFalse
              }
              type="submit"
              value="Submit"
            />
            {activities_createIsLoading ? (
              <Box className={classes.loadingContent}>
                <CircleLoading />
              </Box>
            ) : (
              <Box
                className={classes.btnCancel}
                onClick={() => router.push('/master-data')}
              >
                <Typography>Cancel</Typography>
              </Box>
            )}
          </Grid>
        </form>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  wrapperForm: {
    width: '100%',
    maxWidth: 605,
    backgroundColor: '#fff',
    margin: 'auto',
    borderRadius: 8,
  },
  sectionHeader: {
    padding: 20,
  },
  headerTitle: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  sizeBorder: {
    borderBottom: '1px solid #E7E7E8',
  },
  wrapperBody: {
    padding: 20,
  },
  sizeTop: {
    marginTop: 20,
  },
  wrapperFooter: {
    padding: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  submitBtn: {
    width: 193,
    height: 50,
    borderRadius: 8,
    backgroundColor: '#1B2030',
    color: '#fff',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  submitBtnFalse: {
    width: 193,
    height: 50,
    borderRadius: 8,
    backgroundColor: '#969BAB',
    color: '#fff',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  btnCancel: {
    marginLeft: 20,
    width: 193,
    height: 50,
    borderRadius: 8,
    backgroundColor: '#fff',
    color: '#1B2030',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  errorText: {
    color: 'red',
  },
  loadingContent: {
    marginLeft: 12,
  },
  headerChecklist: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  label: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
  },
  labelGrey: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
  },
  wrapperLabelTable: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  iconsTandaSeru: {
    width: 20,
    height: 20,
    marginLeft: 13,
  },
  wrapperHeaderList: {
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  wrapperHeader: {
    height: 60,
    borderTop: '1px solid #E7E7E8',
    borderRight: '1px solid #E7E7E8',
    borderLeft: '1px solid #E7E7E8',
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  },
  wrapperListItem: {
    maxHeight: 400,
    overflow: 'auto',
    borderBottom: '1px solid #E7E7E8',
    borderRight: '1px solid #E7E7E8',
    borderLeft: '1px solid #E7E7E8',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
  },
});

export default ActivityCreate;
