import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { getDeviceType } from '../../utils/get_device_type';
import { useRouter } from '../../utils/useRouter';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import * as pr from '../../redux/PR/actions';
import * as po from '../../redux/PO/actions';
import * as grppo from '../../redux/GRPO/actions';
import * as apinvoice from '../../redux/APInvoice/actions';
import * as apdp from '../../redux/APDP/actions';
import * as op from '../../redux/OP/actions';
import * as utility from '../../redux/Utility/actions';
import * as dashboard from '../../redux/Dashboard/actions';

// Component
import LayoutDashboard from '../../components/LayoutDashboard';
import NotifDashboard from '../../components/NotifDashboard';
import DashboardTableMenu from '../../components/DashboardTableMenu';
import DashboardTableData from '../../components/DashboardTableData';
import NotifApprovalDashboard from '../../components/NotifApprovalDashboard';
import Lib from 'src/utils/Lib';

const DashboardHome = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const router = useRouter();

  // Global variable
  const { access_token, data } = useSelector((state) => state.auth);
  const { pr_dashboardNotif } = useSelector((state) => state.pr);
  const { po_dashboardNotif } = useSelector((state) => state.po);
  const { grpo_dashboardNotif } = useSelector((state) => state.grpo);
  const { apinvoice_dashboardNotif } = useSelector((state) => state.apinvoice);
  const { apdp_dashboardNotif } = useSelector((state) => state.apdp);
  const { op_dashboardNotif } = useSelector((state) => state.op);
  const {
    isLoading,
    message,
    dashboard_pr,
    dashboard_po,
    dashboard_grpo,
    dashboard_invoice,
    dashboard_apdp,
    dashboard_op,
  } = useSelector((state) => state.dashboard);

  const { department_data, entity_data } = useSelector(
    (state) => state.utility
  );

  // initial state
  const [index, setIndex] = React.useState(1);
  const [showMessageApproval, setShowMessageApproval] = React.useState(false);
  const [deviceType, setDeviceType] = React.useState('');
  const [isShowNotif, setIsShowNotif] = React.useState(false);
  const [rowData, setRowData] = React.useState(null);
  const [sortingData, setSortingData] = React.useState([]);
  const [entityList, setEntityList] = React.useState([])

  // state for notif text
  const [isPR, setIsPR] = React.useState(false);
  const [isPO, setIsPO] = React.useState(false);
  const [isGRPO, setIsGRPO] = React.useState(false);
  const [isInvoice, setIsInvoice] = React.useState(false);
  const [isAPDP, setIsAPDP] = React.useState(false);
  const [isOP, setIsOP] = React.useState(false);

  // filter menu
  const [monthFilter, setMonthFilter] = React.useState('today');
  const [departementFilter, setDepartementFilter] =
    React.useState('All_department');
  const [entityFilter, setEntityFilter] = React.useState('All_entity');

  const handleChange = (event) => {
    setMonthFilter(event.target.value);
  };
  const handleChangeDepartement = (event) => {
    setDepartementFilter(event.target.value);
  };
  const handleChangeEntity = (event) => {
    setEntityFilter(event.target.value);
  };

  const _renderBoxMenu = (index) => {
    if (index === 1) {
      return 'Purchase Requisition (PR)';
    } else if (index === 2) {
      return 'Purchase Order (PO)';
    } else if (index === 3) {
      return 'GRPO';
    } else {
      return 'Invoice';
    }
  };

  React.useEffect(() => {
    const deviceType = getDeviceType();

    setDeviceType(deviceType);
  });

  const handleCloseMessageApproval = () => {
    setShowMessageApproval(false);
  };

  const handleGoToApproval = () => {
    router.push('/approval');
  };

  // Request API
  const handleGETdata = () => {
    let params = {
      period: monthFilter,
      department_id: departementFilter,
      entity_id: entityFilter,
      order_by: 'id',
      order_dir: 'desc',
      limit: 5,
    };

    if (departementFilter === 'All_department') delete params.department_id;

    if (entityFilter === 'All_entity') delete params.entity_id;

    if (index === 1) {
      console.log('Get PR');
      dispatch(dashboard.getDashboardPR(access_token, params));
    }

    if (index === 2) {
      console.log('GET PO');
      dispatch(dashboard.getDashboardPO(access_token, params));
    }

    if (index === 3) {
      console.log('GET GRPO');
      dispatch(dashboard.getDashboardGRPO(access_token, params));
    }

    if (index === 4) {
      console.log('GET AP Invoice');
      dispatch(dashboard.getDashboardINVOICe(access_token, params));
    }

    if (index === 5) {
      console.log('GET APDP');
      dispatch(dashboard.getDashboardAPDP(access_token, params));
    }

    if (index === 6) {
      console.log('GET OP');
      dispatch(dashboard.getDashboardOP(access_token, params));
    }
  };

  React.useEffect(() => {
    dispatch(utility.getDepartment(access_token));
    if(!Lib.isSuperAdmin(data)) {
      setEntityList(get(data, ['user', 'entity'], []))
    } else {
      dispatch(utility.getEntity(access_token));
    }
    dispatch(pr.getSAPnotification(access_token));
    dispatch(po.getSAPnotification(access_token));
    dispatch(grppo.getSAPnotification(access_token));
    dispatch(apinvoice.getSAPnotification(access_token));
    dispatch(apdp.getSAPnotification(access_token));
    dispatch(op.getSAPnotification(access_token));
  }, []);

  React.useEffect(()=> {
    if(entity_data && Lib.isSuperAdmin(data)){
      setEntityList(entity_data)
    }
  }, [entity_data])

  React.useEffect(() => {
    setRowData(sortingData);
  }, [sortingData]);

  React.useEffect(() => {
    let params = {
      period: 'today',
    };

    console.log('GET PR');
    console.log('dispatch PR', params);
    dispatch(dashboard.getDashboardPR(access_token, params));
  }, []);

  React.useEffect(() => {
    handleGETdata();
  }, [departementFilter, entityFilter, monthFilter, index]);

  React.useEffect(() => {
    if (pr_dashboardNotif > 0) setIsPR(true);
  }, [pr_dashboardNotif]);

  React.useEffect(() => {
    if (po_dashboardNotif > 0) setIsPO(true);
  }, [po_dashboardNotif]);

  React.useEffect(() => {
    if (grpo_dashboardNotif > 0) setIsGRPO(true);
  }, [grpo_dashboardNotif]);
  React.useEffect(() => {
    if (apinvoice_dashboardNotif > 0) setIsInvoice(true);
  }, [apinvoice_dashboardNotif]);
  React.useEffect(() => {
    if (apdp_dashboardNotif > 0) setIsAPDP(true);
  }, [apdp_dashboardNotif]);
  React.useEffect(() => {
    if (op_dashboardNotif > 0) setIsOP(true);
  }, [op_dashboardNotif]);

  React.useEffect(() => {
    if (isPR || isPO || isGRPO || isInvoice || isAPDP || isOP) {
      setIsShowNotif(true);
    }
  }, [isPR, isPO, isGRPO, isInvoice, isAPDP, isOP]);

  // Render data for table
  React.useEffect(() => {
    if (dashboard_pr?.data?.length > 0) {
      let tempArr = [];
      dashboard_pr?.data?.map((item, index) => {
        let tempData = {
          index: index,
          id: item?.id,
          docNum: item?.doc_num,
          request: item?.purpose_of_request ?? '-',
          department: item?.linkDepartment?.code ?? '-',
          entity: item?.entity?.code ?? '-',
          activity: item?.items[0].activity_name ?? '-',
          total: item?.grand_total ?? '-',
          status: item?.status,
          date: `${item?.doc_date} ${item?.doc_time ?? ''}`,
          currency: item?.currency,
        };
        tempArr.push(tempData);
      });
      setRowData(tempArr);
    } else {
      setRowData([]);
    }
  }, [dashboard_pr]);

  React.useEffect(() => {
    if (dashboard_po?.data?.length > 0) {
      let tempArr = [];

      dashboard_po?.data?.map((item, index) => {
        let tempData = {
          index: index,
          id: item?.id,
          docNum: item?.doc_num,
          request: item?.pr?.purpose_of_request ?? '-',
          department: item?.department?.code ?? '-',
          entity: item?.entity?.code ?? '-',
          activity: item?.pr?.items[0].activity_name ?? '-',
          total: item?.doc_total_amount ?? '-',
          status: item?.status,
          date: `${item?.doc_date} ${item?.doc_time ?? ''}`,
          currency: item?.currency,
        };
        tempArr.push(tempData);
      });
      setRowData(tempArr);
    } else {
      setRowData([]);
    }
  }, [dashboard_po]);

  React.useEffect(() => {
    if (dashboard_grpo?.data?.length > 0) {
      let tempArr = [];

      dashboard_grpo?.data?.map((item, index) => {
        let tempData = {
          index: index,
          id: item?.id,
          docNum: item?.doc_num,
          request: item?.po?.pr?.purpose_of_request ?? '-',
          department: item?.department?.code ?? '-',
          entity: item?.entity?.code ?? '-',
          activity: item?.po?.pr?.items[0].activity_name ?? '-',
          total: item?.doc_total_amount ?? '-',
          status: item?.status,
          date: `${item?.doc_date} ${item?.doc_time ?? ''}`,
          currency: item?.currency,
        };
        tempArr.push(tempData);
      });
      setRowData(tempArr);
    } else {
      setRowData([]);
    }
  }, [dashboard_grpo]);

  React.useEffect(() => {
    if (dashboard_invoice?.data?.length > 0) {
      let tempArr = [];

      dashboard_invoice?.data?.map((item, index) => {
        let tempData = {
          index: index,
          id: item?.id,
          docNum: item?.doc_num,
          request: item?.grpo?.po?.pr?.purpose_of_request ?? '-',
          department: item?.department?.code ?? '-',
          entity: item?.entity?.code ?? '-',
          activity: item?.grpo?.po?.pr?.items[0].activity_name ?? '-',
          total: item?.doc_total_amount ?? '-',
          status: item?.status,
          date: `${item?.doc_date} ${item?.doc_time ?? ''}`,
          currency: item?.currency,
        };
        tempArr.push(tempData);
      });
      setRowData(tempArr);
    } else {
      setRowData([]);
    }
  }, [dashboard_invoice]);

  React.useEffect(() => {
    if (dashboard_apdp?.data?.length > 0) {
      let tempArr = [];

      dashboard_apdp?.data?.map((item, index) => {
        let tempData = {
          index: index,
          id: item?.id,
          docNum: item?.doc_num,
          request: item?.po?.pr?.purpose_of_request ?? '-',
          department: item?.department?.code ?? '-',
          entity: item?.entity?.code ?? '-',
          activity: item?.po?.pr?.items[0].activity_name ?? '-',
          total: item?.doc_total_amount ?? '-',
          status: item?.status,
          date: `${item?.doc_date} ${item?.doc_time ?? ''}`,
          currency: item?.currency,
        };
        tempArr.push(tempData);
      });
      setRowData(tempArr);
    } else {
      setRowData([]);
    }
  }, [dashboard_apdp]);

  React.useEffect(() => {
    if (dashboard_op?.data?.length > 0) {
      let tempArr = [];

      dashboard_op?.data?.map((item, index) => {
        let tempData = {
          index: index,
          opId: item?.id,
          noDoc: item?.outgoing_no ?? '-',
          currency: item?.currency ?? '-',
          vendorName: item?.to_order_of_vendor_name ?? '-',
          total: item?.total_amount ?? '-',
          status: item?.status ?? '-',
          date: item?.created_at ?? '-',
        };
        tempArr.push(tempData);
      });
      setRowData(tempArr);
    } else {
      setRowData([]);
    }
  }, [dashboard_op]);

  return (
    <LayoutDashboard page="Dashboard" deviceType={deviceType}>
      <NotifApprovalDashboard
        open={showMessageApproval}
        close={handleCloseMessageApproval}
        handleGoToApproval={handleGoToApproval}
      />
      <div
        className={
          deviceType === 'mobile'
            ? classes.pageContainerMobile
            : classes.pageContainer
        }
      >
        {isShowNotif ? (
          <NotifDashboard
            deviceType={deviceType}
            isPR={isPR}
            title_pr="Purchase Requisition"
            onPress_pr={() => router.push('/purchase-requistor')}
            onPress={() => setIsShowNotif(false)}
            isPO={isPO}
            title_po="Purchase Order"
            onPress_po={() => router.push('/purchase-order')}
            isGRPO={isGRPO}
            title_grpo="GRPO"
            onPress_grpo={() => router.push('/grpo')}
            isInvoice={isInvoice}
            title_invoice="AP INVOICE"
            onPress_invoice={() => router.push('/ap-invoice')}
            isAPDP={isAPDP}
            title_apdp="APDP"
            onPress_apdp={() => router.push('/ap-downpayment')}
            isOP={isOP}
            title_op="Outgoing Payment"
            onPress_op={() => router.push('/outgoing-payment')}
          />
        ) : null}

        <Box className={classes.sizebox} />
        {/* <p>{`deviceType ${deviceType}`}</p> */}
        <DashboardTableMenu
          index={index}
          setIndex={setIndex}
          _renderBoxMenu={_renderBoxMenu}
          deviceType={deviceType}
          monthFilter={monthFilter}
          departementFilter={departementFilter}
          entityFilter={entityFilter}
          entity_data={entityList}
          department_data={department_data}
          handleChange={handleChange}
          handleChangeDepartement={handleChangeDepartement}
          handleChangeEntity={handleChangeEntity}
          isLoading={isLoading}
          dashboard_pr={dashboard_pr}
          dashboard_po={dashboard_po}
          dashboard_grpo={dashboard_grpo}
          dashboard_invoice={dashboard_invoice}
          dashboard_apdp={dashboard_apdp}
          dashboard_op={dashboard_op}
        />
        <Box className={classes.sizeboxMenu} />
        <DashboardTableData
          index={index}
          router={router}
          data={rowData}
          isLoading={isLoading}
          setSortingData={setSortingData}
        />
      </div>
    </LayoutDashboard>
  );
};

const useStyles = makeStyles({
  pageContainer: {
    marginBottom: 30,
    paddingTop: 97 - 24,
  },
  pageContainerMobile: {
    maxWidth: '100vw',
    marginBottom: 30,
    paddingTop: 97 - 24,
    // marginLeft: -24,
  },
  sizebox: {
    marginTop: 20,
  },
  sizeboxMenu: {
    marginTop: 27,
  },
});

export default DashboardHome;
