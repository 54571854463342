import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// icons
import Eye from '../../assets/Icons/eye.png';
import EyeDisable from '../../assets/Icons/eye_disable.png';

const InputGroup = ({
  label,
  register,
  required,
  placeholder,
  type,
  error,
  value,
  disable,
  isRequire,
  align,
  expectLabel,
  inputProps,
  extraClassName,
}) => {
  const classes = useStyles();

  const [showPassword, setShowPassword] = React.useState(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const _renderLabel = (label) => {
    if (label === 'Confirm') {
      return 'Confirm New Password';
    } else if (label === 'ConfirmPWD') {
      return 'Confrim Password';
    } else if (label === 'FullName') {
      return 'Full Name';
    } else if (label === 'UsernameE') {
      return 'Username / Email';
    } else if (label === 'CekNo') {
      return 'Cek No.';
    } else if (label === 'ReferensiNo') {
      return 'Referensi No.';
    } else if (label === 'AccountNo') {
      return 'Account No.';
    } else if (label === 'EntityName') {
      return 'Entity Name';
    } else if (label === 'DepartmentName') {
      return 'Department Name';
    } else if (label === 'LevelName') {
      return 'Level Name';
    } else if (label === 'PositionName') {
      return 'Position Name';
    } else if (label === 'CodeNo') {
      return 'Code No';
    } else if (label === 'ChecklistName') {
      return 'Checklist Name';
    } else if (label === 'ActivityName') {
      return 'Activity Name';
    } else if (label === 'TransactionType') {
      return 'Transaction Type';
    } else if (label === 'ApprovalWindowTime') {
      return 'Approval Window Time';
    } else if (label === 'GroupCodeName') {
      return 'Group Code Name';
    } else if (label === 'CategoryName') {
      return 'Category Name';
    } else if (label === 'NOBASTDKT') {
      return 'No.BAST (DKT)';
    } else if (label === 'NOBASTVENDOR') {
      return 'No.BAST Vendor (Optional)';
    } else if (label === 'JOBDESC') {
      return 'Nama Pekerjaan/Barang/Jasa';
    } else {
      return label;
    }
  };

  const _renderMaxLenght = (label) => {
    if (label === 'Address') {
      return 100;
    } else {
      return 50;
    }
  };
  return (
    <>
      <Box className={classes.wrapperInput}>
        <label className={classes.labelInput}>
          {expectLabel
            ? expectLabel
            : `${_renderLabel(label)}${isRequire ? '*' : ''}`}
        </label>
        <input
          value={value ? value : null}
          security={false}
          type={type === 'password' && !showPassword ? 'password' : 'text'}
          className={`${classes.contexInput} inpustyle ${
            align ? classes.alignRight : null
          } ${extraClassName}`}
          {...register(label, { required })}
          placeholder={placeholder}
          autoComplete={`new-${label}`}
          maxLength={_renderMaxLenght(label)}
          disabled={disable ?? false}
          {...inputProps}
        />
        {type === 'password' &&
          (showPassword ? (
            <Box onClick={handleShowPassword}>
              <img
                className={classes.eyeLogo}
                src={Eye}
                alt="image not found"
              />
            </Box>
          ) : (
            <Box onClick={handleShowPassword}>
              <img
                className={classes.eyeLogo}
                src={EyeDisable}
                alt="image not found"
              />
            </Box>
          ))}
      </Box>
    </>
  );
};

const useStyles = makeStyles({
  wrapperInput: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelInput: {
    marginBottom: 12,
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
    fontWeight: 'bold',
  },
  contexInput: {
    height: 50,
    paddingLeft: 20,
    paddingRight: 40,
    // border: '1px solid #E7E7E8',
    border: 'none',
    backgroundColor: '#F8FAFB',
    borderRadius: 8,
    maxWidth: '100%',
    fontSize: 16,
    '&.inpustyle:focus': {
      border: '1.5px solid #F2C94C',
      outline: 'none',
      boxShadow: '0px 0px 0px 4px rgba(242, 201, 76, 0.17);',
      borderRadius: 8,
    },
  },
  eyeLogo: {
    position: 'relative',
    float: 'right',
    marginTop: -35,
    marginRight: 15,
  },
  alignRight: {
    textAlign: 'right',
  },
  // errorBorder: {
  //   height: 50,
  //   paddingLeft: 20,
  //   paddingRight: 40,
  //   border: '1px solid red',
  //   backgroundColor: '#F8FAFB',
  //   borderRadius: 4,
  //   fontSize: 16,
  //   color: '#1B2030',
  // },
});

export default InputGroup;