import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';

// icons
import TandaSeru from '../../../assets/Icons/tandaSeru.png';

const ButtonCancel = ({ handleCanclePR, title, approval }) => {
  const classes = useStyles();

  const [username, setUsername] = React.useState('');

  React.useEffect(() => {
    let person = approval.filter((item) => item.reject === 1);

    setUsername(person[0]?.user?.username);
  }, []);

  return (
    <>
      <Box className={classes.btnContent}>
        <Box>
          <Box className={classes.btn} onClick={handleCanclePR}>
            <Typography
              className={classes.btnText}
              variant="body1"
              component="p"
            >
              Cancel {title}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.btnMessageContent}>
          <Box className={classes.btnMessage}>
            <Box className={classes.btnMessageIcon}>
              <img src={TandaSeru} alt="image not found" />
            </Box>
            <Typography
              className={classes.btnNotifText}
              variant="body1"
              component="p"
            >
              {`This ${title} is rejected by`}
            </Typography>
            <Typography
              className={classes.btnNotifTextBold}
              variant="body1"
              component="p"
            >
              {username}
            </Typography>
            <Typography
              variant="body1"
              component="p"
              className={classes.btnNotifText}
            >
              {`you can cancel this ${title} and create the new one`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const useStyles = makeStyles({
  btnContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingTop: 26,
    paddingBottom: 16,
    paddingRight: 20,
    paddingLeft: 20,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
  },
  btn: {
    cursor: 'pointer',
    backgroundColor: '#FD453A',
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 14,
    paddingBottom: 14,
    borderRadius: 8,
  },
  btnText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
  },
  btnMessageContent: {
    // flex: 1,
  },
  btnMessage: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  btnNotifText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
  },
  btnNotifTextBold: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
    fontWeight: 'bold',
    marginLeft: 8,
    marginRight: 8,
  },
  btnMessageIcon: {
    marginRight: 13,
  },
});

export default ButtonCancel;
