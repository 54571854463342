import React, { useState, useEffect } from 'react';
import { makeStyles, Box, Grid, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../../redux/UserManagement/actions';
import { SortingASC, SortingDESC } from '../../../../utils/SortFunc';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Icons
import Avatar from '../../../../assets/Images/avatar.png';
import Trash from '../../../../assets/Icons/trash.png';
import Pen from '../../../../assets/Icons/pen.png';

// Components
import CustomizedSnackbars from '../../../PopupMessage';
import { TabelLoading, TableNoData } from '../../../Loading/TableLoading';
import ModalDeleteItem from '../../../ModalDeleteItem';
import DesktopPagination from '../../../Pagination/DesktopPagination';
import SortArrow from '../../../SortArrow';

const ListExternalUser = ({ router }) => {
  const classes = useStyles();
  const dispacth = useDispatch();

  // Global Variable
  const { access_token } = useSelector((state) => state.auth);
  const { externalUserIsloading, externalUserData, del_externalMessage } =
    useSelector((state) => state.userManagement);

  // Create format for table and pagination
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(1);
  const [fromRow, setFromRow] = useState(1);
  const [toRow, setToRow] = useState(1);
  const [TotalRow, setTotalRow] = useState(1);
  const [limit, setLimit] = useState(10);

  const [rowData, setRowData] = useState([]);
  const [data, setData] = useState([]);

  const [showModalDelete, setShowModalDelete] = useState(false);

  const [tempDelData, setTempDelData] = useState(null);

  // handle message
  const [show, setShow] = useState(false);
  const [type, setType] = useState('');
  const [notifText, setNotifText] = useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };

  const handleChangePagination = (event, value) => {
    setPagination(value);
  };

  const handleEdit = (id, category) => {
    router.push(`/edit-external/${category}/${id}`);
  };

  const handleDelete = (id, category, category_id) => {
    setTempDelData({ id, category, category_id });
    setShowModalDelete(true);
  };

  const actionDeleteUser = () => {
    if (tempDelData) {
      // console.log(tempDelData);
      if (tempDelData.category === 'distributor') {
        // console.log('delete distributor');
        dispacth(
          actions.delExternalUserDistibutor(
            access_token,
            tempDelData.category_id
          )
        );
      } else {
        console.log('delete supplier');
        dispacth(
          actions.delExternalUserSupplier(access_token, tempDelData.category_id)
        );
      }
    }
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
  };

  const init = () => {
    let params = {
      type: 'supplier,distributor',
      page: pagination,
      limit: limit,
    };
    dispacth(actions.getExternalUser(access_token, params));
  };

  useEffect(() => {
    setData([]);
    init();
  }, []);

  useEffect(() => {
    init();
  }, [pagination, limit]);

  useEffect(() => {
    let tempArr = [];

    const _filteringSupDis = (distributor, supplier) => {
      if (distributor || !supplier) {
        return distributor?.client?.category;
      } else if (!distributor || supplier) {
        return supplier?.client?.category;
      }
    };
    const _filteringSupDisCompanyName = (distributor, supplier) => {
      if (distributor || !supplier) {
        return distributor?.client?.name;
      } else if (!distributor || supplier) {
        return supplier?.client?.name;
      }
    };
    const _filteringSupDisCompanyAddress = (distributor, supplier) => {
      if (distributor || !supplier) {
        return distributor?.client?.address;
      } else if (!distributor || supplier) {
        return supplier?.client?.address;
      }
    };
    const _filteringSupDisCategoryId = (distributor, supplier) => {
      if (distributor || !supplier) {
        return distributor;
      } else if (!distributor || supplier) {
        return supplier;
      }
    };

    externalUserData?.data.map((extData, index) => {
      let tempData = {
        id: extData?.id,
        name: extData?.name,
        avatar: extData?.avatar_base,
        phone: extData?.phone,
        email: extData?.email,
        companyName:
          _filteringSupDisCompanyName(
            extData?.distributor,
            extData?.supplier
          ) ?? '-',
        companyAddress:
          _filteringSupDisCompanyAddress(
            extData?.distributor,
            extData?.supplier
          ) ?? '-',
        category:
          _filteringSupDis(extData?.distributor, extData?.supplier) ?? '-',
        category_id: _filteringSupDisCategoryId(
          extData?.distributor_id,
          extData?.supplier_id
        ),
        programs: extData?.programs ?? '-',
        isLogin: extData?.is_logged_in,
        last_login: extData?.last_login,
        status: extData?.status,
      };
      tempArr.push(tempData);
    });

    let countPage = Math.ceil(externalUserData?.total / limit);
    setPage(countPage);
    if (pagination > countPage) {
      setPagination(countPage);
    }
    setFromRow(externalUserData?.from);
    setToRow(externalUserData?.to);
    setTotalRow(externalUserData?.total);
    setRowData(tempArr);
  }, [externalUserData]);

  useEffect(() => {
    if (del_externalMessage) {
      setShowModalDelete(false);
      setType('success');
      setNotifText(del_externalMessage);
      setShow(true);
      dispacth(actions.getExternalUser(access_token, page));
    }
    dispacth(actions.setBlankDelExernalMessage());
  }, [del_externalMessage]);

  return (
    <>
      <ModalDeleteItem
        open={showModalDelete}
        close={() => setShowModalDelete(false)}
        onPress={actionDeleteUser}
      />
      <CustomizedSnackbars
        show={show}
        handleClose={handleClose}
        message={notifText}
        type={type}
      />
      <Grid md={12}>
        {/* <div style={{ width: '100%' }}> */}
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                    <Box className={classes.wrapperTableHead}>
                      Name
                      <SortArrow
                        pressUp={() =>
                          SortingASC(rowData, 'name', setRowData)
                        }
                        pressDown={() =>
                          SortingDESC(rowData, 'name', setRowData)
                        }
                      />
                    </Box>
                </TableCell>
                <TableCell>
                  <Box
                    className={classes.wrapperTableHead}
                  >
                    Company
                    <SortArrow
                      pressUp={() =>
                        SortingASC(rowData, 'companyName', setRowData)
                      }
                      pressDown={() =>
                        SortingDESC(rowData, 'companyName', setRowData)
                      }
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    className={classes.wrapperTableHead}
                  >
                    Category
                    <SortArrow
                      pressUp={() =>
                        SortingASC(rowData, 'category', setRowData)
                      }
                      pressDown={() =>
                        SortingDESC(rowData, 'category', setRowData)
                      }
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    className={classes.wrapperTableHead}
                  >
                    Programs
                    <SortArrow
                      pressUp={() =>
                        SortingASC(rowData, 'programs', setRowData)
                      }
                      pressDown={() =>
                        SortingDESC(rowData, 'programs', setRowData)
                      }
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    className={classes.wrapperTableHead}
                  >
                    Is Logged In?
                    <SortArrow
                      pressUp={() =>
                        SortingASC(rowData, 'isLogin', setRowData)
                      }
                      pressDown={() =>
                        SortingDESC(rowData, 'isLogin', setRowData)
                      }
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    className={classes.wrapperTableHead}
                  >
                    Last Login
                    <SortArrow
                      pressUp={() =>
                        SortingASC(rowData, 'last_login', setRowData)
                      }
                      pressDown={() =>
                        SortingDESC(rowData, 'last_login', setRowData)
                      }
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    className={classes.wrapperTableHead}
                  >
                    Status
                    <SortArrow
                      pressUp={() =>
                        SortingASC(rowData, 'status', setRowData)
                      }
                      pressDown={() =>
                        SortingDESC(rowData, 'status', setRowData)
                      }
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box className={classes.wrapperTableHead}>
                    <Box>Actions</Box>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {externalUserIsloading ? null : rowData?.length > 0 ? (
                rowData?.map((row, index) => (
                  <TableRow key={index} className={classes.tableRowData}>
                    <TableCell>
                      <Box className="usernameContent">
                        <Box>
                          <img
                            src={
                              `${row?.avatar}` !== ''
                                ? `data:image/jpeg;base64,${row?.avatar}`
                                : Avatar
                            }
                            className="imageAvatar"
                            alt="image not found"
                          />
                        </Box>
                        <Box className="content-Text">
                          <Typography
                            className="userName"
                            variant="body1"
                            component="p"
                          >
                            {`${row?.name}`}
                          </Typography>
                          <Typography
                            className="userEmail"
                            variant="body1"
                            component="p"
                          >
                            {`${row?.phone}`}
                          </Typography>
                          <Typography
                            className="userEmail"
                            variant="body1"
                            component="p"
                          >
                            {`${row?.email}`}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        <Box className={classes.companyField}>
                          <Typography className={classes.companyName}>
                            {`${row?.companyName}`}
                          </Typography>
                          <Typography className={classes.companyAddress}>
                            {`${row?.companyAddress}`}
                          </Typography>
                        </Box>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.categoryText}>
                        {row?.category}
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <div>
                        <Typography
                          className={`isLogin-${
                            row?.isLogin === 0 ? 'no' : 'yes'
                          }`}
                        >
                          {row?.isLogin === 0 ? 'NO' : 'YES'}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>
                        {row?.last_login === null ? '-' : row.last_login}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>
                        <Typography className={`status-${row?.status}`}>
                          {row?.status === '' ? '-' : row?.status}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <Box className="actions-Content">
                        <Box
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            handleEdit(row.category_id, row.category)
                          }
                        >
                          <BootstrapTooltip title="Edit">
                            <img
                              src={Pen}
                              className="imagePen"
                              alt="image not found"
                            />
                          </BootstrapTooltip>
                        </Box>
                        <Box
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            handleDelete(row.id, row.category, row.category_id)
                          }
                        >
                          <BootstrapTooltip title="Delete">
                            <img
                              src={Trash}
                              className="imageTrash"
                              alt="image not found"
                            />
                          </BootstrapTooltip>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableNoData colspan={8} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {externalUserIsloading ? <TabelLoading /> : null}
        {/* </div> */}
      </Grid>
      {externalUserIsloading ? null : rowData?.length > 0 ? (
        <DesktopPagination
          toRow={toRow}
          fromRow={fromRow}
          TotalRow={TotalRow}
          page={page}
          handleChangePagination={handleChangePagination}
          limit={limit}
          handleChangeLimit={handleChangeLimit}
          pagination={pagination}
        />
      ) : null}
    </>
  );
};

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

const useStyles = makeStyles({
  tableContainer: {
    backgroundColor: '#fff',
    width: '100%',
    overflow: 'auto',
    '& .MuiTableRow-root .MuiTableCell-root': {
      borderBottom: 'none',
    },
    '& .MuiTableRow-head .MuiTableCell-head:nth-child(1)': {
      width: 180,
    },
    '& .MuiTableRow-head .MuiTableCell-head:nth-child(2)': {
      width: 180,
    },
    '& .MuiTableRow-head .MuiTableCell-head:nth-child(3)': {
      width: 140,
    },
    '& .MuiTableRow-head .MuiTableCell-head:nth-child(4)': {
      minWidth: 140,
    },
    '& .MuiTableRow-head .MuiTableCell-head:nth-child(5)': {
      minWidth: 160,
      padding: 6,
    },
    '& .MuiTableRow-head .MuiTableCell-head:nth-child(6)': {
      minWidth: 160,
    },
    '& .MuiTableRow-head .MuiTableCell-head:nth-last-child(1)': {
      width: 100,
    },
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
  },
  wrapperTableHead: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: 16,
    lineHeight: '30px',
    color: '#000000',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  tableRowData: {
    '&.MuiTableRow-root:nth-child(odd)': {
      backgroundColor: '#F8FAFB',
    },
    '&.MuiTableRow-root:nth-child(even)': {
      backgroundColor: '#fff',
    },
    '&.MuiTableRow-root .MuiTableCell-root': {
      borderBottom: 'none',
    },
    '& .usernameContent': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingLeft: 8,
      maxWidth: 250,
    },
    '& .usernameContent .imageAvatar': {
      width: 36,
      height: 36,
      borderRadius: 50,
    },
    '& .usernameContent .content-Text': {
      marginLeft: 20,
    },
    '& .usernameContent .content-Text .userName': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#000000',
    },
    '& .usernameContent .content-Text .userEmail': {
      fontSize: 14,
      lineHeight: '19px',
      color: '#969BAB',
    },
    '& .actions-Content': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 8,
      // paddingRight: 20,
      width: '100%',
    },
    '& .isLogin-yes': {
      paddingLeft: 8,
      paddingRight: 8,
      backgroundColor: '#27AE60',
      color: '#fff',
      borderRadius: 100,
      maxWidth: 39,
      fontSize: 12,
      textAlign: 'center',
      lineHeight: '26px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    '& .isLogin-no': {
      paddingLeft: 8,
      paddingRight: 8,
      backgroundColor: '#F12B2C',
      color: '#fff',
      borderRadius: 100,
      maxWidth: 39,
      fontSize: 12,
      textAlign: 'center',
      lineHeight: '26px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    '& .status-active': {
      paddingLeft: 8,
      paddingRight: 8,
      backgroundColor: '#27AE60',
      color: '#fff',
      borderRadius: 100,
      maxWidth: 60,
      textAlign: 'center',
      fontSize: 12,
      lineHeight: '26px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    '& .status-nonactive': {
      paddingLeft: 8,
      paddingRight: 8,
      backgroundColor: '#969BAB',
      color: '#fff',
      borderRadius: 100,
      maxWidth: 85,
      textAlign: 'center',
      fontSize: 12,
      lineHeight: '26px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
  },
  // Pagination styling
  descTable: {
    paddingTop: 11.5,
    paddingBottom: 11.5,
  },
  descTableText: {
    color: '#969BAB',
    fontSize: 16,
    lineHeight: '21px',
  },
  paginationContent: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pagination: {
    borderRadius: 0,
    '&.MuiPagination-root .MuiPagination-ul .MuiPaginationItem-root': {
      margin: 0,
    },
    borderRadius: 0,
    '&.MuiPagination-root .MuiPagination-ul .MuiPaginationItem-rounded': {
      borderRadius: 0,
    },
    '&.MuiPagination-root .MuiPagination-ul .MuiButtonBase-root': {
      backgroundColor: '#fff',
      color: '#969BAB',
    },
    '&.MuiPagination-root .MuiPagination-ul .MuiButtonBase-root.Mui-selected': {
      backgroundColor: '#1B2030',
      color: '#fff',
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-child(1)': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-child(1) button': {
      color: '#1B2030',
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-last-child(1)': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-last-child(1) button': {
      color: '#1B2030',
    },
  },
  companyField: {
    maxWidth: 160,
  },
  companyName: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
  },
  categoryText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
    textTransform: 'capitalize',
  },
  companyAddress: {
    fontSize: 14,
    lineHeight: '19px',
    color: '#969BAB',
    maxWidth: 160,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export default ListExternalUser;
