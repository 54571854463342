import React from 'react';
import { Grid, makeStyles, Box, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import DashboardTableStatus from '../DashboardTableStatus';

// Svg
import { DownArrow } from '../../assets/Icons/svg/DownArrow';

// icons
import ElipsPR from '../../assets/Icons/elipsPR.png';
import ElipsPO from '../../assets/Icons/elipsPO.png';
import ElipsGRPO from '../../assets/Icons/elipsGRPO.png';
import ElipsInvoice from '../../assets/Icons/elipsInvoice.png';

const DashboardTableMenu = ({
  index,
  setIndex,
  deviceType,
  _renderBoxMenu,
}) => {
  const classes = useStyles();

  const [monthFilter, setMonthFilter] = React.useState(10);
  const [departementFilter, setDepartementFilter] = React.useState(10);
  const [entityFilter, setEntityFilter] = React.useState(10);

  const handleChange = (event) => {
    setMonthFilter(event.target.value);
  };
  const handleChangeDepartement = (event) => {
    setDepartementFilter(event.target.value);
  };
  const handleChangeEntity = (event) => {
    setEntityFilter(event.target.value);
  };

  const _renderBackground = (index) => {
    if (index === 1) {
      return classes.wrapperBanner;
    } else if (index === 2) {
      return classes.wrapperBannerPO;
    } else if (index === 3) {
      return classes.wrapperBannerGRPO;
    } else {
      return classes.wrapperBannerInvoice;
    }
  };
  const _renderBackgroundRight = (index) => {
    if (index === 1) {
      return deviceType === 'mobile'
        ? classes.wrapperBannerRightMobile
        : classes.wrapperBannerRight;
    } else if (index === 2) {
      return deviceType === 'mobile'
        ? classes.wrapperBannerRightMobile
        : classes.wrapperBannerRightPO;
    } else if (index === 3) {
      return deviceType === 'mobile'
        ? classes.wrapperBannerRightMobile
        : classes.wrapperBannerRightGRPO;
    } else {
      return deviceType === 'mobile'
        ? classes.wrapperBannerRightMobile
        : classes.wrapperBannerRightInvoice;
    }
  };

  const _renderCircleMobile = (index) => {
    if (index === 1) {
      return ElipsPR;
    } else if (index === 2) {
      return ElipsPO;
    } else if (index === 3) {
      return ElipsGRPO;
    } else {
      return ElipsInvoice;
    }
  };

  return (
    <>
      <Grid
        xs
        md={12}
        container
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid xs={12} md={6}>
          <Box
            className={
              deviceType === 'mobile'
                ? classes.wrapperTabMobile
                : classes.wrapperTab
            }
          >
            <Box
              className={index === 1 ? classes.tabActive : classes.tab}
              onClick={() => setIndex(1)}
            >
              <Typography
                className={
                  index === 1 ? classes.tabTextActive : classes.tabText
                }
                variant="body1"
                component="p"
              >
                PR
              </Typography>
            </Box>
            <Box className={classes.sizeLeft} />
            <Box
              className={index === 2 ? classes.tabActive : classes.tab}
              onClick={() => setIndex(2)}
            >
              <Typography
                className={
                  index === 2 ? classes.tabTextActive : classes.tabText
                }
                variant="body1"
                component="p"
              >
                PO
              </Typography>
            </Box>
            <Box className={classes.sizeLeft} />
            <Box
              className={index === 3 ? classes.tabActive : classes.tab}
              onClick={() => setIndex(3)}
            >
              <Typography
                className={
                  index === 3 ? classes.tabTextActive : classes.tabText
                }
                variant="body1"
                component="p"
              >
                GRPO
              </Typography>
            </Box>
            <Box className={classes.sizeLeft} />
            <Box
              className={index === 4 ? classes.tabActive : classes.tab}
              onClick={() => setIndex(4)}
            >
              <Typography
                className={
                  index === 4 ? classes.tabTextActive : classes.tabText
                }
                variant="body1"
                component="p"
              >
                Invoice
              </Typography>
            </Box>
          </Box>
        </Grid>
        {deviceType === 'mobile' ? (
          <>
            <Grid container xs={12}>
              <Grid xs={6} style={{ paddingRight: 5 }}>
                <FormControl className={classes.formControl}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={monthFilter}
                    onChange={handleChange}
                    IconComponent={() => <DownArrow />}
                  >
                    <MenuItem value={10}>This Month</MenuItem>
                    <MenuItem value={20}>Last Month</MenuItem>
                    <MenuItem value={30}>Last 3 Month</MenuItem>
                    <MenuItem value={40}>Today</MenuItem>
                    <MenuItem value={50}>Yesterday</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={6} style={{ paddingLeft: 5 }}>
                <FormControl className={classes.formControl}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={departementFilter}
                    onChange={handleChangeDepartement}
                    IconComponent={() => <DownArrow />}
                  >
                    <MenuItem value={10}>All Department</MenuItem>
                    <MenuItem value={20}>Finance</MenuItem>
                    <MenuItem value={30}>Communication</MenuItem>
                    <MenuItem value={40}>HRG</MenuItem>
                    <MenuItem value={50}>Marketing</MenuItem>
                    <MenuItem value={60}>Market Development</MenuItem>
                    <MenuItem value={70}>Sales</MenuItem>
                    <MenuItem value={80}>Operation</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12} style={{ marginTop: 10 }}>
                <FormControl className={classes.formControl}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={entityFilter}
                    onChange={handleChangeEntity}
                    IconComponent={() => <DownArrow />}
                  >
                    <MenuItem value={10}>All Entity</MenuItem>
                    <MenuItem value={20}>YDI</MenuItem>
                    <MenuItem value={30}>DKTI</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid xs={12} md={6}>
            <Box className={classes.wrapperFilter}>
              <FormControl className={classes.formControl}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={monthFilter}
                  onChange={handleChange}
                  IconComponent={() => <DownArrow />}
                >
                  <MenuItem value={10}>This Month</MenuItem>
                  <MenuItem value={20}>Last Month</MenuItem>
                  <MenuItem value={30}>Last 3 Month</MenuItem>
                  <MenuItem value={40}>Today</MenuItem>
                  <MenuItem value={50}>Yesterday</MenuItem>
                </Select>
              </FormControl>
              <Box className={classes.sizeSelect} />
              <FormControl className={classes.formControl}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={departementFilter}
                  onChange={handleChangeDepartement}
                  IconComponent={() => <DownArrow />}
                >
                  <MenuItem value={10}>All Department</MenuItem>
                  <MenuItem value={20}>Finance</MenuItem>
                  <MenuItem value={30}>Communication</MenuItem>
                  <MenuItem value={40}>HRG</MenuItem>
                  <MenuItem value={50}>Marketing</MenuItem>
                  <MenuItem value={60}>Market Development</MenuItem>
                  <MenuItem value={70}>Sales</MenuItem>
                  <MenuItem value={80}>Operation</MenuItem>
                </Select>
              </FormControl>
              <Box className={classes.sizeSelect} />
              <FormControl className={classes.formControl}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={entityFilter}
                  onChange={handleChangeEntity}
                  IconComponent={() => <DownArrow />}
                >
                  <MenuItem value={10}>All Entity</MenuItem>
                  <MenuItem value={20}>YDI</MenuItem>
                  <MenuItem value={30}>DKTI</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        )}
      </Grid>
      <Box className={_renderBackground(index)}>
        <Grid container md={12}>
          <Grid xs={12} md={9}>
            <Box
              className={
                deviceType === 'mobile'
                  ? classes.wrapperBannerLeftMobile
                  : classes.wrapperBannerLeft
              }
            >
              <Typography
                className={
                  deviceType === 'mobile'
                    ? classes.bannerTitleMobile
                    : classes.bannerTitle
                }
                variant="body1"
                component="p"
              >
                Total
              </Typography>
              <Typography
                className={
                  deviceType === 'mobile'
                    ? classes.bannerSubTitleMobile
                    : classes.bannerSubTitle
                }
                variant="body1"
                component="p"
              >
                {_renderBoxMenu(index)}
              </Typography>
            </Box>
            {deviceType === 'mobile' ? (
              <Box className={classes.elipsMobile}>
                <img
                  style={{ borderTopRightRadius: 8 }}
                  src={_renderCircleMobile(index)}
                  alt="image not found"
                />
              </Box>
            ) : null}
          </Grid>
          <Grid xs={12} md={3}>
            <Box className={_renderBackgroundRight(index)}>
              <Typography
                className={
                  deviceType === 'mobile'
                    ? classes.bannerTitleMobile
                    : classes.bannerTitle
                }
                variant="body1"
                component="p"
              >
                1302
              </Typography>
              <Typography
                className={
                  deviceType === 'mobile'
                    ? classes.bannerSubTitleMobile
                    : classes.bannerSubTitle
                }
                variant="body1"
                component="p"
              >
                IDR 223,000,000
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        className={
          deviceType === 'mobile'
            ? classes.wrapperStatusMobile
            : classes.wrapperStatus
        }
      >
        <Grid md={12} container>
          <Grid xs={12} md={3}>
            <Box
              className={
                deviceType === 'mobile'
                  ? classes.wrapperColumnMobile
                  : classes.wrapperColumn
              }
            >
              <DashboardTableStatus title="Need" index={index} />
            </Box>
          </Grid>
          <Grid xs={12} md={3}>
            <Box
              className={
                deviceType === 'mobile'
                  ? classes.wrapperColumnMobile
                  : classes.wrapperColumn
              }
            >
              <DashboardTableStatus title="Open" index={index} />
            </Box>
          </Grid>
          <Grid xs={12} md={3}>
            <Box
              className={
                deviceType === 'mobile'
                  ? classes.wrapperColumnMobile
                  : classes.wrapperColumn
              }
            >
              <DashboardTableStatus title="Closed" index={index} />
            </Box>
          </Grid>
          <Grid xs={12} md={3}>
            <Box
              className={
                deviceType === 'mobile'
                  ? classes.wrapperColumnMobile
                  : classes.wrapperColumn
              }
            >
              <DashboardTableStatus title="Approve" index={index} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const useStyles = makeStyles({
  wrapperTab: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  wrapperTabMobile: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  tab: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 9.3,
    paddingBottom: 9.3,
    borderRadius: 8,
    backgroundColor: '#fff',
    cursor: 'pointer',
  },
  tabActive: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 9.3,
    paddingBottom: 9.3,
    borderRadius: 8,
    backgroundColor: '#1B2030',
    cursor: 'pointer',
  },
  tabTextActive: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#F8FAFB',
  },
  tabText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
  },
  sizeLeft: {
    marginLeft: 17,
  },
  wrapperFilter: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  sizeSelect: {
    marginLeft: 16,
  },
  // styling select
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 20,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 5,
    // minWidth: 200,
    width: '100%',
    maxHeight: 40,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      marginTop: 6,
      marginLeft: 4,
    },
  },
  wrapperBanner: {
    marginTop: 20,
    paddingLeft: 22,
    backgroundColor: '#FE7045',
    borderRadius: 9,
  },
  wrapperBannerPO: {
    marginTop: 20,
    paddingLeft: 22,
    backgroundColor: '#3B93EE',
    borderRadius: 9,
  },
  wrapperBannerGRPO: {
    marginTop: 20,
    paddingLeft: 22,
    backgroundColor: '#F28244',
    borderRadius: 9,
  },
  wrapperBannerInvoice: {
    marginTop: 20,
    paddingLeft: 22,
    backgroundColor: '#9B81E4',
    borderRadius: 9,
  },
  bannerTitle: {
    fontSize: 36,
    lineHeight: '49px',
    fontWeight: 'bold',
    color: '#fff',
    zIndex: 90,
  },
  bannerTitleMobile: {
    fontSize: 26,
    lineHeight: '36px',
    fontWeight: 'bold',
    color: '#fff',
    zIndex: 99,
  },
  bannerSubTitle: {
    fontSize: 21,
    lineHeight: '32px',
    color: '#fff',
    zIndex: 99,
    position: 'relative',
  },
  bannerSubTitleMobile: {
    fontSize: 18,
    lineHeight: '27px',
    color: '#fff',
    zIndex: 99,
    position: 'relative',
  },
  wrapperBannerLeft: {
    paddingRight: 22,
    paddingTop: 23,
    paddingBottom: 85,
  },
  wrapperBannerLeftMobile: {
    paddingRight: 22,
    paddingTop: 23,
    paddingBottom: 22,
  },
  wrapperBannerRight: {
    paddingRight: 22,
    paddingTop: 23,
    paddingBottom: 85,
    borderTopLeftRadius: 100,
    borderBottomLeftRadius: 100,
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'flex-end',
    backgroundColor: '#FF5428',
    width: '100%',
    height: '100%',
  },
  wrapperBannerRightMobile: {
    paddingRight: 22,
    // paddingTop: 23,
    paddingBottom: 85,
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    height: '100%',
  },
  wrapperBannerRightPO: {
    paddingRight: 22,
    paddingTop: 23,
    paddingBottom: 85,
    borderTopLeftRadius: 100,
    borderBottomLeftRadius: 100,
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'flex-end',
    backgroundColor: '#127EEE',
    width: '100%',
    height: '100%',
  },
  wrapperBannerRightGRPO: {
    paddingRight: 22,
    paddingTop: 23,
    paddingBottom: 85,
    borderTopLeftRadius: 100,
    borderBottomLeftRadius: 100,
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'flex-end',
    backgroundColor: '#EB6B24',
    width: '100%',
    height: '100%',
  },
  wrapperBannerRightInvoice: {
    paddingRight: 22,
    paddingTop: 23,
    paddingBottom: 85,
    borderTopLeftRadius: 100,
    borderBottomLeftRadius: 100,
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'flex-end',
    backgroundColor: '#8569D3',
    width: '100%',
    height: '100%',
  },
  wrapperStatus: {
    position: 'relative',
    zIndex: 99,
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: -70,
  },
  wrapperStatusMobile: {
    position: 'relative',
    zIndex: 99,
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: -55,
  },
  wrapperColumn: {
    marginLeft: 10,
    marginRight: 10,
    minHeight: 133,
    backgroundColor: '#fff',
    borderRadius: 8,
  },
  wrapperColumnMobile: {
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    minHeight: 133,
    backgroundColor: '#fff',
    borderRadius: 8,
  },
  elipsMobile: {
    marginRight: 20,
    position: 'absolute',
    right: 0,
    marginTop: '-108px',
  },
});

export default DashboardTableMenu;
