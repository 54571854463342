import React from 'react';
import { makeStyles, withStyles, Typography, Box } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import BootstrapTooltip from '../../Tooltip';
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import { formatRupiah, dateFormater } from '../../../utils/formatter';

// Components
import ModalDeleteItem from '../../ModalDeleteItem';

// Icons
import Trash from '../../../assets/Icons/trash.png';
import TrashRed from '../../../assets/Icons/trashRed.png';
import Pen from '../../../assets/Icons/pen.png';
import PenBlk from '../../../assets/Icons/penBlk.png';

// generate fixed table
const StickyTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#fff',
    color: theme.palette.common.white,
    right: 0,
    position: 'sticky',
    zIndex: 99,
  },
  body: {
    backgroundColor: '#fff',
    minWidth: '20px',
    width: 122,
    right: 0,
    position: 'sticky',
    zIndex: 99,
  },
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#fff',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff',
    },
  },
}))(TableRow);

export const Row = (props) => {
  const { row, index, handleEditVendorData, handleDelete, handleEdit } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  // handle action hover icon
  const [isTrashHover, setIsTrashHover] = React.useState(false);
  const [isUnduhHover, setIsUnduhHover] = React.useState(false);
  const [isDetailHover, setIsDetailHover] = React.useState(false);

  const [showModalDelete, setShowModalDelete] = React.useState(false);

  const handleCloseModalDelete = () => {
    setShowModalDelete(false);
  };

  const _renderStatus = (status) => {
    if (status === 'invalid') {
      return <div className={classes.statusInvalid}>{status}</div>;
    } else {
      return <div className={classes.statusValid}>{status}</div>;
    }
  };

  const handleRemoveItem = (id) => {
    handleDelete(id);
    setShowModalDelete(false);
  };

  return (
    <>
      <ModalDeleteItem
        open={showModalDelete}
        close={handleCloseModalDelete}
        id={row.id}
        onPress={() => handleRemoveItem(row.id)}
      />
      <React.Fragment>
        <StyledTableRow
          className={
            index % 2 == 0
              ? classes.tableContentData
              : classes.tableContentDataDua
          }
        >
          <StyledTableCell style={{ width: 80 }}>{row.id}</StyledTableCell>
          <StyledTableCell style={{ width: 80 }}>{row.code}</StyledTableCell>
          <StyledTableCell style={{ width: 80 }}>{row.name}</StyledTableCell>
          <StyledTableCell style={{ width: 198 }}>
            <div className={classes.textnName} onClick={() => setOpen(!open)}>
              {row.address}
            </div>
          </StyledTableCell>

          <StyledTableCell style={{ width: 132 }}>
            <div>{row.email}</div>
          </StyledTableCell>

          <StyledTableCell style={{ width: 94 }}>
            <div>{row.category}</div>
          </StyledTableCell>
          <StyledTableCell style={{ width: 135 }}>
            <div>{row.groupCode}</div>
          </StyledTableCell>
          <StyledTableCell style={{ width: 160 }}>
            {_renderStatus(row.status)}
          </StyledTableCell>
          <StyledTableCell style={{ width: 135 }}>
            <div>{dateFormater(row.date, 'DD-MM-YYYY hh:mm')}</div>
          </StyledTableCell>
          <StickyTableCell style={{ width: 80 }}>
            <StyledTableCell numeric align="right" className={classes.cell}>
              <div className={classes.iconAction}>
                <div
                  className={classes.Action}
                  onClick={() => handleEdit(row.id)}
                  onMouseEnter={() => setIsDetailHover(true)}
                  onMouseLeave={() => setIsDetailHover(false)}
                >
                  <BootstrapTooltip title="Edit">
                    <img
                      src={isDetailHover ? PenBlk : Pen}
                      alt="image not found"
                    />
                  </BootstrapTooltip>
                </div>
                <div
                  className={classes.Action}
                  onClick={() => setShowModalDelete(true)}
                  onMouseEnter={() => setIsTrashHover(true)}
                  onMouseLeave={() => setIsTrashHover(false)}
                >
                  <BootstrapTooltip title="Delete">
                    <img
                      src={isTrashHover ? TrashRed : Trash}
                      alt="image not found"
                    />
                  </BootstrapTooltip>
                </div>
              </div>
            </StyledTableCell>
          </StickyTableCell>
        </StyledTableRow>
      </React.Fragment>
    </>
  );
};

const useRowStyles = makeStyles((theme) => ({
  // cutome table
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  head: {
    backgroundColor: 'black',
    minWidth: '50px',
  },
  tableContainer: {
    maxWidth: '100%',
  },
  cell: {
    minWidth: '100px',
  },
  titleTableContent: {
    paddingRight: 22,
    paddingLeft: 22,
    paddingTop: 27,
    paddingBottom: 27,
    backgroundColor: '#ffff',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleTable: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  contentRigth: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  btnAsnyc: {
    backgroundColor: '#1B2030',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '14px 20px',
    borderRadius: 8,
    cursor: 'pointer',
  },
  btnExport: {
    backgroundColor: '#127EEE',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '14px 20px',
    borderRadius: 8,
    cursor: 'pointer',
  },
  btnText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
    marginLeft: 20,
  },
  notifContent: {
    marginRight: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  notifText: {
    fontWeight: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#1B2030',
    marginLeft: 13,
  },
  filterContent: {
    padding: '16px 20px',
    borderTop: '1px solid #E7E7E8',
    borderBottom: '1px solid #E7E7E8',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filterOption: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  dateRangeContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
  },
  dateRangeIcon: {
    height: 40,
    width: 58,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E7E7E8',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  dateRangeText: {
    paddingLeft: 10,
    backgroundColor: '#F8FAFB',
    height: 40,
    width: 258,
    paddingTop: 9.5,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  formControl: {
    width: 227,
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    paddingLeft: 20,
    paddingRight: 17,
    '&.MuiFormControl-root .MuiInput-underline::before': {
      border: 'none',
      transition: 'none',
    },
  },
  filterStatusContent: {
    marginLeft: 10,
  },
  filterActifityContent: {
    marginLeft: 10,
  },
  iconAction: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
  },
  Action: {
    cursor: 'pointer',
  },
  tableContentData: {
    backgroundColor: '#F8FAFB',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    border: 'none',
    borderBottom: 'none',
    '&.MuiTableRow-root .MuiTableCell-root.MuiTableCell-body': {
      borderBottom: 'none',
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 10,
      paddingBottom: 10,
    },
    '&.MuiTableRow-root .MuiTableCell-sizeSmall': {
      paddingRight: 0,
      borderBottom: 'none',
    },
  },
  tableContentDataDua: {
    backgroundColor: '#fff',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    border: 'none',
    borderBottom: 'none',
    '&.MuiTableRow-root .MuiTableCell-sizeSmall': {
      paddingRight: 0,
      borderBottom: 'none',
    },
    '&.MuiTableRow-root .MuiTableCell-root.MuiTableCell-body': {
      borderBottom: 'none',
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  tableAccordion: {
    backgroundColor: '#E6ECEF',
    '&.MuiTableRow-root .MuiTableCell-root.MuiTableCell-body': {
      borderBottom: 'none',
      paddingLeft: 80,
      paddingRight: 0,
    },
  },
  tab: {
    paddingTop: 11.5,
    paddingBottom: 11.5,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  textName: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
  },
  textSubName: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    marginLeft: 4,
  },
  tabDate: {
    // marginLeft: 60,
    marginRight: -60,
    paddingRight: 0,
    paddingTop: 11.5,
    paddingBottom: 11.5,
  },
  tabApproval: {
    paddingLeft: 10,
    paddingTop: 11.5,
    paddingBottom: 11.5,
  },
  statusInvalid: {
    backgroundColor: '#969BAB',
    color: '#fff',
    fontSize: 12,
    lineHeight: '26px',
    textAlign: 'center',
    borderRadius: 100,
    paddingLeft: 6,
    paddingRight: 6,
    maxWidth: 65,
    width: '100%',
    textTransform: 'uppercase',
  },
  statusValid: {
    backgroundColor: '#27AE60',
    color: '#fff',
    fontSize: 12,
    lineHeight: '26px',
    textAlign: 'center',
    borderRadius: 100,
    paddingLeft: 6,
    paddingRight: 6,
    maxWidth: 53,
    width: '100%',
    textTransform: 'uppercase',
  },
  textnName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 160,
  },
  textnNameNoPointer: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    // maxWidth: 200,
  },
}));
