import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Grid } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

// Icons
import Avatar from '../../assets/Images/avatar.png';
import TopSidebar from '../../assets/Icons/profileDown.png';
import Logout from '../../assets/Icons/logout.png';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    border: '1px solid #E7E7E8',
    borderRadius: 18,
    width: 319,
    marginTop: 8,
    marginRight: 8,
    position: 'fixed',
  },
  paperMobile: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: '1px solid #E7E7E8',
    borderRadius: 18,
    width: '95vw',
    marginTop: 8,
    marginRight: 15,
    position: 'fixed',
    zIndex: 99999,
  },
  wrapperHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 10,
    paddingTop: 14.5,
    paddingBottom: 14.5,
  },
  wrapperUserText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  avatarImage: {
    width: 44,
    height: 44,
  },
  username: {
    fontSize: 21,
    lineHeight: '32px',
    fontWeight: 'bold',
    color: '#08294C',
    marginLeft: 20,
  },
  userid: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#9CA9B7',
    marginLeft: 20,
  },
  borderSpace: {
    borderTop: '1px solid #E7E7E8',
  },
  wrapperBody: {
    padding: '10px 20px',
  },
  bodyTitle: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#9CA9B7',
  },
  bodySubTitle: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
  },
  wrapperFotter: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 20,
    paddingTop: 23,
    paddingBottom: 23,
    cursor: 'pointer',
  },
  footerText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
    marginLeft: 12.5,
  },
  closeDropdonw: {
    cursor: 'pointer',
  },
}));

export default function DropdownUser({
  open,
  close,
  handleLogout,
  deviceType,
  data,
}) {
  const classes = useStyles();

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={close}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div
            className={
              deviceType === 'mobile' ? classes.paperMobile : classes.paper
            }
          >
            <Grid xs={12} className={classes.wrapperHeader}>
              <Box className={classes.wrapperUserText}>
                <div className={classes.avatarImage}>
                  {data?.user?.avatar_base !== '' ? (
                    <img
                      style={{ width: 44, height: 44 }}
                      src={`data:image/jpeg;base64,${data?.user?.avatar_base}`}
                      alt="image not found"
                    />
                  ) : (
                    <img src={Avatar} alt="image not found" />
                  )}
                </div>
                <Box>
                  <Typography
                    className={classes.username}
                    variant="body1"
                    component="p"
                  >
                    {data?.user?.name}
                  </Typography>
                  <Typography
                    className={classes.userid}
                    variant="body1"
                    component="p"
                  >
                    {`Supplier ID ${data?.user?.supplier_id ?? '-'}`}
                  </Typography>
                </Box>
              </Box>
              <div className={classes.closeDropdonw} onClick={close}>
                <img src={TopSidebar} alt="image not found" />
              </div>
            </Grid>
            <Box className={classes.borderSpace} />
            <Grid xs={12} className={classes.wrapperBody}>
              <Box>
                <Typography
                  className={classes.bodyTitle}
                  variant="body1"
                  component="p"
                >
                  Email
                </Typography>
                <Typography
                  className={classes.bodySubTitle}
                  variant="body1"
                  component="p"
                >
                  {data?.user?.email ?? '-'}
                </Typography>
              </Box>
              <Box style={{ marginTop: 13 }}>
                <Typography
                  className={classes.bodyTitle}
                  variant="body1"
                  component="p"
                >
                  Phone
                </Typography>
                <Typography
                  className={classes.bodySubTitle}
                  variant="body1"
                  component="p"
                >
                  {data?.user?.phone}
                </Typography>
              </Box>
            </Grid>
            <Box className={classes.borderSpace} />
            <Grid xs={12}>
              <Box className={classes.wrapperFotter} onClick={handleLogout}>
                <div className={classes.logoutIcon}>
                  <img src={Logout} alt="image not found" />
                </div>
                <Typography
                  className={classes.footerText}
                  variant="body1"
                  component="p"
                >
                  Logout
                </Typography>
              </Box>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
