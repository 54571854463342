import { makeStyles, Box, Typography } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

export const TabelLoading = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.tableLoadingContent}>
        <Box>
          <Box>
            <LinearProgress />
            <Typography
              className={classes.loadingText}
              variant="body1"
              component="p"
            >
              Loading GET Data
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export const TableNoData = ({ colspan }) => {
  const classes = useStyles();
  return (
    <>
      <TableRow className={classes.tableLoadingContent}>
        <TableCell colSpan={colspan}>
          <Box>
            <Typography
              className={classes.noDataText}
              variant="body1"
              component="p"
            >
              No data
            </Typography>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export const NoData = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.NoDataContainer}>
        <Box>
          <Typography
            className={classes.noDataText}
            variant="body1"
            component="p"
          >
            No data
          </Typography>
        </Box>
      </Box>
    </>
  );
};

const useStyles = makeStyles({
  tableLoadingContent: {
    backgroundColor: '#F8FAFB',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    paddingBottom: 20,
  },
  loadingText: {
    marginTop: 20,
    textAlign: 'center',
    fontSize: 18,
    lineHeight: '27px',
  },

  noDataText: {
    textAlign: 'center',
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  NoDataContainer: {
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
});
