import React from 'react';
import { makeStyles, TextareaAutosize } from '@material-ui/core';

export const InputArea = ({ value, setTextNote }) => {
  const classes = useStyles();
  return (
    <TextareaAutosize
      className={classes.inputMenuTextArea}
      placeholder="Write a note..."
      minRows={3}
      cols={12}
      value={value}
      onChange={(e) => setTextNote(e.target.value)}
      maxLength={190}
    />
  );
};

export const InputAreaSecond = ({ name, value, onChange }) => {
  const classes = useStyles();
  return (
    <TextareaAutosize
      className={classes.inputMenuTextArea}
      placeholder="Write a note..."
      minRows={3}
      cols={12}
      name={name}
      value={value}
      onChange={onChange}
      maxLength={190}
    />
  );
};

const useStyles = makeStyles({
  inputMenuTextArea: {
    width: '100% !important',
    paddingLeft: 15,
    paddingTop: 7,
    paddingRight: 21,
    borderRadius: 8,
    border: '1px solid #E7E7E8',
    backgroundColor: '#F8FAFB',
    outline: 'none',
    '&:focus': {
      border: '1.5px solid #F2C94C',
      outline: 'none',
      boxShadow: '0px 0px 0px 4px rgba(242, 201, 76, 0.17);',
      borderRadius: 8,
    },
  },
});
