import React from 'react';
import { makeStyles, Grid, Box } from '@material-ui/core';
import * as po from '../../../../redux/PO/actions';
import { useSelector, useDispatch } from 'react-redux';

// Components
import { ContentText } from '../../../../libs/Typography';
import InputGroup from '../../../../components/inputGroup';
import { CircleLoadingSecondary } from '../../../../components/Loading/CircleLoading';

// Icons
import DownArrow from '../../../../assets/Icons/downArrow.png';
import Search from '../../../../assets/Icons/Search.png';
import Cross from '../../../../assets/Icons/cross.png';

const SearchPo = ({ onSetPO, detail, dataPO }) => {
  const classes = useStyles();
  const dispacth = useDispatch();

  // Global Variable
  const { access_token, data } = useSelector((state) => state.auth);

  const { data_po, search_poIsLoading, search_poData } = useSelector(
    (state) => state.po
  );

  const [open, setOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [PONumber, setPONumber] = React.useState(null);
  const [POData, setPOData] = React.useState([]);

  const handleOpenModal = () => {
    setOpen(!open);
  };

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleOnclick = (item) => {
    setPONumber(item);
    onSetPO(item);
    setOpen(false);
  };

  const handleRemovePO = () => {
    onSetPO(null);
    setPONumber(null);
  };

  React.useEffect(() => {
    let SendData = {
      doc_num: searchText,
      page: 1,
      limit: 50,
      is_draft: 0,
      supplier_id: data?.user?.supplier_id,
      order_dir: 'desc',
      order_by: 'id',
      is_proforma: 1,
      status: 'approved',
    };
    dispacth(po.searchPOdata(access_token, SendData));
  }, []);

  React.useEffect(() => {
    if (searchText !== '') {
      let SendData = {
        doc_num: searchText,
        page: 1,
        is_draft: 0,
        supplier_id: data?.user?.supplier_id,
        order_dir: 'desc',
        order_by: 'id',
        is_proforma: 1,
        status: 'approved',
      };
      dispacth(po.searchPOdata(access_token, SendData));
    }
  }, [searchText]);

  React.useEffect(() => {
    setPOData(search_poData?.data);
  }, [search_poData]);

  React.useEffect(() => {
    if (dataPO) {
      setPONumber(dataPO);
    }
  }, [dataPO]);

  return (
    <>
      <Box className={classes.SectionContainer}>
        <ContentText content={`PO No`} styling={classes.label} />
        <Box
          className={classes.boxPO}
          onClick={detail ? null : handleOpenModal}
          onBlur={() => setOpen(false)}
        >
          <ContentText
            content={PONumber?.doc_num ?? `Select`}
            styling={classes.placeholderPO}
          />
          {PONumber ? (
            <img
              src={Cross}
              alt="none"
              className={classes.iconCross}
              onClick={handleRemovePO}
            />
          ) : (
            <img src={DownArrow} alt="none" className={classes.icon} />
          )}
        </Box>
        {open && (
          <Box className={classes.card}>
            <Box className={classes.inputContent}>
              <input
                value={searchText}
                onChange={handleChange}
                className={classes.inputSearch}
                placeholder="Select"
              />

              {search_poIsLoading ? (
                <Box className={classes.loadingContent}>
                  <CircleLoadingSecondary />
                </Box>
              ) : (
                <img src={Search} alt="none" className={classes.iconSearch} />
              )}
            </Box>
            <Box className={classes.contentList}>
              {POData?.map((item, index) => (
                <Box
                  key={index}
                  className={classes.contentItem}
                  onClick={() => handleOnclick(item)}
                >
                  {item?.doc_num}
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

const SectionSearch = (props) => {
  const classes = useStyles();
  const { register, errors, handleSetDataPO, detail, dataPO } = props;

  return (
    <Grid xs={12} md={12} className={classes.container}>
      <ContentText content={`PURCHASE ORDER`} styling={classes.titleSection} />
      <Grid container className={classes.mainInput}>
        <Grid xs={6} md={4} item className={classes.inputPO}>
          {detail ? (
            <SearchPo onSetPO={handleSetDataPO} detail dataPO={dataPO} />
          ) : (
            <SearchPo onSetPO={handleSetDataPO} />
          )}
        </Grid>
        <Grid xs={6} md={4} item>
          <InputGroup
            label="PoType"
            expectLabel="PO Type"
            register={register}
            type="text"
            isRequire={true}
            align="right"
            disable={true}
          />
        </Grid>
        <Grid xs={6} md={4} item className={classes.inputAmount}>
          <InputGroup
            label="Amount"
            placeholder="Enter Amount"
            register={register}
            required
            type="text"
            isRequire={true}
            align="right"
            disable={true}
          />
          <p className={classes.errorText}>{errors?.Amount?.message}</p>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
    backgroundColor: '#fff',
    borderTop: '1px solid #E7E7E8',
    borderBottom: '1px solid #E7E7E8',
  },
  titleSection: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 700,
    color: '#85878E',
  },
  mainInput: {
    marginTop: 20,
  },
  inputPO: {
    paddingRight: 15,
  },
  inputAmount: {
    paddingLeft: 15,
  },
  label: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 700,
    color: '#1B2030',
  },
  // section box PO
  SectionContainer: {
    position: 'relative',
  },
  boxPO: {
    marginTop: 12,
    width: '100%',
    height: 50,
    border: '1px solid #E7E7E8',
    background: '#F8FAFB',
    borderRadius: 8,
    cursor: 'pointer',
    position: 'relative',
    padding: '14px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  placeholderPO: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 400,
    color: '#969BAB',
  },
  icon: {
    width: 14,
    height: 12,
  },
  iconCross: {
    width: 20,
    height: 20,
  },
  card: {
    marginTop: 4,
    width: '100%',
    background: '#fff',
    position: 'absolute',
    borderRadius: 8,
    border: ' 1px solid #E7E7E8',
    padding: '5px 11px',
    minHeight: 150,
    maxHeight: 202,
  },
  inputContent: {
    position: 'relative',
  },
  inputSearch: {
    width: '100%',
    borderRadius: 8,
    height: 40,
    padding: '9.5px 10px',
    background: '#F8FAFB',
    border: '1px solid #E7E7E8',
    '&:focus': {
      border: '1.5px solid #F2C94C',
      outline: 'none',
      boxShadow: '0px 0px 0px 4px rgba(242, 201, 76, 0.17);',
      borderRadius: 8,
    },
  },
  iconSearch: {
    position: 'absolute',
    right: 0,
    marginTop: 10,
    marginRight: 10,
  },
  contentList: {
    marginTop: 6,
    maxHeight: `calc(202px - 54px)`,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
  },
  contentItem: {
    height: 46,
    width: '100%',
    cursor: 'pointer',
    paddingTop: '15px',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '21px',
    color: '#1B2030',
  },
  loadingContent: {
    position: 'absolute',
    right: 0,
    marginTop: '-30px',
    marginRight: 10,
  },
  errorText: {
    fontSize: 14,
    color: 'red',
    marginTop: 8,
  },
}));

export default SectionSearch;
