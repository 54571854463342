import * as types from './types';

// Just Example
export const drawerReducers = (
  state = {
    isOpen: false,
  },
  action
) => {
  switch (action.type) {
    case types.SET_DRAWER:
      return { ...state, isOpen: action.payload };
    default:
      return state;
  }
};
