import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';

const ButtonQrCode = ({ showModal, handleCanclePR, onPress, isOn, title, hiddenCancel }) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.btnContent}>
        <Box>
          <Box
            className={isOn ? classes.btnQrCode : classes.btn}
            onClick={isOn ? onPress : null}
          >
            <Typography
              className={classes.btnText}
              variant="body1"
              component="p"
            >
              Generated QRCode
            </Typography>
          </Box>
        </Box>
        {!hiddenCancel && (
        <Box>
          <Box className={classes.btnCancel} onClick={handleCanclePR}>
            <Typography
              className={classes.btnCancelText}
              variant="body1"
              component="p"
            >
              {`Cancel ${title ?? 'PR'}`}
            </Typography>
          </Box>
        </Box>)}
      </Box>
    </>
  );
};

const useStyles = makeStyles({
  btnContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingTop: 26,
    paddingBottom: 16,
    paddingRight: 20,
    paddingLeft: 20,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
  },
  btnQrCode: {
    cursor: 'pointer',
    backgroundColor: '#1B2030',
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 14,
    paddingBottom: 14,
    borderRadius: 8,
  },
  btn: {
    // cursor: 'pointer',
    backgroundColor: '#969BAB',
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 14,
    paddingBottom: 14,
    borderRadius: 8,
  },
  btnCancel: {
    cursor: 'pointer',
    backgroundColor: '#fff',
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 14,
    paddingBottom: 14,
    borderRadius: 8,
  },
  btnCancelText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#FD453A',
  },
  btnText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
  },
});

export default ButtonQrCode;
