import * as types from './types';

import request from '../../utils/request';

export const getSAPnotification = (token) => (dispatch) => {
  dispatch({ type: types.APDP_NOTIF_LOADING, payload: true });

  request({
    method: 'get',
    url: 'apdp-invoice/notification',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get notif');
      dispatch({ type: types.APDP_NOTIF_DATA, payload: res?.data?.draft });
      dispatch({
        type: types.APDP_NOTIF_DASHBOARD_DATA,
        payload: res?.data?.draft,
      });
      dispatch({ type: types.APDP_NOTIF_MESSAGE, payload: res?.message });
      dispatch({ type: types.APDP_NOTIF_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get notif apdp', err);
      dispatch({ type: types.APDP_NOTIF_LOADING, payload: false });
    });
};

export const getAPDPdata = (token, params) => (dispatch) => {
  dispatch({ type: types.APDP_DATA_LOADING, payload: true });

  request({
    method: 'get',
    url: `apdp-invoice`,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get APDP data');

      dispatch({ type: types.APDP_SET_DATA, payload: res?.data });
      dispatch({ type: types.APDP_DATA_MESSAGE, payload: res?.message });
      dispatch({ type: types.APDP_DATA_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get data APDP', err);
      dispatch({ type: types.APDP_DATA_LOADING, payload: false });
    });
};

export const getAPDPDetail = (token, id) => (dispatch) => {
  dispatch({ type: types.APDP_DETAIL_LOADING, payload: true });

  request({
    method: 'get',
    url: `apdp-invoice/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get APDP Detail data');
      // console.log(res);
      dispatch({ type: types.APDP_DETAIL_DATA, payload: res?.data });
      dispatch({ type: types.APDP_DETAIL_MESSAGE, payload: res?.message });
      dispatch({ type: types.APDP_DETAIL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get data APDP detail', err);
      dispatch({ type: types.APDP_DETAIL_LOADING, payload: false });
    });
};

export const getAPDPSAPdata = (token, params) => (dispatch) => {
  dispatch({ type: types.APDP_SYNC_LOADING, payload: true });

  request({
    method: 'get',
    url: `apdp-invoice`,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get SAP APDP data');

      dispatch({ type: types.APDP_SYNC_DATA, payload: res?.data });
      dispatch({ type: types.APDP_SYNC_MESSAGE, payload: res?.message });
      dispatch({ type: types.APDP_SYNC_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get SAP data APDP', err);
      dispatch({ type: types.APDP_SYNC_LOADING, payload: false });
    });
};

export const deleteAPDP = (token, id) => (dispatch) => {
  dispatch({ type: types.APDP_DELETE_LOADING, payload: true });

  request({
    method: 'delete',
    url: `apdp-invoice/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('deleted apdp');
      // console.log(res);
      dispatch({ type: types.APDP_DELETE_MESSAGE, payload: res?.message });
      dispatch({ type: types.APDP_DELETE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error delete APDP', err);
      dispatch({
        type: types.APDP_DELETE_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: types.APDP_DELETE_LOADING, payload: false });
    });
};

export const setBlankDelete = () => (dispatch) => {
  dispatch({ type: types.APDP_DELETE_MESSAGE, payload: '' });
};

export const publishAPDP = (token, data) => (dispatch) => {
  dispatch({ type: types.APDP_PUBLISH_LOADING, payload: true });

  request({
    method: 'put',
    url: `apdp-invoice/publish`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('publish apdp data');
      dispatch({ type: types.APDP_PUBLISH_MESSAGE, payload: res?.message });
      dispatch({ type: types.APDP_PUBLISH_LOADING, payload: false });
      dispatch(getSAPnotification(token));
    })
    .catch((err) => {
      console.log('error publish apdp ', err);
      dispatch({ type: types.APDP_PUBLISH_LOADING, payload: false });
    });
};

export const setBlankPublish = () => (dispatch) => {
  dispatch({ type: types.APDP_PUBLISH_MESSAGE, payload: '' });
};

export const cancelAPDP = (token, id) => (dispatch) => {
  dispatch({ type: types.APDP_CANCEL_LOADING, payload: true });

  request({
    method: 'put',
    url: `apdp-invoice/${id}/cancel`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('Cancel apdp');
      dispatch({ type: types.APDP_CANCEL_MESSAGE, payload: res?.message });
      dispatch({ type: types.APDP_CANCEL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error cancel apdp ', err);
      dispatch({
        type: types.APDP_CANCEL_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: types.APDP_CANCEL_LOADING, payload: false });
    });
};

export const setBlankCancel = () => (dispatch) => {
  dispatch({ type: types.APDP_CANCEL_MESSAGE, payload: '' });
};

export const assigmentAPDP = (token, id, data) => (dispatch) => {
  dispatch({ type: types.APDP_ASSIGNMENT_LOADING, payload: true });

  request({
    method: 'put',
    url: `apdp-invoice/${id}/confirm-assignment`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('confirm assigment apdp data');
      // console.log(res);
      dispatch({ type: types.APDP_ASSIGNMENT_MESSAGE, payload: res?.message });
      dispatch({ type: types.APDP_ASSIGNMENT_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error confirm assigment apdp ', err);
      dispatch({
        type: types.APDP_ASSIGNMENT_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: types.APDP_ASSIGNMENT_LOADING, payload: false });
    });
};

export const setBlankAssigment = () => (dispatch) => {
  dispatch({ type: types.APDP_ASSIGNMENT_MESSAGE, payload: '' });
};
