import * as types from './types';

// Just Example
export const carReducers = (
  state = {
    isLoading: false,
    car_message: '',
    car_data: '',
    car_sendIsLoading: false,
    car_sendMessage: '',
    car_sendData: null,
    car_approvalIsLoading: false,
    car_approvalData: null,
    car_detailIsLoading: false,
    car_detail: null,
    car_updateIsLoading: false,
    car_updateMessage: '',
    car_updateData: null,
    car_deleteIsLoading: false,
    car_deleteMessage: '',
  },
  action
) => {
  switch (action.type) {
    // get all car
    case types.CAR_GET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case types.CAR_GET_DATA:
      return {
        ...state,
        car_data: action.payload,
      };
    // save data car
    case types.CAR_SAVE_LOADING:
      return {
        ...state,
        car_sendIsLoading: action.payload,
      };
    case types.CAR_SAVE_MESSAGE:
      return {
        ...state,
        car_sendMessage: action.payload,
      };
    case types.CAR_SAVE_DATA:
      return {
        ...state,
        car_sendData: action.payload,
      };
    // get approval car
    case types.CAR_APPROVAL_LOADING:
      return {
        ...state,
        car_approvalIsLoading: action.payload,
      };
    case types.CAR_APRROVAL_DATA:
      return {
        ...state,
        car_approvalData: action.payload,
      };
    // get car by id
    case types.CAR_DETAIL_LOADING:
      return {
        ...state,
        car_detailIsLoading: action.payload,
      };
    case types.CAR_DETAIL_DATA:
      return {
        ...state,
        car_detail: action.payload,
      };

    // update car
    case types.CAR_UPDATE_LOADING:
      return {
        ...state,
        car_updateIsLoading: action.payload,
      };
    case types.CAR_UPDATE_MESSAGE:
      return {
        ...state,
        car_updateMessage: action.payload,
      };
    case types.CAR_UPDATE_DATA:
      return {
        ...state,
        car_updateData: action.payload,
      };

    // delete car
    case types.CAR_DELETE_LOADING:
      return {
        ...state,
        car_deleteIsLoading: action.payload,
      };
    case types.CAR_DELETE_MESSAGE:
      return {
        ...state,
        car_deleteMessage: action.payload,
      };
    default:
      return state;
  }
};
