import React from 'react';
import { Grid, Box, makeStyles, Typography } from '@material-ui/core';

const ApprovalItem = ({ index, app, _renderStatusApprove }) => {
  const classes = useStyles();
  return (
    <>
      <Grid
        md={3}
        key={index}
        className={index > 0 ? classes.personHide : classes.wrapperPerson}
      >
        <Box className={classes.statusContent}>
          <Typography
            className={classes.valueText}
            variant="body1"
            component="p"
          >
            {`${index + 1}. ${app?.user?.name}`}
          </Typography>
          <Box>{_renderStatusApprove(app?.approve, app?.reject)}</Box>
        </Box>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  personHide: {
    display: 'none',
  },
  valueText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
    marginRight: 10,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 90,
  },
  statusContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  wrapperPerson: {
    minWidth: 100,
  },
});

export default ApprovalItem;
