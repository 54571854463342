import React from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  withStyles,
  Box,
} from '@material-ui/core';
import { get } from 'lodash';
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { dateFormater, hoursCounting, formatRupiah } from '../../../utils/formatter';

// Component

const TableData = ({ row, index, programFilter, isOP }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  
  return (
    <>
      <TableRow
        className={
          index % 2 == 0
            ? classes.tableContentData
            : classes.tableContentDataDua
        }
      >
        <TableCell>{row.doc_entry}</TableCell>
        { !isOP && (<TableCell>{row.department}</TableCell>)}
        { !isOP && (<TableCell>{row.act_name}</TableCell>)}
        { isOP && (<TableCell>{row.vendor_name}</TableCell>)}
        <TableCell>{row.status}</TableCell>
        <TableCell>{formatRupiah(get(row, ['amount'], ''))}</TableCell>
        <TableCell onClick={() => setOpen(!open)}>
          <Typography className={row.exceed ? classes.wrapperStatusRed : classes.wrapperStatus}>
            {hoursCounting(row.time_lead) ?? '0'}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow className={classes.tabelAccordion}>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: '#E6ECEF',
          }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid md={12} className={classes.wrapperAccordion}>
              <Grid md={12} container className={classes.wrapperCol}>
                <Grid md={3}>
                  <Typography className={classes.textGray}>
                    {`${programFilter} History`}
                  </Typography>
                </Grid>
                <Grid md={9}>
                  <Typography className={classes.textGray}>
                    Approval History
                  </Typography>
                </Grid>
              </Grid>
              {/* Approval 1 */}
              <Grid md={12} container className={classes.wrapperDesc}>
                <Grid md={3}>
                  <Typography className={classes.textBlack}>
                    {`Open Date : ${ row.open_date ? dateFormater(
                      row.open_date,
                      'DD-MM-YYYY HH:mm'
                    ) : '-'}`}
                  </Typography>
                </Grid>
                <Grid md={4}>
                  <Box className={classes.wrapperPerson}>
                    <Typography className={classes.namePerson}>
                      {row.approval_one?.name}
                    </Typography>
                    <Typography className={classes.positionPerson}>
                      {`- ${row.approval_one?.position}`}
                    </Typography>
                  </Box>
                </Grid>
                <Grid md={3} className={classes.textBlack}>
                  {dateFormater(
                    row.approval_one?.create_at,
                    'DD-MM-YYYY HH:mm'
                  )}{' '}
                  -{' '}
                  {dateFormater(
                    row.approval_one?.update_at ?? '01-01-2000',
                    'DD-MM-YYYY HH:mm'
                  )}
                </Grid>
                <Grid md={2}>
                  <Typography className={row.approval_one?.exceed ? classes.wrapperStatusRed : classes.wrapperStatus}>
                    {row.approval_one?.time_lead
                      ? hoursCounting(row.approval_one?.time_lead)
                      : '0'}
                  </Typography>
                </Grid>
              </Grid>
              {/* Approval 2 */}
              <Grid md={12} container className={classes.wrapperDesc}>
                <Grid md={3}>
                  <Typography className={classes.textBlack}>
                    {`Close Date : ${row.close_date ? dateFormater(
                      row.close_date,
                      'DD-MM-YYYY HH:mm'
                    ) : '-'}`}
                  </Typography>
                </Grid>
                <Grid md={4}>
                  <Box className={classes.wrapperPerson}>
                    <Typography className={classes.namePerson}>
                      {row.approval_two?.name}
                    </Typography>
                    <Typography className={classes.positionPerson}>
                      {`- ${row.approval_two.position ?? ''}`}
                    </Typography>
                  </Box>
                </Grid>
                <Grid md={3} className={classes.textBlack}>
                  {row.approval_two?.create_at && dateFormater(
                    row.approval_two?.create_at,
                    'DD-MM-YYYY HH:mm'
                  )}{' '}
                  -{' '}
                  {row.approval_two?.update_at && dateFormater(
                    row.approval_two?.update_at ?? '01-01-2000',
                    'DD-MM-YYYY HH:mm'
                  )}
                </Grid>
                <Grid md={2}>
                  <Typography className={row.approval_two?.exceed ? classes.wrapperStatusRed : classes.wrapperStatus}>
                    {row.approval_two?.time_lead
                      ? hoursCounting(row.approval_two?.time_lead)
                      : '0'}
                  </Typography>
                </Grid>
              </Grid>
              {/* Approval 3 */}
              <Grid md={12} container className={classes.wrapperDesc}>
                <Grid md={3}>
                  <Box className={classes.wrapperPerson}>
                    <Typography className={classes.textBlack}>
                      Lead Time :
                    </Typography>
                    <Typography className={row.exceed ? classes.wrapperStatusLabelRed : classes.wrapperStatusLabel}>
                      {hoursCounting(row.time_lead) ?? '0'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid md={4}>
                  <Box className={classes.wrapperPerson}>
                    <Typography className={classes.namePerson}>
                      {row.approval_three?.name}
                    </Typography>
                    <Typography className={classes.positionPerson}>
                      {`- ${row.approval_three?.position ?? ''}`}
                    </Typography>
                  </Box>
                </Grid>
                  <Grid md={3} className={classes.textBlack}>
                  {row.approval_three?.create_at && dateFormater(
                    row.approval_three?.create_at,
                    'DD-MM-YYYY HH:mm'
                  )}{' '}
                  -{' '}
                  {row.approval_three?.update_at && dateFormater(
                    row.approval_three?.update_at ?? '01-01-2000',
                    'DD-MM-YYYY HH:mm'
                  )}
                  </Grid>
                <Grid md={2}>
                  <Typography className={row.approval_three?.exceed ? classes.wrapperStatusRed : classes.wrapperStatus}>
                    {row.approval_three?.time_lead
                      ? hoursCounting(row.approval_three?.time_lead)
                      : '0'}
                  </Typography>
                </Grid>
              </Grid>
              {/* Approval 4 */}
              {programFilter === 'PR' ? (
                <Grid md={12} container className={classes.wrapperDesc}>
                  <Grid md={3}></Grid>
                  <Grid md={4}>
                    <Box className={classes.wrapperPerson}>
                      <Typography className={classes.namePerson}>
                        {row.approval_four?.name}
                      </Typography>
                      <Typography className={classes.positionPerson}>
                        {`- ${row.approval_four?.position}`}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid md={3} className={classes.textBlack}>
                    {dateFormater(
                      row.approval_four?.create_at,
                      'DD-MM-YYYY HH:mm'
                    )}{' '}
                    -{' '}
                    {dateFormater(
                      row.approval_four?.update_at ?? '01-01-2000',
                      'DD-MM-YYYY HH:mm'
                    )}
                  </Grid>
                  <Grid md={2}>
                    <Typography className={row.approval_four?.exceed ? classes.wrapperStatusRed : classes.wrapperStatus}>
                      {row.approval_four?.time_lead
                        ? hoursCounting(row.approval_four?.time_lead)
                        : '0'}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const useStyles = makeStyles({
  textGray: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    fontWeight: 'bold',
  },
  wrapperAccordion: {
    paddingTop: 21,
    paddingBottom: 23,
  },
  wrapperCol: {
    marginBottom: 17,
  },
  textBlack: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
  },
  wrapperPerson: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  namePerson: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
  },
  positionPerson: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 180,
  },
  wrapperStatus: {
    backgroundColor: '#27AE60',
    textTransform: 'uppercase',
    width: '100%',
    maxWidth: 110,
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 'bold',
    color: '#F8FAFB',
    borderRadius: 100,
    textAlign: 'center',
    paddingTop: 4,
    paddingBottom: 4,
  },
  wrapperStatusRed: {
    backgroundColor: '#FD453A',
    textTransform: 'uppercase',
    width: '100%',
    maxWidth: 110,
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 'bold',
    color: '#F8FAFB',
    borderRadius: 100,
    textAlign: 'center',
    paddingTop: 4,
    paddingBottom: 4,
  },
  wrapperStatusLabel: {
    backgroundColor: '#27AE60',
    textTransform: 'uppercase',
    width: '100%',
    maxWidth: 110,
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 'bold',
    color: '#F8FAFB',
    borderRadius: 100,
    textAlign: 'center',
    paddingTop: 4,
    paddingBottom: 4,
    marginLeft: 8,
  },

  wrapperStatusLabelRed: {
    backgroundColor: '#FD453A',
    textTransform: 'uppercase',
    width: '100%',
    maxWidth: 110,
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 'bold',
    color: '#F8FAFB',
    borderRadius: 100,
    textAlign: 'center',
    paddingTop: 4,
    paddingBottom: 4,
    marginLeft: 8,
  },
  wrapperDesc: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  tableContentData: {
    backgroundColor: '#F8FAFB',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    border: 'none',
    borderBottom: 'none',
    borderLeft: '1px solid #E7E7E8',
    borderRight: '1px solid #E7E7E8',
    height: 60,
    '&.MuiTableRow-root .MuiTableCell-root.MuiTableCell-body': {
      borderBottom: 'none',

      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 10,
      paddingBottom: 10,
    },
    '&.MuiTableRow-root .MuiTableCell-sizeSmall': {
      paddingRight: 0,
      borderBottom: 'none',
    },
  },
  tableContentDataDua: {
    backgroundColor: '#fff',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    border: 'none',
    borderBottom: 'none',
    borderLeft: '1px solid #E7E7E8',
    borderRight: '1px solid #E7E7E8',
    height: 60,
    '&.MuiTableRow-root .MuiTableCell-sizeSmall': {
      paddingRight: 0,
      borderBottom: 'none',
    },
    '&.MuiTableRow-root .MuiTableCell-root.MuiTableCell-body': {
      borderBottom: 'none',
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
});

export default TableData;
