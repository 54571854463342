import React from "react";
import { Grid, makeStyles, Typography, Box } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { SortingASC, SortingDESC } from "../../utils/SortFunc";

// components
import TableData from "./components/TableData";
import TableOpData from "./components/TableOpData";
import { TabelLoading, TableNoData } from "../Loading/TableLoading";
import SortArrow from "../SortArrow";

// icons
import EyeBlue from "../../assets/Icons/eyeBlue.png";

const DashboardTableData = ({
  index,
  router,
  data,
  isLoading,
  setSortingData,
}) => {
  const classes = useStyles();

  const _renderTitle = (index) => {
    if (index === 1) {
      return "PR";
    } else if (index === 2) {
      return "PO";
    } else if (index === 3) {
      return "GRPO";
    } else if (index === 4) {
      return "AP Invoice";
    } else if (index === 5) {
      return "APDP";
    } else {
      return "OP";
    }
  };

  const _ActionViewAll = (index) => {
    if (index === 1) {
      return router.push("/purchase-requistor");
    } else if (index === 2) {
      return router.push("/purchase-order");
    } else if (index === 3) {
      return router.push("/grpo");
    } else if (index === 4) {
      return router.push("/ap-invoice");
    } else if (index === 5) {
      return router.push("/ap-downpayment");
    } else {
      return router.push("/outgoing-payment");
    }
  };

  const opHeadTable = () => {
    return(
      <TableHead>
        <TableRow>
          <TableCell>
            <Box className={classes.wrapperTableHeader}>
              <Typography className={classes.headeText}>No.</Typography>
              <SortArrow
                pressUp={() => SortingASC(data, "docNum", setSortingData)}
                pressDown={() => SortingDESC(data, "docNum", setSortingData)}
              />
            </Box>
          </TableCell>
          <TableCell>
            <Box className={classes.wrapperTableHeader}>
              <Typography className={classes.headeText}>
                Vendor Name
              </Typography>
              <SortArrow
                pressUp={() => SortingASC(data, "vendor", setSortingData)}
                pressDown={() => SortingDESC(data, "vendor", setSortingData)}
              />
            </Box>
          </TableCell>
          <TableCell>
            <Box className={classes.wrapperTableHeader}>
              <Typography className={classes.headeText}>Total Payment</Typography>
              <SortArrow
                pressUp={() => SortingASC(data, "total", setSortingData)}
                pressDown={() =>
                  SortingDESC(data, "total", setSortingData)
                }
              />
            </Box>
          </TableCell>
          <TableCell>
            <Box className={classes.wrapperTableHeader}>
              <Typography className={classes.headeText}>Status</Typography>
              <SortArrow
                pressUp={() => SortingASC(data, "status", setSortingData)}
                pressDown={() => SortingDESC(data, "status", setSortingData)}
              />
            </Box>
          </TableCell>
          <TableCell>
            <Box className={classes.wrapperTableHeader}>
              <Typography className={classes.headeText}>Date</Typography>
              <SortArrow
                pressUp={() => SortingASC(data, "date", setSortingData)}
                pressDown={() => SortingDESC(data, "date", setSortingData)}
              />
            </Box>
          </TableCell>
        </TableRow>
      </TableHead>
    )
  }

  const headTable = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell>
            <Box className={classes.wrapperTableHeader}>
              <Typography className={classes.headeText}>No.</Typography>
              <SortArrow
                pressUp={() => SortingASC(data, "docNum", setSortingData)}
                pressDown={() => SortingDESC(data, "docNum", setSortingData)}
              />
            </Box>
          </TableCell>
          <TableCell>
            <Box className={classes.wrapperTableHeader}>
              <Typography className={classes.headeText}>
                Purpose of Request
              </Typography>
              <SortArrow
                pressUp={() => SortingASC(data, "request", setSortingData)}
                pressDown={() => SortingDESC(data, "request", setSortingData)}
              />
            </Box>
          </TableCell>
          <TableCell>
            <Box className={classes.wrapperTableHeader}>
              <Typography className={classes.headeText}>Department</Typography>
              <SortArrow
                pressUp={() => SortingASC(data, "department", setSortingData)}
                pressDown={() =>
                  SortingDESC(data, "department", setSortingData)
                }
              />
            </Box>
          </TableCell>
          <TableCell>
            <Box className={classes.wrapperTableHeader}>
              <Typography className={classes.headeText}>Entity</Typography>
              <SortArrow
                pressUp={() => SortingASC(data, "entity", setSortingData)}
                pressDown={() => SortingDESC(data, "entity", setSortingData)}
              />
            </Box>
          </TableCell>
          <TableCell>
            <Box className={classes.wrapperTableHeader}>
              <Typography className={classes.headeText}>Activity</Typography>
              <SortArrow
                pressUp={() => SortingASC(data, "activity", setSortingData)}
                pressDown={() => SortingDESC(data, "activity", setSortingData)}
              />
            </Box>
          </TableCell>
          <TableCell>
            <Box className={classes.wrapperTableHeader}>
              <Typography className={classes.headeText}>Grand Total</Typography>
              <SortArrow
                pressUp={() => SortingASC(data, "total", setSortingData)}
                pressDown={() => SortingDESC(data, "total", setSortingData)}
              />
            </Box>
          </TableCell>
          <TableCell>
            <Box className={classes.wrapperTableHeader}>
              <Typography className={classes.headeText}>Status</Typography>
              <SortArrow
                pressUp={() => SortingASC(data, "status", setSortingData)}
                pressDown={() => SortingDESC(data, "status", setSortingData)}
              />
            </Box>
          </TableCell>
          <TableCell>
            <Box className={classes.wrapperTableHeader}>
              <Typography className={classes.headeText}>Date</Typography>
              <SortArrow
                pressUp={() => SortingASC(data, "date", setSortingData)}
                pressDown={() => SortingDESC(data, "date", setSortingData)}
              />
            </Box>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const isOP = _renderTitle(index) === 'OP'

  return (
    <>
      <Grid container>
        <Grid xs={12} md={12}>
          <div className={classes.titleTableContent}>
            <Typography
              className={classes.titleTable}
              variant="body1"
              component="p"
            >
              {`${_renderTitle(index)} Last Submitted`}
            </Typography>
            <div
              className={classes.linkTable}
              onClick={() => _ActionViewAll(index)}
            >
              <img src={EyeBlue} alt="image not found" />
              <Typography className={classes.linkTableText}>
                View All
              </Typography>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <Grid md={12} classes={classes.wrapperTable}>
              <TableContainer className={classes.tableContainer}>
                <Table>
                  {isOP ? opHeadTable() : headTable()}
                  <TableBody>
                    {isLoading ? null : data?.length > 0 ? (
                      data.map((row, index) => (
                        isOP ? <TableOpData row={row} index={index} key={index} /> : <TableData row={row} index={index} key={index} />
                      ))
                    ) : (
                      <TableNoData colspan={8} />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {isLoading ? <TabelLoading /> : null}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  titleTableContent: {
    paddingRight: 22,
    paddingLeft: 22,
    paddingTop: 27,
    paddingBottom: 27,
    backgroundColor: "#ffff",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  wrapperTableHeader: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  titleTable: {
    fontSize: 18,
    lineHeight: "27px",
    fontWeight: "bold",
    color: "#969BAB",
  },
  linkTable: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    cursor: "pointer",
  },
  linkTableText: {
    textDecorationLine: "none",
    color: "#127EEE",
    fontSize: 14,
    lineHeight: "26px",
    fontWeight: "bold",
    marginLeft: 10,
  },
  // custome table
  wrapperTableHeader: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  headeText: {
    fontSize: 16,
    lineHeight: "30px",
    fontWeight: "bold",
    color: "#000000",
    marginRight: 4,
  },
  tableContainer: {
    overflowX: "auto",
    width: "100%",
    "& .MuiTableRow-root.MuiTableRow-head": {
      backgroundColor: "#fff",
    },
    "& .MuiTableRow-head .MuiTableCell-head": {
      borderBottom: "none",
    },
    "& .MuiTableCell-root.MuiTableCell-head:nth-child(1)": {
      minWidth: 100,
    },
    "& .MuiTableCell-root.MuiTableCell-head:nth-child(2)": {
      minWidth: 250,
    },
    "& .MuiTableCell-root.MuiTableCell-head:nth-child(3)": {
      minWidth: 120,
    },
    "& .MuiTableCell-root.MuiTableCell-head:nth-child(4)": {
      minWidth: 120,
    },
    "& .MuiTableCell-root.MuiTableCell-head:nth-child(5)": {
      minWidth: 140,
    },
    "& .MuiTableCell-root.MuiTableCell-head:nth-child(6)": {
      minWidth: 160,
    },
    "& .MuiTableCell-root.MuiTableCell-head:nth-child(7)": {
      minWidth: 160,
    },
    "& .MuiTableCell-root.MuiTableCell-head:nth-child(8)": {
      minWidth: 180,
    },
    "&::-webkit-scrollbar": {
      width: "3px",
      height: "3px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
    },
  },
});

export default DashboardTableData;
