import React from 'react';
import { makeStyles, Grid, Box, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

// Icons

import PdfFile from '../../assets/Icons/pdfIcons.png';

const SKGRPODetail = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Grid md={12}>
          <Box className={classes.formTitle}>
            <Skeleton width="100%">
              <Typography>.</Typography>
            </Skeleton>
            <Box className={classes.wrapperStatusForm}>
              <Typography
                className={classes.bodyDataText}
                variant="body1"
                component="p"
              >
                <Skeleton width="100%">
                  <Typography>.</Typography>
                </Skeleton>
              </Typography>
            </Box>
          </Box>
          <Box className={classes.borderLine} />
        </Grid>
        <Grid md={8}>
          <Box className={classes.wrapperInformation}>
            <Box className={classes.informationContent}>
              <Box className={classes.informationHeader}>
                <Typography
                  className={classes.headerText}
                  variant="body1"
                  component="p"
                >
                  View GRPO
                </Typography>
                <Box className={classes.informationHeaderLink}>
                  <img
                    className={classes.bellImage}
                    src={PdfFile}
                    alt="image not found"
                  />
                  <Typography
                    className={classes.headerLink}
                    variant="body1"
                    component="p"
                  >
                    View GRPO
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.infromationBody}>
                <Grid container md={12}>
                  <Grid md={6}>
                    <Box className={classes.wrapperBody}>
                      <Box className={classes.bodyContent}>
                        <Typography
                          className={classes.bodyTitle}
                          variant="body1"
                          component="p"
                        >
                          PO No.
                        </Typography>
                      </Box>
                      <Skeleton width="100%">
                        <Typography>.</Typography>
                      </Skeleton>
                    </Box>
                  </Grid>
                  <Grid md={6}>
                    <Box className={classes.wrapperBody}>
                      <Box className={classes.bodyContent}>
                        <Typography
                          className={classes.bodyTitle}
                          variant="body1"
                          component="p"
                        >
                          Purpose of Request
                        </Typography>
                      </Box>
                      <Skeleton width="100%">
                        <Typography>.</Typography>
                      </Skeleton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box className={classes.infromationBody}>
                <Grid container md={12}>
                  <Grid md={6}>
                    <Box className={classes.wrapperBody}>
                      <Box className={classes.bodyContent}>
                        <Typography
                          className={classes.bodyTitle}
                          variant="body1"
                          component="p"
                        >
                          Currency
                        </Typography>
                      </Box>
                      <Skeleton width="100%">
                        <Typography>.</Typography>
                      </Skeleton>
                    </Box>
                  </Grid>
                  <Grid md={6}>
                    <Box className={classes.wrapperBody}>
                      <Box className={classes.bodyContent}>
                        <Typography
                          className={classes.bodyTitle}
                          variant="body1"
                          component="p"
                        >
                          Grand Total
                        </Typography>
                      </Box>
                      <Skeleton width="100%">
                        <Typography>.</Typography>
                      </Skeleton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box className={classes.infromationBody}>
                <Grid container md={12}>
                  <Grid md={6}>
                    <Box className={classes.wrapperBody}>
                      <Box className={classes.bodyContent}>
                        <Typography
                          className={classes.bodyTitle}
                          variant="body1"
                          component="p"
                        >
                          Department
                        </Typography>
                      </Box>
                      <Skeleton width="100%">
                        <Typography>.</Typography>
                      </Skeleton>
                    </Box>
                  </Grid>
                  <Grid md={6}>
                    <Box className={classes.wrapperBody}>
                      <Box className={classes.bodyContent}>
                        <Typography
                          className={classes.bodyTitle}
                          variant="body1"
                          component="p"
                        >
                          Entity
                        </Typography>
                      </Box>
                      <Skeleton width="100%">
                        <Typography>.</Typography>
                      </Skeleton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box className={classes.infromationBody}>
                <Grid container md={12}>
                  <Grid md={6}>
                    <Box className={classes.wrapperBody}>
                      <Box className={classes.bodyContent}>
                        <Typography
                          className={classes.bodyTitle}
                          variant="body1"
                          component="p"
                        >
                          Activity
                        </Typography>
                      </Box>
                      <Skeleton width="100%">
                        <Typography>.</Typography>
                      </Skeleton>
                    </Box>
                  </Grid>
                  <Grid md={6}>
                    <Box className={classes.wrapperBody}>
                      <Box className={classes.bodyContent}>
                        <Typography
                          className={classes.bodyTitle}
                          variant="body1"
                          component="p"
                        >
                          Category
                        </Typography>
                      </Box>
                      <Skeleton width="100%">
                        <Typography>.</Typography>
                      </Skeleton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Grid>
        {/* <Box className={classes.borderSide} /> */}
        <Grid md={4} style={{ backgroundColor: '#fff' }}>
          <Box className={classes.assignApprovalContent}>
            <Box className={classes.assignHeader}>
              <Typography
                className={classes.headerTitle}
                variant="body1"
                component="p"
              >
                Approve By
              </Typography>
            </Box>

            <Box className={classes.filterActifityContent}>
              <Box>
                <Box className={classes.bodyContent}>
                  <Typography
                    className={classes.bodyTitle}
                    variant="body1"
                    component="p"
                  >
                    Prepared By
                  </Typography>
                </Box>
                <Skeleton width="100%">
                  <Typography>.</Typography>
                </Skeleton>
              </Box>
            </Box>

            <Box className={classes.filterActifityContent}>
              <Box>
                <Box className={classes.bodyContent}>
                  <Typography
                    className={classes.bodyTitle}
                    variant="body1"
                    component="p"
                  >
                    Reviewed By
                  </Typography>
                </Box>
                <Skeleton width="100%">
                  <Typography>.</Typography>
                </Skeleton>
              </Box>
            </Box>

            <Box className={classes.filterActifityContent}>
              <Box>
                <Box className={classes.bodyContent}>
                  <Typography
                    className={classes.bodyTitle}
                    variant="body1"
                    component="p"
                  >
                    Approved By
                  </Typography>
                </Box>
                <Skeleton width="100%">
                  <Typography>.</Typography>
                </Skeleton>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box className={classes.borderLine} />
      <Grid md={12}></Grid>
    </>
  );
};

const useStyles = makeStyles({
  formTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  borderLine: {
    borderBottom: '1px solid #E7E7E8',
  },
  formNumber: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#1B2030',
  },
  formStatus: {
    fontSize: 14,
    lineHeight: '26px',
    color: '#969BAB',
  },
  // styling status
  statusNeed: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#F12B2C',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  statusWaiting: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#FFB800',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  statusClose: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    background: '#969BAB',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  wrapperInformation: {
    paddingTop: 27,
    paddingBottom: 59,
    backgroundColor: '#fff',
  },
  informationContent: {
    // marginTop: 27,
    paddingLeft: 20,
    paddingRight: 18.5,
    backgroundColor: '#fff',
    // marginBottom: 58,
    borderRight: '1px solid #E7E7E8',
  },
  informationHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  informationHeaderLink: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
  },
  headerText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  headerLink: {
    fontSize: 14,
    lineHeight: '26px',
    fontWeight: 'bold',
    color: '#127EEE',
    marginLeft: 10,
  },
  infromationBody: {
    marginTop: 18.5,
    marginLeft: -10,
    marginRight: -10,
  },
  wrapperBody: {
    paddingRight: 10,
    paddingLeft: 10,
  },
  bodyTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
    marginBottom: 12,
  },
  bodyData: {
    width: '100%',
    backgroundColor: '#EBEFF0',
    padding: '12px 20px',
    border: '1px solid #E7E7E8',
    borderRadius: 8,
  },
  wrapperBodyGrandTotal: {},
  infromationAttachment: {
    marginTop: 30,
  },
  AttachmentTitle: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    fontWeight: 'bold',
  },
  AttachmentNotif: {
    marginTop: 20,
    marginBottom: 20,
    padding: 10,
    borderRadius: 4,
    backgroundColor: '#FFE9E8',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  AttachmentMessage: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#F12B2C',
  },
  btnUploadContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  btnUploadText: {
    fontSize: 14,
    lineHeight: '26px',
    color: '#1B2030',
  },
  btnUpload: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: '#127EEE',
    paddingLeft: 19,
    paddingRight: 19,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 8,
  },
  btnUploadValue: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
    marginLeft: 13,
  },
  assignApprovalContent: {
    paddingTop: 26,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 26,
    backgroundColor: '#fff',
    // borderBottom: '1px solid #E7E7E8',
  },
  headerTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  filterActifityContent: {
    marginTop: 20,
  },
  // styling Form select
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 5,
    paddingBottom: 5,
    // maxWidth: 220,
    width: '100%',
    // minWidth: 160,
    maxHeight: 50,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      // marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#969BAB',
      paddingRight: 2,
      backgroundColor: '#F8FAFB',
    },
  },
  sizeBox: {
    marginTop: 20,
  },
  btnOption: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  wrapperMandatory: {
    paddingLeft: 20,
    paddingTop: 15,
    paddingRight: 30,
    paddingBottom: 15,
    border: '1px solid #E7E7E8',
    borderRadius: 8,
  },
  sizeBoxTwo: {
    marginTop: 30,
  },
  textTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
  },
  titleContent: {
    marginBottom: 12,
  },
  supportContent: {
    marginTop: 20,
    marginBottom: 12,
  },
  uploadTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
  },
  bodyUrlText: {
    marginLeft: 10,
    fontSize: 16,
    lineHeight: '21px',
    textDecorationLine: 'underline',
    color: '#1B2030',
  },
  wrapperLink: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  sizeTitle: {
    marginTop: 21.5,
  },
  sizeSubLink: {
    marginTop: 31,
  },
  boxDepartment: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textTransform: 'ellipsis',
    // maxWidth: '90%',
    paddingRight: 20,
  },
  bodyDataText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
  },
  bodyDataTextBlue: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#127EEE',
    fontWeight: 'bold',
  },
  bodyDataSubText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    marginLeft: 4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  BtnConfirm: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 14.5,
    paddingBottom: 14.5,
    backgroundColor: '#1B2030',
    borderRadius: 8,
    maxWidth: 193,
    marginTop: 20,
    cursor: 'pointer',
  },
  BtnConfirmFalse: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 14.5,
    paddingBottom: 14.5,
    backgroundColor: '#969BAB',
    borderRadius: 8,
    maxWidth: 193,
    marginTop: 20,
    cursor: 'pointer',
  },
  BtnConfirmText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
  },
  wrapperStatusForm: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  formStatus: {
    fontSize: 14,
    lineHeight: '26px',
    color: '#969BAB',
  },
  btnAddMoreUpload: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: 15,
    cursor: 'pointer',
  },
});

export default SKGRPODetail;
