import * as types from './types';

import request from '../../utils/request';

export const getReportData = (token, params) => (dispatch) => {
  dispatch({ type: types.REPORT_GET_LOADING, payload: true });

  request({
    method: 'get',
    url: 'report',
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      // console.log('get proforma data');
      dispatch({ type: types.REPORT_GET_DATA, payload: res?.data });
      dispatch({
        type: types.REPORT_GET_DATA_GRAPH,
        payload: res?.data?.graph,
      });
      dispatch({ type: types.REPORT_GET_MESSAGE, payload: res?.message });
      dispatch({ type: types.REPORT_GET_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get data report', err);
      dispatch({ type: types.REPORT_GET_LOADING, payload: false });
    });
};

export const setBlankData = () => (dispatch) => {
  dispatch({ type: types.REPORT_GET_DATA, payload: null });
  dispatch({
    type: types.REPORT_GET_DATA_GRAPH,
    payload: null,
  });
};

// export csv
export const reportExportCSV = (token, data) => (dispatch) => {
  dispatch({ type: types.REPORT_EXPORT_LOADING, payload: true });

  request({
    method: 'post',
    url: 'report/export',
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    // options,
  })
    .then((res) => {
      console.log('export csv');
      // console.log(res);
      var uri = 'data:text/csv;charset=utf-8,' + res;

      var downloadLink = document.createElement('a');
      downloadLink.href = uri;
      downloadLink.download = `${data.doc_type}.csv`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      dispatch({ type: types.REPORT_EXPORT_MESSAGE, payload: res?.message });
      dispatch({ type: types.REPORT_EXPORT_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error file read', err);
      dispatch({ type: types.REPORT_EXPORT_LOADING, payload: false });
    });
};
