// notif APDP
export const APDP_NOTIF_LOADING = 'APDP/APDP_NOTIF_LOADING';
export const APDP_NOTIF_MESSAGE = 'APDP/APDP_NOTIF_MESSAGE';
export const APDP_NOTIF_DATA = 'APDP/APDP_NOTIF_DATA';
export const APDP_NOTIF_DASHBOARD_DATA = 'APDP/APDP_NOTIF_DASHBOARD_DATA';

// get data APDP
export const APDP_DATA_LOADING = 'APDP/APDP_DATA_LOADING';
export const APDP_DATA_MESSAGE = 'APDP/APDP_DATA_MESSAGE';
export const APDP_SET_DATA = 'APDP/APDP_SET_DATA';

// get apdp by id
export const APDP_DETAIL_LOADING = 'APDP/APDP_DETAIL_LOADING';
export const APDP_DETAIL_MESSAGE = 'APDP/APDP_DETAIL_MESSAGE';
export const APDP_DETAIL_DATA = 'APDP/APDP_DETAIL_DATA';

// get apdd SAP data
export const APDP_SYNC_LOADING = 'APDP/APDP_SYNC_LOADING';
export const APDP_SYNC_MESSAGE = 'APDP/APDP_SYNC_MESSAGE';
export const APDP_SYNC_DATA = 'APDP/APDP_SYNC_DATA';

// delete adpd
export const APDP_DELETE_LOADING = 'APDP/APDP_DELETE_LOADING';
export const APDP_DELETE_MESSAGE = 'APDP/APDP_DELETE_MESSAGE';

// publish apdp
export const APDP_PUBLISH_LOADING = 'APDP/APDP_PUBLISH_LOADING';
export const APDP_PUBLISH_MESSAGE = 'APDP/APDP_PUBLISH_MESSAGE';

// Cancel apdp
export const APDP_CANCEL_LOADING = 'APDP/APDP_CANCEL_LOADING';
export const APDP_CANCEL_MESSAGE = 'APDP/APDP_CANCEL_MESSAGE';

// confirm Assign
export const APDP_ASSIGNMENT_LOADING = 'APDP/APDP_ASSIGNMENT_LOADING';
export const APDP_ASSIGNMENT_MESSAGE = 'APDP/APDP_ASSIGNMENT_MESSAGE';
