import React from 'react';
import { makeStyles, Box, Grid, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useRouter } from '../../../../utils/useRouter';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../../redux/UserManagement/actions';
import axios from 'axios';
import { END_POINT } from '../../../../config/EndPoint';

// Components
import InputGroup from '../../../inputGroup';
import CustomizedSnackbars from '../../../PopupMessage';
import ModalSuccessCreateUser from '../../../ModalSuccessCreateUser';

// Icons
import DefaultAvatar from '../../../../assets/Images/defaultAvatar.png';

// Svg
import { DownArrow } from '../../../../assets/Icons/svg/DownArrow';

const schema = yup.object().shape({
  Username: yup.string().required(),
  Email: yup
    .string()
    .email('Must be a valid email')
    .required('Email is required'),
  // Password: yup.string().required(),
  // ConfirmPWD: yup.string().required(),
});

export const AdminEdit = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispacth = useDispatch();
  const { id } = router.query;

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // handle input file
  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  // Global Variable
  const { access_token } = useSelector((state) => state.auth);
  const { adminProfileData, adminProfileLoading, adminProfileMessage } =
    useSelector((state) => state.userManagement);

  const [dataInput, setDataInput] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [username, setUsername] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [pwd, setPwd] = React.useState('');
  const [selectFile, setSelectFile] = React.useState();
  const [previewImage, setPreviewImage] = React.useState();
  const [isFilePicked, setIsFilePicked] = React.useState(false);
  const watchAllFields = watch(); // when pass nothing as argument, you are watching everything
  const [status, setStatus] = React.useState(10);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => setDataInput(value));
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = async (data) => {
    const { Username, Email, Password, ConfirmPWD } = data;
    if (Password) {
      if (Password !== ConfirmPWD) {
        setType('error');
        setShow(true);
        setNotifText('Password and Confirm Password must be same');
      }
    } else {
      let statusUser = status == 10 ? 'active' : 'nonactive';

      try {
        const sendData = new FormData();

        sendData.append('username', Username);
        sendData.append('email', Email);
        if (Password) {
          sendData.append('password', Password);
        }
        sendData.append('status', statusUser);
        if (selectFile) {
          sendData.append('file', selectFile);
        }

        const config = {
          headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${access_token}`,
          },
        };

        await axios
          .post(`${END_POINT}users/${id}/update`, sendData, config)
          .then((res) => {
            // console.log(res);
            if (res?.data?.message == 'Change User Success') {
              router.push('/user-management');
            } else {
              return;
            }
          })
          .catch((err) => {
            console.log('---Error edit admin User', err);
          });
      } catch (error) {
        console.log('error edit admin profie');
      }

      // dispacth(actions.updateProfileAdmin(access_token, id, sendData));
    }
  };

  // handle message
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };

  const handleCancleBtn = () => {
    router.push('/user-management');
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setSelectFile(fileUploaded);
    setPreviewImage(fileUploaded);
    setIsFilePicked(true);
  };

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  React.useEffect(() => {
    dispacth(actions.getProfileAdmin(access_token, id));
  }, []);

  React.useEffect(() => {
    setValue('Username', adminProfileData?.user?.username);
    setValue('Email', adminProfileData?.user?.email);
    if (adminProfileData?.user?.status === 'active') {
      setStatus(10);
    } else {
      setStatus(20);
    }
  }, [adminProfileData]);

  // React.useEffect(() => {
  //   if (adminProfileMessage == 'Change User Success') {
  //     router.push('/user-management');
  //   } else {
  //     return;
  //   }
  //   dispacth(actions.setBlankMessageAdminEdit());
  // }, [adminProfileMessage]);

  return (
    <>
      <CustomizedSnackbars
        show={show}
        handleClose={handleClose}
        message={notifText}
        type={type}
      />
      <Grid container md={12} style={{ marginBottom: 345 }}>
        {/* <ModalSuccessCreateUser open={showModal} close={handleCloseModal} /> */}
        <Grid md={12} style={{ maxWidth: 1180 }}>
          <Grid md={12} className={classes.wrapperHeader}>
            <Typography
              variant="body1"
              component="p"
              className={classes.headerText}
            >
              Admin Detail
            </Typography>
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid md={12} container>
              <Grid container md={8}>
                <Grid md={12} className={classes.wrapperBody}>
                  <Grid md={12} container>
                    <Grid md={6} style={{ paddingRight: 10 }}>
                      <InputGroup
                        label="Username"
                        placeholder="Write an Username"
                        register={register}
                        required
                        type="text"
                        error={errors.Username ? 'error' : null}
                      />
                      <p className={classes.errorText}>
                        {errors.Username?.message}
                      </p>
                    </Grid>
                    <Grid md={6} style={{ paddingLeft: 10 }}>
                      <InputGroup
                        label="Email"
                        placeholder="Write an email"
                        register={register}
                        required
                        type="text"
                        error={errors.Username ? 'error' : null}
                        disable={true}
                      />
                      <p className={classes.errorText}>
                        {errors.Email?.message}
                      </p>
                    </Grid>
                  </Grid>

                  <Grid md={12} container style={{ marginTop: 20 }}>
                    <Grid md={6} style={{ paddingRight: 10 }}>
                      <InputGroup
                        label="Password"
                        placeholder="Write a Password"
                        register={register}
                        required
                        type="password"
                        error={errors.Password ? 'error' : null}
                      />
                      <p className={classes.errorText}>
                        {errors.Password?.message}
                      </p>
                    </Grid>
                    {dataInput?.Password ? (
                      <Grid md={6} style={{ paddingLeft: 10 }}>
                        <InputGroup
                          label="ConfirmPWD"
                          placeholder="write a Password"
                          register={register}
                          required
                          type="password"
                          error={errors.Password ? 'error' : null}
                        />
                        <p className={classes.errorText}>
                          {errors.ConfirmPWD?.message}
                        </p>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
                <Box className={classes.borderCustom} />
                <Grid md={12} className={classes.wrapperStatusContent}>
                  <Grid md={12} container style={{ marginTop: 20 }}>
                    <Grid md={6} style={{ paddingRight: 10 }} container>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.titleLabel}
                      >
                        Status
                      </Typography>
                      <FormControl className={classes.formControl}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={status}
                          onChange={handleChangeStatus}
                          IconComponent={() => <DownArrow />}
                        >
                          <MenuItem value={10}>Active</MenuItem>
                          <MenuItem value={20}>Inactive</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid md={3} style={{ paddingLeft: 11 }}>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.titleLabel}
                      >
                        Is Logged In?
                      </Typography>
                      <Box>
                        <Typography
                          variant="body1"
                          component="p"
                          className={
                            adminProfileData?.user?.is_logged_in === 1
                              ? classes.descTextStatus
                              : classes.descTextStatusNo
                          }
                        >
                          {adminProfileData?.user?.is_logged_in === 1
                            ? 'Yes'
                            : 'No'}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid md={3}>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.titleLabel}
                      >
                        Last Login
                      </Typography>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.descText}
                      >
                        {adminProfileData?.user?.last_login === null
                          ? '-'
                          : adminProfileData?.user?.last_login}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid md={4} className={classes.wrapperProfile}>
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.profileText}
                >
                  Avatar
                </Typography>
                <Box className={classes.avatarContent}>
                  {isFilePicked && previewImage ? (
                    <img
                      src={URL.createObjectURL(previewImage)}
                      className="imageAvatar"
                      alt="image not found"
                      style={{ width: '100%', height: 'auto' }}
                    />
                  ) : adminProfileData?.user?.avatar_base ? (
                    <img
                      src={`data:image/jpeg;base64,${adminProfileData?.user?.avatar_base}`}
                      className="imageAvatar"
                      alt="image not found"
                      style={{ width: '100%', height: 'auto' }}
                    />
                  ) : (
                    <img
                      src={DefaultAvatar}
                      className="imageAvatar"
                      alt="image not found"
                      style={{ width: '100%', height: 'auto' }}
                    />
                  )}
                </Box>
                <Box className={classes.btnUploadContent}>
                  <Box onClick={handleClick} className={classes.btnUpload}>
                    Choose file
                  </Box>
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    id="img"
                    name="img"
                    accept="image/*"
                    onChange={handleChange}
                    style={{ display: 'none' }}
                  />
                  {isFilePicked ? (
                    <>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.fileText}
                      >
                        {selectFile.name}
                      </Typography>
                    </>
                  ) : (
                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.fileText}
                    >
                      No file Choosen
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Grid md={12} className={classes.wrapperFotter}>
              <input
                className={
                  // dataInput?.Username &&
                  // dataInput?.Email &&
                  // dataInput?.Password &&
                  // dataInput?.ConfirmPWD
                  classes.submitBtn
                  // : classes.submitBtnFalse
                }
                type="submit"
                value="Save Change"
              />
              {/* <Box className={classes.cancelBtn} onClick={handleCancleBtn}>
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.cancleText}
                >
                  Cancel
                </Typography>
              </Box> */}
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  wrapperHeader: {
    padding: 20,
    backgroundColor: '#fff',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottom: '1px solid #E7E7E8',
  },
  headerText: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  wrapperBody: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingBottom: 20,
    paddingRight: 19,
    backgroundColor: '#fff',
  },
  errorText: {
    fontSize: 14,
    color: 'red',
  },
  wrapperFotter: {
    padding: 20,
    backgroundColor: '#fff',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderTop: '1px solid #E7E7E8',
  },
  submitBtn: {
    width: 193,
    height: 50,
    borderRadius: 8,
    backgroundColor: '#1B2030',
    color: '#fff',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  submitBtnFalse: {
    width: 193,
    height: 50,
    borderRadius: 8,
    backgroundColor: '#969BAB',
    color: '#fff',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  cancelBtn: {
    width: 193,
    height: 50,
    marginLeft: 2,
    cursor: 'pointer',
    borderRadius: 8,
    color: '#1B2030',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  wrapperStatusContent: {
    padding: 20,
    backgroundColor: '#fff',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  borderCustom: {
    marginLeft: 20,
    marginRight: 20,
    borderBottom: '1px solid #E7E7E8',
  },
  // Select styling
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 10,
    paddingRight: 5,
    paddingTop: 10,
    height: 50,
    paddingBottom: 5,
    // maxWidth: 220,
    width: '100%',
    // minWidth: 180,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#1B2030',
      paddingRight: 4,
    },
  },
  titleLabel: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
    fontWeight: 'bold',
    marginBottom: 12,
  },
  descText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
    alignSelf: 'center',
    marginTop: 26,
  },
  descTextStatus: {
    fontSize: 12,
    lineHeight: '26px',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: '#27AE60',
    borderRadius: 100,
    width: 47,
    height: 26,
    textAlign: 'center',
    marginTop: 24,
  },
  descTextStatusNo: {
    fontSize: 12,
    lineHeight: '26px',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: '#FD453A',
    borderRadius: 100,
    width: 47,
    height: 26,
    textAlign: 'center',
    marginTop: 24,
  },
  wrapperProfile: {
    backgroundColor: '#fff',
    borderBottom: '1px solid #E7E7E8',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 40,
  },
  profileText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
    fontWeight: 'bold',
  },
  avatarContent: {
    marginTop: 12,
    width: 150,
    height: 150,
    borderRadius: 8,
  },
  btnUploadContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // marginTop: 20,
  },
  btnUpload: {
    backgroundColor: '#127EEE',
    color: '#fff',
    width: 102,
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  fileText: {
    marginLeft: 20,
    fontSize: 14,
    lineHeight: '26px',
    color: '#1B2030',
  },
  textCurrent: {
    marginTop: 20,
    marginBottom: 11.5,
    fontSize: 14,
    lineHeight: '26px',
    color: '#1B2030',
  },
});

export default AdminEdit;
