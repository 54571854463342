import React from 'react';
import { makeStyles, Grid, Box, Checkbox } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import moment from 'moment';
import { useRouter } from '../../utils/useRouter';
import { useSelector, useDispatch } from 'react-redux';
import * as proforma from '../../redux/Proforma/actions';
import { dateFormater } from '../../utils/formatter';
import { get } from 'lodash';

// Components
import { ContentText } from '../../libs/Typography';
import HeaderTitle from './components/HeaderTitle';
import SectionSearch from './components/SectionSearch';
import BAST from './components/BAST';
import BASTITEM from './components/BASTITEM';
import ReportDoc from './components/ReportDoc';
import CustomizedSnackbars from '../../components/PopupMessage';
import ModalConfirm from './components/ModalComfirm';
import ModalSuccess from '../../components/ModalSuccess';

//global
import globalConfig from 'src/config/global';

// icons
import Print from '../../assets/Icons/print.png';
import EyeWhite from '../../assets/Icons/EyeWhite.png';

const schema = yup.object().shape({
  Amount: yup.number().required('field required'),
  PoType: yup.string(),
  NOBASTDKT: yup.string(),
  NOBASTVENDOR: yup.string(),
  JOBDESC: yup.string().required('field required'),
  // pihak dkt
  NamaDKT: yup.string().required('field required'),
  JabatanDKT: yup.string().required('field required'),
  NamaPerusahaanDKT: yup.string().required('field required'),
  DKTAddress: yup.string().required('field required'),
  // pihat vendor
  NamaVendor: yup.string().required('field required'),
  JabatanVendor: yup.string().required('field required'),
  NamaPerusahaanVendor: yup.string().required('field required'),
  VendorAddress: yup.string().required('field required'),
  // sumary
  NamaKegiatan: yup.string().required('field required'),
  Periode: yup.string().required('field required'),
  PONumber: yup.string(),
  AmountBeforeTax: yup.string(),
  TAX: yup.string(),
  TotalWithTax: yup.string(),
  JumlahNilaiPekerjaan: yup.string(),
  SisaPO: yup.number().typeError('Type must be number'),
});

const schemaItem = yup.object().shape({
  Amount: yup.number().required('field required'),
  PoType: yup.string(),
});

const CreateProforma = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispacth = useDispatch();

  // Global Variable
  const { access_token, data } = useSelector((state) => state.auth);
  const { add_message, add_Isloading, add_client_message, add_po_message } =
    useSelector((state) => state.proforma);

  const [dataInput, setDataInput] = React.useState(null);
  const [dataPO, setDataPO] = React.useState(null);
  const [vendorAddress, setVendorAddress] = React.useState('');
  const [DKTAddress, setDKTAddress] = React.useState('');
  const [bastDate, setBastDate] = React.useState({
    day: 0,
    monthNow: 0,
    yearNow: 0,
  });
  const [nilaiBAST, setNilaiBAST] = React.useState([]);
  const [tempCategory, setTempCategory] = React.useState([]);
  const [tempFileDKT, setTempFileDKT] = React.useState([]);
  const [isAgree, setIsAgree] = React.useState(false);
  const [paramsData, setParamsData] = React.useState(null);
  const [showModalConfirm, setShowModalConfirm] = React.useState(false);
  const [showModalSuccess, setShowModalSuccess] = React.useState(false);
  const [schemeOBJ, setScheme] = React.useState(schema);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schemeOBJ),
  });

  // handle message

  const handleMessage = (type, show, message) => {
    setType(`${type}`);
    setShow(show);
    setNotifText(message);
  };

  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };

  // end handle message

  const onChangeVendorAddress = (e) => {
    setVendorAddress(e.target.value);
  };

  const onChangeDKTAddress = (e) => {
    setDKTAddress(e.target.value);
  };

  const handleSetDataPO = (po) => {
    setDataPO(po);
  };

  const handleChangeCheck = (e) => {
    const { checked } = e.target;
    setIsAgree(checked);
  };

  const handleSetBastDate = (data) => {
    setBastDate(data);
  };

  const handleAddFile = (e) => {
    let indexCategory = e.target.name;
    let values = [...tempCategory];
    values[indexCategory]?.file?.push(e.target.files[0]);

    setTempCategory(values);
  };

  const handleRemoveFile = (categoryIndex, fileIndex) => {
    let values = [...tempCategory];
    values[categoryIndex]?.file?.splice(fileIndex, 1);

    setTempCategory(values);
  };

  const handleAddMoreFile = (e) => {
    let values = [...tempCategory];

    let tempId = e.target.name.split(',');

    values[tempId[0]]?.file?.push(e.target.files[0]);

    setTempCategory(values);
  };

  const onSubmit = (paramsData) => {
    const {
      Amount,
      NOBASTDKT,
      NOBASTVENDOR,
      JOBDESC,
      NamaDKT,
      JabatanDKT,
      NamaPerusahaanDKT,
      DKTAddress,
      NamaVendor,
      JabatanVendor,
      NamaPerusahaanVendor,
      VendorAddress,
      NamaKegiatan,
      Periode,
      PONumber,
      AmountBeforeTax,
      TAX,
      TotalWithTax,
      JumlahNilaiPekerjaan,
      SisaPO,
    } = paramsData;

    let sendData = {
      po_id: dataPO?.id,
      client_id: data?.user?.client_id,
      bast_date: `${bastDate?.day}/${bastDate?.monthNow}/${bastDate?.yearNow}`,
      vendor_code: NOBASTVENDOR,
      name_1: NamaVendor,
      position_1: JabatanVendor,
      company_1: NamaPerusahaanVendor,
      address_1: VendorAddress,
      name_2: NamaDKT,
      position_2: JabatanDKT,
      company_2: NamaPerusahaanDKT,
      address_2: DKTAddress,
      periode: Periode,
      amount_po: AmountBeforeTax,
      amount_po_tax: TAX,
      amount_po_total: TotalWithTax,
      bast_total: `${Amount}`,
      rest_po: `${SisaPO}`,
      activity_name: NamaKegiatan,
    };

    let params = new FormData();

    let dateFormat = dateFormater(
      `${bastDate?.yearNow}-${bastDate?.monthNow}-${bastDate?.day}`,
      'YYYY-MM-DD'
    );
    
    if(dataPO.doc_type === 'service'){
      params.append('po_id', dataPO?.id);
      params.append('client_id', data?.user?.client_id);
      params.append('bast_date', dateFormat);
      params.append('vendor_code', NOBASTVENDOR);
      params.append('name_1', NamaVendor);
      params.append('position_1', JabatanVendor);
      params.append('company_1', NamaPerusahaanVendor);
      params.append('address_1', VendorAddress);
      params.append('name_2', NamaDKT);
      params.append('position_2', JabatanDKT);
      params.append('company_2', NamaPerusahaanDKT);
      params.append('address_2', DKTAddress);
      params.append('periode', Periode);
      params.append('amount_po', AmountBeforeTax);
      params.append('amount_po_tax', TAX);
      params.append('amount_po_total', TotalWithTax);
      params.append('bast_total', `${Amount}`);
      params.append('rest_po', `${SisaPO}`);
      params.append('activity_name', `${JOBDESC}`);
    

      if (nilaiBAST.length > 0) {
        nilaiBAST.map((item, index) => {
          params.append(`bast_detail[${[index]}][description]`, item.description);
          params.append(`bast_detail[${[index]}][amount]`, item.value);
        });
      } else {
        setType('error');
        setShow(true);
        setNotifText(`Please add Nilai BAST description`);
        return
      }
    } else {
      params.append('po_id', dataPO?.id);
      params.append('client_id', data?.user?.client_id);
    }

    let validArr = [];
    tempCategory?.map((item, index) => {
      if(!globalConfig.proforma.ignoreChecklist.includes(item.name)){
        if (item?.file?.length < 1) {
          validArr.push(false);
        }
      }
      let tempName = item?.identity;
      item?.file?.map((fileItem, fileIndex) => {
        params.append(`${tempName}[]`, fileItem);
      });
    });

    if (validArr.length < 1) {
      if (!isAgree) {
        handleMessage('error', true, 'Please Check the statement');
      } else {
        setParamsData(params);

        setShowModalConfirm(true);
      }
    } else {
      handleMessage('error', true, 'Please check your upload file');
    }
  };

  // React.useEffect(() => {
  //   setTempFileDKT([
  //     {
  //       id: 2,
  //       name: 'Invoice',
  //       desc: 'Invoice',
  //       file: [],
  //       type: 'value',
  //     },
  //     {
  //       id: 3,
  //       name: 'Bukti Potong Pajak',
  //       desc: 'Bukti Potong Pajak',
  //       file: [],
  //     },
  //   ]);
  // }, []);

  const getListExclude = (docType) => {
    let list = globalConfig.proforma.checklistExclude

    if(docType === 'item') list = globalConfig.proforma.checklistItemExclude

    return list
  }

  React.useEffect(() => {
    if (dataPO) {

      if(dataPO.doc_type === 'item'){
        setScheme(schemaItem)
        setValue('Amount', dataPO?.total_before_discount ?? 'none');
        setValue('PoType', dataPO?.doc_type ?? 'none');
      } else {
        setScheme(schema)
        setValue('Amount', dataPO?.total_before_discount ?? 'none');
        setValue('PoType', dataPO?.doc_type ?? 'none');
        setValue('NamaKegiatan', dataPO?.pr?.purpose_of_request ?? 'none');
        setValue('PONumber', dataPO?.doc_num ?? 'none');
        setValue('AmountBeforeTax', dataPO?.total_before_discount ?? 'none');
        setValue('TAX', dataPO?.vat ?? 'none');
        setValue('TotalWithTax', dataPO?.doc_total_amount ?? 'none');
        setValue('SisaPO', dataPO?.total_before_discount ?? '0');
      }
      let tempArr = [];
      dataPO?.checklists?.map((item, index) => {
        if(!getListExclude(dataPO?.doc_type).includes(item?.checklist_attachment?.name)){ //filter
          let tempData = {
            id: index,
            name: item?.checklist_attachment?.name,
            desc: item?.checklist_attachment?.description,
            check_id: item?.checklist_attachment?.id,
            identity: `checklist_${item?.id}`,
            note: '',
            file: [],
          };
          tempArr.push(tempData);
        }
      });
      // filtering category for upload

      const tempDkt = []
      
      for (const i of globalConfig.proforma.checklistInclude){
        if(get(dataPO, ['checklists'], []).find(e => e?.checklist_attachment?.name === i)){
          tempDkt.push(globalConfig.proforma.dkt_checklist[i])
        }
      }

      setTempFileDKT(tempDkt)
      setTempCategory(tempArr)
      // tempArr.map((item, index) => {
      //   setTempCategory((prev) => [...prev, item]);
      // });
    }
  }, [dataPO]);

  React.useEffect(() => {
    if (nilaiBAST.length > 0) {
      let result = 0;
      nilaiBAST.map((item) => {
        return (result = result + parseInt(item.value));
      });
      setValue('JumlahNilaiPekerjaan', result ?? 'none');
    } else {
      setValue('JumlahNilaiPekerjaan', 0);
    }
  }, [nilaiBAST]);

  React.useEffect(() => {
    let result = parseInt(dataInput?.Amount) - parseInt(dataInput?.JumlahNilaiPekerjaan);
    setValue('SisaPO', result);
  }, [dataInput?.JumlahNilaiPekerjaan]);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      // console.log(value)
      setDataInput(value)
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  React.useEffect(() => {
    if (add_message) {
      if (add_message === 'Create proforma invoice successfully') {
        setShowModalSuccess(true);
      } else if (
        add_message !== '' &&
        add_message !== 'Create proforma invoice successfully'
      ) {
        setType('error');
        setShow(true);
        setNotifText(
          `${add_message} : ${add_client_message ?? ''} , ${
            add_po_message ?? ''
          }`
        );
      }

      dispacth(proforma.setBlankAdd());
    }
  }, [add_message]);

  React.useEffect(() => {
    setBastDate({
      day: moment().format('D'),
      monthNow: moment().format('M'),
      yearNow: moment().format('YYYY'),
    });
  }, []);
  
  return (
    <Grid xs={12} md={12} className={classes.mainContent}>
      <CustomizedSnackbars
        show={show}
        handleClose={handleClose}
        message={notifText}
        type={type}
      />
      <ModalConfirm
        open={showModalConfirm}
        handleCloseModalConfirm={() => setShowModalConfirm(false)}
        isLoading={add_Isloading}
        onPress={() => dispacth(proforma.addProforma(access_token, paramsData))}
      />
      <ModalSuccess
        open={showModalSuccess}
        onPress={() => router.push('/invoice')}
      />
      <HeaderTitle />

      <form onSubmit={handleSubmit(onSubmit)}>
        <SectionSearch
          register={register}
          errors={errors}
          handleSetDataPO={handleSetDataPO}
        />
        {dataPO && (
          <>
            { dataPO.doc_type === 'service' && (
            <>
              <BAST
                register={register}
                errors={errors}
                vendorAddress={vendorAddress}
                onChangeVendorAddress={onChangeVendorAddress}
                DKTAddress={DKTAddress}
                onChangeDKTAddress={onChangeDKTAddress}
                handleSetBastDate={handleSetBastDate}
                bastDate={bastDate}
              />
              <BASTITEM
                handleMessage={handleMessage}
                nilaiBAST={nilaiBAST}
                setNilaiBAST={setNilaiBAST}
              />
              <Box className={classes.btnContent}>
                <Box className={classes.btnPeviewDisable}>
                  <img src={EyeWhite} alt="none" />
                  <ContentText
                    content={`Preview BAST`}
                    styling={classes.previewText}
                  />
                </Box>
                <Box className={classes.btnPrint}>
                  <img src={Print} alt="none" />
                  <ContentText content={`Print`} styling={classes.previewText} />
                </Box>
              </Box>
              <Box className={classes.sizeBorder} />
            </>)}
            
            <ReportDoc
              tempCategory={tempCategory}
              showSectionDKT={dataPO.doc_type === 'service'}
              handleAddFile={handleAddFile}
              handleRemoveFile={handleRemoveFile}
              handleAddMoreFile={handleAddMoreFile}
              tempFileDKT={tempFileDKT}
            />
            <Box className={classes.checkContent}>
              <Checkbox
                defaultChecked
                className={classes.checkbox}
                color="secondary"
                checked={isAgree}
                onClick={handleChangeCheck}
                inputProps={{
                  'aria-label': 'checkbox with default color',
                }}
              />
              <ContentText
                content={`Dengan ini menyatakan data yang diberikan sudah benar dan sesuai ketentuan, submit data sekarang`}
                styling={classes.checkInfo}
              />
            </Box>

            <Box className={classes.ActionContent}>
              <input
                type="submit"
                className={classes.submitBtn}
                value="Submit"
              />
              <Box
                className={classes.cancelBtn}
                onClick={() => router.push('/invoice')}
              >
                <ContentText content={'Cancel'} />
              </Box>
            </Box>
          </>
        )}
      </form>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  mainContent: {
    borderRadius: 8,
    background: '#fff',
  },
  btnContent: {
    padding: '30px 20px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  sizeBorder: {
    borderBottom: '1px solid #E7E7E8',
  },
  btnPeviewDisable: {
    borderRadius: 8,
    background: '#969BAB',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 181,
    padding: '9.5px 14px',
    cursor: 'pointer',
  },
  btnPeview: {
    borderRadius: 8,
    background: '#127EEE',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 181,
    padding: '9.5px 14px',
    cursor: 'pointer',
  },
  previewText: {
    marginLeft: 10,
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 700,
    color: '#fff',
  },
  btnPrint: {
    borderRadius: 8,
    background: '#969BAB',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 181,
    padding: '9.5px 14px',
    cursor: 'pointer',
    marginLeft: 20,
  },
  ActionContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 20,
    borderTop: '1px solid #E7E7E8',
  },
  cancelBtn: {
    height: 50,
    width: 193,
    cursor: 'pointer',
    marginLeft: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  submitBtn: {
    height: 50,
    width: 193,
    textAlign: 'center',
    background: '#1B2030',
    borderRadius: 8,
    color: '#fff',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 700,
  },
  checkContent: {
    paddingLeft: 10,
    paddingTop: 0,
    paddingBottom: '10px',
    paddingRight: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  checkbox: {
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#127EEE',
    },
  },
  checkInfo: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 400,
    color: '#1B2030',
  },
}));

export default CreateProforma;
