import * as types from './types';

import request from '../../utils/request';

export const getSAPnotification = (token) => (dispatch) => {
  dispatch({ type: types.GRPO_NOTIF_LOADING, payload: true });

  request({
    method: 'get',
    url: 'grpo/notification',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get notif');
      dispatch({ type: types.GRPO_NOTIF_DATA, payload: res?.data?.draft });
      dispatch({
        type: types.GRPO_NOTIF_DASHBOARD_DATA,
        payload: res?.data?.draft,
      });
      dispatch({ type: types.GRPO_NOTIF_MESSAGE, payload: res?.message });
      dispatch({ type: types.GRPO_NOTIF_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get notif GRPO', err);
      dispatch({ type: types.GRPO_NOTIF_LOADING, payload: false });
    });
};

export const getGRPOdata = (token, params) => (dispatch) => {
  dispatch({ type: types.GRPO_DATA_LOADING, payload: true });

  request({
    method: 'get',
    url: `grpo`,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get GRPO data');
      // console.log('grpo', res?.data?.data);

      dispatch({ type: types.GRPO_SET_DATA, payload: res?.data });
      dispatch({ type: types.GRPO_DATA_MESSAGE, payload: res?.message });
      dispatch({ type: types.GRPO_DATA_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get data GRPO', err);
      dispatch({ type: types.GRPO_DATA_LOADING, payload: false });
    });
};

export const getGRPODetail = (token, id) => (dispatch) => {
  dispatch({ type: types.GRPO_DETAIL_LOADING, payload: true });

  request({
    method: 'get',
    url: `grpo/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get GRPO Detail data');
      // console.log(res);
      dispatch({ type: types.GRPO_DETAIL_DATA, payload: res?.data });
      dispatch({ type: types.GRPO_DETAIL_MESSAGE, payload: res?.message });
      dispatch({ type: types.GRPO_DETAIL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get data GRPO', err);
      dispatch({ type: types.GRPO_DETAIL_LOADING, payload: false });
    });
};

export const getGRPOSAPdata = (token, params) => (dispatch) => {
  dispatch({ type: types.GRPO_SYNC_LOADING, payload: true });

  request({
    method: 'get',
    url: `grpo`,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get SAP GRPO data');

      dispatch({ type: types.GRPO_SYNC_DATA, payload: res?.data });
      dispatch({ type: types.GRPO_SYNC_MESSAGE, payload: res?.message });
      dispatch({ type: types.GRPO_SYNC_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get SAP data GRPO', err);
      dispatch({ type: types.GRPO_SYNC_LOADING, payload: false });
    });
};

export const deleteGRPO = (token, id) => (dispatch) => {
  dispatch({ type: types.GRPO_DELETE_LOADING, payload: true });

  request({
    method: 'delete',
    url: `grpo/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get GRPO Detail data');
      // console.log(res);
      dispatch({ type: types.GRPO_DELETE_MESSAGE, payload: res?.message });
      dispatch({ type: types.GRPO_DELETE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error delete GRPO ', err);
      dispatch({ type: types.GRPO_DELETE_MESSAGE, payload: err?.message });
      dispatch({ type: types.GRPO_DELETE_LOADING, payload: false });
    });
};

export const setBlankDelete = () => (dispatch) => {
  dispatch({ type: types.GRPO_DELETE_MESSAGE, payload: '' });
};

export const publishGRPO = (token, data) => (dispatch) => {
  dispatch({ type: types.GRPO_PUBLISH_LOADING, payload: true });

  request({
    method: 'put',
    url: `grpo/publish`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('publish GRPO data');
      console.log(res);
      dispatch({ type: types.GRPO_PUBLISH_MESSAGE, payload: res?.message });
      dispatch({ type: types.GRPO_PUBLISH_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error publish GRPO ', err);
      dispatch({ type: types.GRPO_PUBLISH_LOADING, payload: false });
    });
};

export const setBlankPublish = () => (dispatch) => {
  dispatch({ type: types.GRPO_PUBLISH_MESSAGE, payload: '' });
};

export const cancelGRPO = (token, id) => (dispatch) => {
  dispatch({ type: types.GRPO_CANCEL_LOADING, payload: true });

  request({
    method: 'put',
    url: `grpo/${id}/cancel`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('Cancel GRPO data');
      console.log(res);
      dispatch({ type: types.GRPO_CANCEL_MESSAGE, payload: res?.message });
      dispatch({ type: types.GRPO_CANCEL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error cancel GRPO ', err);
      dispatch({ type: types.GRPO_CANCEL_MESSAGE, payload: err?.data?.message });
      dispatch({ type: types.GRPO_CANCEL_LOADING, payload: false });
    });
};

export const setBlankCancel = () => (dispatch) => {
  dispatch({ type: types.GRPO_CANCEL_MESSAGE, payload: '' });
};

export const assigmentGRPO = (token, id, data) => (dispatch) => {
  dispatch({ type: types.GRPO_ASSIGNMENT_LOADING, payload: true });

  request({
    method: 'put',
    url: `grpo/${id}/confirm-assignment`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('confirm assigment GRPO data');
      // console.log(res);
      dispatch({ type: types.GRPO_ASSIGNMENT_MESSAGE, payload: res?.message });
      dispatch({ type: types.GRPO_ASSIGNMENT_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error confirm assigment GRPO ', err);
      dispatch({ type: types.GRPO_ASSIGNMENT_MESSAGE, payload: err?.message });
      dispatch({ type: types.GRPO_ASSIGNMENT_LOADING, payload: false });
    });
};

export const setAssigmentMessage = () => (dispatch) => {
  dispatch({ type: types.GRPO_ASSIGNMENT_MESSAGE, payload: '' });
};
