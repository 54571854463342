import { makeStyles, Box } from '@material-ui/core';

// Components
import { ContentText } from '../Typography';

// iconst
import Unduh from '../../assets/Icons/unduhSecond.png';

export const ButtonPrimary = (props) => {
  const classes = useStyles();

  return (
    <>
      <Box className={props.disable ? classes.btnBoxFalse : classes.btnBox}>
        <img src={Unduh} alt="none" />
        <ContentText styling={classes.btnText} content={`Attach File`} />
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  btnBox: {
    width: 156,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#127EEE',
    height: 40,
    borderRadius: 8,
    cursor: 'pointer',
  },
  btnBoxFalse: {
    width: 156,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#969BAB',
    height: 40,
    borderRadius: 8,
    cursor: 'pointer',
  },
  btnText: {
    color: '#fff',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 700,
    marginLeft: 12,
  },
}));
