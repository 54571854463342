import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

// images
import Danger from '../../../../assets/Icons/Danger.png';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    paddingTop: 50,
    paddingBottom: 26,
    borderRadius: 18,
    width: '100%',
    maxWidth: 467,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  notifText: {
    marginTop: 32,
    fontSize: 36,
    lineHeight: '49px',
    color: '#000000',
    fontWeight: 'bold',
    marginBottom: 22,
  },
  textConfirm: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
  },
  borderLine: {
    marginTop: 53,
    width: '100%',
    border: '1px solid #E7E7E8',
  },
  btnContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 20,
    paddingTop: 17,
    paddingBottom: 14,
  },
  btnSubmit: {
    width: 183,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1B2030',
    borderRadius: 8,
    cursor: 'pointer',
    marginLeft: 18,
  },
  btnSubmitFalse: {
    width: 183,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#969BAB',
    borderRadius: 8,
    marginLeft: 18,
  },
  btnSubmitText: {
    fontSize: 16,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#fff',
  },
  btnCancel: {
    width: 183,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    borderRadius: 8,
    cursor: 'pointer',
  },
  btnCancelText: {
    fontSize: 16,
    lineHeight: '27px',
    color: '#1B2030',
  },
}));

export default function ModalConfirmCancel({
  open,
  title,
  close,
  onPress,
  isLoading,
}) {
  const classes = useStyles();

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={close}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div>
              <img
                // className={classes.bellImage}
                src={Danger}
                alt="image not found"
              />
            </div>
            <Typography
              className={classes.notifText}
              variant="body1"
              component="p"
            >
              Are you Sure?
            </Typography>
            <Typography
              className={classes.textConfirm}
              variant="body1"
              component="p"
            >
              Do you want to cancel this invoice ?
            </Typography>
            <Typography
              className={classes.textConfirm}
              variant="body1"
              component="p"
            >
              {isLoading ? 'Loading Sending Data' : null}
            </Typography>
            <div className={classes.borderLine}></div>
            <div className={classes.btnContent}>
              <div className={classes.btnCancel} onClick={close}>
                <Typography
                  className={classes.btnCancelText}
                  variant="body1"
                  component="p"
                >
                  Cancel
                </Typography>
              </div>
              <div
                className={
                  isLoading ? classes.btnSubmitFalse : classes.btnSubmit
                }
                onClick={isLoading ? null : onPress}
              >
                <Typography
                  className={classes.btnSubmitText}
                  variant="body1"
                  component="p"
                >
                  Yes, continue
                </Typography>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
