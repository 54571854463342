import React from 'react';
import { makeStyles, Box, Grid } from '@material-ui/core';

// components
import ChecklistItem from './ChecklistItem';
import { ContentText } from '../../../libs/Typography';

// icons
import Plus from '../../../assets/Icons/plus.png';

const ChecklistMenu = ({
  tempChecklist,
  addMoreActivities,
  deleteActivities,
  checklist_data,
  checklist_isLoading,
  activities_data,
  activities_isLoading,
  handleChecklistChange,
  handleChecklistChexBox,
  isLoading,
  handleConfirmChecklis,
  actionIsLoading,
  status,
}) => {
  const classes = useStyles();
  return (
    <>
      <Grid md={12} className={classes.checklistContainer}>
        <Box className={classes.wrapperHeader}>
          <ContentText
            styling={classes.bodyTitle}
            content={`${tempChecklist.length < 1 ? 'No Data ' : ''}Checklist
            Configuration`}
          />

          {status == 'need_confirm' || status == 'rejected' ? (
            <Box
              className={classes.wrapperBtnAddMore}
              onClick={() =>
                addMoreActivities(tempChecklist[tempChecklist.length - 1].id)
              }
            >
              <img src={Plus} alt="none" />
              <ContentText
                styling={classes.addMoreText}
                content={`Add Activities`}
              />
            </Box>
          ) : null}
        </Box>
        {tempChecklist.length > 0
          ? tempChecklist?.map((item, index) => (
              <ChecklistItem
                key={index}
                index={index}
                item={item}
                addMoreActivities={addMoreActivities}
                deleteActivities={deleteActivities}
                howLenght={tempChecklist.length}
                checklist_data={checklist_data}
                checklist_isLoading={checklist_isLoading}
                activities_data={activities_data}
                activities_isLoading={activities_isLoading}
                handleChecklistChange={handleChecklistChange}
                handleChecklistChexBox={handleChecklistChexBox}
                tempChecklist={tempChecklist}
                isLoading={isLoading}
                status={status}
              />
            ))
          : null}

        {status == 'need_confirm' || status == 'rejected' ? (
          <Box>
            <Box
              className={
                actionIsLoading ? classes.BtnConfirmFalse : classes.BtnConfirm
              }
              onClick={actionIsLoading ? null : handleConfirmChecklis}
            >
              <ContentText
                styling={classes.BtnConfirmText}
                content={`Confirm Checklist Attachment`}
              />
            </Box>
          </Box>
        ) : null}
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  checklistContainer: {
    padding: 20,
  },
  bodyTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  wrapperHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 12,
  },
  wrapperBtnAddMore: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  addMoreText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#127EEE',
    fontWeight: 'bold',
    marginLeft: 12,
  },
  BtnConfirm: {
    paddingTop: 14.5,
    paddingBottom: 14.5,
    backgroundColor: '#1B2030',
    borderRadius: 8,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    cursor: 'pointer',
  },
  BtnConfirmFalse: {
    backgroundColor: '#969BAB',
    paddingTop: 14.5,
    paddingBottom: 14.5,
    borderRadius: 8,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },
  BtnConfirmText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
  },
}));

export default ChecklistMenu;
