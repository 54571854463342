import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import LoadingScreen from '../Loading/LoadingScreen';

const PrivateRoute = ({ component: Component, ...rest }) => {
  // const dispatch = useDispatch();
  const { isLogin, access_token, isLoading, data } = useSelector(
    (state) => state.auth
  );

  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          isLoading ? (
            <LoadingScreen />
          ) : isLogin && data?.user?.role?.length ? (
            <Component {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
      {/* {console.log('login', isLoading)}
      {console.log('user', data?.user?.role[0])} */}
    </>
  );
};

export default PrivateRoute;
