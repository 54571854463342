import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';

// Icons
import Bell from '../../assets/Icons/bell.png';
import Cross from '../../assets/Icons/cross.png';

const NotifDashboard = ({ deviceType }) => {
  // hide everything
  return null;

  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12}>
          <div className={classes.notifMessage}>
            <div className={classes.notifInformation}>
              <img
                className={classes.bellImage}
                src={Bell}
                alt="image not found"
              />
              <div>
                <Typography
                  className={classes.titleMessage}
                  variant="body1"
                  component="p"
                >
                  Attention!
                </Typography>
                <div
                  className={
                    deviceType === 'mobile'
                      ? classes.notifTextMobile
                      : classes.notifText
                  }
                >
                  <Typography
                    className={classes.subMessage}
                    variant="body1"
                    component="p"
                  >
                    We find new data from SAP system. Please sync the data on
                  </Typography>
                  <Typography
                    className={[
                      classes.subMessage,
                      deviceType === 'mobile'
                        ? classes.subMessageUnderlineMobile
                        : classes.subMessageUnderline,
                    ]}
                    variant="body1"
                    component="p"
                  >
                    Purpose Requisition
                  </Typography>
                </div>
              </div>
            </div>
            <div
              className={classes.crossImage}
              onClick={() => console.log('btn click')}
            >
              <img
                // className={classes.CrossLogo}
                src={Cross}
                alt="image not found"
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  notifMessage: {
    // height: 99,
    // backgroundColor: 'red',
    padding: 20,
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    backgroundColor: '#ffff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  notifInformation: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  bellImage: {
    marginRight: 30,
  },
  notifText: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  notifTextMobile: {
    // backgroundColor: 'red',
    // display: 'flex',
    // justifyContent: 'flex-start',
  },
  titleMessage: {
    fontSize: 21,
    lineHeight: '32px',
    fontWeight: 'bold',
    color: '#333642',
  },
  subMessage: {
    fontSize: 18,
    lineHeight: '27px',
    color: '#333642',
  },
  subMessageUnderline: {
    textDecorationLine: 'underline',
    fontWeight: 'bold',
    marginLeft: 5,
  },
  subMessageUnderlineMobile: {
    textDecorationLine: 'underline',
    fontWeight: 'bold',
    // marginLeft: 5,
  },
  crossImage: {
    cursor: 'pointer',
  },
});

export default NotifDashboard;
