import * as types from './types';

import request from '../../utils/request';

export const getSAPnotification = (token) => (dispatch) => {
  dispatch({ type: types.APINVOICE_NOTIF_LOADING, payload: true });

  request({
    method: 'get',
    url: 'apinvoice/notification',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get notif');
      dispatch({
        type: types.APINVOICE_NOTIF_DRAFT,
        payload: res?.data?.draft,
      });
      dispatch({ type: types.APINVOICE_NOTIF_DATA, payload: res?.data?.data });
      dispatch({
        type: types.APINVOICE_NOTIF_DASHBOARD_DATA,
        payload: res?.data?.draft,
      });
      dispatch({ type: types.APINVOICE_NOTIF_MESSAGE, payload: res?.message });
      dispatch({ type: types.APINVOICE_NOTIF_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get notif AP Invoice ', err);
      dispatch({ type: types.APINVOICE_NOTIF_LOADING, payload: false });
    });
};

export const getAPINVOICEdata = (token, params) => (dispatch) => {
  dispatch({ type: types.APINVOICE_DATA_LOADING, payload: true });

  request({
    method: 'get',
    url: `apinvoice`,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get AP INVOICE data');
      // console.log(res?.data);

      dispatch({ type: types.APINVOICE_SET_DATA, payload: res?.data });
      dispatch({ type: types.APINVOICE_DATA_MESSAGE, payload: res?.message });
      dispatch({ type: types.APINVOICE_DATA_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get data AP INVOICE', err);
      dispatch({ type: types.APINVOICE_DATA_LOADING, payload: false });
    });
};

export const getAPINVOICEDetail = (token, id) => (dispatch) => {
  dispatch({ type: types.APINVOICE_DETAIL_LOADING, payload: true });

  request({
    method: 'get',
    url: `apinvoice/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get apinvoice Detail data');
      // console.log(res);
      dispatch({ type: types.APINVOICE_DETAIL_DATA, payload: res?.data });
      dispatch({ type: types.APINVOICE_DETAIL_MESSAGE, payload: res?.message });
      dispatch({ type: types.APINVOICE_DETAIL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get data apinvoice', err);
      dispatch({ type: types.APINVOICE_DETAIL_LOADING, payload: false });
    });
};

export const getAPINVOICESAPdata = (token, params) => (dispatch) => {
  dispatch({ type: types.APINVOICE_SYNC_LOADING, payload: true });

  request({
    method: 'get',
    url: `apinvoice`,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get SAP APINVOICE data');

      dispatch({ type: types.APINVOICE_SYNC_DATA, payload: res?.data });
      dispatch({ type: types.APINVOICE_SYNC_MESSAGE, payload: res?.message });
      dispatch({ type: types.APINVOICE_SYNC_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get SAP data APINVOICE', err);
      dispatch({ type: types.APINVOICE_SYNC_LOADING, payload: false });
    });
};

export const deleteAPINVOICE = (token, id) => (dispatch) => {
  dispatch({ type: types.APINVOICE_DELETE_LOADING, payload: true });

  request({
    method: 'post',
    url: `apinvoice/${id}/delete`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get APINVOICE Detail data');
      dispatch({ type: types.APINVOICE_DELETE_MESSAGE, payload: res?.message });
      dispatch({ type: types.APINVOICE_DELETE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error delete APINVOICE ', err);
      dispatch({ type: types.APINVOICE_DELETE_LOADING, payload: false });
    });
};

export const setBlankDelete = () => (dispatch) => {
  dispatch({ type: types.APINVOICE_DELETE_MESSAGE, payload: '' });
};

export const publishAPINVOICE = (token, data) => (dispatch) => {
  dispatch({ type: types.APINVOICE_PUBLISH_LOADING, payload: true });

  request({
    method: 'put',
    url: `apinvoice/publish`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('publish APINVOICE data');
      dispatch({
        type: types.APINVOICE_PUBLISH_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: types.APINVOICE_PUBLISH_LOADING, payload: false });
      dispatch(getSAPnotification(token));
    })
    .catch((err) => {
      console.log('error publish APINVOICE ', err);
      dispatch({ type: types.APINVOICE_PUBLISH_LOADING, payload: false });
    });
};

export const setBlankPublish = () => (dispatch) => {
  dispatch({
    type: types.APINVOICE_PUBLISH_MESSAGE,
    payload: '',
  });
};

export const cancelAPINVOICE = (token, id) => (dispatch) => {
  dispatch({ type: types.APINVOICE_CANCEL_LOADING, payload: true });

  request({
    method: 'put',
    url: `apinvoice/${id}/cancel`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('Cancel apinvoice data');
      // console.log(res);
      dispatch({ type: types.APINVOICE_CANCEL_MESSAGE, payload: res?.message });
      dispatch({ type: types.APINVOICE_CANCEL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error cancel apinvoice ', err);
      dispatch({ type: types.APINVOICE_CANCEL_MESSAGE, payload: err?.data?.message });
      dispatch({ type: types.APINVOICE_CANCEL_LOADING, payload: false });
    });
};

export const setBlankCancel = () => (dispatch) => {
  dispatch({ type: types.APINVOICE_CANCEL_MESSAGE, payload: '' });
};

export const assigmentAPINVOICE = (token, id, data) => (dispatch) => {
  dispatch({ type: types.APINVOICE_ASSIGNMENT_LOADING, payload: true });

  request({
    method: 'put',
    url: `apinvoice/${id}/confirm-assignment`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('confirm assigment APINVOICE data');
      // console.log(res);
      dispatch({
        type: types.APINVOICE_ASSIGNMENT_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: types.APINVOICE_ASSIGNMENT_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error confirm assigment APINVOICE ', err);
      dispatch({
        type: types.APINVOICE_ASSIGNMENT_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: types.APINVOICE_ASSIGNMENT_LOADING, payload: false });
    });
};

export const setBlankConfirm = () => (dispatch) => {
  dispatch({
    type: types.APINVOICE_ASSIGNMENT_MESSAGE,
    payload: '',
  });
};

export const transactionAPINVOICE = (token, data) => (dispatch) => {
  dispatch({
    type: types.APINVOICE_TRANSACTION_APPROVAL_LOADING,
    payload: true,
  });

  request({
    method: 'post',
    url: `approval-transaction`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('set transaction approval');
      dispatch({
        type: types.APINVOICE_TRANSACTION_APPROVAL_MESSAGE,
        payload: res?.message,
      });
      dispatch({
        type: types.APINVOICE_TRANSACTION_APPROVAL_LOADING,
        payload: false,
      });
    })
    .catch((err) => {
      console.log('error set transaction approval APINVOICE ', err);
      dispatch({
        type: types.APINVOICE_TRANSACTION_APPROVAL_MESSAGE,
        payload: err?.message,
      });
      dispatch({
        type: types.APINVOICE_TRANSACTION_APPROVAL_LOADING,
        payload: false,
      });
    });
};

export const setBlankTransaction = () => (dispatch) => {
  dispatch({
    type: types.APINVOICE_TRANSACTION_APPROVAL_MESSAGE,
    payload: '',
  });
};
