import React from 'react';
import {
  Box,
  makeStyles,
  ListItem,
  ListItemIcon,
  Typography,
} from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';

const PageItem = ({
  value,
  page,
  handleLink,
  isOpen,
  iconEnable,
  iconDisbale,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const [isHover, setIsHover] = React.useState(false);

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  React.useEffect(() => {
    if (isOpen) {
      setOpen(false);
    }
  }, [isOpen]);

  return (
    <>
      <Popper
        onBlur={() => setOpen(false)}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        className={classes.propper}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={classes.paper}>
              <Box
                className={classes.boxDropdown}
                onFocus={() => setOpen(true)}
                onClick={() => handleLink(value)}
              >
                <Typography className={classes.dropdown}>{value.name}</Typography>
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>
      <a href={value.url} className="hyperlink-no-style" onClick={(e) => e.preventDefault()}>
        <ListItem
          className={value.name === page ? classes.linkActive : classes.linkDisable}
          button
          key={value.name}
          onClick={() => handleLink(value)}
          onBlur={() => setOpen(false)}
          onMouseEnter={!isOpen ? handleClick('right') : null}
          onMouseLeave={!isOpen ? () => setOpen(false) : null}
          // onClick={() => handleLink(text)}
          // onClick={handleClick('right')}
        >
          <ListItemIcon
            className={isOpen ? classes.iconContentOpen : classes.iconContent}
          >
            <img
              className={classes.iconImage}
              src={value.name === page ? iconEnable : iconDisbale}
              alt="image not found"
            />
          </ListItemIcon>
          <Typography
            className={value.name === page ? classes.titlePage : classes.textDefault}
          >
            {value.name}
          </Typography>
          {/* <Button onClick={handleClick('right')}>right</Button> */}
        </ListItem>
      </a>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  // styling Hover
  propper: {
    position: 'relative',
    zIndex: 9999,
  },
  paper: {
    backgroundColor: '#1B2030',
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    marginLeft: 6,
  },
  dropdown: {
    width: 210,
    height: 50,
    backgroundColor: '#1B2030',
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    color: '#fff',
    paddingLeft: 20,
    paddingTop: 15,
    cursor: 'pointer',
  },

  urlText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#F3F5F6',
  },
  // end styling hover
  linkActive: {
    borderRight: '4px solid #1B2030',
    paddingTop: 6,
    paddingBottom: 6,
  },
  linkDisable: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  iconContentOpen: {
    minWidth: 46,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: -6,
    marginBottom: -6,
  },
  iconContent: {
    minWidth: 89,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: -16,
    marginTop: -6,
    marginBottom: -6,
  },
  iconImage: {
    height: 20,
    width: 20,
  },
  titlePage: {
    fontSize: '16px !important',
    lineHeight: '21px !important',
    fontWeight: 'bold !important',
    color: '#1B2030 !important',
  },
  textDefault: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
  },
}));

export default PageItem;
