import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';

// Components
import { ContentText } from '../../../libs/Typography';

const CheckboxItem = ({
  item,
  check,
  index,
  indexChec,
  handleChecklistChexBox,
  tempChecklist,
  status,
  isShort,
}) => {
  const classes = useStyles();
  const [isCheck, setIsCheck] = React.useState(false);

  React.useEffect(() => {
    let resultArr = item.checklist_attachment_id.filter(
      (e) => e.id == check.id
    );

    if (resultArr.length > 0) {
      setIsCheck(resultArr[0].isCheck);
    } else {
      setIsCheck(false);
    }
  }, [tempChecklist]);

  const hideSearchInput = (hide) => {
    return hide ? classes.hide : classes.wrapperCheclistMenu;
  }

  const BaseRenderCheck = () => {
    return (
      <Box
        key={indexChec}
        className={hideSearchInput(isShort ? !isCheck : isCheck)}
      >
        <Checkbox
          disabled={
            status == 'need_confirm' || status == 'rejected' ? false : true
          }
          name={`${item?.id},${check?.id},${index},${check?.name}`}
          checked={isCheck}
          onChange={handleChecklistChexBox}
          inputProps={{ 'aria-label': 'primary checkbox' }}
          className={classes.checkBox}
        />
        <ContentText content={check?.name} />
      </Box>
    );
  }

  return <><BaseRenderCheck /></>;
};

const useStyles = makeStyles({
  wrapperCheclistMenu: {
    display: 'flex',
    // justifyContent: 'space-between',
    alignItems: 'center',
  },
  hide: {
    display: 'none',
  },
  checkBox: {
    '&.MuiCheckbox-colorSecondary.Mui-disabled': {
      color: '#2B3595',
    },
  },
});

export default CheckboxItem;
