import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// icons
import Envelope from '../../assets/Icons/envelope.png';

const InputEmail = ({ label, register, required, placeholder, type }) => {
  const classes = useStyles();
  return (
    <Box className={classes.wrapperInput}>
      <label className={classes.labelInput}>{label}</label>
      <Box className={classes.wrapperTextField}>
        <Box className={classes.iconInput}>
          <img
            className={classes.eyeLogo}
            src={Envelope}
            alt="image not found"
          />
        </Box>
        <input
          type={type}
          className={classes.contexInput}
          {...register(label, { required })}
          placeholder={placeholder}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  wrapperInput: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelInput: {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '21px',
    color: '#1B2030',
    marginBottom: 12,
  },
  wrapperTextField: {
    display: 'flex',
    flexDirection: 'row',
  },
  iconInput: {
    height: 50,
    width: 58,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E7E7E8',
    border: '1px solid #E7E7E8',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  contexInput: {
    flex: 1,
    border: '1px solid #E7E7E8',
    backgroundColor: '#F8FAFB',
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
    paddingLeft: 10,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
});

export default InputEmail;
