import React from 'react';
import { makeStyles, Typography, Box } from '@material-ui/core';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { Autocomplete } from '@material-ui/lab';
import './index.css';

const SelectFilter = ({
  masterData,
  data,
  defaultValue,
  onChange,
  disabled,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState(masterData);

  React.useEffect(() => {
    setOptions(isOpen ? data : masterData);
  }, [isOpen]);
  
  if (!options) return null;

  return (
    <>
      <FormControl className={classes.formControl}>
        <Autocomplete
          fullWidth={true}
          disabled={!!disabled}

          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}

          value={defaultValue}
          inputValue={inputValue}

          onChange={onChange}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}

          disablePortal
          options={options}

          getOptionLabel={(value) => {
              const item = typeof value === 'number' ? masterData.find(v => v.id === value) : value;
              const itemLabel = item ? `${item.name} - ${item.positions?.name ?? '-'}` : '';
              return itemLabel;
            }
          }

          getOptionSelected={(option, value) => {
            return option.id === (value?.id || value);
          }}

          className={classes.autocomplete}

          renderInput={(params) => (
            <div ref={params.InputProps.ref} className={classes.divSelectInputContainer}>
              <input type="text" {...params.inputProps} className={classes.divSelectInput} placeholder="Select Approval" />
            </div>
          )}

          renderOption={(item, props) => {
            return (
              <MenuItem {...props} value={item.id} key={item.id}>
                <Box className={classes.boxDepartment}>
                  <Typography
                    className={classes.bodyDataText}
                    variant="body1"
                    component="p"
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    className={classes.bodyDataSubText}
                    variant="body1"
                    component="p"
                  >
                    {`- ${item.positions?.name ?? '-'}`}
                  </Typography>
                </Box>
              </MenuItem>
            );
          }}
        />
      </FormControl>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 5,
    paddingBottom: 5,
    // maxWidth: 220,
    width: '100%',
    // minWidth: 160,
    maxHeight: 50,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      // marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#969BAB',
      paddingRight: 2,
      backgroundColor: '#F8FAFB',
    },
  },
  boxDepartment: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textTransform: 'ellipsis',
    width: '100%',
    height: '100%',
    paddingRight: 20,
  },
  bodyDataSubText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    marginLeft: 4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  bodyDataText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
  },
  hideWhiteSpace: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 100,
  },
  hide: {
    display: 'none',
  },
  divSelectInput: {
    backgroundColor: 'transparent',
    border: '0px',
    width: '100%',
    '&:focus': {
      outline: '0px',
    },
  },
  autocomplete: {
    height: '49px',
    display: 'flex',
    alignItems: 'center',
  },
  divSelectInputContainer: {
    width: '100%',
  },
}));

export default SelectFilter;
