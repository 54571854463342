import React from 'react';
import { makeStyles, Box, Grid, Typography } from '@material-ui/core';

import UploadBtn from '../../../assets/Icons/uploadBtn.png';

const ButtonMandatory = ({ text, open }) => {
  const classes = useStyles({});
  return (
    <>
      <Grid md={12} container>
        <Grid md={7}>
          <Typography
            // className={classes.AttachmentMessage}
            variant="body1"
            component="p"
          >
            {text}
          </Typography>
        </Grid>
        <Grid md={5}>
          <Box className={classes.btnUploadContent}>
            <Typography
              className={classes.btnUploadText}
              variant="body1"
              component="p"
            >
              Choose a file
            </Typography>
            <Box className={classes.btnUpload} onClick={open}>
              <Box>
                <img
                  className={classes.bellImage}
                  src={UploadBtn}
                  alt="image not found"
                />
              </Box>
              <Typography
                className={classes.btnUploadValue}
                variant="body1"
                component="p"
              >
                Upload File
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  btnUploadContent: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  btnUploadText: {
    fontSize: 14,
    lineHeight: '26px',
    color: '#1B2030',
  },
  btnUpload: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: '#127EEE',
    paddingLeft: 19,
    paddingRight: 19,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 8,
    marginLeft: 8,
  },
  btnUploadValue: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
    marginLeft: 13,
  },
});

export default ButtonMandatory;
