import React from 'react';
import { Grid, Box, makeStyles, Typography } from '@material-ui/core';
import { parseRestructuredItemApproval } from 'src/utils/formatter';

const ApprovalItem = ({ index, app, _renderStatusApprove }) => {
  const classes = useStyles();

  const { item, newIndex } = parseRestructuredItemApproval(app);

  return (
    <>
      <Grid
        md={4} xs={4} sm={4}
        key={index}
        className={index > 2 ? classes.personHide : null}
      >
        <Box className={classes.statusContent}>
          {item && (
            <>
              <Typography
                className={classes.valueText}
                variant="body1"
                component="p"
              >
                {`${newIndex + 1}. ${item.user?.name}`}
              </Typography>
              <Box>{_renderStatusApprove(item?.approve, item?.reject)}</Box>
            </>
          )}
        </Box>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  personHide: {
    display: 'none',
  },
  valueText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
    marginRight: 10,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 90,
  },
  statusContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
});

export default ApprovalItem;
