// notif po
export const PO_NOTIF_LOADING = 'PO/PO_NOTIF_LOADING';
export const PO_NOTIF_MESSAGE = 'PO/PO_NOTIF_MESSAGE';
export const PO_NOTIF_DATA = 'PO/PO_NOTIF_DATA';
export const PO_NOTIF_DASHBOARD_DATA = 'PO/PO_NOTIF_DASHBOARD_DATA';

// get data po
export const PO_DATA_LOADING = 'PO/PO_DATA_LOADING';
export const PO_DATA_MESSAGE = 'PO/PO_DATA_MESSAGE';
export const PO_SET_DATA = 'PO/PO_SET_DATA';

// get po by id
export const PO_DETAIL_LOADING = 'PO/PO_DETAIL_LOADING';
export const PO_DETAIL_MESSAGE = 'PO/PO_DETAIL_MESSAGE';
export const PO_DETAIL_DATA = 'PO/PO_DETAIL_DATA';

// get PO SAP data
export const PO_SYNC_LOADING = 'PO/PO_SYNC_LOADING';
export const PO_SYNC_MESSAGE = 'PO/PO_SYNC_MESSAGE';
export const PO_SYNC_DATA = 'PO/PO_SYNC_DATA';

// delete PO
export const PO_DELETE_LOADING = 'PO/PO_DELETE_LOADING';
export const PO_DELETE_MESSAGE = 'PO/PO_DELETE_MESSAGE';

// publish PO
export const PO_PUBLISH_LOADING = 'PO/PO_PUBLISH_LOADING';
export const PO_PUBLISH_MESSAGE = 'PO/PO_PUBLISH_MESSAGE';

// Cancel PO
export const PO_CANCEL_LOADING = 'PO/PO_CANCEL_LOADING';
export const PO_CANCEL_MESSAGE = 'PO/PO_CANCEL_MESSAGE';

// confirm Assign
export const PO_ASSIGNMENT_LOADING = 'PO/PO_ASSIGNMENT_LOADING';
export const PO_ASSIGNMENT_MESSAGE = 'PO/PO_ASSIGNMENT_MESSAGE';

// Search PO
export const PO_SEARCH_LOADING = 'PO/PO_SEARCH_LOADING';
export const PO_SEARCH_DATA = 'PO/PO_SEARCH_DATA';

// confirm checklist PO
export const PO_CONFIRM_CHECKLIST_LOADING = 'PO/PO_CONFIRM_CHECKLIST_LOADING';
export const PO_CONFIRM_CHECKLIST_MESSAGE = 'PO/PO_CONFIRM_CHECKLIST_MESSAGE';
