import React from 'react';
import { makeStyles, Box, Grid, Typography, Checkbox } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { dateFormater } from '../../../utils/formatter';

// Components
import { TabelLoading, NoData } from '../../Loading/TableLoading';
import MobilePagination from '../../Pagination/MobilePagination';

// icons
import FilterIcon from '../../../assets/Icons/fiterIcon.png';
import EyeBlue from '../../../assets/Icons/eyeBlue.png';
import PeopleApprove from '../../../assets/Icons/peopleApprove.png';
import PeopleDisable from '../../../assets/Icons/peopleDisable.png';
import PeopleReject from '../../../assets/Icons/peopleReject.png';

// Svg
import { DownArrow } from '../../../assets/Icons/svg/DownArrow';

const ApprovalMobile = ({
  isLoading,
  handleChange,
  handleChangeEntity,
  handleChangeDepartement,
  entityFilter,
  entity_data,
  departementFilter,
  department_data,
  showFilter,
  setShowFilter,
  data,
  page,
  numberSelect,
  handleShowDetail,
  setShowModalMessageReject,
  setShowModalMessageApprove,
  pagination,
  fromRow,
  toRow,
  TotalRow,
  handleChangePagination,
  userData,
  isEnableSelectAll,
  isSelectAll,
  handleChangeLimit,
  limit,
  itemAvailable,
}) => {

  const _renderHeader = (index) => {
    if (index === 0) {
      return 'Prepared';
    } else if (index === 1) {
      return 'Reviewed by';
    } else if (index === 2) {
      return 'Approved by';
    } else {
      return 'Director';
    }
  };

  const _renderStatusApprove = (approve, reject) => {
    if (approve == 1 && reject == 0) {
      return <img src={PeopleApprove} alt="image not found" />;
    } else if (approve == 0 && reject == 1) {
      return <img src={PeopleReject} alt="image not found" />;
    } else {
      return <img src={PeopleDisable} alt="image not found" />;
    }
  };

  const _renderWrapperAction = () => {
    return (
      <>
        <Grid xs={12} container className={classes.wrapperBtnContent}>
          <Typography
            className={classes.textSort}
            variant="body1"
            component="p"
          >
            {`you have ${
              itemAvailable ?? '0'
            } item need your approval. Please check it.`}
          </Typography>
          <Grid xs={12} container className={classes.wrapperBtnMenu}>
            <Grid xs={6} className={classes.wrapperBtn}>
              <Box
                className={numberSelect > 0 ? classes.btnDanger : classes.btn}
                onClick={
                  numberSelect > 0
                    ? () => setShowModalMessageReject(true)
                    : null
                }
              >
                <Typography
                  className={classes.btnText}
                  variant="body1"
                  component="p"
                >
                  Reject
                </Typography>
              </Box>
            </Grid>
            <Grid xs={6} className={classes.wrapperBtn}>
              <Box
                className={numberSelect > 0 ? classes.btnApprove : classes.btn}
                onClick={
                  numberSelect > 0
                    ? () => setShowModalMessageApprove(true)
                    : null
                }
              >
                <Typography
                  className={classes.btnText}
                  variant="body1"
                  component="p"
                >
                  Approve
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {numberSelect > 0 ? (
            <Typography
              className={classes.textSort}
              variant="body1"
              component="p"
            >
              {`${numberSelect ?? '0'} item are selected`}
            </Typography>
          ) : null}
        </Grid>
      </>
    );
  };

  const classes = useStyles();
  return (
    <>
      {_renderWrapperAction()}
      <Box className={classes.wrapperContent}>
        <Grid container xs={12} className={classes.wrapperSelect}>
          <Grid item xs={6} className={classes.wrapperSelectContent}>
            <Checkbox
              className={classes.checkContent}
              color="secondary"
              name="allSelect"
              disabled={
                !isEnableSelectAll || userData?.user?.role[0] === 'superadmin'
                  ? true
                  : false
              }
              checked={isSelectAll}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'checkbox with default color' }}
            />
            <Typography
              className={classes.titleTable}
              variant="body1"
              component="p"
            >
              Select All
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.wrapperIconFilter}>
            <Box onClick={() => setShowFilter(!showFilter)}>
              <img src={FilterIcon} alt="image not found" />
            </Box>
          </Grid>
        </Grid>
        {showFilter ? (
          <Grid container xs={12} className={classes.wrapperFilterContent}>
            <Typography
              className={classes.textSort}
              variant="body1"
              component="p"
            >
              Sort by :
            </Typography>
            <Grid container xs={12} className={classes.wrapperFilterMenu}>
              <Grid item xs={5} className={classes.wrapperEntity}>
                <FormControl className={classes.formControl}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={entityFilter}
                    onChange={handleChangeEntity}
                    IconComponent={() => <DownArrow />}
                  >
                    <MenuItem value={0}>All Entity</MenuItem>
                    {entity_data?.map((ent, index) => (
                      <MenuItem key={index} value={ent.id}>
                        <Typography
                          className={classes.bodyDataText}
                          variant="body1"
                          component="p"
                        >
                          {ent.code}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={7} className={classes.wrapperDepartment}>
                <FormControl className={classes.formControl}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={departementFilter}
                    onChange={handleChangeDepartement}
                    IconComponent={() => <DownArrow />}
                  >
                    <MenuItem value={0}>All Department</MenuItem>
                    {department_data?.map((dep, index) => (
                      <MenuItem key={index} value={dep.id}>
                        <Box className={classes.boxDepartment}>
                          <Typography
                            className={classes.bodyDataText}
                            variant="body1"
                            component="p"
                          >
                            {dep?.code}
                          </Typography>
                          <Typography
                            className={classes.bodyDataSubText}
                            variant="body1"
                            component="p"
                          >
                            {`- ${dep?.name}`}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        {/* kasih if statment here */}

        {isLoading ? (
          <TabelLoading />
        ) : data?.length > 0 ? (
          data?.map((item, index) => (
            <>
              <Grid
                container
                xs={12}
                className={
                  index % 2 === 0
                    ? classes.wrapperList
                    : classes.wrapperListGrey
                }
              >
                <Grid xs={2} className={classes.contentCheck}>
                  <Checkbox
                    defaultChecked
                    name={`${item.id}`}
                    disabled={
                      item?.status === 'approved' ||
                      item?.read_only ||
                      userData?.user?.role[0] === 'superadmin'
                        ? true
                        : false
                    }
                    checked={item?.isChecked || false}
                    className={classes.checkContent}
                    color="secondary"
                    onClick={handleChange}
                    inputProps={{
                      'aria-label': 'checkbox with default color',
                    }}
                  />
                </Grid>
                <Grid xs={10} className={classes.contentDesc}>
                  <Box className={classes.headerContent}>
                    <Typography
                      className={classes.labelText}
                      variant="body1"
                      component="p"
                    >
                      {`No. ${item?.id} / ${item?.user?.entity[0]?.code} / ${item?.user?.departments?.code}`}
                    </Typography>
                    <Typography
                      className={classes.labelText}
                      variant="body1"
                      component="p"
                    >
                      {`Submitted: ${dateFormater(item.created_at)}
                      `}
                    </Typography>
                    <Typography
                      className={classes.valueTextDesc}
                      variant="body1"
                      component="p"
                    >
                      {item.subject}
                    </Typography>
                    {item.approval_transactions.map((app, index) => (
                      <Box
                        key={index}
                        className={
                          index > 2
                            ? classes.personHide
                            : classes.wrapperStatusList
                        }
                      >
                        <Typography
                          className={classes.labelText}
                          variant="body1"
                          component="p"
                        >
                          {_renderHeader(index)}
                        </Typography>
                        <Box className={classes.statusContent}>
                          <Grid xs={6}>
                            <Typography
                              className={classes.valueText}
                              variant="body1"
                              component="p"
                            >
                              {`${index + 1}. ${app.user?.name}`}
                            </Typography>
                          </Grid>
                          <Grid xs={6}>
                            <Box>
                              {_renderStatusApprove(app?.approve, app?.reject)}
                            </Box>
                          </Grid>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  <Box className={classes.textRightContent}>
                    <Box
                      className={classes.btnDetail}
                      onClick={() => handleShowDetail(item)}
                    >
                      <Box>
                        <img src={EyeBlue} alt="image not found" />
                      </Box>
                      <Typography
                        className={classes.btnDetailText}
                        variant="body1"
                        component="p"
                      >
                        View CA Detail
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </>
          ))
        ) : (
          <NoData />
        )}
      </Box>
      {data?.length > 0 ? (
        isLoading ? null : (
          <MobilePagination
            toRow={toRow}
            fromRow={fromRow}
            TotalRow={TotalRow}
            page={page}
            handleChangePagination={handleChangePagination}
            limit={limit}
            handleChangeLimit={handleChangeLimit}
            pagination={pagination}
          />
        )
      ) : null}
    </>
  );
};

const useStyles = makeStyles({
  wrapperSelect: {
    paddingLeft: 4,
    paddingRight: 26,
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #E7E7E8',
  },
  wrapperSelectContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  wrapperIconFilter: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    cursor: 'pointer',
  },
  wrapperFilterContent: {
    padding: 10,
    borderBottom: '1px solid #E7E7E8',
    backgroundColor: '#fff',
  },
  wrapperFilterMenu: {
    marginTop: 15,
    transition: 'transform 0.5s ease-out',
  },
  textSort: {
    fontSize: 14,
    lineHeight: '21px',
    color: '#969BAB',
  },
  // styling Form select
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 5,
    paddingBottom: 5,
    // maxWidth: 220,
    width: '100%',
    // minWidth: 160,
    maxHeight: 40,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      // marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#969BAB',
      paddingRight: 2,
      background: 'none',
    },
  },
  wrapperBtnContent: {
    backgroundColor: '#fff',
    borderBottom: '1px solid #E7E7E8',
    position: 'fixed',
    zIndex: 150,
    width: '100vw',
    bottom: 0,
    marginLeft: '-10px',
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 10,
    paddingBottom: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapperBtnMenu: {
    marginTop: 15,
    marginBottom: 15,
  },
  wrapperBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  // styling Btn
  btnDanger: {
    cursor: 'pointer',
    backgroundColor: '#F12B2C',
    width: 154,
    paddingTop: 9.5,
    paddingBottom: 9.5,
    borderRadius: 8,
    // marginLeft: 15,
  },
  btnApprove: {
    cursor: 'pointer',
    backgroundColor: '#127EEE',
    width: 154,
    paddingTop: 9.5,
    paddingBottom: 9.5,
    borderRadius: 8,
    // marginLeft: 15,
  },
  btn: {
    backgroundColor: '#969BAB',
    width: 154,
    paddingTop: 9.5,
    paddingBottom: 9.5,
    borderRadius: 8,
    // marginLeft: 15,
  },
  btnText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  wrapperList: {
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  wrapperListGrey: {
    backgroundColor: '#F8FAFB',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  contentCheck: {
    paddingTop: 19,
    paddingLeft: 4,
  },
  contentDesc: {
    paddingTop: 28,
  },
  labelText: {
    fontSize: 14,
    lineHeight: '26px',
    letterSpacing: '0.01em',
    color: '#969BAB',
  },
  valueTextDesc: {
    paddingTop: 18,
    paddingBottom: 20,
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
    paddingRight: 20,
  },
  valueText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 95,
  },
  statusContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 9.5,
  },
  wrapperStatusList: {
    paddingBottom: 9.5,
  },
  btnDetail: {
    paddingTop: 12.5,
    paddingBottom: 34,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  btnDetailText: {
    color: '#127EEE',
    marginLeft: 8,
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  // Pagination styling
  descTable: {
    paddingTop: 11.5,
    paddingBottom: 11.5,
  },
  descTableText: {
    color: '#969BAB',
    fontSize: 16,
    lineHeight: '21px',
  },
  paginationContent: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pagination: {
    borderRadius: 0,
    '&.MuiPagination-root .MuiPagination-ul .MuiPaginationItem-root': {
      margin: 0,
    },
    borderRadius: 0,
    '&.MuiPagination-root .MuiPagination-ul .MuiPaginationItem-rounded': {
      borderRadius: 0,
    },
    '&.MuiPagination-root .MuiPagination-ul .MuiButtonBase-root': {
      backgroundColor: '#fff',
      color: '#969BAB',
    },
    '&.MuiPagination-root .MuiPagination-ul .MuiButtonBase-root.Mui-selected': {
      backgroundColor: '#1B2030',
      color: '#fff',
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-child(1)': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-child(1) button': {
      color: '#1B2030',
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-last-child(1)': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-last-child(1) button': {
      color: '#1B2030',
    },
  },
  // styling menu select
  boxDepartment: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  bodyDataSubText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    marginLeft: 4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  personHide: {
    display: 'none',
  },
  wrapperEntity: {
    paddingRight: 5,
  },
  wrapperDepartment: {
    paddingLeft: 5,
  },
  checkContent: {
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#2B3595',
    },
  },
});

export default ApprovalMobile;
