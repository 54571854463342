import * as types from './types';

// Just Example
export const authReducers = (
  state = {
    isLoading: false,
    access_token: '',
    data: null,
    message: '',
    logout_Message: '',
    isLogin: false,
    verify_isLoading: false,
    verify_message: '',
  },
  action
) => {
  switch (action.type) {
    case types.LOADING:
      return { ...state, isLoading: action.payload };
    case types.FETCHING:
      return {
        ...state,
        access_token: action.payload,
      };
    case types.SET_PROFILE:
      return { ...state, data: action.payload };
    case types.AUTH_LOGIN:
      return { ...state, isLogin: action.payload };
    case types.LOGOUT:
      return {
        ...state,
        data: null,
        message: '',
        access_token: '',
        isLogin: false,
      };
    case types.MESSAGE: {
      return { ...state, message: action.payload };
    }

    case types.VERIFY_LOADING: {
      return { ...state, verify_isLoading: action.payload };
    }
    case types.VERIFY_MESSSAGE: {
      return { ...state, verify_message: action.payload };
    }
    case types.LOGOUT_MESSAGE: {
      return { ...state, logout_Message: action.payload };
    }

    default:
      return state;
  }
};
