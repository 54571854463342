import React from 'react';
import { makeStyles, Grid, Box } from '@material-ui/core';
import classNames from 'classnames';

// Component
import Lib from 'src/utils/Lib';
import { ContentText } from '../../../../libs/Typography';
import { ButtonPrimary } from '../../../../libs/ButtonUpload';

// Icons
import Trash from '../../../../assets/Icons/trash.png';
import Paper from '../../../../assets/Icons/paper.png';
import Plus from '../../../../assets/Icons/plus.png';

const RenderItemFile = ({
  file,
  index,
  lastIndex,
  categoryIndex,
  handleRemoveFile,
  handleAddMoreFile,
  detail,
  isRevision,
}) => {
  const classes = useStyles();

  const handleSeeLink = (url) => {
    window.open(url, '_blank');
  };

  const renderButton = () => {
    if(isRevision){
      return (
        <Box
          className={classes.pointer}
          onClick={() => handleRemoveFile(categoryIndex, file.id, true)}
        >
          <img src={Trash} alt="none" />
        </Box>
      )
    }
    return null
  }

  return (
    <>
      <Box key={index} className={classes.fileContent}>
        <Box
          className={detail ? classes.fileDescLink : classes.fileDesc}
          onClick={detail ? () => handleSeeLink(file.files[0]?.url) : null}
        >
          <img src={Paper} alt="none" />
          <ContentText
            styling={detail ? classes.filenameLink : classes.filename}
            content={detail ? Lib.ellipsis(file?.files[0].file_name) : Lib.ellipsis(file?.name)}
          />
        </Box>
        
        {detail ? renderButton() : (
          <Box className={classes.fileAction}>
            <Box
              className={classes.pointer}
              onClick={() => handleRemoveFile(categoryIndex, index)}
            >
              <img src={Trash} alt="none" />
            </Box>

            {(index === lastIndex || index + 1 === lastIndex) && (
              <form>
                <input
                  className={classes.input}
                  style={{ display: 'none' }}
                  id={`upload-more-${categoryIndex}-${index}`}
                  name={`${categoryIndex},${index}`}
                  onChange={handleAddMoreFile}
                  type="file"
                />
                <label htmlFor={`upload-more-${categoryIndex}-${index}`}>
                  <Box className={classes.pointer}>
                    <img src={Plus} alt="none" />
                  </Box>
                </label>
              </form>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

const ReportDoc = (props) => {
  const classes = useStyles();
  const {
    tempCategory,
    handleAddFile,
    handleRemoveFile,
    handleAddMoreFile,
    tempFileDKT,
    detail,
    isRevision,
    showSectionDKT,
  } = props;

  const fileItem = (file, fileIndex, item, index) => {
    if(detail) 
      return (
        <RenderItemFile
          key={fileIndex}
          file={file}
          index={fileIndex}
          lastIndex={item?.file?.filter(e => !e.deleted).length}
          categoryIndex={index}
          handleRemoveFile={handleRemoveFile}
          handleAddMoreFile={handleAddMoreFile}
          detail
          isRevision={isRevision}
        />
      ) 
    return (
      <RenderItemFile
        key={fileIndex}
        file={file}
        index={fileIndex}
        lastIndex={item?.file?.filter(e => !e.deleted).length}
        categoryIndex={index}
        handleRemoveFile={handleRemoveFile}
        handleAddMoreFile={handleAddMoreFile}
        isRevision={isRevision}
      />)
  }

  const reUploadItem = (item, index) => {
    if (item?.status === 'revise') {
      if(item?.reupload?.length < 1) {
        return(
          <Box className={classes.btnActionReupload}>
            <form>
              <input
                className={classes.input}
                style={{ display: 'none' }}
                id={`upload-${index}`}
                name={`${index}`}
                onChange={handleAddFile}
                type="file"
              />
              <label htmlFor={`upload-${index}`}>
                <ButtonPrimary />
              </label>
            </form>
          </Box>
        )
      } else { 
        return item?.reupload?.map((file, fileIndex) => (
          <RenderItemFile
            key={fileIndex}
            file={file}
            index={fileIndex}
            lastIndex={item?.file.filter(e => !e.deleted).length}
            categoryIndex={index}
            handleRemoveFile={handleRemoveFile}
            handleAddMoreFile={handleAddMoreFile}
          />
        ))
      }
    }
  }

  return (
    <Grid xs={12} md={12} className={classes.mainContainer}>
      <ContentText
        styling={classes.titleMenu}
        content={`Report Document Checklist`}
      />

      <Box className={classes.contentUpload}>
        {tempCategory.map((item, index) => (
          <>
            <Box
              key={index}
              className={classNames(
                classes.categoryBox,
                index % 2 == 0 ? classes.backWhite : classes.backGWhite,
                index === 0 ? classes.bordeTopRad : null,
                index + 1 === tempCategory.length ? classes.bordeBotRad : null
              )}
            >
              <Box className={classes.categoryContent}>
                <Box className={classes.labelContainer}>
                  <div>
                    <ContentText
                      styling={classes.categoryTitle}
                      content={item.name}
                    />
                    <ContentText
                      styling={classes.categoryText}
                      content={item.desc}
                    />
                  </div>
                  {item?.status === 'revise' && (
                    <>
                      <ContentText
                        styling={classes.revisionLabel}
                        content={`(Revision)`}
                      />
                      <Box className={classes.revisionNotesContent}>
                        <ContentText
                          styling={classes.revisionNotesLabel}
                          content={`Notes :`}
                        />
                        <ContentText
                          styling={classes.revisionNotesText}
                          content={`${item?.notes}`}
                        />
                      </Box>
                    </>
                  )}
                </Box>
                {item?.file?.length < 1 ? (
                  <Box className={classes.btnAction}>
                    <form>
                      <input
                        className={classes.input}
                        style={{ display: 'none' }}
                        id={`upload-${index}`}
                        name={`${index}`}
                        onChange={handleAddFile}
                        type="file"
                      />
                      <label htmlFor={`upload-${index}`}>
                        <ButtonPrimary />
                      </label>
                    </form>
                  </Box>
                ) : (
                  <>
                    <Box>
                      {item?.file?.filter(e => !e.deleted).map((file, fileIndex)=>fileItem(file, fileIndex, item, index))}
                      {reUploadItem(item, index)}
                      <Box></Box>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </>
        ))}

        { showSectionDKT && (
        <>
        <Box className={classes.uploadInfo}>
          <ContentText
            content={`Bagian dibawah diisi oleh pihak DKT`}
            styling={classes.textinfo}
          />
          <Box className={classes.border} />
        </Box>
        <Box className={classes.uploadInfoContent}>
          {tempFileDKT?.map((item, index) => (
            <>
              <Box
                key={index}
                className={classNames(
                  classes.uploadInfoBox,
                  index % 2 == 0 ? classes.backWhite : classes.backGWhite,
                  index === 0 ? classes.bordeTopRad : null,
                  index + 1 === tempCategory.length ? classes.bordeBotRad : null
                )}
              >
                <Box>
                  <ContentText
                    styling={classes.labelInfo}
                    content={item.name}
                  />
                  {item?.note !== '' && (
                    <ContentText
                      styling={classes.labelSubInfo}
                      content={item.note}
                    />
                  )}
                  {item?.type === 'value' && (
                    <Box className={classes.inputInfo}>
                      <Box className={classes.inputDesc}>
                        <ContentText
                          styling={classes.inputDescLabel}
                          content={`Nomor Invoice`}
                        />
                        <Box className={classes.inputDescText}>
                          <ContentText
                            styling={classes.inputDescTextContent}
                            content={`-`}
                          />
                        </Box>
                        <Box className={classes.distLeft} />
                        <ContentText
                          styling={classes.inputDescLabel}
                          content={`Jumlah yang ditagihkan`}
                        />
                        <Box className={classes.inputDescText}>
                          <ContentText
                            styling={classes.inputDescTextContent}
                            content={`-`}
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box className={classes.btnAction}>
                  <ButtonPrimary disable={true} />
                </Box>
              </Box>
            </>
          ))}
        </Box>
        </>)}
      </Box>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingTop: 34,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: '21.4px',
  },
  titleMenu: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '21px',
    color: '#969BAB',
  },
  contentUpload: {
    marginTop: 25,
  },
  categoryText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 400,
    marginBottom: 15,
  },
  categoryTitle: {
    fontWeight: '700'
  },
  categoryBox: {
    padding: '14px 20px',
    border: '1px solid #E7E7E8',
  },
  uploadInfoBox: {
    padding: '14px 20px',
    border: '1px solid #E7E7E8',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  categoryContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  btnAction: {
    marginRight: 100,
  },
  btnActionReupload: {
    '& label > div': {
      marginTop: '8px',
      marginLeft: 'auto',
      marginRight: '38px',
    },
  },
  backWhite: {
    background: '#fff',
  },
  backGWhite: {
    background: '#F8FAFB',
  },
  bordeTopRad: {
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  },
  bordeBotRad: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  fileContent: {
    padding: '6px 20px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  pointer: {
    cursor: 'pointer',
  },
  fileDesc: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  fileDescLink: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
  },
  filename: {
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 500,
    marginRight: 18,
    marginLeft: 12,
  },
  filenameLink: {
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 500,
    marginRight: 18,
    marginLeft: 12,
    textDecoration: 'underline',
  },
  fileAction: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 65,
  },
  textinfo: {
    fontSize: 14,
    lineHeight: '19px',
    fontWeight: 400,
    fontStyle: 'italic',
    color: '#969BAB',
    marginRight: 20,
  },
  border: {
    flex: 1,
    border: '1px dashed #E7E7E8',
  },
  uploadInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 17,
    paddingBottom: 21,
  },
  labelInfo: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 400,
    color: '#000000',
  },
  labelSubInfo: {
    marginTop: 11,
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 400,
    color: '#85878E',
  },
  inputInfo: {
    paddingTop: 24,
    paddingBottom: 21,
    paddingLeft: 40,
    paddingRight: 20,
  },
  inputDesc: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  inputDescLabel: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 700,
    color: '#000000',
  },
  inputDescText: {
    background: '#EBEFF0',
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    height: 34,
    width: 234,
    marginLeft: 10,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 4,
  },
  inputDescTextContent: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 400,
    color: '#969BAB',
  },
  distLeft: {
    marginLeft: 10,
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  revisionLabel: {
    fontSize: 16,
    lineHeight: 1.5,
    color: '#F3F5F6',
    background: '#FD453A',
    borderRadius: 5,
    marginLeft: 12,
    padding: '2px 8px',
  },
  revisionNotesContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  revisionNotesLabel: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
    fontWeight: 700,
    marginLeft: 25,
  },
  revisionNotesText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
    fontWeight: 400,
    marginLeft: 12,
  },
}));

export default ReportDoc;
