import React from 'react';
import { makeStyles, Grid, Box, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useRouter } from '../../../../utils/useRouter';
import { useSelector, useDispatch } from 'react-redux';
import * as settingAction from '../../../../redux/Settings/actions';

// Components
import GroupCodeData from './TableData/GroupCodeData';
import SortArrow from '../../../SortArrow';
import CustomizedSnackbars from '../../../PopupMessage';
import { TabelLoading, TableNoData } from '../../../Loading/TableLoading';
import ModalDeleteItem from '../ModalDeleteItem';
import ModalSuccess from '../../../ModalSuccess';

const GroupCodeListing = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispacth = useDispatch();

  // Global Variable
  const { access_token } = useSelector((state) => state.auth);
  const {
    distributor_isLoading,
    distributor_data,
    distributor_delIsLoading,
    distributor_delMessage,
    suppiler_isLoading,
    supplier_data,
    supplier_delIsLoading,
    supplier_delMessage,
  } = useSelector((state) => state.settings);

  // for initial state
  const [rowData, setRowData] = React.useState([]);
  const [tempDeleteItem, setTempDeleteItem] = React.useState(0);
  const [tempDistributor, setTempDistributor] = React.useState([]);
  const [tempSupplier, setTempSupplier] = React.useState([]);

  // state for modal
  const [showModalDelete, setShowModalDelete] = React.useState(false);
  const [showModalSuccess, setShowModalSuccess] = React.useState(false);

  // handle message
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };
  //  end handle Message

  // function register

  const handleDeleteItem = (item) => {
    setTempDeleteItem(item);
    setShowModalDelete(true);
  };

  const handleActionDelete = () => {
    if (tempDeleteItem.client_category === 'Distributor') {
      dispacth(
        settingAction.deleteDistributor(access_token, tempDeleteItem.id)
      );
    } else {
      dispacth(settingAction.deleteSupplier(access_token, tempDeleteItem.id));
    }
  };

  const handleActionSuccess = () => {
    setShowModalSuccess(false);
    setShowModalDelete(false);
    dispacth(settingAction.getDistributor(access_token));
    dispacth(settingAction.getSupplier(access_token));
  };

  const handleEditItem = (id, category) => {
    router.push(`/master-data/groupCode/${id}/${category}/edit`);
  };

  React.useEffect(() => {
    dispacth(settingAction.getDistributor(access_token));
    dispacth(settingAction.getSupplier(access_token));
  }, []);

  React.useEffect(() => {
    let tempArr = [];
    distributor_data?.distributorgc?.map((item) => {
      let tempData = {
        id: item?.id,
        group_code: item?.name,
        client_category: 'Distributor',
        desc: '-',
      };
      tempArr.push(tempData);
    });
    setTempDistributor(tempArr);
  }, [distributor_data]);

  React.useEffect(() => {
    let tempArr = [];
    supplier_data?.suppliergc?.map((item) => {
      let tempData = {
        id: item?.id,
        group_code: item?.name,
        client_category: 'Supplier',
        desc: '-',
      };
      tempArr.push(tempData);
    });
    setTempSupplier(tempArr);
  }, [supplier_data]);

  React.useEffect(() => {
    let tempArr = [];
    tempArr = [...tempDistributor, ...tempSupplier];
    setRowData(tempArr);
  }, [tempDistributor, tempSupplier]);

  React.useEffect(() => {
    if (distributor_delMessage) {
      if (distributor_delMessage === 'Delete distributor group code Success') {
        setShowModalSuccess(true);
      } else if (
        distributor_delMessage !== '' &&
        distributor_delMessage !== 'Delete distributor group code Success'
      ) {
        setShow(true);
        setType('error');
        setNotifText(distributor_delMessage);
      }
      dispacth(settingAction.setBlankDeleteDistributor());
    }
  }, [distributor_delMessage]);

  React.useEffect(() => {
    if (supplier_delMessage) {
      if (supplier_delMessage === 'Delete Supplier group code Success') {
        setShowModalSuccess(true);
      } else if (
        supplier_delMessage !== '' &&
        supplier_delMessage !== 'Delete Supplier group code Success'
      ) {
        setShow(true);
        setType('error');
        setNotifText(supplier_delMessage);
      }
      dispacth(settingAction.setBlankDeleteSupplier());
    }
  }, [supplier_delMessage]);

  return (
    <>
      <CustomizedSnackbars
        show={show}
        handleClose={handleClose}
        message={notifText}
        type={type}
      />
      <ModalDeleteItem
        open={showModalDelete}
        close={() => setShowModalDelete(false)}
        onPress={handleActionDelete}
        isLoading={
          supplier_delIsLoading || distributor_delIsLoading ? true : false
        }
      />
      <ModalSuccess open={showModalSuccess} onPress={handleActionSuccess} />
      <div style={{ width: '100%' }}>
        <Grid md={12} classes={classes.wrapperTable}>
          <TableContainer className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Box className={classes.wrapperTableHeader}>
                      <Typography className={classes.headeText}>ID</Typography>
                      <SortArrow />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className={classes.wrapperTableHeader}>
                      <Typography className={classes.headeText}>
                        Group Code
                      </Typography>
                      <SortArrow />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className={classes.wrapperTableHeader}>
                      <Typography className={classes.headeText}>
                        Client Category
                      </Typography>
                      <SortArrow />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className={classes.wrapperTableHeader}>
                      <Typography className={classes.headeText}>
                        Description
                      </Typography>
                      <SortArrow />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className={classes.wrapperTableHeader}>
                      <Typography className={classes.headeText}>
                        Action
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {suppiler_isLoading ||
                distributor_isLoading ? null : rowData.length > 0 ? (
                  rowData.map((row, index) => (
                    <GroupCodeData
                      row={row}
                      index={index}
                      key={index}
                      handleEditItem={handleEditItem}
                      handleDeleteItem={handleDeleteItem}
                    />
                  ))
                ) : (
                  <TableNoData colspan={5} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {suppiler_isLoading || distributor_isLoading ? (
            <TabelLoading />
          ) : null}
        </Grid>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapperTableHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headeText: {
    fontSize: 16,
    lineHeight: '30px',
    fontWeight: 'bold',
    color: '#000000',
  },
  tableContainer: {
    '& .MuiTableRow-root.MuiTableRow-head': {
      backgroundColor: '#fff',
    },
    '& .MuiTableRow-head .MuiTableCell-head': {
      borderBottom: 'none',
    },
    '& .MuiTableRow-head .MuiTableCell-head:nth-child(1)': {
      width: 91,
    },
    '& .MuiTableRow-head .MuiTableCell-head:nth-child(5)': {
      width: 100,
      paddingLeft: 20,
      paddinRigth: 20,
    },
  },
}));

const activityFake = [
  {
    id: 1,
    group_code: 'Related Party (C)',
    client_category: 'Distributor',
    desc: 'lorem ipsum',
  },
  {
    id: 2,
    group_code: 'Distributors',
    client_category: 'Distributor',
    desc: 'lorem ipsum',
  },
  {
    id: 3,
    group_code: 'Export',
    client_category: 'Distributor',
    desc: 'lorem ipsum',
  },
];

export default GroupCodeListing;
