import * as types from './types';

// Just Example
export const apInvoiceReducers = (
  state = {
    notifLoading: false,
    notifMesage: '',
    notfiData: null,
    noitfDraf: 0,
    apinvoice_dashboardNotif: null,
    data_apinvoice: null,
    isLoading: false,
    message: '',
    apinvoice_detail: null,
    detail_message: '',
    detail_isLoading: false,
    apinvoice_sap: null,
    apinvoice_sapLoading: false,
    apinvoice_sapMessage: '',
    del_apinvoiceLoading: false,
    del_apinvoiceMessage: '',
    publish_apinvoiceLoading: false,
    publish_apinvoiceMessage: '',
    cancel_apinvoiceLoading: false,
    cancel_apinvoiceMessage: '',
    assignment_loading: false,
    assigment_message: '',
    ap_transactionIsloading: false,
    ap_transactionMessage: '',
  },
  action
) => {
  switch (action.type) {
    // notif GRPO
    case types.APINVOICE_NOTIF_LOADING:
      return { ...state, notifLoading: action.payload };

    case types.APINVOICE_NOTIF_MESSAGE:
      return { ...state, notifMesage: action.payload };

    case types.APINVOICE_NOTIF_DATA:
      return { ...state, notfiData: action.payload };

    case types.APINVOICE_NOTIF_DRAFT:
      return { ...state, noitfDraf: action.payload };

    case types.APINVOICE_NOTIF_DASHBOARD_DATA:
      return { ...state, apinvoice_dashboardNotif: action.payload };

    // GRPO data
    case types.APINVOICE_SET_DATA:
      return { ...state, data_apinvoice: action.payload };

    case types.APINVOICE_DATA_MESSAGE:
      return { ...state, message: action.payload };

    case types.APINVOICE_DATA_LOADING:
      return { ...state, isLoading: action.payload };

    // detail grpo
    case types.APINVOICE_DETAIL_DATA:
      return { ...state, apinvoice_detail: action.payload };

    case types.APINVOICE_DETAIL_MESSAGE:
      return { ...state, detail_message: action.payload };

    case types.APINVOICE_DETAIL_LOADING:
      return { ...state, detail_isLoading: action.payload };

    // Sync SAP GRPO
    case types.APINVOICE_SYNC_DATA:
      return { ...state, apinvoice_sap: action.payload };

    case types.APINVOICE_SYNC_MESSAGE:
      return { ...state, apinvoice_sapMessage: action.payload };

    case types.APINVOICE_SYNC_LOADING:
      return { ...state, apinvoice_sapLoading: action.payload };

    // delete GRPO
    case types.APINVOICE_DELETE_LOADING:
      return { ...state, del_apinvoiceLoading: action.payload };

    case types.APINVOICE_DELETE_MESSAGE:
      return { ...state, del_apinvoiceMessage: action.payload };

    // publish GRPO
    case types.APINVOICE_PUBLISH_LOADING:
      return { ...state, publish_apinvoiceLoading: action.payload };

    case types.APINVOICE_PUBLISH_MESSAGE:
      return { ...state, publish_apinvoiceMessage: action.payload };

    // Cancel GRPO
    case types.APINVOICE_CANCEL_LOADING:
      return { ...state, cancel_apinvoiceLoading: action.payload };

    case types.APINVOICE_CANCEL_MESSAGE:
      return { ...state, cancel_apinvoiceMessage: action.payload };

    // Assignment GRPO
    case types.APINVOICE_ASSIGNMENT_LOADING:
      return { ...state, assignment_loading: action.payload };

    case types.APINVOICE_ASSIGNMENT_MESSAGE:
      return { ...state, assigment_message: action.payload };

    // transaction approval
    case types.APINVOICE_TRANSACTION_APPROVAL_LOADING:
      return { ...state, ap_transactionIsloading: action.payload };

    case types.APINVOICE_TRANSACTION_APPROVAL_MESSAGE:
      return { ...state, ap_transactionMessage: action.payload };

    default:
      return state;
  }
};
