import * as types from './types';

// Just Example
export const proformaReducers = (
  state = {
    data_proforma: null,
    isLoading: false,
    message: '',
    detail_data: null,
    detai_isLoading: false,
    detail_message: '',
    add_Isloading: false,
    add_message: '',
    add_po_message: '',
    add_client_message: '',
    revise_isLoading: false,
    revise_message: '',
    update_isLoading: false,
    update_message: '',
    delete_isLoading: false,
    delete_message: '',
    cancel_isLoading: false,
    cancel_message: '',
    update_vendor_Isloading: false,
    update_vendor_message: '',
    preview_data: null,
    approval_isLoading: false,
    approval_message: '',
    upload_isLoading: false,
    upload_message: '',
    approval_bast_isLoading: false,
    approval_bast_message: '',
    process_isLoading: false,
    process_message: '',
  },
  action
) => {
  switch (action.type) {
    // get proforma
    case types.PROFORMA_DATA_LOADING:
      return { ...state, isLoading: action.payload };

    case types.PROFORMA_DATA_MESSAGE:
      return { ...state, message: action.payload };

    case types.PROFORMA_SET_DATA:
      return { ...state, data_proforma: action.payload };

    // get proforma by id
    case types.PROFORMA_DETAIL_LOADING:
      return { ...state, detai_isLoading: action.payload };

    case types.PROFORMA_DETAIL_MESSAGE:
      return { ...state, detail_message: action.payload };

    case types.PROFORMA_DETAIL_DATA:
      return { ...state, detail_data: action.payload };

    // add proforma
    case types.PROFORMA_ADD_LOADING:
      return { ...state, add_Isloading: action.payload };

    case types.PROFORMA_ADD_MESSAGE:
      return { ...state, add_message: action.payload };

    case types.PROFORMA_ADD_PO_MESSAGE:
      return { ...state, add_po_message: action.payload };

    case types.PROFORMA_ADD_CLIENT_MESSAGE:
      return { ...state, add_client_message: action.payload };

    // revise proforma
    case types.PROFORMA_REVISE_LOADING:
      return { ...state, revise_isLoading: action.payload };

    case types.PROFORMA_REVISE_MESSAGE:
      return { ...state, revise_message: action.payload };

    // update proforma
    case types.PROFORMA_UPDATE_LOADING:
      return { ...state, update_isLoading: action.payload };

    case types.PROFORMA_UPDATE_MESSAGE:
      return { ...state, update_message: action.payload };

    // delete
    case types.PROFORMA_DELETE_LOADING:
      return { ...state, delete_isLoading: action.payload };

    case types.PROFORMA_DELETE_MESSAGE:
      return { ...state, delete_message: action.payload };

    // cancel
    case types.PROFORMA_CANCEL_LOADING:
      return { ...state, cancel_isLoading: action.payload };

    case types.PROFORMA_CANCEL_MESSAGE:
      return { ...state, cancel_message: action.payload };

    // update vendor
    case types.PROFORMA_UPDATE_VENDOR_LOADING:
      return { ...state, update_vendor_Isloading: action.payload };

    case types.PROFORMA_UPDATE_VENDOR_MESSAGE:
      return { ...state, update_vendor_message: action.payload };

    // approval proforma
    case types.PROFORMA_APPROVAL_LOADING:
      return { ...state, approval_isLoading: action.payload };

    case types.PROFORMA_APPROVAL_MESSAGE:
      return { ...state, approval_message: action.payload };

    // for upload dkt
    case types.PROFORMA_UPLOAD_LOADING:
      return { ...state, upload_isLoading: action.payload };

    case types.PROFORMA_UPLOAD_MESSAGE:
      return { ...state, upload_message: action.payload };

    // approval bast proforma
    case types.PROFORMA_APPROVAL_BAST_LOADING:
      return { ...state, approval_bast_isLoading: action.payload };

    case types.PROFORMA_APPROVAL_BAST_MESSAGE:
      return { ...state, approval_bast_message: action.payload };

    // process GRPO
    case types.PROFORMA_PROCESS_GRPO_LOADING:
      return { ...state, process_isLoading: action.payload };

    case types.PROFORMA_PROCESS_GRPO_MESSAGE:
      return { ...state, process_message: action.payload };

    default:
      return state;
  }
};
