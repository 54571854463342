import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../redux/Approval/actions';
import * as utility from '../../redux/Utility/actions';
import { formatRupiah } from '../../utils/formatter';

// Components
import ModalApproveDetail from './components/ModalApproveDetail';
import ModalApproveMessage from '../ModalApproveMessage';
import ModalRejectMessage from '../ModalRejectMessage';
import ModalSuccess from '../ModalSuccess';
import ApprovalDesktop from './components/ApprovalDesktop';
import ApprovalMobile from './components/ApprovalMobile';
import CustomizedSnackbars from '../PopupMessage';
import POpreview from '../PDFpreview/POpreview';

const ApprovalCA = ({
  entity_data,
  access_token,
  deviceType,
  router,
  userData,
}) => {
  const dispacth = useDispatch();

  // Global Variable
  const {
    isLoadingCA,
    data_approvalCA,
    update_caIsloading,
    update_caMessage,
    messageCA,
  } = useSelector((state) => state.approval);
  const { department_data } = useSelector((state) => state.utility);
  const [filtered_department_data, setFilteredDepartmentData] = React.useState([]);

  // Create format for table and pagination
  const [page, setPage] = React.useState(1);
  const [pagination, setPagination] = React.useState(1);
  const [fromRow, setFromRow] = React.useState(1);
  const [toRow, setToRow] = React.useState(1);
  const [TotalRow, setTotalRow] = React.useState(1);
  const [limit, setLimit] = React.useState(10);

  // for initial state
  const [data, setData] = React.useState([]);
  const [dataModalDetail, setDataModalDetail] = React.useState(null);
  const [departementFilter, setDepartementFilter] = React.useState(0);
  const [entityFilter, setEntityFilter] = React.useState(0);
  const [numberSelect, setNumberSelect] = React.useState(0);
  const [isSelectAll, setIsSelectAll] = React.useState(false);
  const [isEnableSelectAll, setIsEnableSelectAll] = React.useState(false);
  const [pdfData, setPdfData] = React.useState(null);
  const [itemAvailable, setItemAvalilable] = React.useState(0);

  // state for modal
  const [showFilter, setShowFilter] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [showModalMessageApprove, setShowModalMessageApprove] =
    React.useState(false);
  const [showModalMessageReject, setShowModalMessageReject] =
    React.useState(false);
  const [showModalSuccess, setShowModalSuccess] = React.useState(false);
  const [showPDFpreview, setShowPDFpreview] = React.useState(false);

  // handle message
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };

  const handleMessageInfo = (type, status, message) => {
    setType(type);
    setShow(status);
    setNotifText(message);
  };

  // end handle Message

  const handleChangeDepartement = (event) => {
    setDepartementFilter(event.target.value);
  };

  const handleChangeEntity = (event) => {
    setEntityFilter(event.target.value);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
  };

  const handleChangePagination = (event, value) => {
    setPagination(value);
  };

  const handleChange = (e) => {
    const { checked, name } = e.target;
    // console.log('e', e);
    // console.log('check', checked);
    // console.log('id', id);
    if (name === 'allSelect') {
      let tempData = data.map((row, index) => {
        if (row.read_only) {
          return row;
        } else {
          return { ...row, isChecked: checked };
        }
      });
      setIsSelectAll(!isSelectAll);
      setData(tempData);
    } else {
      let tempData = data.map((row, index) =>
        row.id == name ? { ...row, isChecked: checked } : row
      );
      setIsSelectAll(false);
      setData(tempData);
    }
  };

  // request API
  const GETApproval = () => {
    let params = {
      doc_type: 'CA',
      entity_id: entityFilter,
      dept_id: departementFilter,
      page: pagination,
      order_by: 'id',
      order_dir: 'desc',
      limit: limit,
    };

    if (departementFilter === 0) delete params.dept_id;

    if (entityFilter === 0) delete params.entity_id;

    dispacth(actions.getApprovalCA(access_token, params));
  };

  const ApproveAction = () => {
    let dataApprover = data?.filter((dt, index) => dt.isChecked === true);

    if (dataApprover.length > 1) {
      let tempArrId = [];
      dataApprover.map((item, index) => {
        let id = item?.id;
        tempArrId.push(id);
      });

      let tempId = tempArrId.join(';');
      let sendData = {
        doc_id: tempId,
        approve: 1,
        reject: 0,
        message: '',
        transaction_type_id: 11,
      };

      dispacth(actions.updateApprovalCA(access_token, sendData));
    } else {
      let sendData = {
        doc_id: dataApprover[0].id,
        approve: 1,
        reject: 0,
        message: '',
        transaction_type_id: 11,
      };

      dispacth(actions.updateApprovalCA(access_token, sendData));
    }
  };

  const rejectAction = (textNote) => {
    let dataApprover = data?.filter((dt, index) => dt.isChecked === true);

    if (dataApprover.length > 1) {
      let tempArrId = [];
      dataApprover.map((item, index) => {
        let id = item?.id;
        tempArrId.push(id);
      });

      let tempId = tempArrId.join(';');
      let sendData = {
        doc_id: tempId,
        approve: 0,
        reject: 1,
        message: textNote,
        transaction_type_id: 11,
      };
      // console.log(sendData);
      dispacth(actions.updateApprovalCA(access_token, sendData));
    } else {
      let sendData = {
        doc_id: dataApprover[0].id,
        approve: 0,
        reject: 1,
        message: textNote,
        transaction_type_id: 11,
      };
      // console.log(sendData);
      dispacth(actions.updateApprovalCA(access_token, sendData));
    }
  };

  // React.useEffect(() => {
  //   let params = {
  //     doc_type: 'PO',
  //     page: 1,
  //     order_by: 'id',
  //     order_dir: 'desc',
  //     limit: limit,
  //   };

  //   dispacth(actions.getApprovalPO(access_token, params));
  // }, []);

  React.useEffect(() => {
    setIsSelectAll(false);
    GETApproval();
  }, [entityFilter, departementFilter, pagination, limit]);

  React.useEffect(() => {
    setData(data_approvalCA?.ApprovalTransaction?.data);

    let countPage = Math.ceil(
      data_approvalCA?.ApprovalTransaction?.total / limit
    );
    setPage(countPage);
    setFromRow(data_approvalCA?.ApprovalTransaction?.from);
    setToRow(data_approvalCA?.ApprovalTransaction?.to);
    setTotalRow(data_approvalCA?.ApprovalTransaction?.total);

  }, [data_approvalCA]);

  React.useEffect(() => {
    let dataLenght = data?.filter((dt, index) => dt.isChecked === true).length;
    setNumberSelect(dataLenght);

    let dataIsEnable = data?.filter(
      (dt, index) => dt.read_only === false
    ).length;

    dataIsEnable > 0 ? setItemAvalilable(dataIsEnable) : setItemAvalilable(0);

    if (dataIsEnable > 0) {
      setIsEnableSelectAll(true);
    } else {
      setIsEnableSelectAll(false);
      setIsSelectAll(false);
    }

    // show required depts filter
    if (departementFilter === 0 && data) {
      const allDepts = [];

      for (const d of data) {
        if (typeof d.user?.department_id === 'number' && !allDepts.includes(d.user.department_id))
          allDepts.push(d.user.department_id);
      }

      setFilteredDepartmentData(department_data?.filter(d => allDepts.includes(d.id)) || []);
    }
  }, [data]);

  React.useEffect(() => {
    if (entityFilter !== 0) {
      let params = {
        entity_id: entityFilter,
      };
      dispacth(utility.getDepartmentById(access_token, params));
    } else {
      dispacth(utility.setNullDepartmentById());
    }
  }, [entityFilter]);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleCloseModalMessage = () => {
    setShowModalMessageApprove(false);
  };
  const handleCloseModalMessageReject = () => {
    setShowModalMessageReject(false);
  };
  const handleCloseModalSuccess = () => {
    setShowModalSuccess(false);
  };

  const handleViewInformation = (id) => {
    router.push(`/purchase-order-detail/poId=${id}`);
  };

  const handleActionSuccess = () => {
    setShowModalSuccess(false);
    GETApproval();
  };

  const handleShowPDf = (data) => {
    setPdfData(data);
    setShowPDFpreview(true);
  };

  const handleCloseShowPDF = () => {
    setShowPDFpreview(false);
  };

  const handleShowDetail = (data) => {
    // console.log(data, 'sd')
    let showData = {
      id: data?.id,
      no: data?.id,
      name: data?.subject,
      currency: data?.currency,
      department: {
        name: data?.user?.departments?.name,
        code: data?.user?.departments?.code,
      },
      entity: {
        name: data?.user?.entity[0]?.name,
        code: data?.user?.entity[0]?.code,
      },
      // activity:
      //   data?.pr?.items?.length > 0 ? data?.pr?.items[0]?.activity_name : '-',
      total: formatRupiah(data?.amount ?? '0'),
      requestor: data?.user?.name ?? '-',
      email: data?.user?.email ?? '-',
      // budget_type:
      //   data?.pr?.items?.length > 0 ? data?.pr?.items[0]?.budget_code : '-',
      files: data?.files ?? [],
      approval: data?.approval_transactions,
      data,
    };
    setDataModalDetail(showData);
    setShowModal(true);
  };

  const handleChangeViewComponent = (deviceType) => {
    if (deviceType === 'mobile') {
      return (
        <ApprovalMobile
          data={data}
          userData={userData}
          isLoading={isLoadingCA}
          handleChange={handleChange}
          handleChangeEntity={handleChangeEntity}
          handleChangeDepartement={handleChangeDepartement}
          entityFilter={entityFilter}
          entity_data={entity_data}
          departementFilter={departementFilter}
          department_data={filtered_department_data}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          setShowModalMessageReject={setShowModalMessageReject}
          setShowModalMessageApprove={setShowModalMessageApprove}
          setShowModal={setShowModal}
          // perubahan
          handleShowDetail={handleShowDetail}
          numberSelect={numberSelect}
          page={page}
          pagination={pagination}
          fromRow={fromRow}
          toRow={toRow}
          TotalRow={TotalRow}
          handleChangePagination={handleChangePagination}
          isSelectAll={isSelectAll}
          isEnableSelectAll={isEnableSelectAll}
          itemAvailable={itemAvailable}
          limit={limit}
          handleChangeLimit={handleChangeLimit}
        />
      );
    } else {
      return (
        <ApprovalDesktop
          data={data}
          userData={userData}
          isLoading={isLoadingCA}
          handleChange={handleChange}
          handleChangeEntity={handleChangeEntity}
          handleChangeDepartement={handleChangeDepartement}
          entityFilter={entityFilter}
          entity_data={entity_data}
          departementFilter={departementFilter}
          department_data={filtered_department_data}
          setShowModalMessageReject={setShowModalMessageReject}
          setShowModalMessageApprove={setShowModalMessageApprove}
          setShowModal={setShowModal}
          // perubahan
          handleShowDetail={handleShowDetail}
          numberSelect={numberSelect}
          page={page}
          pagination={pagination}
          fromRow={fromRow}
          toRow={toRow}
          TotalRow={TotalRow}
          handleChangePagination={handleChangePagination}
          isSelectAll={isSelectAll}
          isEnableSelectAll={isEnableSelectAll}
          limit={limit}
          handleChangeLimit={handleChangeLimit}
        />
      );
    }
  };

  React.useEffect(() => {
    if (update_caMessage) {
      if (update_caMessage === 'Update Approval Transaction Success') {
        setShowModalMessageApprove(false);
        setShowModalMessageReject(false);
        setShowModalSuccess(true);
      } else if (
        update_caMessage !== '' &&
        update_caMessage !== 'Update Approval Transaction Success'
      ) {
        setType('error');
        setShow(true);
        setNotifText(update_caMessage);
      }
      dispacth(actions.setBlankUpdateCA());
    }
  }, [update_caMessage]);

  return (
    <>
      <CustomizedSnackbars
        show={show}
        handleClose={handleClose}
        message={notifText}
        type={type}
      />
      <ModalApproveDetail
        open={showModal}
        handleCloseModal={handleCloseModal}
        title={'CA'}
        dataModalDetail={dataModalDetail}
        handleViewInformation={handleViewInformation}
        handleShowPDf={handleShowPDf}
      />
      <POpreview
        open={showPDFpreview}
        close={handleCloseShowPDF}
        data={pdfData}
        isMobile={deviceType === 'mobile' ? true : false}
      />
      <ModalApproveMessage
        open={showModalMessageApprove}
        close={handleCloseModalMessage}
        isApprove={true}
        title={'CA'}
        onPress={ApproveAction}
        isLoading={update_caIsloading}
      />
      <ModalRejectMessage
        open={showModalMessageReject}
        close={handleCloseModalMessageReject}
        isLoading={update_caIsloading}
        title={'CA'}
        onPress={rejectAction}
        handleMessageInfo={handleMessageInfo}
      />
      <ModalSuccess
        open={showModalSuccess}
        // handleCloseModalSuccess={handleCloseModalSuccess}
        onPress={handleActionSuccess}
      />
      {handleChangeViewComponent(deviceType)}
    </>
  );
};

export default ApprovalCA;
