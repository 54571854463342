// Get all car
export const CAR_GET_LOADING = 'CAR/CAR_GET_LOADING';
export const CAR_GET_DATA = 'CAR/CAR_GET_DATA';

// save data car
export const CAR_SAVE_LOADING = 'CAR/CAR_SAVE_LOADING';
export const CAR_SAVE_MESSAGE = 'CAR/CAR_SAVE_MESSAGE';
export const CAR_SAVE_DATA = 'CAR/CAR_SAVE_DATA';

// get approval car
export const CAR_APPROVAL_LOADING = 'CAR/CAR_APPROVAL_LOADING';
export const CAR_APRROVAL_DATA = 'CAR/CAR_APRROVAL_DATA';

// get car by id
export const CAR_DETAIL_LOADING = 'CAR/CAR_DETAIL_LOADING';
export const CAR_DETAIL_DATA = 'CAR/CAR_DETAIL_DATA';

// update car
export const CAR_UPDATE_LOADING = 'CAR/CAR_UPDATE_LOADING';
export const CAR_UPDATE_MESSAGE = 'CAR/CAR_UPDATE_MESSAGE';
export const CAR_UPDATE_DATA = 'CAR/CAR_UPDATE_DATA';

// delete car
export const CAR_DELETE_LOADING = 'CAR/CAR_DELETE_LOADING';
export const CAR_DELETE_MESSAGE = 'CAR/CAR_DELETE_MESSAGE';
