import * as types from './types';

// Just Example
export const generalReducers = (
  state = {
    data_number: 0,
  },
  action
) => {
  switch (action.type) {
    case types.SET_BANNER:
      return {
        ...state,
        data_number: action.payload,
      };

    case types.SET_INCREASE:
      return {
        ...state,
        data_number: state.data_number + 1,
      };

    case types.SET_DECREASE:
      return {
        ...state,
        data_number: state.data_number - 1,
      };

    default:
      return state;
  }
};
