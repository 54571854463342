// notif OP
export const OP_NOTIF_LOADING = 'OP/OP_NOTIF_LOADING';
export const OP_NOTIF_MESSAGE = 'OP/OP_NOTIF_MESSAGE';
export const OP_NOTIF_DATA = 'OP/OP_NOTIF_DATA';
export const OP_NOTIF_DASHBOARD_DATA = 'OP/OP_NOTIF_DASHBOARD_DATA';

// get OP SYNC data
export const OP_SYNC_LOADING = 'OP/OP_SYNC_LOADING';
export const OP_SYNC_MESSAGE = 'OP/OP_SYNC_MESSAGE';
export const OP_SYNC_DATA = 'OP/OP_SYNC_DATA';

// get data op
export const OP_DATA_LOADING = 'OP/OP_DATA_LOADING';
export const OP_DATA_MESSAGE = 'OP/OP_DATA_MESSAGE';
export const OP_SET_DATA = 'OP/OP_SET_DATA';

// get detail op
export const OP_DETAIL_LOADING = 'OP/OP_DETAIL_LOADING';
export const OP_DETAIL_MESSAGE = 'OP/OP_DETAIL_MESSAGE';
export const OP_DETAIL_DATA = 'OP/OP_DETAIL_DATA';

// publish OP
export const PUBLISH_OP_LOADING = 'OP/PUBLISH_OP_LOADING';
export const PUBLISH_OP_MESSAGE = 'OP/PUBLISH_OP_MESSAGE';

// confirm Payment op
export const CONFIRM_OP_LOADING = 'OP/CONFIRM_OP_LOADING';
export const CONFIRM_OP_MESSAGE = 'OP/CONFIRM_OP_MESSAGE';

// delete Op
export const DELETE_OP_LOADING = 'OP/DELETE_OP_LOADING';
export const DELETE_OP_MESSAGE = 'OP/DELETE_OP_MESSAGE';
