import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

// Components
import Detail from './components/Detail';
import History from './components/History';
import Attach from './components/Attach';

// Icons
import Cross from '../../assets/Icons/cross.png';
import PdfIcon from '../../assets/Icons/pdfIcons.png';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      paddingTop: 300,
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 30,
      overflow: 'auto',
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: 8,
    maxWidth: 685,
    width: '100%',
    backgroundColor: '#fff',
    [theme.breakpoints.down('md')]: {
      width: '100vw',
    },
  },
  wrapperModal: {
    width: '100%',
  },
  wrapperHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #E7E7E8',
  },
  wrapperTitleHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      alignItems: 'stretch',
    },
  },
  headerLink: {
    padding: 20,
    cursor: 'pointer',
    minHeight: 60,
  },
  headerLinkActive: {
    padding: 20,
    borderBottom: '3px solid #127EEE',
    cursor: 'pointer',
    minHeight: 60,
  },
  headerText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#969BAB',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  headerTextActive: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#127EEE',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  iconContent: {
    paddingRight: 20,
    cursor: 'pointer',
  },
  wrapperFooter: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 20,
    paddingTop: 30,
    paddingBottom: 32,
    paddingRight: 20,
    borderTop: '1px solid #E7E7E8',
    cursor: 'pointer',
  },
  footerText: {
    marginLeft: 8,
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#127EEE',
  },
}));

export default function ModalApproveDetail({
  open,
  handleCloseModal,
  isPR,
  dataModalDetail,
  handleViewInformation,
  title,
  handleShowPDf,
}) {
  const classes = useStyles();

  const [index, setIndex] = React.useState(1);

  const _renderBody = (index) => {
    if (index === 1) {
      return <Detail title={title} dataModalDetail={dataModalDetail} />;
    } else if (index === 2) {
      return <Attach files={dataModalDetail?.files} />;
    } else {
      return <History title={title} approval={dataModalDetail?.approval} />;
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Box className={classes.wrapperModal}>
              <Grid container>
                <Grid xs={12} md={12}>
                  <Box className={classes.wrapperHeader}>
                    <Box className={classes.wrapperTitleHeader}>
                      <Box
                        className={
                          index === 1
                            ? classes.headerLinkActive
                            : classes.headerLink
                        }
                        onClick={() => setIndex(1)}
                      >
                        <Typography
                          className={
                            index === 1
                              ? classes.headerTextActive
                              : classes.headerText
                          }
                          variant="body1"
                          component="p"
                        >
                          {`${title} Detail`}
                        </Typography>
                      </Box>
                      <Box
                        className={
                          index === 2
                            ? classes.headerLinkActive
                            : classes.headerLink
                        }
                        onClick={() => setIndex(2)}
                      >
                        <Typography
                          className={
                            index === 2
                              ? classes.headerTextActive
                              : classes.headerText
                          }
                          variant="body1"
                          component="p"
                        >
                          Attachment
                        </Typography>
                      </Box>
                      <Box
                        className={
                          index === 3
                            ? classes.headerLinkActive
                            : classes.headerLink
                        }
                        onClick={() => setIndex(3)}
                      >
                        <Typography
                          className={
                            index === 3
                              ? classes.headerTextActive
                              : classes.headerText
                          }
                          variant="body1"
                          component="p"
                        >
                          Approval History
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      className={classes.iconContent}
                      onClick={handleCloseModal}
                    >
                      <img
                        // className={classes.bellImage}
                        src={Cross}
                        alt="image not found"
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              {_renderBody(index)}
              <Grid container>
                <Grid xs={12} md={12}>
                  <Box
                    className={classes.wrapperFooter}
                    onClick={() => handleShowPDf(dataModalDetail?.data)}
                  >
                    <Box>
                      <img src={PdfIcon} alt="image not found" />
                    </Box>
                    <Typography
                      className={classes.footerText}
                      variant="body1"
                      component="p"
                    >
                      {`View ${title} Information`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
