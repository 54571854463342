import React from 'react';
import { Grid, makeStyles, Box, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useRouter } from '../../../../utils/useRouter';
import { useSelector, useDispatch } from 'react-redux';
import * as settingAction from '../../../../redux/Settings/actions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// Components
import InputGroup from '../../../inputGroup';
import ModalConfirm from '../ModalComfirm';
import ModalSuccess from '../../../ModalSuccess';
import CustomizedSnackbars from '../../../PopupMessage';
import { CircleLoading } from '../../../Loading/CircleLoading';

const schema = yup.object().shape({
  CategoryName: yup.string().required(),
  // Description: yup.string().required(),
});

const ClientCategoryEdit = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispacth = useDispatch();
  const { id } = router.query;
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const { CategoryName } = data;

    let sendData = {
      name: CategoryName,
      // description: Description,
    };

    setTempSendData(sendData);
    setShowModalConfirm(true);
  };

  // Global Variable
  const { access_token } = useSelector((state) => state.auth);
  const {
    clientCategory_detailIsLoading,
    clientCategory_detail,
    clientCategory_updateIsLoading,
    clientCategory_updateMessage,
  } = useSelector((state) => state.settings);

  // for initial state
  const [dataInput, setDataInput] = React.useState(null);
  const [tempSendData, setTempSendData] = React.useState(null);
  const [isMandatory, setIsMandatory] = React.useState(false);

  // state for modal
  const [showModalConfirm, setShowModalConfirm] = React.useState(false);
  const [showModalSuccess, setShowModalSuccess] = React.useState(false);

  // handle message
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };
  //  end handle Message

  const handleChange = (event) => {
    // setIsMandatory(event.target.checked);
    setIsMandatory(!isMandatory);
  };

  // for wacth value inside useform
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      // console.log(value, name, type)
      setDataInput(value)
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  React.useEffect(() => {
    let params = {
      id,
    };
    dispacth(settingAction.detailClientCategory(access_token, params));
  }, []);

  React.useEffect(() => {
    setValue('CategoryName', clientCategory_detail?.CategoryClient?.name);
  }, [clientCategory_detail]);

  React.useEffect(() => {
    if (clientCategory_updateMessage) {
      if (clientCategory_updateMessage === 'Change category-client Success') {
        setShowModalSuccess(true);
      } else if (
        clientCategory_updateMessage !== '' &&
        clientCategory_updateMessage !== 'Change category-client Success'
      ) {
        setShow(true);
        setType('error');
        setNotifText(clientCategory_updateMessage);
      }
      dispacth(settingAction.setBlankUpdateClientCategory());
    }
  }, [clientCategory_updateMessage]);

  return (
    <>
      <CustomizedSnackbars
        show={show}
        handleClose={handleClose}
        message={notifText}
        type={type}
      />
      <ModalConfirm
        open={showModalConfirm}
        handleCloseModalConfirm={() => setShowModalConfirm(false)}
        onPress={() =>
          dispacth(
            settingAction.updateClientCategory(access_token, id, tempSendData)
          )
        }
        isLoading={clientCategory_updateIsLoading}
      />
      <ModalSuccess
        open={showModalSuccess}
        onPress={() => router.push('/master-data')}
      />
      <Grid md={12} className={classes.wrapperForm}>
        <Box className={classes.sectionHeader}>
          <Typography className={classes.headerTitle}>
            Edit Client Category
          </Typography>
        </Box>
        <Box className={classes.sizeBorder} />
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Grid md={12} className={classes.wrapperBody}>
            <InputGroup
              label="CategoryName"
              placeholder="input Text"
              register={register}
              required
              type="text"
              isRequire={true}
            />
            <p className={classes.errorText}>{errors.CategoryName?.message}</p>
            {/* <Box className={classes.sizeTop} />
            <InputGroup
              label="Description"
              placeholder="input Text"
              register={register}
              required
              type="text"
              isRequire={true}
            />
            <p className={classes.errorText}>{errors.Description?.message}</p> */}
            {/* <Box className={classes.sizeTop} />
            <Typography className={classes.LabelText}>Mandatory</Typography>
            <Grid xs={12} container>
              <Grid xs={6} item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isMandatory}
                      onChange={handleChange}
                      color="primary"
                    />
                  }
                  label="Yes"
                />
              </Grid>
              <Grid xs={6} item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!isMandatory}
                      onChange={handleChange}
                      color="primary"
                    />
                  }
                  label="No"
                />
              </Grid>
            </Grid> */}
          </Grid>

          <Box className={classes.sizeBorder} />
          <Grid md={12} className={classes.wrapperFooter}>
            <input
              className={
                dataInput?.CategoryName || clientCategory_updateIsLoading
                  ? classes.submitBtn
                  : classes.submitBtnFalse
              }
              type="submit"
              value="Submit"
            />

            {clientCategory_updateIsLoading ? (
              <Box className={classes.loadingContent}>
                <CircleLoading />
              </Box>
            ) : (
              <Box
                className={classes.btnCancel}
                onClick={() => router.push('/master-data')}
              >
                <Typography>Cancel</Typography>
              </Box>
            )}
          </Grid>
        </form>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  wrapperForm: {
    width: '100%',
    maxWidth: 605,
    backgroundColor: '#fff',
    margin: 'auto',
    borderRadius: 8,
  },
  sectionHeader: {
    padding: 20,
  },
  headerTitle: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  sizeBorder: {
    borderBottom: '1px solid #E7E7E8',
  },
  wrapperBody: {
    padding: 20,
  },
  sizeTop: {
    marginTop: 20,
  },
  wrapperFooter: {
    padding: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  submitBtn: {
    width: 193,
    height: 50,
    borderRadius: 8,
    backgroundColor: '#1B2030',
    color: '#fff',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  btnCancel: {
    marginLeft: 20,
    width: 193,
    height: 50,
    borderRadius: 8,
    backgroundColor: '#fff',
    color: '#1B2030',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  submitBtnFalse: {
    width: 193,
    height: 50,
    borderRadius: 8,
    backgroundColor: '#969BAB',
    color: '#fff',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  errorText: {
    color: 'red',
  },
  loadingContent: {
    marginLeft: 12,
  },
});

export default ClientCategoryEdit;
