import * as types from './types';

import request from '../../utils/request';

// Section Pr
export const getApproval = (token, data) => (dispatch) => {
  dispatch({ type: types.APPROVAL_LOADING, payload: true });

  request({
    method: 'post',
    url: 'approval-transaction/myapproval',
    params: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get approval data');

      dispatch({ type: types.APPROVAL_DATA, payload: res?.data });
      dispatch({ type: types.APPROVAL_MESSAGE, payload: res?.message });
      dispatch({ type: types.APPROVAL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get approval data ', err);
      dispatch({ type: types.APPROVAL_LOADING, payload: false });
    });
};

export const getApprovalPRid = (token, id) => (dispatch) => {
  dispatch({ type: types.PR_DETAIL_LOADING, payload: true });

  request({
    method: 'get',
    url: `approval-transaction/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get approval pr id');

      dispatch({ type: types.PR_DETAIL_DATA, payload: res?.data });
      dispatch({ type: types.PR_DETAIL_MESSAGE, payload: res?.message });
      dispatch({ type: types.PR_DETAIL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get approval pr id ', err);
      dispatch({ type: types.PR_DETAIL_LOADING, payload: false });
    });
};

export const updateApprovalPR = (token, data) => (dispatch) => {
  dispatch({ type: types.PR_UPDATE_LOADING, payload: true });

  request({
    method: 'put',
    url: `approval-transaction/update`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('update approval pr');
      dispatch({ type: types.PR_UPDATE_MESSAGE, payload: res?.message });
      dispatch({ type: types.PR_UPDATE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error update approval pr', err);
      dispatch({ type: types.PR_UPDATE_MESSAGE, payload: err?.data?.message });
      dispatch({ type: types.PR_UPDATE_LOADING, payload: false });
    });
};

export const setBlankUpdatePR = () => (dispatch) => {
  dispatch({ type: types.PR_UPDATE_MESSAGE, payload: '' });
};

export const submitApprovalPR = (token, data) => (dispatch) => {
  dispatch({ type: types.PR_SUBMIT_LOADING, payload: true });

  request({
    method: 'post',
    url: `approval-transaction`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('submit approval pr');
      dispatch({ type: types.PR_SUBMIT_MESSAGE, payload: res?.message });
      dispatch({ type: types.PR_SUBMIT_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error submit approval pr', err);
      dispatch({ type: types.PR_SUBMIT_LOADING, payload: false });
    });
};

// Section CA

export const getApprovalCA = (token, data) => (dispatch) => {
  dispatch({ type: types.CA_APPROVAL_LOADING, payload: true });

  request({
    method: 'post',
    url: 'approval-transaction/myapproval',
    params: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {

      dispatch({ type: types.CA_APPROVAL_DATA, payload: res?.data });
      dispatch({ type: types.CA_APPROVAL_MESSAGE, payload: res?.message });
      dispatch({ type: types.CA_APPROVAL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get approval CA data ', err);
      dispatch({ type: types.CA_APPROVAL_LOADING, payload: false });
    });
};

export const getApprovalCAid = (token, id) => (dispatch) => {
  dispatch({ type: types.CA_DETAIL_LOADING, payload: true });

  request({
    method: 'get',
    url: `approval-transaction/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get approval ca id');

      dispatch({ type: types.CA_DETAIL_DATA, payload: res?.data });
      dispatch({ type: types.CA_DETAIL_MESSAGE, payload: res?.message });
      dispatch({ type: types.CA_DETAIL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get approval ca id ', err);
      dispatch({ type: types.CA_DETAIL_LOADING, payload: false });
    });
};

export const updateApprovalCA = (token, data) => (dispatch) => {
  dispatch({ type: types.CA_UPDATE_LOADING, payload: true });

  request({
    method: 'put',
    url: `approval-transaction/update`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {

      dispatch({ type: types.CA_UPDATE_MESSAGE, payload: res?.message });
      dispatch({ type: types.CA_UPDATE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error update approval ca', err);
      dispatch({ type: types.CA_UPDATE_MESSAGE, payload: err?.data?.message });
      dispatch({ type: types.CA_UPDATE_LOADING, payload: false });
    });
};

export const setBlankUpdateCA = () => (dispatch) => {
  dispatch({ type: types.CA_UPDATE_MESSAGE, payload: '' });
};

export const submitApprovalCA = (token, data) => (dispatch) => {
  dispatch({ type: types.CA_SUBMIT_LOADING, payload: true });

  request({
    method: 'post',
    url: `approval-transaction`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('submit approval CA');
      dispatch({ type: types.CA_SUBMIT_MESSAGE, payload: res?.message });
      dispatch({ type: types.CA_SUBMIT_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error submit approval CA', err);
      dispatch({ type: types.CA_SUBMIT_LOADING, payload: false });
    });
};

// Section PO

export const getApprovalPO = (token, data) => (dispatch) => {
  dispatch({ type: types.PO_APPROVAL_LOADING, payload: true });

  request({
    method: 'post',
    url: 'approval-transaction/myapproval',
    params: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get approval PO data');

      dispatch({ type: types.PO_APPROVAL_DATA, payload: res?.data });
      dispatch({ type: types.PO_APPROVAL_MESSAGE, payload: res?.message });
      dispatch({ type: types.PO_APPROVAL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get approval PO data ', err);
      dispatch({ type: types.PO_APPROVAL_LOADING, payload: false });
    });
};

export const getApprovalPOid = (token, id) => (dispatch) => {
  dispatch({ type: types.PO_DETAIL_LOADING, payload: true });

  request({
    method: 'get',
    url: `approval-transaction/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get approval po id');

      dispatch({ type: types.PO_DETAIL_DATA, payload: res?.data });
      dispatch({ type: types.PO_DETAIL_MESSAGE, payload: res?.message });
      dispatch({ type: types.PO_DETAIL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get approval po id ', err);
      dispatch({ type: types.PO_DETAIL_LOADING, payload: false });
    });
};

export const updateApprovalPO = (token, data) => (dispatch) => {
  console.log('request')
  dispatch({ type: types.PO_UPDATE_LOADING, payload: true });
  request({
    method: 'put',
    url: `approval-transaction/update`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: types.PO_UPDATE_MESSAGE, payload: res?.message });
      dispatch({ type: types.PO_UPDATE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error update approval po', err);
      
      dispatch({ type: types.PO_UPDATE_MESSAGE, payload: err?.data?.message });
      dispatch({ type: types.PO_UPDATE_LOADING, payload: false });
    });
};

export const setBlankUpdatePO = () => (dispatch) => {
  dispatch({ type: types.PO_UPDATE_MESSAGE, payload: '' });
};

export const submitApprovalPO = (token, data) => (dispatch) => {
  dispatch({ type: types.PO_SUBMIT_LOADING, payload: true });

  request({
    method: 'post',
    url: `approval-transaction`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('submit approval po');
      dispatch({ type: types.PO_SUBMIT_MESSAGE, payload: res?.message });
      dispatch({ type: types.PO_SUBMIT_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error submit approval po', err);
      dispatch({ type: types.PO_SUBMIT_LOADING, payload: false });
    });
};

// Section GRPO
export const getApprovalGRPO = (token, params) => (dispatch) => {
  dispatch({ type: types.GRPO_APPROVAL_LOADING, payload: true });

  request({
    method: 'post',
    url: 'approval-transaction/myapproval',
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get approval GRPO data');

      dispatch({ type: types.GRPO_APPROVAL_DATA, payload: res?.data });
      dispatch({ type: types.GRPO_APPROVAL_MESSAGE, payload: res?.message });
      dispatch({ type: types.GRPO_APPROVAL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get approval GRPO data ', err);
      dispatch({ type: types.GRPO_APPROVAL_LOADING, payload: false });
    });
};

export const getApprovalGRPOid = (token, id) => (dispatch) => {
  dispatch({ type: types.GRPO_DETAIL_LOADING, payload: true });

  request({
    method: 'get',
    url: `approval-transaction/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get approval grpo id');

      dispatch({ type: types.GRPO_DETAIL_DATA, payload: res?.data });
      dispatch({ type: types.GRPO_DETAIL_MESSAGE, payload: res?.message });
      dispatch({ type: types.GRPO_DETAIL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get approval grpo id ', err);
      dispatch({ type: types.GRPO_DETAIL_LOADING, payload: false });
    });
};

export const updateApprovalGRPO = (token, data) => (dispatch) => {
  dispatch({ type: types.GRPO_UPDATE_LOADING, payload: true });

  request({
    method: 'put',
    url: `approval-transaction/update`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('update approval grpo');
      dispatch({ type: types.GRPO_UPDATE_MESSAGE, payload: res?.message });
      dispatch({ type: types.GRPO_UPDATE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error update approval grpo', err);
      dispatch({ type: types.GRPO_UPDATE_LOADING, payload: false });
      dispatch({
        type: types.GRPO_UPDATE_MESSAGE,
        payload: err?.data?.message,
      });
    });
};

export const setBlankUpdateGRPO = () => (dispatch) => {
  dispatch({ type: types.GRPO_UPDATE_MESSAGE, payload: '' });
};

export const submitApprovalGRPO = (token, data) => (dispatch) => {
  dispatch({ type: types.GRPO_SUBMIT_LOADING, payload: true });

  request({
    method: 'post',
    url: `approval-transaction`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('submit approval grpo');
      dispatch({ type: types.GRPO_SUBMIT_MESSAGE, payload: res?.message });
      dispatch({ type: types.GRPO_SUBMIT_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error submit approval grpo', err);
      dispatch({ type: types.GRPO_SUBMIT_LOADING, payload: false });
    });
};
// Section General
export const getApprovalGeneral = (token, params) => (dispatch) => {
  dispatch({ type: types.GENERAL_APPROVAL_LOADING, payload: true });

  request({
    method: 'post',
    url: 'approval-transaction/myapproval',
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get approval general data');

      dispatch({ type: types.GENERAL_APPROVAL_DATA, payload: res?.data });
      dispatch({ type: types.GENERAL_APPROVAL_MESSAGE, payload: res?.message });
      dispatch({ type: types.GENERAL_APPROVAL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get approval general data ', err);
      dispatch({ type: types.GENERAL_APPROVAL_LOADING, payload: false });
    });
};

export const getApprovalGeneralId = (token, id) => (dispatch) => {
  dispatch({ type: types.GENERAL_DETAIL_LOADING, payload: true });

  request({
    method: 'get',
    url: `approval-transaction/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get approval general id');

      dispatch({ type: types.GENERAL_DETAIL_DATA, payload: res?.data });
      dispatch({ type: types.GENERAL_DETAIL_MESSAGE, payload: res?.message });
      dispatch({ type: types.GENERAL_DETAIL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get approval general id ', err);
      dispatch({ type: types.GENERAL_DETAIL_LOADING, payload: false });
    });
};

export const updateApprovalGENERAL = (token, data) => (dispatch) => {
  dispatch({ type: types.GENERAL_UPDATE_LOADING, payload: true });

  request({
    method: 'put',
    url: `approval-transaction/update`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('update approval general');
      dispatch({ type: types.GENERAL_UPDATE_MESSAGE, payload: res?.message });
      dispatch({ type: types.GENERAL_UPDATE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error update approval general', err);
      dispatch({ type: types.GENERAL_UPDATE_LOADING, payload: false });
      dispatch({
        type: types.GENERAL_UPDATE_MESSAGE,
        payload: err?.data?.message,
      });
    });
};

export const setBlankUpdateGeneral = () => (dispatch) => {
  dispatch({ type: types.GENERAL_UPDATE_MESSAGE, payload: '' });
};

export const submitApprovalGeneral = (token, data) => (dispatch) => {
  dispatch({ type: types.GENERAL_SUBMIT_LOADING, payload: true });

  request({
    method: 'post',
    url: `approval-transaction`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('submit approval general');
      dispatch({ type: types.GENERAL_SUBMIT_MESSAGE, payload: res?.message });
      dispatch({ type: types.GENERAL_SUBMIT_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error submit approval general', err);
      dispatch({ type: types.GENERAL_SUBMIT_LOADING, payload: false });
    });
};
