import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  titleAndSearchDiv: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
  },
  searchNoDiv: {
    display: 'flex',
    gap: '8px',
    height: '40px',
  },
  searchNoButton: {
    height: '100%',
    width: '74px',
    color: 'white',
    borderRadius: '8px',
    backgroundColor: '#127EEE',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#32b4ff',
    },
    '&:active': {
      backgroundColor: '#1464ff',
    },
  },
  searchNoInput: {
    height: '100%',
    padding: '0px 8px',
    borderRadius: '8px',
    border: '1px solid #E7E7E8',
    '&:invalid': {
      backgroundColor: 'rgba(255,0,0,0.1)',
      border: '1px solid red',
    },
  },
});

const TitleWithSearchNo = ({ Title, docNumParam, setDocNumParam, sendApiQuery }) => {
  const classes = useStyles();

  const handleChange = (e) => {
    const value = e.target.value.trim();

    if (!/^[0-9]+$|^$/.test(value)) {
      e.preventDefault();
      return;
    }

    setDocNumParam(value);
  }

  const handleCariClick = (e) => {
    e.preventDefault();
    sendApiQuery();
  }

  return (
    <div className={classes.titleAndSearchDiv}>
      {Title}

      <form className={classes.searchNoDiv} onSubmit={handleCariClick}>
        <input pattern="^[0-9]+$|^$" value={docNumParam} onChange={handleChange} className={classes.searchNoInput} type="text" placeholder="Masukan no data ..."/>
        <input className={classes.searchNoButton} value="Cari" type="submit"/>
      </form>
    </div>
  );
}

export default TitleWithSearchNo;
