import React from 'react';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// Svg
import { DownArrow } from '../../assets/Icons/svg/DownArrow';

const DesktopPagination = ({
  fromRow,
  toRow,
  TotalRow,
  page,
  handleChangePagination,
  handleChangeLimit,
  limit,
  pagination,
}) => {
  const classes = useStyles();
  return (
    <>
      <Grid
        xs={12}
        md={12}
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: 15 }}
      >
        <Grid xs={12} md={6}>
          <div className={classes.descTable}>
            <Typography
              className={classes.descTableText}
              variant="body1"
              component="p"
            >
              {`Showing ${fromRow} to ${toRow} of ${TotalRow} entries`}
            </Typography>
          </div>
        </Grid>
        <Grid md={6} className={classes.wrapperPageAction}>
          <div className={classes.wrapperSelecRow}>
            <Typography className={classes.descTableText}>
              Row per page
            </Typography>
            <FormControl className={classes.formControl}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={limit}
                onChange={handleChangeLimit}
                IconComponent={() => <DownArrow />}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={classes.paginationContent}>
            <Pagination
              className={classes.pagination}
              defaultPage={pagination}
              count={page}
              color={'standard'}
              shape="rounded"
              onChange={handleChangePagination}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  // Pagination styling
  descTable: {
    paddingTop: 11.5,
    paddingBottom: 11.5,
  },
  descTableText: {
    color: '#969BAB',
    fontSize: 16,
    lineHeight: '21px',
  },
  paginationContent: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pagination: {
    borderRadius: 0,
    marginLeft: 20,
    '&.MuiPagination-root .MuiPagination-ul .MuiPaginationItem-root': {
      margin: 0,
    },
    borderRadius: 0,
    '&.MuiPagination-root .MuiPagination-ul .MuiPaginationItem-rounded': {
      borderRadius: 0,
    },
    '&.MuiPagination-root .MuiPagination-ul .MuiButtonBase-root': {
      backgroundColor: '#fff',
      color: '#969BAB',
    },
    '&.MuiPagination-root .MuiPagination-ul .MuiButtonBase-root.Mui-selected': {
      backgroundColor: '#1B2030',
      color: '#fff',
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-child(1)': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-child(1) button': {
      color: '#1B2030',
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-last-child(1)': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-last-child(1) button': {
      color: '#1B2030',
    },
  },
  // styling select
  wrapperPageAction: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  wrapperSelecRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  // styling Form select
  formControl: {
    // backgroundColor: '#F8FAFB',
    // border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingTop: 5,
    paddingBottom: 5,
    marginLeft: 10,
    width: 60,
    height: 40,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      marginTop: 3,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#969BAB',
      paddingRight: 2,
      background: 'none',
    },
  },
}));

export default DesktopPagination;
