export const defaultPage = [
  {
    name: 'Dashboard',
    url: '/',
  },
  {
    name: 'My Approval',
    url: '/approval',
  },
  {
    name: 'Purchase Requisition',
    url: '/purchase-requistor',
    api_key: 'pr',
  },
  {
    name: 'Purchase Order',
    url: '/purchase-order',
    api_key: 'po'
  },
  {
    name: 'Proforma Invoice',
    url: '/proforma-invoice',
    api_key: 'proforma_invoice'
  },
  {
    name: 'Good Receipt (GRPO)',
    url: '/grpo',
    api_key: 'grpo'
  },
  {
    name: 'A/P Invoice',
    url: '/ap-invoice',
    api_key: 'ap_invoice'
  },
  {
    name: 'A/P DP',
    url: '/ap-downpayment',
    api_key: 'ap_dp'
  },
  {
    name: 'Outgoing Payment',
    url: '/outgoing-payment',
    api_key: 'op'
  },
  {
    name: 'Cash Advance Report',
    url: '/cash-report',
    api_key: 'ca'
  },
  // {
  //   name: 'Report',
  //   url: '/report',
  // },
  // 'Users',
  {
    name: 'Client/Vendors',
    url: '/client-vendor',
  },
];

export const superAdmin = [
  {
    name: 'Dashboard',
    url: '/',
  },
  {
    name: 'My Approval',
    url: '/approval',
  },
  {
    name: 'Purchase Requisition',
    url: '/purchase-requistor',
    api_key: 'pr'
  },
  {
    name: 'Purchase Order',
    url: '/purchase-order',
    api_key: 'po'
  },
  {
    name: 'Proforma Invoice',
    url: '/proforma-invoice',
    api_key: 'proforma_invoice'
  },
  {
    name: 'Good Receipt (GRPO)',
    url: '/grpo',
    api_key: 'grpo'
  },
  {
    name: 'A/P Invoice',
    url: '/ap-invoice',
    api_key: 'ap_invoice'
  },
  {
    name: 'A/P DP',
    url: '/ap-downpayment',
    api_key: 'ap_dp'
  },
  {
    name: 'Outgoing Payment',
    url: '/outgoing-payment',
    api_key: 'op'
  },
  {
    name: 'Cash Advance Report',
    url: '/cash-report',
    api_key: 'ca'
  },
  {
    name: 'Report',
    url: '/report',
  },
  {
    name: 'Users',
    url: '/user-management',
  },
  {
    name: 'Client/Vendors',
    url: '/client-vendor',
  },
];

export const finance = [
  {
    name: 'Dashboard',
    url: '/',
  },
  {
    name: 'My Approval',
    url: '/approval',
  },
  {
    name: 'Purchase Requisition',
    url: '/purchase-requistor',
    api_key: 'pr'
  },
  {
    name: 'Purchase Order',
    url: '/purchase-order',
    api_key: 'po'
  },
  {
    name: 'Proforma Invoice',
    url: '/proforma-invoice',
    api_key: 'proforma_invoice'
  },
  {
    name: 'Good Receipt (GRPO)',
    url: '/grpo',
    api_key: 'grpo'
  },
  {
    name: 'A/P Invoice',
    url: '/ap-invoice',
    api_key: 'ap_invoice'
  },
  {
    name: 'A/P DP',
    url: '/ap-downpayment',
    api_key: 'ap_dp'
  },
  {
    name: 'Outgoing Payment',
    url: '/outgoing-payment',
    api_key: 'op'
  },
  {
    name: 'Cash Advance Report',
    url: '/cash-report',
    api_key: 'ca'
  },
  // {
  //   name: 'Report',
  //   url: '/report',
  // },
  {
    name: 'Client/Vendors',
    url: '/client-vendor',
  },
];
