import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: false,
      text: 'Chart.js Bar Chart - Stacked',
    },
    legend: {
      position: 'bottom',
      labels: {
        padding: 30,
        pointStyle: 'rectRounded',
        usePointStyle: true,
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const labels = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'Desember',
];

const BarChart = ({ barOnTrack, barOffTrack }) => {
  const data = {
    labels,
    datasets: [
      {
        label: 'Off Track',
        // data: [20, 40, 90, 10, 30, 20, 40, 90, 10, 30, 100, 10],
        data: barOffTrack,
        backgroundColor: '#FD453A',
      },
      {
        label: 'On Track',
        // data: [10, 10, 20, 5, 20, 10, 10, 20, 5, 20, 10, 0],
        data: barOnTrack,
        backgroundColor: '#27AE60',
      },
    ],
  };

  return (
    <>
      <Bar height={350} options={options} data={data} />
    </>
  );
};

export default BarChart;
