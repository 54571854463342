import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core';

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

// Toolip configuration
export default function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return props.arrow ? (
    <Tooltip classes={classes} {...props} />
  ) : (
    <Tooltip arrow classes={classes} {...props} />
  );
}
