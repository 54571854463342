import React from 'react';
import { makeStyles, Grid, Box, Typography } from '@material-ui/core';

// icons
import FinishRed from '../../assets/Icons/finishRed.png';
import FinishBlue from '../../assets/Icons/finishBlue.png';
import FinishBlack from '../../assets/Icons/finishBlack.png';
import FinishGreen from '../../assets/Icons/finishGreen.png';

const DashboardTableStatus = ({
  title,
  index,
  _renderNeedTotal,
  _renderTotalAmount,
  _renderTotalAmountDOLAR,
  _renderTotalDOLAR,
  Skeleton,
  isLoading,
}) => {
  const classes = useStyles();

  const _renderTitle = (title) => {
    if (title === 'Need') {
      return 'Need Approval';
    } else if (title === 'Open') {
      return 'Open';
    } else if (title === 'Closed') {
      return 'Closed';
    } else {
      return 'Approve';
    }
  };

  const _renderIcon = (title) => {
    if (title === 'Need') {
      return FinishRed;
    } else if (title === 'Open') {
      return FinishBlue;
    } else if (title === 'Closed') {
      return FinishBlack;
    } else {
      return FinishGreen;
    }
  };

  const _renderColor = (title) => {
    if (title === 'Need') {
      return classes.menuNumber;
    } else if (title === 'Open') {
      return classes.menuNumberOpen;
    } else if (title === 'Closed') {
      return classes.menuNumberClosed;
    } else {
      return classes.menuNumberApproved;
    }
  };

  return (
    <>
      <Grid
        xs={12}
        md={12}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid md={8}>
          <Typography
            className={classes.menuTitle}
            variant="body1"
            component="p"
          >
            {_renderTitle(title)}
          </Typography>
        </Grid>
        <Grid md={4}>
          <Box className={classes.wrapperIcon}>
            <img
              // className={classes.bellImage}
              src={_renderIcon(title)}
              alt="image not found"
            />
          </Box>
        </Grid>
      </Grid>
      {isLoading ? (
        <Box style={{ paddingLeft: 18, paddingRight: 18 }}>
          <Skeleton width="100%" />
        </Box>
      ) : (
        <Grid
          xs={12}
          md={12}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid md={4}>
            <Typography
              className={_renderColor(title)}
              variant="body1"
              component="p"
            >
              {_renderNeedTotal(index, title)}
            </Typography>
          </Grid>
          <Grid md={8}>
            <Typography
              className={classes.menuCurrency}
              variant="body1"
              component="p"
            >
              IDR {_renderTotalAmount(index, title)}
            </Typography>
          </Grid>
        </Grid>
      )}
      {index === 2 || index === 3 || index === 4 ? (
        <>
          <Box className={classes.sizeTop} />
          {isLoading ? (
            <Box style={{ paddingLeft: 18, paddingRight: 18 }}>
              <Skeleton width="100%" />
            </Box>
          ) : (
            <Grid
              xs={12}
              md={12}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid md={4}>
                <Typography
                  className={_renderColor(title)}
                  variant="body1"
                  component="p"
                >
                  {_renderTotalDOLAR(index, title)}
                </Typography>
              </Grid>
              <Grid md={8}>
                <Typography
                  className={classes.menuCurrency}
                  variant="body1"
                  component="p"
                >
                  ${_renderTotalAmountDOLAR(index, title)}
                </Typography>
              </Grid>
            </Grid>
          )}
        </>
      ) : null}
    </>
  );
};

const useStyles = makeStyles({
  wrapperIcon: {
    minHeight: 80,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  menuTitle: {
    paddingLeft: 18,
    fontSize: 18,
    lineHeight: '17px',
    color: '#1B2030',
    letterSpacing: '0.01em',
  },
  menuNumber: {
    paddingLeft: 18,
    color: '#FD453A',
    fontSize: 36,
    lineHeight: '49px',
    fontWeight: 'bold',
  },
  menuNumberOpen: {
    paddingLeft: 18,
    color: '#127EEE',
    fontSize: 36,
    lineHeight: '49px',
    fontWeight: 'bold',
  },
  menuNumberClosed: {
    paddingLeft: 18,
    color: '#969BAB',
    fontSize: 36,
    lineHeight: '49px',
    fontWeight: 'bold',
  },
  menuNumberApproved: {
    paddingLeft: 18,
    color: '#219653',
    fontSize: 36,
    lineHeight: '49px',
    fontWeight: 'bold',
  },
  menuCurrency: {
    textAlign: 'right',
    paddingRight: 18,
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
  },
  sizeTop: {
    marginTop: 16,
  },
});

export default DashboardTableStatus;
