import React from 'react';
import { makeStyles, Grid, Box, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useRouter } from '../../../../utils/useRouter';
import { useSelector, useDispatch } from 'react-redux';
import * as settingAction from '../../../../redux/Settings/actions';

// Components
import ClientCategoryData from './TableData/ClientCategoryData';
import SortArrow from '../../../SortArrow';
import CustomizedSnackbars from '../../../PopupMessage';
import { TabelLoading, TableNoData } from '../../../Loading/TableLoading';
import ModalDeleteItem from '../ModalDeleteItem';
import ModalSuccess from '../../../ModalSuccess';

const ClientCategoryListing = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispacth = useDispatch();

  // Global Variable
  const { access_token } = useSelector((state) => state.auth);
  const {
    clientCategory_IsLoading,
    clientCategory_data,
    clientCategory_delIsLoading,
    clientCategory_delMessage,
  } = useSelector((state) => state.settings);

  // for initial state
  const [rowData, setRowData] = React.useState([]);
  const [tempDeleteId, setTempDeleteId] = React.useState(0);

  // state for modal
  const [showModalDelete, setShowModalDelete] = React.useState(false);
  const [showModalSuccess, setShowModalSuccess] = React.useState(false);

  // handle message
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };
  //  end handle Message

  // function register

  const handleDeleteItem = (id) => {
    setTempDeleteId(id);
    setShowModalDelete(true);
  };

  const handleActionSuccess = () => {
    setShowModalSuccess(false);
    setShowModalDelete(false);
    dispacth(settingAction.getClientCategory(access_token));
  };

  const handleEditItem = (id) => {
    router.push(`/master-data/client-category/${id}/edit`);
  };

  React.useEffect(() => {
    dispacth(settingAction.getClientCategory(access_token));
  }, []);

  React.useEffect(() => {
    let tempArr = [];

    clientCategory_data?.CategoryClient?.map((item, index) => {
      let tempData = {
        id: item.id,
        name: item.name,
        // description: check.description,
      };

      tempArr.push(tempData);
    });

    setRowData(tempArr);
  }, [clientCategory_data]);

  React.useEffect(() => {
    if (clientCategory_delMessage) {
      if (clientCategory_delMessage === 'Delete Category Client Success') {
        setShowModalSuccess(true);
      } else if (
        clientCategory_delMessage !== '' &&
        clientCategory_delMessage !== 'Delete Category Client Success'
      ) {
        setShow(true);
        setType('error');
        setNotifText(clientCategory_delMessage);
      }
      dispacth(settingAction.setBlankDeleteClientCategory());
    }
  }, [clientCategory_delMessage]);

  return (
    <>
      <CustomizedSnackbars
        show={show}
        handleClose={handleClose}
        message={notifText}
        type={type}
      />
      <ModalDeleteItem
        open={showModalDelete}
        close={() => setShowModalDelete(false)}
        onPress={() =>
          dispacth(
            settingAction.deleteClientCategory(access_token, tempDeleteId)
          )
        }
        isLoading={clientCategory_delIsLoading}
      />
      <ModalSuccess open={showModalSuccess} onPress={handleActionSuccess} />

      <div style={{ width: '100%' }}>
        <Grid md={12} classes={classes.wrapperTable}>
          <TableContainer className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Box className={classes.wrapperTableHeader}>
                      <Typography className={classes.headeText}>ID</Typography>
                      <SortArrow />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className={classes.wrapperTableHeader}>
                      <Typography className={classes.headeText}>
                        Category Name
                      </Typography>
                      <SortArrow />
                    </Box>
                  </TableCell>
                  {/* <TableCell>
                    <Box className={classes.wrapperTableHeader}>
                      <Typography className={classes.headeText}>
                        Description
                      </Typography>
                      <SortArrow />
                    </Box>
                  </TableCell> */}
                  <TableCell>
                    <Box className={classes.wrapperTableHeader}>
                      <Typography className={classes.headeText}>
                        Action
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clientCategory_IsLoading ? null : rowData.length > 0 ? (
                  rowData.map((row, index) => (
                    <ClientCategoryData
                      row={row}
                      index={index}
                      key={index}
                      handleEditItem={handleEditItem}
                      handleDeleteItem={handleDeleteItem}
                    />
                  ))
                ) : (
                  <TableNoData colspan={3} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {clientCategory_IsLoading ? <TabelLoading /> : null}
        </Grid>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapperTableHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headeText: {
    fontSize: 16,
    lineHeight: '30px',
    fontWeight: 'bold',
    color: '#000000',
  },
  tableContainer: {
    '& .MuiTableRow-root.MuiTableRow-head': {
      backgroundColor: '#fff',
    },
    '& .MuiTableRow-head .MuiTableCell-head': {
      borderBottom: 'none',
    },
    '& .MuiTableRow-head .MuiTableCell-head:nth-child(1)': {
      width: 91,
    },
    // '& .MuiTableRow-head .MuiTableCell-head:nth-child(2)': {
    //   width: 200,
    // },
    '& .MuiTableRow-head .MuiTableCell-head:nth-child(3)': {
      width: 100,
      paddingLeft: 20,
      paddinRigth: 20,
    },
    '& .MuiTableRow-head .MuiTableCell-head:nth-child(4)': {
      width: 100,
      paddingLeft: 20,
      paddinRigth: 20,
    },
  },
}));

const clientCategoryFake = [
  {
    id: 1,
    name: 'Distributor',
    desc: 'lorem ipsum',
  },
  {
    id: 2,
    name: 'Supplier',
    desc: 'lorem ipsum',
  },
];

export default ClientCategoryListing;
