import React from 'react';
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  TextareaAutosize,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { dateFormater, formatFileCount } from '../../utils/formatter';
import { useRouter } from '../../utils/useRouter';
import { useSelector, useDispatch } from 'react-redux';
import { formatRupiah } from '../../utils/formatter';
import * as client from '../../redux/ClientVendor/actions';
import * as proforma from '../../redux/Proforma/actions';
import * as po from '../../redux/PO/actions';
import CloseIcon from '@material-ui/icons/Close';

// Components
import DatePickerSingle from '../DatePickerSingle';
import CustomizedSnackbars from '../PopupMessage';
import ModalConfirm from './components/ModalComfirm';
import ModalSuccess from '../ModalSuccess';
import { CircleLoadingSecondary } from '../Loading/CircleLoading';

// Icons
import TandaSeru from '../../assets/Icons/tandaSeru.png';
import Asnyc from '../../assets/Icons/async.png';
import DateIcon from '../../assets/Icons/date.png';
import UnduhSecond from '../../assets/Icons/unduhSecond.png';
import Export from '../../assets/Icons/export.png';
import Search from '../../assets/Icons/Search.png';
import Trash from '../../assets/Icons/trash.png';
import Paper from '../../assets/Icons/paper.png';
import Plus from '../../assets/Icons/plus.png';

// Svg
import { DownArrow } from '../../assets/Icons/svg/DownArrow';
import { SortArrow } from '../../assets/Icons/svg/SortArrow';

const CreateProforma = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispacth = useDispatch();

  // Global Variable
  const { access_token } = useSelector((state) => state.auth);

  const { searchData, searchIsLoading, data } = useSelector(
    (state) => state.clientVendor
  );
  const { add_message, add_Isloading, add_client_message, add_po_message } =
    useSelector((state) => state.proforma);

  const { data_po, search_poIsLoading, search_poData } = useSelector(
    (state) => state.po
  );

  const [invoiceNumber, setInvoiceNumber] = React.useState('');
  const [billingNumber, setBillingNumber] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [statusFilter, setStatusFilter] = React.useState('open');
  const [tempImgFaktur, setTempImgFaktur] = React.useState(null);
  const [tempImgBAST, setTempImgBAST] = React.useState(null);
  const [tempSendData, setTempSendData] = React.useState(null);
  const [tempFileCheck, setTempFileCheck] = React.useState(null);

  const [date, setDate] = React.useState({
    start: null,
    end: null,
  });

  const handleChangeFile = (e) => {
    let values = [...tempFileCheck];

    values[e.target.name]?.file?.push(e.target.files);

    setTempFileCheck(values);
  };

  const handleAddFile = (e) => {
    let values = [...tempFileCheck];

    let tempId = e.target.name.split(',');

    values[tempId[0]]?.file?.push(e.target.files);

    setTempFileCheck(values);
  };

  const handleDeleteFile = (labelIndex, indexFile) => {
    let values = [...tempFileCheck];
    values[labelIndex]?.file?.splice(indexFile, 1);
    // console.log('label', labelIndex);
    // console.log('indexFile', indexFile);
    setTempFileCheck(values);
  };

  const handleChangeInvoiceNumber = (event) => {
    setInvoiceNumber(event.target.velue);
  };

  const handleChangeBilling = (event) => {
    setBillingNumber(event.target.velue);
  };

  const handleChangeStatusFilter = (event) => {
    setStatusFilter(event.target.value);
  };

  // handle Error input
  const [errrorInvoiceNo, setErrorInvoiceNo] = React.useState('');
  const [errrorInvoiceDate, setErrorInvoiceDate] = React.useState('');
  const [errrorBillingNo, setErrorBillingNo] = React.useState('');
  const [errrorDescription, setErrorDescription] = React.useState('');

  const [isValid, setIsValid] = React.useState(false);

  React.useEffect(() => {
    if (invoiceNumber) {
      setErrorInvoiceNo('');
    }
  }, [invoiceNumber]);
  React.useEffect(() => {
    if (date) {
      setErrorInvoiceDate('');
    }
  }, [date]);
  React.useEffect(() => {
    if (billingNumber) {
      setErrorBillingNo('');
    }
  }, [billingNumber]);
  React.useEffect(() => {
    if (description) {
      setErrorDescription('');
    }
  }, [description]);

  React.useEffect(() => {
    if (
      invoiceNumber &&
      date.start &&
      billingNumber
      // description &&
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [invoiceNumber, date.start, billingNumber, description]);

  // end handle error input

  const handleProcessGRPO = () => {
    if (!invoiceNumber) {
      setErrorInvoiceNo('Field required');
    }
    if (!billingNumber) {
      setErrorBillingNo('Field Required');
    }
    if (!date || !date.start) {
      setErrorInvoiceDate('Field Required');
    }
    // if (!description) {
    //   setErrorDescription('Field Required');
    // }
    if (!vendor) {
      setType('error');
      setShow(true);
      setNotifText('please select vendor');
    }
    if (!PO) {
      setType('error');
      setShow(true);
      setNotifText('please select PO No');
    }
    if (isValid) {
      let tempNumber = billingNumber.replace('.', '');

      let sendData = {
        po_id: PO.id,
        client_id: vendor.id,
        amount: tempNumber,
        invoice_num: invoiceNumber,
        invoice_date: dateFormater(date.start, 'YYYY-MM-DD'),
        description: description,
        // status: statusFilter,
        // file: fileArr,
      };

      if (!description) delete sendData.description;

      let validArr = [];

      tempFileCheck?.map((item, index) => {
        if (item?.file?.length < 1) {
          validArr.push(false);
        }
        let tempName = item?.identity;
        sendData[`${tempName}[]`] = item.file;
        // sendData.append(`${tempName}[]`, item.file);
      });

      if (validArr.length < 1) {
        console.log(sendData);
        setTempSendData(sendData);
        setShowModalConfirm(true);
      } else {
        handleMessage('error', true, 'Please check your upload file');
      }
      // console.log('valid', validArr);

      // let sendData = new FormData();
      // sendData.append('po_id', PO.id);
      // sendData.append('client_id', vendor.id);
      // sendData.append('amount', billingNumber);
      // sendData.append('invoice_num', invoiceNumber);
      // sendData.append('invoice_date', dateFormater(date.start, 'YYYY-MM-DD'));
      // sendData.append('description', description);
      // fileArr.map((img, index) => {
      //   sendData.append('file[]', img);
      // });

      // proforma.addProforma(access_token, sendData);
      // console.log(sendData);
      // setTempSendData(sendData);
      // setShowModalConfirm(true);
    }
  };

  // for handle search input PO

  const [isPOFocus, setIsPOFocus] = React.useState(false);
  const [showPO, setShowPO] = React.useState(false);

  const handleChoosePO = (data, index) => {
    selectPO(data, index);
    setIsPOFocus(false);
    setShowPO(false);
  };

  const [PO, setPO] = React.useState(null);
  const [POData, setPOData] = React.useState(null);
  const [searchTextPO, setSearchTextPO] = React.useState('');

  const selectPO = (data, index) => {
    setPO(data);
    setSearchTextPO('');
  };

  React.useEffect(() => {
    if (searchTextPO !== '') {
      let data = { doc_num: searchTextPO, page: 1, is_draft: 0 };
      dispacth(po.searchPOdata(access_token, data));
    } else {
      let params = {
        page: 1,
        is_draft: 0,
      };
      dispacth(po.getPOdata(access_token, params));
    }
  }, [searchTextPO]);

  React.useEffect(() => {
    let params = {
      page: 1,
      is_draft: 0,
    };
    dispacth(po.getPOdata(access_token, params));
  }, []);

  React.useEffect(() => {
    // setPOData(data_po?.data);
  }, [data_po]);

  React.useEffect(() => {
    setPOData(search_poData?.data);
  }, [search_poData]);

  // end handle search PO

  // for handle search input vendor

  const [deleteHover, setDeleteHover] = React.useState(false);
  const [isVendorFocus, setIsVendorFocus] = React.useState(false);
  const [showVendor, setShowVendor] = React.useState(false);

  const handleChooseVandor = (data, index) => {
    selectVendor(data, index);
    setIsVendorFocus(false);
    setShowVendor(false);
  };

  const [vendor, setVendor] = React.useState(null);
  const [vendorData, setVendorData] = React.useState(null);
  const [searchText, setSearchText] = React.useState('');

  const selectVendor = (data, index) => {
    setVendor(data);
    // setIsVendorFocus(false);
    // setShowVendor(false);
    setSearchText('');
    setVendorData(data?.client?.data);
  };

  React.useEffect(() => {
    if (searchText !== '') {
      let data = { name: searchText };
      dispacth(client.searchClientVendor(access_token, data));
    } else {
      setVendorData(data?.client?.data);
      dispacth(client.setBlankSearchData());
    }
  }, [searchText]);

  React.useEffect(() => {
    dispacth(client.getClientVendor(access_token));
  }, []);

  React.useEffect(() => {
    setVendorData(data?.client?.data);
  }, [data]);

  React.useEffect(() => {
    setVendorData(searchData?.client);
  }, [searchData]);

  // end handle serach vendor

  // handle message

  const handleMessage = (type, show, message) => {
    setType(`${type}`);
    setShow(show);
    setNotifText(message);
  };
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };
  // end handle message

  // configure modal confirm

  const [showModalConfirm, setShowModalConfirm] = React.useState(false);
  const [showModalSuccess, setModalSuccess] = React.useState(false);
  // end configure modal confirm

  React.useEffect(() => {
    if (PO) {
      let tempArr = [];

      PO?.checklists?.map((item, index) => {
        let tempData = {
          id: index,
          name: item?.name,
          desc: item?.description,
          identity: item?.identity,
          note: '',
          file: [],
        };
        tempArr.push(tempData);
      });

      setTempFileCheck(tempArr);
    }
  }, [PO]);

  React.useEffect(() => {
    if (add_message) {
      if (add_message === 'Create proforma invoice successfully') {
        setModalSuccess(true);
      } else if (
        add_message !== '' &&
        add_message !== 'Create proforma invoice successfully'
      ) {
        setType('error');
        setShow(true);
        setNotifText(
          `${add_message} : ${add_client_message ?? ''} , ${
            add_po_message ?? ''
          }`
        );
      }

      dispacth(proforma.setBlankAddSecond());
      // dispacth(proforma.setBlankAddPO());
      // dispacth(proforma.setBlankAddClient());
    }
  }, [add_message]);

  return (
    <>
      <CustomizedSnackbars
        show={show}
        handleClose={handleClose}
        message={notifText}
        type={type}
      />
      <ModalConfirm
        open={showModalConfirm}
        handleCloseModalConfirm={() => setShowModalConfirm(false)}
        isLoading={add_Isloading}
        onPress={() =>
          dispacth(proforma.addProforma(access_token, tempSendData))
        }
      />
      <ModalSuccess
        open={showModalSuccess}
        onPress={() => router.push('/proforma-invoice')}
      />
      <Grid md={12} className={classes.wrapperContainer}>
        <Grid md={12} className={classes.wrapperHeader}>
          <Typography className={classes.headerTitle}>
            Create Proforma Invoice
          </Typography>
        </Grid>

        <Grid md={12} className={classes.wrapperBody}>
          <Typography className={classes.bodyTitleText}>
            Proforma Invoice Information
          </Typography>
          <Grid md={12} className={classes.wrapperInput} container>
            <Grid md={4} className={classes.wrapperInputSearch}>
              <Box className={classes.bodyContent}>
                <Typography
                  className={classes.bodyTitle}
                  variant="body1"
                  component="p"
                >
                  PO Number
                </Typography>
              </Box>
              <Box
                className={classes.filterVendorContent}
                // onBlur={() => setIsVendorFocus(false)}
              >
                <Box className={classes.wrapperInputPO}>
                  <input
                    className={classes.inputVendor}
                    value={PO?.doc_num ?? 'Select'}
                    autoComplete="on"
                    onFocus={() => setIsPOFocus(true)}
                    placeholder="Select"
                    onBlur={() => setIsPOFocus(false)}
                  />
                  <Box
                    className={
                      PO?.doc_num ? classes.iconClose : classes.iconDownArrow
                    }
                  >
                    {PO?.doc_num ? (
                      <Box
                        className={classes.pointer}
                        onClick={() => setPO(null)}
                      >
                        <CloseIcon />
                      </Box>
                    ) : (
                      <DownArrow />
                    )}
                  </Box>
                </Box>
                {/* render for select vendor name */}
                {(isPOFocus || showPO) && !PO ? (
                  <Box
                    onBlur={() => setIsPOFocus(true)}
                    className={classes.contentDataVendor}
                    onMouseOver={() => setShowPO(true)}
                    onMouseOut={() => setShowPO(false)}
                  >
                    <Box className={classes.contentVendorSearch}>
                      <input
                        className={classes.searchVendor}
                        autoComplete="on"
                        onFocus={() => setIsPOFocus(true)}
                        value={searchTextPO}
                        onChange={(e) => setSearchTextPO(e.target.value)}
                        placeholder="Search"
                        // onBlur={() => setIsVendorFocus(false)}
                      />
                      {search_poIsLoading ? (
                        <CircleLoadingSecondary />
                      ) : (
                        <img
                          className={classes.serachIcon}
                          src={Search}
                          alt="image not found"
                        />
                      )}
                    </Box>
                    <Box className={classes.listVendorNamePO}>
                      {search_poIsLoading ? (
                        <Typography className={classes.TitleVendor}>
                          Loading Get Data
                        </Typography>
                      ) : POData?.length < 1 ? (
                        <Typography className={classes.TitleVendor}>
                          PO Not Found
                        </Typography>
                      ) : (
                        POData?.map((data, poIndex) => (
                          <Box
                            key={poIndex}
                            onClick={() => handleChoosePO(data, poIndex)}
                            className={classes.vendorBox}
                          >
                            <Typography className={classes.TitleVendor}>
                              {`${data.doc_num}`}
                            </Typography>
                          </Box>
                        ))
                      )}
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </Grid>
            <Grid md={8} container>
              <Grid md={4}>
                <Box className={classes.wrapperBodyInput}>
                  <Box className={classes.bodyContent}>
                    <Typography
                      className={classes.bodyTitle}
                      variant="body1"
                      component="p"
                    >
                      Activity
                    </Typography>
                  </Box>
                  <Box className={classes.bodyData}>
                    <Typography
                      className={classes.bodyDataTextAcc}
                      variant="body1"
                      component="p"
                    >
                      {PO?.items[0]?.acct_name ?? '-'}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid md={4}>
                <Box className={classes.wrapperBodyInput}>
                  <Box className={classes.bodyContent}>
                    <Typography
                      className={classes.bodyTitle}
                      variant="body1"
                      component="p"
                    >
                      Department
                    </Typography>
                  </Box>
                  <Box className={classes.bodyData}>
                    <Typography
                      className={classes.bodyDataTextAcc}
                      variant="body1"
                      component="p"
                    >
                      {PO?.pr?.department?.name ?? '-'}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid md={4}>
                <Box className={classes.wrapperBodyInput}>
                  <Box className={classes.bodyContent}>
                    <Typography
                      className={classes.bodyTitle}
                      variant="body1"
                      component="p"
                    >
                      Entity
                    </Typography>
                  </Box>
                  <Box className={classes.bodyData}>
                    <Typography
                      className={classes.bodyDataText}
                      variant="body1"
                      component="p"
                    >
                      {PO?.entity?.name ?? '-'}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid md={12} className={classes.wrapperInput} container>
            <Grid md={7} className={classes.wrapperInputSearchVendor}>
              <Box className={classes.bodyContent}>
                <Typography
                  className={classes.bodyTitle}
                  variant="body1"
                  component="p"
                >
                  Vendor Name
                </Typography>
              </Box>
              <Box
                className={classes.filterVendorContent}
                // onBlur={() => setIsVendorFocus(false)}
              >
                <Box className={classes.wrapperInputPO}>
                  <input
                    className={classes.inputVendor}
                    value={vendor?.name ?? 'All Vendor'}
                    autoComplete="on"
                    onFocus={() => setIsVendorFocus(true)}
                    placeholder="All Vendor"
                    onBlur={() => setIsVendorFocus(false)}
                  />
                  <Box
                    className={
                      vendor?.name ? classes.iconClose : classes.iconDownArrow
                    }
                  >
                    {vendor?.name ? (
                      <Box
                        className={classes.pointer}
                        onClick={() => setVendor(null)}
                      >
                        <CloseIcon />
                      </Box>
                    ) : (
                      <DownArrow />
                    )}
                  </Box>
                </Box>

                {/* render for select vendor name */}
                {isVendorFocus || showVendor ? (
                  <Box
                    onBlur={() => setIsVendorFocus(true)}
                    className={classes.contentDataVendorSecond}
                    onMouseOver={() => setShowVendor(true)}
                    onMouseOut={() => setShowVendor(false)}
                  >
                    <Box className={classes.contentVendorSearch}>
                      <input
                        className={classes.searchVendor}
                        autoComplete="on"
                        onFocus={() => setIsVendorFocus(true)}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        placeholder="Search"
                        // onBlur={() => setIsVendorFocus(false)}
                      />
                      {searchIsLoading ? (
                        <CircleLoadingSecondary />
                      ) : (
                        <img
                          className={classes.serachIcon}
                          src={Search}
                          alt="image not found"
                        />
                      )}
                    </Box>
                    <Box className={classes.listVendorName}>
                      {searchIsLoading ? (
                        <Typography className={classes.TitleVendor}>
                          Loading Get Data
                        </Typography>
                      ) : (
                        vendorData?.map((data, vendorIndex) => (
                          <Box
                            key={vendorIndex}
                            onClick={() =>
                              handleChooseVandor(data, vendorIndex)
                            }
                            className={classes.vendorBox}
                          >
                            <Typography className={classes.TitleVendor}>
                              {`${data.name}`}
                            </Typography>
                          </Box>
                        )) ?? (
                          <Typography className={classes.TitleVendor}>
                            Company Not Found
                          </Typography>
                        )
                      )}
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </Grid>
            <Grid md={5} container>
              <Grid md={6} className={classes.wrapperInputInvoice}>
                <Typography
                  className={classes.bodyTitle}
                  variant="body1"
                  component="p"
                >
                  Invoice No.
                </Typography>
                <input
                  className={`${classes.inputMenu} inputstyle`}
                  name="name"
                  placeholder="Input Number"
                  type="text"
                  value={invoiceNumber}
                  onChange={(e) => setInvoiceNumber(e.target.value)}
                  maxLength={30}
                />
                <p className={classes.errorText}>{errrorInvoiceNo}</p>
              </Grid>
              <Grid md={6} className={classes.wrapperStatusFilter}>
                <Typography
                  className={classes.bodyTitle}
                  variant="body1"
                  component="p"
                >
                  Status
                </Typography>
                <FormControl className={classes.formControl}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    IconComponent={() => <DownArrow />}
                    value={statusFilter}
                    onChange={handleChangeStatusFilter}
                  >
                    <MenuItem value={'open'}>Open</MenuItem>
                    <MenuItem value={'close'}>Close</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid md={12}>
            {vendor ? (
              <Grid md={12} className={classes.wrapperVendorInformation}>
                <Typography className={classes.vendorName}>
                  {vendor?.name}
                </Typography>
                <Typography className={classes.vendorAddress}>
                  {vendor?.address}
                </Typography>
                <Box className={classes.wrapperContact}>
                  <Grid container>
                    <Grid md={3} className={classes.contactTitle}>
                      Phone
                    </Grid>
                    <Grid md={9} className={classes.contactDesc}>
                      : {vendor?.phone}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid md={3} className={classes.contactTitle}>
                      Fax
                    </Grid>
                    <Grid md={9} className={classes.contactDesc}>
                      : {vendor?.fax}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid md={3} className={classes.contactTitle}>
                      CP
                    </Grid>
                    <Grid md={9} className={classes.contactDesc}>
                      : -
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid md={3} className={classes.contactTitle}>
                      Npwp
                    </Grid>
                    <Grid md={9} className={classes.contactDesc}>
                      : {vendor?.npwp}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ) : null}
          </Grid>
          <Grid md={12} container className={classes.wrapperInput}>
            <Grid md={4}>
              <Box>
                <Box className={classes.bodyContent}>
                  <Typography
                    className={classes.bodyTitle}
                    variant="body1"
                    component="p"
                  >
                    Invoice Date
                  </Typography>
                </Box>
                <Box className={classes.dateContent}>
                  <div className={classes.dateRangeIcon}>
                    <img src={DateIcon} alt="image not found" />
                  </div>
                  <div className={classes.containerDate}>
                    <DatePickerSingle
                      onDatePick={(pickedDay) => {
                        setDate(pickedDay);
                      }}
                      placeholder="Select Date Range"
                    />
                  </div>
                </Box>
                <p className={classes.errorText}>{errrorInvoiceDate}</p>
              </Box>
            </Grid>
            <Grid md={8} className={classes.wrapperInputBilling}>
              <Box className={classes.bodyContent}>
                <Typography
                  className={classes.bodyTitle}
                  variant="body1"
                  component="p"
                >
                  Billing Amount
                </Typography>
              </Box>
              <input
                className={`${classes.inputMenu} inputstyle`}
                name="name"
                placeholder="Input Number"
                type="text"
                value={formatRupiah(billingNumber)}
                onChange={(e) => setBillingNumber(e.target.value)}
                maxLength={30}
              />
              <p className={classes.errorText}>{errrorBillingNo}</p>
            </Grid>
          </Grid>
          <Grid md={12} className={classes.wrapperInput}>
            <Box className={classes.bodyContent}>
              <Typography
                className={classes.bodyTitle}
                variant="body1"
                component="p"
              >
                Service Description
              </Typography>
            </Box>
            <TextareaAutosize
              className={classes.inputMenuTextArea}
              // aria-label="empty textarea"
              placeholder="Write a note..."
              minRows={3}
              cols={12}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            {/* <p className={classes.errorText}>{errrorDescription}</p> */}
          </Grid>
          {PO ? (
            <Grid md={12} className={classes.wrapperInput}>
              <Typography className={classes.bodyTitleText}>
                Attachment
              </Typography>
              <Grid className={classes.wrapperFile}>
                {tempFileCheck?.map((item, index) => (
                  <>
                    <Box className={classes.wrapperTitleFile} key={index}>
                      <Typography className={classes.titleCheclist}>
                        {item.name}
                      </Typography>
                      {item?.file.length > 0 ? null : (
                        <form>
                          <input
                            // accept="image/*"
                            className={classes.input}
                            style={{ display: 'none' }}
                            id={`raised-button-file-${index}`}
                            name={`${index}`}
                            onChange={handleChangeFile}
                            type="file"
                          />
                          <label htmlFor={`raised-button-file-${index}`}>
                            <Box className={classes.btnUploadContent}>
                              <Box className={classes.btnUpload}>
                                <Box>
                                  <img
                                    src={UnduhSecond}
                                    alt="image not found"
                                  />
                                </Box>
                                <Typography
                                  className={classes.btnUploadValue}
                                  variant="body1"
                                  component="p"
                                >
                                  Attach File
                                </Typography>
                              </Box>
                            </Box>
                          </label>
                        </form>
                      )}
                    </Box>
                    {item?.file?.map((file, indexFile) => (
                      <>
                        <Grid
                          key={indexFile}
                          md={12}
                          container
                          className={classes.wrapperListFile}
                          alignItems="center"
                        >
                          <Grid md={2} className={classes.fileName}>
                            <img src={Paper} alt="none" />
                            <Typography className={classes.textFile}>
                              {file[0]?.name}
                            </Typography>
                          </Grid>
                          <Grid md={2} className={classes.fileAction}>
                            <Box className={classes.addMoreFile}>
                              <form>
                                <input
                                  className={classes.input}
                                  style={{ display: 'none' }}
                                  id={`add-more-file-${index}-${indexFile}`}
                                  name={`${index},${indexFile}`}
                                  onChange={handleAddFile}
                                  type="file"
                                />
                                <label
                                  className={classes.addMoreFile}
                                  htmlFor={`add-more-file-${index}-${indexFile}`}
                                >
                                  <img src={Plus} alt="none" />
                                </label>
                              </form>
                            </Box>
                            <img
                              src={Trash}
                              className={classes.pointer}
                              alt="none"
                              onClick={() => handleDeleteFile(index, indexFile)}
                            />
                            {/* <img src={TandaSeru} alt="none" /> */}
                          </Grid>
                          {/* <Grid md={4} className={classes.Note}>
                            <Typography className={classes.textFileNotif}>
                              The document crashsed try to locate
                            </Typography>
                          </Grid> */}
                        </Grid>
                      </>
                    ))}
                  </>
                ))}
              </Grid>
            </Grid>
          ) : null}
        </Grid>
        <Grid md={12} className={classes.wrapperFooter}>
          <Box className={classes.btnSubmit} onClick={handleProcessGRPO}>
            <Typography className={classes.btnText}>Proceed GRPO</Typography>
          </Box>
          {/* <Box className={classes.btnSubmit}>
            <Typography className={classes.btnText}>Save Change</Typography>
          </Box> */}
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapperContainer: {
    backgroundColor: '#fff',
    borderRadius: 8,
  },
  wrapperHeader: {
    padding: 20,
    borderBottom: '1px solid #E7E7E8',
  },
  headerTitle: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  wrapperBody: {
    padding: 20,
    borderBottom: '1px solid #E7E7E8',
  },
  bodyTitleText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  wrapperInput: {
    marginTop: 20,
  },
  wrapperBodyInput: {
    paddingRight: 10,
    paddingLeft: 10,
  },
  bodyTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
    marginBottom: 12,
  },
  bodyData: {
    width: '100%',
    backgroundColor: '#EBEFF0',
    padding: '12px 20px',
    border: '1px solid #E7E7E8',
    borderRadius: 8,
  },
  // Styling search PO
  wrapperInputSearch: {
    position: 'relative',
  },
  wrapperInputSearchVendor: {
    position: 'relative',
    paddingRight: 40,
  },
  inputVendor: {
    width: '100%',
    height: 50,
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    paddingLeft: 15,
    zIndex: 99,
    position: 'relative',
    background: 'transparent',
    '&.focus-visible': {
      border: '1px solid #E7E7E8',
      outline: 'none',
    },
  },
  filterVendorContent: {
    // paddingLeft: 5,
    paddingRight: 5,
    width: '100%',
    // position: 'relative',
  },
  iconDownArrow: {
    position: 'relative',
    zIndex: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 4,
    marginLeft: '-45px',
  },
  iconClose: {
    position: 'relative',
    zIndex: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 4,
    marginLeft: '-45px',
  },
  wrapperInputPO: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  iconDownArrowSecond: {
    position: 'absolute',
    zIndex: 100,
    marginTop: -30,
    width: 'calc(100% - 40px)',
    textAlign: 'right',
    paddingRight: 40,
  },
  iconTrash: {
    position: 'absolute',
    zIndex: 10000,
    marginTop: -36,
    marginLeft: 150,
    cursor: 'pointer',
  },
  exportContent: {
    marginRight: 24,
    paddingRight: 20,
    width: '100%',
    maxWidth: 164,
    minWidth: 154,
    position: 'absolute',
    right: 0,
    zIndex: 90,
    backgroundColor: '#fff',
  },
  filterBlankArea: {
    minWidth: 150,
  },
  contentDataVendor: {
    width: 'calc(100% - 10px)',
    backgroundColor: '#fff',
    border: '1px solid #E7E7E8',
    position: 'absolute',
    // maxWidth: 300,
    borderRadius: 8,
    padding: 6,
    zIndex: 99999,
  },
  pointer: {
    cursor: 'pointer',
  },
  contentDataVendorSecond: {
    width: 'calc(100% - 40px)',
    backgroundColor: '#fff',
    border: '1px solid #E7E7E8',
    position: 'absolute',
    // maxWidth: 300,
    borderRadius: 8,
    padding: 6,
    zIndex: 99999,
  },
  contentVendorSearch: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    width: '100%',
    border: '1px solid #E7E7E8',
    paddingRight: 15,
    borderRadius: 8,
  },
  searchVendor: {
    flex: 1,
    height: 40,
    border: 'none',
    outline: 'none',
    paddingLeft: 12,
  },
  listVendorName: {
    marginTop: 8,
    minHeight: `calc(202px - 56px)`,
    maxHeight: `calc(202px - 56px)`,
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.4em',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
      borderRadius: 4,
    },
  },
  listVendorNamePO: {
    marginTop: 8,
    minHeight: `calc(120px - 56px)`,
    maxHeight: `calc(120px - 56px)`,
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.4em',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
      borderRadius: 4,
    },
  },
  TitleVendor: {
    height: 46,
    marginTop: 2,
    marginBottom: 2,
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
    cursor: 'pointer',
  },
  wrapperInputInvoice: {
    marginLeft: -30,
  },
  inputMenu: {
    width: '100%',
    height: 50,
    borderRadius: 8,
    paddingLeft: 20,
    border: '1px solid #E7E7E8',
    backgroundColor: '#F8FAFB',
    outline: 'none',
    '&.inputstyle:focus': {
      border: '1.5px solid #F2C94C',
      outline: 'none',
      boxShadow: '0px 0px 0px 4px rgba(242, 201, 76, 0.17);',
      borderRadius: 8,
    },
  },
  inputMenuTextArea: {
    width: '100% !important',
    // height: 50,
    borderRadius: 8,
    paddingLeft: 15,
    border: '1px solid #E7E7E8',
    backgroundColor: '#F8FAFB',
    outline: 'none',
    paddingTop: 10,
    '&.inputstyle:focus': {
      border: '1.5px solid #F2C94C',
      outline: 'none',
      boxShadow: '0px 0px 0px 4px rgba(242, 201, 76, 0.17);',
      borderRadius: 8,
    },
  },
  // styling select
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 10,
    paddingBottom: 5,
    // maxWidth: 220,
    width: '100%',
    // minWidth: 160,
    height: 50,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#969BAB',
      paddingRight: 2,
    },
  },
  wrapperStatusFilter: {
    marginLeft: 20,
  },
  dateContent: {
    width: '100%',
    // backgroundColor: 'red',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingRight: 5,
  },
  dateRangeIcon: {
    width: 58,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E7E7E8',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  containerDate: {
    width: `100%`,
    maxWidth: `calc(100% - 58px)`,
  },
  wrapperInputBilling: {
    paddingLeft: 10,
  },
  boxEmpty: {
    width: '100%',
    paddingTop: 24.5,
    paddingBottom: 24.5,
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    marginTop: 24,
  },
  emptyText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    textAlign: 'center',
  },
  wrapperAttachment: {
    width: '100%',

    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    marginTop: 24,
    paddingLeft: 20,
    paddingRight: 31,
  },
  wrapperAttItem: {
    paddingTop: 24.5,
    paddingBottom: 24.5,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  attText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
  },
  btnUploadContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  btnUploadText: {
    fontSize: 14,
    lineHeight: '26px',
    color: '#1B2030',
  },
  btnUpload: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: '#127EEE',
    paddingLeft: 19,
    paddingRight: 19,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 8,
  },
  btnUploadValue: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
    marginLeft: 13,
  },
  wrapperFooter: {
    padding: 20,
  },
  btnSubmit: {
    width: '100%',
    maxWidth: 193,
    height: 50,
    backgroundColor: '#1B2030',
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  btnText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
  },
  wrapperVendorInformation: {
    marginTop: 26,
    paddingTop: 27,
    paddingBottom: 24,
    paddingLeft: 25,
    backgroundColor: '#F8FAFB',
  },
  vendorName: {
    fontSize: 16,
    lineHeight: '30px',
    fontWeight: 'bold',
    color: '#000000',
  },
  bodyDataTextAcc: {
    fontSize: 16,
    lineHeight: '30px',
    color: '#000000',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '90%',
  },
  vendorAddress: {
    fontSize: 16,
    lineHeight: '30px',
    color: '#000000',
  },
  wrapperContact: {
    marginTop: 17,
  },
  contactTitle: {
    fontSize: 16,
    lineHeight: '30px',
    fontWeight: 'bold',
    color: '#000000',
  },
  contactDesc: {
    fontSize: 16,
    lineHeight: '30px',
    color: '#000000',
  },
  errorText: {
    fontSize: 14,
    color: 'red',
  },
  wrapperLink: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
  },
  bodyUrlText: {
    marginLeft: 10,
    fontSize: 16,
    lineHeight: '21px',
    textDecorationLine: 'underline',
    color: '#1B2030',
  },
  trashFile: {
    marginLeft: 10,
    cursor: 'pointer',
  },
  wrapperFile: {
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    paddingTop: 15,
    paddingBottom: 15,
    marginTop: 20,
  },
  wrapperTitleFile: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 70,
    paddingLeft: 20,
    paddingRight: 20,
  },
  wrapperListFile: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  titleCheclist: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  fileName: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 6,
    paddingBottom: 6,
  },
  textFile: {
    fontSize: 16,
    lineHeight: '21px',
    marginLeft: 8,
  },
  fileAction: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  textFileNotif: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#FD453A',
  },
  addMoreFile: {
    cursor: 'pointer',
  },
  pointer: {
    cursor: 'pointer',
  },
}));

export default CreateProforma;
