import React from 'react';
import { makeStyles, Grid, Typography, Box } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { dateFormater, formatFileCount } from '../../utils/formatter';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../redux/CAR/actions';
import { useRouter } from '../../utils/useRouter';
import CircularProgress from '@material-ui/core/CircularProgress';

// Components
import Requestor from './components/Requestor';
import Information from './components/Information';
import HistoryTableDetail from '../HistoryTableDetail';

// Icons
import Cross from '../../assets/Icons/cross.png';
import UploadBtn from '../../assets/Icons/uploadBtn.png';
import Paper from '../../assets/Icons/paper.png';
import Lib from 'src/utils/Lib';

const DetailForm = () => {
  const classes = useStyles();
  const dispacth = useDispatch();
  const router = useRouter();
  const { id } = router.query;

  // Global Variable
  const { access_token, data } = useSelector((state) => state.auth);
  const {
    car_detail,
    car_updateIsLoading,
    car_updateMessage,
    car_approvalData,
  } = useSelector((state) => state.car);

  const [aprrovalOne, setArpprovalOne] = React.useState(10);
  const [aprrovalTwo, setArpprovalTwo] = React.useState(10);
  const [aprrovalThree, setArpprovalThree] = React.useState(10);

  // handle option select admin
  const handleChangeApprovalOne = (event) => {
    setArpprovalOne(event.target.value);
  };
  const handleChangeApprovalTwo = (event) => {
    setArpprovalTwo(event.target.value);
  };
  const handleChangeApprovalThree = (event) => {
    setArpprovalThree(event.target.value);
  };

  const handleSeeImage = (url) => {
    window.open(url);
  };

  const handleCancelCAR = (id) => {
    let tempFile = [];
    car_detail?.cashAdvance?.files?.map((file, index) => {
      let temp = { id: file.id };

      tempFile.push(temp);
    });
    let SendData = {
      subject: car_detail?.cashAdvance?.subject,
      amount: car_detail?.cashAdvance?.amount,
      start_date: car_detail?.cashAdvance?.start_date,
      end_date: car_detail?.cashAdvance?.end_date,
      user_id: car_detail?.cashAdvance?.user?.id,
      description: car_detail?.cashAdvance?.description,
      status: 'cancelled',
      file: tempFile,
    };
    // console.log(SendData);
    dispacth(
      actions.updateCAR(access_token, car_detail?.cashAdvance?.id, SendData)
    );
  };

  React.useEffect(() => {
    dispacth(actions.getCARDetail(access_token, id));
  }, []);

  React.useEffect(() => {
    setArpprovalOne(car_detail?.cashAdvance?.approval_transactions[0]?.user?.id);
    setArpprovalTwo(car_detail?.cashAdvance?.approval_transactions[1]?.user?.id);
    setArpprovalThree(car_detail?.cashAdvance?.approval_transactions[2]?.user?.id);
  }, [car_detail]);

  React.useEffect(() => {
    if (car_updateMessage === 'Update Cash Advance Success') {
      router.push('/cash-report');
    }
    dispacth(actions.setBlankUpdate());
  }, [car_updateMessage]);

  // for render styling
  const _renderStatus = (status) => {
    if (status === 'need_confirm') {
      return (
        <>
          <Typography className={classes.statusNeed}>
            Confirm Approval
          </Typography>
        </>
      );
    } else if (status === 'closed') {
      return (
        <>
          <Typography className={classes.statusClose}>Closed</Typography>
        </>
      );
    } else if (status === 'waiting') {
      return (
        <>
          <Typography className={classes.statusWaiting}>
            Waiting Approval
          </Typography>
        </>
      );
    } else if (status === 'open') {
      return (
        <>
          <Typography className={classes.statusOpen}>Open</Typography>
        </>
      );
    }
  };
  return (
    <>
      <Grid md={12} className={classes.paperContainer}>
        <Grid md={12} className={classes.wrapperHeader}>
          <Box className={classes.formTitle}>
            <Typography
              className={classes.formNumber}
              variant="body1"
              component="p"
            >
              {`No. ${car_detail?.cashAdvance?.id}`}
            </Typography>
            <Box className={classes.wrapperStatusForm}>
              <Typography
                className={classes.bodyDataText}
                variant="body1"
                component="p"
              >
                {dateFormater(
                  car_detail?.cashAdvance?.created_at,
                  'DD-MM-YYYY hh:mm'
                )}
              </Typography>
              {_renderStatus(car_detail?.cashAdvance?.status)}
            </Box>
          </Box>
        </Grid>
        <Box className={classes.sizeBorder} />
        <Grid md={12} container>
          <Grid md={8} item className={classes.wrapperLeftBody}>
            <Requestor data={car_detail?.cashAdvance} />
            <Information
              isDetail={true}
              valSubject={car_detail?.cashAdvance?.subject}
              valAmount={car_detail?.cashAdvance?.amount}
              valStartPeriode={car_detail?.cashAdvance?.start_date}
              valEndPeriode={car_detail?.cashAdvance?.end_date}
              valDescription={car_detail?.cashAdvance?.description}
            />
            <Typography className={classes.titleAttach}>Attachment</Typography>
            {car_detail?.cashAdvance?.files?.length < 0 ? (
              <Box className={classes.AttachmentNotif}>
                <Typography
                  className={classes.AttachmentMessage}
                  variant="body1"
                  component="p"
                >
                  No document attached.
                </Typography>
                {/* <Box>
                  <img src={Cross} alt="image not found" />
                </Box> */}
              </Box>
            ) : null}
            {car_detail?.cashAdvance?.files?.map((file, index) => (
              <>
                <Box className={classes.sizeSubLink} />
                <Box key={index} className={classes.wrapperLink}>
                  <Box
                    onClick={() => handleSeeImage(file?.url)}
                    className={classes.wrapperLink}
                  >
                    <Box>
                      <img src={Paper} alt="image not found" />
                    </Box>
                    <Typography
                      className={classes.bodyUrlText}
                      variant="body1"
                      component="p"
                    >
                      {`${file.file_name} ${formatFileCount(file?.size)} MB`}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.sizeSubLink} />
              </>
            ))}
          </Grid>
          <Grid md={4} item className={classes.wrapperRightBody}>
            {/* Assigmnet apprival */}
            <Box className={classes.assignApprovalContent}>
              <Box className={classes.assignHeader}>
                <Typography
                  className={classes.headerSelectTitle}
                  variant="body1"
                  component="p"
                >
                  Assign Approval
                </Typography>
              </Box>
              <Box className={classes.filterActifityContent}>
                <Box>
                  <Box className={classes.bodyContent}>
                    <Typography
                      className={classes.bodyTitle}
                      variant="body1"
                      component="p"
                    >
                      Approval 1
                    </Typography>
                  </Box>
                  <FormControl className={classes.formControl}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={aprrovalOne}
                      disabled={true}
                      onChange={handleChangeApprovalOne}
                    >
                      <MenuItem
                        value={car_detail?.cashAdvance?.approval_transactions[0]?.user?.id}
                      >
                        <Box className={classes.boxDepartment}>
                          <Typography
                            className={classes.bodyDataText}
                            variant="body1"
                            component="p"
                          >
                            {car_detail?.cashAdvance?.approval_transactions[0]?.user?.name}
                          </Typography>
                          <Typography
                            className={classes.bodyDataSubText}
                            variant="body1"
                            component="p"
                          >
                            {`- ${car_detail?.cashAdvance?.approval_transactions[0]?.user?.roles[0]?.name} ${car_detail?.cashAdvance?.approval_transactions[0]?.user?.departments?.name}`}
                          </Typography>
                        </Box>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box className={classes.sizeBox} />
                <Box>
                  <Box className={classes.bodyContent}>
                    <Typography
                      className={classes.bodyTitle}
                      variant="body1"
                      component="p"
                    >
                      Approval 2
                    </Typography>
                  </Box>
                  <FormControl className={classes.formControl}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={aprrovalTwo}
                      disabled={true}
                      onChange={handleChangeApprovalTwo}
                    >
                      <MenuItem
                        value={car_detail?.cashAdvance?.approval_transactions[1]?.user?.id}
                      >
                        <Box className={classes.boxDepartment}>
                          <Typography
                            className={classes.bodyDataText}
                            variant="body1"
                            component="p"
                          >
                            {car_detail?.cashAdvance?.approval_transactions[1]?.user?.name}
                          </Typography>
                          <Typography
                            className={classes.bodyDataSubText}
                            variant="body1"
                            component="p"
                          >
                            {`- ${car_detail?.cashAdvance?.approval_transactions[1]?.user?.roles[0]?.name} ${car_detail?.cashAdvance?.approval_transactions[1]?.user?.departments?.name}`}
                          </Typography>
                        </Box>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box className={classes.sizeBox} />
                <Box>
                  <Box className={classes.bodyContent}>
                    <Typography
                      className={classes.bodyTitle}
                      variant="body1"
                      component="p"
                    >
                      Approval 3
                    </Typography>
                  </Box>
                  <FormControl className={classes.formControl}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={aprrovalThree}
                      disabled={true}
                      onChange={handleChangeApprovalThree}
                    >
                      <MenuItem
                        value={car_detail?.cashAdvance?.approval_transactions[2]?.user?.id}
                      >
                        <Box className={classes.boxDepartment}>
                          <Typography
                            className={classes.bodyDataText}
                            variant="body1"
                            component="p"
                          >
                            {car_detail?.cashAdvance?.approval_transactions[2]?.user?.name}
                          </Typography>
                          <Typography
                            className={classes.bodyDataSubText}
                            variant="body1"
                            component="p"
                          >
                            {`- ${car_detail?.cashAdvance?.approval_transactions[2]?.user?.roles[0]?.name} ${car_detail?.cashAdvance?.approval_transactions[2]?.user?.departments?.name}`}
                          </Typography>
                        </Box>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              {/* <Box
                className={classes.BtnConfirm}
                // onClick={handleConfirmAssigment}
              >
                <Typography
                  className={classes.BtnConfirmText}
                  variant="body1"
                  component="p"
                >
                  Confirm Assignment
                </Typography>
              </Box> */}
            </Box>
          </Grid>
        </Grid>
        { Lib.allowCancel(data) ? (
        <Grid md={12} className={classes.wrapperBtnCancel}>
          <Box className={classes.wrapperBtnContent}>
            <Box className={classes.bntCancel} onClick={handleCancelCAR}>
              <Typography className={classes.bntCancelText}>
                Cancel CAR
              </Typography>
            </Box>
            {car_updateIsLoading ? <CircularProgress /> : null}
          </Box>
        </Grid>) : null }
      </Grid>
      <Box className={classes.sizeBorder} />
      <Grid md={12}>
        <HistoryTableDetail
          showHistory={true}
          data={car_detail?.cashAdvance?.approval_transactions}
        />
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    backgroundColor: '#fff',
    borderRadius: 8,
  },
  wrapperHeader: {
    padding: 20,
  },
  headerTitle: {
    fontSize: 18,
    lineHeight: '27px',
    color: '#969BAB',
    fontWeight: 'bold',
  },
  headerSelectTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  sizeBorder: {
    borderBottom: '1px solid #E7E7E8',
  },
  wrapperLeftBody: {
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 20,
    borderRight: '1px solid #E7E7E8',
  },
  wrapperRightBody: {
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 20,
  },
  titleAttach: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    fontWeight: 'bold',
  },
  AttachmentNotif: {
    marginTop: 20,
    marginBottom: 20,
    padding: 10,
    borderRadius: 4,
    backgroundColor: '#FFE9E8',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  AttachmentMessage: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#F12B2C',
  },
  btnUploadContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  btnUploadText: {
    fontSize: 14,
    lineHeight: '26px',
    color: '#1B2030',
  },
  btnUpload: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: '#127EEE',
    paddingLeft: 19,
    paddingRight: 19,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 8,
  },
  btnUploadValue: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
    marginLeft: 13,
  },
  filterActifityContent: {
    marginTop: 20,
  },
  bodyTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
    marginBottom: 12,
  },
  // styling Form select
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 5,
    paddingBottom: 5,
    // maxWidth: 220,
    width: '100%',
    // minWidth: 160,
    height: 50,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      // marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#969BAB',
      paddingRight: 2,
      backgroundColor: '#F8FAFB',
    },
  },
  sizeBox: {
    marginTop: 20,
  },
  boxDepartment: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textTransform: 'ellipsis',
    maxWidth: 320,
    paddingRight: 20,
  },
  bodyDataSubText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    marginLeft: 4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  BtnConfirm: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 14.5,
    paddingBottom: 14.5,
    backgroundColor: '#1B2030',
    borderRadius: 8,
    maxWidth: 193,
    marginTop: 20,
    cursor: 'pointer',
  },
  BtnConfirmFalse: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 14.5,
    paddingBottom: 14.5,
    backgroundColor: '#969BAB',
    borderRadius: 8,
    maxWidth: 193,
    marginTop: 20,
    cursor: 'pointer',
  },
  BtnConfirmText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
  },
  wrapperBtnCancel: {
    padding: 20,
  },
  bntCancel: {
    marginRight: 20,
    paddingTop: 6,
    paddingBottom: 6,
    cursor: 'pointer',
  },
  bntCancelText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#FD453A',
  },
  formTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  formNumber: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#1B2030',
  },
  wrapperStatusForm: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  // styling status
  statusNeed: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#F12B2C',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  statusOpen: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#127EEE',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  statusWaiting: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#FFB800',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  statusClose: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    background: '#969BAB',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  wrapperLink: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
  },
  bodyUrlText: {
    marginLeft: 10,
    fontSize: 16,
    lineHeight: '21px',
    textDecorationLine: 'underline',
    color: '#1B2030',
  },
  sizeSubLink: {
    marginTop: 15,
  },
  wrapperBtnContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
}));

export default DetailForm;
