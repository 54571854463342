import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Grid } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

// images
import Checklist from '../../assets/Icons/messageReset.png';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    paddingTop: 30,
    paddingBottom: 26,
    borderRadius: 18,
    maxWidth: 454,
    maxHeight: 640,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapperContent: {
    width: 454,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  notifText: {
    marginTop: 32,
    fontSize: 36,
    lineHeight: '49px',
    color: '#000000',
    fontWeight: 'bold',
    marginBottom: 22,
  },
  textDesc: {
    fontSize: 16,
    lineHeight: '30px',
    color: '#1B2030',
    textAlign: 'center',
  },
  wrapperDetail: {
    width: '100%',
    backgroundColor: '#F8FAFB',
    paddingTop: 20,
    paddingLeft: 20,
  },
  btnContent: {
    marginTop: 30,
    width: 229,
    backgroundColor: '#1B2030',
    height: 48,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  btnText: {
    fontSize: 16,
    lineHeight: '30px',
    fontWeight: 'bold',
    color: '#fff',
  },
}));

export default function ModalSuccessCreateUser({
  open,
  close,
  username,
  email,
  password,
  click,
}) {
  const classes = useStyles();

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        // onClose={close}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Grid md={12} className={classes.wrapperContent}>
              <div>
                <img
                  // className={classes.bellImage}
                  src={Checklist}
                  alt="image not found"
                />
              </div>
              <Typography
                className={classes.notifText}
                variant="body1"
                component="p"
              >
                Success
              </Typography>
              <Typography
                className={classes.textDesc}
                variant="body1"
                component="p"
              >
                You are successfully created an account. The user will
                automatically receive an email about this credentials
                information:
              </Typography>
              <Grid
                md={12}
                style={{
                  marginTop: 20,
                  paddingLeft: 55,
                  paddingRight: 55,
                  width: '100%',
                }}
              >
                <Grid md={12} className={classes.wrapperDetail}>
                  <Grid container md={12}>
                    <Grid md={4}>Username</Grid>
                    <Grid md={8}>: {username}</Grid>
                  </Grid>
                  <Grid
                    container
                    md={12}
                    style={{ paddingTop: 14, paddingBottom: 14 }}
                  >
                    <Grid md={4}>Email</Grid>
                    <Grid md={8}>: {email}</Grid>
                  </Grid>
                  <Grid container md={12}>
                    <Grid md={4}>Password</Grid>
                    <Grid md={8}>: {password}</Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box className={classes.btnContent} onClick={click}>
                <Typography
                  className={classes.btnText}
                  variant="body1"
                  component="p"
                >
                  Oke
                </Typography>
              </Box>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
