import * as types from './types';

import request from '../../utils/request';

export const getSAPnotification = (token) => (dispatch) => {
  dispatch({ type: types.PO_NOTIF_LOADING, payload: true });

  request({
    method: 'get',
    url: 'purchase-order/notification',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get notif');
      dispatch({ type: types.PO_NOTIF_DATA, payload: res?.data?.draft });
      dispatch({
        type: types.PO_NOTIF_DASHBOARD_DATA,
        payload: res?.data?.draft,
      });
      dispatch({ type: types.PO_NOTIF_MESSAGE, payload: res?.message });
      dispatch({ type: types.PO_NOTIF_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get notif PO', err);
      dispatch({ type: types.PO_NOTIF_LOADING, payload: false });
    });
};

export const getPOdata = (token, params) => (dispatch) => {
  dispatch({ type: types.PO_DATA_LOADING, payload: true });

  request({
    method: 'get',
    url: `purchase-order`,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get PO data');
      dispatch({ type: types.PO_SET_DATA, payload: res?.data });
      dispatch({ type: types.PO_DATA_MESSAGE, payload: res?.message });
      dispatch({ type: types.PO_DATA_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get data PO', err);
      dispatch({ type: types.PO_DATA_LOADING, payload: false });
    });
};

export const searchPOdata = (token, params) => (dispatch) => {
  dispatch({ type: types.PO_SEARCH_LOADING, payload: true });

  request({
    method: 'get',
    url: `purchase-order`,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('serach PO data', res.data);
      dispatch({ type: types.PO_SEARCH_DATA, payload: res?.data });
      dispatch({ type: types.PO_SEARCH_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error search data PO', err);
      dispatch({ type: types.PO_SEARCH_LOADING, payload: false });
    });
};

export const getPODetail = (token, id) => (dispatch) => {
  dispatch({ type: types.PO_DETAIL_LOADING, payload: true });

  request({
    method: 'get',
    url: `purchase-order/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get PO Detail data');
      // console.log(res);
      dispatch({ type: types.PO_DETAIL_DATA, payload: res?.data });
      dispatch({ type: types.PO_DETAIL_MESSAGE, payload: res?.message });
      dispatch({ type: types.PO_DETAIL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get data PO', err);
      dispatch({ type: types.PO_DETAIL_LOADING, payload: false });
    });
};

export const getPOSAPdata = (token, params) => (dispatch) => {
  dispatch({ type: types.PO_SYNC_LOADING, payload: true });

  request({
    method: 'get',
    url: `purchase-order`,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get SAP PO data');

      dispatch({ type: types.PO_SYNC_DATA, payload: res?.data });
      dispatch({ type: types.PO_SYNC_MESSAGE, payload: res?.message });
      dispatch({ type: types.PO_SYNC_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get SAP data PO', err);
      dispatch({ type: types.PO_SYNC_LOADING, payload: false });
    });
};

export const deletePO = (token, id) => (dispatch) => {
  dispatch({ type: types.PO_DELETE_LOADING, payload: true });

  request({
    method: 'delete',
    url: `purchase-order/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get PO Detail data');
      // console.log(res);
      dispatch({ type: types.PO_DELETE_MESSAGE, payload: res?.message });
      dispatch({ type: types.PO_DELETE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error delete PO ', err);
      dispatch({ type: types.PO_DELETE_MESSAGE, payload: err?.message });
      dispatch({ type: types.PO_DELETE_LOADING, payload: false });
    });
};

export const setBlankDelete = () => (dispatch) => {
  dispatch({ type: types.PO_DELETE_MESSAGE, payload: '' });
};

export const publishPO = (token, data) => (dispatch) => {
  dispatch({ type: types.PO_PUBLISH_LOADING, payload: true });

  request({
    method: 'put',
    url: `purchase-order/publish`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('publish PO data');
      dispatch({ type: types.PO_PUBLISH_MESSAGE, payload: res?.message });
      dispatch({ type: types.PO_PUBLISH_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error publish PO ', err);
      dispatch({ type: types.PO_PUBLISH_LOADING, payload: false });
    });
};

export const setBlankPublish = () => (dispatch) => {
  dispatch({ type: types.PO_PUBLISH_MESSAGE, payload: '' });
};

export const cancelPO = (token, id) => (dispatch) => {
  dispatch({ type: types.PO_CANCEL_LOADING, payload: true });

  request({
    method: 'put',
    url: `purchase-order/${id}/cancel`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('Cancel PO data');
      console.log(res);
      dispatch({ type: types.PO_CANCEL_MESSAGE, payload: res?.message });
      dispatch({ type: types.PO_CANCEL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error cancel PO ', err);
      dispatch({ type: types.PO_CANCEL_MESSAGE, payload: err?.data?.message });
      dispatch({ type: types.PO_CANCEL_LOADING, payload: false });
    });
};

export const setBlankCancel = () => (dispatch) => {
  dispatch({ type: types.PO_CANCEL_MESSAGE, payload: '' });
};

export const assigmentPO = (token, id, data) => (dispatch) => {
  dispatch({ type: types.PO_ASSIGNMENT_LOADING, payload: true });

  request({
    method: 'put',
    url: `purchase-order/${id}/confirm-assignment`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('confirm assigment PO data');
      // console.log(res);
      dispatch({ type: types.PO_ASSIGNMENT_MESSAGE, payload: res?.message });
      dispatch({ type: types.PO_ASSIGNMENT_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error confirm assigment PO ', err);
      dispatch({
        type: types.PO_ASSIGNMENT_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: types.PO_ASSIGNMENT_LOADING, payload: false });
    });
};

export const setAssigmentMessage = () => (dispatch) => {
  dispatch({ type: types.PO_ASSIGNMENT_MESSAGE, payload: '' });
};

export const confrimChecklistPO = (token, id, data) => (dispatch) => {
  dispatch({ type: types.PO_CONFIRM_CHECKLIST_LOADING, payload: true });

  request({
    method: 'post',
    url: `purchase-order/${id}/confirm-activity`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: types.PO_CONFIRM_CHECKLIST_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: types.PO_CONFIRM_CHECKLIST_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error confirm checklist PO ', err);
      dispatch({
        type: types.PO_CONFIRM_CHECKLIST_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: types.PO_CONFIRM_CHECKLIST_LOADING, payload: false });
    });
};

export const setBlankConfirmChecklist = () => (dispatch) => {
  dispatch({ type: types.PO_CONFIRM_CHECKLIST_MESSAGE, payload: '' });
};
