// notif PR
export const PR_NOTIF_LOADING = 'PR/PR_NOTIF_LOADING';
export const PR_NOTIF_MESSAGE = 'PR/PR_NOTIF_MESSAGE';
export const PR_NOTIF_DATA = 'PR/PR_NOTIF_DATA';
export const PR_NOTIF_DASHBOARD_DATA = 'PR/PR_NOTIF_DASHBOARD_DATA';

// get data PR
export const PR_DATA_LOADING = 'PR/PR_DATA_LOADING';
export const PR_DATA_MESSAGE = 'PR/PR_DATA_MESSAGE';
export const PR_SET_DATA = 'PR/PR_SET_DATA';

// get pr by id
export const PR_DETAIL_LOADING = 'PR/PR_DETAIL_LOADING';
export const PR_DETAIL_MESSAGE = 'PR/PR_DETAIL_MESSAGE';
export const PR_DETAIL_DATA = 'PR/PR_DETAIL_DATA';

// get pr SAP data
export const PR_SYNC_LOADING = 'PR/PR_SYNC_LOADING';
export const PR_SYNC_MESSAGE = 'PR/PR_SYNC_MESSAGE';
export const PR_SYNC_DATA = 'PR/PR_SYNC_DATA';

// delete Pr
export const PR_DELETE_LOADING = 'PR/PR_DELETE_LOADING';
export const PR_DELETE_MESSAGE = 'PR/PR_DELETE_MESSAGE';

// publish Pr
export const PR_PUBLISH_LOADING = 'PR/PR_PUBLISH_LOADING';
export const PR_PUBLISH_MESSAGE = 'PR/PR_PUBLISH_MESSAGE';

// Cancel Pr
export const PR_CANCEL_LOADING = 'PR/PR_CANCEL_LOADING';
export const PR_CANCEL_MESSAGE = 'PR/PR_CANCEL_MESSAGE';

// confirm Assign
export const PR_ASSIGNMENT_LOADING = 'PR/PR_ASSIGNMENT_LOADING';
export const PR_ASSIGNMENT_MESSAGE = 'PR/PR_ASSIGNMENT_MESSAGE';
