import React from 'react';
import { Grid, Box, Typography, makeStyles } from '@material-ui/core';
import { useRouter } from '../../utils/useRouter';

// icons
import PlusBtn from '../../assets/Icons/plusBtn.png';

// Components
import ActivityListing from './Components/Listing/ActivityListing';
import ChecklistListing from './Components/Listing/ChecklistListing';
import ClientCategoryListing from './Components/Listing/ClientCategotyListing';
import ProcurementCategoryListing from './Components/Listing/ProcurementCategoryListing';
import GroupCodeListing from './Components/Listing/GroupCodeListing';
import ReportListing from './Components/Listing/ReportListing';
import ApproverListing from './Components/Listing/ApproverListing';

const MasterData = () => {
  const classes = useStyles();
  const router = useRouter();
  const [index, setIndex] = React.useState(1);
  const [title, setTitle] = React.useState('Activity');

  React.useEffect(() => {
    if (index === 1) {
      setTitle('Activity');
    } else if (index === 2) {
      setTitle('Checklist');
    } else if (index === 3) {
      setTitle('Category');
    } else if (index === 4) {
      setTitle('Category');
    } else if (index === 5) {
      setTitle('Group Code');
    } else if (index === 6) {
      setTitle('');
    } else if (index === 7) {
      setTitle('Approver');
    }
  }, [index]);

  const _renderListData = (index) => {
    if (index === 1) {
      return <ActivityListing />;
    } else if (index === 2) {
      return <ChecklistListing />;
    } else if (index === 3) {
      return <ClientCategoryListing />;
    } else if (index === 4) {
      return <ProcurementCategoryListing />;
    } else if (index === 5) {
      return <GroupCodeListing />;
    } else if (index === 6) {
      return <ReportListing />;
    } else if (index === 7) {
      return <ApproverListing />;
    }
  };

  const handleAddData = (index) => {
    if (index === 1) {
      router.push('/master-data/create-activity');
    } else if (index === 2) {
      router.push('/master-data/create-checklist');
    } else if (index === 3) {
      router.push('/master-data/create-client-category');
    } else if (index === 4) {
      router.push('/master-data/create-purchase-type');
    } else if (index === 5) {
      router.push('/master-data/create-groupCode');
    }
  };

  return (
    <>
      <Grid md={12} className={classes.wrapperContainer}>
        <Grid md={12} className={classes.wrapperMainTitle}>
          <Typography className={classes.mainTitle}>Master Data</Typography>
        </Grid>
        <Grid md={12} className={classes.wrapperTab} container>
          <Grid md={10} className={classes.wrapperTabContent}>
            <Box
              className={
                index === 1 ? classes.tabColomActive : classes.tabColom
              }
              onClick={() => setIndex(1)}
            >
              <Typography
                className={
                  index === 1 ? classes.tabTextActive : classes.tabText
                }
              >
                Activities
              </Typography>
            </Box>
            <Box
              className={
                index === 2 ? classes.tabColomActive : classes.tabColom
              }
              onClick={() => setIndex(2)}
            >
              <Typography
                className={
                  index === 2 ? classes.tabTextActive : classes.tabText
                }
              >
                Checklist
              </Typography>
            </Box>
            <Box
              className={
                index === 3 ? classes.tabColomActive : classes.tabColom
              }
              onClick={() => setIndex(3)}
            >
              <Typography
                className={
                  index === 3 ? classes.tabTextActive : classes.tabText
                }
              >
                Client Category
              </Typography>
            </Box>
            <Box
              className={
                index === 4 ? classes.tabColomActive : classes.tabColom
              }
              onClick={() => setIndex(4)}
            >
              <Typography
                className={
                  index === 4 ? classes.tabTextActive : classes.tabText
                }
              >
                Procurement Category
              </Typography>
            </Box>
            <Box
              className={
                index === 5 ? classes.tabColomActive : classes.tabColom
              }
              onClick={() => setIndex(5)}
            >
              <Typography
                className={
                  index === 5 ? classes.tabTextActive : classes.tabText
                }
              >
                Group Code
              </Typography>
            </Box>
            <Box
              className={
                index === 6 ? classes.tabColomActive : classes.tabColom
              }
              onClick={() => setIndex(6)}
            >
              <Typography
                className={
                  index === 6 ? classes.tabTextActive : classes.tabText
                }
              >
                Report
              </Typography>
            </Box>
            <Box
              className={
                index === 7 ? classes.tabColomActive : classes.tabColom
              }
              onClick={() => setIndex(7)}
            >
              <Typography
                className={
                  index === 7 ? classes.tabTextActive : classes.tabText
                }
              >
                Approver
              </Typography>
            </Box>
          </Grid>
          <Grid md={2} className={classes.wrapperBtnContent}>
            {index === 6 ? null : (
              <Box
                className={classes.btnAdd}
                onClick={() => handleAddData(index)}
              >
                <img src={PlusBtn} alt="image plus" />
                <Typography
                  className={classes.textbtn}
                >{`Add ${title}`}</Typography>
              </Box>
            )}
          </Grid>
        </Grid>
        {_renderListData(index)}
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapperContainer: {
    borderRadius: 8,
    backgroundColor: '#fff',
  },
  wrapperMainTitle: {
    padding: 20,
  },
  mainTitle: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  wrapperTab: {
    borderTop: '1px solid #E7E7E8',
    borderBottom: '1px solid #E7E7E8',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  wrapperTabContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  tabColomActive: {
    padding: 20,
    borderBottom: '3px solid #127EEE',
    cursor: 'pointer',
    height: 64,
    textAlign: 'center',
  },
  tabColom: {
    padding: 20,
    cursor: 'pointer',
    height: 64,
    textAlign: 'center',
  },
  tabTextActive: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#127EEE',
  },
  tabText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  wrapperBtnContent: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  btnAdd: {
    minWidth: 146,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#1B2030',
    borderRadius: 8,
    marginRight: 20,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 9.5,
    paddingBottom: 9.5,
    cursor: 'pointer',
  },
  textbtn: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
    marginLeft: 24,
  },
}));

export default MasterData;
