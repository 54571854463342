import _ from 'lodash';

// TODO: need to add more columns name for more complex sorting

export const SortingASC = (initialRow, name, setData) => {
  let sortItem = _.orderBy(initialRow, `${name}`, 'asc');
  setData(sortItem);
};

export const SortingDESC = (initialRow, name, setData) => {
  let sortItem = _.orderBy(initialRow, `${name}`, 'desc');
  setData(sortItem);
};
