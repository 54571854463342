import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';

const ButtonTransparent = ({ setShowModalConfirm, text, hiddenCancel }) => {
  const classes = useStyles();
  return (
    <>{ !hiddenCancel ? 
      (<Box className={classes.btnContent}>
        <Box>
          <Box
            className={classes.btnCancel}
            onClick={() => setShowModalConfirm(true)}
          >
            <Typography
              className={classes.btnCancelText}
              variant="body1"
              component="p"
            >
              {text ? text : 'Cancel PR'}
            </Typography>
          </Box>
        </Box>
      </Box>) : null}
    </>
  );
};

const useStyles = makeStyles({
  btnContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingTop: 26,
    paddingBottom: 16,
    paddingRight: 20,
    paddingLeft: 20,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
  },

  btnCancel: {
    cursor: 'pointer',
    backgroundColor: '#fff',
    paddingRight: 20,
    paddingTop: 14,
    paddingBottom: 14,
    borderRadius: 8,
  },
  btnCancelText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#FD453A',
  },
  btnText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
  },
});

export default ButtonTransparent;
