import React from 'react';
import { makeStyles, Grid, Box } from '@material-ui/core';

// Components
import { ContentText } from '../../../../libs/Typography';

const DefaultHeader = () => {
  const classes = useStyles();
  return (
    <Grid xs={12} md={12} className={classes.container}>
      <ContentText content={`Create Invoice`} styling={classes.textTitle} />
    </Grid>
  );
};

const SecondaryHeader = ({ number, status, date, loading }) => {
  const classes = useStyles();

  const renderStyleStatus = (status) => {
    if (status === 'Verification') return classes.verification;
    if (status === 'Revision') return classes.revision;
    if (status === 'Process BAST') return classes.processbast;
    if (status === 'Closed') return classes.close;
  };

  const renderTextStatus = (status) => {
    if (status === 'Verification') return `verification`;
    if (status === 'Revision') return `revision`;
    if (status === 'Process BAST') return `Process BAST`;
    if (status === 'Closed') return `Closed`;
  };

  return (
    <Grid xs={12} md={12} className={classes.container}>
      <Box className={classes.descContent}>
        {loading ? (
          <ContentText
            styling={classes.numberText}
            content={`Loading GET data proforma`}
          />
        ) : (
          <ContentText
            styling={classes.numberText}
            content={`No Proforma ${number}`}
          />
        )}
        {!loading ? (
          <Box className={classes.descStatus}>
            <ContentText styling={classes.dateText} content={date} />
            <ContentText
              styling={renderStyleStatus(status)}
              content={renderTextStatus(status)}
            />
          </Box>
        ) : null}
      </Box>
    </Grid>
  );
};

const HeaderTitle = (props) => {
  if (props?.variant)
    return (
      <SecondaryHeader
        number={props?.number}
        status={props?.status}
        date={props?.date}
        loading={props?.loading}
      />
    );

  return <DefaultHeader />;
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
    backgroundColor: '#fff',
  },
  textTitle: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '27px',
    color: '#969BAB',
  },
  descContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  descStatus: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  numberText: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 700,
    color: '#1B2030',
  },
  dateText: {
    fontSize: 14,
    lineHeight: '26px',
    fontWeight: 400,
    color: '#1B2030',
    marginRight: 8,
  },
  verification: {
    padding: '0px 12px',
    background: '#127EEE',
    borderRadius: 100,
    fontSize: 12,
    lineHeight: '26px',
    fontWeight: 700,
    color: '#fff',
    textTransform: 'uppercase',
  },
  revision: {
    padding: '0px 12px',
    background: '#FD453A',
    borderRadius: 100,
    fontSize: 12,
    lineHeight: '26px',
    fontWeight: 700,
    color: '#fff',
    textTransform: 'uppercase',
  },
  processbast: {
    padding: '0px 12px',
    background: '#FFB800',
    borderRadius: 100,
    fontSize: 12,
    lineHeight: '26px',
    fontWeight: 700,
    color: '#fff',
    textTransform: 'uppercase',
  },
  close: {
    padding: '0px 12px',
    background: '#969BAB',
    borderRadius: 100,
    fontSize: 12,
    lineHeight: '26px',
    fontWeight: 700,
    color: '#fff',
    textTransform: 'uppercase',
  },
}));

export default HeaderTitle;
