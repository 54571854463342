import React from 'react';
import { makeStyles, Grid, Typography, Box } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../redux/CAR/actions';
import * as userManagement from '../../redux/UserManagement/actions';
import { dateFormater, formatFileCount } from '../../utils/formatter';
import { useRouter } from '../../utils/useRouter';
import axios from 'axios';
import { END_POINT } from '../../config/EndPoint';
import CircularProgress from '@material-ui/core/CircularProgress';
import Skeleton from '@material-ui/lab/Skeleton';

// Components
import Requestor from './components/Requestor';
import Information from './components/Information';
import HistoryTableDetail from '../HistoryTableDetail';
import ModalUpload from '../ModalUpload';
import ModalConfirmUpload from '../ModalConfirmUpload';
import CustomizedSnackbars from '../PopupMessage';
import SelectFilter from '../SelectFilter';

// Icons
import Cross from '../../assets/Icons/cross.png';
import UploadBtn from '../../assets/Icons/uploadBtn.png';
import Paper from '../../assets/Icons/paper.png';
import CirclePlus from '../../assets/Icons/circlePlus.png';
import { isArray, isEmpty } from 'lodash';

const CreateForm = () => {
  const classes = useStyles();
  const dispacth = useDispatch();
  const router = useRouter();

  // Global Variable
  const { access_token, data } = useSelector((state) => state.auth);
  const { car_approvalData, car_sendMessage, car_sendIsLoading } = useSelector(
    (state) => state.car
  );

  const { internal_all_data, internal_all_IsLoading } = useSelector(
    (state) => state.userManagement
  );

  // variabel for parsing data car
  const [startPeriode, setStartPeriode] = React.useState(null);
  const [endPeriode, setEndPeriode] = React.useState(null);
  const [subject, setSubject] = React.useState('');
  const [amount, setAmount] = React.useState('');
  const [description, setDescription] = React.useState('');

  // handle state Error
  const [errorSubject, setErrorSubject] = React.useState('');
  const [errorAmount, setErrorAmount] = React.useState('');
  const [errorStartPeriode, setErrorStartPeriode] = React.useState('');
  const [errorEndPeriode, setErrorEndPeriode] = React.useState('');
  const [errorDescription, setErrorDescription] = React.useState('');

  React.useEffect(() => {
    if (subject.length > 0) {
      setErrorSubject('');
    }
  }, [subject]);

  React.useEffect(() => {
    if (amount.length > 0) {
      setErrorAmount('');
    }
  }, [amount]);

  React.useEffect(() => {
    if (startPeriode) {
      setErrorStartPeriode('');
    }
  }, [startPeriode]);

  React.useEffect(() => {
    if (endPeriode) {
      setErrorEndPeriode('');
    }
  }, [endPeriode]);

  React.useEffect(() => {
    if (description.length > 0) {
      setErrorDescription('');
    }
  }, [description]);

  // end handle state error

  const [showModalUpload, setShowModalUpload] = React.useState(false);
  const [showModalConfirmUpload, setShowModalConfirmUpload] =
    React.useState(false);

  const [aprrovalOne, setArpprovalOne] = React.useState('select');
  const [aprrovalTwo, setArpprovalTwo] = React.useState('select');
  const [aprrovalThree, setArpprovalThree] = React.useState('select');

  const handleConfirmAssigment = () => {

    if (!subject) {
      setErrorSubject('subject field required');
    }
    if (!amount) {
      setErrorAmount('amount field required');
    }
    if (!startPeriode || !startPeriode.start) {
      setErrorStartPeriode('date field required');
    }
    if (!endPeriode || !endPeriode.start) {
      setErrorEndPeriode('date field required');
    }
    if (!description) {
      setErrorDescription('field required');
    }

    if (temporaryImage.length < 1) {
      setType('error');
      setShow(true);
      setNotifText('please attach at least one file');
    }

    if (
      !errorSubject &&
      !errorAmount &&
      !errorStartPeriode &&
      !errorStartPeriode &&
      !errorDescription &&
      temporaryImage.length > 0
    ) {
      // console.log('valid');
      let tempFile = [];
      temporaryImage.map((file, index) => {
        let temp = { id: file.id };

        tempFile.push(temp);
      });

      let dataTransaction = []
      
      if ([aprrovalOne, aprrovalTwo, aprrovalThree].includes('select') || [aprrovalOne, aprrovalTwo, aprrovalThree].includes(undefined)) {
        setType('error');
        setShow(true);
        setNotifText('Please set Assign Approval setting');
        return
      } else {
        dataTransaction = [
          {
            transaction_type_id: 11,
            doc_id: 0,
            user_id: aprrovalOne,
            level: 1,
          },
          {
            transaction_type_id: 11,
            doc_id: 0,
            user_id: aprrovalTwo,
            level: 2,
          },
          {
            transaction_type_id: 11,
            doc_id: 0,
            user_id: aprrovalThree,
            level: 3,
          },
        ];
      }

      let SendData = {
        subject: subject,
        amount: amount,
        start_date: dateFormater(startPeriode.start, 'YYYY-MM-DD'),
        end_date: dateFormater(endPeriode.start, 'YYYY-MM-DD'),
        user_id: data?.user?.id,
        description: description,
        status: 'open',
        file: tempFile,
        approval_transactions: dataTransaction
      };
      // console.log(SendData);
      dispacth(actions.saveCAR(access_token, SendData));
    }
  };

  // handle Upload Document

  const [imageColletion, setImageCollection] = React.useState([]);
  const [uploadLoading, setUploadLoading] = React.useState(false);
  const [percent, setPercent] = React.useState(0);
  const [totalFile, setTotalFile] = React.useState(0);
  const [uploadedFile, setUploadedFile] = React.useState(0);
  const [rowApproval, setRowApproval] = React.useState([]);
  const [temporaryImage, setTemporaryImage] = React.useState([]);

  const handleAddTempImage = (value) => {
    let tempArr = [...value];

    // shifting for delete null in reponse API
    let result = tempArr.shift();
    let itemArr = [];
    tempArr.map((data, index) => {
      itemArr.push(data);
    });
    setTemporaryImage([...temporaryImage, ...itemArr]);
  };

  const handleRemoveTempImage = (index) => {
    const values = [...temporaryImage];
    values.splice(index, 1);
    setTemporaryImage(values);
  };

  const onFileChange = (e) => {
    let tempFile = e.target.files;

    // console.log(tempFile);

    setImageCollection((prev) => [...prev, ...tempFile]);
  };

  const handleRemoveFile = (indexOf) => {
    let filteImage = imageColletion.filter((img, index) => index !== indexOf);

    setImageCollection(filteImage);
  };

  const handleUploadFile = () => {
    if (imageColletion) {
      try {
        setUploadLoading(true);

        const sendData = new FormData();
        imageColletion.map((img, index) => {
          sendData.append('file[]', img);
        });

        const config = {
          headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${access_token}`,
          },
          onUploadProgress: (progressEvent) => {
            var percentComplete = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setPercent(percentComplete);
          },
        };

        axios
          .post(
            `${END_POINT}files?doc_type=${'CA'} 
            &entity_code=${data?.user?.entity[0]?.code}`,
            sendData,
            config
          )
          .then((res) => {
            // console.log(res);
            setPercent(0);
            setUploadLoading(false);
            setShowModalConfirmUpload(false);
            setShowModalUpload(false);
            handleAddTempImage(res?.data?.data);
            setImageCollection([]);
          });
      } catch (error) {
        console.log('error upload file');
        setUploadLoading(false);
      }
      // dispacth(actions_2.uploadFile(access_token, sendData));
    } else {
      console.log('chosses an image');
    }
  };

  // end handle Upload Document

  // handle option
  const handleChangeApprovalOne = (event) => {
    setArpprovalOne(event.target.value);
  };
  const handleChangeApprovalTwo = (event) => {
    setArpprovalTwo(event.target.value);
  };
  const handleChangeApprovalThree = (event) => {
    setArpprovalThree(event.target.value);
  };
  const handleOnchangeSubject = (event) => {
    setSubject(event.target.value);
  };
  const handleOnchangeAmount = (event) => {
    setAmount(event.target.value);
  };
  const handleOnchangeDesc = (event) => {
    setDescription(event.target.value);
  };

  const handleSeeImage = (url) => {
    window.open(url);
  };

  // handle message
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };
  // end handle message

  // set approval CAR
  React.useEffect(() => {
    let params = { department_id: data?.user?.department?.id };

    dispacth(actions.getApprovalCAR(access_token, params));
    
    // for get internal user
    dispacth(userManagement.getInternalAllUser(access_token));
  }, []);

  React.useEffect(() => {
    if (internal_all_data) {
      setRowApproval(internal_all_data);
    }
  }, [internal_all_data]);

  React.useEffect(() => {
    setArpprovalOne(car_approvalData?.approvalCashAdvance[0]?.user?.id);
    setArpprovalTwo(car_approvalData?.approvalCashAdvance[1]?.user?.id);
    setArpprovalThree(car_approvalData?.approvalCashAdvance[2]?.user?.id);
  }, [car_approvalData]);
  // end set approval car

  React.useEffect(() => {
    if (car_sendMessage === 'Save Cash Advance Success') {
      router.push('/cash-report');
      dispacth(actions.setBlankSave());
    } else if(car_sendMessage === 'The given data was invalid.'){
      setType('error');
      setShow(true);
      setNotifText('The given data was invalid.');
    }

  }, [car_sendMessage]);

  const getRowApproval = (key) => {

    const mapApproval = {
      'a': [aprrovalTwo, aprrovalThree],
      'b': [aprrovalOne, aprrovalThree],
      'c': [aprrovalOne, aprrovalTwo],
    }

    if(!isEmpty(mapApproval[key]) && isArray(rowApproval)){
      return rowApproval.filter(e => !mapApproval[key].includes(e.id))
    }

    return rowApproval

  }
  
  return (
    <>
      <CustomizedSnackbars
        show={show}
        handleClose={handleClose}
        message={notifText}
        type={type}
      />
      <ModalUpload
        open={showModalUpload}
        handleCloseModalUpload={() => setShowModalUpload(false)}
        onPress={() => setShowModalConfirmUpload(true)}
        imageColletion={imageColletion}
        handleRemoveFile={handleRemoveFile}
        onFileChange={onFileChange}
      />
      <ModalConfirmUpload
        open={showModalConfirmUpload}
        handleCloseModalConfirmUpload={() => setShowModalConfirmUpload(false)}
        onPress={handleUploadFile}
        uploadPercent={percent}
        isLoading={uploadLoading}
        title="CAR"
      />
      <Grid md={12} className={classes.paperContainer}>
        <Grid md={12} className={classes.wrapperHeader}>
          <Typography className={classes.headerTitle}>
            Create CA Reports
          </Typography>
        </Grid>

        <Box className={classes.sizeBorder} />
        <Grid md={12} container>
          <Grid md={8} item className={classes.wrapperLeftBody}>
            <Requestor data={data} />
            <Information
              setStartPeriode={setStartPeriode}
              setEndPeriode={setEndPeriode}
              subject={subject}
              amount={amount}
              description={description}
              handleOnchangeDesc={handleOnchangeDesc}
              handleOnchangeSubject={handleOnchangeSubject}
              handleOnchangeAmount={handleOnchangeAmount}
              errorSubject={errorSubject}
              errorAmount={errorAmount}
              errorStartPeriode={errorStartPeriode}
              errorEndPeriode={errorEndPeriode}
              errorDescription={errorDescription}
            />
            <Typography className={classes.titleAttach}>Attachment</Typography>
            {isEmpty(temporaryImage) ? 
            (<Box className={classes.AttachmentNotif}>
              <Typography
                className={classes.AttachmentMessage}
                variant="body1"
                component="p"
              >
                No document attached.
              </Typography>
              {/* <Box>
                <img src={Cross} alt="image not found" />
              </Box> */}
            </Box>) : null }
            {temporaryImage?.length < 1 ? (
              <Box className={classes.btnUploadContent}>
                <Box
                  className={classes.btnUpload}
                  onClick={() => setShowModalUpload(true)}
                >
                  <Box>
                    <img
                      className={classes.bellImage}
                      src={UploadBtn}
                      alt="image not found"
                    />
                  </Box>
                  <Typography
                    className={classes.btnUploadValue}
                    variant="body1"
                    component="p"
                  >
                    Attach File
                  </Typography>
                </Box>
              </Box>
            ) : (
              temporaryImage?.map((file, index) => (
                <>
                  <Box key={index} className={classes.wrapperLink}>
                    <Box
                      onClick={() => handleSeeImage(file?.url)}
                      className={classes.wrapperLink}
                    >
                      <Box>
                        <img src={Paper} alt="image not found" />
                      </Box>
                      <Typography
                        className={classes.bodyUrlText}
                        variant="body1"
                        component="p"
                      >
                        {`${file.file_name} ${formatFileCount(file?.size)} MB`}
                      </Typography>
                    </Box>
                    {index + 1 === temporaryImage.length ? (
                      <Box
                        className={classes.btnAddMoreUpload}
                        onClick={() => setShowModalUpload(true)}
                      >
                        <img
                          style={{ marginRight: 8 }}
                          src={CirclePlus}
                          alt="image not found"
                        />
                        <Typography className={classes.bodyDataTextBlue}>
                          Add More Document
                        </Typography>
                      </Box>
                    ) : null}
                  </Box>
                  <Box className={classes.sizeSubLink} />
                </>
              ))
            )}
          </Grid>
          <Grid md={4} item className={classes.wrapperRightBody}>
            {/* Assigmnet apprival */}
            <Box className={classes.assignApprovalContent}>
              <Box className={classes.assignHeader}>
                <Typography
                  className={classes.headerSelectTitle}
                  variant="body1"
                  component="p"
                >
                  Assign Approval
                </Typography>
              </Box>
              <Box className={classes.filterActifityContent}>
                <Box>
                  <Box className={classes.bodyContent}>
                    <Typography
                      className={classes.bodyTitle}
                      variant="body1"
                      component="p"
                    >
                      Approval 1
                    </Typography>
                  </Box>
                  {internal_all_IsLoading ? (
                      <Skeleton variant="text" width={'100%'} />
                    ) : (
                  <SelectFilter
                    masterData={internal_all_data}
                    data={getRowApproval('a')}
                    defaultValue={aprrovalOne}
                    onChange={handleChangeApprovalOne}
                  />)}
                </Box>
                <Box className={classes.sizeBox} />
                <Box>
                  <Box className={classes.bodyContent}>
                    <Typography
                      className={classes.bodyTitle}
                      variant="body1"
                      component="p"
                    >
                      Approval 2
                    </Typography>
                  </Box>
                  {internal_all_IsLoading ? (
                      <Skeleton variant="text" width={'100%'} />
                    ) : (
                      <SelectFilter
                        masterData={internal_all_data}
                        data={getRowApproval('b')}
                        defaultValue={aprrovalTwo}
                        onChange={handleChangeApprovalTwo}
                      />
                    )}
                </Box>
                <Box className={classes.sizeBox} />
                <Box>
                  <Box className={classes.bodyContent}>
                    <Typography
                      className={classes.bodyTitle}
                      variant="body1"
                      component="p"
                    >
                      Approval 3
                    </Typography>
                  </Box>
                  {internal_all_IsLoading ? (
                      <Skeleton variant="text" width={'100%'} />
                    ) : (
                      <SelectFilter
                        masterData={internal_all_data}
                        defaultValue={aprrovalThree}
                        data={getRowApproval('c')}
                        onChange={handleChangeApprovalThree}
                      />
                    )}
                </Box>
              </Box>
              <Box className={classes.wrapperBtnContent}>
                <Box
                  className={classes.BtnConfirm}
                  onClick={handleConfirmAssigment}
                >
                  <Typography
                    className={classes.BtnConfirmText}
                    variant="body1"
                    component="p"
                  >
                    Confirm Assignment
                  </Typography>
                </Box>
                {car_sendIsLoading ? (
                  <Box className={classes.loadingContent}>
                    <CircularProgress />
                  </Box>
                ) : null}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.sizeBorder} />
        {/* <Grid md={12} className={classes.wrapperBtnCancel}>
          <Box className={classes.bntCancel}>
            <Typography className={classes.bntCancelText}>
              Cancel CAR
            </Typography>
          </Box>
        </Grid> */}
      </Grid>
      <Box className={classes.sizeBorder} />
      <Grid md={12}>
        <HistoryTableDetail showHistory={false} data={[]} />
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    backgroundColor: '#fff',
    borderRadius: 8,
  },
  wrapperHeader: {
    padding: 20,
  },
  headerTitle: {
    fontSize: 18,
    lineHeight: '27px',
    color: '#969BAB',
    fontWeight: 'bold',
  },
  headerSelectTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  sizeBorder: {
    borderBottom: '1px solid #E7E7E8',
  },
  wrapperLeftBody: {
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 20,
    borderRight: '1px solid #E7E7E8',
  },
  wrapperRightBody: {
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 20,
  },
  titleAttach: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    fontWeight: 'bold',
  },
  AttachmentNotif: {
    marginTop: 20,
    marginBottom: 20,
    padding: 10,
    borderRadius: 4,
    backgroundColor: '#FFE9E8',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  AttachmentMessage: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#F12B2C',
  },
  btnUploadContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  btnUploadText: {
    fontSize: 14,
    lineHeight: '26px',
    color: '#1B2030',
  },
  btnUpload: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: '#127EEE',
    paddingLeft: 19,
    paddingRight: 19,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 8,
  },
  btnUploadValue: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
    marginLeft: 13,
  },
  filterActifityContent: {
    marginTop: 20,
  },
  bodyTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
    marginBottom: 12,
  },
  // styling Form select
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 5,
    paddingBottom: 5,
    // maxWidth: 220,
    width: '100%',
    // minWidth: 160,
    height: 50,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      // marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#969BAB',
      paddingRight: 2,
      backgroundColor: '#F8FAFB',
    },
  },
  sizeBox: {
    marginTop: 20,
  },
  boxDepartment: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textTransform: 'ellipsis',
    maxWidth: 320,
    paddingRight: 20,
  },
  bodyDataSubText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    marginLeft: 4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  BtnConfirm: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 14.5,
    paddingBottom: 14.5,
    backgroundColor: '#1B2030',
    borderRadius: 8,
    maxWidth: 193,
    marginTop: 20,
    cursor: 'pointer',
  },
  BtnConfirmFalse: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 14.5,
    paddingBottom: 14.5,
    backgroundColor: '#969BAB',
    borderRadius: 8,
    maxWidth: 193,
    marginTop: 20,
    cursor: 'pointer',
  },
  BtnConfirmText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
  },
  wrapperBtnCancel: {
    padding: 20,
  },
  bntCancel: { paddingTop: 6, paddingBottom: 6, cursor: 'pointer' },
  bntCancelText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#FD453A',
  },
  wrapperLink: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
  },
  bodyUrlText: {
    marginLeft: 10,
    fontSize: 16,
    lineHeight: '21px',
    textDecorationLine: 'underline',
    color: '#1B2030',
  },
  btnAddMoreUpload: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: 15,
    cursor: 'pointer',
  },
  bodyDataTextBlue: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#127EEE',
    fontWeight: 'bold',
  },
  sizeSubLink: {
    marginTop: 15,
  },
  wrapperBtnContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  loadingContent: {
    paddingTop: 15,
    marginLeft: 20,
  },
}));

export default CreateForm;
