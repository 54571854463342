import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BaseImage from '../../assets/Images/bgBase.png';

const Layout = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};

const useStyles = makeStyles({
  root: {
    // backgroundColor: '#F3F5F6',
    // backgroundColor: 'red',
    height: '100%',
  },
});

export default Layout;
