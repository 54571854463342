import React from 'react';
import { Grid, makeStyles, Box, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Skeleton from '@material-ui/lab/Skeleton';
import DashboardTableStatus from '../DashboardTableStatus';
import { formatRupiah } from '../../utils/formatter';

// Svg
import { DownArrow } from '../../assets/Icons/svg/DownArrow';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

//css
import './style.css'

const DashboardTableMenu = ({
  index,
  setIndex,
  deviceType,
  _renderBoxMenu,
  monthFilter,
  departementFilter,
  entityFilter,
  handleChange,
  handleChangeDepartement,
  handleChangeEntity,
  entity_data,
  department_data,
  isLoading,
  dashboard_pr,
  dashboard_po,
  dashboard_grpo,
  dashboard_invoice,
  dashboard_apdp,
  dashboard_op,
}) => {
  const classes = useStyles();

  const { data } = useSelector(state => state.auth)
  const perm = get(data, ['user', 'user_page_permission'], {})

  const _renderBackground = (index) => {
    if (index === 1) {
      return classes.wrapperBanner;
    } else if (index === 2) {
      return classes.wrapperBannerPO;
    } else if (index === 3) {
      return classes.wrapperBannerGRPO;
    } else if (index === 4) {
      return classes.wrapperBannerInvoice;
    } else if (index === 5) {
      return classes.wrapperBannerAPDP;
    } else {
      return classes.wrapperBannerOP;
    }
  };
  const _renderBackgroundRight = (index) => {
    if (index === 1) {
      return deviceType === 'mobile'
        ? classes.wrapperBannerRightMobile
        : classes.wrapperBannerRight;
    } else if (index === 2) {
      return deviceType === 'mobile'
        ? classes.wrapperBannerRightMobile
        : classes.wrapperBannerRightPO;
    } else if (index === 3) {
      return deviceType === 'mobile'
        ? classes.wrapperBannerRightMobile
        : classes.wrapperBannerRightGRPO;
    } else if (index === 4) {
      return deviceType === 'mobile'
        ? classes.wrapperBannerRightMobile
        : classes.wrapperBannerRightInvoice;
    } else if (index === 5) {
      return deviceType === 'mobile'
        ? classes.wrapperBannerRightMobile
        : classes.wrapperBannerRightAPDP;
    } else {
      return deviceType === 'mobile'
        ? classes.wrapperBannerRightMobile
        : classes.wrapperBannerRightOP;
    }
  };

  const _renderCircleMobile = (index) => {
    if (index === 1) {
      return classes.elipsMobile;
    } else if (index === 2) {
      return classes.elipsMobilePO;
    } else if (index === 3) {
      return classes.elipsMobileGRPO;
    } else if (index === 4) {
      return classes.elipsMobileAPINVOICE;
    } else if (index === 5) {
      return classes.elipsMobileAPDP;
    } else {
      return classes.elipsMobileOP;
    }
  };

  const _renderTotalTitle = (index) => {
    if (index === 1) {
      return dashboard_pr?.total?.qty ?? '-';
    } else if (index === 2) {
      return dashboard_po?.total?.qty ?? '-';
    } else if (index === 3) {
      return dashboard_grpo?.total?.qty ?? '-';
    } else if (index === 4) {
      return dashboard_invoice?.total?.qty ?? '-';
    } else if (index === 5) {
      return dashboard_apdp?.total?.qty ?? '-';
    } else {
      return dashboard_op?.total?.qty ?? '-';
    }
  };

  const _renderAmountTitle = (index) => {
    if (index === 1) {
      return formatRupiah(dashboard_pr?.total?.amount ?? '0');
    } else if (index === 2) {
      return formatRupiah(dashboard_po?.total?.amount ?? '0');
    } else if (index === 3) {
      return formatRupiah(dashboard_grpo?.total?.amount ?? '0');
    } else if (index === 4) {
      return formatRupiah(dashboard_invoice?.total?.amount ?? '0');
    } else if (index === 5) {
      return formatRupiah(dashboard_apdp?.total?.amount ?? '0');
    } else {
      return formatRupiah(dashboard_op?.total?.amount ?? '0');
    }
  };

  const _renderTotalStatus = (index, title) => {
    if (index === 1) {
      if (title === 'Need') return dashboard_pr?.total_inprogress?.qty ?? '-';
      if (title === 'Open') return dashboard_pr?.total_open?.qty ?? '-';
      if (title === 'Closed') return dashboard_pr?.total_closed?.qty ?? '-';
      if (title === 'Approve') return dashboard_pr?.total_approved?.qty ?? '-';
    } else if (index === 2) {
      if (title === 'Need') return dashboard_po?.total_inprogress?.qty ?? '-';
      if (title === 'Open') return dashboard_po?.total_open?.qty ?? '-';
      if (title === 'Closed') return dashboard_po?.total_closed?.qty ?? '-';
      if (title === 'Approve') return dashboard_po?.total_approved?.qty ?? '-';
    } else if (index === 3) {
      if (title === 'Need') return dashboard_grpo?.total_inprogress?.qty ?? '-';
      if (title === 'Open') return dashboard_grpo?.total_open?.qty ?? '-';
      if (title === 'Closed') return dashboard_grpo?.total_closed?.qty ?? '-';
      if (title === 'Approve')
        return dashboard_grpo?.total_approved?.qty ?? '-';
    } else if (index === 4) {
      if (title === 'Need')
        return dashboard_invoice?.total_inprogress?.qty ?? '-';
      if (title === 'Open') return dashboard_invoice?.total_open?.qty ?? '-';
      if (title === 'Closed')
        return dashboard_invoice?.total_closed?.qty ?? '-';
      if (title === 'Approve')
        return dashboard_invoice?.total_approved?.qty ?? '-';
    } else if (index === 5) {
      if (title === 'Need') return dashboard_apdp?.total_inprogress?.qty ?? '-';
      if (title === 'Open') return dashboard_apdp?.total_open?.qty ?? '-';
      if (title === 'Closed') return dashboard_apdp?.total_closed?.qty ?? '-';
      if (title === 'Approve')
        return dashboard_apdp?.total_approved?.qty ?? '-';
    } else {
      if (title === 'Need') return dashboard_op?.total_inprogress?.qty ?? '-';
      if (title === 'Open') return dashboard_op?.total_open?.qty ?? '-';
      if (title === 'Closed') return dashboard_op?.total_closed?.qty ?? '-';
      if (title === 'Approve') return dashboard_op?.total_approved?.qty ?? '-';
    }
  };

  const _renderTotalAmount = (index, title) => {
    if (index === 1) {
      if (title === 'Need')
        return formatRupiah(dashboard_pr?.total_inprogress?.amount_idr ?? '0');
      if (title === 'Open')
        return formatRupiah(dashboard_pr?.total_open?.amount_idr ?? '0');
      if (title === 'Closed')
        return formatRupiah(dashboard_pr?.total_closed?.amount_idr ?? '0');
      if (title === 'Approve')
        return formatRupiah(dashboard_pr?.total_approved?.amount_idr ?? '0');
    } else if (index === 2) {
      if (title === 'Need')
        return formatRupiah(dashboard_po?.total_inprogress?.amount_idr ?? '0');
      if (title === 'Open')
        return formatRupiah(dashboard_po?.total_open?.amount_idr ?? '0');
      if (title === 'Closed')
        return formatRupiah(dashboard_po?.total_closed?.amount_idr ?? '0');
      if (title === 'Approve')
        return formatRupiah(dashboard_po?.total_approved?.amount_idr ?? '0');
    } else if (index === 3) {
      if (title === 'Need')
        return formatRupiah(
          dashboard_grpo?.total_inprogress?.amount_idr ?? '0'
        );
      if (title === 'Open')
        return formatRupiah(dashboard_grpo?.total_open?.amount_idr ?? '0');
      if (title === 'Closed')
        return formatRupiah(dashboard_grpo?.total_closed?.amount_idr ?? '0');
      if (title === 'Approve')
        return formatRupiah(dashboard_grpo?.total_approved?.amount_idr ?? '0');
    } else if (index === 4) {
      if (title === 'Need')
        return formatRupiah(
          dashboard_invoice?.total_inprogress?.amount_idr ?? '0'
        );
      if (title === 'Open')
        return formatRupiah(dashboard_invoice?.total_open?.amount_idr ?? '0');
      if (title === 'Closed')
        return formatRupiah(dashboard_invoice?.total_closed?.amount_idr ?? '0');
      if (title === 'Approve')
        return formatRupiah(
          dashboard_invoice?.total_approved?.amount_idr ?? '0'
        );
    } else if (index === 5) {
      if (title === 'Need')
        return formatRupiah(
          dashboard_apdp?.total_inprogress?.amount_idr ?? '0'
        );
      if (title === 'Open')
        return formatRupiah(dashboard_apdp?.total_open?.amount_idr ?? '0');
      if (title === 'Closed')
        return formatRupiah(dashboard_apdp?.total_closed?.amount_idr ?? '0');
      if (title === 'Approve')
        return formatRupiah(dashboard_apdp?.total_approved?.amount_idr ?? '0');
    } else {
      if (title === 'Need')
        return formatRupiah(dashboard_op?.total_inprogress?.amount_idr ?? '');
      if (title === 'Open')
        return formatRupiah(dashboard_op?.total_open?.amount_idr ?? '0');
      if (title === 'Closed')
        return formatRupiah(dashboard_op?.total_closed?.amount_idr ?? '0');
      if (title === 'Approve')
        return formatRupiah(dashboard_op?.total_approved?.amount_idr ?? '0');
    }
  };

  const _renderTotalDOLAR = (index, title) => {
    if (index === 1) {
      if (title === 'Need')
        return dashboard_pr?.total_inprogress?.qty_usd ?? '-';
      if (title === 'Open') return dashboard_pr?.total_open?.qty_usd ?? '-';
      if (title === 'Closed') return dashboard_pr?.total_closed?.qty_usd ?? '-';
      if (title === 'Approve')
        return dashboard_pr?.total_approved?.qty_usd ?? '-';
    } else if (index === 2) {
      if (title === 'Need')
        return dashboard_po?.total_inprogress?.qty_usd ?? '-';
      if (title === 'Open') return dashboard_po?.total_open?.qty_usd ?? '-';
      if (title === 'Closed') return dashboard_po?.total_closed?.qty_usd ?? '-';
      if (title === 'Approve')
        return dashboard_po?.total_approved?.qty_usd ?? '-';
    } else if (index === 3) {
      if (title === 'Need')
        return dashboard_grpo?.total_inprogress?.qty_usd ?? '-';
      if (title === 'Open') return dashboard_grpo?.total_open?.qty_usd ?? '-';
      if (title === 'Closed')
        return dashboard_grpo?.total_closed?.qty_usd ?? '-';
      if (title === 'Approve')
        return dashboard_grpo?.total_approved?.qty_usd ?? '-';
    } else if (index === 4) {
      if (title === 'Need')
        return dashboard_invoice?.total_inprogress?.qty_usd ?? '-';
      if (title === 'Open')
        return dashboard_invoice?.total_open?.qty_usd ?? '-';
      if (title === 'Closed')
        return dashboard_invoice?.total_closed?.qty_usd ?? '-';
      if (title === 'Approve')
        return dashboard_invoice?.total_approved?.qty_usd ?? '-';
    } else if (index === 5) {
      if (title === 'Need')
        return dashboard_apdp?.total_inprogress?.qty_usd ?? '-';
      if (title === 'Open') return dashboard_apdp?.total_open?.qty_usd ?? '-';
      if (title === 'Closed')
        return dashboard_apdp?.total_closed?.qty_usd ?? '-';
      if (title === 'Approve')
        return dashboard_apdp?.total_approved?.qty_usd ?? '-';
    } else {
      if (title === 'Need')
        return dashboard_op?.total_inprogress?.qty_usd ?? '-';
      if (title === 'Open') return dashboard_op?.total_open?.qty_usd ?? '-';
      if (title === 'Closed') return dashboard_op?.total_closed?.qty_usd ?? '-';
      if (title === 'Approve')
        return dashboard_op?.total_approved?.qty_usd ?? '-';
    }
  };

  const _renderTotalAmountDOLAR = (index, title) => {
    if (index === 1) {
      if (title === 'Need')
        return dashboard_pr?.total_inprogress?.amount_usd ?? '-';
      if (title === 'Open') return dashboard_pr?.total_open?.amount_usd ?? '-';
      if (title === 'Closed')
        return dashboard_pr?.total_closed?.amount_usd ?? '-';
      if (title === 'Approve')
        return dashboard_pr?.total_approved?.amount_usd ?? '-';
    } else if (index === 2) {
      if (title === 'Need')
        return dashboard_po?.total_inprogress?.amount_usd ?? '-';
      if (title === 'Open') return dashboard_po?.total_open?.amount_usd ?? '-';
      if (title === 'Closed')
        return dashboard_po?.total_closed?.amount_usd ?? '-';
      if (title === 'Approve')
        return dashboard_po?.total_approved?.amount_usd ?? '-';
    } else if (index === 3) {
      if (title === 'Need')
        return dashboard_grpo?.total_inprogress?.amount_usd ?? '-';
      if (title === 'Open')
        return dashboard_grpo?.total_open?.amount_usd ?? '-';
      if (title === 'Closed')
        return dashboard_grpo?.total_closed?.amount_usd ?? '-';
      if (title === 'Approve')
        return dashboard_grpo?.total_approved?.amount_usd ?? '-';
    } else if (index === 4) {
      if (title === 'Need')
        return dashboard_invoice?.total_inprogress?.amount_usd ?? '-';
      if (title === 'Open')
        return dashboard_invoice?.total_open?.amount_usd ?? '-';
      if (title === 'Closed')
        return dashboard_invoice?.total_closed?.amount_usd ?? '-';
      if (title === 'Approve')
        return dashboard_invoice?.total_approved?.amount_usd ?? '-';
    } else if (index === 5) {
      if (title === 'Need')
        return dashboard_apdp?.total_inprogress?.amount_usd ?? '-';
      if (title === 'Open')
        return dashboard_apdp?.total_open?.amount_usd ?? '-';
      if (title === 'Closed')
        return dashboard_apdp?.total_closed?.amount_usd ?? '-';
      if (title === 'Approve')
        return dashboard_apdp?.total_approved?.amount_usd ?? '-';
    } else {
      if (title === 'Need')
        return dashboard_op?.total_inprogress?.amount_usd ?? '-';
      if (title === 'Open') return dashboard_op?.total_open?.amount_usd ?? '-';
      if (title === 'Closed')
        return dashboard_op?.total_closed?.amount_usd ?? '-';
      if (title === 'Approve')
        return dashboard_op?.total_approved?.amount_usd ?? '-';
    }
  };

  return (
    <>
      <Grid
        md={12}
        sm={12}
        xs={12}
        container
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid xs={12} sm={12} md={8}>
          <Box
            className={
              deviceType === 'mobile'
                ? classes.wrapperTabMobile
                : classes.wrapperTab
            }
          >
            { get(perm, 'pr') ? (
              <>
            <Box
              className={index === 1 ? classes.tabActive : classes.tab}
              onClick={() => setIndex(1)}
            >
              <Typography
                className={
                  index === 1 ? classes.tabTextActive : classes.tabText
                }
                variant="body1"
                component="p"
              >
                PR
              </Typography>
            </Box>
            <Box className={classes.sizeLeft} /></>): null}
            { get(perm, 'po') ? (
              <>
            <Box
              className={index === 2 ? classes.tabActive : classes.tab}
              onClick={() => setIndex(2)}
            >
              <Typography
                className={
                  index === 2 ? classes.tabTextActive : classes.tabText
                }
                variant="body1"
                component="p"
              >
                PO
              </Typography>
            </Box>
            <Box className={classes.sizeLeft} /></>) : null}
            { get(perm, 'grpo') ? (
              <>
            <Box
              className={index === 3 ? classes.tabActive : classes.tab}
              onClick={() => setIndex(3)}
            >
              <Typography
                className={
                  index === 3 ? classes.tabTextActive : classes.tabText
                }
                variant="body1"
                component="p"
              >
                GRPO
              </Typography>
            </Box>
            <Box className={classes.sizeLeft} /></>) : null}
            { get(perm, 'ap_invoice') ? (
              <>
            <Box
              className={index === 4 ? classes.tabActive : classes.tab}
              onClick={() => setIndex(4)}
            >
              <Typography
                className={
                  index === 4 ? classes.tabTextActive : classes.tabText
                }
                variant="body1"
                component="p"
                style={{ whiteSpace: 'nowrap' }}
              >
                A/P Invoice
              </Typography>
            </Box>
            <Box className={classes.sizeLeft} /></>): null}
            { get(perm, 'ap_dp') ? (
              <>
            <Box
              className={index === 5 ? classes.tabActive : classes.tab}
              onClick={() => setIndex(5)}
            >
              <Typography
                className={
                  index === 5 ? classes.tabTextActive : classes.tabText
                }
                variant="body1"
                component="p"
              >
                APDP
              </Typography>
            </Box>
            <Box className={classes.sizeLeft} /></>): null}
            { get(perm, 'op') ? (
            <Box
              className={index === 6 ? classes.tabActive : classes.tab}
              onClick={() => setIndex(6)}
            >
              <Typography
                className={
                  index === 6 ? classes.tabTextActive : classes.tabText
                }
                variant="body1"
                component="p"
              >
                OP
              </Typography>
            </Box>): null}
          </Box>
        </Grid>
        {deviceType === 'mobile' ? (
          <>
            <Grid container xs={12}>
              <Grid xs={6} style={{ paddingRight: 5 }}>
                <FormControl className={classes.formControl}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={monthFilter}
                    onChange={handleChange}
                    IconComponent={() => <DownArrow />}
                  >
                    <MenuItem value={'today'}>Today</MenuItem>
                    <MenuItem value={'yesterday'}>Yesterday</MenuItem>
                    <MenuItem value={'this_month'}>This Month</MenuItem>
                    <MenuItem value={'last_month'}>Last Month</MenuItem>
                    <MenuItem value={'last_3month'}>Last 3 Month</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={6} style={{ paddingLeft: 5 }}>
                <FormControl className={classes.formControl}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={departementFilter}
                    onChange={handleChangeDepartement}
                    IconComponent={() => <DownArrow />}
                  >
                    <MenuItem value={'All_department'}>All Department</MenuItem>
                    {department_data?.map((dep, index) => (
                      <MenuItem key={index} value={dep.id}>
                        <Box className={classes.boxDepartment}>
                          <Typography
                            className={classes.bodyDataText}
                            variant="body1"
                            component="p"
                          >
                            {dep.code}
                          </Typography>
                          <Typography
                            className={classes.bodyDataSubText}
                            variant="body1"
                            component="p"
                          >
                            {`- ${dep.name}`}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12} style={{ marginTop: 10 }}>
                <FormControl className={classes.formControl}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={entityFilter}
                    onChange={handleChangeEntity}
                    IconComponent={() => <DownArrow />}
                  >
                    <MenuItem value={'All_entity'}>All Entity</MenuItem>
                    {entity_data?.map((ent, index) => (
                      <MenuItem key={index} value={ent.id}>
                        <Typography
                          className={classes.bodyDataText}
                          variant="body1"
                          component="p"
                        >
                          {ent.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid xs={12} md={4} sm={12} className="filter-dashboard">
            <Box className={classes.wrapperFilter}>
              <FormControl className={classes.formControl}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={monthFilter}
                  onChange={handleChange}
                  IconComponent={() => <DownArrow />}
                >
                  <MenuItem value={'today'}>Today</MenuItem>
                  <MenuItem value={'yesterday'}>Yesterday</MenuItem>
                  <MenuItem value={'this_month'}>This Month</MenuItem>
                  <MenuItem value={'last_month'}>Last Month</MenuItem>
                  <MenuItem value={'last_3month'}>Last 3 Month</MenuItem>
                </Select>
              </FormControl>
              <Box className={classes.sizeSelect} />
              <FormControl className={classes.formControl}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={departementFilter}
                  onChange={handleChangeDepartement}
                  IconComponent={() => <DownArrow />}
                >
                  <MenuItem value={'All_department'}>All Department</MenuItem>
                  {department_data?.map((dep, index) => (
                    <MenuItem key={index} value={dep.id}>
                      <Box className={classes.boxDepartment}>
                        <Typography
                          className={classes.bodyDataText}
                          variant="body1"
                          component="p"
                        >
                          {dep.code}
                        </Typography>
                        <Typography
                          className={classes.bodyDataSubText}
                          variant="body1"
                          component="p"
                        >
                          {`- ${dep.name}`}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box className={classes.sizeSelect} />
              <FormControl className={classes.formControl}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={entityFilter}
                  onChange={handleChangeEntity}
                  IconComponent={() => <DownArrow />}
                >
                  <MenuItem value={'All_entity'}>All Entity</MenuItem>
                  {entity_data?.map((ent, index) => (
                    <MenuItem key={index} value={ent.id}>
                      <Typography
                        className={classes.bodyDataText}
                        variant="body1"
                        component="p"
                      >
                        {ent.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        )}
      </Grid>
      <Box className={_renderBackground(index)}>
        <Grid container md={12}>
          <Grid xs={12} md={9}>
            <Box
              className={
                deviceType === 'mobile'
                  ? classes.wrapperBannerLeftMobile
                  : classes.wrapperBannerLeft
              }
            >
              <Typography
                className={
                  deviceType === 'mobile'
                    ? classes.bannerTitleMobile
                    : classes.bannerTitle
                }
                variant="body1"
                component="p"
              >
                Total
              </Typography>
              <Typography
                className={
                  deviceType === 'mobile'
                    ? classes.bannerSubTitleMobile
                    : classes.bannerSubTitle
                }
                variant="body1"
                component="p"
              >
                {_renderBoxMenu(index)}
              </Typography>
            </Box>
            {deviceType === 'mobile' ? (
              <Box className={_renderCircleMobile(index)}>
                {/* <img
                  style={{ borderTopRightRadius: 8 }}
                  src={_renderCircleMobile(index)}
                  alt="image not found"
                /> */}
              </Box>
            ) : null}
          </Grid>
          <Grid xs={12} md={3}>
            <Box className={_renderBackgroundRight(index)}>
              <Typography
                className={
                  deviceType === 'mobile'
                    ? classes.bannerTitleMobile
                    : classes.bannerTitle
                }
                variant="body1"
                component="p"
              >
                {isLoading ? (
                  <Skeleton variant="text" width={100} />
                ) : (
                  _renderTotalTitle(index)
                )}
              </Typography>
              <Typography
                className={
                  deviceType === 'mobile'
                    ? classes.bannerSubTitleMobile
                    : classes.bannerSubTitle
                }
                variant="body1"
                component="p"
              >
                {isLoading ? (
                  <Skeleton variant="text" width={120} />
                ) : (
                  `IDR ${_renderAmountTitle(index)}`
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        className={
          deviceType === 'mobile'
            ? classes.wrapperStatusMobile
            : classes.wrapperStatus
        }
      >
        <Grid md={12} container>
          <Grid xs={12} md={3}>
            <Box
              className={
                deviceType === 'mobile'
                  ? classes.wrapperColumnMobile
                  : classes.wrapperColumn
              }
            >
              <DashboardTableStatus
                title="Need"
                index={index}
                _renderNeedTotal={_renderTotalStatus}
                _renderTotalAmount={_renderTotalAmount}
                _renderTotalAmountDOLAR={_renderTotalAmountDOLAR}
                _renderTotalDOLAR={_renderTotalDOLAR}
                isLoading={isLoading}
                Skeleton={Skeleton}
              />
            </Box>
          </Grid>
          <Grid xs={12} md={3}>
            <Box
              className={
                deviceType === 'mobile'
                  ? classes.wrapperColumnMobile
                  : classes.wrapperColumn
              }
            >
              <DashboardTableStatus
                title="Open"
                index={index}
                _renderNeedTotal={_renderTotalStatus}
                _renderTotalAmount={_renderTotalAmount}
                _renderTotalAmountDOLAR={_renderTotalAmountDOLAR}
                _renderTotalDOLAR={_renderTotalDOLAR}
                isLoading={isLoading}
                Skeleton={Skeleton}
              />
            </Box>
          </Grid>
          <Grid xs={12} md={3}>
            <Box
              className={
                deviceType === 'mobile'
                  ? classes.wrapperColumnMobile
                  : classes.wrapperColumn
              }
            >
              <DashboardTableStatus
                title="Closed"
                index={index}
                _renderNeedTotal={_renderTotalStatus}
                _renderTotalAmount={_renderTotalAmount}
                _renderTotalAmountDOLAR={_renderTotalAmountDOLAR}
                _renderTotalDOLAR={_renderTotalDOLAR}
                isLoading={isLoading}
                Skeleton={Skeleton}
              />
            </Box>
          </Grid>
          <Grid xs={12} md={3}>
            <Box
              className={
                deviceType === 'mobile'
                  ? classes.wrapperColumnMobile
                  : classes.wrapperColumn
              }
            >
              <DashboardTableStatus
                title="Approve"
                index={index}
                _renderNeedTotal={_renderTotalStatus}
                _renderTotalAmount={_renderTotalAmount}
                _renderTotalAmountDOLAR={_renderTotalAmountDOLAR}
                _renderTotalDOLAR={_renderTotalDOLAR}
                isLoading={isLoading}
                Skeleton={Skeleton}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const useStyles = makeStyles({
  wrapperTab: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  wrapperTabMobile: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
    maxWidth: '100%',
    overflow: 'auto',
  },
  tab: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 9.3,
    paddingBottom: 9.3,
    borderRadius: 8,
    backgroundColor: '#fff',
    cursor: 'pointer',
  },
  tabActive: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 9.3,
    paddingBottom: 9.3,
    borderRadius: 8,
    backgroundColor: '#1B2030',
    cursor: 'pointer',
  },
  tabTextActive: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#F8FAFB',
  },
  tabText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
  },
  sizeLeft: {
    marginLeft: 17,
  },
  wrapperFilter: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  sizeSelect: {
    marginLeft: 16,
  },
  // styling Form select
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 5,
    // // paddingBottom: 5,
    // maxWidth: 220,
    width: '100%',
    // minWidth: 160,
    height: 40,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      marginTop: 4,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#969BAB',
      paddingRight: 2,
      background: 'none',
    },
  },
  wrapperBanner: {
    marginTop: 20,
    paddingLeft: 22,
    backgroundColor: '#FE7045',
    borderRadius: 9,
    position: 'relative',
  },
  wrapperBannerPO: {
    marginTop: 20,
    paddingLeft: 22,
    backgroundColor: '#3B93EE',
    borderRadius: 9,
    position: 'relative',
  },
  wrapperBannerGRPO: {
    marginTop: 20,
    paddingLeft: 22,
    backgroundColor: '#F28244',
    borderRadius: 9,
    position: 'relative',
  },
  wrapperBannerInvoice: {
    marginTop: 20,
    paddingLeft: 22,
    backgroundColor: '#9B81E4',
    borderRadius: 9,
    position: 'relative',
  },
  wrapperBannerAPDP: {
    marginTop: 20,
    paddingLeft: 22,
    backgroundColor: '#E44C43',
    borderRadius: 9,
    position: 'relative',
  },
  wrapperBannerOP: {
    marginTop: 20,
    paddingLeft: 22,
    backgroundColor: '#67C0B0',
    borderRadius: 9,
    position: 'relative',
  },
  bannerTitle: {
    fontSize: 36,
    lineHeight: '49px',
    fontWeight: 'bold',
    color: '#fff',
    zIndex: 90,
  },
  bannerTitleMobile: {
    fontSize: 26,
    lineHeight: '36px',
    fontWeight: 'bold',
    color: '#fff',
    zIndex: 99,
  },
  bannerSubTitle: {
    fontSize: 21,
    lineHeight: '32px',
    color: '#fff',
    zIndex: 99,
    position: 'relative',
  },
  bannerSubTitleMobile: {
    fontSize: 18,
    lineHeight: '27px',
    color: '#fff',
    zIndex: 99,
    position: 'relative',
  },
  wrapperBannerLeft: {
    paddingRight: 22,
    paddingTop: 23,
    paddingBottom: 85,
  },
  wrapperBannerLeftMobile: {
    paddingRight: 22,
    paddingTop: 23,
    paddingBottom: 22,
  },
  wrapperBannerRight: {
    paddingRight: 22,
    paddingTop: 23,
    paddingBottom: 85,
    clipPath: 'circle(100% at 84% 48%)',
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'flex-end',
    backgroundColor: '#FF5428',
    width: '100%',
    height: '100%',
  },
  wrapperBannerRightMobile: {
    paddingRight: 22,
    // paddingTop: 23,
    paddingBottom: 85,
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  wrapperBannerRightPO: {
    paddingRight: 22,
    paddingTop: 23,
    paddingBottom: 85,
    clipPath: 'circle(100% at 84% 48%)',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'flex-end',
    backgroundColor: '#127EEE',
    width: '100%',
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    height: '100%',
  },
  wrapperBannerRightGRPO: {
    paddingRight: 22,
    paddingTop: 23,
    paddingBottom: 85,
    clipPath: 'circle(100% at 84% 48%)',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'flex-end',
    backgroundColor: '#EB6B24',
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    width: '100%',
    height: '100%',
  },
  wrapperBannerRightInvoice: {
    paddingRight: 22,
    paddingTop: 23,
    paddingBottom: 85,
    clipPath: 'circle(100% at 84% 48%)',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'flex-end',
    backgroundColor: '#8569D3',
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    width: '100%',
    height: '100%',
  },
  wrapperBannerRightAPDP: {
    paddingRight: 22,
    paddingTop: 23,
    paddingBottom: 85,
    clipPath: 'circle(100% at 84% 48%)',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'flex-end',
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    backgroundColor: '#D62014',
    width: '100%',
    height: '100%',
  },
  wrapperBannerRightOP: {
    paddingRight: 22,
    paddingTop: 23,
    paddingBottom: 85,
    clipPath: 'circle(100% at 84% 48%)',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'flex-end',
    backgroundColor: '#55A899',
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    width: '100%',
    height: '100%',
  },

  wrapperStatus: {
    position: 'relative',
    zIndex: 99,
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: -70,
  },
  wrapperStatusMobile: {
    position: 'relative',
    zIndex: 99,
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: -55,
  },
  wrapperColumn: {
    marginLeft: 10,
    marginRight: 10,
    minHeight: 133,
    backgroundColor: '#fff',
    borderRadius: 8,
  },
  wrapperColumnMobile: {
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    minHeight: 133,
    backgroundColor: '#fff',
    borderRadius: 8,
  },
  // elipsMobile: {
  //   marginRight: 20,
  //   position: 'absolute',
  //   right: 0,
  //   marginTop: '-108px',
  // },
  elipsMobile: {
    width: 286,
    height: '80%',
    backgroundColor: '#FF5428',
    clipPath: 'circle(68.0% at 96% 5%)',
    position: 'absolute',
    right: 0,
    top: 0,
    borderTopRightRadius: 8,
  },
  elipsMobilePO: {
    width: 286,
    height: '80%',
    backgroundColor: '#127EEE',
    clipPath: 'circle(68.0% at 96% 5%)',
    position: 'absolute',
    right: 0,
    top: 0,
    borderTopRightRadius: 8,
  },
  elipsMobileGRPO: {
    width: 286,
    height: '80%',
    backgroundColor: '#E75E2A',
    clipPath: 'circle(68.0% at 96% 5%)',
    position: 'absolute',
    right: 0,
    top: 0,
    borderTopRightRadius: 8,
  },
  elipsMobileAPINVOICE: {
    width: 286,
    height: '80%',
    backgroundColor: '#8569D3',
    clipPath: 'circle(68.0% at 96% 5%)',
    position: 'absolute',
    right: 0,
    top: 0,
    borderTopRightRadius: 8,
  },
  elipsMobileAPDP: {
    width: 286,
    height: '80%',
    backgroundColor: '#D62014',
    clipPath: 'circle(68.0% at 96% 5%)',
    position: 'absolute',
    right: 0,
    top: 0,
    borderTopRightRadius: 8,
  },
  elipsMobileOP: {
    width: 286,
    height: '80%',
    backgroundColor: '#55A899',
    clipPath: 'circle(68.0% at 96% 5%)',
    position: 'absolute',
    right: 0,
    top: 0,
    borderTopRightRadius: 8,
  },
  // styling menu select
  boxDepartment: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  bodyDataSubText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    marginLeft: 4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export default DashboardTableMenu;
