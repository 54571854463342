import React from 'react';
import { makeStyles } from '@material-ui/core';

// Component
import LayoutDashboard from '../../components/LayoutDashboard';
import GRPOTableData from '../../components/GRPOTableData';

const GRPOPage = () => {
  const classes = useStyles();

  return (
    <LayoutDashboard page="Good Receipt (GRPO)">
      <div className={classes.pageContainer}>
        <GRPOTableData />
      </div>
    </LayoutDashboard>
  );
};

const useStyles = makeStyles({
  pageContainer: {
    marginBottom: 30,
    paddingTop: 97 - 24,
    minHeight: '84vh',
  },
  sizebox: {
    marginTop: 20,
  },
});

export default GRPOPage;
