import { combineReducers, applyMiddleware, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import AsyncStorage from '@callstack/async-storage';
import thunk from 'redux-thunk';

import { generalReducers } from './general/reducers';
import { authReducers } from './Auth/reducers';
import { drawerReducers } from './Drawer/reducers';
import { userManagementReducers } from './UserManagement/reducers';
import { utilityReducers } from './Utility/reducers';
import { prReducers } from './PR/reducers';
import { poReducers } from './PO/reducers';
import { grpoReducers } from './GRPO/reducers';
import { apInvoiceReducers } from './APInvoice/reducers';
import { approvalReducers } from './Approval/reducers';
import { clientVendorReducers } from './ClientVendor/reducers';
import { opReducers } from './OP/reducers';
import { apdpReducers } from './APDP/reducers';
import { proformaReducers } from './Proforma/reducers';
import { dashboardReducers } from './Dashboard/reducers';
import { carReducers } from './CAR/reducers';
import { reportReducers } from './Report/reducers';
import { settingsReducers } from './Settings/reducers';

const rootReducers = combineReducers({
  general: generalReducers,
  auth: authReducers,
  drawer: drawerReducers,
  userManagement: userManagementReducers,
  utility: utilityReducers,
  pr: prReducers,
  po: poReducers,
  grpo: grpoReducers,
  apinvoice: apInvoiceReducers,
  approval: approvalReducers,
  clientVendor: clientVendorReducers,
  op: opReducers,
  apdp: apdpReducers,
  proforma: proformaReducers,
  dashboard: dashboardReducers,
  car: carReducers,
  report: reportReducers,
  settings: settingsReducers,
});

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: ['auth'],
};

export const store = createStore(
  persistReducer(persistConfig, rootReducers),
  composeWithDevTools(applyMiddleware(thunk))
);

export const persistor = persistStore(store);
