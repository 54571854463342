import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

// images
import Upload from '../../assets/Icons/Upload.png';
import Trash from '../../assets/Icons/trash.png';
import Pluss from '../../assets/Icons/plus.png';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    paddingTop: 44,
    paddingBottom: 30,
    borderRadius: 18,
    maxWidth: 467,
    width: '100%',
  },
  notifText: {
    fontSize: 26,
    lineHeight: '36px',
    textAlign: 'center',
    color: '#000000',
    fontWeight: 'bold',
    marginBottom: 40,
  },
  textConfirm: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
  },
  borderLine: {
    marginTop: 53,
    width: '100%',
    border: '1px solid #E7E7E8',
  },
  borderLineFile: {
    marginTop: 28,
    width: '100%',
    border: '1px solid #E7E7E8',
  },
  btnContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // paddingLeft: 20,
    paddingTop: 17,
    paddingBottom: 14,
  },
  btnSubmit: {
    width: 229,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1B2030',
    borderRadius: 8,
    cursor: 'pointer',
    // marginLeft: 18,
  },
  btnSubmitText: {
    fontSize: 16,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#fff',
  },
  textFormat: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
    marginTop: 16,
    textAlign: 'center',
  },
  imageIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputFile: {
    content: 'Choose a File',
    backgroundColor: 'red',
  },
  wrapperBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
  },
  button: {
    backgroundColor: 'black',
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    padding: '12px 66px',
    borderRadius: 8,
    cursor: 'pointer',
  },
  textUploadDesc: {
    paddingLeft: 43,
    marginTop: 66,
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    fontWeight: 'bold',
  },
  customBackground: {
    marginTop: 15,
    backgroundColor: '#F8FAFB',
    marginBottom: 15,
  },
  boxFileContent: {
    marginTop: 12,
    maxHeight: 140,
    overflow: 'auto',
  },
  wrapperFilename: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 43,
    paddingRight: 43,
    marginTop: 20,
  },
  wrapperAddMore: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  textAddMore: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#127EEE',
    marginLeft: 12,
  },
  wrapperConfirm: {
    marginTop: 28,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnConfirm: {
    backgroundColor: '#1B2030',
    cursor: 'pointer',
    paddingTop: 14,
    paddingBottom: 14,
    paddingRight: 50,
    paddingLeft: 50,
    borderRadius: 8,
  },
  textConfirm: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
  },
  btnTrash: {
    cursor: 'pointer',
  },
  textFilename: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '60%',
  },
}));

export default function ModalUpload({
  open,
  onPress,
  handleCloseModalUpload,
  imageColletion,
  handleRemoveFile,
  onFileChange,
}) {
  const classes = useStyles();

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleCloseModalUpload}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {/* {imageColletion ? _renderImage() : null} */}
            <Typography
              className={classes.notifText}
              variant="body1"
              component="p"
            >
              Upload File
            </Typography>
            <div className={classes.imageIcon}>
              <img src={Upload} alt="image not found" />
            </div>
            {imageColletion?.length > 0 ? null : (
              <div className={classes.borderLine}></div>
            )}
            {imageColletion?.length > 0 ? null : (
              <Typography
                className={classes.textFormat}
                variant="body1"
                component="p"
              >
                (text, doc, docx, jpg, and png)
              </Typography>
            )}
            {imageColletion?.length > 0 ? (
              <>
                <div className={classes.customBackground}>
                  <Typography
                    className={classes.textUploadDesc}
                    variant="body1"
                    component="p"
                  >
                    Uploading File
                  </Typography>
                  <div className={classes.boxFileContent}>
                    {imageColletion?.map((img, index) => (
                      <div key={index} className={classes.wrapperFilename}>
                        <Typography className={classes.textFilename}>
                          {img.name}
                        </Typography>
                        <div
                          className={classes.btnTrash}
                          onClick={() => handleRemoveFile(index)}
                        >
                          <img src={Trash} alt="image not found" />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <form>
                  <input
                    // accept="image/*"
                    className={classes.input}
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    onChange={onFileChange}
                    multiple
                    type="file"
                  />
                  <label htmlFor="raised-button-file">
                    <div
                      className={classes.wrapperAddMore}
                      onClick={() => console.log('add more')}
                    >
                      <div>
                        <img
                          // className={classes.bellImage}
                          src={Pluss}
                          alt="image not found"
                        />
                      </div>
                      <Typography
                        className={classes.textAddMore}
                        variant="body1"
                        component="p"
                      >
                        Add more Document
                      </Typography>
                    </div>
                  </label>
                </form>
              </>
            ) : null}
            {imageColletion.length > 0 ? (
              <div className={classes.borderLineFile}></div>
            ) : null}
            {imageColletion.length > 0 ? (
              <div className={classes.wrapperConfirm}>
                <div className={classes.btnConfirm} onClick={onPress}>
                  <Typography
                    className={classes.textConfirm}
                    variant="body1"
                    component="p"
                  >
                    Confirm Attachment
                  </Typography>
                </div>
              </div>
            ) : (
              <div className={classes.wrapperBtn}>
                <form>
                  <input
                    // accept="image/*"
                    className={classes.input}
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    onChange={onFileChange}
                    multiple
                    type="file"
                  />
                  <label htmlFor="raised-button-file">
                    <div
                      className={classes.wrapperBtn}
                      // onClick={() => console.log('add more')}
                    >
                      <Typography
                        className={classes.button}
                        variant="body1"
                        component="p"
                      >
                        Choose A file
                      </Typography>
                    </div>
                  </label>
                </form>
              </div>
            )}
          </div>
        </Fade>
      </Modal>
    </>
  );
}
