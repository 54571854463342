import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, Box } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import { useRouter } from '../../utils/useRouter';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../redux/Auth/actions';
import * as actions_2 from '../../redux/Drawer/actions';
import { Link } from 'react-router-dom';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import request from 'src/utils/request';

import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Grid } from '@material-ui/core';
// Icons
import ReportOn from '../../assets/Icons/reportOn.png';
import ReportOff from '../../assets/Icons/reportOff.png';
import DashboardOn from '../../assets/Icons/dashboardOn.png';
import DashboardOff from '../../assets/Icons/dashboardOff.png';
import PrOn from '../../assets/Icons/prOn.png';
import PrOff from '../../assets/Icons/prOff.png';
import PoOn from '../../assets/Icons/poOn.png';
import PoOff from '../../assets/Icons/poOff.png';
import InvoiceOn from '../../assets/Icons/invoiceOn.png';
import InvoiceOff from '../../assets/Icons/invoiceOff.png';
import UserOn from '../../assets/Icons/userOn.png';
import UserOff from '../../assets/Icons/userOff.png';
import ApprovalOff from '../../assets/Icons/approvalOff.png';
import ApprovalOn from '../../assets/Icons/approvalOn.png';
import Menu from '../../assets/Icons/menu.png';
import Avatar from '../../assets/Images/avatar.png';
import Logo from '../../assets/Icons/logo.png';
import Indonesia from '../../assets/Icons/indonesia.png';
import MenuCross from '../../assets/Icons/menuCross.png';
import SettingOn from '../../assets/Icons/settingOn.png';
import SettingOff from '../../assets/Icons/settingOff.png';
import GrpoOn from '../../assets/Icons/grpoOn.png';
import GrpoOff from '../../assets/Icons/grpoOff.png';
import PerformaOn from '../../assets/Icons/performaOn.png';
import PerformaOff from '../../assets/Icons/performaOff.png';
import ApdpOn from '../../assets/Icons/apdpOn.png';
import ApdpOff from '../../assets/Icons/apdpOff.png';
import OpOn from '../../assets/Icons/opOn.png';
import OpOff from '../../assets/Icons/opOff.png';
import ClientOn from '../../assets/Icons/clientOn.png';
import ClientOff from '../../assets/Icons/clientOff.png';
import ProfileIcon from '../../assets/Icons/profileIcon.png';
import SettingDown from '../../assets/Icons/settingDown.png';

// Components
import DropdownUser from '../DropdownUser';
import PageItem from './components/PageItem';

//page list
import { finance, defaultPage, superAdmin } from './pageList';
import { get } from 'lodash';

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerOpenMobile: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    position: 'fixed',
    zIndex: 999,
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '89px !important',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  drawerCloseMobile: {
    overflowX: 'hidden',
    width: '0px !important',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    height: '96.5px !important',
  },
  content: {
    flexGrow: 1,
    paddingTop: 24,
    paddingLeft: 24,
    paddingRight: 24,
    // padding: theme.spacing(3),
    backgroundColor: '#F3F5F6',
    maxWidth: `calc(100% - 89px)`,
  },
  contentOpen: {
    flexGrow: 1,
    paddingTop: 24,
    paddingLeft: 24,
    paddingRight: 24,
    maxWidth: `calc(100% - ${drawerWidth}px)`,
    // padding: theme.spacing(3),
    backgroundColor: '#F3F5F6',
  },
  contentMobile: {
    flexGrow: 1,
    paddingTop: 24,
    paddingLeft: 15,
    paddingRight: 15,
    maxWidth: '99vw',
    // padding: theme.spacing(3),
    backgroundColor: '#F3F5F6',
  },
  titlePage: {
    fontSize: '16px !important',
    lineHeight: '21px !important',
    fontWeight: 'bold !important',
    color: '#1B2030 !important',
  },
  iconContentLogoOpen: {
    minWidth: 46,
    height: 80,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: -16,
    marginBottom: -16,
  },
  iconContentOpen: {
    minWidth: 46,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: -6,
    marginBottom: -6,
  },
  iconLogoContent: {
    minWidth: 89,
    height: 80,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: -16,
    marginTop: -16,
    marginBottom: -16,
  },
  iconContent: {
    // backgroundColor: 'red',
    minWidth: 89,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: -16,
    marginTop: -6,
    marginBottom: -6,
    // marginRight: 10,
  },
  iconImage: {
    height: 20,
    width: 20,
  },
  maintTop: {
    height: 96.5,
    top: 0,
    width: '90%',
    paddingTop: 3.5,
  },
  setPadding: {},
  wrapperHeader: {
    marginTop: -24,
    paddingTop: 24,
    height: 97,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    zIndex: 999999,
  },
  wrapperUser: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  avatarImage: {
    marginRight: 20,
  },
  role: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#9CA9B7',
  },
  iconLogoTextImage: {
    marginTop: 35,
    marginLeft: 10,
  },
  mainFooter: {
    height: 63,
    backgroundColor: '#fff',
    marginLeft: -24,
    marginRight: -24,
    paddingLeft: 30,
    display: 'flex',
    alignItems: 'center',
  },
  mainFooterMobile: {
    height: 63,
    backgroundColor: '#fff',
    marginLeft: -24,
    marginRight: -24,
    paddingLeft: 30,
    display: 'flex',
    alignItems: 'center',
  },
  headerContentClose: {
    paddingTop: 24,
    paddingRight: 24,
    paddingLeft: 89 + 24,
    width: '100%',
    marginTop: -24,
    position: 'fixed',
    backgroundColor: '#F3F5F6',
    zIndex: 999,
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  headerContentCloseMobile: {
    paddingTop: 24,
    paddingRight: 24,
    paddingLeft: 24,
    width: '100%',
    maxWidth: '100vw',
    marginTop: -24,
    position: 'fixed',
    backgroundColor: '#F3F5F6',
    zIndex: 999,
  },
  headerContent: {
    paddingLeft: drawerWidth + 24,
    paddingTop: 24,
    paddingRight: 24,
    width: '100%',
    marginTop: -24,
    position: 'fixed',
    backgroundColor: '#F3F5F6',
    zIndex: 999,
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  headerContentMobile: {
    // paddingLeft: drawerWidth + 24,
    paddingTop: 24,
    paddingRight: 24,
    width: '100%',
    maxWidth: '100vw',
    marginTop: -24,
    position: 'fixed',
    backgroundColor: '#F3F5F6',
    // zIndex: 9999,
  },
  logoutBtn: {
    cursor: 'pointer',
  },
  textDefault: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
  },
  linkActive: {
    borderRight: '4px solid #1B2030',
    paddingTop: 6,
    paddingBottom: 6,
  },
  linkDisable: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  hideMenu: {
    position: 'fixed',
    zIndex: 9999,
    paddingTop: 10,
    marginLeft: drawerWidth + 19,
  },
  logoSideBar: {
    position: 'absolute',
    height: 83,
  },
  listSideBar: {
    marginTop: 83,
    maxHeight: `calc(100vh - 83px)`,
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
  },
  wrapperSetting: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  arrSetting: {
    transform: 'rotate(270deg)',
  },
  dropdown: {
    width: 210,
    height: 50,
    backgroundColor: '#1B2030',
    borderTopRightRadius: 8,
    color: '#fff',
    paddingLeft: 20,
    paddingTop: 15,
    // cursor: 'pointer',
    marginTop: 100,
  },
  dropdownWhite: {
    width: 210,
    height: 50,
    backgroundColor: '#ffff',
    borderTopRightRadius: 8,
    color: '#1B2030',
    paddingLeft: 20,
    paddingTop: 15,
    cursor: 'pointer',
  },
  urlText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#F3F5F6',
  },
}));

const extraPageList = {
  masterData: {
    name: 'Master Data',
    url: '/master-data',
  },
  organize: {
    name: 'Organize',
    url: '/organize',
  },
};

const defaultPagePermission = {
  pr:0,
  po:0,
  grpo:0,
  proforma_invoice:0,
  ap_invoice:0,
  ap_dp:0,
  op:0,
  ca:0,
}

const urlmatches = {
  '/user-management': ['/edit-internal', '/edit-external', '/create-admin', '/create-internal', '/create-external']
}

const levelMatches = {
  'superadmin': ['/organize', '/master-data']
}

const mapIcon = {
  '/': {
    enableIcon: DashboardOn,
    disableIcon: DashboardOff
  },
  
  '/approval': {
    enableIcon: ApprovalOn,
    disableIcon: ApprovalOff
  },
  '/purchase-requistor': {
    enableIcon: PrOn,
    disableIcon: PrOff
  },
  '/purchase-order': {
    enableIcon: PoOn,
    disableIcon: PoOff
  },
  '/proforma-invoice': {
    enableIcon: PerformaOn,
    disableIcon: PerformaOff
  },
  '/grpo': {
    enableIcon: GrpoOn,
    disableIcon: GrpoOff
  },
  '/ap-invoice': {
    enableIcon: InvoiceOn,
    disableIcon: InvoiceOff
  },
  '/ap-downpayment': {
    enableIcon: ApdpOn,
    disableIcon: ApdpOff
  },
  '/outgoing-payment': {
    enableIcon: OpOn,
    disableIcon: OpOff
  },
  '/cash-report': {
    enableIcon: GrpoOn,
    disableIcon: GrpoOff
  },
  '/report': {
    enableIcon: ReportOn,
    disableIcon: ReportOff
  },
  '/user-management': {
    enableIcon: UserOn,
    disableIcon: UserOff
  },
  '/client-vendor': {
    enableIcon: ClientOn,
    disableIcon: ClientOff
  },

}

export default function LayoutDashboard({
  children,
  page,
  deviceType,
  Dropdown,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const router = useRouter();
  const [open, setOpen] = React.useState(true);
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [showSetting, setShowSetting] = React.useState(false);

  // handle for settings
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorOpen, setAnchorOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setAnchorOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  // end handle settings

  const [pageList, setPageList] = React.useState([]);

  // gobal Variable
  const { access_token, data, logout_Message } = useSelector(
    (state) => state.auth
  );
  const { background_color } = useSelector( state => state.settings)
  const { isOpen } = useSelector((state) => state.drawer);

  const getMenu = (list, perm) => {

    const res = []
    for(const val of list){
      const isPerm = perm[val.api_key]
      
      if(isPerm !== 0){
        res.push({...val, icon: mapIcon[val.url]})
      }
    }
    const checkPerm = urlValidationChecker(res)
    if(!checkPerm){
      router.push('/')
    }

    return res
  } 

  const validateMenu = (result) => {
    if (data?.user?.department?.name === 'Finance') {
      setPageList(getMenu(finance, result));
    } else if (data?.user?.role[0] === 'superadmin') {
      setPageList(getMenu(superAdmin, result));
    } else {
      setPageList(getMenu(defaultPage, result))
    }
  }

  React.useEffect(() => {
    validateMenu(get(data, ['user', 'user_page_permission']) || defaultPagePermission)
  }, []);

  const handleDrawer = () => {
    // setOpen(!open);
    dispatch(actions_2.setDrawer(!isOpen));
  };
  const setCloseOpen = () => {
    if (deviceType === 'mobile') {
      dispatch(actions_2.setDrawer(false));
    }
  };

  const handleLink = (route) => {
    router.push(route.url);
    setCloseOpen();
  };
  
  const handleCloseDropdown = () => {
    setShowDropdown(false);
  };

  const handleLogout = () => {
    dispatch(actions.logout(access_token));
    // router.push('/login');
  };

  function urlValidationChecker(menuList){
    const arrUrl = get(router, ['pathname'], '').split('/')
    let isGranted = false
    const role = get(data, 'user.role[0]', '')
    
    if(arrUrl.length > 1){
      const url = `/${arrUrl[1]}`
      for(const obj of menuList){
        if(url.includes(obj.url) && obj.url !== '/'){
          isGranted = true
        } else if(urlmatches[obj.url]){
          if(urlmatches[obj.url].includes(url)){
            isGranted = true
          }
        } else if(levelMatches[role]){
          if(levelMatches[role].includes(url)){
            isGranted = true
          }
        }
      }
    } else {
      isGranted = true
    }
    return isGranted
  }

  React.useState(() => {
    setShowSetting(Dropdown);
  }, []);

  React.useEffect(() => {
    if (logout_Message) {
      if (logout_Message == 'Logout') {
        router.push('/login');
      }
      dispatch(actions.setBlankLogout());
    }
  }, [logout_Message]);

  React.useEffect(() => {
    if (open) {
      setAnchorOpen(false);
    }
  }, [open]);

  return (
    <div className={classes.root}>
      <DropdownUser
        open={showDropdown}
        close={handleCloseDropdown}
        handleLogout={handleLogout}
        deviceType={deviceType}
        data={data}
      />
      <div
        className={
          isOpen
            ? deviceType === 'mobile'
              ? classes.headerContentMobile
              : classes.headerContent
            : deviceType === 'mobile'
            ? classes.headerContentCloseMobile
            : classes.headerContentClose
        }
      >
        <Grid container>
          <Grid xs={12}>
            <div className={classes.wrapperHeader}>
              <div onClick={handleDrawer} className={classes.btnDrawer}>
                <img src={Menu} alt="image not found" />
              </div>

              <div className={classes.wrapperUser}>
                <div className={classes.avatarImage}>
                  {data?.user?.avatar_base !== '' ? (
                    <img
                      style={{ width: 44, height: 44 }}
                      src={`data:image/jpeg;base64,${data?.user?.avatar_base}`}
                      alt="image not found"
                    />
                  ) : (
                    <img src={Avatar} alt="image not found" />
                  )}
                </div>

                <div
                  className={classes.logoutBtn}
                  onClick={() => setShowDropdown(true)}
                >
                  <img src={ProfileIcon} alt="image not found" />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <CssBaseline />

      {deviceType === 'mobile' && isOpen ? (
        <div className={classes.hideMenu} onClick={handleDrawer}>
          <img src={MenuCross} alt="image not found" />
        </div>
      ) : null}

      <Drawer
        variant="permanent"
        className={
          deviceType === 'mobile'
            ? clsx(classes.drawer, {
                [classes.drawerOpenMobile]: isOpen,
                [classes.drawerCloseMobile]: !isOpen,
              })
            : clsx(classes.drawer, {
                [classes.drawerOpen]: isOpen,
                [classes.drawerClose]: !isOpen,
              })
        }
        classes={{
          paper:
            deviceType === 'mobile'
              ? clsx({
                  [classes.drawerOpenMobile]: isOpen,
                  [classes.drawerCloseMobile]: !isOpen,
                })
              : clsx({
                  [classes.drawerOpen]: isOpen,
                  [classes.drawerClose]: !isOpen,
                }),
        }}
      >
        <List className={classes.logoSideBar}>
          {['logo'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon
                className={
                  isOpen ? classes.iconContentLogoOpen : classes.iconLogoContent
                }
              >
                <img
                  className={classes.iconLogoImage}
                  src={Logo}
                  alt="image not found"
                />
              </ListItemIcon>
              <div className={classes.imageText}>
                <img
                  className={classes.iconLogoTextImage}
                  src={Indonesia}
                  alt="image not found"
                />
              </div>
            </ListItem>
          ))}
        </List>
        {/* rendering list page side bar */}
        <List className={classes.listSideBar}>
          {pageList.map((value, index) => (
            <PageItem
              page={page}
              value={value}
              key={index}
              handleLink={handleLink}
              isOpen={isOpen}
              iconEnable={get(value, ['icon', 'enableIcon'])}
              iconDisbale={get(value, ['icon', 'disableIcon'])}
            />
          ))}

          {/* list setting for admin only */}
          {data?.user?.role[0].length > 0 &&
          data?.user?.role[0] === 'superadmin' ? (
            <>
              <Popper
                onBlur={() => setAnchorOpen(false)}
                open={anchorOpen}
                anchorEl={anchorEl}
                placement={placement}
                transition
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper>
                      <Box
                      // onClick={() => handleLink(text)}
                      >
                        <Typography className={classes.dropdown}>
                          {/* {text} */}
                          Settings
                        </Typography>
                        <Box className={classes.wrapperSettingList}>
                          <Box
                            onClick={() => handleLink(extraPageList.organize)}
                          >
                            <Typography className={classes.dropdownWhite}>
                              Organization
                            </Typography>
                          </Box>
                          <Box
                            onClick={() => handleLink(extraPageList.masterData)}
                          >
                            <Typography className={classes.dropdownWhite}>
                              Master Data
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Paper>
                  </Fade>
                )}
              </Popper>
              <ListItem
                className={
                  'Settings' === page ? classes.linkActive : classes.linkDisable
                }
                button
                onClick={
                  isOpen
                    ? () => setShowSetting(!showSetting)
                    : handleClick('right')
                }
                onBlur={() => setAnchorOpen(false)}
              >
                <ListItemIcon
                  className={
                    isOpen ? classes.iconContentOpen : classes.iconContent
                  }
                >
                  <img
                    className={classes.iconImage}
                    src={'Setting' === page ? SettingOn : SettingOff}
                    alt="image not found"
                  />
                </ListItemIcon>
                <Box className={classes.wrapperSetting}>
                  <Typography
                    className={
                      'Settings' === page
                        ? classes.titlePage
                        : classes.textDefault
                    }
                    variant="body1"
                    component="p"
                  >
                    Settings
                  </Typography>
                  <img
                    src={SettingDown}
                    className={showSetting ? null : classes.arrSetting}
                    alt="setting-arrow"
                  />
                </Box>
              </ListItem>
            </>
          ) : null}
          {(showSetting || !isOpen) && data?.user?.role[0] === 'superadmin' ? (
            <>
              <a
                href={extraPageList?.organize?.url}
                className="hyperlink-no-style"
                onClick={(e) => e.preventDefault()}
              >
                <ListItem
                  className={
                    'Organize' === page
                      ? classes.linkActive
                      : classes.linkDisable
                  }
                  button
                  onClick={() => handleLink(extraPageList.organize)}
                >
                  <ListItemIcon
                    className={
                      isOpen ? classes.iconContentOpen : classes.iconContent
                    }
                  ></ListItemIcon>
                  <Box className={classes.wrapperSetting}>
                    <Typography
                      className={
                        'Organize' === page
                          ? classes.titlePage
                          : classes.textDefault
                      }
                      variant="body1"
                      component="p"
                    >
                      Organization
                    </Typography>
                  </Box>
                </ListItem>
              </a>
              <a
                href={extraPageList?.masterData?.url}
                className="hyperlink-no-style"
                onClick={(e) => e.preventDefault()}
              >
                <ListItem
                  className={
                    'Master Data' === page
                      ? classes.linkActive
                      : classes.linkDisable
                  }
                  button
                  onClick={() => handleLink(extraPageList.masterData)}
                >
                  <ListItemIcon
                    className={
                      isOpen ? classes.iconContentOpen : classes.iconContent
                    }
                  ></ListItemIcon>
                  <Box className={classes.wrapperSetting}>
                    <Typography
                      className={
                        'Master Data' === page
                          ? classes.titlePage
                          : classes.textDefault
                      }
                      variant="body1"
                      component="p"
                    >
                      Master Data
                    </Typography>
                  </Box>
                </ListItem>
              </a>
            </>
          ) : null}
        </List>
      </Drawer>
      <main
        className={
          deviceType === 'mobile'
            ? classes.contentMobile
            : isOpen
            ? classes.contentOpen
            : classes.content
        }
        style={{ backgroundColor : background_color ? background_color : undefined}}
      >
        {children}
        <Grid container>
          <Grid item xs={12}>
            <div
              className={
                deviceType === 'mobile'
                  ? classes.mainFooterMobile
                  : classes.mainFooter
              }
            >
              <Typography
                className={classes.footer}
                variant="body1"
                component="p"
              >
                © DKT Indonesia 2021. All right reserved.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </main>
    </div>
  );
}
