import React from 'react';
import { makeStyles, withStyles, Typography, Box } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import BootstrapTooltip from '../../Tooltip';
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import { formatRupiah, dateFormater } from '../../../utils/formatter';

// components
import Item from '../../PDFpreview/APInvoicePreview/Item';
import Service from '../../PDFpreview/APInvoicePreview/Service';

// Icons
import Details from '../../../assets/Icons/details.png';
import EyeBlack from '../../../assets/Icons/eyeBlack.png';
import Trash from '../../../assets/Icons/trash.png';
import TrashRed from '../../../assets/Icons/trashRed.png';
import Unduh from '../../../assets/Icons/unduh.png';
import UnduhBlack from '../../../assets/Icons/unduhBlack.png';

// generate fixed table
const StickyTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#fff',
    color: theme.palette.common.white,
    right: 0,
    position: 'sticky',
    zIndex: 99,
  },
  body: {
    backgroundColor: '#fff',
    minWidth: '20px',
    width: 122,
    right: 0,
    position: 'sticky',
    zIndex: 99,
  },
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#fff',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff',
    },
  },
}))(TableRow);

export const Row = (props) => {
  const { row, index, handleDetailData, handleDelete } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  // configure to handle show Pdf Preview
  const [showPDFpreview, setShowPDFpreview] = React.useState(false);
  const [showPDFServie, setShowPDFService] = React.useState(false);

  // handle action hover icon
  const [isTrashHover, setIsTrashHover] = React.useState(false);
  const [isUnduhHover, setIsUnduhHover] = React.useState(false);
  const [isDetailHover, setIsDetailHover] = React.useState(false);

  const _renderStatus = (status) => {
    if (status === 'open') {
      return <div className={classes.statusOpen}>{status}</div>;
    } else if (status === 'cancelled' || status === 'closed') {
      return <div className={classes.statusClosed}>{status}</div>;
    } else if (status === 'rejected') {
      return <div className={classes.statusReject}>{status}</div>;
    } else if (status === 'need_confirm') {
      return <div className={classes.statusNeed}>CONFIRM APPROVAL</div>;
    } else if (status === 'waiting') {
      return <div className={classes.statusWating}>WAITING APPROVAL</div>;
    } else if (status === 'approval_in_progress') {
      return (
        <div className={classes.statusApproval}>Approval in Progress </div>
      );
    } else {
      return <div className={classes.statusApprove}>{status}</div>;
    }
  };

  // render status
  const _renderStatusLabel = (approve, reject) => {
    if (approve === 1 && reject === 0) {
      return <Box className={classes.statusApprove}>Approved</Box>;
    } else if (approve === 0 && reject === 1) {
      return <Box className={classes.statusReject}>Reject</Box>;
    } else {
      return <Box className={classes.statusWating}>Waiting Approval</Box>;
    }
  };

  const handleUnduhAction = (qr_url, id) => {
    if (qr_url) {
      window.open(qr_url);
    } else {
      if (row?.data?.doc_type === 'service') {
        setShowPDFService(true);
      } else {
        setShowPDFpreview(true);
      }
      // handleDetailData(id);
    }
  };

  return (
    <React.Fragment>
      <Item
        open={showPDFpreview}
        close={() => setShowPDFpreview(false)}
        data={row?.data}
      />
      <Service
        open={showPDFServie}
        close={() => setShowPDFService(false)}
        data={row?.data}
      />
      <StyledTableRow
        className={
          index % 2 == 0
            ? classes.tableContentData
            : classes.tableContentDataDua
        }
      >
        <StyledTableCell style={{ width: 80 }}>
          {row.invoiceNum}
        </StyledTableCell>
        <StyledTableCell style={{ width: 198 }}>
          <div className={classes.textnName} onClick={() => setOpen(!open)}>
            {row.status !== 'need_confirm' ? (
              <BootstrapTooltip
                arrow={false}
                title="Click to see history"
                placement="bottom-start"
              >
                <Typography>{row?.vendorName}</Typography>
              </BootstrapTooltip>
            ) : (
              <Typography>{row?.vendorName}</Typography>
            )}
          </div>
        </StyledTableCell>
        <StyledTableCell style={{ width: 80 }}>{row.noGrpo}</StyledTableCell>
        <StyledTableCell style={{ width: 80 }}>{row.noPO}</StyledTableCell>

        <StyledTableCell style={{ width: 132 }} align="center">
          <div>{row.department}</div>
        </StyledTableCell>

        <StyledTableCell style={{ width: 94 }} align="center">
          <div>{row.entity}</div>
        </StyledTableCell>
        <StyledTableCell style={{ width: 135 }}>
          <div>{row.activity}</div>
        </StyledTableCell>
        <StyledTableCell style={{ width: 135 }}>
          <div>{row.category}</div>
        </StyledTableCell>
        <StyledTableCell style={{ width: 135 }} align="center">
          <div>{row.currency}</div>
        </StyledTableCell>
        <StyledTableCell style={{ width: 138 }}>
          <div>
            {row.currency === 'IDR'
              ? formatRupiah(row.total ?? '0')
              : row.total}
          </div>
        </StyledTableCell>
        <StyledTableCell style={{ width: 160 }}>
          {_renderStatus(row.status)}
        </StyledTableCell>
        <StyledTableCell style={{ width: 160 }}>
          {dateFormater(row.date, 'DD-MM-YYYY HH:mm')}
        </StyledTableCell>

        <StickyTableCell style={{ width: 80 }}>
          <StyledTableCell numeric align="right" className={classes.cell}>
            <div className={classes.iconAction}>
              <div
                className={classes.Action}
                onClick={() => handleDetailData(row.invoiceId)}
                onMouseEnter={() => setIsDetailHover(true)}
                onMouseLeave={() => setIsDetailHover(false)}
              >
                <BootstrapTooltip title="View Details">
                  <img
                    src={isDetailHover ? EyeBlack : Details}
                    alt="image not found"
                  />
                </BootstrapTooltip>
              </div>
              <div
                className={classes.Action}
                onClick={() => handleDelete(row.invoiceId)}
                onMouseEnter={() => setIsTrashHover(true)}
                onMouseLeave={() => setIsTrashHover(false)}
              >
                <BootstrapTooltip title="Delete">
                  <img
                    src={isTrashHover ? TrashRed : Trash}
                    alt="image not found"
                  />
                </BootstrapTooltip>
              </div>
              <div
                className={classes.Action}
                onMouseEnter={() => setIsUnduhHover(true)}
                onMouseLeave={() => setIsUnduhHover(false)}
                onClick={() => handleUnduhAction(row.qr_url, row.prId)}
              >
                <BootstrapTooltip
                  title={row.qr_url ? 'Donwload' : 'Preview Detail'}
                >
                  <img
                    src={isUnduhHover ? UnduhBlack : Unduh}
                    alt="image not found"
                  />
                </BootstrapTooltip>
              </div>
            </div>
          </StyledTableCell>
        </StickyTableCell>
      </StyledTableRow>
      {row.status !== 'need_confirm' ? (
        <TableRow className={classes.tableAccordion}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell width="40%">
                        <div className={classes.tab}>
                          <Typography
                            className={classes.textName}
                            variant="body1"
                            component="p"
                          >
                            {row?.approval_one?.name ?? '-'}
                          </Typography>
                          <Typography
                            className={classes.textSubName}
                            variant="body1"
                            component="p"
                          >
                            - {row?.approval_one?.position ?? '-'}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell width="20%">
                        <div className={classes.tabApproval}>
                          {_renderStatusLabel(
                            row?.approval_one?.approve,
                            row?.approval_one?.reject
                          )}
                        </div>
                      </TableCell>
                      <TableCell width="6%"></TableCell>
                      <TableCell width="30%">
                        <div className={classes.tabDate}>
                          {dateFormater(
                            row?.approval_one?.date ?? Date.now(),
                            'DD-MM-YYYY hh:mm'
                          )}
                        </div>
                      </TableCell>
                      <StickyTableCell
                        style={{
                          width: 122,
                          paddingRight: 6,
                          backgroundColor: '#E6ECEF',
                        }}
                      >
                        <StyledTableCell
                          numeric
                          align="right"
                          className={classes.cell}
                          style={{ backgroundColor: '#E6ECEF' }}
                        ></StyledTableCell>
                      </StickyTableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </React.Fragment>
  );
};

const useRowStyles = makeStyles((theme) => ({
  // cutome table
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  head: {
    backgroundColor: 'black',
    minWidth: '50px',
  },
  tableContainer: {
    maxWidth: '100%',
  },
  cell: {
    minWidth: '100px',
  },
  titleTableContent: {
    paddingRight: 22,
    paddingLeft: 22,
    paddingTop: 27,
    paddingBottom: 27,
    backgroundColor: '#ffff',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleTable: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  contentRigth: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  btnAsnyc: {
    backgroundColor: '#1B2030',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '14px 20px',
    borderRadius: 8,
    cursor: 'pointer',
  },
  btnExport: {
    backgroundColor: '#127EEE',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '14px 20px',
    borderRadius: 8,
    cursor: 'pointer',
  },
  btnText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
    marginLeft: 20,
  },
  notifContent: {
    marginRight: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  notifText: {
    fontWeight: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#1B2030',
    marginLeft: 13,
  },
  filterContent: {
    padding: '16px 20px',
    borderTop: '1px solid #E7E7E8',
    borderBottom: '1px solid #E7E7E8',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filterOption: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  dateRangeContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
  },
  dateRangeIcon: {
    height: 40,
    width: 58,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E7E7E8',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  dateRangeText: {
    paddingLeft: 10,
    backgroundColor: '#F8FAFB',
    height: 40,
    width: 258,
    paddingTop: 9.5,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  formControl: {
    width: 227,
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    paddingLeft: 20,
    paddingRight: 17,
    '&.MuiFormControl-root .MuiInput-underline::before': {
      border: 'none',
      transition: 'none',
    },
  },
  filterStatusContent: {
    marginLeft: 10,
  },
  filterActifityContent: {
    marginLeft: 10,
  },
  iconAction: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  Action: {
    cursor: 'pointer',
  },
  tableContentData: {
    backgroundColor: '#F8FAFB',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    border: 'none',
    borderBottom: 'none',
    '&.MuiTableRow-root .MuiTableCell-root.MuiTableCell-body': {
      borderBottom: 'none',
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 10,
      paddingBottom: 10,
    },
    '&.MuiTableRow-root .MuiTableCell-sizeSmall': {
      paddingRight: 0,
      borderBottom: 'none',
    },
  },
  tableContentDataDua: {
    backgroundColor: '#fff',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    border: 'none',
    borderBottom: 'none',
    '&.MuiTableRow-root .MuiTableCell-sizeSmall': {
      paddingRight: 0,
      borderBottom: 'none',
    },
    '&.MuiTableRow-root .MuiTableCell-root.MuiTableCell-body': {
      borderBottom: 'none',
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  tableAccordion: {
    backgroundColor: '#E6ECEF',
    '&.MuiTableRow-root .MuiTableCell-root.MuiTableCell-body': {
      borderBottom: 'none',
      paddingLeft: 80,
      paddingRight: 0,
    },
    '& .MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeSmall': {
      borderBottom: 'none',
    },
  },
  tab: {
    paddingTop: 11.5,
    paddingBottom: 11.5,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  textName: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
  },
  textSubName: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    marginLeft: 4,
  },
  tabDate: {
    // marginLeft: 60,
    marginRight: -60,
    paddingRight: 0,
    paddingTop: 11.5,
    paddingBottom: 11.5,
  },
  tabApproval: {
    paddingLeft: 10,
    paddingTop: 11.5,
    paddingBottom: 11.5,
  },
  statusOpen: {
    backgroundColor: '#127EEE',
    color: '#fff',
    textAlign: 'center',
    borderRadius: 100,
    // maxWidth: 58,
    width: 150,
  },
  statusClosed: {
    backgroundColor: '#969BAB',
    width: 150,
    // maxWidth: 90,
    textAlign: 'center',
    borderRadius: 100,
    textTransform: 'uppercase',
    color: '#fff',
    fontSize: 12,
    lineHeight: '26px',
    fontWeight: 'bold',
  },
  statusCancel: {
    backgroundColor: '#969BAB',
    color: '#fff',
    textAlign: 'center',
    borderRadius: 100,
    // maxWidth: 96,
    width: 150,
  },
  statusReject: {
    backgroundColor: '#F12B2C',
    width: 150,
    // maxWidth: 73,
    textAlign: 'center',
    borderRadius: 100,
    textTransform: 'uppercase',
    color: '#fff',
    fontSize: 12,
    lineHeight: '26px',
    fontWeight: 'bold',
  },
  statusApprove: {
    backgroundColor: '#27AE60',
    width: 150,
    // maxWidth: 90,
    textAlign: 'center',
    borderRadius: 100,
    textTransform: 'uppercase',
    color: '#fff',
    fontSize: 12,
    lineHeight: '26px',
    fontWeight: 'bold',
  },
  statusApproval: {
    backgroundColor: '#27AE60',
    width: 150,
    // maxWidth: 73,
    backgroundColor: '#FFB800',
    textAlign: 'center',
    borderRadius: 100,
    textTransform: 'uppercase',
    color: '#fff',
    fontSize: 12,
    lineHeight: '26px',
    fontWeight: 'bold',
  },
  statusNeed: {
    width: 150,
    // maxWidth: 150,
    backgroundColor: '#F12B2C',
    textAlign: 'center',
    borderRadius: 100,
    textTransform: 'uppercase',
    color: '#fff',
    fontSize: 12,
    lineHeight: '26px',
    fontWeight: 'bold',
  },
  statusWating: {
    width: 150,
    // maxWidth: 150,
    backgroundColor: '#FFB800',
    textAlign: 'center',
    borderRadius: 100,
    textTransform: 'uppercase',
    color: '#fff',
    fontSize: 12,
    lineHeight: '26px',
    fontWeight: 'bold',
  },
  textnName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    // maxWidth: 200,
    cursor: 'pointer',
  },
  textnNameNoPointer: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    // maxWidth: 200,
  },
}));
