// get permission
export const GET_PERMISSION_LOADING = 'SETTINGS/GET_PERMISSION_LOADING';
export const GET_PERMISSION_DATA = 'SETTINGS/GET_PERMISSION_DATA';

//setColor
export const SET_BACKGROUND_COLOR = 'SETTINGS/SET_BACKGROUND_COLOR';

// create  roles
export const CREATE_ROLE_LOADING = 'SETTINGS/CREATE_ROLE_LOADING';
export const CREATE_ROLE_MESSAGE = 'SETTINGS/CREATE_ROLE_MESSAGE';

// Delete roles
export const DELETE_ROLE_LOADING = 'SETTINGS/DELETE_ROLE_LOADING';
export const DELETE_ROLE_MESSAGE = 'SETTINGS/DELETE_ROLE_MESSAGE';

// get roles
export const GET_ROLES_LOADING = 'SETTINGS/GET_ROLES_LOADING';
export const GET_ROLES_DATA = 'SETTINGS/GET_ROLES_DATA';

// get detail roles
export const DETAIL_ROLES_LOADING = 'SETTINGS/DETAIL_ROLES_LOADING';
export const DETAIL_ROLES_DATA = 'SETTINGS/DETAIL_ROLES_DATA';

// update roles
export const UPDATE_ROLE_LOADING = 'SETTINGS/UPDATE_ROLE_LOADING';
export const UPDATE_ROLE_MESSAGE = 'SETTINGS/UPDATE_ROLE_MESSAGE';

// get all entities
export const GET_ENTITY_LOADING = 'SETTINGS/GET_ENTITY_LOADING';
export const GET_ENTITY_DATA = 'SETTINGS/GET_ENTITY_DATA';

// create entities
export const CREATE_ENTITY_LOADING = 'SETTINGS/CREATE_ENTITY_LOADING';
export const CREATE_ENTITY_MESSAGE = 'SETTINGS/CREATE_ENTITY_MESSAGE';

// detele entitie
export const DELETE_ENTITY_LOADING = 'SETTINGS/DELETE_ENTITY_LOADING';
export const DELETE_ENTITY_MESSAGE = 'SETTINGS/DELETE_ENTITY_MESSAGE';

// get detail
export const DETAIL_ENTITY_LOADING = 'SETTINGS/DETAIL_ENTITY_LOADING';
export const DETAIL_ENTITY_DATA = 'SETTINGS/DETAIL_ENTITY_DATA';

// get all department
export const GET_DEPARTMENT_LOADING = 'SETTINGS/GET_DEPARTMENT_LOADING';
export const GET_DEPARTMENT_DATA = 'SETTINGS/GET_DEPARTMENT_DATA';

// create department
export const CREATE_DEPARTMENT_LOADING = 'SETTINGS/CREATE_DEPARTMENT_LOADING';
export const CREATE_DEPARTMENT_MESSAGE = 'SETTINGS/CREATE_DEPARTMENT_MESSAGE';

// delete department
export const DELETE_DEPARTMENT_LOADING = 'SETTINGS/DELETE_DEPARTMENT_LOADING';
export const DELETE_DEPARTMENT_MESSAGE = 'SETTINGS/DELETE_DEPARTMENT_MESSAGE';

// get detail department
export const DETAIL_DEPARTMENT_LOADING = 'SETTINGS/DETAIL_DEPARTMENT_LOADING';
export const DETAIL_DEPARTMENT_DATA = 'SETTINGS/DETAIL_DEPARTMENT_DATA';

// get department by entity id
export const GET_DEPARTMENT_ID_LOADING = 'SETTINGS/GET_DEPARTMENT_ID_LOADING';
export const GET_DEPARTMENT_ID_DATA = 'SETTINGS/GET_DEPARTMENT_ID_DATA';

// postion create
export const CREATE_POSITION_LOADING = 'SETTINGS/CREATE_POSITION_LOADING';
export const CREATE_POSITION_MESSAGE = 'SETTINGS/CREATE_POSITION_MESSAGE';

// get position all
export const GET_POSITION_LOADING = 'SETTINGS/GET_POSITION_LOADING';
export const GET_POSITION_DATA = 'SETTINGS/GET_POSITION_DATA';

// delete position
export const DELETE_POSITION_LOADING = 'SETTINGS/DELETE_POSITION_LOADING';
export const DELETE_POSITION_MESSAGE = 'SETTINGS/DELETE_POSITION_MESSAGE';

// detail position
export const DETAIL_POSITION_LOADING = 'SETTINGS/DETAIL_POSITION_LOADING';
export const DETAIL_POSITION_DATA = 'SETTINGS/DETAIL_POSITION_DATA';

//########################## master data
// save checklist data
export const CREATE_CHECKLIST_LOADING = 'SETTINGS/CREATE_CHECKLIST_LOADING';
export const CREATE_CHECKLIST_MESSAGE = 'SETTINGS/CREATE_CHECKLIST_MESSAGE';

// get all checklist
export const GET_CHECKLIST_LOADING = 'SETTINGS/GET_CHECKLIST_LOADING';
export const GET_CHECKLIST_DATA = 'SETTINGS/GET_CHECKLIST_DATA';

// get checklist by id
export const DETAIL_CHECKLIST_LOADING = 'SETTINGS/DETAIL_CHECKLIST_LOADING';
export const DETAIL_CHECKLIST_DATA = 'SETTINGS/DETAIL_CHECKLIST_DATA';

// delete checklist
export const DELETE_CHECKLIST_LOADING = 'SETTINGS/DELETE_CHECKLIST_LOADING';
export const DELETE_CHECKLIST_MESSAGE = 'SETTINGS/DELETE_CHECKLIST_MESSAGE';

// update checklist
export const UPDATE_CHECKLIST_LOADING = 'SETTINGS/UPDATE_CHECKLIST_LOADING';
export const UPDATE_CHECKLIST_MESSAGE = 'SETTINGS/UPDATE_CHECKLIST_MESSAGE';

// create activitie
export const CREATE_ACTIVITIES_LOADING = 'SETTINGS/CREATE_ACTIVITIES_LOADING';
export const CREATE_ACTIVITIES_MESSAGE = 'SETTINGS/CREATE_ACTIVITIES_MESSAGE';

// get all activities
export const GET_ACTIVITIES_LOADING = 'SETTINGS/GET_ACTIVITIES_LOADING';
export const GET_ACTIVITIES_DATA = 'SETTINGS/GET_ACTIVITIES_DATA';

// delete activities
export const DELETE_ACTIVITIES_LOADING = 'SETTINGS/DELETE_ACTIVITIES_LOADING';
export const DELETE_ACTIVITIES_MESSAGE = 'SETTINGS/DELETE_ACTIVITIES_MESSAGE';

// get activities by id
export const DETAIL_ACTIVITIES_LOADING = 'SETTINGS/DETAIL_ACTIVITIES_LOADING';
export const DETAIL_ACTIVITIES_DATA = 'SETTINGS/DETAIL_ACTIVITIES_DATA';

// update activities
export const UPDATE_ACTIVITIES_LOADING = 'SETTINGS/UPDATE_ACTIVITIES_LOADING';
export const UPDATE_ACTIVITIES_MESSAGE = 'SETTINGS/UPDATE_ACTIVITIES_MESSAGE';

// get all Report
export const GET_REPORT_LOADING = 'SETTINGS/GET_REPORT_LOADING';
export const GET_REPORT_DATA = 'SETTINGS/GET_REPORT_DATA';

// get report by id
export const DETAIL_REPORT_LOADING = 'SETTINGS/DETAIL_REPORT_LOADING';
export const DETAIL_REPORT_DATA = 'SETTINGS/DETAIL_REPORT_DATA';

// update report
export const UPDATE_REPORT_LOADING = 'SETTINGS/UPDATE_REPORT_LOADING';
export const UPDATE_REPORT_MESSAGE = 'SETTINGS/UPDATE_REPORT_MESSAGE';

// get all distributor group code
export const GET_DISTRIBUTOR_LOADING = 'SETTINGS/GET_DISTRIBUTOR_LOADING';
export const GET_DISTRIBUTOR_DATA = 'SETTINGS/GET_DISTRIBUTOR_DATA';

// create distributor
export const CREATE_DISTRIBUTOR_LOADING = 'SETTINGS/CREATE_DISTRIBUTOR_LOADING';
export const CREATE_DISTRIBUTOR_MESSAGE = 'SETTINGS/CREATE_DISTRIBUTOR_MESSAGE';

// detele distributor
export const DELETE_DISTRIBUTOR_LOADING = 'SETTINGS/DELETE_DISTRIBUTOR_LOADING';
export const DELETE_DISTRIBUTOR_MESSAGE = 'SETTINGS/DELETE_DISTRIBUTOR_MESSAGE';

// get distributor by id
export const DETAIL_DISTRIBUTOR_LOADING = 'SETTINGS/DETAIL_DISTRIBUTOR_LOADING';
export const DETAIL_DISTRIBUTOR_DATA = 'SETTINGS/DETAIL_DISTRIBUTOR_DATA';

// update distributor
export const UPDATE_DISTRIBUTOR_LOADING = 'SETTINGS/UPDATE_DISTRIBUTOR_LOADING';
export const UPDATE_DISTRIBUTOR_MESSAGE = 'SETTINGS/UPDATE_DISTRIBUTOR_MESSAGE';

// get all supplier group code
export const GET_SUPPLIER_LOADING = 'SETTINGS/GET_SUPPLIER_LOADING';
export const GET_SUPPLIER_DATA = 'SETTINGS/GET_SUPPLIER_DATA';

// create supplier
export const CREATE_SUPPLIER_LOADING = 'SETTINGS/CREATE_SUPPLIER_LOADING';
export const CREATE_SUPPLIER_MESSAGE = 'SETTINGS/CREATE_SUPPLIER_MESSAGE';

// delete supplier
export const DELETE_SUPPLIER_LOADING = 'SETTINGS/DELETE_SUPPLIER_LOADING';
export const DELETE_SUPPLIER_MESSAGE = 'SETTINGS/DELETE_SUPPLIER_MESSAGE';

// get supplier by id
export const DETAIL_SUPPLIER_LOADING = 'SETTINGS/DETAIL_SUPPLIER_LOADING';
export const DETAIL_SUPPLIER_DATA = 'SETTINGS/DETAIL_SUPPLIER_DATA';

// update supplier
export const UDPATE_SUPPLIER_LOADING = 'SETTINGS/UDPATE_SUPPLIER_LOADING';
export const UPDATE_SUPPLIER_MESSAGE = 'SETTINGS/UPDATE_SUPPLIER_MESSAGE';

// creat client category
export const CREATE_CLIENT_CATEGORY_LOADING =
  'SETTINGS/CREATE_CLIENT_CATEGORY_LOADING';
export const CREATE_CLIENT_CATEGORY_MESSAGE =
  'SETTINGS/CREATE_CLIENT_CATEGORY_MESSAGE';

// get all client category
export const GET_CLIENT_CATEGORY_LOADING =
  'SETTINGS/GET_CLIENT_CATEGORY_LOADING';
export const GET_CLIENT_CATEGORY_DATA = 'SETTINGS/GET_CLIENT_CATEGORY_DATA';

// delete client category
export const DELETE_CLIENT_CATEGORY_LOADING =
  'SETTINGS/DELETE_CLIENT_CATEGORY_LOADING';
export const DELETE_CLIENT_CATEGORY_MESSAGE =
  'SETTINGS/DELETE_CLIENT_CATEGORY_MESSAGE';

// get detail client category
export const DETAIL_CLIENT_CATEGORY_LOADING =
  'SETTINGS/DETAIL_CLIENT_CATEGORY_LOADING';
export const DETAIL_CLIENT_CATEGORY_DATA =
  'SETTINGS/DETAIL_CLIENT_CATEGORY_DATA';

// update client category
export const UPDATE_CLIENT_CATEGORY_LOADING =
  'SETTINGS/UPDATE_CLIENT_CATEGORY_LOADING';
export const UPDATE_CLIENT_CATEGORY_MESSAGE =
  'SETTINGS/UPDATE_CLIENT_CATEGORY_MESSAGE';

// create purchase Type
export const CREATE_PURCHASE_TYPE_LOADING =
  'SETTINGS/CREATE_PURCHASE_TYPE_LOADING';
export const CREATE_PURCHASE_TYPE_MESSAGE =
  'SETTINGS/CREATE_PURCHASE_TYPE_MESSAGE';

// get all puchase type
export const GET_PURCHASE_TYPE_LOADING = 'SETTINGS/GET_PURCHASE_TYPE_LOADING';
export const GET_PURCHASE_TYPE_DATA = 'SETTINGS/GET_PURCHASE_TYPE_DATA';

//  delete purchase type
export const DELETE_PURCHASE_TYPE_LOADING =
  'SETTINGS/DELETE_PURCHASE_TYPE_LOADING';
export const DELETE_PURCHASE_TYPE_MESSAGE =
  'SETTINGS/DELETE_PURCHASE_TYPE_MESSAGE';

// get detail purcahse type
export const DETAIL_PURCHASE_TYPE_LOADING =
  'SETTINGS/DETAIL_PURCHASE_TYPE_LOADING';
export const DETAIL_PURCHASE_TYPE_DATA = 'SETTINGS/DETAIL_PURCHASE_TYPE_DATA';

// update puchase detail
export const UPDATE_PURCHASE_TYPE_LOADING =
  'SETTINGS/UPDATE_PURCHASE_TYPE_LOADING';
export const UPDATE_PURCHASE_TYPE_MESSAGE =
  'SETTINGS/UPDATE_PURCHASE_TYPE_MESSAGE';
