import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';

// images
import Upload from '../../assets/Icons/Upload.png';
import Trash from '../../assets/Icons/trash.png';
import Pluss from '../../assets/Icons/plus.png';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    paddingTop: 44,
    paddingBottom: 30,
    borderRadius: 18,
    width: 467,
  },
  notifText: {
    fontSize: 26,
    lineHeight: '36px',
    textAlign: 'center',
    color: '#000000',
    fontWeight: 'bold',
    marginBottom: 40,
  },
  textConfirm: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
  },
  borderLine: {
    marginTop: 53,
    width: '100%',
    border: '1px solid #E7E7E8',
  },
  borderLineFile: {
    marginTop: 28,
    width: '100%',
    border: '1px solid #E7E7E8',
  },
  btnContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // paddingLeft: 20,
    paddingTop: 17,
    paddingBottom: 14,
  },
  btnSubmit: {
    width: 229,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1B2030',
    borderRadius: 8,
    cursor: 'pointer',
  },

  btnSubmitText: {
    fontSize: 16,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#fff',
  },
  textFormat: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
    marginTop: 16,
    textAlign: 'center',
  },
  imageIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputFile: {
    content: 'Choose a File',
    backgroundColor: 'red',
  },
  wrapperBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
  },
  button: {
    backgroundColor: 'black',
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    padding: '12px 66px',
    borderRadius: 8,
    cursor: 'pointer',
  },
  textUploadDesc: {
    paddingLeft: 43,
    marginTop: 66,
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    fontWeight: 'bold',
  },
  customBackground: {
    paddingTop: 15,
    backgroundColor: '#F8FAFB',
    paddingBottom: 15,
  },
  wrapperFilename: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 43,
    paddingRight: 43,
    marginTop: 20,
  },
  wrapperAddMore: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  textAddMore: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#127EEE',
    marginLeft: 12,
  },
  wrapperConfirm: {
    marginTop: 28,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnConfirm: {
    backgroundColor: '#1B2030',
    cursor: 'pointer',
    paddingTop: 14,
    paddingBottom: 14,
    paddingRight: 50,
    paddingLeft: 50,
    borderRadius: 8,
  },
  btnSubmitFalse: {
    paddingTop: 14,
    paddingBottom: 14,
    paddingRight: 50,
    paddingLeft: 50,
    borderRadius: 8,
    backgroundColor: '#969BAB',
  },
  textConfirm: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
  },
  btnTrash: {
    cursor: 'pointer',
  },
  wrapperFileUploading: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  wrapperLoadingContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: 20,
  },
  textLoading: {
    fontSize: 10,
    lineHeight: '26px',
    color: '#969BAB',
  },
  barLoading: {
    minWidth: 80,
    marginRight: 8,
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: '#fff',
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#1B2030',
    },
  },
}));

export default function ModalSingleUpload({
  open,
  onPress,
  setModalConfirm,
  imageColletion,
  handleRemoveFile,
  onFileChange,
  close,
  isLoading,
  uploadPercent,
}) {
  const classes = useStyles();
  // const [imageColletion, setImageCollection] = React.useState('');

  // const onFileChange = (e) => {
  //   setImageCollection([e.target.files]);
  // };

  // const handleRemoveFile = (indexOf) => {
  //   let filteImage = imageColletion.filter((img, index) => index !== indexOf);
  //   // setImageCollection()
  //   // console.log(filteImage);
  //   setImageCollection(filteImage);
  // };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={close}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {/* {imageColletion ? _renderImage() : null} */}
            <Typography
              className={classes.notifText}
              variant="body1"
              component="p"
            >
              Upload File
            </Typography>
            {/* {console.log(imageColletion)} */}
            <div className={classes.imageIcon}>
              <img src={Upload} alt="image not found" />
            </div>
            {imageColletion?.length > 0 ? null : (
              <div className={classes.borderLine}></div>
            )}
            {imageColletion?.length > 0 ? null : (
              <Typography
                className={classes.textFormat}
                variant="body1"
                component="p"
              >
                (text, doc, docx, jpg, and png)
              </Typography>
            )}
            {imageColletion?.length > 0 ? (
              <>
                <div className={classes.customBackground}>
                  <Typography
                    className={classes.textUploadDesc}
                    variant="body1"
                    component="p"
                  >
                    Uploading File
                  </Typography>
                  {imageColletion?.map((img, index) => (
                    <>
                      <div className={classes.wrapperFilename}>
                        <Typography variant="body1" component="p">
                          {img.name}
                        </Typography>
                        <div className={classes.wrapperFileUploading}>
                          {isLoading ? (
                            <div className={classes.wrapperLoadingContent}>
                              <div className={classes.barLoading}>
                                <LinearProgress
                                  variant="determinate"
                                  value={uploadPercent}
                                />
                              </div>
                              <Typography className={classes.textLoading}>
                                {`${uploadPercent} %`}
                              </Typography>
                            </div>
                          ) : null}
                          <div
                            className={classes.btnTrash}
                            onClick={() => handleRemoveFile(index)}
                          >
                            <img src={Trash} alt="image not found" />
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </>
            ) : null}
            {imageColletion?.length > 0 ? (
              <div className={classes.borderLineFile}></div>
            ) : null}
            {imageColletion?.length > 0 ? (
              <div className={classes.wrapperConfirm}>
                <div
                  className={
                    isLoading ? classes.btnSubmitFalse : classes.btnConfirm
                  }
                  onClick={isLoading ? null : onPress}
                >
                  <Typography
                    className={classes.textConfirm}
                    variant="body1"
                    component="p"
                  >
                    Confirm Attachment
                  </Typography>
                </div>
              </div>
            ) : (
              <div className={classes.wrapperBtn}>
                <form>
                  <input
                    accept="image/*"
                    className={classes.input}
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    onChange={onFileChange}
                    type="file"
                  />
                  <label htmlFor="raised-button-file">
                    <div
                      className={classes.wrapperBtn}
                      // onClick={() => console.log('add more')}
                    >
                      <Typography
                        className={classes.button}
                        variant="body1"
                        component="p"
                      >
                        Choose A file
                      </Typography>
                    </div>
                  </label>
                </form>
              </div>
            )}
          </div>
        </Fade>
      </Modal>
    </>
  );
}
