import * as types from './types';

// Just Example
export const userManagementReducers = (
  state = {
    internal_data: null,
    internalMessage: '',
    internalCreateData: null,
    internalCreatMessage: '',
    internalCreateLoading: false,
    internal_all_IsLoading: false,
    internal_all_data: null,
    admin_data: null,
    isAdminLoading: false,
    createIsLoading: false,
    adminCreateData: null,
    adminCreateMessage: '',
    isInternalLoading: false,
    adminProfileData: null,
    adminProfileLoading: false,
    adminProfileMessage: '',
    message: '',
    isLoading: false,
    externalIsLoading: false,
    externalData: null,
    externalMessage: '',
    externalUserData: null,
    externalUserIsloading: false,
    externalUserMessage: '',
    del_externalLoading: false,
    del_externalMessage: '',
    externalProfile: null,
    externalProfileLoading: false,
    externalProfileMessage: '',
  },
  action
) => {
  switch (action.type) {
    case types.SET_INTERNAL_USER:
      return {
        ...state,
        internal_data: action.payload,
      };
    case types.SET_INTERNAL_LOADING:
      return {
        ...state,
        isInternalLoading: action.payload,
      };
    // get all internal for approval list
    case types.GET_ALL_INTERNAL_LOADING:
      return {
        ...state,
        internal_all_IsLoading: action.payload,
      };
    case types.GET_ALL_INTERNAL_DATA:
      return {
        ...state,
        internal_all_data: action.payload,
      };

    case types.SET_INTERNAL_MESSAGE:
      return {
        ...state,
        internalMessage: action.payload,
      };

    case types.SET_ADMIN_USER:
      return {
        ...state,
        admin_data: action.payload,
      };
    case types.SET_ADMIN_LOADING:
      return {
        ...state,
        isAdminLoading: action.payload,
      };
    // create adminuser
    case types.SET_ADMIN_CREATE_LOADING:
      return {
        ...state,
        createIsLoading: action.payload,
      };
    case types.SET_ADMIN_CREATE_DATA:
      return {
        ...state,
        adminCreateData: action.payload,
      };
    case types.SET_ADMIN_MESSAGE:
      return {
        ...state,
        adminCreateMessage: action.payload,
      };
    // edit admin user
    case types.SET_ADMIN_PROFILE:
      return {
        ...state,
        adminProfileData: action.payload,
      };
    case types.SET_ADMIN_PROFILE_MESSAGE:
      return {
        ...state,
        adminProfileMessage: action.payload,
      };
    case types.SET_ADMIN_PROFILE_LOADING:
      return {
        ...state,
        adminProfileLoading: action.payload,
      };
    // creat internal user
    case types.SET_INTERNAL_CREATE_LOADING:
      return {
        ...state,
        internalCreateLoading: action.payload,
      };
    case types.SET_INTERNAL_CREATE_DATA:
      return {
        ...state,
        internalCreateData: action.payload,
      };
    case types.SET_INTERNAL_CREATE_MESSAGE:
      return {
        ...state,
        internalCreatMessage: action.payload,
      };

    // creat external user
    case types.SET_EXTERNAL_LOADING:
      return {
        ...state,
        externalIsLoading: action.payload,
      };
    case types.SET_EXTERNAL_MESSAGE:
      return {
        ...state,
        externalMessage: action.payload,
      };
    case types.SET_EXTERNAL_DATA:
      return {
        ...state,
        externalData: action.payload,
      };

    // get external user
    case types.GET_EXTERNAL_USER_LOADING:
      return {
        ...state,
        externalUserIsloading: action.payload,
      };
    case types.GET_EXTERNAL_USER_MESSAGE:
      return {
        ...state,
        externalUserMessage: action.payload,
      };
    case types.GET_EXTERNAL_USER_DATA:
      return {
        ...state,
        externalUserData: action.payload,
      };

    // Delete external user
    case types.DEL_EXTERNAL_USER_MESSAGE:
      return {
        ...state,
        del_externalMessage: action.payload,
      };
    case types.DEL_EXTERNAL_USER_LOADING:
      return {
        ...state,
        del_externalLoading: action.payload,
      };

    // get external user
    case types.GET_EXTERNAL_DETAIL_LOADING:
      return {
        ...state,
        externalProfileLoading: action.payload,
      };
    case types.GET_EXTERNAL_DETAIL_MESSAGE:
      return {
        ...state,
        externalProfileMessage: action.payload,
      };
    case types.GET_EXTERNAL_DETAIL:
      return {
        ...state,
        externalProfile: action.payload,
      };

    // General
    case types.MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case types.LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    default:
      return state;
  }
};
