import React from 'react';
import { makeStyles, Grid, Box, Typography, Checkbox } from '@material-ui/core';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from '../../utils/useRouter';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../redux/OP/actions';
import * as utility from '../../redux/Utility/actions';
import axios from 'axios';
import { END_POINT } from '../../config/EndPoint';
import Skeleton from '@material-ui/lab/Skeleton';

import {
  dateFormater,
  formatRupiah,
  formatFileCount,
} from '../../utils/formatter';

// Components

import ButtonCancel from '../Button/ButtonCancel';
import ModalCancelPr from '../ModalCancelPr';
import ModalSuccess from '../ModalSuccess';
import ModalQRCode from '../ModalQRCode';
import ModalUpload from '../ModalUpload';
import ModalConfirm from '../ModalComfirm';
import ModalConfirmUpload from '../ModalConfirmUpload';
import ButtonMandatory from '../Button/ButtonMandatory';
import ModalSingleUpload from '../ModalSingleUpload';
import { CircleLoading } from '../Loading/CircleLoading';
import InputGroup from '../inputGroup';
import CustomizedSnackbars from '../PopupMessage';
import { CircleLoadingSecondary } from '../Loading/CircleLoading';
import TrashDelete from '../TrashDelete';
import ModalDeleteItem from '../ModalDeleteItem';
import DatePickerSingle from '../DatePickerSingle';
import PaymentItem from './components/PaymentItem';

// Icons
import EyeBlue from '../../assets/Icons/eyeBlue.png';
import Cross from '../../assets/Icons/cross.png';
import BCALogo from '../../assets/Icons/bcaLogo.png';
import DateIcon from '../../assets/Icons/date.png';
import Search from '../../assets/Icons/Search.png';
import CloseIcon from '@material-ui/icons/Close';
import UploadBtn from '../../assets/Icons/uploadBtn.png';
import UnduhSecond from '../../assets/Icons/unduhSecond.png';
import Paper from '../../assets/Icons/paper.png';
import CirclePlus from '../../assets/Icons/circlePlus.png';

// svg
import { DownArrow } from '../../assets/Icons/svg/DownArrow';
import { SortArrow } from '../../assets/Icons/svg/SortArrow';

const schema = yup.object().shape({
  // schema validator
  CekNo: yup.string().typeError('you must specify a number'),
  ReferensiNo: yup
    .string()
    .typeError('you must specify a number')
    .required('Field is Required'),
  AccountNo: yup.string().typeError('you must specify a number'),
});

const OutgoingPaymentFormData = () => {
  const classes = useStyles();
  const router = useRouter();
  const { id } = router.query;
  const dispacth = useDispatch();

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      // console.log(value, name, type)
      setDataInput(value)
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = (data) => {
    // console.log(data, 'sss');
    // console.log(bank);
    const { CekNo, ReferensiNo, AccountNo } = data;
    
    if (detail_opData?.type_of_payment === 'Bank Transfer') {
      if (!bank) {
        setType('error');
        setShow(true);
        setNotifText('Please select bank');
      } else if (!date.start) {
        setType('error');
        setShow(true);
        setNotifText('Please select Date');
      } else {
        let data = {
          payment_info_id: bank?.id,
          reff_no: ReferensiNo,
          paid_date: dateFormater(date?.start, 'YYYY-MM-DD'),
          is_notify: checked ? 1 : 0,
          // check_no: CekNo,
          account_no: AccountNo,
          bank_id: bank?.id
        };
        // console.log(data);
        setTempSendData(data);
        setShowModalConfirm(true);
      }
    } else {
      if (!date.start) {
        setType('error');
        setShow(true);
        setNotifText('Please select Date');
      } else {
        let data = {
          reff_no: ReferensiNo,
          paid_date: dateFormater(date?.start, 'YYYY-MM-DD'),
          is_notify: checked ? 1 : 0,
          check_no: CekNo,
        };
        // console.log(data);
        setTempSendData(data);
        setShowModalConfirm(true);
      }
    }
  };

  // handle message
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };

  const [date, setDate] = React.useState({
    start: null,
    end: null,
  });

  // Global Variable
  const { access_token } = useSelector((state) => state.auth);
  const {
    detail_opisLoading,
    detail_opData,
    confirm_opIsLoading,
    confirm_opMesssage,
  } = useSelector((state) => state.op);
  const {
    payment_vcIsLoading,
    payment_vc,
    all_bank_isLoading,
    all_bank_data,
    read_loading,
    read_data,
    del_file_isLoading,
    del_file_message,
  } = useSelector((state) => state.utility);

  const [dataInput, setDataInput] = React.useState(null);
  const [paymentData, setPaymentData] = React.useState(null);
  const [IsFileLoading, setIsFileLoading] = React.useState(false);
  const [fileData, setFileData] = React.useState([]);
  const [showFileData, setShowFileData] = React.useState(false);
  const [canUpload, setCanUpload] = React.useState(false);
  const [canDelete, setCanDelete] = React.useState(false);
  const [tempDeleteId, setTempDeleteId] = React.useState(0);
  const [tempSendData, setTempSendData] = React.useState(null);

  // handleshow modal
  const [showModalConfirm, setShowModalConfirm] = React.useState(false);
  const [showModalSuccess, setShowModalSuccess] = React.useState(false);
  const [showModalQRCode, setShowModalQRCode] = React.useState(false);
  const [showModalDetail, setShowModalDetail] = React.useState(false);
  const [showModalUpload, setShowModalUpload] = React.useState(false);
  const [showModalConfirmUpload, setShowModalConfirmUpload] =
    React.useState(false);
  const [showModalSingleUpload, setShowModalSingleUpload] =
    React.useState(false);
  const [showModalSuccessUpload, setShowModalSuccessUpload] =
    React.useState(false);
  const [showModalDelete, setShowModalDelete] = React.useState(false);
  const [showModalSuccesDel, setShowModalSuccessDel] = React.useState(false);

  // handle for select bank
  const [bank, setBank] = React.useState(null);
  const [isBankFocus, setIsBankFocus] = React.useState(false);
  const [showBank, setShowBank] = React.useState(false);
  const [bankData, setBankData] = React.useState(null);
  const [searchText, setSearchText] = React.useState('');

  const selectBank = (data) => {
    setBank(data);
    setIsBankFocus(false);
    setShowBank(false);
    setSearchText('');
  };

  const setDefaultBank = (detail_opData, all_bank_data) => {
    
    if(!detail_opData || !all_bank_data) return

    if(detail_opData?.bank_id){
      const obj = all_bank_data.find(e => e.id === detail_opData.bank_id)
      if(obj){
        selectBank(obj)
      }
    }

  }

  React.useEffect(() => {
    setBankData(all_bank_data);
    dispacth(actions.getOPDetail(access_token, id));

  }, [all_bank_data]);

  React.useEffect(() => {
    if (searchText !== '') {
      let TempArr = [];
      bankData?.map((vendor, index) => {
        let tempName = vendor.name.toLowerCase();
        if (tempName.includes(searchText.toLocaleLowerCase())) {
          TempArr.push(vendor);
        }
      });
      setBankData(TempArr);
    } else {
      setBankData(all_bank_data);
    }
  }, [searchText]);

  // end handle for bank select
  // configure to handle File for upload PR
  const [imageColletion, setImageCollection] = React.useState([]);
  const [uploadLoading, setUploadLoading] = React.useState(false);
  const [percent, setPercent] = React.useState(0);
  const [totalFile, setTotalFile] = React.useState(0);
  const [uploadedFile, setUploadedFile] = React.useState(0);

  const onFileChange = (e) => {
    let tempFile = e.target.files;

    // console.log(tempFile);

    setImageCollection((prev) => [...prev, ...tempFile]);
  };

  const handleRemoveFile = (indexOf) => {
    let filteImage = imageColletion.filter((img, index) => index !== indexOf);

    setImageCollection(filteImage);
  };

  const handleUploadFile = () => {
    if (imageColletion) {
      try {
        setUploadLoading(true);

        const sendData = new FormData();
        imageColletion.map((img, index) => {
          sendData.append('file[]', img);
        });

        const config = {
          headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${access_token}`,
          },
          onUploadProgress: (progressEvent) => {
            var percentComplete = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setPercent(percentComplete);
          },
        };

        axios
          .post(
            `${END_POINT}files?doc_type=${'OP'}&doc_id=${
              detail_opData?.id
            }&entity_code=${detail_opData?.entity?.code}`,
            sendData,
            config
          )
          .then((res) => {
            setPercent(0);
            setUploadLoading(false);
            setShowModalConfirmUpload(false);
            setShowModalUpload(false);
            setImageCollection([]);
            handleGETFileOP();
            setShowModalSuccessUpload(true);
          });
      } catch (error) {
        console.log('error upload file');
        setUploadLoading(false);
      }
    } else {
      console.log('chosses an image');
    }
  };

  const handleGETFileOP = () => {
    setIsFileLoading(true);
    let readData = {
      doc_type: 'OP',
      doc_id: detail_opData?.id,
      entity_code: detail_opData?.entity?.code,
    };

    dispacth(utility.readFile(access_token, readData));
  };

  React.useEffect(() => {
    if (read_data) {
      if (read_data.length > 0) {
        setShowFileData(true);
        setFileData(read_data);
        setIsFileLoading(false);
      } else {
        setShowFileData(false);
        setFileData([]);
        setIsFileLoading(false);
      }
    } else {
      setShowFileData(false);
      setFileData([]);
      setIsFileLoading(false);
    }
  }, [read_data]);

  React.useEffect(() => {
    if (del_file_message) {
      if (del_file_message === 'Delete file Success') {
        setShowModalDelete(false);
        setShowModalSuccessDel(true);
      } else if (
        del_file_message !== '' &&
        del_file_message !== 'Delete file Success'
      ) {
        setType('error');
        setShow(true);
        setNotifText(del_file_message);
      }
      dispacth(utility.setBlankDelFile());
    }
  }, [del_file_message]);

  // end configure file upload

  // handle Checkbox
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleCloseModalSuccessUpload = () => {
    setShowModalSuccessUpload(false);
  };
  const handleCloseUploading = () => {
    setShowModalUpload(false);
    setShowModalConfirmUpload(false);
    setShowModalSuccessUpload(false);
  };
  const handleCloseModalConfirm = () => {
    setShowModalConfirm(false);
  };
  const handleCloseModalSuccess = () => {
    setShowModalSuccess(false);
  };
  const handleCloseModalQRCode = () => {
    setShowModalQRCode(false);
  };
  const handleCloseModalDetail = () => {
    setShowModalDetail(false);
  };
  const handleCloseModalUpload = () => {
    setShowModalUpload(false);
  };
  const handleCloseModalConfirmUpload = () => {
    setShowModalConfirmUpload(false);
  };
  const handleCloseModalSingleUpload = () => {
    setShowModalSingleUpload(false);
  };

  const handleDelete = (id) => {
    setTempDeleteId(id);
    setShowModalDelete(true);
  };
  const reqDeleteFile = () => {
    // console.log('del', tempDeleteId);
    dispacth(utility.delFile(access_token, tempDeleteId));
  };
  const handleSuccesDelete = () => {
    handleGETFileOP();
    setShowModalSuccessDel(false);
  };

  const handleSeeImage = (url) => {
    window.open(url);
  };

  const handleUsePayment = (data) => {
    setValue('AccountNo', data?.number);

    let bankChoose = all_bank_data?.filter((e) => e?.name == data?.bank?.name);
    setBank(bankChoose[0]);
  };

  React.useEffect(() => {
    dispacth(utility.getAllBank(access_token));
  }, []);

  React.useEffect(() => {
    if (detail_opData) {
      let params = {
        code: detail_opData?.po?.customer_code,
      };

      dispacth(utility.paymentInfoCode(access_token, params));
    }

    setValue('CekNo', detail_opData?.check_no ?? '');
    setValue('ReferensiNo', detail_opData?.reff_no ?? '');
    setValue('AccountNo', detail_opData?.account_no ?? '');

    setDefaultBank(detail_opData, bankData)
    
    if (detail_opData?.status == 'open') {
      setCanUpload(true);
      setCanDelete(true);
    } else {
      setCanUpload(true);
      setCanDelete(true);
    }

    if(detail_opData?.status == 'closed'){
      setDate({ start: new Date(detail_opData?.paid_date) })
    }

    setFileData([]);
    handleGETFileOP();
  }, [detail_opData]);

  React.useEffect(() => {
    if (payment_vc) {
      setPaymentData(payment_vc);
    } else {
      setPaymentData(null);
    }
  }, [payment_vc]);

  React.useEffect(() => {
    if (confirm_opMesssage) {
      if (confirm_opMesssage === 'Update outgoing payment Success') {
        setShowModalSuccess(true);
      } else if (
        confirm_opMesssage !== '' &&
        confirm_opMesssage !== 'Update outgoing payment Success'
      ) {
        setType('error');
        setShow(true);
        setNotifText(confirm_opMesssage);
      }
      dispacth(actions.setBlankConfirm());
    }
  }, [confirm_opMesssage]);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      // console.log(value, name, type)
      setDataInput(value)
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  // for render styling
  const _renderStatus = (status) => {
    if (status === 'open') {
      return (
        <>
          <Typography className={classes.statusWaiting}>Open</Typography>
        </>
      );
    } else if (status === 'cancelled' || status === 'closed') {
      return (
        <>
          <Typography className={classes.statusClose}>{status}</Typography>
        </>
      );
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Grid container>
          <CustomizedSnackbars
            show={show}
            handleClose={handleClose}
            message={notifText}
            type={type}
          />
          {/* modal delete */}
          <ModalDeleteItem
            open={showModalDelete}
            close={() => setShowModalDelete(false)}
            id={tempDeleteId}
            onPress={reqDeleteFile}
            isLoading={del_file_isLoading}
          />
          <ModalSuccess
            open={showModalSuccesDel}
            onPress={handleSuccesDelete}
          />
          <ModalSuccess
            open={showModalSuccess}
            setModalConfirm={setShowModalSuccess}
            handleCloseModalSuccess={handleCloseModalSuccess}
            onPress={() => router.push('/outgoing-payment')}
          />
          <ModalConfirm
            success={() => setShowModalSuccess(true)}
            open={showModalConfirm}
            setModalConfirm={setShowModalConfirm}
            handleCloseModalConfirm={handleCloseModalConfirm}
            title={'OP'}
            onPress={() =>
              dispacth(
                actions.confirmhOP(
                  access_token,
                  detail_opData?.id,
                  tempSendData
                )
              )
            }
            isLoading={confirm_opIsLoading}
          />
          <ModalQRCode
            open={showModalQRCode}
            handleCloseModalQRCode={handleCloseModalQRCode}
          />
          {/* for uploading file */}
          <ModalSuccess
            open={showModalSuccessUpload}
            setModalConfirm={setShowModalSuccessUpload}
            // handleCloseModalSuccess={handleCloseModalSuccessUpload}
            onPress={handleCloseUploading}
          />
          <ModalUpload
            open={showModalUpload}
            handleCloseModalUpload={handleCloseModalUpload}
            onPress={() => setShowModalConfirmUpload(true)}
            imageColletion={imageColletion}
            handleRemoveFile={handleRemoveFile}
            onFileChange={onFileChange}
          />
          <ModalConfirmUpload
            open={showModalConfirmUpload}
            handleCloseModalConfirmUpload={handleCloseModalConfirmUpload}
            onPress={handleUploadFile}
            uploadPercent={percent}
            isLoading={uploadLoading}
            title="OP"
          />

          <Grid md={12} sm={12} xs={12}>
            <Box className={classes.formTitle}>
              <Typography
                className={classes.formNumber}
                variant="body1"
                component="p"
              >
                Outgoing Payment No. {detail_opData?.outgoing_no} ({detail_opData?.type_of_payment})
              </Typography>
              <Box className={classes.wrapperStatusForm}>
                <Typography className={classes.bodyDataText}>
                  {dateFormater(
                    `${detail_opData?.doc_date} ${detail_opData?.doc_time}`,
                    'DD-MM-YYYY HH:MM'
                  )}
                </Typography>
                {_renderStatus(detail_opData?.status)}
              </Box>
            </Box>
            <Box className={classes.borderLine} />
          </Grid>
          <Grid md={12} sm={12} xs={12}>
            <Box className={classes.wrapperInformation}>
              <Box className={classes.informationContent}>
                <Box className={classes.informationHeader}>
                  <Typography
                    className={classes.headerText}
                    variant="body1"
                    component="p"
                  >
                    Outgoing Payment Information{' '}
                    {/* {`${detail_opData?.po?.customer_code}`} */}
                  </Typography>
                </Box>
                <Box className={classes.infromationBody}>
                  <Grid container md={12}>
                    <Grid md={6} sm={6} xs={6}>
                      <Box className={classes.wrapperBody}>
                        <Box className={classes.bodyContent}>
                          <Typography
                            className={classes.bodyTitle}
                            variant="body1"
                            component="p"
                          >
                            Vendor Name
                          </Typography>
                        </Box>
                        {detail_opisLoading ? (
                          <Skeleton variant="text" width={'100%'} height={50} />
                        ) : (
                          <Box className={classes.bodyData}>
                            <Typography
                              className={classes.bodyDataText}
                              variant="body1"
                              component="p"
                            >
                              {detail_opData?.to_order_of_vendor_name}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                    <Grid md={6} sm={6} xs={6}>
                      <Box className={classes.wrapperBody}>
                        <Box className={classes.bodyContent}>
                          <Typography
                            className={classes.bodyTitle}
                            variant="body1"
                            component="p"
                          >
                            Total Payment
                          </Typography>
                        </Box>
                        {detail_opisLoading ? (
                          <Skeleton variant="text" width={'100%'} height={50} />
                        ) : (
                          <Box className={classes.bodyData}>
                            <Typography
                              className={classes.bodyDataText}
                              variant="body1"
                              component="p"
                            >
                              {detail_opData?.currency === 'IDR'
                                ? formatRupiah(
                                    detail_opData?.total_amount ?? '0'
                                  )
                                : detail_opData?.total_amount ?? '0'}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box className={classes.infromationBody}>
                  <Grid container md={12}>
                    <Grid md={6} sm={6} xs={6}>
                      <Box className={classes.wrapperBody}>
                        <Box className={classes.detailVendor}>
                          {detail_opisLoading ? (
                            <Skeleton variant="text" width={'100%'} />
                          ) : (
                            <Box className={classes.bodyContent}>
                              <Typography
                                className={classes.bodyTitle}
                                variant="body1"
                                component="p"
                              >
                                {detail_opData?.po?.customer_name ?? '-'}
                              </Typography>
                              <Typography
                                className={classes.bodyDataText}
                                variant="body1"
                                component="p"
                              >
                                {detail_opData?.po?.vendor_address ?? '-'}
                              </Typography>
                              <Box className={classes.sizeDetailVendor} />
                              <Grid
                                container
                                md={12}
                                className={classes.detailVendorContact}
                              >
                                <Grid md={2}>
                                  <Typography
                                    className={classes.bodyTitle}
                                    variant="body1"
                                    component="p"
                                  >
                                    Phone
                                  </Typography>
                                </Grid>
                                <Grid md={8}>
                                  <Typography
                                    className={classes.bodyDataText}
                                    variant="body1"
                                    component="p"
                                  >
                                    : {detail_opData?.po?.vendor_phone ?? '-'}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                md={12}
                                className={classes.detailVendorContact}
                              >
                                <Grid md={2}>
                                  <Typography
                                    className={classes.bodyTitle}
                                    variant="body1"
                                    component="p"
                                  >
                                    Fax
                                  </Typography>
                                </Grid>
                                <Grid md={8}>
                                  <Typography
                                    className={classes.bodyDataText}
                                    variant="body1"
                                    component="p"
                                  >
                                    : {detail_opData?.po?.vendor_fax ?? '-'}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                md={12}
                                className={classes.detailVendorContact}
                              >
                                <Grid md={2}>
                                  <Typography
                                    className={classes.bodyTitle}
                                    variant="body1"
                                    component="p"
                                  >
                                    CP
                                  </Typography>
                                </Grid>
                                <Grid md={8}>
                                  <Typography
                                    className={classes.bodyDataText}
                                    variant="body1"
                                    component="p"
                                  >
                                    : {detail_opData?.po?.contact_person ?? '-'}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                md={12}
                                className={classes.detailVendorContact}
                              >
                                <Grid md={2}>
                                  <Typography
                                    className={classes.bodyTitle}
                                    variant="body1"
                                    component="p"
                                  >
                                    Npwp
                                  </Typography>
                                </Grid>
                                <Grid md={8}>
                                  <Typography
                                    className={classes.bodyDataText}
                                    variant="body1"
                                    component="p"
                                  >
                                    :{' '}
                                    {detail_opData?.po?.tax_id_customer ?? '-'}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                    
                    <Grid md={6} sm={6} xs={6}>
                      <Box className={classes.wrapperBody}>
                        <Box className={classes.detailVendor}>
                          <Typography
                            className={classes.bodyTitle}
                            variant="body1"
                            component="p"
                          >
                            Payment Information
                          </Typography>
                          <Box className={classes.sizePaymentInformation} />
                          {payment_vcIsLoading || detail_opisLoading ? (
                            // handle Loading
                            <Skeleton variant="text" width={'100%'} />
                          ) : !paymentData ? (
                            <Typography>No data payment information</Typography>
                          ) : (
                            paymentData?.paymentInfo?.map((item, index) => (
                              <PaymentItem
                                item={item}
                                key={index}
                                index={index}
                                handleUsePayment={handleUsePayment}
                              />
                            ))
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Grid md={12}>
                <Typography
                  className={`${classes.headerText} ${classes.verticalSpace}`}
                  variant="body1"
                  component="p"
                >
                  Payment Information
                </Typography>
                <Box className={classes.borderLine} />
                </Grid>
                
                <Box className={classes.infromationBody}>
                  <Grid container md={12}>
                    {detail_opData?.type_of_payment ===
                    'Bank Transfer' ? null : (
                      <Grid md={3}>
                        <Box className={classes.wrapperBody}>
                          <InputGroup
                            label="CekNo"
                            placeholder="Enter Number"
                            register={register}
                            required
                            type="text"
                            error={errors.Username ? 'error' : null}
                          />
                          <p className={classes.errorText}>
                            {errors.CekNo?.message}
                          </p>
                        </Box>
                      </Grid>
                    )}
                    <Grid
                      md={
                        detail_opData?.type_of_payment === 'Bank Transfer'
                          ? 6
                          : 3
                      }
                    >
                      <Box className={classes.wrapperBody}>
                        <InputGroup
                          label="ReferensiNo"
                          placeholder="Enter Number"
                          register={register}
                          required
                          type="text"
                          error={errors.Username ? 'error' : null}
                        />
                        <p className={classes.errorText}>
                          {errors.ReferensiNo?.message}
                        </p>
                      </Box>
                    </Grid>
                    <Grid md={6} sm={6} xs={6}>
                      <Box className={classes.wrapperBody}>
                        <Box className={classes.bodyContent}>
                          <Typography
                            className={classes.bodyTitle}
                            variant="body1"
                            component="p"
                          >
                            Paid Date
                          </Typography>
                        </Box>
                        <Box className={classes.dateContent}>
                          <div className={classes.dateRangeIcon}>
                            <img src={DateIcon} alt="image not found" />
                          </div>
                          <div className={classes.containerDate}>
                            <DatePickerSingle
                              value={date?.start}
                              onDatePick={(pickedDay) => {
                                setDate(pickedDay);
                              }}
                              placeholder="Select Date Range"
                            />
                          </div>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box className={classes.infromationBody}>
                  {detail_opData?.type_of_payment === 'Check' ? null : (
                    <Grid container md={12}>
                      <Grid md={6}>
                        <Box className={classes.wrapperBody}>
                          <InputGroup
                            label="AccountNo"
                            placeholder="Enter Number"
                            register={register}
                            required
                            type="text"
                            error={errors.Username ? 'error' : null}
                          />
                          <p className={classes.errorText}>
                            {errors.NoRek?.message}
                          </p>
                        </Box>
                      </Grid>
                      <Grid md={6}>
                        <Box className={classes.wrapperBody}>
                          {all_bank_isLoading ? (
                            <>
                              <Typography
                                className={classes.bodyTitle}
                                variant="body1"
                                component="p"
                              >
                                Bank
                              </Typography>
                              <Skeleton variant="text" width={'100%'} />
                            </>
                          ) : (
                            <Box className={classes.bodyContent}>
                              <Typography
                                className={classes.bodyTitle}
                                variant="body1"
                                component="p"
                              >
                                Bank
                              </Typography>
                              <Box
                                className={classes.filterVendorContent}
                                // onBlur={() => setIsVendorFocus(false)}
                              >
                                <Box className={classes.wrapperInputIcons}>
                                  <Box className={classes.wrapperInpuAvatar}>
                                    {bank?.avatar?.url ? (
                                      <img
                                        className={classes.iconAvatar}
                                        src={bank?.avatar?.url}
                                        alt="image not found"
                                      />
                                    ) : null}
                                    <input
                                      className={classes.inputVendor}
                                      value={bank?.name ?? 'Select Bank'}
                                      autoComplete="on"
                                      onFocus={() => setIsBankFocus(true)}
                                      placeholder="Select Bank"
                                      onBlur={() => setIsBankFocus(false)}
                                    />
                                  </Box>

                                  <Box className={classes.iconDownArrow}>
                                    <DownArrow />
                                  </Box>
                                </Box>
                                {/* render for select vendor name */}
                                {isBankFocus || showBank ? (
                                  <Box
                                    onBlur={() => setIsBankFocus(true)}
                                    className={classes.contentDataVendor}
                                    onMouseOver={() => setShowBank(true)}
                                    onMouseOut={() => setShowBank(false)}
                                  >
                                    <Box
                                      className={classes.contentVendorSearch}
                                    >
                                      <input
                                        className={classes.searchVendor}
                                        autoComplete="on"
                                        onFocus={() => setIsBankFocus(true)}
                                        value={searchText}
                                        onChange={(e) =>
                                          setSearchText(e.target.value)
                                        }
                                        placeholder="Search"
                                        // onBlur={() => setIsVendorFocus(false)}
                                      />
                                      <img src={Search} alt="search" />
                                    </Box>
                                    <Box className={classes.listVendorName}>
                                      {bankData?.map((data, index) => (
                                        <Box
                                          key={index}
                                          className={classes.wrapperDataBank}
                                          onClick={() => selectBank(data)}
                                        >
                                          <img
                                            className={classes.bankAvatar}
                                            src={data?.avatar?.url}
                                            alt="image not found"
                                          />
                                          <Box className={classes.TitleVendor}>
                                            {`- ${data.name}`}
                                          </Box>
                                        </Box>
                                      ))}
                                    </Box>
                                  </Box>
                                ) : null}
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </Box>

                <Box className={classes.infromationAttachment}>
                  <Grid container>
                    {/* Mandatory Menu */}
                    <Grid xs={12} md={12} container>
                      <Grid md={12}>
                        <Typography
                          className={classes.uploadTitle}
                          variant="body1"
                          component="p"
                        >
                          Proof of Payment
                        </Typography>
                        {read_loading || IsFileLoading ? null : (
                          <Grid md={3}>
                            {fileData.length < 1 ? (
                              <Box className={classes.AttachmentNotif}>
                                <Typography
                                  className={classes.AttachmentMessage}
                                  variant="body1"
                                  component="p"
                                >
                                  No document attached.
                                </Typography>
                                {/* <Box>
                                  <img
                                    className={classes.bellImage}
                                    src={Cross}
                                    alt="image not found"
                                  />
                                </Box> */}
                              </Box>
                            ) : null}
                          </Grid>
                        )}
                        <Grid md={12}>
                          {detail_opisLoading ||
                          read_loading ||
                          IsFileLoading ? (
                            <CircleLoading />
                          ) : !showFileData &&
                            fileData?.length < 1 &&
                            canUpload ? (
                            <>
                              <Box className={classes.btnUploadContent}>
                                <Box
                                  className={classes.btnUpload}
                                  onClick={() => setShowModalUpload(true)}
                                >
                                  <Box>
                                    <img
                                      className={classes.bellImage}
                                      src={UploadBtn}
                                      alt="image not found"
                                    />
                                  </Box>
                                  <Typography
                                    className={classes.btnUploadValue}
                                    variant="body1"
                                    component="p"
                                  >
                                    Upload File
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className={classes.sizeSubLink} />
                            </>
                          ) : (
                            fileData?.map((data, index) => (
                              <>
                                <Box
                                  key={index}
                                  className={classes.wrapperLink}
                                >
                                  <Box className={classes.wrapperLink}>
                                    <Box
                                      className={classes.wrapperLinkFile}
                                      onClick={() => handleSeeImage(data?.url)}
                                    >
                                      <Box>
                                        <img
                                          src={Paper}
                                          alt="image not found"
                                        />
                                      </Box>
                                      <Typography
                                        className={classes.bodyUrlText}
                                        variant="body1"
                                        component="p"
                                      >
                                        {`${data.file_name} `}
                                      </Typography>
                                      <Typography
                                        className={classes.textFileCount}
                                        variant="body1"
                                        component="p"
                                      >
                                        {`${formatFileCount(data?.size)} MB`}
                                      </Typography>
                                    </Box>
                                    {canDelete ? (
                                      <TrashDelete
                                        onPress={() => handleDelete(data?.id)}
                                      />
                                    ) : null}
                                  </Box>
                                  {index + 1 === fileData.length &&
                                  canUpload ? (
                                    <Box
                                      className={classes.btnAddMoreUpload}
                                      onClick={() => setShowModalUpload(true)}
                                    >
                                      <img
                                        style={{ marginRight: 8 }}
                                        src={CirclePlus}
                                        alt="image not found"
                                      />
                                      <Typography
                                        className={classes.bodyDataTextBlue}
                                      >
                                        Add More Document
                                      </Typography>
                                    </Box>
                                  ) : null}
                                </Box>
                                <Box className={classes.sizeSubLink} />
                              </>
                            ))
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.borderLine} />
        <Grid md={12}>
          <Box className={classes.btnContent}>
            <Box>
              <input
                className={
                  dataInput?.ReferensiNo
                    ? classes.btnCancel
                    : classes.btnCancelFalse
                }
                type="submit"
                value="Submit"
              />
            </Box>
            <Box className={classes.wrapperChecbox}>
              <Checkbox
                defaultChecked
                onChange={handleChange}
                checked={checked}
                className={classes.checkbox}
              />
              <Typography
                className={classes.bodyDataText}
                variant="body1"
                component="p"
              >
                Notify Vendor by Email
              </Typography>
            </Box>
          </Box>
        </Grid>
      </form>
    </>
  );
};

const useStyles = makeStyles({
  bankAvatar: {
    maxWidth: '60px',
  },
  verticalSpace: {
    margin: '30px 0 20px 0'
  },
  formTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  borderLine: {
    borderBottom: '1px solid #E7E7E8',
  },
  formNumber: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#1B2030',
  },
  formStatus: {
    fontSize: 14,
    lineHeight: '26px',
    color: '#969BAB',
  },
  formStatusNeed: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    background: '#FD453A',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    textTransform: 'uppercase',
  },
  wrapperInformation: {
    paddingTop: 27,
    paddingBottom: 59,
    backgroundColor: '#fff',
  },
  informationContent: {
    // marginTop: 27,
    paddingLeft: 20,
    paddingRight: 18.5,
    backgroundColor: '#fff',
    // marginBottom: 58,
  },
  informationHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  informationHeaderLink: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
  },
  headerText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  headerLink: {
    fontSize: 14,
    lineHeight: '26px',
    fontWeight: 'bold',
    color: '#127EEE',
    marginLeft: 10,
  },
  infromationBody: {
    marginTop: 18.5,
    marginLeft: -10,
    marginRight: -10,
  },
  wrapperBody: {
    paddingRight: 10,
    paddingLeft: 10,
  },
  bodyTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
    marginBottom: 12,
  },
  bodyData: {
    width: '100%',
    backgroundColor: '#EBEFF0',
    padding: '12px 20px',
    border: '1px solid #E7E7E8',
    borderRadius: 8,
  },
  wrapperBodyGrandTotal: {},
  infromationAttachment: {
    marginTop: 30,
  },
  AttachmentTitle: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    fontWeight: 'bold',
  },
  AttachmentNotif: {
    marginTop: 20,
    marginBottom: 20,
    padding: 10,
    borderRadius: 4,
    backgroundColor: '#FFE9E8',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  AttachmentMessage: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#F12B2C',
  },
  btnUploadContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  btnUploadText: {
    fontSize: 14,
    lineHeight: '26px',
    color: '#1B2030',
  },
  btnUpload: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: '#127EEE',
    paddingLeft: 19,
    paddingRight: 19,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 8,
  },
  btnUploadValue: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
    marginLeft: 13,
  },
  assignApprovalContent: {
    paddingTop: 26,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 26,
    backgroundColor: '#fff',
    // borderBottom: '1px solid #E7E7E8',
  },
  headerTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  filterActifityContent: {
    marginTop: 20,
  },
  formControl: {
    // marginTop: 6,
    width: '100%',
    backgroundColor: '#EBEFF0',
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    paddingTop: 12,
    paddingBottom: 8,
    paddingLeft: 20,
    paddingRight: 20,
    '&.MuiFormControl-root .MuiInput-underline::before': {
      border: 'none',
      transition: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root.MuiSelect-select':
      {
        paddingTop: 2,
        paddingBottom: 2,
      },
  },
  sizeBox: {
    marginTop: 20,
  },
  btnOption: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  wrapperMandatory: {
    paddingLeft: 20,
    paddingTop: 15,
    paddingRight: 30,
    paddingBottom: 15,
    border: '1px solid #E7E7E8',
    borderRadius: 8,
  },
  sizeBoxTwo: {
    marginTop: 30,
  },
  textTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
  },
  titleContent: {
    marginBottom: 12,
  },
  supportContent: {
    marginTop: 20,
    marginBottom: 12,
  },
  uploadTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
    marginBottom: 20,
  },
  bodyUrlText: {
    marginLeft: 10,
    fontSize: 16,
    lineHeight: '21px',
    textDecorationLine: 'underline',
    color: '#1B2030',
  },
  wrapperLink: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  sizeTitle: {
    marginTop: 21.5,
  },
  sizeSubLink: {
    marginTop: 31,
  },
  boxDepartment: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  bodyDataSubText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    marginLeft: 4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  statusWaiting: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#127EEE',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  statusClose: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    background: '#969BAB',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  BtnConfirm: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 14.5,
    paddingBottom: 14.5,
    backgroundColor: '#1B2030',
    borderRadius: 8,
    maxWidth: 193,
    marginTop: 20,
    cursor: 'pointer',
  },
  BtnConfirmText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
  },
  detailVendor: {
    width: '100%',
    backgroundColor: '#F8FAFB',
    // backgroundColor: 'red',
    height: 248,
    borderRadius: 8,
    paddingLeft: 25,
    paddingRight: 18,
    paddingTop: 14,
    paddingBottom: 14,
  },
  sizeDetailVendor: {
    marginTop: 6,
  },
  detailVendorContact: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  sizePaymentInformation: {
    marginTop: 3,
  },
  wrapperListBank: {
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    height: 50,
    paddingLeft: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 8,
    marginBottom: 8,
  },
  textRekening: {
    fontSize: 16,
    lineHeight: '30px',
    marginLeft: 20,
    flex: 1,
  },
  bodyDataTextBlue: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#127EEE',
    cursor: 'pointer',
    fontWeight: 'bold',
    paddingRight: 41,
  },
  dateContent: {
    width: '100%',
    // backgroundColor: 'red',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingRight: 5,
  },
  dateRangeIcon: {
    width: 58,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E7E7E8',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  containerDate: {
    width: `100%`,
    maxWidth: `calc(100% - 58px)`,
  },
  // Styling Input serach
  inputVendor: {
    width: '100%',
    height: 50,
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    paddingLeft: 70,
    '&.focus-visible': {
      border: '1px solid #E7E7E8',
      outline: 'none',
    },
  },
  filterVendorContent: {
    paddingLeft: 5,
    paddingRight: 5,
    width: '100%',
    // maxWidth: 250,
    // minWidth: 250,
    position: 'relative',
  },
  exportContent: {
    marginRight: 24,
    paddingRight: 20,
    width: '100%',
    // maxWidth: 164,
    // minWidth: 154,
    position: 'absolute',
    right: 0,
    zIndex: 90,
    backgroundColor: '#fff',
  },
  filterBlankArea: {
    minWidth: 150,
  },
  contentDataVendor: {
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid #E7E7E8',
    minHeight: 202,
    maxHeight: 202,
    position: 'absolute',
    // maxWidth: 245,
    borderRadius: 8,
    padding: 6,
    zIndex: 99999,
    marginTop: 4,
  },
  contentVendorSearch: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    width: '100%',
    border: '1px solid #E7E7E8',
    paddingRight: 15,
    borderRadius: 8,
  },
  searchVendor: {
    flex: 1,
    height: 40,
    border: 'none',
    outline: 'none',
    paddingLeft: 12,
  },
  listVendorName: {
    marginTop: 8,
    height: `calc(202px - 56px)`,
    maxHeight: `calc(202px - 56px)`,
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.4em',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
      borderRadius: 4,
    },
  },
  TitleVendor: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',

    marginLeft: 10,
  },
  wrapperDataBank: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 46,
    cursor: 'pointer',
    marginTop: 2,
    marginBottom: 2,
  },
  // Styling Button
  btnContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingTop: 26,
    paddingBottom: 16,
    paddingRight: 20,
    paddingLeft: 20,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
  },
  btnAddMoreUpload: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: 26,
    cursor: 'pointer',
  },

  btnCancel: {
    cursor: 'pointer',
    backgroundColor: '#1B2030',
    width: 193,
    height: 50,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    lineHeight: '21px',
    color: '#fff',
  },
  btnCancelFalse: {
    backgroundColor: '#969BAB',
    width: 193,
    height: 50,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    lineHeight: '21px',
    color: '#fff',
  },
  btnCancelText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#fff',
  },
  btnText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
  },
  wrapperChecbox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 20,
  },
  checkbox: {
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#2B3595',
    },
  },
  wrapperLinkFile: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    width: 250,
  },
  bodyUrlText: {
    marginLeft: 10,
    fontSize: 16,
    lineHeight: '21px',
    textDecorationLine: 'underline',
    color: '#1B2030',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 145,
  },
  textFileCount: {
    marginLeft: 2,
    fontSize: 16,
    lineHeight: '21px',
    textDecorationLine: 'underline',
    color: '#1B2030',
  },

  wrapperStatusForm: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  wrapperInputIcons: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  iconDownArrow: {
    position: 'relative',
    marginLeft: '-40px',
    marginTop: 15,
  },
  wrapperInpuAvatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  iconAvatar: {    
    maxWidth: '60px',
    position: 'relative',
    marginRight: '-60px',
    zIndex: 100,
  },
  errorText: {
    fontSize: 14,
    color: 'red',
  },
});

export default OutgoingPaymentFormData;
