import * as type from './types';
import request from '../../utils/request';
import _ from 'lodash';

export const getPermission = (token) => (dispatch) => {
  dispatch({ type: type.GET_PERMISSION_LOADING, payload: true });

  request({
    method: 'get',
    url: 'permission',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      const data = res?.data;
      // console.log(res?.data);
      dispatch({ type: type.GET_PERMISSION_DATA, payload: data });
      dispatch({ type: type.GET_PERMISSION_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get permission', err);
      dispatch({ type: type.GET_PERMISSION_LOADING, payload: false });
    });
};

// create role
export const createRole = (token, data) => (dispatch) => {
  dispatch({ type: type.CREATE_ROLE_LOADING, payload: true });

  request({
    method: 'post',
    url: 'roles',
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.CREATE_ROLE_MESSAGE, payload: res?.message });
      dispatch({ type: type.CREATE_ROLE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error create role', err);
      dispatch({ type: type.CREATE_ROLE_LOADING, payload: false });
    });
};

export const setBlankCreateRole = () => (dispatch) => {
  dispatch({ type: type.CREATE_ROLE_MESSAGE, payload: '' });
};

export const setBackgroundColor = (color) => (dispatch) => {
  dispatch({ type: type.SET_BACKGROUND_COLOR, payload: color })
}

// get role
export const getRole = (token) => (dispatch) => {
  dispatch({ type: type.GET_ROLES_LOADING, payload: true });

  request({
    method: 'get',
    url: 'roles/get-roles',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.GET_ROLES_DATA, payload: res?.data });
      dispatch({ type: type.GET_ROLES_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get role', err);
      dispatch({ type: type.GET_ROLES_LOADING, payload: false });
    });
};

// delete role
export const deleteRole = (token, id) => (dispatch) => {
  dispatch({ type: type.DELETE_ROLE_LOADING, payload: true });

  request({
    method: 'post',
    url: `roles/${id}/delete`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.DELETE_ROLE_MESSAGE, payload: res?.message });
      dispatch({ type: type.DELETE_ROLE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error delete role', err);
      dispatch({ type: type.DELETE_ROLE_LOADING, payload: false });
    });
};

export const setBlankDeleteRole = () => (dispatch) => {
  dispatch({ type: type.DELETE_ROLE_MESSAGE, payload: '' });
};

// get detail role
export const getDetailRole = (token, id) => (dispatch) => {
  dispatch({ type: type.DETAIL_ROLES_LOADING, payload: true });

  request({
    method: 'get',
    url: `roles/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.DETAIL_ROLES_DATA, payload: res?.data });
      dispatch({ type: type.DETAIL_ROLES_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get detail role', err);
      dispatch({ type: type.DETAIL_ROLES_LOADING, payload: false });
    });
};

// update role
export const updateRole = (token, id, data) => (dispatch) => {
  dispatch({ type: type.UPDATE_ROLE_LOADING, payload: true });

  request({
    method: 'post',
    url: `roles/${id}/update`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.UPDATE_ROLE_MESSAGE, payload: res?.message });
      dispatch({ type: type.UPDATE_ROLE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error update  role', err);
      dispatch({ type: type.UPDATE_ROLE_LOADING, payload: false });
    });
};

export const setBlankUpdateRole = () => (dispatch) => {
  dispatch({ type: type.UPDATE_ROLE_MESSAGE, payload: '' });
};

// get all entity
export const getEntity = (token) => (dispatch) => {
  dispatch({ type: type.GET_ENTITY_LOADING, payload: true });

  request({
    method: 'get',
    url: `entities`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.GET_ENTITY_DATA, payload: res?.data });
      dispatch({ type: type.GET_ENTITY_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get  entity', err);
      dispatch({ type: type.GET_ENTITY_LOADING, payload: false });
    });
};

// create entity
export const createEntity = (token, data) => (dispatch) => {
  dispatch({ type: type.CREATE_ENTITY_LOADING, payload: true });

  request({
    method: 'post',
    url: `entities`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.CREATE_ENTITY_MESSAGE, payload: res?.message });
      dispatch({ type: type.CREATE_ENTITY_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error create entity', err);
      dispatch({ type: type.CREATE_ENTITY_LOADING, payload: false });
    });
};

export const setBlankCreateEntity = () => (dispatch) => {
  dispatch({ type: type.CREATE_ENTITY_MESSAGE, payload: '' });
};

// delete entity
export const deleteEntity = (token, id) => (dispatch) => {
  dispatch({ type: type.DELETE_ENTITY_LOADING, payload: true });

  request({
    method: 'post',
    url: `entities/${id}/delete`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.DELETE_ENTITY_MESSAGE, payload: res?.message });
      dispatch({ type: type.DELETE_ENTITY_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error delete entity', err);
      dispatch({ type: type.DELETE_ENTITY_LOADING, payload: false });
    });
};

export const setBlankDeleteEntity = () => (dispatch) => {
  dispatch({ type: type.DELETE_ENTITY_MESSAGE, payload: '' });
};

// get detail Entity
export const getDetailEntity = (token, id) => (dispatch) => {
  dispatch({ type: type.DETAIL_ENTITY_LOADING, payload: true });

  request({
    method: 'get',
    url: `entities/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.DETAIL_ENTITY_DATA, payload: res?.data });
      dispatch({ type: type.DETAIL_ENTITY_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get detail entity', err);
      dispatch({ type: type.DETAIL_ENTITY_LOADING, payload: false });
    });
};

// get all department
export const getDepartment = (token) => (dispatch) => {
  dispatch({ type: type.GET_DEPARTMENT_LOADING, payload: true });

  request({
    method: 'get',
    url: `departments`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.GET_DEPARTMENT_DATA, payload: res?.data });
      dispatch({ type: type.GET_DEPARTMENT_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get all department', err);
      dispatch({ type: type.GET_DEPARTMENT_LOADING, payload: false });
    });
};

// create department
export const createDepartment = (token, data) => (dispatch) => {
  dispatch({ type: type.CREATE_DEPARTMENT_LOADING, payload: true });

  request({
    method: 'post',
    url: `departments`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.CREATE_DEPARTMENT_MESSAGE, payload: res?.message });
      dispatch({ type: type.CREATE_DEPARTMENT_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error create department', err);
      dispatch({ type: type.CREATE_DEPARTMENT_LOADING, payload: false });
    });
};

export const setBlankCreateDepartment = () => (dispatch) => {
  dispatch({ type: type.CREATE_DEPARTMENT_MESSAGE, payload: '' });
};

// detele department
export const deleteDepartment = (token, id) => (dispatch) => {
  dispatch({ type: type.DELETE_DEPARTMENT_LOADING, payload: true });

  request({
    method: 'post',
    url: `departments/${id}/delete`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.DELETE_DEPARTMENT_MESSAGE, payload: res?.message });
      dispatch({ type: type.DELETE_DEPARTMENT_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error delete department', err);
      dispatch({ type: type.DELETE_DEPARTMENT_LOADING, payload: false });
    });
};

export const setBlankDeleteDepartment = () => (dispatch) => {
  dispatch({ type: type.DELETE_DEPARTMENT_MESSAGE, payload: '' });
};

// detail department
export const getDetailDepartment = (token, id) => (dispatch) => {
  dispatch({ type: type.DETAIL_DEPARTMENT_LOADING, payload: true });

  request({
    method: 'get',
    url: `departments/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.DETAIL_DEPARTMENT_DATA, payload: res?.data });
      dispatch({ type: type.DETAIL_DEPARTMENT_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error detail department', err);
      dispatch({ type: type.DETAIL_DEPARTMENT_LOADING, payload: false });
    });
};

// get department by entity id
export const getDepartmentByEntity = (token, params) => (dispatch) => {
  dispatch({ type: type.GET_DEPARTMENT_ID_LOADING, payload: true });

  request({
    method: 'get',
    url: `departments/byentity`,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.GET_DEPARTMENT_ID_DATA, payload: res?.data });
      dispatch({ type: type.GET_DEPARTMENT_ID_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get department by entity id', err);
      dispatch({ type: type.GET_DEPARTMENT_ID_LOADING, payload: false });
    });
};

// create position
export const createPosition = (token, data) => (dispatch) => {
  dispatch({ type: type.CREATE_POSITION_LOADING, payload: true });

  request({
    method: 'post',
    url: `positions`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.CREATE_POSITION_MESSAGE, payload: res?.message });
      dispatch({ type: type.CREATE_POSITION_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error create position', err);
      dispatch({ type: type.CREATE_POSITION_LOADING, payload: false });
    });
};

export const setBlankCreatePosition = () => (dispatch) => {
  dispatch({ type: type.CREATE_POSITION_MESSAGE, payload: '' });
};

// get all position
export const getPosition = (token) => (dispatch) => {
  dispatch({ type: type.GET_POSITION_LOADING, payload: true });

  request({
    method: 'get',
    url: `positions`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.GET_POSITION_DATA, payload: res?.data });
      dispatch({ type: type.GET_POSITION_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get all position', err);
      dispatch({ type: type.GET_POSITION_LOADING, payload: false });
    });
};

// delete position
export const deletePosition = (token, id) => (dispatch) => {
  dispatch({ type: type.DELETE_POSITION_LOADING, payload: true });

  request({
    method: 'post',
    url: `positions/${id}/delete`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.DELETE_POSITION_MESSAGE, payload: res?.message });
      dispatch({ type: type.DELETE_POSITION_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error delete position', err);
      dispatch({ type: type.DELETE_POSITION_LOADING, payload: false });
    });
};

export const setBlankDeletePosition = () => (dispatch) => {
  dispatch({ type: type.DELETE_POSITION_MESSAGE, payload: '' });
};

// detail position
export const getDetailPosition = (token, id) => (dispatch) => {
  dispatch({ type: type.DETAIL_POSITION_LOADING, payload: true });

  request({
    method: 'get',
    url: `positions/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.DETAIL_POSITION_DATA, payload: res?.data });
      dispatch({ type: type.DETAIL_POSITION_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get detail position', err);
      dispatch({ type: type.DETAIL_POSITION_LOADING, payload: false });
    });
};

// ############## master data ###########################

// create checklist
export const createChecklist = (token, data) => (dispatch) => {
  dispatch({ type: type.CREATE_CHECKLIST_LOADING, payload: true });

  request({
    method: 'post',
    url: `checklist-attachment`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.CREATE_CHECKLIST_MESSAGE, payload: res?.message });
      dispatch({ type: type.CREATE_CHECKLIST_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error create checklist', err);
      dispatch({
        type: type.CREATE_CHECKLIST_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: type.CREATE_CHECKLIST_LOADING, payload: false });
    });
};

export const setBlankCreateChecklist = () => (dispatch) => {
  dispatch({ type: type.CREATE_CHECKLIST_MESSAGE, payload: '' });
};

// get all checlist
export const getChecklist = (token, params) => (dispatch) => {
  dispatch({ type: type.GET_CHECKLIST_LOADING, payload: true });
  
  request({
    method: 'get',
    url: `checklist-attachment`,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.GET_CHECKLIST_DATA, payload: res?.data });
      dispatch({ type: type.GET_CHECKLIST_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get checklist', err);
      dispatch({ type: type.GET_CHECKLIST_LOADING, payload: false });
    });
};

// get checlist by id
export const detailChecklist = (token, id) => (dispatch) => {
  dispatch({ type: type.DETAIL_CHECKLIST_LOADING, payload: true });

  request({
    method: 'get',
    url: `checklist-attachment/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.DETAIL_CHECKLIST_DATA, payload: res?.data });
      dispatch({ type: type.DETAIL_CHECKLIST_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get detail checklist', err);
      dispatch({ type: type.DETAIL_CHECKLIST_LOADING, payload: false });
    });
};

// update checklist
export const updateChecklist = (token, id, data) => (dispatch) => {
  dispatch({ type: type.UPDATE_CHECKLIST_LOADING, payload: true });

  request({
    method: 'post',
    url: `checklist-attachment/${id}/update`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.UPDATE_CHECKLIST_MESSAGE, payload: res?.message });
      dispatch({ type: type.UPDATE_CHECKLIST_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error update  checklist', err);
      dispatch({
        type: type.UPDATE_CHECKLIST_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: type.UPDATE_CHECKLIST_LOADING, payload: false });
    });
};

export const setBlankUpdateChecklist = () => (dispatch) => {
  dispatch({ type: type.UPDATE_CHECKLIST_MESSAGE, payload: '' });
};

// delete checklist
export const deleteChecklist = (token, id) => (dispatch) => {
  dispatch({ type: type.DELETE_CHECKLIST_LOADING, payload: true });

  request({
    method: 'post',
    url: `checklist-attachment/${id}/delete`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.DELETE_CHECKLIST_MESSAGE, payload: res?.message });
      dispatch({ type: type.DELETE_CHECKLIST_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error delete checklist', err);
      dispatch({
        type: type.DELETE_CHECKLIST_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: type.DELETE_CHECKLIST_LOADING, payload: false });
    });
};

export const setBlankDeleteChecklist = () => (dispatch) => {
  dispatch({ type: type.DELETE_CHECKLIST_MESSAGE, payload: '' });
};

// create activities
export const createActivities = (token, data) => (dispatch) => {
  dispatch({ type: type.CREATE_ACTIVITIES_LOADING, payload: true });

  request({
    method: 'post',
    url: `activities`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.CREATE_ACTIVITIES_MESSAGE, payload: res?.message });
      dispatch({ type: type.CREATE_ACTIVITIES_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error create activities', err);
      dispatch({
        type: type.CREATE_ACTIVITIES_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: type.CREATE_ACTIVITIES_LOADING, payload: false });
    });
};

export const setBlankCreateActivities = () => (dispatch) => {
  dispatch({ type: type.CREATE_ACTIVITIES_MESSAGE, payload: '' });
};

// get all activities
export const getActivities = (token) => (dispatch) => {
  dispatch({ type: type.GET_ACTIVITIES_LOADING, payload: true });

  request({
    method: 'get',
    url: `activities`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.GET_ACTIVITIES_DATA, payload: res?.data });
      dispatch({ type: type.GET_ACTIVITIES_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get activities', err);
      dispatch({ type: type.GET_ACTIVITIES_LOADING, payload: false });
    });
};

// delet activities
export const deleteActivities = (token, id) => (dispatch) => {
  dispatch({ type: type.DELETE_ACTIVITIES_LOADING, payload: true });

  request({
    method: 'post',
    url: `activities/${id}/delete`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.DELETE_ACTIVITIES_MESSAGE, payload: res?.message });
      dispatch({ type: type.DELETE_ACTIVITIES_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error delete activities', err);
      dispatch({
        type: type.DELETE_ACTIVITIES_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: type.DELETE_ACTIVITIES_LOADING, payload: false });
    });
};

export const setBlankDeleteActivities = () => (dispatch) => {
  dispatch({ type: type.DELETE_ACTIVITIES_MESSAGE, payload: '' });
};

// get by id activities
export const detailActivities = (token, id) => (dispatch) => {
  dispatch({ type: type.DETAIL_ACTIVITIES_LOADING, payload: true });

  request({
    method: 'get',
    url: `activities/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.DETAIL_ACTIVITIES_DATA, payload: res?.data });
      dispatch({ type: type.DETAIL_ACTIVITIES_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get detail activities', err);
      dispatch({ type: type.DETAIL_ACTIVITIES_LOADING, payload: false });
    });
};

// update activities
export const updateActivities = (token, id, data) => (dispatch) => {
  dispatch({ type: type.UPDATE_ACTIVITIES_LOADING, payload: true });

  request({
    method: 'post',
    url: `activities/${id}/update`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.UPDATE_ACTIVITIES_MESSAGE, payload: res?.message });
      dispatch({ type: type.UPDATE_ACTIVITIES_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error update activities', err);
      dispatch({
        type: type.UPDATE_ACTIVITIES_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: type.UPDATE_ACTIVITIES_LOADING, payload: false });
    });
};

export const setBlankUpdateActivities = () => (dispatch) => {
  dispatch({ type: type.UPDATE_ACTIVITIES_MESSAGE, payload: '' });
};

// get Report
export const getReport = (token) => (dispatch) => {
  dispatch({ type: type.GET_REPORT_LOADING, payload: true });

  request({
    method: 'get',
    url: `deadline-target-report`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.GET_REPORT_DATA, payload: res?.data });
      dispatch({ type: type.GET_REPORT_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get report', err);
      dispatch({ type: type.GET_REPORT_LOADING, payload: false });
    });
};

// get Report by id
export const detailReport = (token, id) => (dispatch) => {
  dispatch({ type: type.DETAIL_REPORT_LOADING, payload: true });

  request({
    method: 'get',
    url: `deadline-target-report/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.DETAIL_REPORT_DATA, payload: res?.data });
      dispatch({ type: type.DETAIL_REPORT_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get detail report', err);
      dispatch({ type: type.DETAIL_REPORT_LOADING, payload: false });
    });
};

// update Report
export const updateReport = (token, id, data) => (dispatch) => {
  dispatch({ type: type.UPDATE_REPORT_LOADING, payload: true });

  request({
    method: 'post',
    url: `deadline-target-report/${id}/update`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.UPDATE_REPORT_MESSAGE, payload: res?.message });
      dispatch({ type: type.UPDATE_REPORT_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get detail report', err);
      dispatch({
        type: type.UPDATE_REPORT_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: type.UPDATE_REPORT_LOADING, payload: false });
    });
};

export const setBlankUpdateReport = () => (dispatch) => {
  dispatch({
    type: type.UPDATE_REPORT_MESSAGE,
    payload: '',
  });
};

// get distributor
export const getDistributor = (token) => (dispatch) => {
  dispatch({ type: type.GET_DISTRIBUTOR_LOADING, payload: true });

  request({
    method: 'get',
    url: `distributor-group-code`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.GET_DISTRIBUTOR_DATA, payload: res?.data });
      dispatch({ type: type.GET_DISTRIBUTOR_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get distributor', err);
      dispatch({ type: type.GET_DISTRIBUTOR_LOADING, payload: false });
    });
};

// create distributor

export const createDistributor = (token, data) => (dispatch) => {
  dispatch({ type: type.CREATE_DISTRIBUTOR_LOADING, payload: true });

  request({
    method: 'post',
    url: `distributor-group-code`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: type.CREATE_DISTRIBUTOR_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: type.CREATE_DISTRIBUTOR_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error create distributor', err);
      dispatch({
        type: type.CREATE_DISTRIBUTOR_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: type.CREATE_DISTRIBUTOR_LOADING, payload: false });
    });
};

export const setBlankCreateDistributor = () => (dispatch) => {
  dispatch({
    type: type.CREATE_DISTRIBUTOR_MESSAGE,
    payload: '',
  });
};

// delete distributor
export const deleteDistributor = (token, id) => (dispatch) => {
  dispatch({ type: type.DELETE_DISTRIBUTOR_LOADING, payload: true });

  request({
    method: 'post',
    url: `distributor-group-code/${id}/delete`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: type.DELETE_DISTRIBUTOR_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: type.DELETE_DISTRIBUTOR_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error delete distributor', err);
      dispatch({
        type: type.DELETE_DISTRIBUTOR_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: type.DELETE_DISTRIBUTOR_LOADING, payload: false });
    });
};

export const setBlankDeleteDistributor = () => (dispatch) => {
  dispatch({
    type: type.DELETE_DISTRIBUTOR_MESSAGE,
    payload: '',
  });
};

// get distributor by id
export const detailDistributor = (token, id) => (dispatch) => {
  dispatch({ type: type.DETAIL_DISTRIBUTOR_LOADING, payload: true });

  request({
    method: 'get',
    url: `distributor-group-code/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: type.DETAIL_DISTRIBUTOR_DATA,
        payload: res?.data,
      });
      dispatch({ type: type.DETAIL_DISTRIBUTOR_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get detail distributor', err);
      dispatch({ type: type.DETAIL_DISTRIBUTOR_LOADING, payload: false });
    });
};

// update distributor
export const updateDistributor = (token, id, data) => (dispatch) => {
  dispatch({ type: type.UPDATE_DISTRIBUTOR_LOADING, payload: true });

  request({
    method: 'put',
    url: `distributor-group-code/${id}/update`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: type.UPDATE_DISTRIBUTOR_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: type.UPDATE_DISTRIBUTOR_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error update distributor', err);
      dispatch({
        type: type.UPDATE_DISTRIBUTOR_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: type.UPDATE_DISTRIBUTOR_LOADING, payload: false });
    });
};

export const setBlankUpdateDistributor = () => (dispatch) => {
  dispatch({
    type: type.UPDATE_DISTRIBUTOR_MESSAGE,
    payload: '',
  });
};

// get supplier
export const getSupplier = (token) => (dispatch) => {
  dispatch({ type: type.GET_SUPPLIER_LOADING, payload: true });

  request({
    method: 'get',
    url: `supplier-group-code`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.GET_SUPPLIER_DATA, payload: res?.data });
      dispatch({ type: type.GET_SUPPLIER_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get supplier', err);
      dispatch({ type: type.GET_SUPPLIER_LOADING, payload: false });
    });
};

// create supplier
export const createSupplier = (token, data) => (dispatch) => {
  dispatch({ type: type.CREATE_SUPPLIER_LOADING, payload: true });

  request({
    method: 'post',
    url: `supplier-group-code`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.CREATE_SUPPLIER_MESSAGE, payload: res?.message });
      dispatch({ type: type.CREATE_SUPPLIER_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error create supplier', err);
      dispatch({
        type: type.CREATE_SUPPLIER_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: type.CREATE_SUPPLIER_LOADING, payload: false });
    });
};

export const setBlankCreateSupplier = () => (dispatch) => {
  dispatch({
    type: type.CREATE_SUPPLIER_MESSAGE,
    payload: '',
  });
};

// delete supplier
export const deleteSupplier = (token, id) => (dispatch) => {
  dispatch({ type: type.DELETE_SUPPLIER_LOADING, payload: true });

  request({
    method: 'post',
    url: `supplier-group-code/${id}/delete`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.DELETE_SUPPLIER_MESSAGE, payload: res?.message });
      dispatch({ type: type.DELETE_SUPPLIER_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error delete supplier', err);
      dispatch({
        type: type.DELETE_SUPPLIER_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: type.DELETE_SUPPLIER_LOADING, payload: false });
    });
};

export const setBlankDeleteSupplier = () => (dispatch) => {
  dispatch({
    type: type.DELETE_SUPPLIER_MESSAGE,
    payload: '',
  });
};

// get supplier by id
export const detailSupplier = (token, id) => (dispatch) => {
  dispatch({ type: type.DETAIL_SUPPLIER_LOADING, payload: true });

  request({
    method: 'get',
    url: `supplier-group-code/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: type.DETAIL_SUPPLIER_DATA,
        payload: res?.data,
      });
      dispatch({ type: type.DETAIL_SUPPLIER_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get detail supplier', err);
      dispatch({ type: type.DETAIL_SUPPLIER_LOADING, payload: false });
    });
};

// update supplier
export const updateSupplier = (token, id, data) => (dispatch) => {
  dispatch({ type: type.UDPATE_SUPPLIER_LOADING, payload: true });

  request({
    method: 'put',
    url: `supplier-group-code/${id}/update`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: type.UPDATE_SUPPLIER_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: type.UDPATE_SUPPLIER_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error update supplier', err);
      dispatch({
        type: type.UPDATE_SUPPLIER_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: type.UDPATE_SUPPLIER_LOADING, payload: false });
    });
};

export const setBlankUpdateSupplier = () => (dispatch) => {
  dispatch({
    type: type.UPDATE_SUPPLIER_MESSAGE,
    payload: '',
  });
};

// create client category
export const createClientCategory = (token, data) => (dispatch) => {
  dispatch({ type: type.CREATE_CLIENT_CATEGORY_LOADING, payload: true });

  request({
    method: 'post',
    url: `category-client`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: type.CREATE_CLIENT_CATEGORY_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: type.CREATE_CLIENT_CATEGORY_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error create client category', err);
      dispatch({
        type: type.CREATE_CLIENT_CATEGORY_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: type.CREATE_CLIENT_CATEGORY_LOADING, payload: false });
    });
};

export const setBlankCreateClientCategory = () => (dispatch) => {
  dispatch({
    type: type.CREATE_CLIENT_CATEGORY_MESSAGE,
    payload: '',
  });
};

// get all client category
export const getClientCategory = (token) => (dispatch) => {
  dispatch({ type: type.GET_CLIENT_CATEGORY_LOADING, payload: true });

  request({
    method: 'get',
    url: `category-client`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: type.GET_CLIENT_CATEGORY_DATA,
        payload: res?.data,
      });
      dispatch({ type: type.GET_CLIENT_CATEGORY_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get client category', err);
      dispatch({ type: type.GET_CLIENT_CATEGORY_LOADING, payload: false });
    });
};

// delete client category
export const deleteClientCategory = (token, id) => (dispatch) => {
  dispatch({ type: type.DELETE_CLIENT_CATEGORY_LOADING, payload: true });

  request({
    method: 'post',
    url: `category-client/delete?id=${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: type.DELETE_CLIENT_CATEGORY_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: type.DELETE_CLIENT_CATEGORY_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error delete client category', err);
      dispatch({ type: type.DELETE_CLIENT_CATEGORY_LOADING, payload: false });
      dispatch({
        type: type.DELETE_CLIENT_CATEGORY_MESSAGE,
        payload: err?.data?.message,
      });
    });
};

export const setBlankDeleteClientCategory = () => (dispatch) => {
  dispatch({
    type: type.DELETE_CLIENT_CATEGORY_MESSAGE,
    payload: '',
  });
};

// get detail client category
export const detailClientCategory = (token, params) => (dispatch) => {
  dispatch({ type: type.DETAIL_CLIENT_CATEGORY_LOADING, payload: true });

  request({
    method: 'get',
    url: `category-client/byid`,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: type.DETAIL_CLIENT_CATEGORY_DATA,
        payload: res?.data,
      });
      dispatch({ type: type.DETAIL_CLIENT_CATEGORY_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get detail client category', err);
      dispatch({ type: type.DETAIL_CLIENT_CATEGORY_LOADING, payload: false });
    });
};

// update client category
export const updateClientCategory = (token, id, data) => (dispatch) => {
  dispatch({ type: type.UPDATE_CLIENT_CATEGORY_LOADING, payload: true });

  request({
    method: 'post',
    url: `category-client/${id}/update`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: type.UPDATE_CLIENT_CATEGORY_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: type.UPDATE_CLIENT_CATEGORY_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error update client category', err);
      dispatch({
        type: type.UPDATE_CLIENT_CATEGORY_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: type.UPDATE_CLIENT_CATEGORY_LOADING, payload: false });
    });
};

export const setBlankUpdateClientCategory = () => (dispatch) => {
  dispatch({
    type: type.UPDATE_CLIENT_CATEGORY_MESSAGE,
    payload: '',
  });
};

// create purchase type
export const createPurchaseType = (token, data) => (dispatch) => {
  dispatch({ type: type.CREATE_PURCHASE_TYPE_LOADING, payload: true });

  request({
    method: 'post',
    url: `purchase-type`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: type.CREATE_PURCHASE_TYPE_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: type.CREATE_PURCHASE_TYPE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error create purchase type', err);
      dispatch({
        type: type.CREATE_PURCHASE_TYPE_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: type.CREATE_PURCHASE_TYPE_LOADING, payload: false });
    });
};

export const setBlankCreatePuchaseType = () => (dispatch) => {
  dispatch({
    type: type.CREATE_PURCHASE_TYPE_MESSAGE,
    payload: '',
  });
};

// get all purchase type
export const getPurchaseType = (token) => (dispatch) => {
  dispatch({ type: type.GET_PURCHASE_TYPE_LOADING, payload: true });

  request({
    method: 'get',
    url: `purchase-type`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: type.GET_PURCHASE_TYPE_DATA,
        payload: res?.data,
      });
      dispatch({ type: type.GET_PURCHASE_TYPE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get purchase type', err);
      dispatch({ type: type.GET_PURCHASE_TYPE_LOADING, payload: false });
    });
};

// delete purchase type
export const deletePurchaseType = (token, id) => (dispatch) => {
  dispatch({ type: type.DELETE_PURCHASE_TYPE_LOADING, payload: true });

  request({
    method: 'post',
    url: `purchase-type/delete?id=${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: type.DELETE_PURCHASE_TYPE_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: type.DELETE_PURCHASE_TYPE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error delete purchase type', err);
      dispatch({
        type: type.DELETE_PURCHASE_TYPE_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: type.DELETE_PURCHASE_TYPE_LOADING, payload: false });
    });
};

export const setBlankDelPurchaseType = () => (dispatch) => {
  dispatch({
    type: type.DELETE_PURCHASE_TYPE_MESSAGE,
    payload: '',
  });
};

// detail purchase type
export const detailPurchaseType = (token, id) => (dispatch) => {
  dispatch({ type: type.DETAIL_PURCHASE_TYPE_LOADING, payload: true });

  request({
    method: 'get',
    url: `purchase-type/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: type.DETAIL_PURCHASE_TYPE_DATA,
        payload: res?.data,
      });
      dispatch({ type: type.DETAIL_PURCHASE_TYPE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error detail purchase type', err);
      dispatch({ type: type.DETAIL_PURCHASE_TYPE_LOADING, payload: false });
    });
};

// update purchase type
export const updatePurchaseType = (token, id, data) => (dispatch) => {
  dispatch({ type: type.UPDATE_PURCHASE_TYPE_LOADING, payload: true });

  request({
    method: 'post',
    url: `purchase-type/${id}/update`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: type.UPDATE_PURCHASE_TYPE_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: type.UPDATE_PURCHASE_TYPE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error update purchase type', err);
      dispatch({
        type: type.UPDATE_PURCHASE_TYPE_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: type.UPDATE_PURCHASE_TYPE_LOADING, payload: false });
    });
};

export const setBlankUpdatePuchaseType = () => (dispatch) => {
  dispatch({
    type: type.UPDATE_PURCHASE_TYPE_MESSAGE,
    payload: '',
  });
};
