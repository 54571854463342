import React from 'react';
import { makeStyles, Grid, Box } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import * as actions from '../../redux/PO/actions';
import * as actions_2 from '../../redux/Utility/actions';
import * as userManagement from '../../redux/UserManagement/actions';
import * as settingAction from '../../redux/Settings/actions';
import { useRouter } from '../../utils/useRouter';
import {
  formatFileCount,
  dateFormater,
  formatRupiah,
} from '../../utils/formatter';
import axios from 'axios';
import { END_POINT } from '../../config/EndPoint';
import Skeleton from '@material-ui/lab/Skeleton';

// Components
import ButtonTransparent from '../Button/ButtonTransparent';
import HistoryTableDetail from '../HistoryTableDetail';
import ButtonQrCode from '../Button/ButtonQrCode';
import ButtonCancel from '../Button/ButtonCancel';
import ModalCancelPr from '../ModalCancelPr';
import ModalSuccess from '../ModalSuccess';
import ModalQRCode from '../ModalQRCode';
import ModalUpload from '../ModalUpload';
import ModalConfirmUpload from '../ModalConfirmUpload';
import ButtonMandatory from '../Button/ButtonMandatory';
import ModalSingleUpload from '../ModalSingleUpload';
import POpreview from '../PDFpreview/POpreview';
import { CircleLoading } from '../Loading/CircleLoading';
import SKPODetail from '../Skeleton/SKPODetail';
import CustomizedSnackbars from '../PopupMessage';
import TrashDelete from '../TrashDelete';
import ModalDeleteItem from '../ModalDeleteItem';
import CompanyInfromation from '../Company/CompanyInfromation';
import SelectFilter from '../SelectFilter';
import { ContentText } from '../../libs/Typography';

// subComponents
import ChecklistMenu from './components/ChecklistMenu';

//config
import globalConfig from 'src/config/global';

// Icons
import EyeBlue from '../../assets/Icons/eyeBlue.png';
import Cross from '../../assets/Icons/cross.png';
// import UploadBtn from '../../assets/Icons/uploadBtn.png';
import PdfFile from '../../assets/Icons/pdfIcons.png';
import UnduhSecond from '../../assets/Icons/unduhSecond.png';
import Paper from '../../assets/Icons/paper.png';
import CirclePlus from '../../assets/Icons/circlePlus.png';
import Lib from 'src/utils/Lib';

const PoFormApproval = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispacth = useDispatch();
  const { id } = router.query;

  // Global Variable
  const { access_token, data } = useSelector((state) => state.auth);
  const {
    po_detail,
    detail_message,
    detail_isLoading,
    cancel_poMessage,
    assignment_poloading,
    assigment_pomessage,
    confirm_checklist_isLoading,
    confirm_checklist_Message,
    cancel_poLoading,
  } = useSelector((state) => state.po);
  const {
    qrcode_data,
    qrcode_loading,
    read_loading,
    read_data,
    read_data_second,
    read_loading_second,
    del_file_isLoading,
    del_file_message,
  } = useSelector((state) => state.utility);
  const { internal_all_data, internal_all_IsLoading } = useSelector(
    (state) => state.userManagement
  );
  const {
    checklist_isLoading,
    checklist_data,
    activities_isLoading,
    activities_data,
  } = useSelector((state) => state.settings);

  // initial State
  const [fileData, setFileData] = React.useState([]);
  const [showFileData, setShowFileData] = React.useState(false);
  const [fileDataSecond, setFileDataSecond] = React.useState([]);
  const [showFileDataSecond, setShowFileDataSecond] = React.useState(false);
  const [tempDeleteId, setTempDeleteId] = React.useState(0);

  const [aprrovalOne, setArpprovalOne] = React.useState(null);
  const [aprrovalTwo, setArpprovalTwo] = React.useState(null);
  const [aprrovalThree, setArpprovalThree] = React.useState(null);

  const [company, setCompany] = React.useState(null);
  const [rowApproval, setRowApproval] = React.useState(null);

  // handle Render By Status
  const [isNeed, seIsNeed] = React.useState(false);
  const [btnQRon, setBtnQRon] = React.useState(false);
  const [canDownloadPDF, setCanDownloadPDF] = React.useState(false);
  const [canUpload, setCanUpload] = React.useState(false);
  const [canDelete, setCanDelete] = React.useState(false);
  const [canConfirm, setCanConfirm] = React.useState(false);

  // handleshow modal
  const [showModalConfirm, setShowModalConfirm] = React.useState(false);
  const [showModalSuccess, setShowModalSuccess] = React.useState(false);
  const [showModalSuccessUpload, setShowModalSuccessUpload] =
    React.useState(false);
  const [showModalQRCode, setShowModalQRCode] = React.useState(false);
  const [showModalUpload, setShowModalUpload] = React.useState(false);
  const [showModalConfirmUpload, setShowModalConfirmUpload] =
    React.useState(false);
  const [showModalSingleUpload, setShowModalSingleUpload] =
    React.useState(false);
  const [isStatusNeed, setIsStatusNeed] = React.useState(false);
  const [showPDFpreview, setShowPDFpreview] = React.useState(false);
  const [showModalDelete, setShowModalDelete] = React.useState(false);
  const [showModalSuccesDel, setShowModalSuccessDel] = React.useState(false);

  // handle message
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };

  // end handle Message

  // configure to handle File for upload PR
  const [imageColletion, setImageCollection] = React.useState([]);
  const [uploadLoading, setUploadLoading] = React.useState(false);
  const [percent, setPercent] = React.useState(0);
  const [totalFile, setTotalFile] = React.useState(0);
  const [uploadedFile, setUploadedFile] = React.useState(0);

  const onFileChange = (e) => {
    let tempFile = e.target.files;

    // console.log(tempFile);

    setImageCollection((prev) => [...prev, ...tempFile]);
  };

  const handleRemoveFile = (indexOf) => {
    let filteImage = imageColletion.filter((img, index) => index !== indexOf);

    setImageCollection(filteImage);
  };

  const handleUploadFile = () => {
    if (imageColletion) {
      try {
        setUploadLoading(true);

        const sendData = new FormData();
        imageColletion.map((img, index) => {
          sendData.append('file[]', img);
        });

        const config = {
          headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${access_token}`,
          },
          onUploadProgress: (progressEvent) => {
            var percentComplete = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setPercent(percentComplete);
          },
        };

        axios
          .post(
            `${END_POINT}files?doc_type=${'PO'}&doc_id=${
              po_detail?.id
            }&entity_code=${po_detail?.entity?.code}`,
            sendData,
            config
          )
          .then((res) => {
            // console.log(res);
            setPercent(0);
            setUploadLoading(false);
            setShowModalConfirmUpload(false);
            setShowModalUpload(false);
            setImageCollection([]);
            handleGETFilePO();
            setShowModalSuccessUpload(true);
          });
      } catch (error) {
        console.log('error upload file');
        setUploadLoading(false);
      }
      // dispacth(actions_2.uploadFile(access_token, sendData));
    } else {
      console.log('chosses an image');
    }
  };

  // end configure file upload

  const handleCloseModalConfirm = () => {
    setShowModalConfirm(false);
  };
  const handleCloseModalSuccess = () => {
    setShowModalSuccess(false);
  };
  const handleCloseModalQRCode = () => {
    setShowModalQRCode(false);
  };

  const handleCloseModalUpload = () => {
    setShowModalUpload(false);
  };
  const handleCloseModalConfirmUpload = () => {
    setShowModalConfirmUpload(false);
  };
  const handleCloseModalSingleUpload = () => {
    setShowModalSingleUpload(false);
  };
  const handleClosePDFpreview = () => {
    setShowPDFpreview(false);
  };

  const handleGETFilePO = () => {
    // this configute to get file PO
    let readDataSecond = {
      doc_type: 'PO',
      doc_id: po_detail?.id,
      entity_code: po_detail?.entity?.code,
    };

    dispacth(actions_2.readFileSecond(access_token, readDataSecond));
  };

  const baseHandleChangeApproval = (newValue, setFn) => {
    const setVal = newValue?.id ?? null;
    setFn(setVal);
  }

  // handle option select admin
  const handleChangeApprovalOne = (event, newValue) => {
    baseHandleChangeApproval(newValue, setArpprovalOne);
  };

  const handleChangeApprovalTwo = (event, newValue) => {
    baseHandleChangeApproval(newValue, setArpprovalTwo);
  };

  const handleChangeApprovalThree = (event, newValue) => {
    baseHandleChangeApproval(newValue, setArpprovalThree);
  };

  // conigure btn action
  const handleCancelPO = (id) => {
    if (data?.user?.role.length > 0) {
      if (data?.user?.role[0] == 'admin' || data?.user?.role[0] == 'staff') {
        setType('error');
        setShow(true);
        setNotifText('you not have permission to take this action');
      } else {
        dispacth(actions.cancelPO(access_token, id));
      }
    }
  };

  const handleSeeImage = (url) => {
    window.open(url);
  };

  const handleConfirmAssigment = () => {
    const data = [];

    if (aprrovalThree) {
      data.push({
        transaction_type_id: '3',
        doc_id: po_detail?.id,
        user_id: aprrovalThree,
        level: '3',
      });
    }

    if (aprrovalTwo) {
      data.push({
        transaction_type_id: '3',
        doc_id: po_detail?.id,
        user_id: aprrovalTwo,
        level: '2',
      });
    }

    if (aprrovalOne) {
      data.push({
        transaction_type_id: '3',
        doc_id: po_detail?.id,
        user_id: aprrovalOne,
        level: '1',
      });
    }
    console.log('valid');
    dispacth(actions.assigmentPO(access_token, po_detail?.id, data));
  };
  const handleCloseQR = () => {
    setShowModalQRCode(false);
    dispacth(actions.getPODetail(access_token, id));
  };

  const handleDelete = (id) => {
    setTempDeleteId(id);
    setShowModalDelete(true);
  };
  const reqDeleteFile = () => {
    // console.log('del', tempDeleteId);
    dispacth(actions_2.delFile(access_token, tempDeleteId));
  };
  const handleSuccesDelete = () => {
    handleGETFilePO();
    setShowModalSuccessDel(false);
  };

  // for handling checklist configuration
  const [tempChecklist, setTempChecklist] = React.useState([
    {
      id: 1,
      activity_id: 0,
      checklist_attachment_id: [],
    },
  ]);

  const addMoreActivities = (index) => {
    let values = [...tempChecklist];

    values.push({
      id: index + 1,
      activity_id: 0,
      checklist_attachment_id: [],
    });

    setTempChecklist(values);
  };

  const deleteActivities = (id) => {
    let values = [...tempChecklist];

    if (values.length < 2) {
      setType('error');
      setShow(true);
      setNotifText('Minimum Activity Purchase Order is 1');
    } else {
      let filterValue = values.filter((e) => e.id !== id);
      setTempChecklist(filterValue);
    }
  };

  const handleChecklistChange = (e) => {
    let values = [...tempChecklist];

    let changeId = e.target.name;

    let changeFilter = values.map((val, index) => {
      if (val.id == changeId) {
        return { ...val, activity_id: e.target.value };
      } else {
        return val;
      }
    });

    setTempChecklist(changeFilter);
  };

  const handleChecklistChexBox = (event) => {
    console.log('change true');
    let values = [...tempChecklist];

    let tempId = event.target.name.split(',');

    if (event.target.checked == true) {
      let tempData = {
        id: tempId[1],
        name: tempId[3],
        isCheck: true,
      };

      let checklistVal = [...values[tempId[2]].checklist_attachment_id];

      let filterChecklistVal = checklistVal.filter((e) => e.id == tempId[1]);

      if (filterChecklistVal.length > 0) {
        let resultArr = checklistVal.map((item) => {
          if (item.id == tempId[1]) {
            return { ...item, isCheck: true };
          } else {
            return item;
          }
        });
        values[tempId[2]].checklist_attachment_id = resultArr;
        setTempChecklist(values);
      } else {
        checklistVal.push(tempData);
        values[tempId[2]].checklist_attachment_id = checklistVal;
        setTempChecklist(values);
      }
    } else {
      let checklistVal = [...values[tempId[2]].checklist_attachment_id];

      let resulArr = checklistVal.map((item) => {
        if (item.id == tempId[1]) {
          return { ...item, isCheck: false };
        } else {
          return item;
        }
      });

      values[tempId[2]].checklist_attachment_id = resulArr;

      setTempChecklist(values);
    }
  };

  const handleConfirmChecklis = () => {
    let sendData = {
      doc_id: id,
      checklists: [],
    };

    tempChecklist.map((item, index) => {
      let tempChecklist = {
        activity_id: item?.activity_id,
        checklist_attachment_id: [
          ...item.checklist_attachment_id.map((e) => parseInt(e.id)),
        ],
      };
      sendData.checklists.push(tempChecklist);
    });

    let validCheck = [];
    let validChecklist = [];

    sendData.checklists.map((item, index) => {
      if (item.activity_id == 0) {
        validCheck.push(false);
      } else {
        validCheck.push(true);
      }
      if (item.checklist_attachment_id?.length < 1) {
        validChecklist.push(false);
      } else {
        validChecklist.push(true);
      }
    });

    if (validCheck.includes(false)) {
      setType('error');
      setShow(true);
      setNotifText(
        'Please check checklist configuration, there is activity still need to be set'
      );
    } else if (validChecklist.includes(false)) {
      setType('error');
      setShow(true);
      setNotifText(
        'Please check checklist configuration, there is checklist document still need to be set'
      );
    } else {
      console.log(sendData);
      dispacth(actions.confrimChecklistPO(access_token, id, sendData));
    }
  };

  const handleActionMessage = () => {
    setType('error');
    setShow(true);
    setNotifText('Please Confirm PO document checklist');
  };

  React.useEffect(() => {
    // role model object
    /*
    const [tempChecklist, setTempChecklist] = React.useState([
      {
        id: 1,
        activity_id: 0,
        checklist_attachment_id: [],
      },
    ]);

    */

    if (po_detail?.status !== 'need_confirm') {
      if (po_detail?.checklists?.length > 0) {
        let tempArr = [];
        let resultArr = [];

        po_detail?.checklists?.map((check, index) => {
          tempArr.push(check.activity_id);
        });

        let uniqueArr = [...new Set(tempArr)];

        uniqueArr.map((item, index) => {
          let tempData = {
            id: index + 1,
            activity_id: item,
            checklist_attachment_id: [],
          };

          po_detail?.checklists?.map((e, eIndex) => {
            if (e?.activity_id === item) {
              if (e?.checklist_attachment?.name !== 'Other') {
                tempData.checklist_attachment_id.push({
                  id: e.checklist_attachment?.id,
                  name: e.checklist_attachment?.name,
                  isCheck: true,
                });
              }
            }
          });
          resultArr.push(tempData);
        });

        setTempChecklist(resultArr);
      } else {
        setTempChecklist([
          {
            id: 1,
            activity_id: 0,
            checklist_attachment_id: [],
          },
        ]);
      }
    }
  }, [checklist_data, activities_data]);

  React.useEffect(() => {
    if (confirm_checklist_Message) {
      if (
        confirm_checklist_Message == 'Confirm Activity Purchase Order Success'
      ) {
        setCanConfirm(true);
        setType('success');
        setShow(true);
        setNotifText(
          'Successfull set Checklist Document, now you can confirm this PO'
        );
      } else if (
        confirm_checklist_Message !== '' &&
        confirm_checklist_Message !== 'Confirm Activity Purchase Order Success'
      ) {
      }
      dispacth(actions.setBlankConfirmChecklist());
    }
  }, [confirm_checklist_Message]);

  // end handling checklist configuration

  React.useEffect(() => {
    dispacth(actions.getPODetail(access_token, id));
  }, []);

  React.useEffect(() => {
    po_detail?.status !== 'need_confirm' &&
    po_detail?.approval_transactions?.length > 0
      ? setIsStatusNeed(true)
      : setIsStatusNeed(false);

    if (
      po_detail?.status === 'need_confirm' ||
      po_detail?.status === 'rejected'
    ) {
      setCanDelete(true);
      setCanUpload(true);
    } else {
      setCanDelete(false);
      setCanUpload(false);
    }

    po_detail?.qr_code ? setBtnQRon(false) : setBtnQRon(true);
    po_detail?.qr_code ? setCanDownloadPDF(true) : setCanDownloadPDF(false);

    let readData = {
      doc_type: 'PR',
      doc_id: po_detail?.pr?.id,
      entity_code: po_detail?.entity?.code,
    };

    dispacth(actions_2.readFile(access_token, readData));

    handleGETFilePO();

    let tempCompany = {
      company: po_detail?.customer_name,
      address: po_detail?.vendor_address,
      phone: po_detail?.vendor_phone,
      fax: po_detail?.vendor_fax,
      contact_person: po_detail?.contact_person,
      npwp: po_detail?.tax_id_customer,
    };

    setCompany(tempCompany);

    // for get checklis
    dispacth(settingAction.getChecklist(access_token, { type: po_detail?.doc_type }));
    dispacth(settingAction.getActivities(access_token));

    // for get internal user
    dispacth(userManagement.getInternalAllUser(access_token));

    // for if doc_type not service
    // po_detail?.doc_type !== 'service'
    //   ? setCanConfirm(true)
    //   : 
      setCanConfirm(false);
      
    if(po_detail?.entity?.color){
      dispacth(settingAction.setBackgroundColor(po_detail?.entity?.color))
    }
  }, [po_detail]);

    //unmount
    React.useEffect(()=>{
      return () => dispacth(settingAction.setBackgroundColor(''))
    },[])

  React.useEffect(() => {
    if (read_data && read_data.length > 0) {
      setShowFileData(true);
      setFileData(read_data);
    }
  }, [read_data]);

  React.useEffect(() => {
    if (read_data_second && read_data_second.length > 0) {
      setShowFileDataSecond(true);
      setFileDataSecond(read_data_second);
    } else {
      setShowFileDataSecond(false);
      setFileDataSecond([]);
    }
  }, [read_data_second]);

  React.useEffect(() => {
    if (assigment_pomessage) {
      router.push('/purchase-order');
      dispacth(actions.setAssigmentMessage());
    }
  }, [assigment_pomessage]);

  React.useEffect(() => {
    if (cancel_poMessage) {
      if (cancel_poMessage === 'Update cancel status purchase order Success') {
        setShowModalConfirm(false);
        setShowModalSuccess(true);
      } else if (cancel_poMessage !== '') {
        setType('error');
        setShow(true);
        setNotifText(cancel_poMessage);
      }
      dispacth(actions.setBlankCancel());
    }
  }, [cancel_poMessage]);

  React.useEffect(() => {
    if (del_file_message) {
      if (del_file_message === 'Delete file Success') {
        setShowModalDelete(false);
        setShowModalSuccessDel(true);
      } else if (
        del_file_message !== '' &&
        del_file_message !== 'Delete file Success'
      ) {
        setType('error');
        setShow(true);
        setNotifText(del_file_message);
      }
      dispacth(actions_2.setBlankDelFile());
    }
  }, [del_file_message]);

  // React.useEffect(() => {
  //   dispacth(userManagement.getInternalAllUser(access_token));
  // }, []);

  const findApprovalTransactionUserId = (level) => {
    return po_detail?.approval_transactions?.find(v=>v.level===level)?.user?.id;
  }

  React.useEffect(() => {
    if (internal_all_data) {
      setArpprovalOne(
        findApprovalTransactionUserId(1) ?? null
      );
      setArpprovalTwo(findApprovalTransactionUserId(2) ?? null);
      setArpprovalThree(
        findApprovalTransactionUserId(3) ?? null
      );
    }
  }, [internal_all_data]);

  React.useEffect(() => {
    let filterUser = internal_all_data?.filter(
      (user) =>
        user?.id !== aprrovalOne &&
        user?.id !== aprrovalTwo &&
        user?.id !== aprrovalThree
    );

    setRowApproval(filterUser);
  }, [aprrovalOne, aprrovalTwo, aprrovalThree]);

  // Generarte Qr
  // Change with url Stating or Production late
  let url = `https://dkt.senisites.com/purchase-order-detail/poId=${po_detail?.id}`;

  const generateQrCode = () => {
    let sendData = {
      doc_type: 'PO',
      entity_id: po_detail?.entity_id,
      doc_id: po_detail?.id,
      url,
    };

    dispacth(actions_2.getQrCode(access_token, sendData));
    setShowModalQRCode(true);
  };

  // for render styling
  const _renderStatus = (status) => {
    if (status === 'need_confirm') {
      return (
        <ContentText
          styling={classes.statusNeed}
          content={`Confirm Approval`}
        />
      );
    } else if (status === 'closed') {
      return <ContentText styling={classes.statusClose} content={`Closed`} />;
    } else if (status === 'waiting') {
      return (
        <ContentText
          styling={classes.statusWaiting}
          content={`Waiting Approval`}
        />
      );
    } else if (status === 'rejected') {
      return (
        <ContentText styling={classes.statusReject} content={`Rejected`} />
      );
    } else if (status === 'approved') {
      return <ContentText styling={classes.statusApprove} content={status} />;
    }
  };

  const _renderButtonAction = (status) => {
    if (status === 'closed' || status === 'approved') {
      return null
      // return (
      //   <ButtonQrCode
      //     isOn={btnQRon}
      //     onPress={generateQrCode}
      //     showModal={() => setShowModalQRCode(true)}
      //     handleCanclePR={() => setShowModalConfirm(true)}
      //     hiddenCancel={globalConfig.cancelButton.hideOnStatus.includes(status) || !Lib.allowCancel(data)}
      //     title="PO"
      //   />
      // );
    } else if (status === 'need_confirm' || status === 'waiting') {
      return (
        <ButtonTransparent
          setShowModalConfirm={setShowModalConfirm}
          isComplete={true}
          text={'Cancel PO'}
          hiddenCancel={!Lib.allowCancel(data)}
        />
      );
    } else if (status === 'rejected' && Lib.allowCancel(data)) {
      return (
        <ButtonCancel
          title={'PO'}
          handleCanclePR={() => setShowModalConfirm(true)}
          approval={po_detail?.approval_transactions}
        />
      );
    }
  };

  return (
    <>
      {detail_isLoading ? (
        <SKPODetail />
      ) : (
        <>
          <Grid container>
            <CustomizedSnackbars
              show={show}
              handleClose={handleClose}
              message={notifText}
              type={type}
            />
            {/* modal delete */}
            <ModalDeleteItem
              open={showModalDelete}
              close={() => setShowModalDelete(false)}
              id={tempDeleteId}
              onPress={reqDeleteFile}
              isLoading={del_file_isLoading}
            />
            <ModalSuccess
              open={showModalSuccesDel}
              onPress={handleSuccesDelete}
            />
            {/* for cancel PO */}
            <ModalSuccess
              open={showModalSuccess}
              setModalConfirm={setShowModalSuccess}
              onPress={() => router.push('/purchase-order')}
            />
            <ModalCancelPr
              open={showModalConfirm}
              setModalConfirm={setShowModalConfirm}
              handleCloseModalConfirm={handleCloseModalConfirm}
              onPress={() => handleCancelPO(po_detail.id)}
              title="PO"
              isLoading={cancel_poLoading}
            />
            <ModalQRCode
              open={showModalQRCode}
              // handleCloseModalQRCode={handleCloseModalQRCode}
              title="PO"
              onClose={handleCloseQR}
              qrCode={qrcode_data}
              isLoading={qrcode_loading}
              no={po_detail?.doc_entry}
              name={po_detail?.pr?.purpose_of_request ?? '-'}
              activity={
                po_detail?.pr?.items?.length > 0
                  ? po_detail?.pr?.items[0]?.activity_name ?? '-'
                  : '-'
              }
              total={po_detail?.doc_total_amount}
            />
            <ModalUpload
              open={showModalUpload}
              handleCloseModalUpload={handleCloseModalUpload}
              onPress={() => setShowModalConfirmUpload(true)}
              imageColletion={imageColletion}
              handleRemoveFile={handleRemoveFile}
              onFileChange={onFileChange}
            />
            <ModalConfirmUpload
              open={showModalConfirmUpload}
              handleCloseModalConfirmUpload={handleCloseModalConfirmUpload}
              onPress={handleUploadFile}
              uploadPercent={percent}
              isLoading={uploadLoading}
              title="PO"
            />
            <ModalSingleUpload
              open={showModalSingleUpload}
              close={handleCloseModalSingleUpload}
            />
            <POpreview
              open={showPDFpreview}
              close={handleClosePDFpreview}
              data={po_detail}
            />
            <Grid md={12} sm={12} xs={12}>
              <Box className={classes.formTitle}>
                <ContentText
                  styling={classes.formNumber}
                  variant={'body1'}
                  content={`PO No. ${po_detail?.doc_num}`}
                />
                <Box className={classes.wrapperStatusForm}>
                  <ContentText
                    styling={classes.bodyDataText}
                    variant={'body1'}
                    content={dateFormater(
                      `${po_detail?.doc_date} ${po_detail?.doc_time}`,
                      'DD-MM-YYYY hh:mm'
                    )}
                  />
                  {_renderStatus(po_detail?.status)}
                </Box>
              </Box>
              <Box className={classes.borderLine} />
            </Grid>
            <Grid md={8} sm={12} xs={12} style={{ backgroundColor: '#fff' }}>
              <Box className={classes.wrapperInformation}>
                <Box className={classes.informationContent}>
                  <Box className={classes.informationHeader}>
                    <ContentText
                      styling={classes.headerText}
                      variant={'body1'}
                      content={`PO Information`}
                    />

                    {canDownloadPDF ? (
                      <Box
                        className={classes.informationHeaderLink}
                        onClick={() => handleSeeImage(po_detail?.qr_code)}
                        // onClick={handleLinkPDF}
                      >
                        <img
                          className={classes.bellImage}
                          src={PdfFile}
                          alt="image not found"
                        />
                        <ContentText
                          styling={classes.headerLink}
                          variant={'body1'}
                          content={`View PO`}
                        />
                      </Box>
                    ) : (
                      <Box
                        className={classes.informationHeaderLink}
                        onClick={() => setShowPDFpreview(true)}
                        // onClick={handleLinkPDF}
                      >
                        <img
                          className={classes.bellImage}
                          src={PdfFile}
                          alt="image not found"
                        />
                        <ContentText
                          styling={classes.headerLink}
                          variant={'body1'}
                          content={`View PO`}
                        />
                      </Box>
                    )}
                  </Box>
                  <Box className={classes.infromationBody}>
                    <Grid container md={12} sm={12} xs={12}>
                      <Grid md={6} sm={4} xs={4}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <ContentText
                              styling={classes.bodyTitle}
                              variant={'body1'}
                              content={`Purpose of Request`}
                            />
                          </Box>
                          <Box className={classes.bodyData}>
                            <ContentText
                              styling={classes.bodyDataText}
                              variant={'body1'}
                              content={po_detail?.pr?.purpose_of_request ?? '-'}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid md={3} sm={4} xs={4}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <ContentText
                              styling={classes.bodyTitle}
                              variant={'body1'}
                              content={`Currency`}
                            />
                          </Box>
                          <Box className={classes.bodyData}>
                            <ContentText
                              styling={classes.bodyDataText}
                              variant={'body1'}
                              content={po_detail?.currency ?? '-'}
                            />
                          </Box>
                        </Box>
                      </Grid>

                      <Grid md={3} sm={4} xs={4}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <ContentText
                              styling={classes.bodyTitle}
                              variant={'body1'}
                              content={`Grand Total`}
                            />
                          </Box>
                          <Box className={classes.bodyData}>
                            <ContentText
                              styling={classes.bodyDataText}
                              variant={'body1'}
                              content={
                                po_detail?.currency == 'IDR'
                                  ? formatRupiah(po_detail?.doc_total_amount)
                                  : po_detail?.doc_total_amount
                              }
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classes.infromationBody}>
                    <Grid container md={12}>
                      <Grid md={6} sm={6} xs={6}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <ContentText
                              styling={classes.bodyTitle}
                              variant={'body1'}
                              content={`Department`}
                            />
                          </Box>
                          <Box className={classes.bodyData}>
                            <Box className={classes.boxDepartment}>
                              <ContentText
                                styling={classes.bodyDataText}
                                variant={'body1'}
                                content={po_detail?.pr?.department?.code}
                              />
                              <ContentText
                                styling={classes.bodyDataSubText}
                                variant={'body1'}
                                content={`- ${
                                  po_detail?.pr?.department?.name ?? '-'
                                }`}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid md={6} sm={6} xs={6}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <ContentText
                              styling={classes.bodyTitle}
                              variant={'body1'}
                              content={`Entity`}
                            />
                          </Box>
                          <Box className={classes.bodyData}>
                            <ContentText
                              styling={classes.bodyDataText}
                              variant={'body1'}
                              content={po_detail?.entity?.name}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classes.infromationBody}>
                    <Grid container md={12}>
                      <Grid md={6} sm={12} xs={12}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <ContentText
                              styling={classes.bodyTitle}
                              variant={'body1'}
                              content={`Activity`}
                            />
                          </Box>
                          <Box className={classes.bodyData}>
                            <ContentText
                              styling={classes.bodyDataText}
                              variant={'body1'}
                              content={
                                po_detail?.pr?.items?.length > 0
                                  ? po_detail?.pr?.items[0]?.activity_name ??
                                    '-'
                                  : '-'
                              }
                            />
                          </Box>
                        </Box>
                      </Grid>
                      {po_detail?.approval_code ? (
                        <Grid md={6}>
                          <Box className={classes.wrapperBody}>
                            <Box className={classes.bodyContent}>
                              <ContentText
                                styling={classes.bodyTitle}
                                variant={'body1'}
                                content={`Approval Code`}
                              />
                            </Box>
                            <Box className={classes.bodyData}>
                              <ContentText
                                styling={classes.bodyDataText}
                                variant={'body1'}
                                content={po_detail?.approval_code ?? '-'}
                              />
                            </Box>
                          </Box>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Box>
                  <Box className={classes.sizeBox} />
                  <ContentText
                    styling={classes.bodyTitle}
                    variant={'body1'}
                    content={`VENDOR/SUPPLIER`}
                  />
                  <CompanyInfromation data={company} />
                  <Box className={classes.infromationAttachment}>
                    <Grid container>
                      <Grid md={12} style={{ marginBottom: 24 }}>
                        <Box>
                          <ContentText
                            styling={classes.AttachmentTitle}
                            variant={'body1'}
                            content={`Attachment`}
                          />
                        </Box>
                      </Grid>
                      <Grid xs={12} md={12} container>
                        <Grid md={6}>
                          <ContentText
                            styling={classes.uploadTitle}
                            variant={'body1'}
                            content={`PR Document`}
                          />
                          <Box className={classes.sizeTitle} />

                          {read_loading ? (
                            <CircleLoading />
                          ) : (
                            fileData?.map((data, index) => (
                              <>
                                <Box
                                  key={index}
                                  className={classes.wrapperLink}
                                  onClick={() => handleSeeImage(data?.url)}
                                >
                                  <Box>
                                    <img
                                      // className={classes.bellImage}
                                      src={Paper}
                                      alt="image not found"
                                    />
                                  </Box>
                                  <ContentText
                                    styling={classes.bodyUrlText}
                                    variant={'body1'}
                                    content={`${
                                      data.file_name
                                    } ${formatFileCount(data?.size)} MB`}
                                  />
                                </Box>
                                <Box className={classes.sizeSubLink} />
                              </>
                            ))
                          )}
                          {po_detail?.pr?.qr_code && (
                            <Box
                              className={classes.informationHeaderLink}
                              onClick={() =>
                                handleSeeImage(po_detail?.pr?.qr_code)
                              }
                            >
                              <ContentText
                                styling={classes.headerLink}
                                variant={'body1'}
                                content={`View PR`}
                              />
                            </Box>
                          )}
                        </Grid>
                        <Grid md={6}>
                          <ContentText
                            styling={classes.uploadTitle}
                            variant={'body1'}
                            content={`Other Document`}
                          />
                          {!canUpload && fileDataSecond?.length < 1 && (
                            <>
                              <Box className={classes.sizeTitle} />
                              <ContentText
                                styling={classes.AttachmentMessage}
                                variant={'body1'}
                                content={`No Document`}
                              />
                            </>
                          )}
                          <Box className={classes.sizeTitle} />
                          {read_loading_second ? (
                            <CircleLoading />
                          ) : canUpload &&
                            !showFileDataSecond &&
                            fileDataSecond?.length < 1 ? (
                            <>
                              <Box className={classes.btnUploadContent}>
                                <Box
                                  className={classes.btnUpload}
                                  onClick={() => setShowModalUpload(true)}
                                >
                                  <Box>
                                    <img
                                      className={classes.bellImage}
                                      src={UnduhSecond}
                                      alt="image not found"
                                    />
                                  </Box>
                                  <ContentText
                                    styling={classes.btnUploadValue}
                                    variant={'body1'}
                                    content={`Attach File`}
                                  />
                                </Box>
                              </Box>
                            </>
                          ) : fileDataSecond?.length < 1 ? (
                            <>
                              {/* if you want to add some file second null */}
                            </>
                          ) : fileDataSecond?.length > 0 ? (
                            fileDataSecond.map((data, index) => (
                              <>
                                <Box className={classes.wrapperLink}>
                                  <Box className={classes.wrapperLink}>
                                    <Box
                                      className={classes.wrapperLinkFile}
                                      onClick={() => handleSeeImage(data?.url)}
                                    >
                                      <Box>
                                        <img
                                          src={Paper}
                                          alt="image not found"
                                        />
                                      </Box>
                                      <ContentText
                                        styling={classes.bodyUrlText}
                                        variant={'body1'}
                                        content={`${data.file_name} `}
                                      />

                                      <ContentText
                                        styling={classes.textFileCount}
                                        variant={'body1'}
                                        content={`${formatFileCount(
                                          data?.size
                                        )} MB`}
                                      />
                                    </Box>
                                    {canDelete ? (
                                      <TrashDelete
                                        onPress={() => handleDelete(data?.id)}
                                      />
                                    ) : null}
                                  </Box>
                                  {index + 1 === fileDataSecond.length &&
                                  canUpload ? (
                                    <Box
                                      className={classes.btnAddMoreUpload}
                                      onClick={() => setShowModalUpload(true)}
                                    >
                                      <img
                                        style={{ marginRight: 8 }}
                                        src={CirclePlus}
                                        alt="image not found"
                                      />

                                      <ContentText
                                        styling={classes.bodyDataTextBlue}
                                        variant={'body1'}
                                        content={`Add More`}
                                      />
                                    </Box>
                                  ) : null}
                                </Box>
                                <Box className={classes.sizeSubLink} />
                              </>
                            ))
                          ) : null}
                        </Grid>
                        {/* add in null for list file PO if status not null */}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Grid>
            {/* <Box className={classes.borderSide} /> */}
            <Grid md={4} sm={12} xs={12} style={{ backgroundColor: '#fff' }}>
              <Box className={classes.assignApprovalContent}>
                <Box className={classes.assignHeader}>
                  <ContentText
                    styling={classes.headerTitle}
                    variant={'body1'}
                    content={`Approve By`}
                  />
                </Box>
                <Box className={classes.filterActifityContent}>
                  <Box>
                    <Box className={classes.bodyContent}>
                      <ContentText
                        styling={classes.bodyTitle}
                        variant={'body1'}
                        content={`Prepared by`}
                      />
                    </Box>
                    {internal_all_IsLoading ? (
                      <Skeleton variant="text" width={'100%'} />
                    ) : (
                      <SelectFilter
                        masterData={internal_all_data}
                        data={rowApproval}
                        defaultValue={aprrovalOne}
                        onChange={handleChangeApprovalOne}
                        disabled={
                          po_detail?.status == 'need_confirm' ||
                          po_detail?.status == 'rejected'
                            ? false
                            : true
                        }
                      />
                    )}
                  </Box>
                  <Box className={classes.sizeBox} />
                  <Box>
                    <Box className={classes.bodyContent}>
                      <ContentText
                        styling={classes.bodyTitle}
                        variant={'body1'}
                        content={`Reviewed by`}
                      />
                    </Box>
                    {internal_all_IsLoading ? (
                      <Skeleton variant="text" width={'100%'} />
                    ) : (
                      <SelectFilter
                        masterData={internal_all_data}
                        data={rowApproval}
                        defaultValue={aprrovalTwo}
                        onChange={handleChangeApprovalTwo}
                        disabled={
                          po_detail?.status == 'need_confirm' ||
                          po_detail?.status == 'rejected'
                            ? false
                            : true
                        }
                      />
                    )}
                  </Box>
                  <Box className={classes.sizeBox} />
                  <Box>
                    <Box className={classes.bodyContent}>
                      <ContentText
                        styling={classes.bodyTitle}
                        variant={'body1'}
                        content={`Approved by`}
                      />
                    </Box>
                    {internal_all_IsLoading ? (
                      <Skeleton variant="text" width={'100%'} />
                    ) : (
                      <SelectFilter
                        masterData={internal_all_data}
                        data={rowApproval}
                        defaultValue={aprrovalThree}
                        onChange={handleChangeApprovalThree}
                        disabled={
                          po_detail?.status == 'need_confirm' ||
                          po_detail?.status == 'rejected'
                            ? false
                            : true
                        }
                      />
                    )}
                  </Box>
                </Box>
                {po_detail?.status === 'need_confirm' ? (
                  canConfirm ? (
                    <Box
                      className={
                        assignment_poloading
                          ? classes.BtnConfirmFalse
                          : classes.BtnConfirm
                      }
                      onClick={
                        assignment_poloading ? null : handleConfirmAssigment
                      }
                    >
                      <ContentText
                        styling={classes.BtnConfirmText}
                        variant={'body1'}
                        content={`Confirm Assignment`}
                      />
                    </Box>
                  ) : (
                    <Box
                      className={classes.BtnConfirmFalse}
                      onClick={handleActionMessage}
                    >
                      <ContentText
                        styling={classes.BtnConfirmText}
                        variant={'body1'}
                        content={`Confirm Assignment`}
                      />
                    </Box>
                  )
                ) : null}
              </Box>
              {/* {po_detail?.doc_type === 'service' ? ( */}
                <ChecklistMenu
                  tempChecklist={tempChecklist}
                  addMoreActivities={addMoreActivities}
                  deleteActivities={deleteActivities}
                  checklist_data={checklist_data}
                  checklist_isLoading={checklist_isLoading}
                  activities_data={activities_data}
                  activities_isLoading={activities_isLoading}
                  isLoading={detail_isLoading}
                  handleChecklistChange={handleChecklistChange}
                  handleChecklistChexBox={handleChecklistChexBox}
                  handleConfirmChecklis={handleConfirmChecklis}
                  actionIsLoading={confirm_checklist_isLoading}
                  status={po_detail?.status}
                />
              {/* ) : null} */}
            </Grid>
          </Grid>
          <Box className={classes.borderLine} />
          <Grid md={12}>
            {_renderButtonAction(po_detail?.status)}

            {/* <ButtonCancel handleCanclePR={() => setShowModalConfirm(true)} /> */}
          </Grid>
          <HistoryTableDetail
            showHistory={isStatusNeed}
            data={po_detail?.approval_transactions}
          />
        </>
      )}
    </>
  );
};

const useStyles = makeStyles({
  formTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  borderLine: {
    borderBottom: '1px solid #E7E7E8',
  },
  formNumber: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#1B2030',
  },
  wrapperStatusForm: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  formStatus: {
    fontSize: 14,
    lineHeight: '26px',
    color: '#969BAB',
  },
  // styling status
  statusNeed: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#F12B2C',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  statusWaiting: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#FFB800',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  statusClose: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    background: '#969BAB',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  statusReject: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#FD453A',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  statusApprove: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    background: '#27AE60',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  wrapperInformation: {
    paddingTop: 27,
    paddingBottom: 59,
    backgroundColor: '#fff',
  },
  informationContent: {
    // marginTop: 27,
    paddingLeft: 20,
    paddingRight: 18.5,
    backgroundColor: '#fff',
    // marginBottom: 58,
    borderRight: '1px solid #E7E7E8',
  },
  informationHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  informationHeaderLink: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
  },
  headerText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  headerLink: {
    fontSize: 14,
    lineHeight: '26px',
    fontWeight: 'bold',
    color: '#127EEE',
    marginLeft: 10,
  },
  infromationBody: {
    marginTop: 18.5,
    marginLeft: -10,
    marginRight: -10,
  },
  wrapperBody: {
    paddingRight: 10,
    paddingLeft: 10,
  },
  bodyTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
    marginBottom: 12,
  },
  bodyData: {
    width: '100%',
    backgroundColor: '#EBEFF0',
    padding: '12px 20px',
    border: '1px solid #E7E7E8',
    borderRadius: 8,
  },
  wrapperBodyGrandTotal: {},
  infromationAttachment: {
    marginTop: 30,
  },
  AttachmentTitle: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    fontWeight: 'bold',
  },
  AttachmentNotif: {
    marginTop: 20,
    marginBottom: 20,
    padding: 10,
    borderRadius: 4,
    backgroundColor: '#FFE9E8',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  AttachmentMessage: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#F12B2C',
  },
  btnUploadContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  btnUploadText: {
    fontSize: 14,
    lineHeight: '26px',
    color: '#1B2030',
  },
  btnUpload: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: '#127EEE',
    paddingLeft: 19,
    paddingRight: 19,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 8,
  },
  btnUploadValue: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
    marginLeft: 13,
  },
  assignApprovalContent: {
    paddingTop: 26,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 26,
    backgroundColor: '#fff',
    // borderBottom: '1px solid #E7E7E8',
  },
  headerTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  filterActifityContent: {
    marginTop: 20,
  },
  // styling Form select
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 5,
    paddingBottom: 5,
    // maxWidth: 220,
    width: '100%',
    // minWidth: 160,
    maxHeight: 50,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      // marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#969BAB',
      paddingRight: 2,
      backgroundColor: '#F8FAFB',
    },
  },
  sizeBox: {
    marginTop: 20,
  },
  btnOption: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  wrapperMandatory: {
    paddingLeft: 20,
    paddingTop: 15,
    paddingRight: 30,
    paddingBottom: 15,
    border: '1px solid #E7E7E8',
    borderRadius: 8,
  },
  sizeBoxTwo: {
    marginTop: 30,
  },
  textTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
  },
  titleContent: {
    marginBottom: 12,
  },
  supportContent: {
    marginTop: 20,
    marginBottom: 12,
  },
  uploadTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
  },
  bodyUrlText: {
    marginLeft: 10,
    fontSize: 16,
    lineHeight: '21px',
    textDecorationLine: 'underline',
    color: '#1B2030',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 150,
  },
  wrapperLink: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  wrapperLinkFile: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    width: 230,
  },
  textFileCount: {
    marginLeft: 2,
    fontSize: 16,
    lineHeight: '21px',
    textDecorationLine: 'underline',
    color: '#1B2030',
    width: 70,
  },
  sizeTitle: {
    marginTop: 21.5,
  },
  sizeSubLink: {
    marginTop: 31,
  },
  boxDepartment: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textTransform: 'ellipsis',
    // maxWidth: 270,
    paddingRight: 20,
  },
  bodyDataText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '95%',
  },
  bodyDataTextBlue: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#127EEE',
    fontWeight: 'bold',
  },
  bodyDataSubText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    marginLeft: 4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  BtnConfirm: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 14.5,
    paddingBottom: 14.5,
    backgroundColor: '#1B2030',
    borderRadius: 8,
    maxWidth: 193,
    marginTop: 20,
    cursor: 'pointer',
  },
  BtnConfirmFalse: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 14.5,
    paddingBottom: 14.5,
    backgroundColor: '#969BAB',
    borderRadius: 8,
    maxWidth: 193,
    marginTop: 20,
    cursor: 'pointer',
  },
  BtnConfirmText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
  },
  btnAddMoreUpload: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: 15,
    cursor: 'pointer',
  },
});

export default PoFormApproval;
