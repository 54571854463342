import * as types from './types';

// Just Example
export const clientVendorReducers = (
  state = {
    isLoading: false,
    message: '',
    data: null,
    getIsloading: false,
    getMessage: '',
    delIsLoading: false,
    delMessage: '',
    detailIsloading: false,
    detailMessage: '',
    detailData: null,
    searchData: null,
    searchIsLoading: false,
    searchMessage: '',
  },
  action
) => {
  switch (action.type) {
    // post send client vendor
    case types.CLIENTVENDOR_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case types.CLIENTVENDOR_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };

    // get client vendor
    case types.CLIENTVENDOR_GET_DATA:
      return {
        ...state,
        data: action.payload,
      };

    case types.CLIENTVENDOR_GET_LOADING:
      return {
        ...state,
        getIsloading: action.payload,
      };
    case types.CLIENTVENDOR_GET_MESSAGE:
      return {
        ...state,
        getMessage: action.payload,
      };

    // delete client vendor
    case types.CLIENTVENDOR_DELETE_MESSAGE:
      return {
        ...state,
        delMessage: action.payload,
      };
    case types.CLIENTVENDOR_DELETE_LOADING:
      return {
        ...state,
        delIsLoading: action.payload,
      };

    // get detail client vendor
    case types.CLIENTVENDOR_DETAIL_LOADING:
      return {
        ...state,
        detailIsloading: action.payload,
      };

    case types.CLIENTVENDOR_DETAIL_MESSAGE:
      return {
        ...state,
        detailMessage: action.payload,
      };
    case types.CLIENTVENDOR_DETAIL_DATA:
      return {
        ...state,
        detailData: action.payload,
      };
    //search client vendor
    case types.CLIENTVENDOR_SEARCH_LOADING:
      return {
        ...state,
        searchIsLoading: action.payload,
      };

    case types.CLIENTVENDOR_SEARCH_MESSAGE:
      return {
        ...state,
        searchMessage: action.payload,
      };
    case types.CLIENTVENDOR_SEARCH_DATA:
      return {
        ...state,
        searchData: action.payload,
      };

    default:
      return state;
  }
};
