import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import { dateFormater, formatRupiah } from '../../utils/formatter';
import Lib from 'src/utils/Lib';

// Images
import QRCODE from '../../assets/Images/QrCode.png';
import LogoFooter from '../../assets/Images/logoFooter.png';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 50,
    overflow: 'scroll',
    height: '100%',
    display: 'block',
  },
  paper: {
    backgroundColor: '#fff',
    height: 'auto',
    width: 793,
    maxWidth: 793,
    margin: '0 auto',
    minHeight: 1122,
    maxHeight: 1122,
    background: '#fff',
    position: 'relative',
  },
  btnClose: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 20,
  },
}));

export default function PRpreview({ open, close, data, isMobile }) {
  const classes = useStyles();

  // Configure to the value inside paper PDF
  // change in here will effect the value
  let prNo = data?.doc_num ?? '-';
  let docDate = dateFormater(data?.document_date, 'DD-MMMM-YYYY') ?? '-';

  let requestor = data?.requester ?? '-';
  let department = data?.department.name ?? '-';
  let email = data?.email_address ?? '-';
  let purposeReques = data?.purpose_of_request ?? '-';
  let budgetType = data?.budget_type ?? '-';

  let deliverName = 'Name';
  let requireOn = dateFormater(data?.required_date, 'DD-MMMM-YYYY') ?? '-';
  let supllierName = data?.supplier_name;
  let supplierAddres = data?.supplier_address ?? '-';
  let DocumentType = 'Service';

  let vatAmount = data?.vat_amount ?? '-';
  let freight = data?.freight ?? '-';
  let grandTotal = data?.grand_total
    ? formatRupiah(`${data?.grand_total ?? '0'}`)
    : '-';

  let remark = data?.remarks ?? '-';
  // end of configure

  // const _exportPdf = () => {
  //   console.log('pdf');
  //   html2canvas(document.querySelector('#capture')).then((canvas) => {
  //     // document.body.appendChild(canvas); // if you want see your screenshot in body.
  //     const imgData = canvas.toDataURL('image/png');
  //     const pdf = new jsPDF();
  //     pdf.addImage(imgData, 'PNG', 0, 0);
  //     pdf.save('download.pdf');
  //   });
  // };

  const renderRemark = () => {
    if(data?.status === 'cancelled'){
      return(
        <div className="custom-remark">
          {data?.status_label}<br/>
          {data?.sap_cancelled_at}<br/>
          By {data?.canceled_by_user?.name ?? '-'}
        </div>
      )
    }
    return null
  }

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={close}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <>
            {/* <Button variant="contained" color="primary" onClick={_exportPdf}>
              PDF
            </Button> */}
            <div className={classes.paper} id="capture">
              <table style={{ borderSpacing: 0, padding: 0, width: '100%' }}>
                <tr>
                  <td>
                    <table
                      style={{
                        margin: 0,
                        width: '100%',
                        borderSpacing: 0,
                        padding: 0,
                        height: 13,
                      }}
                    >
                      <tr>
                        <td
                          style={{
                            width: '30%',
                            backgroundColor: '#1b2030',
                            height: 13,
                          }}
                        ></td>
                        <td
                          style={{
                            width: '10%',
                            backgroundColor: '#e61724',
                            height: 13,
                          }}
                        ></td>
                        <td
                          style={{
                            width: '60%',
                            backgroundColor: '#ac121c',
                            height: 13,
                          }}
                        ></td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
              <table style={{ borderSpacing: 0, padding: 0, width: '100%' }}>
                <tr>
                  <td>
                    <table
                      style={{
                        margin: 0,
                        width: '100%',
                        borderSpacing: 0,
                        height: 18,
                        padding: 0,
                        marginTop: 30,
                        position: 'relative',
                      }}
                    >
                      <tr>
                        <td
                          colSpan="2"
                          style={{
                            paddingLeft: 27,
                            fontSize: 21,
                            lineHeight: '32px',
                            fontWeight: 'bold',
                            color: '#fd453a',
                          }}
                        >
                          PURCHASE REQUISITION (PR)
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            paddingTop: 15,
                            paddingLeft: 27,
                            fontSize: 12,
                            lineHeight: '27px',
                            color: '#969bab',
                            width: '20%',
                          }}
                        >
                          No.{' '}
                          <span
                            style={{
                              fontSize: 12,
                              lineHeight: '27px',
                              color: '#969bab',
                              fontWeight: 'bold',
                            }}
                          >
                            {prNo}
                          </span>
                        </td>
                        <td
                          style={{
                            paddingTop: 15,
                            paddingLeft: 32,
                            fontSize: 12,
                            lineHeight: '16px',
                            color: '#969bab',
                            fontWeight: 'bold',
                          }}
                        >
                          {docDate}
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'flex-end',
                    }}
                  >
                    <div
                      style={{
                        position: 'relative',
                        top: 0,
                        // marginTop: -12,
                        paddingRight: 27,
                      }}
                    >
                      {Lib.getLogo(data)}
                    </div>
                  </td>
                </tr>
              </table>
              <table
                style={{
                  borderSpacing: 0,
                  padding: 0,
                  width: '100%',
                  marginTop: 22,
                }}
              >
                <tr>
                  <td
                    style={{
                      width: '36%',
                      fontSize: 12,
                      paddingLeft: 27,
                      lineHeight: '16px',
                      color: '#000000',
                      fontWeight: 'bold',
                    }}
                  >
                    Requestor :
                  </td>
                  <td
                    style={{
                      width: '64%',
                      fontSize: 12,
                      paddingLeft: 27,
                      lineHeight: '16px',
                      color: '#000000',
                      fontWeight: 'bold',
                    }}
                  >
                    Deliver to:
                  </td>
                </tr>
              </table>
              <table
                style={{
                  borderSpacing: 0,
                  padding: 0,
                  // width: '100%',
                  marginTop: 10,
                  marginLeft: 27,
                }}
              >
                <tr>
                  <td
                    style={{
                      width: 275,
                      padding: 10,
                      backgroundColor: '#F8FAFB',
                    }}
                  >
                    <p
                      style={{
                        fontSize: 12,
                        lineHeight: '16px',
                        color: '#000000',
                        fontWeight: 'bold',
                      }}
                    >
                      {requestor}
                    </p>
                    <tr>
                      <td
                        style={{
                          width: 110,
                          fontSize: 12,
                          lineHeight: '24px',
                          color: '#000000',
                          paddingTop: 9,
                          paddingBottom: 3,
                          verticalAlign: 'top',
                        }}
                      >
                        Department
                      </td>
                      <td
                        style={{
                          fontSize: 12,
                          lineHeight: '24px',
                          color: '#000000',
                          paddingTop: 9,
                          paddingBottom: 3,
                          verticalAlign: 'top',
                        }}
                      >
                        : {department}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          width: 120,
                          fontSize: 12,
                          lineHeight: '24px',
                          color: '#000000',
                          paddingTop: 3,
                          paddingBottom: 3,
                          verticalAlign: 'top',
                        }}
                      >
                        Email Address
                      </td>
                      <td
                        style={{
                          fontSize: 12,
                          lineHeight: '24px',
                          color: '#000000',
                          paddingTop: 3,
                          paddingBottom: 3,
                          verticalAlign: 'top',
                        }}
                      >
                        : {email}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          width: 120,
                          fontSize: 12,
                          lineHeight: '24px',
                          color: '#000000',
                          paddingTop: 3,
                          paddingBottom: 3,
                          verticalAlign: 'top',
                        }}
                      >
                        Purpose Of Request
                      </td>
                      <td
                        style={{
                          fontSize: 12,
                          lineHeight: '24px',
                          color: '#000000',
                          paddingTop: 3,
                          paddingBottom: 3,
                          verticalAlign: 'top',
                        }}
                      >
                        : {purposeReques}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          width: 120,
                          fontSize: 12,
                          lineHeight: '24px',
                          color: '#000000',
                          paddingTop: 3,
                          paddingBottom: 3,
                        }}
                      >
                        Budget Type
                      </td>
                      <td
                        style={{
                          fontSize: 12,
                          lineHeight: '24px',
                          color: '#000000',
                          paddingTop: 3,
                          paddingBottom: 3,
                          verticalAlign: 'top',
                        }}
                      >
                        : {budgetType}
                      </td>
                    </tr>
                  </td>
                  {/* Section Two */}
                  <td
                    style={{
                      width: 340,
                      padding: 10,
                      backgroundColor: '#F8FAFB',
                    }}
                  >
                    <p
                      style={{
                        fontSize: 12,
                        lineHeight: '16px',
                        color: '#000000',
                        fontWeight: 'bold',
                      }}
                    >
                      {deliverName}
                    </p>
                    <tr>
                      <td
                        style={{
                          width: 120,
                          fontSize: 12,
                          lineHeight: '24px',
                          color: '#000000',
                          paddingTop: 9,
                          paddingBottom: 3,
                        }}
                      >
                        Require On
                      </td>
                      <td
                        style={{
                          fontSize: 12,
                          lineHeight: '24px',
                          color: '#000000',
                          paddingTop: 9,
                          paddingBottom: 3,
                        }}
                      >
                        : {requireOn}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          width: 120,
                          fontSize: 12,
                          lineHeight: '24px',
                          color: '#000000',
                          paddingTop: 3,
                          paddingBottom: 3,
                        }}
                      >
                        Supplier's Name
                      </td>
                      <td
                        style={{
                          fontSize: 12,
                          lineHeight: '24px',
                          color: '#000000',
                          paddingTop: 3,
                          paddingBottom: 3,
                        }}
                      >
                        : {supllierName}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          width: 120,
                          fontSize: 12,
                          lineHeight: '24px',
                          color: '#000000',
                          paddingTop: 3,
                          paddingBottom: 3,
                        }}
                      >
                        Supplier's Address
                      </td>
                      <td
                        style={{
                          fontSize: 12,
                          lineHeight: '24px',
                          color: '#000000',
                          paddingTop: 3,
                          paddingBottom: 3,
                          verticalAlign: 'top',
                        }}
                      >
                        : {supplierAddres}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          width: 120,
                          fontSize: 12,
                          lineHeight: '24px',
                          color: '#000000',
                          paddingTop: 3,
                          paddingBottom: 3,
                        }}
                      >
                        Document Type
                      </td>
                      <td
                        style={{
                          fontSize: 12,
                          lineHeight: '24px',
                          color: '#000000',
                          paddingTop: 3,
                          paddingBottom: 3,
                          verticalAlign: 'top',
                        }}
                      >
                        : {DocumentType}
                      </td>
                    </tr>
                  </td>
                  {/* Section three
                <td
                  style={{
                    width: '28%',
                    padding: 10,
                    maxWidth: 200,
                    paddingLeft: 48,
                    verticalAlign: 'top',
                  }}
                >
                  <div
                    style={{
                      width: 140,
                      height: 137,
                      border: '1px solid #000000',
                      borderRadius: 8,

                      paddingBottom: 1,
                    }}
                  >
                    <img
                      style={{
                        marginTop: 13,
                        marginLeft: 28,
                        marginRight: 28,
                        width: 82.5,
                        height: 82.5,
                      }}
                      src={QRCODE}
                      alt=""
                    />
                    <p
                      style={{
                        marginTop: 13,
                        marginRight: 22,
                        marginLeft: 22,
                        fontSize: 11,
                        lineHeight: '26px',
                        fontWeight: 'bold',
                        color: '#1B2030',
                      }}
                    >
                      PR210810999999
                    </p>
                  </div>
                  <p
                    style={{
                      fontSize: 12,
                      lineHeight: '16px',
                      color: '#969BAB',
                      textAlign: 'right',
                      fontWeight: 'bold',
                      marginTop: 18,
                      paddingRight: 20,
                    }}
                  >
                    Currency: IDR
                  </p>
                </td>
          */}
                </tr>
              </table>
              {/* Table Data */}
              <table
                style={{
                  borderSpacing: 0,
                  padding: 0,
                  width: '100%',
                  marginTop: 21,
                }}
              >
                <tr>
                  <td style={{ paddingLeft: 24, paddingRight: 27 }}>
                    <table style={{ width: '100%' }}>
                      <thead>
                        <tr>
                          <th
                            style={{
                              width: '5%',
                              height: 44,
                              backgroundColor: '#333642',
                              color: '#fff',
                              fontSize: 12,
                              lineHeight: '16px',
                              fontWeight: 'bold',
                              borderTopLeftRadius: 8,
                              paddingLeft: 4,
                              paddingRight: 10,
                            }}
                          >
                            No
                          </th>
                          <th
                            style={{
                              width: '20%',
                              height: 44,
                              backgroundColor: '#333642',
                              color: '#fff',
                              fontSize: 12,
                              lineHeight: '16px',
                              fontWeight: 'bold',
                              textAlign: 'left',
                              paddingLeft: 4,
                              paddingRight: 10,
                            }}
                          >
                            Description
                          </th>
                          <th
                            style={{
                              width: '15%',
                              height: 44,
                              backgroundColor: '#333642',
                              color: '#fff',
                              fontSize: 12,
                              lineHeight: '16px',
                              fontWeight: 'bold',
                              textAlign: 'left',
                              paddingLeft: 4,
                              paddingRight: 10,
                            }}
                          >
                            Specification
                          </th>
                          <th
                            style={{
                              width: '10%',
                              height: 44,
                              backgroundColor: '#333642',
                              color: '#fff',
                              fontSize: 12,
                              lineHeight: '16px',
                              fontWeight: 'bold',
                              textAlign: 'left',
                              paddingLeft: 4,
                              paddingRight: 10,
                            }}
                          >
                            Brand
                          </th>
                          <th
                            style={{
                              width: '10%',
                              height: 44,
                              backgroundColor: '#333642',
                              color: '#fff',
                              fontSize: 12,
                              lineHeight: '16px',
                              fontWeight: 'bold',
                              textAlign: 'left',
                              paddingLeft: 4,
                              paddingRight: 10,
                            }}
                          >
                            Product
                          </th>
                          <th
                            style={{
                              width: '10%',
                              height: 44,
                              backgroundColor: '#333642',
                              color: '#fff',
                              fontSize: 12,
                              lineHeight: '16px',
                              fontWeight: 'bold',
                              textAlign: 'left',
                              paddingLeft: 4,
                              paddingRight: 10,
                            }}
                          >
                            Position
                          </th>
                          <th
                            style={{
                              width: '6%',
                              height: 44,
                              backgroundColor: '#333642',
                              color: '#fff',
                              fontSize: 12,
                              lineHeight: '16px',
                              fontWeight: 'bold',
                              textAlign: 'left',
                              paddingLeft: 4,
                              paddingRight: 10,
                            }}
                          >
                            Qty
                          </th>
                          <th
                            style={{
                              width: '12%',
                              height: 44,
                              backgroundColor: '#333642',
                              color: '#fff',
                              fontSize: 12,
                              lineHeight: '16px',
                              fontWeight: 'bold',
                              textAlign: 'left',
                              paddingLeft: 4,
                              paddingRight: 10,
                            }}
                          >
                            Price
                          </th>
                          <th
                            style={{
                              width: '12%',
                              height: 44,
                              backgroundColor: '#333642',
                              color: '#fff',
                              fontSize: 12,
                              lineHeight: '16px',
                              fontWeight: 'bold',
                              borderTopRightRadius: 8,
                              textAlign: 'left',
                              paddingLeft: 4,
                              paddingRight: 10,
                            }}
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.items?.map((item, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                fontSize: 12,
                                lineHeight: '16px',
                                backgroundColor: '#F8FAFB',
                                minHeight: 40,
                                borderLeft: '1px solid #E7E7E8',
                                textAlign: 'center',
                                paddingLeft: 4,
                                paddingRight: 10,
                                paddingTop: 12,
                                paddingBottom: 12,
                              }}
                            >
                              {index + 1}
                            </td>
                            <td
                              style={{
                                fontSize: 12,
                                lineHeight: '16px',
                                backgroundColor: '#F8FAFB',
                                minHeight: 40,
                                paddingLeft: 4,
                                paddingRight: 10,
                                paddingTop: 12,
                                paddingBottom: 12,
                              }}
                            >
                              {item.description}
                            </td>
                            <td
                              style={{
                                fontSize: 12,
                                lineHeight: '16px',
                                backgroundColor: '#F8FAFB',
                                minHeight: 40,
                                paddingLeft: 4,
                                paddingRight: 10,
                                paddingTop: 12,
                                paddingBottom: 12,
                              }}
                            >
                              {item.item_spec}
                            </td>
                            <td
                              style={{
                                fontSize: 12,
                                lineHeight: '16px',
                                backgroundColor: '#F8FAFB',
                                minHeight: 40,
                                paddingLeft: 4,
                                paddingRight: 10,
                                paddingTop: 12,
                                paddingBottom: 12,
                              }}
                            >
                              {item.brand}
                            </td>
                            <td
                              style={{
                                fontSize: 12,
                                lineHeight: '16px',
                                backgroundColor: '#F8FAFB',
                                minHeight: 40,
                                paddingLeft: 4,
                                paddingRight: 10,
                                paddingTop: 12,
                                paddingBottom: 12,
                              }}
                            >
                              {item.product}
                            </td>
                            <td
                              style={{
                                fontSize: 12,
                                lineHeight: '16px',
                                backgroundColor: '#F8FAFB',
                                minHeight: 40,
                                paddingLeft: 4,
                                paddingRight: 10,
                                paddingTop: 12,
                                paddingBottom: 12,
                              }}
                            >
                              {item.position}
                            </td>
                            <td
                              style={{
                                fontSize: 12,
                                lineHeight: '16px',
                                backgroundColor: '#F8FAFB',
                                minHeight: 40,
                                paddingLeft: 4,
                                paddingRight: 10,
                                paddingTop: 12,
                                paddingBottom: 12,
                              }}
                            >
                              {item.quantity}
                            </td>
                            <td
                              style={{
                                fontSize: 12,
                                lineHeight: '16px',
                                backgroundColor: '#F8FAFB',
                                minHeight: 40,
                                paddingLeft: 4,
                                paddingRight: 10,
                                textAlign: 'right',
                                paddingTop: 12,
                                paddingBottom: 12,
                              }}
                            >
                              {formatRupiah(item.price)}
                            </td>
                            <td
                              style={{
                                fontSize: 12,
                                lineHeight: '16px',
                                backgroundColor: '#F8FAFB',
                                minHeight: 40,
                                paddingLeft: 4,
                                paddingRight: 10,
                                textAlign: 'right',
                                borderRight: '1px solid #E7E7E8',
                                paddingTop: 12,
                                paddingBottom: 12,
                              }}
                            >
                              {formatRupiah(`${item.amount}`)}
                            </td>
                          </tr>
                        ))}

                        {/* second data */}
                        {/* <tr>
                        <td
                          style={{
                            fontSize: 12,
                            lineHeight: '16px',
                            backgroundColor: '#fff',
                            minHeight: 40,
                            borderLeft: '1px solid #E7E7E8',
                            textAlign: 'center',
                            paddingLeft: 4,
                            paddingRight: 10,
                            paddingTop: 12,
                            paddingBottom: 12,
                          }}
                        >
                          2
                        </td>
                        <td
                          style={{
                            fontSize: 12,
                            lineHeight: '16px',
                            backgroundColor: '#fff',
                            minHeight: 40,
                            paddingLeft: 4,
                            paddingRight: 10,
                            paddingTop: 12,
                            paddingBottom: 12,
                          }}
                        >
                          {DescriptionTwo}
                        </td>
                        <td
                          style={{
                            fontSize: 12,
                            lineHeight: '16px',
                            backgroundColor: '#fff',
                            minHeight: 40,
                            paddingLeft: 4,
                            paddingRight: 10,
                            paddingTop: 12,
                            paddingBottom: 12,
                          }}
                        >
                          {itemSpectTwo}
                        </td>
                        <td
                          style={{
                            fontSize: 12,
                            lineHeight: '16px',
                            backgroundColor: '#fff',
                            minHeight: 40,
                            paddingLeft: 4,
                            paddingRight: 10,
                            paddingTop: 12,
                            paddingBottom: 12,
                          }}
                        >
                          {brandTwo}
                        </td>
                        <td
                          style={{
                            fontSize: 12,
                            lineHeight: '16px',
                            backgroundColor: '#fff',
                            minHeight: 40,
                            paddingLeft: 4,
                            paddingRight: 10,
                            paddingTop: 12,
                            paddingBottom: 12,
                          }}
                        >
                          {productTwo}
                        </td>
                        <td
                          style={{
                            fontSize: 12,
                            lineHeight: '16px',
                            backgroundColor: '#fff',
                            minHeight: 40,
                            paddingLeft: 4,
                            paddingRight: 10,
                            paddingTop: 12,
                            paddingBottom: 12,
                          }}
                        >
                          {positionTwo}
                        </td>
                        <td
                          style={{
                            fontSize: 12,
                            lineHeight: '16px',
                            backgroundColor: '#fff',
                            minHeight: 40,
                            paddingLeft: 4,
                            paddingRight: 10,
                            paddingTop: 12,
                            paddingBottom: 12,
                          }}
                        >
                          {qtyTwo}
                        </td>
                        <td
                          style={{
                            fontSize: 12,
                            lineHeight: '16px',
                            backgroundColor: '#fff',
                            minHeight: 40,
                            paddingLeft: 4,
                            paddingRight: 10,
                            textAlign: 'right',
                            paddingTop: 4,
                            paddingBottom: 4,
                            paddingTop: 12,
                            paddingBottom: 12,
                          }}
                        >
                          {priceTwo}
                        </td>
                        <td
                          style={{
                            fontSize: 12,
                            lineHeight: '16px',
                            backgroundColor: '#fff',
                            minHeight: 40,
                            paddingLeft: 4,
                            paddingRight: 10,
                            textAlign: 'right',
                            borderRight: '1px solid #E7E7E8',
                            paddingTop: 12,
                            paddingBottom: 12,
                          }}
                        >
                          {formatRupiah(amountTwo)}
                        </td>
                      </tr> */}
                        <tr>
                          <td
                            colSpan={8}
                            style={{
                              paddingLeft: 20,
                              fontSize: 12,
                              lineHeight: '16px',
                              backgroundColor: '#F8FAFB',
                              fontWeight: 'bold',
                              paddingTop: 12,
                              paddingBottom: 12,
                              minHeight: 40,
                              borderTop: '1px solid #E7E7E8',
                              borderLeft: '1px solid #E7E7E8',
                            }}
                          >
                            Total
                          </td>
                          <td
                            colSpan={1}
                            style={{
                              paddingLeft: 20,
                              paddingRight: 10,
                              fontSize: 12,
                              lineHeight: '16px',
                              backgroundColor: '#F8FAFB',
                              fontWeight: 'bold',
                              minHeight: 40,
                              paddingTop: 12,
                              paddingBottom: 12,
                              borderTop: '1px solid #E7E7E8',
                              borderRight: '1px solid #E7E7E8',
                              textAlign: 'right',
                            }}
                          >
                            {formatRupiah(data?.total ?? '0')}
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan={8}
                            style={{
                              paddingLeft: 20,
                              fontSize: 12,
                              lineHeight: '16px',
                              backgroundColor: '#F8FAFB',
                              fontWeight: 'bold',
                              paddingTop: 12,
                              paddingBottom: 12,
                              minHeight: 40,
                              borderTop: '1px solid #E7E7E8',
                              borderLeft: '1px solid #E7E7E8',
                            }}
                          >
                            VAT Amount
                          </td>
                          <td
                            colSpan={1}
                            style={{
                              paddingLeft: 20,
                              paddingRight: 10,
                              fontSize: 12,
                              lineHeight: '16px',
                              backgroundColor: '#F8FAFB',
                              fontWeight: 'bold',
                              minHeight: 40,
                              paddingTop: 12,
                              paddingBottom: 12,
                              borderTop: '1px solid #E7E7E8',
                              borderRight: '1px solid #E7E7E8',
                              textAlign: 'right',
                            }}
                          >
                            {formatRupiah(vatAmount)}
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan={8}
                            style={{
                              paddingLeft: 20,
                              fontSize: 12,
                              lineHeight: '16px',
                              backgroundColor: '#F8FAFB',
                              fontWeight: 'bold',
                              paddingTop: 12,
                              paddingBottom: 12,
                              minHeight: 40,
                              borderTop: '1px solid #E7E7E8',
                              borderLeft: '1px solid #E7E7E8',
                            }}
                          >
                            Freight
                          </td>
                          <td
                            colSpan={1}
                            style={{
                              paddingLeft: 20,
                              paddingRight: 10,
                              fontSize: 12,
                              lineHeight: '16px',
                              backgroundColor: '#F8FAFB',
                              fontWeight: 'bold',
                              minHeight: 40,
                              paddingTop: 12,
                              paddingBottom: 12,
                              borderTop: '1px solid #E7E7E8',
                              borderRight: '1px solid #E7E7E8',
                              textAlign: 'right',
                            }}
                          >
                            {formatRupiah(freight)}
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan={8}
                            style={{
                              paddingLeft: 20,
                              fontSize: 12,
                              lineHeight: '16px',
                              backgroundColor: '#AC121C',
                              fontWeight: 'bold',
                              color: '#fff',
                              paddingTop: 12,
                              paddingBottom: 12,
                              minHeight: 40,
                              borderTop: '1px solid #E7E7E8',
                              borderLeft: '1px solid #E7E7E8',
                              borderBottomLeftRadius: 8,
                            }}
                          >
                            Grand Total
                          </td>
                          <td
                            colSpan={1}
                            style={{
                              paddingLeft: 20,
                              paddingRight: 10,
                              fontSize: 12,
                              lineHeight: '16px',
                              color: '#fff',
                              backgroundColor: '#AC121C',
                              fontWeight: 'bold',
                              minHeight: 40,
                              paddingTop: 12,
                              paddingBottom: 12,
                              borderTop: '1px solid #E7E7E8',
                              borderRight: '1px solid #E7E7E8',
                              borderBottomRightRadius: 8,
                              textAlign: 'right',
                            }}
                          >
                            {grandTotal}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>
              <table
                style={{
                  borderSpacing: 0,
                  padding: 0,
                  width: '100%',
                  marginTop: 25,
                }}
              >
                <tr>
                  <td
                    style={{
                      fontSize: 12,
                      lineHeight: '16px',
                      fontWeight: 'bold',
                      color: '#969BAB',
                      paddingLeft: 27,
                    }}
                  >
                    Account & Activity
                  </td>
                </tr>
              </table>
              <table
                style={{
                  borderSpacing: 0,
                  padding: 0,
                  width: '100%',
                  marginTop: 20,
                }}
              >
                <tr>
                  <td
                    style={{
                      paddingLeft: 27,
                      paddingRight: 27,
                      width: '100%',
                    }}
                  >
                    <table
                      style={{
                        width: '100%',
                        borderBottom: '1px solid #E7E7E8',
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              width: '10%',
                              height: 44,
                              backgroundColor: '#333642',
                              color: '#fff',
                              fontSize: 12,
                              lineHeight: '16px',
                              fontWeight: 'bold',
                              borderTopLeftRadius: 8,
                              paddingLeft: 4,
                              paddingRight: 10,
                            }}
                          >
                            No
                          </th>
                          <th
                            style={{
                              width: '10%',
                              height: 44,
                              backgroundColor: '#333642',
                              color: '#fff',
                              fontSize: 12,
                              lineHeight: '16px',
                              fontWeight: 'bold',
                              paddingLeft: 4,
                              paddingRight: 10,
                              textAlign: 'left',
                            }}
                          >
                            SOF
                          </th>
                          <th
                            style={{
                              width: '15%',
                              height: 44,
                              backgroundColor: '#333642',
                              color: '#fff',
                              fontSize: 12,
                              lineHeight: '16px',
                              fontWeight: 'bold',
                              textAlign: 'left',
                              paddingLeft: 4,
                              paddingRight: 10,
                            }}
                          >
                            Budget Head
                          </th>
                          <th
                            style={{
                              width: '20%',
                              height: 44,
                              backgroundColor: '#333642',
                              color: '#fff',
                              fontSize: 12,
                              lineHeight: '16px',
                              fontWeight: 'bold',
                              paddingLeft: 4,
                              paddingRight: 10,
                              textAlign: 'left',
                            }}
                          >
                            Account Name
                          </th>
                          <th
                            style={{
                              width: '20%',
                              height: 44,
                              backgroundColor: '#333642',
                              color: '#fff',
                              fontSize: 12,
                              lineHeight: '16px',
                              fontWeight: 'bold',
                              paddingLeft: 4,
                              paddingRight: 10,
                              textAlign: 'left',
                            }}
                          >
                            Activity Code
                          </th>
                          <th
                            style={{
                              width: '25%',
                              height: 44,
                              backgroundColor: '#333642',
                              color: '#fff',
                              fontSize: 12,
                              lineHeight: '16px',
                              fontWeight: 'bold',
                              borderTopRightRadius: 8,
                              paddingLeft: 4,
                              paddingRight: 10,
                              textAlign: 'left',
                            }}
                          >
                            Activity Name
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.items?.map((item, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                backgroundColor: '#F8FAFB',
                                fontSize: 12,
                                lineHeight: '16px',
                                color: '#000000',
                                minHeight: 40,
                                borderLeft: '1px solid #E7E7E8',
                                paddingTop: 12,
                                paddingBottom: 12,
                                paddingLeft: 10,
                              }}
                            >
                              {item.gl_account}
                            </td>
                            <td
                              style={{
                                backgroundColor: '#F8FAFB',
                                fontSize: 12,
                                lineHeight: '16px',
                                color: '#000000',
                                minHeight: 40,
                                paddingTop: 12,
                                paddingBottom: 12,
                                paddingLeft: 10,
                              }}
                            >
                              {item.sof}
                            </td>
                            <td
                              style={{
                                backgroundColor: '#F8FAFB',
                                fontSize: 12,
                                lineHeight: '16px',
                                color: '#000000',
                                minHeight: 40,
                                paddingTop: 12,
                                paddingBottom: 12,
                                paddingLeft: 10,
                              }}
                            >
                              {item.budget_code}
                            </td>
                            <td
                              style={{
                                backgroundColor: '#F8FAFB',
                                fontSize: 12,
                                lineHeight: '16px',
                                color: '#000000',
                                minHeight: 40,
                                paddingTop: 12,
                                paddingBottom: 12,
                                paddingLeft: 10,
                              }}
                            >
                              {item.account_name}
                            </td>
                            <td
                              style={{
                                backgroundColor: '#F8FAFB',
                                fontSize: 12,
                                lineHeight: '16px',
                                color: '#000000',
                                minHeight: 40,
                                paddingTop: 12,
                                paddingBottom: 12,
                                paddingLeft: 10,
                              }}
                            >
                              {item.activity_code}
                            </td>
                            <td
                              style={{
                                backgroundColor: '#F8FAFB',
                                fontSize: 12,
                                lineHeight: '16px',
                                color: '#000000',
                                minHeight: 40,
                                borderRight: '1px solid #E7E7E8',
                                paddingTop: 12,
                                paddingBottom: 12,
                                paddingLeft: 10,
                              }}
                            >
                              {item.activity_name}
                            </td>
                          </tr>
                        ))}

                        {/* <tr>
                        <td
                          style={{
                            backgroundColor: '#fff',
                            fontSize: 12,
                            lineHeight: '16px',
                            color: '#000000',
                            minHeight: 40,
                            borderLeft: '1px solid #E7E7E8',
                            paddingTop: 12,
                            paddingBottom: 12,
                            borderBottom: '1px solid #E7E7E8',
                            borderBottomLeftRadius: 8,
                            paddingLeft: 10,
                          }}
                        >
                          {glNumberTwo}
                        </td>
                        <td
                          style={{
                            backgroundColor: '#fff',
                            fontSize: 12,
                            lineHeight: '16px',
                            color: '#000000',
                            minHeight: 40,
                            paddingTop: 12,
                            paddingBottom: 12,
                            borderBottom: '1px solid #E7E7E8',
                            paddingLeft: 10,
                          }}
                        >
                          {sofTwo}
                        </td>
                        <td
                          style={{
                            backgroundColor: '#fff',
                            fontSize: 12,
                            lineHeight: '16px',
                            color: '#000000',
                            minHeight: 40,
                            paddingTop: 12,
                            paddingBottom: 12,
                            borderBottom: '1px solid #E7E7E8',
                            paddingLeft: 10,
                          }}
                        >
                          {budgetHeadTwo}
                        </td>
                        <td
                          style={{
                            backgroundColor: '#fff',
                            fontSize: 12,
                            lineHeight: '16px',
                            color: '#000000',
                            minHeight: 40,
                            paddingTop: 12,
                            paddingBottom: 12,
                            borderBottom: '1px solid #E7E7E8',
                            paddingLeft: 10,
                          }}
                        >
                          {accountNameTwo}
                        </td>
                        <td
                          style={{
                            backgroundColor: '#fff',
                            fontSize: 12,
                            lineHeight: '16px',
                            color: '#000000',
                            minHeight: 40,
                            paddingTop: 12,
                            paddingBottom: 12,
                            borderBottom: '1px solid #E7E7E8',
                            paddingLeft: 10,
                          }}
                        >
                          {activityCodeTwo}
                        </td>
                        <td
                          style={{
                            backgroundColor: '#fff',
                            fontSize: 12,
                            lineHeight: '16px',
                            color: '#000000',
                            minHeight: 40,
                            borderRight: '1px solid #E7E7E8',
                            paddingTop: 12,
                            paddingBottom: 12,
                            borderBottom: '1px solid #E7E7E8',
                            paddingLeft: 10,
                            borderBottomRightRadius: 8,
                          }}
                        >
                          {activityNameTwo}
                        </td>
                      </tr> */}
                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>
              <table
                style={{
                  borderSpacing: 0,
                  padding: 0,
                  width: '100%',
                  marginTop: 25,
                }}
              >
                <tr>
                  <td
                    style={{
                      fontSize: 12,
                      lineHeight: '16px',
                      fontWeight: 'bold',
                      color: '#969BAB',
                      paddingLeft: 27,
                    }}
                  >
                    Additional Information
                  </td>
                </tr>
              </table>
              <table
                style={{
                  borderSpacing: 0,
                  padding: 0,
                  width: '100%',
                  marginTop: 20,
                }}
              >
                <tr>
                  <td style={{ width: '73%', paddingLeft: 27 }}>
                    <table
                      style={{
                        width: '100%',
                        borderBottom: '1px solid #E7E7E8',
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              width: '15%',
                              height: 44,
                              backgroundColor: '#333642',
                              color: '#fff',
                              fontSize: 12,
                              lineHeight: '16px',
                              fontWeight: 'bold',
                              borderTopLeftRadius: 8,
                              paddingLeft: 4,
                              paddingRight: 10,
                            }}
                          >
                            No.
                          </th>
                          <th
                            style={{
                              width: '37%',
                              height: 44,
                              backgroundColor: '#333642',
                              color: '#fff',
                              fontSize: 12,
                              lineHeight: '16px',
                              fontWeight: 'bold',
                              textAlign: 'left',
                              paddingLeft: 4,
                              paddingRight: 10,
                            }}
                          >
                            Program/Activity
                          </th>
                          <th
                            style={{
                              width: '38%',
                              height: 44,
                              backgroundColor: '#333642',
                              color: '#fff',
                              fontSize: 12,
                              lineHeight: '16px',
                              fontWeight: 'bold',
                              textAlign: 'left',
                              borderTopRightRadius: 8,
                              paddingLeft: 4,
                              paddingRight: 10,
                            }}
                          >
                            Purchase Type
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.items?.map((item, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                backgroundColor: '#F8FAFB',
                                fontSize: 12,
                                lineHeight: '16px',
                                color: '#000000',
                                minHeight: 40,
                                borderLeft: '1px solid #E7E7E8',
                                paddingTop: 12,
                                paddingBottom: 12,
                                paddingLeft: 10,
                              }}
                            >
                              {item.doc_entry_line}
                            </td>
                            <td
                              style={{
                                backgroundColor: '#F8FAFB',
                                fontSize: 12,
                                lineHeight: '16px',
                                color: '#000000',
                                minHeight: 40,
                                paddingTop: 12,
                                paddingBottom: 12,
                                paddingLeft: 10,
                              }}
                            >
                              {item.program_activity}
                            </td>
                            <td
                              style={{
                                backgroundColor: '#F8FAFB',
                                fontSize: 12,
                                lineHeight: '16px',
                                color: '#000000',
                                borderRight: '1px solid #E7E7E8',
                                minHeight: 40,
                                paddingTop: 12,
                                paddingBottom: 12,
                                paddingLeft: 10,
                              }}
                            >
                              {item.purchase_type}
                            </td>
                          </tr>
                        ))}

                        {/* <tr>
                        <td
                          style={{
                            backgroundColor: '#fff',
                            fontSize: 12,
                            lineHeight: '16px',
                            color: '#000000',
                            minHeight: 40,
                            borderLeft: '1px solid #E7E7E8',
                            borderBottom: '1px solid #E7E7E8',
                            paddingTop: 12,
                            paddingBottom: 12,
                            paddingLeft: 10,
                            borderBottomLeftRadius: 8,
                          }}
                        >
                          {noAddTwo}
                        </td>
                        <td
                          style={{
                            backgroundColor: '#fff',
                            fontSize: 12,
                            lineHeight: '16px',
                            color: '#000000',
                            minHeight: 40,
                            paddingTop: 12,
                            paddingBottom: 12,
                            paddingLeft: 10,
                            borderBottom: '1px solid #E7E7E8',
                          }}
                        >
                          {programActivityTwo}
                        </td>
                        <td
                          style={{
                            backgroundColor: '#fff',
                            fontSize: 12,
                            lineHeight: '16px',
                            color: '#000000',
                            borderRight: '1px solid #E7E7E8',
                            borderBottom: '1px solid #E7E7E8',
                            minHeight: 40,
                            paddingTop: 12,
                            paddingBottom: 12,
                            paddingLeft: 10,
                            borderBottomRightRadius: 8,
                          }}
                        >
                          {puchaseTypeTwo}
                        </td>
                      </tr> */}
                      </tbody>
                    </table>
                  </td>
                  <td
                    style={{
                      width: '27%',
                      verticalAlign: 'top',
                      paddingLeft: 22,
                    }}
                  >
                    <p style={{ fontSize: 12, lineHeight: '24px' }}>Remark :</p>
                    <p style={{ fontSize: 12, lineHeight: '24px' }}>{remark}</p>
                    {/* this for Remark */}
                    {renderRemark()}
                  </td>
                </tr>
              </table>
              {/* <table
                style={{
                  borderSpacing: 0,
                  padding: 0,
                  width: '100%',
                  marginTop: 20,
                  position: 'absolute',
                  bottom: 0,
                  backgroundColor: '#F3F5F6',
                }}
              >
                <tr>
                  <td
                    style={{
                      backgroundColor: '#F3F5F6',
                      paddingTop: 20,
                      paddingBottom: 16,
                      paddingLeft: 19,
                      paddingRight: 20,
                    }}
                  >
                    <table>
                      <tr>
                        <td style={{ width: '8%' }}>
                          <img
                            src={LogoFooter}
                            style={{ width: 45, height: 38 }}
                            alt=""
                          />
                        </td>
                        <td style={{ width: '38%' }}>
                          <p
                            style={{
                              fontSize: 11,
                              lineHeight: '20px',
                              color: '#85878E',
                            }}
                          >
                            Graha Sucofindo Lt 12 Jl. Raya Pasar Minggu Kav. 34,
                            DKI Jakarta 12780, Indonesia
                          </p>
                        </td>
                        <td
                          style={{
                            width: '25%',
                            verticalAlign: 'top',
                            paddingLeft: 18,
                          }}
                        >
                          <tr>
                            <td
                              style={{
                                width: 33,
                                fontSize: 11,
                                lineHeight: '20px',
                                color: '#85878E',
                                fontWeight: 'bold',
                              }}
                            >
                              Phone
                            </td>
                            <td
                              style={{
                                fontSize: 11,
                                lineHeight: '20px',
                                color: '#85878E',
                                paddingLeft: 12,
                              }}
                            >
                              : +6221-7986569/71
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                width: 33,
                                fontSize: 11,
                                lineHeight: '20px',
                                color: '#85878E',
                                fontWeight: 'bold',
                              }}
                            >
                              Fax
                            </td>
                            <td
                              style={{
                                paddingLeft: 12,
                                fontSize: 11,
                                lineHeight: '20px',
                                color: '#85878E',
                              }}
                            >
                              : +6221-7986570
                            </td>
                          </tr>
                        </td>
                        <td
                          style={{
                            width: '25%',
                            verticalAlign: 'top',
                            paddingLeft: 10,
                          }}
                        >
                          <tr>
                            <td
                              style={{
                                width: 33,
                                fontSize: 11,
                                lineHeight: '20px',
                                color: '#85878E',
                                fontWeight: 'bold',
                              }}
                            >
                              Npwp
                            </td>
                            <td
                              style={{
                                fontSize: 11,
                                lineHeight: '20px',
                                color: '#85878E',
                                paddingLeft: 12,
                              }}
                            >
                              : 10.209.321.2-098.000
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                width: 33,
                                fontSize: 11,
                                lineHeight: '20px',
                                color: '#85878E',
                                fontWeight: 'bold',
                              }}
                            >
                              Website
                            </td>
                            <td
                              style={{
                                paddingLeft: 12,
                                fontSize: 11,
                                lineHeight: '20px',
                                color: '#85878E',
                              }}
                            >
                              : https://dktindonesia.org/
                            </td>
                          </tr>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table> */}
            </div>
            {isMobile ? (
              <Button
                className={classes.btnClose}
                variant="contained"
                color="primary"
                onClick={close}
              >
                Close Preview
              </Button>
            ) : null}
          </>
        </Fade>
      </Modal>
    </>
  );
}
