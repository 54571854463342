import React from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  withStyles,
  Box,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { get } from 'lodash';

import { Row } from './TableCustom/index';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../redux/OP/actions';
import * as actions_2 from '../../redux/Utility/actions';
import * as actions_3 from '../../redux/ClientVendor/actions';
import { dateFormater, formatRupiah } from '../../utils/formatter';
import { useRouter } from '../../utils/useRouter';
import { SortingASC, SortingDESC } from '../../utils/SortFunc';

// compontent
import DatePicker from '../DatePicker';
import { TabelLoading, TableNoData } from '../Loading/TableLoading';
import { CircleLoadingSecondary } from '../Loading/CircleLoading';
import ModalDeleteItem from '../ModalDeleteItem';
import CustomizedSnackbars from '../PopupMessage';
import DesktopPagination from '../Pagination/DesktopPagination';
import SortArrow from '../SortArrow';
import TitleWithSearchNo from '../TableData-Common/titleWithSearchNo';

// Icons
import TandaSeru from '../../assets/Icons/tandaSeru.png';
import Asnyc from '../../assets/Icons/async.png';
import DateIcon from '../../assets/Icons/date.png';
import Export from '../../assets/Icons/export.png';
import Search from '../../assets/Icons/Search.png';
import Trash from '../../assets/Icons/trash.png';

// Svg
import { DownArrow } from '../../assets/Icons/svg/DownArrow';

// generate fixed table
const StickyTableCell = withStyles((theme) => ({
  head: {
    right: 0,
    // position: 'sticky',
    // zIndex: theme.zIndex.appBar + 2,
  },
  body: {
    backgroundColor: '#ddd',
    minWidth: '50px',
    right: 0,
    position: 'sticky',
    zIndex: 99,
  },
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const OutgoingPaymentTableData = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispacth = useDispatch();

  // Create format for table and pagination
  const [page, setPage] = React.useState(1);
  const [pagination, setPagination] = React.useState(1);
  const [fromRow, setFromRow] = React.useState(1);
  const [toRow, setToRow] = React.useState(1);
  const [TotalRow, setTotalRow] = React.useState(1);
  const [limit, setLimit] = React.useState(10);

  // initial state
  const [rowData, setRowData] = React.useState([]);
  const [sortingData, setSortingData] = React.useState([]);
  const [status, setStatus] = React.useState('All_Status');
  const [tempId, setTempId] = React.useState(null);
  const [buttonSyncClicked, setButtonSyncClicked] = React.useState(false);

  const [docNumParam, setDocNumParam] = React.useState('');

  const [date, setDate] = React.useState({
    start: null,
    end: null,
  });

  // handle message
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };

  // end handle Message

  // Global Variable
  const { access_token } = useSelector((state) => state.auth);
  const auth = useSelector((state) => state.auth);

  const {
    notifLoading,
    notfiData,
    op_sap,
    data_op,
    isLoading,
    publish_opIsLoading,
    publish_opMessage,
    delete_opIsLoading,
    delete_opMessage,
  } = useSelector((state) => state.op);
  const { data, searchData, searchIsLoading } = useSelector(
    (state) => state.clientVendor
  );
  const { csv_loading, category_data } = useSelector((state) => state.utility);

  // for handle search input vendor

  const [deleteHover, setDeleteHover] = React.useState(false);
  const [isVendorFocus, setIsVendorFocus] = React.useState(false);
  const [showVendor, setShowVendor] = React.useState(false);

  const handleChooseVandor = (data, index) => {
    selectVendor(data, index);
    setIsVendorFocus(false);
    setShowVendor(false);
  };

  const [vendor, setVendor] = React.useState(null);
  // const [isVendorFocus, setIsVendorFocus] = React.useState(false);
  // const [showVendor, setShowVendor] = React.useState(false);
  const [vendorData, setVendorData] = React.useState(null);
  const [searchText, setSearchText] = React.useState('');

  const selectVendor = (data, index) => {
    setVendor(data);
    // setIsVendorFocus(false);
    // setShowVendor(false);
    setSearchText('');
    setVendorData(data?.client?.data);
  };

  React.useEffect(() => {
    if (searchText !== '') {
      // let TempArr = [];
      // vendorData?.map((vendor, index) => {
      //   let tempName = vendor.name.toLowerCase();
      //   if (tempName.includes(searchText.toLocaleLowerCase())) {
      //     TempArr.push(vendor);
      //   }
      // });
      // setVendorData(TempArr);
      let data = { name: searchText };
      dispacth(actions_3.searchClientVendor(access_token, data));
    } else {
      setVendorData(data?.client?.data);
      dispacth(actions_3.setBlankSearchData());
    }
  }, [searchText]);

  React.useEffect(() => {
    dispacth(actions_3.getClientVendor(access_token));
  }, []);

  React.useEffect(() => {
    setVendorData(data?.client?.data);
  }, [data]);

  React.useEffect(() => {
    setVendorData(searchData?.client);
  }, [searchData]);

  // end handle serach vendor

  // handle Delete Item
  const [showModalDelete, setShowModalDetele] = React.useState(false);
  const [tempDeleteId, setTempDeleteId] = React.useState(0);

  const handleChangePagination = (event, value) => {
    setPagination(value);
  };

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
  };

  const handleDetailData = (id) => {
    router.push(`/outgoing-payment-detail/opId=${id}`);
  };

  // func request API

  const handleGETSyncSAP = () => {
    let params = {
      is_draft: 1,
      limit: notfiData,
      order_by: 'id',
      order_dir: 'desc',
    };

    dispacth(actions.getOPSAPdata(access_token, params));
    setButtonSyncClicked(true);
  };

  React.useEffect(() => {
    if (buttonSyncClicked && tempId.length) {
      handleSubmitSAP();
      setButtonSyncClicked(false);
    }
  }, [tempId]);

  const handleExportCsv = () => {
    let params = {
      doc_type: 'OP',
      date_start: dateFormater(date.start, 'YYYY-MM-DD'),
      date_end: dateFormater(date.end, 'YYYY-MM-DD'),
      status: status,
    };

    if (!date.start || !date.end) {
      delete params.date_start;
      delete params.date_end;
    }

    if (status === 'All_Status') delete params.status;

    dispacth(actions_2.exportCSV(access_token, params));
  };

  const handleGETOPdata = () => {
    let params = {
      date_start: dateFormater(date.start, 'YYYY-MM-DD'),
      date_end: dateFormater(date.end, 'YYYY-MM-DD'),
      status: status,
      page: pagination,
      is_draft: 0,
      order_by: 'id',
      order_dir: 'desc',
      limit: limit,
    };

    if (docNumParam.length) params.doc_num = docNumParam;

    if (!date.start || !date.end) {
      delete params.date_start;
      delete params.date_end;
    }

    if (status === 'All_Status') delete params.status;

    // console.log(params);
    dispacth(actions.getOPdata(access_token, params));
  };

  const handleSubmitSAP = () => {
    let data = {
      ids: JSON.stringify(tempId),
    };

    dispacth(actions.publishOP(access_token, data));
  };

  const handleDelete = (id) => {
    setTempDeleteId(id);
    setShowModalDetele(true);
  };

  const handleDeleteItem = () => {
    dispacth(actions.deleteOP(access_token, tempDeleteId));
  };

  React.useEffect(() => {
    dispacth(actions.getSAPnotification(access_token));
  }, []);

  React.useEffect(() => {
    setRowData(sortingData);
  }, [sortingData]);

  React.useEffect(() => {
    let params = {
      is_draft: 0,
      page: 1,
      order_by: 'id',
      order_dir: 'desc',
      limit: limit,
    };

    dispacth(actions.getOPdata(access_token, params));
  }, []);

  React.useEffect(() => {
    handleGETOPdata();
  }, [date, status, pagination, limit]);

  React.useEffect(() => {
    let tempArr = [];

    data_op?.data?.map((data, index) => {
      let tempData = {
        dataIndex: index,
        opId: data?.id,
        noDoc: data?.outgoing_no,
        currency: data?.currency,
        vendorName: data?.to_order_of_vendor_name,
        total: data?.total_amount,
        status: data?.status,
        date: data?.created_at,
      };
      tempArr.push(tempData);
    });

    let countPage = Math.ceil(data_op?.total / limit);
    setPage(countPage);
    if (pagination > countPage) {
      setPagination(countPage);
    }
    setFromRow(data_op?.from);
    setToRow(data_op?.to);
    setTotalRow(data_op?.total);
    setRowData(tempArr);
  }, [data_op]);

  React.useEffect(() => {
    let tempArrId = [];

    op_sap?.data?.map((data, index) => {
      if (data?.id) tempArrId.push(data.id);
    });

    setTempId(tempArrId);
  }, [op_sap]);

  React.useEffect(() => {
    if (publish_opMessage) {
      if (publish_opMessage === 'Outgoing payment published successfully.') {
        handleGETOPdata();
        dispacth(actions.getSAPnotification(access_token));
      } else if (
        publish_opMessage !== '' &&
        publish_opMessage !== 'Outgoing payment published successfully.'
      ) {
        setType('error');
        setShow(true);
        setNotifText(publish_opMessage);
      }
      dispacth(actions.setBlankPublish());
    }
  }, [publish_opMessage]);

  React.useEffect(() => {
    if (delete_opMessage) {
      if (delete_opMessage === 'Delete Outgoing Payment Success') {
        handleGETOPdata();
        setShowModalDetele(false);
      } else if (
        delete_opMessage !== '' &&
        delete_opMessage !== 'Delete Outgoing Payment Success'
      ) {
        setType('error');
        setShow(true);
        setNotifText(delete_opMessage);
      }
      dispacth(actions.setBlankDelete());
    }
  }, [delete_opMessage]);

  return (
    <>
      <Grid container md={12} style={{ maxWidth: '100vw' }}>
        <Grid md={12}>
          <CustomizedSnackbars
            show={show}
            handleClose={handleClose}
            message={notifText}
            type={type}
          />
          <ModalDeleteItem
            open={showModalDelete}
            close={() => setShowModalDetele(false)}
            onPress={handleDeleteItem}
            isLoading={delete_opIsLoading}
          />
          <div className={classes.titleTableContent}>
            <TitleWithSearchNo
              Title={<Typography
                className={classes.titleTable}
                variant="body1"
                component="p"
              >
                OP - OUTGOING PAYMENT
              </Typography>}
              docNumParam={docNumParam}
              setDocNumParam={setDocNumParam}
              sendApiQuery={handleGETOPdata}
            />

            {false /*get(auth, ['data', 'user', 'op_sync'])*/ ? (
            <div className={classes.contentRigth}>
              <div className={classes.notifContent}>
                {notifLoading ? (
                  <Typography
                    className={classes.notifText}
                    variant="body1"
                    component="p"
                  >
                    Loading Read Data
                  </Typography>
                ) : notfiData && notfiData > 0 ? (
                  <>
                    <img src={TandaSeru} alt="image not found" />
                    <Typography
                      className={classes.notifText}
                      variant="body1"
                      component="p"
                    >
                      {`${notfiData} new data are available`}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography
                      className={classes.notifTextNull}
                      variant="body1"
                      component="p"
                    >
                      No new data
                    </Typography>
                  </>
                )}
              </div>
              <div
                className={
                  notfiData > 0 ? classes.btnAsnyc : classes.btnAsnycDisable
                }
                onClick={notfiData > 0 && !publish_opIsLoading ? handleGETSyncSAP : null}
              >
                <img src={Asnyc} alt="image not found" />
                <Typography
                  className={classes.btnText}
                  variant="body1"
                  component="p"
                >
                  Sync SAP Data
                </Typography>
              </div>
            </div>) : null}
          </div>
        </Grid>
        <Box className={classes.filterContent}>
          <Box className={classes.dateContent}>
            <div className={classes.dateRangeIcon}>
              <img src={DateIcon} alt="image not found" />
            </div>
            <div className={classes.containerDate}>
              <DatePicker
                onDatePick={(pickedDay) => {
                  setDate(pickedDay);
                }}
                placeholder="Select Date Range"
              />
            </div>
          </Box>
          <Box
            className={classes.filterVendorContent}
            // onBlur={() => setIsVendorFocus(false)}
          >
            <input
              className={classes.inputVendor}
              value={vendor?.name ?? 'All Vendor'}
              autoComplete="on"
              onFocus={() => setIsVendorFocus(true)}
              placeholder="All Vendor"
              onBlur={() => setIsVendorFocus(false)}
            />
            <Box className={classes.iconDownArrow}>
              <DownArrow />
            </Box>
            {vendor?.name ? (
              <Box
                className={classes.iconTrash}
                onClick={() => setVendor(null)}
              >
                <img src={Trash} />
              </Box>
            ) : null}
            {/* render for select vendor name */}
            {isVendorFocus || showVendor ? (
              <Box
                onBlur={() => setIsVendorFocus(true)}
                className={classes.contentDataVendor}
                onMouseOver={() => setShowVendor(true)}
                onMouseOut={() => setShowVendor(false)}
              >
                <Box className={classes.contentVendorSearch}>
                  <input
                    className={classes.searchVendor}
                    autoComplete="on"
                    onFocus={() => setIsVendorFocus(true)}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder="Search"
                    // onBlur={() => setIsVendorFocus(false)}
                  />
                  <img
                    className={classes.serachIcon}
                    src={Search}
                    alt="image not found"
                  />
                </Box>
                <Box className={classes.listVendorName}>
                  {searchIsLoading ? (
                    <Typography className={classes.TitleVendor}>
                      Loading Get Data
                    </Typography>
                  ) : (
                    vendorData?.map((data, vendorIndex) => (
                      <Box
                        key={vendorIndex}
                        onClick={() => handleChooseVandor(data, vendorIndex)}
                        className={classes.vendorBox}
                      >
                        <Typography className={classes.TitleVendor}>
                          {`${data.name}`}
                        </Typography>
                      </Box>
                    )) ?? (
                      <Typography className={classes.TitleVendor}>
                        Company Not Found
                      </Typography>
                    )
                  )}
                </Box>
              </Box>
            ) : null}
          </Box>
          <Box className={classes.filterStatusContent}>
            <FormControl className={classes.formControl}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                IconComponent={() => <DownArrow />}
                value={status}
                onChange={handleChangeStatus}
              >
                <MenuItem value={'All_Status'}>All Status</MenuItem>
                <MenuItem value={'open'}>Open</MenuItem>
                <MenuItem value={'cancelled'}>Cancelled</MenuItem>
                <MenuItem value={'closed'}>Closed</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.filterBlankArea}>{/* black area */}</Box>
          <Box className={classes.exportContent}>
            <div className={classes.btnExportBlue} onClick={handleExportCsv}>
              {csv_loading ? (
                <Box className={classes.loadingExport}>
                  <CircleLoadingSecondary />
                </Box>
              ) : (
                <img src={Export} alt="image not found" />
              )}
              <Typography
                className={classes.btnText}
                variant="body1"
                component="p"
              >
                Export .csv
              </Typography>
            </div>
          </Box>
        </Box>

        <div style={{ width: '100%' }}>
          <Grid md={12}>
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        No.
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'noPr', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'noPr', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        Vendor Name
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'name', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'name', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        Total Payment
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'total', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'total', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        Status
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'status', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'status', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        Date
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'date', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'date', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StickyTableCell className={classes.head}>
                      Action
                    </StickyTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? null : rowData.length > 0 ? (
                    rowData?.map((row, index) => (
                      <Row
                        key={index}
                        index={index}
                        row={row}
                        handleDetailData={handleDetailData}
                        handleDelete={handleDelete}
                      />
                    ))
                  ) : (
                    <TableNoData colspan={6} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {isLoading ? <TabelLoading /> : null}
          </Grid>
        </div>
        {isLoading ? null : rowData?.length > 0 ? (
          <DesktopPagination
            toRow={toRow}
            fromRow={fromRow}
            TotalRow={TotalRow}
            page={page}
            handleChangePagination={handleChangePagination}
            limit={limit}
            handleChangeLimit={handleChangeLimit}
            pagination={pagination}
          />
        ) : null}
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  // cutome table
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  wrapperTableHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  wrapperTableHead: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  iconSort: {
    paddingTop: 15,
    width: 10,
    marginLeft: 5,
  },
  head: {
    backgroundColor: '#fff',
    fontSize: 16,
    lineHeight: '30px',
    fontWeight: 'bold',
    color: '#000000',
    borderBottom: 'none',
    '&:nth-child(1)': {
      minWidth: 80,
      borderBottom: 'none',
    },
    '&:nth-child(2)': {
      minWidth: 250,
      flex: 1,
    },
    '&:nth-child(3)': {
      minWidth: 90,
    },
    '&:nth-child(4)': {
      minWidth: 90,
    },
    '&:nth-child(5)': {
      minWidth: 200,
    },
    '&:nth-child(6)': {
      width: 100,
    },
  },
  tableContainer: {
    // maxHeight: '400px',
    Width: '100%',
    '&.MuiTableContainer-root .MuiTableRow-root .MuiTableCell-root.MuiTableCell-head':
      {
        paddingLeft: 8,
      },
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
    },
  },
  cell: {
    minWidth: '100px',
  },
  // end custom table
  titleTableContent: {
    paddingRight: 22,
    paddingLeft: 22,
    paddingTop: 27,
    paddingBottom: 27,
    backgroundColor: '#ffff',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleTable: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  contentRigth: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  btnAsnyc: {
    backgroundColor: '#1B2030',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '14px 20px',
    borderRadius: 8,
    cursor: 'pointer',
  },
  btnAsnycDisable: {
    backgroundColor: '#969BAB',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '14px 20px',
    borderRadius: 8,
  },
  btnExport: {
    backgroundColor: '#127EEE',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '14px 20px',
    borderRadius: 8,
    cursor: 'pointer',
  },
  btnExportBlue: {
    backgroundColor: '#127EEE',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 14px',
    borderRadius: 8,
    cursor: 'pointer',
    maxHeight: 40,
    maxWidth: 154,
  },
  btnText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
    marginLeft: 8,
  },
  notifContent: {
    marginRight: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  notifText: {
    fontWeight: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#1B2030',
    marginLeft: 13,
  },

  iconAction: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 20,
  },
  Action: {
    cursor: 'pointer',
  },
  tableContent: {
    backgroundColor: '#fff',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderBottom: 'none !important',
    position: 'relative',
    maxWidth: 1440,
  },
  tableHead: {
    fontSize: 16,
    lineHeight: '30px',
    color: '#000000',
    fontWeight: 'bold',
    // position: 'relative',
    // maxWidth: 1440,
    overflowX: 'scroll',

    '&.MuiTableRow-root .MuiTableCell-root': {
      borderBottom: 'none',
      fontSize: 16,
      lineHeight: '30px',
      color: '#000000',
      fontWeight: 'bold',
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 16,
      paddingBottom: 16,
    },
    '&.MuiTableRow-root .MuiTableCell-head:nth-last-child(1)': {
      position: '-webkit-sticky',
      position: 'sticky',
      background: '#fff',
      left: 0,
      zIndex: 1,
    },
  },
  // Pagination styling
  descTable: {
    paddingTop: 11.5,
    paddingBottom: 11.5,
  },
  descTableText: {
    color: '#969BAB',
    fontSize: 16,
    lineHeight: '21px',
  },
  paginationContent: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pagination: {
    borderRadius: 0,
    '&.MuiPagination-root .MuiPagination-ul .MuiPaginationItem-root': {
      margin: 0,
    },
    borderRadius: 0,
    '&.MuiPagination-root .MuiPagination-ul .MuiPaginationItem-rounded': {
      borderRadius: 0,
    },
    '&.MuiPagination-root .MuiPagination-ul .MuiButtonBase-root': {
      backgroundColor: '#fff',
      color: '#969BAB',
    },
    '&.MuiPagination-root .MuiPagination-ul .MuiButtonBase-root.Mui-selected': {
      backgroundColor: '#1B2030',
      color: '#fff',
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-child(1)': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-child(1) button': {
      color: '#1B2030',
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-last-child(1)': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-last-child(1) button': {
      color: '#1B2030',
    },
  },
  wrapperMenuContent: {
    width: '100%',
  },
  // Filter Content
  filterContent: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: '#fff',
    borderTop: '1px solid #E7E7E8',
    borderBottom: '1px solid #E7E7E8',
    width: '100%',
    overflow: 'auto',
    // position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&::-webkit-scrollbar': {
      width: '0.4em',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
    },
    // position: 'relative',
  },
  dateContent: {
    width: '100%',
    maxWidth: 225,
    // backgroundColor: 'red',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingRight: 5,
  },
  dateRangeIcon: {
    width: 58,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E7E7E8',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  containerDate: {
    width: `100%`,
    maxWidth: `calc(100% - 58px)`,
  },
  // styling select
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 5,
    paddingBottom: 5,
    // maxWidth: 220,
    width: '100%',
    // minWidth: 160,
    maxHeight: 40,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#969BAB',
      paddingRight: 2,
    },
  },
  filterCategoryContent: {
    width: '100%',
    maxWidth: 190,
    minWidth: 185,
    paddingLeft: 5,
    paddingRight: 5,
  },
  filterStatusContent: {
    paddingLeft: 5,
    paddingRight: 5,
    width: '100%',
    maxWidth: 133,
    minWidth: 130,
  },
  inputVendor: {
    width: '100%',
    height: 40,
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    paddingLeft: 15,
    '&.focus-visible': {
      border: '1px solid #E7E7E8',
      outline: 'none',
    },
  },
  filterVendorContent: {
    paddingLeft: 5,
    paddingRight: 5,
    width: '100%',
    maxWidth: 250,
    minWidth: 250,
    // position: 'relative',
  },
  iconDownArrow: {
    position: 'absolute',
    zIndex: 100,
    marginTop: -25,
    marginLeft: 200,
  },
  iconTrash: {
    position: 'absolute',
    zIndex: 100,
    marginTop: -30,
    marginLeft: 150,
    cursor: 'pointer',
  },
  exportContent: {
    marginRight: 24,
    paddingRight: 20,
    width: '100%',
    maxWidth: 164,
    minWidth: 154,
    position: 'absolute',
    right: 0,
    zIndex: 90,
    backgroundColor: '#fff',
  },
  filterBlankArea: {
    minWidth: 150,
  },
  contentDataVendor: {
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid #E7E7E8',
    minHeight: 202,
    maxHeight: 202,
    position: 'absolute',
    maxWidth: 245,
    borderRadius: 8,
    padding: 6,
    zIndex: 99999,
  },
  contentVendorSearch: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    width: '100%',
    border: '1px solid #E7E7E8',
    paddingRight: 15,
    borderRadius: 8,
  },
  searchVendor: {
    flex: 1,
    height: 40,
    border: 'none',
    outline: 'none',
    paddingLeft: 12,
  },
  listVendorName: {
    marginTop: 8,
    minHeight: `calc(202px - 56px)`,
    maxHeight: `calc(202px - 56px)`,
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.4em',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
      borderRadius: 4,
    },
  },
  TitleVendor: {
    height: 46,
    marginTop: 2,
    marginBottom: 2,
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
    cursor: 'pointer',
  },
}));

const rows = [
  {
    id: 12321,
    grpoId: 54251,
    poId: 23891,
    name: 'Citra Van Titipan, PT',
    department: 'FINC',
    entity: 'DKTI',
    activity: 'TV Commercial',
    category: 'item',
    currency: 'IDR',
    total: '3150000',
    status: 'Open',
    date: '12/07/2021 09:34',
  },
  {
    id: 12322,
    grpoId: 54252,
    poId: 23892,
    name: 'Citra Van Titipan, PT',
    department: 'FINC',
    entity: 'DKTI',
    activity: 'TV Commercial',
    category: 'item',
    currency: 'IDR',
    total: '3150000',
    status: 'Closed',
    date: '12/07/2021 09:34',
  },
  {
    id: 12323,
    grpoId: 54253,
    poId: 23892,
    name: 'Citra Van Titipan, PT',
    department: 'FINC',
    entity: 'DKTI',
    activity: 'TV Commercial',
    category: 'item',
    currency: 'IDR',
    total: '3150000',
    status: 'Reject',
    date: '12/07/2021 09:34',
  },
  {
    id: 12324,
    grpoId: 54254,
    poId: 23892,
    name: 'Citra Van Titipan, PT',
    department: 'FINC',
    entity: 'DKTI',
    activity: 'TV Commercial',
    category: 'item',
    currency: 'IDR',
    total: '3150000',
    status: 'Need approval',
    date: '12/07/2021 09:34',
  },
  {
    id: 12325,
    grpoId: 54255,
    poId: 23892,
    name: 'Citra Van Titipan, PT',
    department: 'FINC',
    entity: 'DKTI',
    activity: 'TV Commercial',
    category: 'service',
    currency: 'USD',
    total: '3150000',
    status: 'Open',
    date: '12/07/2021 09:34',
  },
  {
    id: 12325,
    grpoId: 54255,
    poId: 23892,
    name: 'Citra Van Titipan, PT',
    department: 'FINC',
    entity: 'DKTI',
    activity: 'TV Commercial',
    category: 'service',
    currency: 'USD',
    total: '3150000',
    status: 'Cancelled',
    date: '12/07/2021 09:34',
  },
  {
    id: 12325,
    grpoId: 54255,
    poId: 23892,
    name: 'Citra Van Titipan, PT',
    department: 'FINC',
    entity: 'DKTI',
    activity: 'TV Commercial',
    category: 'service',
    currency: 'USD',
    total: '3150000',
    status: 'Approved',
    date: '12/07/2021 09:34',
  },
];

const vendorRows = [
  { id: 1, name: 'Citra Titipan' },
  { id: 2, name: 'Cahaya Impian' },
  { id: 3, name: 'Triangle Sinar' },
  { id: 4, name: 'Sinar Mas' },
  { id: 5, name: 'Sukamaju Bali' },
  { id: 6, name: 'Sri Raha Wangi' },
  { id: 7, name: 'Sri Raha Wangi' },
  { id: 8, name: 'Sri Raha Wangi' },
  { id: 9, name: 'Sri Raha Wangi' },
  { id: 10, name: 'Sri Raha Wangi' },
];
export default OutgoingPaymentTableData;
