import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { useRouter } from '../../../utils/useRouter';

const Buttonsubmit = ({ onPress, isOn }) => {
  const router = useRouter();
  const classes = useStyles();
  return (
    <>
      <Box className={classes.wrapperBtn}>
        <input
          className={isOn ? classes.btnSubmit : classes.btnSubmitDisable}
          type="submit"
          value="Submit"
        />
        <Box
          className={classes.btnCancel}
          onClick={() => router.push('/client-vendor')}
        >
          <span>Cancel</span>
        </Box>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapperBtn: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  btnCancel: {
    marginLeft: 20,
    width: 193,
    height: 50,
    borderRadius: 8,
    backgroundColor: '#f48686',
    color: '#fff',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  btnSubmit: {
    width: 193,
    height: 50,
    backgroundColor: '#1B2030',
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
  },

  btnSubmitDisable: {
    width: 193,
    height: 50,
    backgroundColor: '#969BAB',
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
  },
}));

export default Buttonsubmit;
