import React from 'react';
import { makeStyles, Grid, Box, Typography } from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../redux/PO/actions';
import { useRouter } from '../../utils/useRouter';
import {
  formatFileCount,
  dateFormater,
  formatRupiah,
} from '../../utils/formatter';

// Components

import POpreview from '../../components/PDFpreview/POpreview';
import SKPODetail from '../Skeleton/SKPODetail';

// Icons
import EyeBlue from '../../assets/Icons/eyeBlue.png';

const PoFormApproval = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispacth = useDispatch();
  const { id } = router.query;

  // Global Variable
  const { access_token } = useSelector((state) => state.auth);
  const {
    po_detail,
    detail_message,
    detail_isLoading,
    cancel_poMessage,
    assignment_poloading,
    assigment_pomessage,
  } = useSelector((state) => state.po);

  // handle Render By Status
  const [isClose, setIsClose] = React.useState(false);
  const [isNeed, seIsNeed] = React.useState(false);
  const [btnQRon, setBtnQRon] = React.useState(false);
  const [tempChecklist, setTempChecklist] = React.useState([]);

  // handleshow modal
  const [showPDFpreview, setShowPDFpreview] = React.useState(false);

  const handleClosePDFpreview = () => {
    setShowPDFpreview(false);
  };

  // conigure btn action

  const handleSeeImage = (url) => {
    window.open(url);
  };

  const handleLinkPDF = () => {
    window.open(po_detail?.qr_code);
  };

  const handleConfirmAssigment = () => {
    dispacth(actions.assigmentPO(access_token, po_detail?.id));
  };

  React.useEffect(() => {
    dispacth(actions.getPODetail(access_token, id));
  }, []);

  React.useEffect(() => {
    po_detail?.status === 'approve' || po_detail?.status === 'close'
      ? setBtnQRon(true)
      : setBtnQRon(false);

    if (po_detail?.checklists?.length > 0) {
      let tempArr = [];
      let resultArr = [];

      po_detail?.checklists?.map((check, index) => {
        tempArr.push(check.activity_id);
      });

      let uniqueArr = [...new Set(tempArr)];

      uniqueArr.map((item, index) => {
        let tempData = {
          id: index + 1,
          activity_id: item,
          activity_name: '',
          checklist_attachment_id: [],
        };

        po_detail?.checklists?.map((e, eIndex) => {
          if (e?.activity_id === item) {
            if (e?.checklist_attachment?.name !== 'Other') {
              tempData.activity_name = e.activity?.name;
              tempData.checklist_attachment_id.push({
                id: e.checklist_attachment?.id,
                name: e.checklist_attachment?.name,
                isCheck: true,
              });
            }
          }
        });
        resultArr.push(tempData);
      });

      setTempChecklist(resultArr);
    } else {
      setTempChecklist([]);
    }
  }, [po_detail]);

  return (
    <>
      {detail_isLoading ? (
        <SKPODetail />
      ) : (
        <>
          <Grid container className={classes.wrapperPaper}>
            <POpreview
              open={showPDFpreview}
              close={handleClosePDFpreview}
              data={po_detail}
            />
            <Grid md={12}>
              <Box className={classes.formTitle}>
                <Typography
                  className={classes.formNumber}
                  variant="body1"
                  component="p"
                >
                  {`No. ${po_detail?.doc_num}`}
                </Typography>
              </Box>
              <Box className={classes.borderLine} />
            </Grid>
            <Grid md={12} className={classes.wrapperPaper}>
              <Box className={classes.wrapperInformation}>
                <Box className={classes.informationContent}>
                  <Box className={classes.informationHeader}>
                    <Typography
                      className={classes.headerText}
                      variant="body1"
                      component="p"
                    >
                      PO Information
                    </Typography>
                    {po_detail?.qr_code ? (
                      <Box
                        className={classes.informationHeaderLink}
                        onClick={handleLinkPDF}
                      >
                        <img
                          className={classes.bellImage}
                          src={EyeBlue}
                          alt="image not found"
                        />
                        <Typography
                          className={classes.headerLink}
                          variant="body1"
                          component="p"
                        >
                          View PO
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        className={classes.informationHeaderLink}
                        onClick={() => setShowPDFpreview(true)}
                      >
                        <img
                          className={classes.bellImage}
                          src={EyeBlue}
                          alt="image not found"
                        />
                        <Typography
                          className={classes.headerLink}
                          variant="body1"
                          component="p"
                        >
                          View PO
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box className={classes.infromationBody}>
                    <Grid container md={12}>
                      <Grid md={6}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <Typography
                              className={classes.bodyTitle}
                              variant="body1"
                              component="p"
                            >
                              Purpose of Request
                            </Typography>
                          </Box>
                          <Box className={classes.bodyData}>
                            <Typography
                              className={classes.bodyDataText}
                              variant="body1"
                              component="p"
                            >
                              {po_detail?.pr?.purpose_of_request ?? '-'}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid md={6}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <Typography
                              className={classes.bodyTitle}
                              variant="body1"
                              component="p"
                            >
                              Activity
                            </Typography>
                          </Box>
                          <Box className={classes.bodyData}>
                            <Typography
                              className={classes.bodyDataText}
                              variant="body1"
                              component="p"
                            >
                              {po_detail?.pr?.items?.length > 0
                                ? po_detail?.pr?.items[0]?.activity_name ?? '-'
                                : '-'}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classes.infromationBody}>
                    <Grid container md={12}>
                      <Grid md={6}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <Typography
                              className={classes.bodyTitle}
                              variant="body1"
                              component="p"
                            >
                              Currency
                            </Typography>
                          </Box>
                          <Box className={classes.bodyData}>
                            <Typography
                              className={classes.bodyDataText}
                              variant="body1"
                              component="p"
                            >
                              {po_detail?.pr?.currency ?? '-'}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid md={6}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <Typography
                              className={classes.bodyTitle}
                              variant="body1"
                              component="p"
                            >
                              Grand Total
                            </Typography>
                          </Box>
                          <Box className={classes.bodyData}>
                            <Typography
                              className={classes.bodyDataText}
                              variant="body1"
                              component="p"
                            >
                              {formatRupiah(po_detail?.pr?.grand_total || '')}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  {po_detail?.approval_code ? (
                    <Box className={classes.infromationBody}>
                      <Grid container md={12}>
                        <Grid md={6}>
                          <Box className={classes.wrapperBody}>
                            <Box className={classes.bodyContent}>
                              <Typography
                                className={classes.bodyTitle}
                                variant="body1"
                                component="p"
                              >
                                Approval Code
                              </Typography>
                            </Box>
                            <Box className={classes.bodyData}>
                              <Typography
                                className={classes.bodyDataText}
                                variant="body1"
                                component="p"
                              >
                                {po_detail?.approval_code ?? '-'}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : null}
                  {po_detail?.doc_type == 'service' ? (
                    <Box className={classes.infromationBody}>
                      <Grid md={12} className={classes.wrapperChecklist}>
                        <Typography className={classes.bodyTitle}>
                          {tempChecklist.length < 1 ? 'No ' : null}Checklist
                          document must be prepared
                        </Typography>
                        {tempChecklist.length > 0
                          ? tempChecklist.map((item, index) => (
                              <Box className={classes.checkItem} key={index}>
                                <Typography className={classes.checkItemText}>
                                  - {item.activity_name}
                                </Typography>
                                {item.checklist_attachment_id?.map(
                                  (check, checkIndex) => (
                                    <Typography
                                      className={classes.checkItemList}
                                      key={checkIndex}
                                    >
                                      {`- ${check.name}`}
                                    </Typography>
                                  )
                                )}
                              </Box>
                            ))
                          : null}
                      </Grid>
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

const useStyles = makeStyles({
  wrapperPaper: {
    minHeight: '64vh',
    backgroundColor: '#fff',
    borderRadius: 8,
  },
  formTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  borderLine: {
    borderBottom: '1px solid #E7E7E8',
  },
  formNumber: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#1B2030',
  },
  wrapperStatusForm: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  formStatus: {
    fontSize: 14,
    lineHeight: '26px',
    color: '#969BAB',
  },
  // styling status
  statusNeed: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#F12B2C',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  statusWaiting: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#FFB800',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  statusClose: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    background: '#969BAB',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  wrapperInformation: {
    paddingTop: 27,
    paddingBottom: 59,
    backgroundColor: '#fff',
  },
  informationContent: {
    // marginTop: 27,
    paddingLeft: 20,
    paddingRight: 18.5,
    backgroundColor: '#fff',
    // marginBottom: 58,
    borderRight: '1px solid #E7E7E8',
  },
  informationHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  informationHeaderLink: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
  },
  headerText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  headerLink: {
    fontSize: 14,
    lineHeight: '26px',
    fontWeight: 'bold',
    color: '#127EEE',
    marginLeft: 10,
  },
  infromationBody: {
    marginTop: 18.5,
    marginLeft: -10,
    marginRight: -10,
  },
  wrapperBody: {
    paddingRight: 10,
    paddingLeft: 10,
  },
  bodyTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
    marginBottom: 12,
  },
  bodyData: {
    width: '100%',
    backgroundColor: '#EBEFF0',
    padding: '12px 20px',
    border: '1px solid #E7E7E8',
    borderRadius: 8,
  },
  wrapperBodyGrandTotal: {},
  infromationAttachment: {
    marginTop: 30,
  },
  AttachmentTitle: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    fontWeight: 'bold',
  },
  AttachmentNotif: {
    marginTop: 20,
    marginBottom: 20,
    padding: 10,
    borderRadius: 4,
    backgroundColor: '#FFE9E8',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  AttachmentMessage: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#F12B2C',
  },
  btnUploadContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  btnUploadText: {
    fontSize: 14,
    lineHeight: '26px',
    color: '#1B2030',
  },
  btnUpload: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: '#127EEE',
    paddingLeft: 19,
    paddingRight: 19,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 8,
  },
  btnUploadValue: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
    marginLeft: 13,
  },
  assignApprovalContent: {
    paddingTop: 26,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 26,
    backgroundColor: '#fff',
    // borderBottom: '1px solid #E7E7E8',
  },
  headerTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  filterActifityContent: {
    marginTop: 20,
  },
  // styling Form select
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 5,
    paddingBottom: 5,
    // maxWidth: 220,
    width: '100%',
    // minWidth: 160,
    maxHeight: 50,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      // marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#969BAB',
      paddingRight: 2,
      backgroundColor: '#F8FAFB',
    },
  },
  sizeBox: {
    marginTop: 20,
  },
  btnOption: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  wrapperMandatory: {
    paddingLeft: 20,
    paddingTop: 15,
    paddingRight: 30,
    paddingBottom: 15,
    border: '1px solid #E7E7E8',
    borderRadius: 8,
  },
  sizeBoxTwo: {
    marginTop: 30,
  },
  textTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
  },
  titleContent: {
    marginBottom: 12,
  },
  supportContent: {
    marginTop: 20,
    marginBottom: 12,
  },
  uploadTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
  },
  bodyUrlText: {
    marginLeft: 10,
    fontSize: 16,
    lineHeight: '21px',
    textDecorationLine: 'underline',
    color: '#1B2030',
  },
  wrapperLink: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  sizeTitle: {
    marginTop: 21.5,
  },
  sizeSubLink: {
    marginTop: 31,
  },
  boxDepartment: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textTransform: 'ellipsis',
    // maxWidth: 270,
    paddingRight: 20,
  },
  bodyDataText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
  },
  bodyDataTextBlue: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#127EEE',
    fontWeight: 'bold',
  },
  bodyDataSubText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    marginLeft: 4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  BtnConfirm: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 14.5,
    paddingBottom: 14.5,
    backgroundColor: '#1B2030',
    borderRadius: 8,
    maxWidth: 193,
    marginTop: 20,
    cursor: 'pointer',
  },
  BtnConfirmFalse: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 14.5,
    paddingBottom: 14.5,
    backgroundColor: '#969BAB',
    borderRadius: 8,
    maxWidth: 193,
    marginTop: 20,
    cursor: 'pointer',
  },
  BtnConfirmText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
  },
  btnAddMoreUpload: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: 15,
    cursor: 'pointer',
  },
  wrapperChecklist: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  checkItem: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 40,
  },
  checkItemText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  checkItemList: {
    fontSize: 16,
    lineHeight: '21px',
    paddingLeft: 20,
    paddingTop: 5,
    paddingBottom: 5,
  },
});

export default PoFormApproval;
