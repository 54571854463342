import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';

const PaymentItem = ({ item, index, handleUsePayment }) => {
  const classes = useStyles();
  const [isHover, setIsHover] = React.useState(false);
  return (
    <>
      <Box
        onClick={() => handleUsePayment(item)}
        className={classes.wrapperListBank}
        key={index}
      >
        <img className={classes.bankAvatar} src={item?.bank?.avatar?.url} alt={item?.bank?.name}/>
        <Typography
          className={classes.textRekening}
          variant="body1"
          component="p"
        >
          {item?.number}
        </Typography>
        <Box
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          className={isHover ? classes.wrapperBlue : classes.wrapperBlue}
        >
          <Typography
            className={
              isHover ? classes.bodyDataTextWhite : classes.bodyDataTextBlue
            }
          >
            Use
          </Typography>
        </Box>
      </Box>
    </>
  );
};

const useStyles = makeStyles({
  wrapperListBank: {
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    height: 50,
    paddingLeft: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 8,
    marginBottom: 8,
  },
  bankAvatar: {
    maxWidth: '60px',
  },
  textRekening: {
    fontSize: 16,
    lineHeight: '30px',
    marginLeft: 20,
    flex: 1,
  },
  wrapperBlue: {
    // padding: 8,
    paddingRight: 41,
  },
  bodyDataTextBlue: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#127EEE',
    cursor: 'pointer',
    fontWeight: 'bold',
    paddingTop: 4,
    paddingBottom: 4,
    paddingRight: 6,
    paddingLeft: 6,
  },
  bodyDataTextWhite: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#fff',
    cursor: 'pointer',
    fontWeight: 'bold',
    paddingTop: 4,
    paddingBottom: 4,
    paddingRight: 6,
    paddingLeft: 6,
    borderRadius: 8,
    background: '#127EEE',
  },
});

export default PaymentItem;
