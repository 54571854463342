import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const FooterLine = () => {
  const classes = useStyles();

  return (
    <Grid md={12} className={classes.wrapperLine}>
      <Box className={classes.secitonOne}></Box>
      <Box className={classes.secitonTwo}></Box>
      <Box className={classes.secitonThree}></Box>
    </Grid>
  );
};

const useStyles = makeStyles({
  secitonOne: {
    width: '30%',
    height: 12,
    backgroundColor: '#1B2030',
  },
  secitonTwo: {
    width: '20%',
    height: 12,
    backgroundColor: '#E61724',
  },
  secitonThree: {
    width: '50%',
    height: 12,
    backgroundColor: '#AC121C',
  },
  wrapperLine: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    // position: 'absolute',
    zIndex: 10,
  },
});

export default FooterLine;
