import React from 'react';
import { Grid, Box, makeStyles, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { dateFormater } from '../../utils/formatter';

// Svg
import { SortArrow } from '../../assets/Icons/svg/SortArrow';

const HistoryTableDetail = ({ showHistory, data }) => {
  const classes = useStyles();

  const _renderStatusStyle = (status) => {
    if (status == 1) {
      return classes.statusApprove;
    } else {
      return classes.statusReject;
    }
  };

  const _renderStatus = (approve, reject) => {
    if (approve === 1 && reject === 0) {
      return <Box className={classes.approve}>Approved</Box>;
    } else if (approve === 0 && reject === 1) {
      return <Box className={classes.reject}>Reject</Box>;
    } else {
      return <Box className={classes.waiting}>Waiting Approval</Box>;
    }
  };

  const _renderHistoryApproval = () => {
    return (
        <Grid md={12} sm={12} xs={12}>
          <Box className={classes.historyContent}>
            <Box className={classes.historyTitle}>
              <Typography
                className={classes.historyTitleText}
                variant="body1"
                component="p"
              >
                Approval History
              </Typography>
            </Box>
          </Box>
          <Box style={{ width: '100%' }}>
            <TableContainer className={classes.tableContainer}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Box className={classes.wrapperTableHead}>
                        <Box>Name</Box>
                        <Box className={classes.iconSort}>
                          <SortArrow />
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Box className={classes.wrapperTableHead}>
                        <Box>Status</Box>
                        <Box className={classes.iconSort}>
                          <SortArrow />
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Box className={classes.wrapperTableHead}>
                        <Box>Note</Box>
                        <Box className={classes.iconSort}>
                          <SortArrow />
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Box className={classes.wrapperTableHead}>
                        <Box>Approved At</Box>
                        <Box className={classes.iconSort}>
                          <SortArrow />
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((person, index) => (
                    <TableRow
                      key={index}
                      className={
                        index % 2 == 0
                          ? classes.tableContentData
                          : classes.tableContentDataDua
                      }
                    >
                      <TableCell>
                        <Box className={classes.boxDepartment}>
                          <Typography
                            className={classes.bodyDataText}
                            variant="body1"
                            component="p"
                          >
                            {person?.user?.name}
                          </Typography>
                          <Typography
                            className={classes.bodyDataSubText}
                            variant="body1"
                            component="p"
                          >
                            {`${person?.user?.positions?.name}`}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {_renderStatus(person?.approve, person?.reject)}
                      </TableCell>
                      <TableCell>
                        {person?.message === null || person?.message === ''
                          ? '-'
                          : person?.message}
                      </TableCell>
                      <TableCell>
                        {(person?.approve || person?.reject) && person?.updated_at
                          ? dateFormater(person?.updated_at, 'DD-MM-YYYY HH:mm')
                          : '-'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
    );
  };

  const _renderNullHistory = () => {
    return (
      <Grid container>
        <Grid md={12} sm={12} xs={12}>
          <Box className={classes.historyContent}>
            <Box className={classes.historyTitle}>
              <Typography
                className={classes.historyTitleText}
                variant="body1"
                component="p"
              >
                Approval History
              </Typography>
            </Box>
          </Box>
          <Box className={classes.tableNull}>
            <Typography
              className={classes.tableNullText}
              variant="body1"
              component="p"
            >
              no data
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {(!showHistory || data?.length < 1) ? (
        <_renderNullHistory />
      ) : (
        <_renderHistoryApproval />
      )}
    </>
  );
};

const useStyles = makeStyles({
  historyContent: {
    marginTop: 20,
    width: '100%',
    borderBottom: '1px solid #E7E7E8',
  },
  historyTitle: {
    paddingLeft: 22,
    paddingRight: 22,
    paddingTop: 27,
    paddingBottom: 27,
    backgroundColor: '#fff',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  historyTitleText: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  tableNull: {
    paddingTop: 25,
    paddingBottom: 25,
    backgroundColor: '#F8FAFB',
  },
  tableNullText: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
    textAlign: 'center',
  },
  wrapperTableHead: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  // Styling Table
  tableContainer: {
    // maxHeight: '400px',
    Width: '100%',
    background: '#fff',
    '&.MuiTableContainer-root .MuiTableRow-root .MuiTableCell-root.MuiTableCell-head':
      {
        paddingLeft: 20,
        paddingTop: 15,
        paddingBottom: 15,
        fontSize: 16,
        lineHeight: '30px',
        fontWeight: 'bold',
        color: '#000000',
        border: 'none',
      },
    '& .MuiTableCell-root.MuiTableCell-head:nth-child(1)': {
      width: 500,
    },
    '& .MuiTableCell-root.MuiTableCell-head:nth-child(3)': {
      width: 300,
    },
  },
  tableContentData: {
    backgroundColor: '#F8FAFB',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    border: 'none',
    borderBottom: 'none',
    '&.MuiTableRow-root .MuiTableCell-root.MuiTableCell-body': {
      borderBottom: 'none',
      paddingLeft: 20,
      paddingRight: 8,
      paddingTop: 16,
      paddingBottom: 16,
    },
    '&.MuiTableRow-root .MuiTableCell-sizeSmall': {
      paddingRight: 0,
      borderBottom: 'none',
    },
  },
  tableContentDataDua: {
    backgroundColor: '#fff',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    border: 'none',
    borderBottom: 'none',
    '&.MuiTableRow-root .MuiTableCell-sizeSmall': {
      paddingRight: 0,
      borderBottom: 'none',
    },
    '&.MuiTableRow-root .MuiTableCell-root.MuiTableCell-body': {
      borderBottom: 'none',
      paddingLeft: 20,
      paddingRight: 8,
      paddingTop: 16,
      paddingBottom: 16,
    },
  },
  iconSort: {
    paddingTop: 20,
    width: 10,
    marginLeft: 5,
  },
  boxDepartment: {},
  bodyDataSubText: {
    fontSize: 14,
    lineHeight: '21px',
    color: '#969BAB',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  approve: {
    width: '100%',
    maxWidth: 92,
    background: '#27AE60',
    textAlign: 'center',
    borderRadius: 100,
    textTransform: 'uppercase',
    color: '#fff',
    fontSize: 12,
    lineHeight: '26px',
    fontWeight: 'bold',
  },
  reject: {
    width: '100%',
    maxWidth: 73,
    background: '#FD453A',
    textAlign: 'center',
    borderRadius: 100,
    textTransform: 'uppercase',
    color: '#fff',
    fontSize: 12,
    lineHeight: '26px',
    fontWeight: 'bold',
  },
  waiting: {
    width: '100%',
    maxWidth: 150,
    backgroundColor: '#FFB800',
    textAlign: 'center',
    borderRadius: 100,
    textTransform: 'uppercase',
    color: '#fff',
    fontSize: 12,
    lineHeight: '26px',
    fontWeight: 'bold',
  },
});

export default HistoryTableDetail;
