import { Typography } from '@material-ui/core';

export const ContentText = ({ content, variant, styling }) => {
  return (
    <Typography
      variant={variant ?? 'body1'}
      component={'p'}
      className={styling}
    >
      {content}
    </Typography>
  );
};
