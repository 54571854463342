import React from 'react';
import { makeStyles, Grid, Box, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import axios from 'axios';
import { END_POINT } from '../../config/EndPoint';
import { useRouter } from '../../utils/useRouter';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../redux/GRPO/actions';
import * as actions_2 from '../../redux/Utility/actions';
import * as userManagement from '../../redux/UserManagement/actions';
import * as settingAction from 'src/redux/Settings/actions';

import {
  dateFormater,
  formatFileCount,
  formatRupiah,
} from '../../utils/formatter';
import { get, isEmpty } from 'lodash';

// Components
import ButtonTransparent from '../Button/ButtonTransparent';
import HistoryTableDetail from '../HistoryTableDetail';
import ButtonQrCode from '../Button/ButtonQrCode';
import ButtonCancel from '../Button/ButtonCancel';
import ModalCancelPr from '../ModalCancelPr';
import ModalSuccess from '../ModalSuccess';
import ModalQRCode from '../ModalQRCode';
import ModalUpload from '../ModalUpload';
import ModalConfirmUpload from '../ModalConfirmUpload';
import ButtonMandatory from '../Button/ButtonMandatory';
import ModalSingleUpload from '../ModalSingleUpload';
import { CircleLoading } from '../Loading/CircleLoading';
import SKGRPODetail from '../Skeleton/SKGRPODetail';
import Commodity from '../PDFpreview/GRPOpreview/Commodity';
import NonCommodity from '../PDFpreview/GRPOpreview/NonCommodity';
import Assets from '../PDFpreview/GRPOpreview/Assets';
import Service from '../PDFpreview/GRPOpreview/Service';
import CustomizedSnackbars from '../PopupMessage';
import TrashDelete from '../TrashDelete';
import ModalDeleteItem from '../ModalDeleteItem';
import SelectFilter from '../SelectFilter';
import { ContentText } from '../../libs/Typography';

//config
import globalConfig from 'src/config/global';

// Icons
import PdfFile from '../../assets/Icons/pdfIcons.png';
import CirclePlus from '../../assets/Icons/circlePlus.png';
import UnduhSecond from '../../assets/Icons/unduhSecond.png';
import Paper from '../../assets/Icons/paper.png';
import Lib from 'src/utils/Lib';

const GRPOFormApproval = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispacth = useDispatch();
  const { id } = router.query;

  // Global Variable
  const { access_token, data } = useSelector((state) => state.auth);
  const {
    grpo_detail,
    detail_message,
    detail_isLoading,
    cancel_grpoMessage,
    cancel_grpoLoading,
    assigment_message,
    assignment_loading,
  } = useSelector((state) => state.grpo);
  const {
    qrcode_data,
    qrcode_loading,
    read_loading,
    read_data,
    read_loading_second,
    read_data_second,
    read_loading_third,
    read_data_third,
    del_file_isLoading,
    del_file_message,
  } = useSelector((state) => state.utility);

  const { internal_all_data, internal_all_IsLoading } = useSelector(
    (state) => state.userManagement
  );

  // initial state
  const [fileData, setFileData] = React.useState([]);
  const [showFileData, setShowFileData] = React.useState(false);
  const [fileDataSecond, setFileDataSecond] = React.useState([]);
  const [showFileDataSecond, setShowFileDataSecond] = React.useState(false);
  const [fileDataThird, setFileDataThird] = React.useState([]);
  const [showFileDataThird, setShowFileDataThird] = React.useState(false);

  const [aprrovalOne, setArpprovalOne] = React.useState(null);
  const [aprrovalTwo, setArpprovalTwo] = React.useState(null);
  const [aprrovalThree, setArpprovalThree] = React.useState(null);

  const [tempDeleteId, setTempDeleteId] = React.useState(0);
  const [rowApproval, setRowApproval] = React.useState(null);

  // handle Render By Status
  const [isNeed, seIsNeed] = React.useState(false);
  const [btnQRon, setBtnQRon] = React.useState(false);
  const [canDownloadPDF, setCanDownloadPDF] = React.useState(false);
  const [canUpload, setCanUpload] = React.useState(false);
  const [canDelete, setCanDelete] = React.useState(false);

  // handleshow modal
  const [showModalConfirm, setShowModalConfirm] = React.useState(false);
  const [showModalSuccess, setShowModalSuccess] = React.useState(false);
  const [showModalSuccessUpload, setShowModalSuccessUpload] =
    React.useState(false);
  const [showModalQRCode, setShowModalQRCode] = React.useState(false);
  const [showModalDetail, setShowModalDetail] = React.useState(false);
  const [showModalUpload, setShowModalUpload] = React.useState(false);
  const [isStatusNeed, setIsStatusNeed] = React.useState(false);
  const [showModalConfirmUpload, setShowModalConfirmUpload] =
    React.useState(false);
  const [showModalSingleUpload, setShowModalSingleUpload] =
    React.useState(false);
  const [showModalDelete, setShowModalDelete] = React.useState(false);
  const [showModalSuccesDel, setShowModalSuccessDel] = React.useState(false);

  // configure to handle show Pdf Preview
  const [showPDFpreview, setShowPDFpreview] = React.useState(false);
  const [showPDFNonCommodity, setShowPDFNonCommodity] = React.useState(false);
  const [showPDFAssets, setShowPDFAssests] = React.useState(false);
  const [showPDFService, setShowPDFService] = React.useState(false);

  // handle message
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };

  // end handle Message

  // configure to handle File for upload GRPO
  const [imageColletion, setImageCollection] = React.useState([]);
  const [uploadLoading, setUploadLoading] = React.useState(false);
  const [percent, setPercent] = React.useState(0);
  const [totalFile, setTotalFile] = React.useState(0);
  const [uploadedFile, setUploadedFile] = React.useState(0);

  const onFileChange = (e) => {
    let tempFile = e.target.files;

    // console.log(tempFile);

    setImageCollection((prev) => [...prev, ...tempFile]);
  };

  const handleRemoveFile = (indexOf) => {
    let filteImage = imageColletion.filter((img, index) => index !== indexOf);

    setImageCollection(filteImage);
  };

  const handleUploadFile = () => {
    if (imageColletion) {
      try {
        setUploadLoading(true);

        const sendData = new FormData();
        imageColletion.map((img, index) => {
          sendData.append('file[]', img);
        });

        const config = {
          headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${access_token}`,
          },
          onUploadProgress: (progressEvent) => {
            var percentComplete = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setPercent(percentComplete);
          },
        };

        axios
          .post(
            `${END_POINT}files?doc_type=${'GRPO'}&doc_id=${
              grpo_detail?.id
            }&entity_code=${grpo_detail?.entity?.code}`,
            sendData,
            config
          )
          .then((res) => {
            // console.log(res);
            setPercent(0);
            setUploadLoading(false);
            setShowModalConfirmUpload(false);
            setShowModalUpload(false);
            setImageCollection([]);
            handleGetFileGRPO();
            setShowModalSuccessUpload(true);
          });
      } catch (error) {
        console.log('error upload file');
        setUploadLoading(false);
      }
      // dispacth(actions_2.uploadFile(access_token, sendData));
    } else {
      console.log('chosses an image');
    }
  };

  // end configure file upload

  const handleCloseModalConfirm = () => {
    setShowModalConfirm(false);
  };
  const handleCloseModalSuccess = () => {
    setShowModalSuccess(false);
  };
  const handleCloseModalSuccessUpload = () => {
    setShowModalSuccessUpload(false);
  };
  const handleCloseUploading = () => {
    setShowModalUpload(false);
    setShowModalConfirmUpload(false);
    setShowModalSuccessUpload(false);
  };
  const handleCloseModalQRCode = () => {
    setShowModalQRCode(false);
  };
  const handleCloseModalDetail = () => {
    setShowModalDetail(false);
  };
  const handleCloseModalUpload = () => {
    setShowModalUpload(false);
  };
  const handleCloseModalConfirmUpload = () => {
    setShowModalConfirmUpload(false);
  };
  const handleCloseModalSingleUpload = () => {
    setShowModalSingleUpload(false);
  };

  const handleClosePDFpreview = () => {
    setShowPDFpreview(false);
  };

  const handleClosePDFNonCommodity = () => {
    setShowPDFNonCommodity(false);
  };

  const handleClosePDFAssets = () => {
    setShowPDFAssests(false);
  };

  const handleClosePDFService = () => {
    setShowPDFService(false);
  };

  const baseHandleChangeApproval = (newValue, setFn) => {
    const setVal = newValue?.id ?? null;
    setFn(setVal);
  }

  // handle option select admin
  const handleChangeApprovalOne = (event, newValue) => {
    baseHandleChangeApproval(newValue, setArpprovalOne);
  };
  const handleChangeApprovalTwo = (event, newValue) => {
    baseHandleChangeApproval(newValue, setArpprovalTwo);
  };
  const handleChangeApprovalThree = (event, newValue) => {
    baseHandleChangeApproval(newValue, setArpprovalThree);
  };

  // configure btn action
  const handleShowPDFpreview = (purchaseType) => {
    if (purchaseType === 'Non-commodity (POSM/Gimmick)') {
      setShowPDFNonCommodity(true);
    } else if (
      purchaseType === 'Commodity - Local' ||
      purchaseType === 'Commodity - Import'
    ) {
      setShowPDFpreview(true);
    } else if (purchaseType === 'Fixed Asset') {
      setShowPDFAssests(true);
    } else if (purchaseType === 'General Service') {
      setShowPDFService(true);
    }
    // console.log(purchaseType);
  };
  const handleCancelPR = (id) => {
    if (data?.user?.role.length > 0) {
      if (data?.user?.role[0] == 'admin' || data?.user?.role[0] == 'staff') {
        setType('error');
        setShow(true);
        setNotifText('you not have permission to take this action');
      } else {
        dispacth(actions.cancelGRPO(access_token, id));
      }
    }
  };

  const handleSeeImage = (url) => {
    window.open(url);
  };

  const handleCloseQR = () => {
    setShowModalQRCode(false);
    dispacth(actions.getGRPODetail(access_token, id));
  };

  const handleGetFileGRPO = () => {
    //this configure for get file when page load or upload from client
    // get file GRPO
    let readDataGRPO = {
      doc_type: 'GRPO',
      doc_id: grpo_detail?.id,
      entity_code: grpo_detail?.entity?.code,
    };

    dispacth(actions_2.readFileThird(access_token, readDataGRPO));
  };

  const handleConfirmAssigment = () => {
    const data = [];

    if (aprrovalThree) {
      data.push({
        transaction_type_id: '6',
        doc_id: grpo_detail?.id,
        user_id: aprrovalThree,
        level: '3',
      });
    }

    if (aprrovalTwo) {
      data.push({
        transaction_type_id: '6',
        doc_id: grpo_detail?.id,
        user_id: aprrovalTwo,
        level: '2',
      });
    }

    if (aprrovalOne) {
      data.push({
        transaction_type_id: '6',
        doc_id: grpo_detail?.id,
        user_id: aprrovalOne,
        level: '1',
      });
    }

    dispacth(actions.assigmentGRPO(access_token, grpo_detail?.id, data));
  };

  const handleDelete = (id) => {
    setTempDeleteId(id);
    setShowModalDelete(true);
  };
  const reqDeleteFile = () => {
    dispacth(actions_2.delFile(access_token, tempDeleteId));
  };
  const handleSuccesDelete = () => {
    handleGetFileGRPO();
    setShowModalSuccessDel(false);
  };

  React.useEffect(() => {
    dispacth(actions_2.NullFile());
    dispacth(actions.getGRPODetail(access_token, id));
    setFileData([]);
  }, []);

  React.useEffect(() => {
    if (
      grpo_detail?.status !== 'need_confirm' &&
      grpo_detail?.approval_transactions?.length > 0
    ) {
      setIsStatusNeed(true);
    } else {
      setIsStatusNeed(false);
    }

    if (
      grpo_detail?.status === 'need_confirm' ||
      grpo_detail?.status === 'rejected'
    ) {
      setCanDelete(true);
      setCanUpload(true);
    } else {
      setCanDelete(false);
      setCanUpload(false);
    }

    grpo_detail?.qr_code ? setBtnQRon(false) : setBtnQRon(true);
    grpo_detail?.qr_code ? setCanDownloadPDF(true) : setCanDownloadPDF(false);

    // get file PR
    let readDataPR = {
      doc_type: 'PR',
      doc_id: grpo_detail?.po?.pr?.id,
      entity_code: grpo_detail?.entity?.code,
    };

    dispacth(actions_2.readFile(access_token, readDataPR));

    // get file PO
    let readDataPO = {
      doc_type: 'PO',
      doc_id: grpo_detail?.po?.id,
      entity_code: grpo_detail?.entity?.code,
    };
    dispacth(actions_2.readFileSecond(access_token, readDataPO));

    handleGetFileGRPO();

    // fot get internal user
    dispacth(userManagement.getInternalAllUser(access_token));
    if(grpo_detail?.entity?.color){
      dispacth(settingAction.setBackgroundColor(grpo_detail?.entity?.color))
    }

  }, [grpo_detail]);

    //unmount
    React.useEffect(()=>{
      return () => dispacth(settingAction.setBackgroundColor(''))
    },[])

  React.useEffect(() => {
    if (read_data && read_data.length > 0) {
      setShowFileData(true);
      setFileData(read_data);
    }
  }, [read_data]);

  React.useEffect(() => {
    if (read_data_second && read_data_second.length > 0) {
      setShowFileDataSecond(true);
      setFileDataSecond(read_data_second);
    }
  }, [read_data_second]);

  React.useEffect(() => {
    if (read_data_third && read_data_third.length > 0) {
      setShowFileDataThird(true);
      setFileDataThird(read_data_third);
    } else {
      setShowFileDataThird(false);
      setFileDataThird([]);
    }
  }, [read_data_third]);

  // Generarte Qr
  // Change with url Stating or Production late
  let url = `https://dkt.senisites.com/grpo-detail/grpoId=${grpo_detail?.id}`;

  const generateQrCode = () => {
    let params = {
      doc_type: 'GRPO',
      entity_id: grpo_detail?.entity?.id,
      doc_id: grpo_detail?.id,
      url,
    };

    dispacth(actions_2.getQrCode(access_token, params));
    setShowModalQRCode(true);
  };

  React.useEffect(() => {
    if (assigment_message) {
      router.push('/grpo');
      dispacth(actions.setAssigmentMessage());
    }
  }, [assigment_message]);

  React.useEffect(() => {
    if (cancel_grpoMessage) {
      if (
        cancel_grpoMessage ===
        'Update cancel status goods receipt purchase order Success'
      ) {
        setShowModalConfirm(false);
        setShowModalSuccess(true);
      } else if (cancel_grpoMessage !== '') {
        setType('error');
        setShow(true);
        setNotifText(cancel_grpoMessage);
      }
      dispacth(actions.setBlankCancel());
    }
  }, [cancel_grpoMessage]);

  React.useEffect(() => {
    if (del_file_message) {
      if (del_file_message === 'Delete file Success') {
        setShowModalDelete(false);
        setShowModalSuccessDel(true);
      } else if (
        del_file_message !== '' &&
        del_file_message !== 'Delete file Success'
      ) {
        setType('error');
        setShow(true);
        setNotifText(del_file_message);
      }
      dispacth(actions_2.setBlankDelFile());
    }
  }, [del_file_message]);

  // React.useEffect(() => {
  //   dispacth(userManagement.getInternalAllUser(access_token));
  // }, []);

  const findApprovalTransactionUserId = (level) => {
    return grpo_detail?.approval_transactions?.find(v=>v.level===level)?.user?.id;
  }

  React.useEffect(() => {
    if (internal_all_data) {
      setArpprovalOne(
        findApprovalTransactionUserId(1) ?? null
      );
      setArpprovalTwo(
        findApprovalTransactionUserId(2) ?? null
      );
      setArpprovalThree(
        findApprovalTransactionUserId(3) ?? null
      );
    }
  }, [internal_all_data]);

  React.useEffect(() => {
    let filterUser = internal_all_data?.filter(
      (user) =>
        user?.id !== aprrovalOne &&
        user?.id !== aprrovalTwo &&
        user?.id !== aprrovalThree
    );

    setRowApproval(filterUser);
  }, [aprrovalOne, aprrovalTwo, aprrovalThree]);

  // for render styling
  const _renderStatus = (status) => {
    if (status === 'need_confirm') {
      return (
        <>
          <Typography className={classes.statusNeed}>
            Confirm Approval
          </Typography>
        </>
      );
    } else if (status === 'waiting') {
      return (
        <>
          <Typography className={classes.statusWaiting}>
            Waiting Approval
          </Typography>
        </>
      );
    } else if (status === 'cancelled' || status === 'closed') {
      return (
        <>
          <Typography className={classes.statusClose}>{status}</Typography>
        </>
      );
    } else if (status === 'approved') {
      return (
        <Typography className={classes.statusApprove}>{status}</Typography>
      );
    }
  };

  const _renderButtonAction = (status) => {
    if (status === 'closed' || status === 'approved') {
      return null
      // return (
      //   <ButtonQrCode
      //     isOn={btnQRon}
      //     onPress={generateQrCode}
      //     showModal={() => setShowModalQRCode(true)}
      //     handleCanclePR={() => setShowModalConfirm(true)}
      //     hiddenCancel={globalConfig.cancelButton.hideOnStatus.includes(status) || !Lib.allowCancel(data)}
      //     title="GRPO"
      //   />
      // );
    } else if (status === 'need_confirm' || status === 'waiting') {
      return (
        <ButtonTransparent
          onSubmit={() => setShowModalConfirmUpload(true)}
          setShowModalConfirm={setShowModalConfirm}
          hiddenCancel={!Lib.allowCancel(data)}
          text={'Cancel GRPO'}
        />
      );
    } else if (status === 'rejected' && Lib.allowCancel(data)) {
      return (
        <ButtonCancel
          title={'GRPO'}
          handleCanclePR={() => setShowModalConfirm(true)}
          approval={grpo_detail?.approval_transactions}
        />
      );
    }
  };

  return (
    <>
      {detail_isLoading ? (
        <SKGRPODetail />
      ) : (
        <>
          <CustomizedSnackbars
            show={show}
            handleClose={handleClose}
            message={notifText}
            type={type}
          />
          <Grid container>
            {/* modal delete */}
            <ModalDeleteItem
              open={showModalDelete}
              close={() => setShowModalDelete(false)}
              id={tempDeleteId}
              onPress={reqDeleteFile}
              isLoading={del_file_isLoading}
            />
            <ModalSuccess
              open={showModalSuccesDel}
              onPress={handleSuccesDelete}
            />
            {/* for cancel GRPO */}
            <ModalSuccess
              open={showModalSuccess}
              setModalConfirm={setShowModalSuccess}
              handleCloseModalSuccess={handleCloseModalSuccess}
              onPress={() => router.push('/grpo')}
            />
            {/* for uploading file */}
            <ModalSuccess
              open={showModalSuccessUpload}
              setModalConfirm={setShowModalSuccessUpload}
              handleCloseModalSuccess={handleCloseModalSuccessUpload}
              onPress={handleCloseUploading}
            />
            <ModalCancelPr
              open={showModalConfirm}
              setModalConfirm={setShowModalConfirm}
              handleCloseModalConfirm={handleCloseModalConfirm}
              onPress={() => handleCancelPR(grpo_detail.id)}
              title="GRPO"
              isLoading={cancel_grpoLoading}
            />
            <ModalQRCode
              open={showModalQRCode}
              // handleCloseModalQRCode={handleCloseModalQRCode}
              onClose={handleCloseQR}
              title="GRPO"
              qrCode={qrcode_data}
              isLoading={qrcode_loading}
              no={grpo_detail?.doc_num}
              name={grpo_detail?.po?.pr?.purpose_of_requested ?? '-'}
              activity={grpo_detail?.po?.pr?.activity_name ?? '-'}
              total={grpo_detail?.doc_total_amount ?? '-'}
            />
            <ModalUpload
              open={showModalUpload}
              handleCloseModalUpload={handleCloseModalUpload}
              onPress={() => setShowModalConfirmUpload(true)}
              imageColletion={imageColletion}
              handleRemoveFile={handleRemoveFile}
              onFileChange={onFileChange}
            />
            <ModalConfirmUpload
              open={showModalConfirmUpload}
              handleCloseModalConfirmUpload={handleCloseModalConfirmUpload}
              onPress={handleUploadFile}
              uploadPercent={percent}
              isLoading={uploadLoading}
              title="GRPO"
            />
            <ModalSingleUpload
              open={showModalSingleUpload}
              close={handleCloseModalSingleUpload}
            />
            <Commodity
              open={showPDFpreview}
              close={handleClosePDFpreview}
              data={grpo_detail}
            />
            <NonCommodity
              open={showPDFNonCommodity}
              close={handleClosePDFNonCommodity}
              data={grpo_detail}
            />
            <Assets
              open={showPDFAssets}
              close={handleClosePDFAssets}
              data={grpo_detail}
            />
            <Service
              open={showPDFService}
              close={handleClosePDFService}
              data={grpo_detail}
            />

            <Grid md={12} sm={12} xs={12}>
              <Box className={classes.formTitle}>
                <Typography
                  className={classes.formNumber}
                  variant="body1"
                  component="p"
                >
                  {`GRPO No. ${grpo_detail?.doc_num}`}
                </Typography>
                <Box className={classes.wrapperStatusForm}>
                  <Typography
                    className={classes.bodyDataText}
                    variant="body1"
                    component="p"
                  >
                    {dateFormater(grpo_detail?.created_at, 'DD-MM-YYYY hh:mm')}
                  </Typography>
                  {_renderStatus(grpo_detail?.status)}
                </Box>
              </Box>
              <Box className={classes.borderLine} />
            </Grid>
            <Grid md={8} sm={12} xs={12}>
              <Box className={classes.wrapperInformation}>
                <Box className={classes.informationContent}>
                  <Box className={classes.informationHeader}>
                    <Typography
                      className={classes.headerText}
                      variant="body1"
                      component="p"
                    >
                      GRPO Information
                    </Typography>
                    {canDownloadPDF ? (
                      <Box
                        className={classes.informationHeaderLink}
                        onClick={() => handleSeeImage(grpo_detail?.qr_code)}
                      >
                        <img
                          className={classes.bellImage}
                          src={PdfFile}
                          alt="image not found"
                        />
                        <Typography
                          className={classes.headerLink}
                          variant="body1"
                          component="p"
                        >
                          View GRPO
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        className={classes.informationHeaderLink}
                        onClick={() =>
                          handleShowPDFpreview(
                            grpo_detail?.purchase_type?.description
                          )
                        }
                      >
                        <img
                          className={classes.bellImage}
                          src={PdfFile}
                          alt="image not found"
                        />
                        <Typography
                          className={classes.headerLink}
                          variant="body1"
                          component="p"
                        >
                          View GRPO
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box className={classes.infromationBody}>
                    <Grid container md={12}>
                      <Grid md={6} sm={6} xs={6}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <Typography
                              className={classes.bodyTitle}
                              variant="body1"
                              component="p"
                            >
                              PO No.
                            </Typography>
                          </Box>
                          <Box className={classes.bodyData}>
                            <Typography
                              className={classes.bodyDataText}
                              variant="body1"
                              component="p"
                            >
                              {grpo_detail?.po?.doc_num ?? '-'}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid md={6} sm={6} xs={6}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <Typography
                              className={classes.bodyTitle}
                              variant="body1"
                              component="p"
                            >
                              Purpose of Request
                            </Typography>
                          </Box>
                          <Box className={classes.bodyData}>
                            <Box className={classes.boxDepartment}>
                              <Typography
                                className={classes.bodyDataText}
                                variant="body1"
                                component="p"
                              >
                                {grpo_detail?.po?.pr?.purpose_of_request ?? '-'}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classes.infromationBody}>
                    <Grid container md={12}>
                      <Grid md={6} sm={6} xs={6}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <Typography
                              className={classes.bodyTitle}
                              variant="body1"
                              component="p"
                            >
                              Currency
                            </Typography>
                          </Box>
                          <Box className={classes.bodyData}>
                            <Typography
                              className={classes.bodyDataText}
                              variant="body1"
                              component="p"
                            >
                              {grpo_detail?.currency}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid md={6} sm={6} xs={6}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <Typography
                              className={classes.bodyTitle}
                              variant="body1"
                              component="p"
                            >
                              Grand Total
                            </Typography>
                          </Box>
                          <Box className={classes.bodyData}>
                            <Typography
                              className={classes.bodyDataText}
                              variant="body1"
                              component="p"
                            >
                              {grpo_detail?.currency === 'IDR'
                                ? formatRupiah(
                                    grpo_detail?.doc_total_amount ?? '0'
                                  )
                                : grpo_detail?.doc_total_amount ?? '0'}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classes.infromationBody}>
                    <Grid container md={12}>
                      <Grid md={6} sm={6} xs={6}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <Typography
                              className={classes.bodyTitle}
                              variant="body1"
                              component="p"
                            >
                              Department
                            </Typography>
                          </Box>
                          <Box className={classes.bodyData}>
                            <Box className={classes.boxDepartment}>
                              <Typography
                                className={classes.bodyDataText}
                                variant="body1"
                                component="p"
                              >
                                {grpo_detail?.po?.pr?.department?.code}
                              </Typography>
                              <Typography
                                className={classes.bodyDataSubText}
                                variant="body1"
                                component="p"
                              >
                                - {grpo_detail?.po?.pr?.department?.name}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid md={6} sm={6} xs={6}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <Typography
                              className={classes.bodyTitle}
                              variant="body1"
                              component="p"
                            >
                              Entity
                            </Typography>
                          </Box>
                          <Box className={classes.bodyData}>
                            <Box className={classes.boxDepartment}>
                              <Typography
                                className={classes.bodyDataText}
                                variant="body1"
                                component="p"
                              >
                                {grpo_detail?.entity?.name}
                              </Typography>
                              {/* <Typography
                            className={classes.bodyDataSubText}
                            variant="body1"
                            component="p"
                          >
                            - Yayasan DKT Indonesia
                          </Typography> */}
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box className={classes.infromationBody}>
                    <Grid container md={12}>
                      <Grid md={6} sm={6} xs={6}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <Typography
                              className={classes.bodyTitle}
                              variant="body1"
                              component="p"
                            >
                              Activity
                            </Typography>
                          </Box>
                          <Box className={classes.bodyData}>
                            <Typography
                              className={classes.bodyDataText}
                              variant="body1"
                              component="p"
                            >
                              {grpo_detail?.po?.pr?.items.length > 0
                                ? grpo_detail?.po?.pr?.items[0].activity_name ??
                                  '-'
                                : '-'}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid md={6} sm={6} xs={6}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <Typography
                              className={classes.bodyTitle}
                              variant="body1"
                              component="p"
                            >
                              Category
                            </Typography>
                          </Box>
                          <Box className={classes.bodyData}>
                            <Typography
                              className={classes.bodyDataText}
                              variant="body1"
                              component="p"
                            >
                              {grpo_detail?.purchase_type?.description ?? '-'}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  {grpo_detail?.approval_code ? (
                    <Box className={classes.infromationBody}>
                      <Grid container md={12}>
                        <Grid md={6}>
                          <Box className={classes.wrapperBody}>
                            <Box className={classes.bodyContent}>
                              <Typography
                                className={classes.bodyTitle}
                                variant="body1"
                                component="p"
                              >
                                Approval Code
                              </Typography>
                            </Box>
                            <Box className={classes.bodyData}>
                              <Typography
                                className={classes.bodyDataText}
                                variant="body1"
                                component="p"
                              >
                                {grpo_detail?.approval_code ?? '-'}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : null}

                  <Box className={classes.infromationAttachment}>
                    <Grid container>
                      <Grid md={12} style={{ marginBottom: 24 }}>
                        <Box>
                          <Typography
                            className={classes.AttachmentTitle}
                            variant="body1"
                            component="p"
                          >
                            Attachment
                          </Typography>
                        </Box>
                      </Grid>

                      {/* Mandatory Menu */}
                      <Grid xs={12} md={12} container>
                        <Grid md={6}>
                          <Typography
                            className={classes.uploadTitle}
                            variant="body1"
                            component="p"
                          >
                            PR Document
                          </Typography>
                          <Box className={classes.sizeTitle} />
                          {read_loading ? (
                            <CircleLoading />
                          ) : fileData.length < 1 ? (
                            <>
                              <Typography
                                className={classes.AttachmentMessage}
                                variant="body1"
                                component="p"
                              >
                                No Attach File
                              </Typography>
                            </>
                          ) : (
                            fileData?.map((data, index) => (
                              <>
                                <Box
                                  key={index}
                                  className={classes.wrapperLink}
                                  onClick={() => handleSeeImage(data?.url)}
                                >
                                  <Box>
                                    <img
                                      // className={classes.bellImage}
                                      src={Paper}
                                      alt="image not found"
                                    />
                                  </Box>
                                  <Typography
                                    className={classes.bodyUrlText}
                                    variant="body1"
                                    component="p"
                                  >
                                    {`${data.file_name} ${formatFileCount(
                                      data?.size
                                    )} MB`}
                                  </Typography>
                                </Box>
                                <Box className={classes.sizeSubLink} />
                              </>
                            ))
                          )}

                          {grpo_detail?.po?.pr?.qr_code && (
                            <Box
                              className={classes.informationHeaderLink}
                              onClick={() =>
                                handleSeeImage(grpo_detail?.po?.pr?.qr_code)
                              }
                            >
                              <ContentText
                                styling={classes.headerLink}
                                variant={'body1'}
                                content={`View PR`}
                              />
                            </Box>
                          )}
                        </Grid>

                        <Grid md={6}>
                          <Typography
                            className={classes.uploadTitle}
                            variant="body1"
                            component="p"
                          >
                            PO Document
                          </Typography>
                          <Box className={classes.sizeTitle} />
                          {read_loading_second ? (
                            <CircleLoading />
                          ) : fileDataSecond.length < 1 ? (
                            <>
                              <Typography
                                className={classes.AttachmentMessage}
                                variant="body1"
                                component="p"
                              >
                                No Attach File
                              </Typography>
                              <Box className={classes.sizeSubLink} />
                            </>
                          ) : (
                            fileDataSecond?.map((data, index) => (
                              <>
                                <Box
                                  key={index}
                                  className={classes.wrapperLink}
                                  onClick={() => handleSeeImage(data?.url)}
                                >
                                  <Box>
                                    <img
                                      // className={classes.bellImage}
                                      src={Paper}
                                      alt="image not found"
                                    />
                                  </Box>
                                  <Typography
                                    className={classes.bodyUrlText}
                                    variant="body1"
                                    component="p"
                                  >
                                    {`${data.file_name} ${formatFileCount(
                                      data?.size
                                    )} MB`}
                                  </Typography>
                                </Box>
                                <Box className={classes.sizeSubLink} />
                              </>
                            ))
                          )}

                          {grpo_detail?.po?.qr_code && (
                            <Box
                              className={classes.informationHeaderLink}
                              onClick={() =>
                                handleSeeImage(grpo_detail?.po?.qr_code)
                              }
                            >
                              <ContentText
                                styling={classes.headerLink}
                                variant={'body1'}
                                content={`View PO`}
                              />
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                      <Grid md={12}>
                        {/* Other document content */}
                        <Box className={classes.sizeSubLink} />
                        <Typography
                          className={classes.uploadTitle}
                          variant="body1"
                          component="p"
                        >
                          Other Document
                        </Typography>
                        <Box className={classes.sizeTitle} />
                        {read_loading_third ? (
                          <CircleLoading />
                        ) : !showFileDataThird &&
                          fileDataThird.length < 1 &&
                          canUpload ? (
                          <>
                            <Box className={classes.btnUploadContent}>
                              <Box
                                className={classes.btnUpload}
                                onClick={() => setShowModalUpload(true)}
                              >
                                <Box>
                                  <img
                                    className={classes.bellImage}
                                    src={UnduhSecond}
                                    alt="image not found"
                                  />
                                </Box>
                                <Typography
                                  className={classes.btnUploadValue}
                                  variant="body1"
                                  component="p"
                                >
                                  Attach File
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        ) : (
                          <>
                          {fileDataThird.map((data, index) => (
                            <>
                              <Box className={classes.wrapperLink}>
                                <Box className={classes.wrapperLink}>
                                  <Box
                                    className={classes.wrapperLinkFile}
                                    onClick={() => handleSeeImage(data?.url)}
                                  >
                                    <Box>
                                      <img src={Paper} alt="image not found" />
                                    </Box>
                                    <Typography
                                      className={classes.bodyUrlText}
                                      variant="body1"
                                      component="p"
                                    >
                                      {`${data.file_name} `}
                                    </Typography>
                                    <Typography
                                      className={classes.textFileCount}
                                      variant="body1"
                                      component="p"
                                    >
                                      {`${formatFileCount(data?.size)} MB`}
                                    </Typography>
                                  </Box>
                                  {canDelete ? (
                                    <TrashDelete
                                      onPress={() => handleDelete(data?.id)}
                                    />
                                  ) : null}
                                </Box>
                                {index + 1 === fileDataThird.length &&
                                canUpload ? (
                                  <Box
                                    className={classes.btnAddMoreUpload}
                                    onClick={() => setShowModalUpload(true)}
                                  >
                                    <img
                                      style={{ marginRight: 8 }}
                                      src={CirclePlus}
                                      alt="image not found"
                                    />
                                    <Typography
                                      className={classes.bodyDataTextBlue}
                                    >
                                      Add More
                                    </Typography>
                                  </Box>
                                ) : null}
                              </Box>
                              <Box className={classes.sizeSubLink} />
                            </>
                          ))}
                          {isEmpty(fileDataThird) && (
                            <Typography
                              className={classes.AttachmentMessage}
                              variant="body1"
                              component="p"
                            >
                              No Attach File
                            </Typography>
                          )}
                          </>
                        )}
                        { get(grpo_detail, ['proforma_invoice']) &&
                        (<Box
                          className={classes.informationHeaderLink}
                          style={{ marginTop: '20px' }}
                          onClick={() =>
                            handleSeeImage(`/proforma-invoice-detail/proforma=${grpo_detail.proforma_invoice.id}`)
                          }
                        >
                          <ContentText
                            styling={classes.headerLink}
                            variant={'body1'}
                            content={`View Proforma`}
                          />
                        </Box>)}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Grid>
            {/* <Box className={classes.borderSide} /> */}
            <Grid md={4} sm={12} xs={12} style={{ backgroundColor: '#fff' }}>
              <Box className={classes.assignApprovalContent}>
                <Box className={classes.assignHeader}>
                  <Typography
                    className={classes.headerTitle}
                    variant="body1"
                    component="p"
                  >
                    Approve By
                  </Typography>
                </Box>

                <Box className={classes.filterActifityContent}>
                  <Box>
                    <Box className={classes.bodyContent}>
                      <Typography
                        className={classes.bodyTitle}
                        variant="body1"
                        component="p"
                      >
                        Prepared By
                      </Typography>
                    </Box>
                    {internal_all_IsLoading ? (
                      <Skeleton variant="text" width={'100%'} />
                    ) : (
                      <SelectFilter
                        masterData={internal_all_data}
                        data={rowApproval}
                        defaultValue={aprrovalOne}
                        onChange={handleChangeApprovalOne}
                        disabled={
                          grpo_detail?.status == 'need_confirm' ||
                          grpo_detail?.status == 'rejected'
                            ? false
                            : true
                        }
                      />
                    )}
                  </Box>
                </Box>

                <Box className={classes.filterActifityContent}>
                  <Box>
                    <Box className={classes.bodyContent}>
                      <Typography
                        className={classes.bodyTitle}
                        variant="body1"
                        component="p"
                      >
                        Reviewed By
                      </Typography>
                    </Box>
                    {internal_all_IsLoading ? (
                      <Skeleton variant="text" width={'100%'} />
                    ) : (
                      <SelectFilter
                        masterData={internal_all_data}
                        data={rowApproval}
                        defaultValue={aprrovalTwo}
                        onChange={handleChangeApprovalTwo}
                        disabled={
                          grpo_detail?.status == 'need_confirm' ||
                          grpo_detail?.status == 'rejected'
                            ? false
                            : true
                        }
                      />
                    )}
                  </Box>
                </Box>

                <Box className={classes.filterActifityContent}>
                  <Box>
                    <Box className={classes.bodyContent}>
                      <Typography
                        className={classes.bodyTitle}
                        variant="body1"
                        component="p"
                      >
                        Approved By
                      </Typography>
                    </Box>
                    {internal_all_IsLoading ? (
                      <Skeleton variant="text" width={'100%'} />
                    ) : (
                      <SelectFilter
                        masterData={internal_all_data}
                        data={rowApproval}
                        defaultValue={aprrovalThree}
                        onChange={handleChangeApprovalThree}
                        disabled={
                          grpo_detail?.status == 'need_confirm' ||
                          grpo_detail?.status == 'rejected'
                            ? false
                            : true
                        }
                      />
                    )}
                  </Box>
                </Box>
                {grpo_detail?.status === 'need_confirm' ? (
                  <Box
                    className={
                      assignment_loading
                        ? classes.BtnConfirmFalse
                        : classes.BtnConfirm
                    }
                    onClick={assignment_loading ? null : handleConfirmAssigment}
                  >
                    <Typography
                      className={classes.BtnConfirmText}
                      variant="body1"
                      component="p"
                    >
                      Confirm Assignment
                    </Typography>
                  </Box>
                ) : null}
              </Box>
            </Grid>
          </Grid>
          <Box className={classes.borderLine} />
          <Grid md={12}>
            {_renderButtonAction(grpo_detail?.status)}
            {/* <ButtonSubmit
          setShowModalConfirm={setShowModalConfirm}
          isComplete={true}
          text={'Cancel GRPO'}
        /> */}
            {/* <ButtonQrCode
          showModal={() => setShowModalQRCode(true)}
          handleCanclePR={() => setShowModalConfirm(true)}
        /> */}
            {/* <ButtonCancel handleCanclePR={() => setShowModalConfirm(true)} /> */}
          </Grid>
          <HistoryTableDetail
            showHistory={isStatusNeed}
            data={grpo_detail?.approval_transactions}
          />
        </>
      )}
    </>
  );
};

const useStyles = makeStyles({
  mt2 : {
    margin: '10px 0px',
  },
  formTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  borderLine: {
    borderBottom: '1px solid #E7E7E8',
  },
  formNumber: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#1B2030',
  },
  formStatus: {
    fontSize: 14,
    lineHeight: '26px',
    color: '#969BAB',
  },
  // styling status
  statusNeed: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#F12B2C',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  statusWaiting: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#FFB800',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  statusClose: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    background: '#969BAB',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  statusApprove: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    background: '#27AE60',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  wrapperInformation: {
    paddingTop: 27,
    paddingBottom: 59,
    backgroundColor: '#fff',
  },
  informationContent: {
    // marginTop: 27,
    paddingLeft: 20,
    paddingRight: 18.5,
    backgroundColor: '#fff',
    // marginBottom: 58,
    borderRight: '1px solid #E7E7E8',
  },
  informationHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  informationHeaderLink: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
  },
  headerText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  headerLink: {
    fontSize: 14,
    lineHeight: '26px',
    fontWeight: 'bold',
    color: '#127EEE',
    marginLeft: 10,
  },
  infromationBody: {
    marginTop: 18.5,
    marginLeft: -10,
    marginRight: -10,
  },
  wrapperBody: {
    paddingRight: 10,
    paddingLeft: 10,
  },
  bodyTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
    marginBottom: 12,
  },
  bodyData: {
    width: '100%',
    backgroundColor: '#EBEFF0',
    padding: '12px 20px',
    border: '1px solid #E7E7E8',
    borderRadius: 8,
  },
  wrapperBodyGrandTotal: {},
  infromationAttachment: {
    marginTop: 30,
  },
  AttachmentTitle: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    fontWeight: 'bold',
  },
  AttachmentNotif: {
    marginTop: 20,
    marginBottom: 20,
    padding: 10,
    borderRadius: 4,
    backgroundColor: '#FFE9E8',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  AttachmentMessage: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#F12B2C',
  },
  btnUploadContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  btnUploadText: {
    fontSize: 14,
    lineHeight: '26px',
    color: '#1B2030',
  },
  btnUpload: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: '#127EEE',
    paddingLeft: 19,
    paddingRight: 19,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 8,
  },
  btnUploadValue: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
    marginLeft: 13,
  },
  assignApprovalContent: {
    paddingTop: 26,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 26,
    backgroundColor: '#fff',
    // borderBottom: '1px solid #E7E7E8',
  },
  headerTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  filterActifityContent: {
    marginTop: 20,
  },
  // styling Form select
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 5,
    paddingBottom: 5,
    // maxWidth: 220,
    width: '100%',
    // minWidth: 160,
    maxHeight: 50,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      // marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#969BAB',
      paddingRight: 2,
      backgroundColor: '#F8FAFB',
    },
  },
  sizeBox: {
    marginTop: 20,
  },
  btnOption: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  wrapperMandatory: {
    paddingLeft: 20,
    paddingTop: 15,
    paddingRight: 30,
    paddingBottom: 15,
    border: '1px solid #E7E7E8',
    borderRadius: 8,
  },
  sizeBoxTwo: {
    marginTop: 30,
  },
  textTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
  },
  titleContent: {
    marginBottom: 12,
  },
  supportContent: {
    marginTop: 20,
    marginBottom: 12,
  },
  uploadTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
  },
  bodyUrlText: {
    marginLeft: 10,
    fontSize: 16,
    lineHeight: '21px',
    textDecorationLine: 'underline',
    color: '#1B2030',
  },
  wrapperLink: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  wrapperLinkFile: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    width: 250,
  },
  textFileCount: {
    marginLeft: 2,
    fontSize: 16,
    lineHeight: '21px',
    textDecorationLine: 'underline',
    color: '#1B2030',
  },
  sizeTitle: {
    marginTop: 21.5,
  },
  sizeSubLink: {
    marginTop: 31,
  },
  boxDepartment: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textTransform: 'ellipsis',
    // maxWidth: '90%',
    paddingRight: 20,
  },
  bodyDataText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
  },
  bodyDataTextBlue: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#127EEE',
    fontWeight: 'bold',
  },
  bodyDataSubText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    marginLeft: 4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  BtnConfirm: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 14.5,
    paddingBottom: 14.5,
    backgroundColor: '#1B2030',
    borderRadius: 8,
    maxWidth: 193,
    marginTop: 20,
    cursor: 'pointer',
  },
  BtnConfirmFalse: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 14.5,
    paddingBottom: 14.5,
    backgroundColor: '#969BAB',
    borderRadius: 8,
    maxWidth: 193,
    marginTop: 20,
    cursor: 'pointer',
  },
  BtnConfirmText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
  },
  wrapperStatusForm: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  formStatus: {
    fontSize: 14,
    lineHeight: '26px',
    color: '#969BAB',
  },
  btnAddMoreUpload: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: 15,
    cursor: 'pointer',
  },
});

export default GRPOFormApproval;
