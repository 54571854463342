// General
export const DASHBOARD_LOADING = 'DASHBOARD/DASHBOARD_LOADING';
export const DASHBOARD_MESSAGE = 'DASHBOARD/DASHBOARD_MESSAGE';

// get dashboard pr
export const PR_DASHBOARD_DATA = 'DASHBOARD/PR_DASHBOARD_DATA';

// get dashboard po
export const PO_DASHBOARD_DATA = 'DASHBOARD/PO_DASHBOARD_DATA';

// get dashboard grpo
export const GRPO_DASHBOARD_DATA = 'DASHBOARD/GRPO_DASHBOARD_DATA';

// get dashboard apinvoice
export const INVOICE_DASHBOARD_DATA = 'DASHBOARD/INVOICE_DASHBOARD_DATA';

// get dashboard apdp
export const APDP_DASHBOARD_DATA = 'DASHBOARD/APDP_DASHBOARD_DATA';

// get dashboard op
export const OP_DASHBOARD_DATA = 'DASHBOARD/OP_DASHBOARD_DATA';
