import React from 'react';
import { makeStyles, Box, Grid, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useRouter } from '../../../../utils/useRouter';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../../redux/UserManagement/actions';
import * as actions_2 from '../../../../redux/ClientVendor/actions';
import axios from 'axios';
import { phoneRegExp } from '../../../../utils/RegexFormula';
import { END_POINT } from '../../../../config/EndPoint';

// Components
import InputGroup from '../../../inputGroup';
import CustomizedSnackbars from '../../../PopupMessage';
import ModalSuccessCreateUser from '../../../ModalSuccessCreateUser';

// Svg
import { DownArrow } from '../../../../assets/Icons/svg/DownArrow';

// Icons
import DefaultAvatar from '../../../../assets/Images/defaultAvatar.png';
import Search from '../../../../assets/Icons/Search.png';

const schema = yup.object().shape({
  FullName: yup.string().required(),
  Username: yup.string().required(),
  // Email: yup
  //   .string()
  //   .email('Must be a valid email')
  //   .required('Email is required'),
  // Phone: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  // Password: yup.string().required(),
  // ConfirmPWD: yup.string().required(),
});

export const ExternalEdit = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispacth = useDispatch();
  const { id, category } = router.query;

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // handle input file
  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const [dataInput, setDataInput] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [username, setUsername] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [pwd, setPwd] = React.useState('');
  const watchAllFields = watch(); // when pass nothing as argument, you are watching everything
  const [status, setStatus] = React.useState(10);
  const [selectFile, setSelectFile] = React.useState();
  const [previewImage, setPreviewImage] = React.useState();
  const [isFilePicked, setIsFilePicked] = React.useState(false);
  const [avatarUrl, setAvatarUrl] = React.useState('');

  // Global Variable
  const { access_token } = useSelector((state) => state.auth);
  const { externalProfile, externalProfileLoading, externalMessage } =
    useSelector((state) => state.userManagement);
  const { data, searchData, searchIsLoading } = useSelector(
    (state) => state.clientVendor
  );

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      // console.log(value)
      setDataInput(value)
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = (data) => {
    // console.log(data);
    // Send API
    const { ConfirmPWD, Email, FullName, Password, Phone, Username } = data;
    // Send API
    // console.log(Phone.length);
    if (!vendor) {
      setType('error');
      setShow(true);
      setNotifText('Pelase Select Company');
    } else if (!selectFile) {
      setType('error');
      setShow(true);
      setNotifText('Avatar image Cannot Be null');
    } else {
      if (vendor.category === 'distributor') {
        // console.log('distibutor Create user');
        let SendData = {
          // section distibutor
          code: vendor.vendor_code ?? '-',
          address: vendor.address,
          distributor_group_code_id: vendor.group_code_id,
          fax: vendor.fax,
          client_id: vendor.id,
          // section main Field
          username: Username,
          password: Password,
          name: FullName,
          email: Email,
          file: selectFile,
          phone: Phone,
        };
        if (Phone.length < 1) delete SendData.phone;
        if (Password == '') delete SendData.password;
        console.log(SendData);
        dispacth(actions.editExternalUserDistributor(access_token, SendData));
      } else {
        // console.log('distibutor Create user');
        let SendData = {
          // section distibutor
          code: vendor.vendor_code ?? '-',
          address: vendor.address,
          supplier_group_code_id: vendor.group_code_id,
          fax: vendor.fax,
          client_id: vendor.id,
          // section main Field
          username: Username,
          password: Password,
          name: FullName,
          email: Email,
          file: selectFile,
          phone: Phone,
        };
        if (Phone.length < 1) delete SendData.phone;
        if (Password == '') delete SendData.password;
        console.log(SendData);
        dispacth(actions.editExternalUserSupplier(access_token, SendData));
      }
    }
  };

  // for handle search input vendor

  const [deleteHover, setDeleteHover] = React.useState(false);
  const [isVendorFocus, setIsVendorFocus] = React.useState(false);
  const [showVendor, setShowVendor] = React.useState(false);

  const handleChooseVandor = (data, index) => {
    selectVendor(data, index);
    setIsVendorFocus(false);
    setShowVendor(false);
  };

  const [vendor, setVendor] = React.useState(null);
  // const [isVendorFocus, setIsVendorFocus] = React.useState(false);
  // const [showVendor, setShowVendor] = React.useState(false);
  const [vendorData, setVendorData] = React.useState(null);
  const [searchText, setSearchText] = React.useState('');

  const selectVendor = (data, index) => {
    setVendor(data);
    // setIsVendorFocus(false);
    // setShowVendor(false);
    setSearchText('');
    setVendorData(data?.client?.data);
  };

  React.useEffect(() => {
    if (searchText !== '') {
      // let TempArr = [];
      // vendorData?.map((vendor, index) => {
      //   let tempName = vendor.name.toLowerCase();
      //   if (tempName.includes(searchText.toLocaleLowerCase())) {
      //     TempArr.push(vendor);
      //   }
      // });
      // setVendorData(TempArr);
      let data = { name: searchText };
      dispacth(actions_2.searchClientVendor(access_token, data));
    } else {
      setVendorData(data?.client?.data);
      dispacth(actions_2.setBlankSearchData());
    }
  }, [searchText]);

  React.useEffect(() => {
    dispacth(actions_2.getClientVendor(access_token));
  }, []);

  React.useEffect(() => {
    setVendorData(data?.client?.data);
  }, [data]);

  React.useEffect(() => {
    setVendorData(searchData?.client);
  }, [searchData]);

  // end handle serach vendor

  // handle message
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };

  const handleCancleBtn = () => {
    router.push('/user-management');
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setSelectFile(fileUploaded);
    setPreviewImage(fileUploaded);
    setIsFilePicked(true);
  };

  React.useEffect(() => {
    if (category === 'distributor') {
      dispacth(actions.getProfileExternalDistributor(access_token, id));
    } else {
      dispacth(actions.getProfileExternalSupplier(access_token, id));
    }
  }, []);

  React.useEffect(() => {
    if (category === 'distributor') {
      setValue('FullName', externalProfile?.distributors?.name);
      setValue('Phone', externalProfile?.distributors?.user?.phone);
      setValue('Username', externalProfile?.distributors?.user?.username);
      setValue('Email', externalProfile?.distributors?.user?.email);

      setVendor(externalProfile?.distributors?.client);
      setAvatarUrl(externalProfile?.distributors?.user?.avatar_base ?? '');
      setStatus(externalProfile?.distributors?.user?.status);
    } else {
      setValue('FullName', externalProfile?.suppliers?.name);
      setValue('Phone', externalProfile?.suppliers?.user?.phone);
      setValue('Username', externalProfile?.suppliers?.user?.username);
      setValue('Email', externalProfile?.suppliers?.user?.email);

      setVendor(externalProfile?.suppliers?.client);
      setAvatarUrl(externalProfile?.suppliers?.user?.avatar_base ?? '');
      setStatus(externalProfile?.suppliers?.user?.status);
    }
  }, [externalProfile]);

  React.useEffect(() => {
    if (
      externalMessage == 'Save Distributors Success' ||
      externalMessage == 'Save Suppliers Success'
    ) {
      // setEmail(externalData?.user?.email);
      // setUsername(externalData?.user?.username);
      router.push('/user-management');
    } else if (externalMessage) {
      setType('error');
      setShow(true);
      setNotifText(externalMessage);
    } else {
      return;
    }
    dispacth(actions.setBlankExernalMessage());
  }, [externalMessage]);

  return (
    <>
      <CustomizedSnackbars
        show={show}
        handleClose={handleClose}
        message={notifText}
        type={type}
      />
      <Grid container md={12} style={{ marginBottom: 120 }}>
        {/* <ModalSuccessCreateUser open={showModal} close={handleCloseModal} /> */}
        <Grid md={12} style={{ maxWidth: 1180 }}>
          <Grid md={12} className={classes.wrapperHeader}>
            <Typography
              variant="body1"
              component="p"
              className={classes.headerText}
            >
              User Detail
            </Typography>
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid md={12} container>
              <Grid md={8}>
                <Grid md={12} className={classes.wrapperBody}>
                  <Grid
                    md={12}
                    container
                    style={{ marginTop: 20, paddingBottom: 26 }}
                  >
                    <Grid md={6} style={{ paddingRight: 10 }}>
                      <InputGroup
                        label="FullName"
                        placeholder="Enter Full Name"
                        register={register}
                        required
                        type="text"
                        // error={errors.Password ? 'error' : null}
                      />
                      <p className={classes.errorText}>{errors.NIK?.message}</p>
                    </Grid>
                    <Grid md={6} style={{ paddingLeft: 10 }}>
                      <InputGroup
                        label="Phone"
                        placeholder="Enter Phone"
                        register={register}
                        required
                        type="text"
                        error={errors.Username ? 'error' : null}
                      />
                      <p className={classes.errorText}>
                        {errors.Phone?.message}
                      </p>
                    </Grid>
                  </Grid>
                  <Box className={classes.boderDividerTwo} />
                  <Grid md={12} container style={{ paddingTop: 26 }}>
                    <Grid md={6} style={{ paddingRight: 10 }}>
                      <InputGroup
                        label="Username"
                        placeholder="Enter Username"
                        register={register}
                        required
                        type="text"
                        error={errors.Username ? 'error' : null}
                      />
                      <p className={classes.errorText}>
                        {errors.Username?.message}
                      </p>
                    </Grid>
                    <Grid md={6} style={{ paddingLeft: 10 }}>
                      <InputGroup
                        label="Email"
                        placeholder="Enter Email"
                        register={register}
                        required
                        type="text"
                        disable={true}
                        error={errors.Username ? 'error' : null}
                      />
                      <p className={classes.errorText}>
                        {errors.Email?.message}
                      </p>
                    </Grid>
                  </Grid>

                  <Grid md={12} container style={{ marginTop: 20 }}>
                    <Grid md={6} style={{ paddingRight: 10 }}>
                      <InputGroup
                        label="Password"
                        placeholder="Enter Password"
                        register={register}
                        required
                        type="password"
                        error={errors.Password ? 'error' : null}
                      />
                      <p className={classes.errorText}>
                        {errors.Password?.message}
                      </p>
                    </Grid>
                    <Grid md={6} style={{ paddingLeft: 10 }}>
                      <InputGroup
                        label="ConfirmPWD"
                        placeholder="Enter Password"
                        register={register}
                        required
                        type="password"
                        error={errors.Password ? 'error' : null}
                      />
                      <p className={classes.errorText}>
                        {errors.ConfirmPWD?.message}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
                <Box className={classes.boderDivider} />
                <Grid md={12} className={classes.wrapperCategory} container>
                  <Grid md={6} style={{ paddingRight: 10 }}>
                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.titleLabel}
                    >
                      Company
                    </Typography>
                    <Box
                      className={classes.filterVendorContent}
                      // onBlur={() => setIsVendorFocus(false)}
                    >
                      <Box className={classes.iconDownArrow}>
                        <DownArrow />
                      </Box>
                      <input
                        className={classes.inputVendor}
                        // value={inputPayment.bank_id}
                        value={vendor?.name}
                        name="vendor"
                        autoComplete={`new-`}
                        onFocus={() => setIsVendorFocus(true)}
                        placeholder={vendor ? '' : 'Select'}
                        onBlur={() => setIsVendorFocus(false)}
                      />
                      {/* render for select vendor name */}
                      {isVendorFocus || showVendor ? (
                        <Box
                          onBlur={() => setIsVendorFocus(true)}
                          className={classes.contentDataVendor}
                          onMouseOver={() => setShowVendor(true)}
                          onMouseOut={() => setShowVendor(false)}
                        >
                          <Box className={classes.contentVendorSearch}>
                            <input
                              className={classes.searchVendor}
                              autoComplete="on"
                              onFocus={() => setIsVendorFocus(true)}
                              value={searchText}
                              onChange={(e) => setSearchText(e.target.value)}
                              placeholder="Search"
                              // onBlur={() => setIsVendorFocus(false)}
                            />
                            <img
                              className={classes.serachIcon}
                              src={Search}
                              alt="image not found"
                            />
                          </Box>
                          <Box className={classes.listVendorName}>
                            {searchIsLoading ? (
                              <Typography className={classes.TitleVendor}>
                                Loading Get Data
                              </Typography>
                            ) : (
                              vendorData?.map((data, vendorIndex) => (
                                <Box
                                  key={vendorIndex}
                                  onClick={() =>
                                    handleChooseVandor(data, vendorIndex)
                                  }
                                  className={classes.vendorBox}
                                >
                                  <Typography className={classes.TitleVendor}>
                                    {`${data.name}`}
                                  </Typography>
                                </Box>
                              )) ?? (
                                <Typography className={classes.TitleVendor}>
                                  Company Not Found
                                </Typography>
                              )
                            )}
                          </Box>
                        </Box>
                      ) : null}
                    </Box>
                  </Grid>
                  <Grid md={3} style={{ paddingRight: 10 }}>
                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.titleLabel}
                    >
                      Category
                    </Typography>

                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.titleBoxLabel}
                    >
                      {vendor?.category}
                    </Typography>
                  </Grid>
                  <Grid md={3} style={{ paddingLeft: 10 }}>
                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.titleLabel}
                    >
                      Programs
                    </Typography>

                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.titleBoxLabel}
                    >
                      {/* Category */}
                    </Typography>
                  </Grid>
                  {vendor ? (
                    <Grid md={12} className={classes.wrapperVendorInformation}>
                      <Typography className={classes.vendorName}>
                        {vendor?.name}
                      </Typography>
                      <Typography className={classes.vendorAddress}>
                        {vendor?.address}
                      </Typography>
                      <Box className={classes.wrapperContact}>
                        <Grid container>
                          <Grid md={3} className={classes.contactTitle}>
                            Phone
                          </Grid>
                          <Grid md={9} className={classes.contactDesc}>
                            : {vendor?.phone}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid md={3} className={classes.contactTitle}>
                            Fax
                          </Grid>
                          <Grid md={9} className={classes.contactDesc}>
                            : {vendor?.fax}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid md={3} className={classes.contactTitle}>
                            CP
                          </Grid>
                          <Grid md={9} className={classes.contactDesc}>
                            : -
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid md={3} className={classes.contactTitle}>
                            Npwp
                          </Grid>
                          <Grid md={9} className={classes.contactDesc}>
                            : {vendor?.npwp}
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  ) : null}
                </Grid>

                <Box className={classes.boderDivider} />
                <Grid md={12} className={classes.wrapperStatusContent}>
                  <Grid md={12} container style={{ marginTop: 6 }}>
                    <Grid md={6} style={{ paddingRight: 10 }} container>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.titleLabel}
                      >
                        Status
                      </Typography>
                      <FormControl className={classes.formControl}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={status}
                          onChange={handleChangeStatus}
                          IconComponent={() => <DownArrow />}
                        >
                          <MenuItem value={'active'}>Active</MenuItem>
                          <MenuItem value={'nonactive'}>Inactive</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid md={3} style={{ paddingLeft: 11 }}>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.titleLabel}
                      >
                        Is Logged In?
                      </Typography>
                      <Box>
                        <Typography
                          variant="body1"
                          component="p"
                          className={classes.descTextStatus}
                        >
                          Yes
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid md={3}>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.titleLabel}
                      >
                        Last Login
                      </Typography>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.descText}
                      >
                        12/09/2021 09:00AM
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid md={4} className={classes.wrapperProfile}>
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.profileText}
                >
                  Avatar
                </Typography>
                <Box className={classes.avatarContent}>
                  {isFilePicked && previewImage ? (
                    <img
                      src={URL.createObjectURL(previewImage)}
                      className="imageAvatar"
                      alt="image not found"
                      style={{ width: '100%', height: 'auto' }}
                    />
                  ) : avatarUrl === '' ? (
                    <>
                      <img
                        src={DefaultAvatar}
                        className="imageAvatar"
                        alt="image not found"
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </>
                  ) : (
                    <img
                      src={`data:image/jpeg;base64,${avatarUrl}`}
                      className="imageAvatar"
                      alt="image not found"
                      style={{ width: '100%', height: 'auto', maxHeight: 160 }}
                    />
                  )}
                </Box>
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.textCurrent}
                >
                  Current File :
                </Typography>
                <Box className={classes.btnUploadContent}>
                  <Box onClick={handleClick} className={classes.btnUpload}>
                    Choose file
                  </Box>
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    id="img"
                    name="img"
                    accept="image/*"
                    onChange={handleChange}
                    style={{ display: 'none' }}
                  />
                  {isFilePicked ? (
                    <>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.fileText}
                      >
                        {selectFile.name}
                      </Typography>
                    </>
                  ) : (
                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.fileText}
                    >
                      No file Choosen
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Grid md={12} className={classes.wrapperFotter}>
              <input
                className={
                  dataInput?.Username && dataInput?.Email
                    ? classes.submitBtn
                    : classes.submitBtnFalse
                }
                type="submit"
                value="Save Changes"
              />
              {dataInput?.Username && dataInput?.Email ? (
                <Box className={classes.cancelBtn} onClick={handleCancleBtn}>
                  <Typography
                    variant="body1"
                    component="p"
                    className={classes.cancleText}
                  >
                    Cancel
                  </Typography>
                </Box>
              ) : null}
            </Grid>
          </form>
        </Grid>
        <Grid md={4}>{/* Blank Space */}</Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  wrapperHeader: {
    padding: 20,
    backgroundColor: '#fff',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottom: '1px solid #E7E7E8',
  },
  headerText: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  wrapperBody: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingBottom: 20,
    paddingRight: 19,
    backgroundColor: '#fff',
  },
  errorText: {
    fontSize: 14,
    color: 'red',
  },
  wrapperFotter: {
    padding: 20,
    backgroundColor: '#fff',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderTop: '1px solid #E7E7E8',
  },
  submitBtn: {
    width: 193,
    height: 50,
    borderRadius: 8,
    backgroundColor: '#1B2030',
    color: '#fff',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  submitBtnFalse: {
    width: 193,
    height: 50,
    borderRadius: 8,
    backgroundColor: '#969BAB',
    color: '#fff',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  cancelBtn: {
    width: 193,
    height: 50,
    marginLeft: 2,
    cursor: 'pointer',
    borderRadius: 8,
    color: '#1B2030',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  titleLabel: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
    fontWeight: 'bold',
  },
  wrapperInputDisabel: {
    width: '100%',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 14.5,
    paddingBottom: 14.5,
    backgroundColor: '#EBEFF0',
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    maxHeight: 50,
    marginTop: 12,
  },
  textNumberID: {
    width: '100%',
  },
  boderDivider: {
    marginLeft: 20,
    marginRight: 20,
    borderTop: '1px solid #E7E7E8',
  },
  boderDividerTwo: {
    borderTop: '1px solid #E7E7E8',
  },
  wrapperCategory: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 26,
    paddingBottom: 20,
    backgroundColor: '#fff',
  },
  // Select styling
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 5,
    paddingBottom: 5,
    // maxWidth: 220,
    width: '100%',
    // minWidth: 160,
    maxHeight: 50,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#969BAB',
      paddingRight: 2,
      background: 'none',
    },
  },
  wrapperStatusContent: {
    padding: 20,
    backgroundColor: '#fff',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  borderCustom: {
    marginLeft: 20,
    marginRight: 20,
    borderBottom: '1px solid #E7E7E8',
  },
  descText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
    alignSelf: 'center',
    marginTop: 26,
  },
  descTextStatus: {
    fontSize: 12,
    lineHeight: '26px',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: '#27AE60',
    borderRadius: 100,
    width: 47,
    height: 26,
    textAlign: 'center',
    marginTop: 24,
  },
  titleBoxLabel: {
    marginTop: 12,
    width: '100%',
    height: 50,
    background: '#EBEFF0',
    borderRadius: 8,
    border: '1px solid #E7E7E8',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: 20,
  },
  // styling avatar content
  wrapperProfile: {
    backgroundColor: '#fff',
    paddingTop: 20,
    paddingBottom: 20,
  },
  profileText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
    fontWeight: 'bold',
  },
  avatarContent: {
    marginTop: 12,
    width: 150,
    height: 150,
    borderRadius: 8,
  },
  btnUploadContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 20,
  },
  btnUpload: {
    backgroundColor: '#127EEE',
    color: '#fff',
    width: 102,
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  fileText: {
    marginLeft: 20,
    fontSize: 14,
    lineHeight: '26px',
    color: '#1B2030',
  },
  textCurrent: {
    marginTop: 20,
    marginBottom: 11.5,
    fontSize: 14,
    lineHeight: '26px',
    color: '#1B2030',
  },
  wrapperCompanyDetail: {
    backgroundColor: '#fff',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 26,
    paddingBottom: 26,
  },
  contentCompanyDetail: {
    paddingTop: 27,
    paddingLeft: 25,
    paddingBottom: 24,
    backgroundColor: '#F8FAFB',
  },
  compannyAddress: {
    marginTop: 17,
  },
  titleDetailLabel: {
    fontSize: 16,
    lineHeight: '30px',
    color: '#000000',
    fontWeight: 'bold',
  },
  titleSubLabel: {
    fontSize: 16,
    lineHeight: '30px',
    color: '#000000',
  },
  // styling vendor search
  inputVendor: {
    marginTop: 12,
    width: '99%',
    height: 50,
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    position: 'relative',
    zIndex: 99,
    paddingLeft: 15,
    '&.focus-visible': {
      border: '1px solid #E7E7E8',
      outline: 'none',
    },
  },
  filterVendorContent: {
    // paddingLeft: 10,
    // paddingRight: 5,
    width: '94%',
    position: 'relative',
  },
  exportContent: {
    marginRight: 24,
    paddingRight: 20,
    width: '100%',
    // maxWidth: 164,
    // minWidth: 154,
    position: 'absolute',
    right: 0,
    zIndex: 90,
    backgroundColor: '#fff',
  },
  filterBlankArea: {
    minWidth: 150,
  },
  contentDataVendor: {
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid #E7E7E8',
    minHeight: 202,
    maxHeight: 202,
    position: 'absolute',
    // maxWidth: 660,
    marginTop: 4,
    borderRadius: 8,
    padding: 6,
    zIndex: 99999,
  },
  contentVendorSearch: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    width: '100%',
    border: '1px solid #E7E7E8',
    paddingRight: 15,
    borderRadius: 8,
  },
  searchVendor: {
    flex: 1,
    height: 40,
    border: 'none',
    outline: 'none',
    paddingLeft: 12,
  },
  listVendorName: {
    marginTop: 8,
    height: `calc(202px - 56px)`,
    maxHeight: `calc(202px - 56px)`,
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.4em',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
      borderRadius: 4,
    },
  },
  vendorBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: 46,
  },
  TitleVendor: {
    marginTop: 2,
    marginBottom: 2,
    fontSize: 16,
    marginLeft: 10,
    lineHeight: '21px',
    color: '#000000',
    cursor: 'pointer',
  },
  iconDownArrow: {
    position: 'absolute',
    paddingTop: 20,
    right: 0,
    paddingRight: 20,
    zIndex: 100,
    marginTop: 12,
  },
  vendorBoxChosse: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 50,
    paddingLeft: 20,
    zIndex: 100,
  },
  wrapperVendorInformation: {
    marginTop: 26,
    paddingTop: 27,
    paddingBottom: 24,
    paddingLeft: 25,
    backgroundColor: '#F8FAFB',
  },
  vendorName: {
    fontSize: 16,
    lineHeight: '30px',
    fontWeight: 'bold',
    color: '#000000',
  },
  vendorAddress: {
    fontSize: 16,
    lineHeight: '30px',
    color: '#000000',
  },
  wrapperContact: {
    marginTop: 17,
  },
  contactTitle: {
    fontSize: 16,
    lineHeight: '30px',
    fontWeight: 'bold',
    color: '#000000',
  },
  contactDesc: {
    fontSize: 16,
    lineHeight: '30px',
    color: '#000000',
  },
});

export default ExternalEdit;
