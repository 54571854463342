import React from 'react';
import { makeStyles, Box, Grid, Typography, Checkbox } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// Components
import { TabelLoading, NoData } from '../../Loading/TableLoading';
import ListItem from './subComponents/ListItem';
import DesktopPagination from '../../Pagination/DesktopPagination';

// Icons
import EyeBlue from '../../../assets/Icons/eyeBlue.png';
import PeopleApprove from '../../../assets/Icons/peopleApprove.png';
import PeopleDisable from '../../../assets/Icons/peopleDisable.png';
import PeopleReject from '../../../assets/Icons/peopleReject.png';

// Svg
import { DownArrow } from '../../../assets/Icons/svg/DownArrow';

const ApprovalDesktop = ({
  isLoading,
  handleChange,
  handleChangeEntity,
  handleChangeDepartement,
  entityFilter,
  entity_data,
  departementFilter,
  department_data,
  data,
  setShowModalMessageReject,
  setShowModalMessageApprove,
  setShowModal,
  handleShowDetail,
  userData,
  numberSelect,
  page,
  pagination,
  fromRow,
  toRow,
  TotalRow,
  handleChangePagination,
  isSelectAll,
  isEnableSelectAll,
  handleChangeLimit,
  limit,
}) => {
  const classes = useStyles();

  const _renderStatusApprove = (approve, reject) => {
    if (approve == 1 && reject == 0) {
      return <img src={PeopleApprove} alt="image not found" />;
    } else if (approve == 0 && reject == 1) {
      return <img src={PeopleReject} alt="image not found" />;
    } else {
      return <img src={PeopleDisable} alt="image not found" />;
    }
  };

  return (
    <>
      <Box className={classes.optionsMenu}>
        <Grid
          md={12}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid md={7} container>
            <Grid md={5} className={classes.checkMenu} item>
              <Checkbox
                className={classes.checkContent}
                color="secondary"
                name="allSelect"
                disabled={
                  !isEnableSelectAll || userData?.user?.role[0] === 'superadmin'
                    ? true
                    : false
                }
                checked={isSelectAll}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'checkbox with default color' }}
              />
              <Typography
                className={classes.titleTable}
                variant="body1"
                component="p"
              >
                Select All
              </Typography>
              <Typography
                className={classes.titleSort}
                variant="body1"
                component="p"
              >
                Sort By
              </Typography>
              <Box className={classes.sizeSelect} />
            </Grid>
            <Grid md={3} className={classes.selectContainer} item>
              <FormControl className={classes.formControl}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={entityFilter}
                  onChange={handleChangeEntity}
                  IconComponent={() => <DownArrow />}
                >
                  <MenuItem value={0}>All Entity</MenuItem>
                  {entity_data?.map((ent, index) => (
                    <MenuItem key={index} value={ent.id}>
                      <Typography
                        className={classes.bodyDataText}
                        variant="body1"
                        component="p"
                      >
                        {ent.code}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid md={3} className={classes.selectContainer} item>
              <FormControl className={classes.formControl}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={departementFilter}
                  onChange={handleChangeDepartement}
                  IconComponent={() => <DownArrow />}
                >
                  <MenuItem value={0}>All Department</MenuItem>
                  {department_data?.map((dep, index) => (
                    <MenuItem key={index} value={dep.id}>
                      <Box className={classes.boxDepartment}>
                        <Typography
                          className={classes.bodyDataText}
                          variant="body1"
                          component="p"
                        >
                          {dep?.code}
                        </Typography>
                        <Typography
                          className={classes.bodyDataSubText}
                          variant="body1"
                          component="p"
                        >
                          {`- ${dep?.name}`}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid md={5} className={classes.optionContent} item>
            <Typography
              className={classes.countCheckText}
              variant="body1"
              component="p"
            >
              {`${numberSelect ?? '0'} items selected`}
            </Typography>
            <Box
              className={numberSelect > 0 ? classes.btnDanger : classes.btn}
              onClick={
                numberSelect > 0 ? () => setShowModalMessageReject(true) : null
              }
            >
              <Typography
                className={classes.btnText}
                variant="body1"
                component="p"
              >
                Reject
              </Typography>
            </Box>
            <Box
              className={numberSelect > 0 ? classes.btnApprove : classes.btn}
              onClick={
                numberSelect > 0 ? () => setShowModalMessageApprove(true) : null
              }
            >
              <Typography
                className={classes.btnText}
                variant="body1"
                component="p"
              >
                Approve
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {isLoading ? (
        <TabelLoading />
      ) : data?.length > 0 ? (
        data?.map((item, index) => (
          <ListItem
            EyeBlue={EyeBlue}
            item={item}
            index={index}
            handleChange={handleChange}
            _renderStatusApprove={_renderStatusApprove}
            handleShowDetail={handleShowDetail}
            userData={userData}
          />
        ))
      ) : (
        <NoData />
      )}
      {isLoading ? null : data?.length > 0 ? (
        <DesktopPagination
          toRow={toRow}
          fromRow={fromRow}
          TotalRow={TotalRow}
          page={page}
          handleChangePagination={handleChangePagination}
          limit={limit}
          pagination={pagination}
          handleChangeLimit={handleChangeLimit}
        />
      ) : null}
    </>
  );
};

const useStyles = makeStyles({
  optionsMenu: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderBottom: '1px solid #E7E7E8',
  },
  checkMenu: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 11,
    paddingBottom: 20,
    paddingTop: 11,
  },
  checkContent: {
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#2B3595',
    },
  },
  titleTable: {
    fontSize: 16,
    lineHeight: '30px',
    color: '#000000',
  },
  titleSort: {
    fontSize: 16,
    lineHeight: '30px',
    color: ' #969BAB',
    paddingLeft: 10,
    borderLeft: '1px solid #E7E7E8',
    marginLeft: 10,
  },
  countCheckText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
  },
  optionContent: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    // paddingLeft: 11,
    paddingBottom: 20,
    paddingTop: 11,
    paddingRight: 20,
  },
  btnDanger: {
    cursor: 'pointer',
    backgroundColor: '#F12B2C',
    minWidth: 120,
    maxWidth: 154,
    paddingTop: 9.5,
    paddingBottom: 9.5,
    borderRadius: 8,
    marginLeft: 10,
  },
  btnApprove: {
    cursor: 'pointer',
    backgroundColor: '#127EEE',
    minWidth: 120,
    maxWidth: 154,
    paddingTop: 9.5,
    paddingBottom: 9.5,
    borderRadius: 8,
    marginLeft: 10,
  },
  btn: {
    backgroundColor: '#969BAB',
    minWidth: 120,
    maxWidth: 154,
    paddingTop: 9.5,
    paddingBottom: 9.5,
    borderRadius: 8,
    marginLeft: 10,
  },
  btnText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  // Styling Item
  wrapperItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxHeight: 89,
    backgroundColor: '#fff',
  },
  Approved: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxHeight: 89,
    border: '2px solid #27AE60',
  },
  wrapperCheckbox: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 12,
    paddingBottom: 12,
  },
  wrapperDetail: {
    flex: 1,
    paddingRight: 20,
    paddingTop: 16,
    paddingBottom: 18,
  },
  labelText: {
    fontSize: 14,
    lineHeight: '26px',
    // letterSpacing: '0.01em',
    color: '#969BAB',
  },
  lastItem: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  valueTextDesc: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
    paddingRight: 20,
  },
  valueText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
    marginRight: 10,
  },
  statusContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  textRightContent: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  btnDetail: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
  },
  btnDetailText: {
    color: '#127EEE',
    marginLeft: 8,
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  // Pagination styling
  descTable: {
    paddingTop: 11.5,
    paddingBottom: 11.5,
  },
  descTableText: {
    color: '#969BAB',
    fontSize: 16,
    lineHeight: '21px',
  },
  paginationContent: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pagination: {
    borderRadius: 0,
    '&.MuiPagination-root .MuiPagination-ul .MuiPaginationItem-root': {
      margin: 0,
    },
    borderRadius: 0,
    '&.MuiPagination-root .MuiPagination-ul .MuiPaginationItem-rounded': {
      borderRadius: 0,
    },
    '&.MuiPagination-root .MuiPagination-ul .MuiButtonBase-root': {
      backgroundColor: '#fff',
      color: '#969BAB',
    },
    '&.MuiPagination-root .MuiPagination-ul .MuiButtonBase-root.Mui-selected': {
      backgroundColor: '#1B2030',
      color: '#fff',
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-child(1)': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-child(1) button': {
      color: '#1B2030',
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-last-child(1)': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-last-child(1) button': {
      color: '#1B2030',
    },
  },
  // styling Form select
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 5,
    paddingBottom: 5,
    // maxWidth: 220,
    width: '100%',
    // minWidth: 160,
    maxHeight: 40,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      // marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#969BAB',
      paddingRight: 2,
      background: 'none',
    },
  },
  selectContainer: {
    paddingTop: 11,
    paddingBottom: 11,
    maxHeight: 50,
    paddingLeft: 4,
    paddingRight: 4,
  },
  // styling menu select
  boxDepartment: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  bodyDataSubText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    marginLeft: 4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export default ApprovalDesktop;
