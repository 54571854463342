import React from 'react';
import { makeStyles } from '@material-ui/core';

// Component
import LayoutDashboard from '../../componentsExternal/LayoutDashboard';
import ProformaTableDataExternal from '../../componentsExternal/ProformaTableData';
const InvoiceExternal = () => {
  const classes = useStyles();

  return (
    <LayoutDashboard page="Invoice">
      <div className={classes.pageContainer}>
        <ProformaTableDataExternal />
      </div>
    </LayoutDashboard>
  );
};

const useStyles = makeStyles({
  pageContainer: {
    marginBottom: 30,
    paddingTop: 97 - 24,
    minHeight: `calc(92vh - 63px)`,
  },
  sizebox: {
    marginTop: 20,
  },
});

export default InvoiceExternal;
