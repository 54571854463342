import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import request from '../../utils/request';

// Component
import InputGroup from '../inputGroup';
import MessageAuth from '../MessageAuth';
import CustomizedSnackbars from '../PopupMessage';

const schema = yup.object().shape({
  Password: yup.string().required(),
  Confirm: yup.string().required(),
});

const ResetForm = ({ email, token }) => {
  const classes = useStyles();

  const [showMessage, setShowMassege] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
    setNotifText('');
  };

  const onSubmit = (data) => {
    if (data.Password === data.Confirm) {
      request({
        method: 'post',
        url: 'auth/reset-password',
        data: {
          email,
          password: data.Password,
          password_confirmation: data.Password,
          token,
        },
      })
        .then((res) => {
          console.log('----- try reset password -------');
          setShowMassege(true);
        })
        .catch((err) => {
          setType('error');
          setShow(true);
          setNotifText(err.message);
        });
    } else {
      setType('error');
      setShow(true);
      setNotifText('Your Password Must Macth');
    }
    // alert(
    //   JSON.stringify({
    //     Password: data.Password,
    //     Confirm: data.Confirm,
    //     token,
    //     email,
    //   })
    // );
  };

  return (
    <Box className={classes.wrapperForm}>
      <Grid container direction="column">
        {!showMessage ? (
          <>
            <Typography
              className={classes.subTitle}
              variant="body1"
              component="p"
            >
              Reset Password
            </Typography>
            <Box className={classes.inputBoxes} />
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box className={classes.sizeBox} />
              <InputGroup
                label="Password"
                placeholder="Enter new password"
                register={register}
                required
                type="password"
                error={errors.Password ? 'error' : null}
              />
              <p className={classes.errorText}>{errors.Password?.message}</p>
              <Box className={classes.sizeInput} />
              <InputGroup
                label="Confirm"
                placeholder="Your Password"
                register={register}
                required
                type="password"
                error={errors.Password ? 'error' : null}
              />
              <p className={classes.errorText}>{errors.Confirm?.message}</p>
              <input
                className={classes.submitBtn}
                type="submit"
                value="Submit"
              />
            </form>
            {/* <Box className={classes.wrapperLink}>
              <Typography
                className={classes.checkText}
                variant="body1"
                component="p"
              >
                Create an Account?
              </Typography>
              <a className={classes.linkText} href="#">
                Sign Up
              </a>
            </Box> */}
          </>
        ) : (
          <MessageAuth reset={true} />
        )}
        <CustomizedSnackbars
          show={show}
          handleClose={handleClose}
          message={notifText}
          type={type}
        />
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles({
  wrapperForm: {
    width: 359,
    backgroundColor: '#fff',
    marginTop: 49,
    paddingTop: 31,
    paddingBottom: 31,
    paddingLeft: 20,
    paddingRight: 20,
    border: '1px solid #E7E7E8',
    borderRadius: 16,
  },
  titlePage: {
    fontWeight: 'bold',
    color: '#969BAB',
    fontSize: 16,
    lineHeight: '30px',
    // fontFamily: 'Nunito Sans, sans-serif',
    textAlign: 'center',
  },
  subTitle: {
    fontWeight: 'bold',
    color: '#1B2030',
    fontSize: 21,
    lineHeight: '32px',
    textAlign: 'center',
  },
  sizeBox: {
    marginTop: 18,
  },
  sizeInput: {
    marginTop: 12,
  },
  inputBoxes: {
    marginTop: 38,
  },
  optionInput: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  optionCheck: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  checkContent: {
    marginLeft: -10,
  },
  checkText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
  },
  forgotText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
    textDecorationLine: 'underline',
    fontWeight: 'bold',
  },
  submitBtn: {
    width: '100%',
    height: 50,
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    backgroundColor: '#1B2030',
    borderRadius: 8,
    borderWidth: 0,
    marginTop: 20,
  },
  wrapperLink: {
    marginTop: 38,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  linkText: {
    marginLeft: 10,
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
    textDecorationLine: 'underline',
    fontWeight: 'bold',
  },
  errorText: {
    fontSize: 14,
    color: 'red',
  },
});

export default ResetForm;
