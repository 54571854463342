import React from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  Box,
  TextareaAutosize,
} from '@material-ui/core';

import DatePickerSingle from '../../DatePickerSingle';
import DateIcon from '../../../assets/Icons/date.png';
import { formatRupiah } from 'src/utils/formatter';

function preventKeyDown(e){
  const keyCode = [69]

  if (keyCode.includes(e.which)) {
    e.preventDefault();
  }
}

const Information = (props) => {
  const {
    isDetail,
    setStartPeriode,
    setEndPeriode,
    subject,
    amount,
    handleOnchangeSubject,
    handleOnchangeAmount,
    handleOnchangeDesc,
    description,
    errorSubject,
    errorAmount,
    errorStartPeriode,
    errorEndPeriode,
    errorDescription,
    valSubject,
    valAmount,
    valStartPeriode,
    valEndPeriode,
    valDescription,
  } = props;
  const classes = useStyles();

  return (
    <>
      <Grid md={12} className={classes.wrapperInformation}>
        <Typography className={classes.titleText}>CAR Information</Typography>
        <Grid md={12} container className={classes.wrapperInput}>
          <Grid md={6} item className={classes.inputLeft}>
            <Typography className={classes.label}>
              Subject or Activity
            </Typography>
            {isDetail ? (
              <Box className={classes.bodyData}>
                <Typography
                  className={classes.bodyDataText}
                  variant="body1"
                  component="p"
                >
                  {valSubject}
                </Typography>
              </Box>
            ) : (
              <>
                <input
                  className={`${classes.inputMenu} inputstyle`}
                  name="subject"
                  placeholder="Input text"
                  maxLength={30}
                  value={subject}
                  onChange={handleOnchangeSubject}
                />
                <p className={classes.errorText}>{errorSubject}</p>
              </>
            )}
          </Grid>
          <Grid md={6} item className={classes.inputRight}>
            <Typography className={classes.label}>Amount</Typography>
            {isDetail ? (
              <Box className={classes.bodyData}>
                <Typography
                  className={classes.bodyDataText}
                  variant="body1"
                  component="p"
                >
                  {formatRupiah(valAmount || '0')}
                </Typography>
              </Box>
            ) : (
              <>
                <input
                  className={`${classes.inputMenu} inputstyle no-spin`}
                  name="amount"
                  placeholder="Input amount"
                  type='number'
                  onKeyDown={preventKeyDown}
                  min={0}
                  maxLength={30}
                  value={amount}
                  onChange={handleOnchangeAmount}
                />
                <p className={classes.errorText}>{errorAmount}</p>
              </>
            )}
          </Grid>
        </Grid>
        <Grid md={12} container className={classes.wrapperInput}>
          <Grid md={6} item className={classes.inputLeft}>
            <Box className={classes.wrapperBody}>
              <Box className={classes.bodyContent}>
                <Typography
                  className={classes.bodyTitle}
                  variant="body1"
                  component="p"
                >
                  Start Periode
                </Typography>
              </Box>
              {isDetail ? (
                <Box className={classes.bodyData}>
                  <Typography
                    className={classes.bodyDataText}
                    variant="body1"
                    component="p"
                  >
                    {valStartPeriode}
                  </Typography>
                </Box>
              ) : (
                <>
                  <Box className={classes.dateContent}>
                    <div className={classes.dateRangeIcon}>
                      <img src={DateIcon} alt="image not found" />
                    </div>
                    <div className={classes.containerDate}>
                      <DatePickerSingle
                        onDatePick={(pickedDay) => {
                          setStartPeriode(pickedDay);
                        }}
                        placeholder="Select Date"
                      />
                    </div>
                  </Box>
                  <p className={classes.errorText}>{errorStartPeriode}</p>
                </>
              )}
            </Box>
          </Grid>
          <Grid md={6} item className={classes.inputRight}>
            <Box className={classes.wrapperBody}>
              <Box className={classes.bodyContent}>
                <Typography
                  className={classes.bodyTitle}
                  variant="body1"
                  component="p"
                >
                  End Periode
                </Typography>
              </Box>
              {isDetail ? (
                <Box className={classes.bodyData}>
                  <Typography
                    className={classes.bodyDataText}
                    variant="body1"
                    component="p"
                  >
                    {valEndPeriode}
                  </Typography>
                </Box>
              ) : (
                <>
                  <Box className={classes.dateContent}>
                    <div className={classes.dateRangeIcon}>
                      <img src={DateIcon} alt="image not found" />
                    </div>
                    <div className={classes.containerDate}>
                      <DatePickerSingle
                        onDatePick={(pickedDay) => {
                          setEndPeriode(pickedDay);
                        }}
                        placeholder="Select Date"
                      />
                    </div>
                  </Box>
                  <p className={classes.errorText}>{errorEndPeriode}</p>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid md={12} className={classes.wrapperDescription}>
          <Box className={classes.bodyContent}>
            <Typography
              className={classes.bodyTitle}
              variant="body1"
              component="p"
            >
              Description
            </Typography>
          </Box>
          {isDetail ? (
            <Box className={classes.bodyData}>
              <Typography
                className={classes.bodyDataText}
                variant="body1"
                component="p"
              >
                {valDescription}
              </Typography>
            </Box>
          ) : (
            <>
              <TextareaAutosize
                className={classes.inputMenuTextArea}
                // aria-label="empty textarea"
                placeholder="Write a note..."
                minRows={3}
                cols={12}
                value={description}
                onChange={handleOnchangeDesc}
              />
              <p className={classes.errorText}>{errorDescription}</p>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapperInformation: {
    marginTop: 30,
  },
  titleText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    fontWeight: 'bold',
  },
  wrapperInput: {
    marginTop: 15,
  },
  label: {
    marginBottom: 12,
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
  },
  inputMenu: {
    width: '100%',
    height: 50,
    borderRadius: 8,
    paddingLeft: 20,
    border: '1px solid #E7E7E8',
    backgroundColor: '#F8FAFB',
    outline: 'none',
    '&.inputstyle:focus': {
      border: '1.5px solid #F2C94C',
      outline: 'none',
      boxShadow: '0px 0px 0px 4px rgba(242, 201, 76, 0.17);',
      borderRadius: 8,
    },
  },
  inputLeft: {
    paddingRight: 10,
  },
  inputRight: {
    paddingLeft: 10,
  },
  bodyTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
    marginBottom: 12,
  },
  dateContent: {
    width: '100%',
    // backgroundColor: 'red',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  dateRangeIcon: {
    width: 58,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E7E7E8',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  containerDate: {
    width: `100%`,
    maxWidth: `calc(100% - 58px)`,
  },
  wrapperDescription: {
    marginTop: 15,
    marginBottom: 29,
  },
  inputMenuTextArea: {
    width: '100% !important',
    // height: 50,
    borderRadius: 8,
    paddingLeft: 15,
    border: '1px solid #E7E7E8',
    backgroundColor: '#F8FAFB',
    outline: 'none',
    '&.inputstyle:focus': {
      border: '1.5px solid #F2C94C',
      outline: 'none',
      boxShadow: '0px 0px 0px 4px rgba(242, 201, 76, 0.17);',
      borderRadius: 8,
    },
  },
  bodyData: {
    width: '100%',
    backgroundColor: '#EBEFF0',
    padding: '12px 20px',
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    minHeight: 50,
  },
  bodyDataText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
  },
  errorText: {
    fontSize: 14,
    color: 'red',
  },
}));

export default Information;
