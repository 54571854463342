import React from 'react';
import { makeStyles, Grid, Box } from '@material-ui/core';
import { formatRupiah } from '../../../../utils/formatter';

// Components
import { ContentText } from '../../../../libs/Typography';
import ModalBAST from '../ModalBAST';

// icons
import PlusBtn from '../../../../assets/Icons/plusBtn.png';
import EditBlack from '../../../../assets/Icons/EditBlack.png';
import Edit from '../../../../assets/Icons/Edit.png';
import Trash from '../../../../assets/Icons/trash.png';
import TrashRed from '../../../../assets/Icons/trashRed.png';
import Plus from '../../../../assets/Icons/plus.png';

const DefaultAddBAST = ({ onPress }) => {
  const classes = useStyle();
  return (
    <Grid xs={12} md={12} className={classes.sectionDefault}>
      <Box className={classes.btn} onClick={onPress}>
        <img src={PlusBtn} alt="none" />
        <ContentText content={`Add Item`} styling={classes.btnAddText} />
      </Box>
    </Grid>
  );
};

const SecondaryAddBAST = ({ onPress }) => {
  const classes = useStyle();

  return (
    <Grid xs={12} md={12} className={classes.sectionDefault}>
      <Box className={classes.btnAddMore} onClick={onPress}>
        <img src={Plus} alt="none" />
        <ContentText
          content={`Add more item`}
          styling={classes.btnAddMoreText}
        />
      </Box>
    </Grid>
  );
};

const ListItemBAST = ({ item, index, onDelete, onEdit, detail, length }) => {
  const classes = useStyle();
  const [editHover, setEditHover] = React.useState(false);
  const [removeHover, setRemoveHover] = React.useState(false);

  return (
    <Grid
      container
      xs={12}
      md={12}
      className={
        index + 1 === length && detail
          ? classes.listContainerlast
          : classes.listContainer
      }
      key={index}
    >
      <Grid item xs={1} className={classes.itemNumber}>
        <ContentText content={index + 1} styling={classes.itemLabel} />
      </Grid>
      <Grid item xs={7} className={classes.itemName}>
        <ContentText content={item.description} styling={classes.itemLabel} />
      </Grid>
      <Grid item xs={3} className={classes.itemValue}>
        <ContentText
          content={formatRupiah(item.value)}
          styling={classes.itemLabel}
        />
      </Grid>
      {detail ? null : (
        <Grid item xs={1} className={classes.actionContent}>
          <Box
            onMouseEnter={() => setEditHover(true)}
            onMouseLeave={() => setEditHover(false)}
            className={classes.pointer}
            onClick={() => onEdit(index)}
          >
            <img src={editHover ? EditBlack : Edit} alt="none" />
          </Box>
          <Box
            onMouseEnter={() => setRemoveHover(true)}
            onMouseLeave={() => setRemoveHover(false)}
            className={classes.pointer}
            onClick={() => onDelete(index)}
          >
            <img src={removeHover ? TrashRed : Trash} alt="none" />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

const BASTITEM = ({ nilaiBAST, setNilaiBAST, detail }) => {
  const classes = useStyle();
  const [openModal, setOpenModal] = React.useState(false);
  const [BASTItem, setBASTItem] = React.useState(null);

  const handleAddItem = (item) => {
    let data = {
      id: nilaiBAST.length + 1,
      description: item.description,
      value: item.ppn,
    };
    setNilaiBAST((prev) => [...prev, data]);
  };

  const handleDeleteItem = (indexItem) => {
    let result = nilaiBAST.filter((item, index) => index != indexItem);
    setNilaiBAST(result);
  };

  const handleEditItem = (indexItem) => {
    let result = nilaiBAST[indexItem];
    setBASTItem(result);
    setOpenModal(true);
  };

  const handleEditAction = (item) => {
    let value = nilaiBAST.map((bast) => {
      if (bast.id === BASTItem.id) {
        return { ...bast, description: item.description, value: item.ppn };
      } else {
        return bast;
      }
    });
    setNilaiBAST(value);
    setBASTItem(null);
  };

  return (
    <Grid xs={12} md={12} className={classes.mainContainer}>
      <ModalBAST
        open={openModal}
        close={() => setOpenModal(false)}
        onPress={handleAddItem}
        onEdit={handleEditAction}
        data={BASTItem}
        title={BASTItem ? 'Edit' : 'Add'}
      />
      <ContentText content={`Detail Nilai BAST`} styling={classes.mainTitle} />
      <Grid container xs={12} md={12} className={classes.headerTitle}>
        <Grid item xs={1} className={classes.tableName}>
          <ContentText content={`No`} styling={classes.tableLabel} />
        </Grid>
        <Grid item xs={7} className={classes.tableName}>
          <ContentText content={`Deskripsi`} styling={classes.tableLabel} />
        </Grid>
        <Grid item xs={detail ? 4 : 3} className={classes.tableNamePpn}>
          <ContentText
            content={`Nilai Pekerjaan (*excl Ppn)`}
            styling={classes.tableLabel}
          />
        </Grid>
        {detail ? null : (
          <Grid item xs={1} className={classes.tableName}></Grid>
        )}
      </Grid>
      {nilaiBAST.length > 0
        ? nilaiBAST.map((item, index) =>
            detail ? (
              <ListItemBAST
                key={index}
                item={item}
                index={index}
                onDelete={handleDeleteItem}
                onEdit={handleEditItem}
                length={nilaiBAST.length}
                detail
              />
            ) : (
              <ListItemBAST
                key={index}
                item={item}
                index={index}
                onDelete={handleDeleteItem}
                onEdit={handleEditItem}
                length={nilaiBAST.length}
              />
            )
          )
        : null}
      {detail ? null : nilaiBAST.length > 0 ? (
        <SecondaryAddBAST onPress={() => setOpenModal(true)} />
      ) : (
        <DefaultAddBAST onPress={() => setOpenModal(true)} />
      )}
    </Grid>
  );
};

const useStyle = makeStyles((theme) => ({
  mainContainer: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 20,
    paddingRight: 20,
    background: '#fff',
  },
  sizeBorder: {
    marginTop: 30,
    borderBottom: '1px solid #E7E7E8',
  },
  mainTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 700,
    color: '#969BAB',
  },
  headerTitle: {
    marginTop: 24,
    background: '#fff',
    borderLeft: '1px solid #E7E7E8',
    borderRight: '1px solid #E7E7E8',
    borderTop: '1px solid #E7E7E8',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  tableName: {
    padding: '19.5px 20px',
  },
  tableNamePpn: {
    padding: '19.5px 20px',
    textAlign: 'right',
  },
  tableLabel: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 700,
    color: '#1B2030',
  },
  sectionDefault: {
    padding: '17px 20px',
    background: '#fff',
    borderLeft: '1px solid #E7E7E8',
    borderRight: '1px solid #E7E7E8',
    borderBottom: '1px solid #E7E7E8',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  btn: {
    cursor: 'pointer',
    padding: '9.5px 7.5px',
    background: '#127EEE',
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 156,
  },
  btnAddText: {
    marginLeft: 14,
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 700,
    color: '#fff',
  },
  listContainer: {
    height: 60,
    background: '#fff',
    borderLeft: '1px solid #E7E7E8',
    borderRight: '1px solid #E7E7E8',
  },
  listContainerlast: {
    borderLeft: '1px solid #E7E7E8',
    borderRight: '1px solid #E7E7E8',
    borderBottom: '1px solid #E7E7E8',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  itemNumber: {
    padding: '19.5px 20px',
  },
  itemName: {
    padding: '19.5px 20px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    flex: 1,
  },
  itemValue: {
    padding: '19.5px 20px',
    textAlign: 'right',
  },
  itemLabel: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 400,
    color: '#1B2030',
  },
  actionContent: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  pointer: {
    cursor: 'pointer',
  },
  btnAddMore: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  btnAddMoreText: {
    marginLeft: 14,
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 700,
    color: '#127EEE',
  },
}));

export default BASTITEM;
