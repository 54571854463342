import React from "react";
import { Box, Grid, Typography, Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../redux/Auth/actions";
import { useRouter } from "../../utils/useRouter";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";

// Component
import InputGroup from "../inputGroup";
import CustomizedSnackbars from "../PopupMessage";

const schema = yup.object().shape({
  UsernameE: yup.string().required(),
  Password: yup.string().required(),
});

// SITE KEY
// 6LedzDUfAAAAAHOiizj9p1mLlPnjopqXjC_K-8Bb

// SECRET KEY
// 6LedzDUfAAAAAK4x5Tubc42n4b8S495AmY8l-6Xu

const SigninForm = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const classes = useStyles();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // gobal Variable
  const { access_token, data, isLoading, message, isLogin } = useSelector(
    (state) => state.auth
  );

  const [checked, setChecked] = React.useState(false);

  // let token;

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const onSubmit = (data) => {
    // if(token){
    const { UsernameE, Password } = data;
    dispatch(actions.login(UsernameE, Password, checked));
    // }else{
    //     setType('error');
    //     setShow(true);
    //     setNotifText('Plese verify you are human');
    // }
  };

  React.useEffect(() => {
    const setToken = () => {
      // await AsyncStorage.setItem('@token', access_token);
      // dispatch(actions.setIsLogin(true));
    };

    if (access_token) {
      setToken();
      dispatch(actions.getProfile(access_token));
      // router.push('/');
    }
  }, [access_token]);

  React.useEffect(() => {
    if (data) {
      if (data?.user?.role?.length) {
        router.push("/");
      } else {
        router.push("/purchase-order-external");
      }
    }
  }, [data]);

  // handle message
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState("");
  const [notifText, setNotifText] = React.useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShow(false);
    dispatch(actions.setMessage());
  };

  React.useEffect(() => {
    if (message) {
      if (message === "Unauthorized.") {
        setType("error");
        setShow(true);
        setNotifText("email or password incorrect");
      } else if (message === "Unauthorized. Email or username not found") {
        setType("error");
        setShow(true);
        setNotifText(message);
      } else {
        setType("error");
        setShow(true);
        setNotifText(message);
      }
    }
  }, [message]);

  const handleForgotLink = () => {
    router.push("/forgot");
  };

  // function onChange(value) {
  //   // console.log('Captcha value:', value);
  //   setVerify(true);
  // }
  return (
    <>
      <CustomizedSnackbars
        show={show}
        handleClose={handleClose}
        message={notifText}
        type={type}
      />
      <Box className={classes.wrapperForm}>
        <Grid container direction="column">
          <Typography
            className={classes.titlePage}
            variant="body1"
            component="p"
          >
            WELCOME TO DKT Business One
          </Typography>
          <Typography
            className={classes.subTitle}
            variant="body1"
            component="p"
          >
            Log into your account
          </Typography>
          <Box className={classes.inputBoxes} />

          <form onSubmit={handleSubmit(onSubmit)}>
            <InputGroup
              label="UsernameE"
              placeholder="Your Username / Email"
              register={register}
              required
              type="text"
              error={errors.Username ? "error" : null}
            />
            <p className={classes.errorText}>{errors.Username?.message}</p>
            <Box className={classes.sizeBox} />
            <InputGroup
              label="Password"
              placeholder="Your Password"
              register={register}
              required
              type="password"
              error={errors.Password ? "error" : null}
            />
            <p className={classes.errorText}>{errors.Password?.message}</p>

            <Box className={classes.optionInput}>
              <Box className={classes.optionCheck}>
                <Typography
                  className={classes.checkText}
                  variant="body1"
                  component="p"
                >
                  Remember Me
                </Typography>
                <Checkbox
                  className={classes.checkContent}
                  checked={checked}
                  onChange={handleChange}
                  color="default"
                  inputProps={{ "aria-label": "checkbox with default color" }}
                />
              </Box>
              <Box className={classes.forgotLink} onClick={handleForgotLink}>
                <Typography
                  variant="body1"
                  className={classes.forgotText}
                  component="p"
                >
                  Forgot Password
                </Typography>
              </Box>
            </Box>
            {/* <GoogleReCaptchaProvider
              language="en-US"
              reCaptchaKey="6LedzDUfAAAAAHOiizj9p1mLlPnjopqXjC_K-8Bb"
            >
              <GoogleReCaptcha
                onVerify={(n) => {
                  token = n;
                }}
              /> */}
              <input
                className={classes.submitBtn}
                type="submit"
                value="Login"
              />
            {/* </GoogleReCaptchaProvider> */}
          </form>

          {/* <Box className={classes.wrapperLink}>
          <Typography
          className={classes.checkText}
          variant="body1"
          component="p"
          >
          Create an Account?
          </Typography>
          <a className={classes.linkText} href="#">
          Sign Up
          </a>
        </Box> */}
        </Grid>
      </Box>
    </>
  );
};

// const WrappedSign = () => {
//   return(
//     <GoogleReCaptchaProvider
//       language="en-US"
//       reCaptchaKey="6LedzDUfAAAAAHOiizj9p1mLlPnjopqXjC_K-8Bb"
//     >
//       <SigninForm />
//     </GoogleReCaptchaProvider>
//   )
// }

const useStyles = makeStyles({
  wrapperForm: {
    width: 346,
    backgroundColor: "#fff",
    marginTop: 45,
    paddingTop: 31,
    paddingBottom: 31,
    paddingLeft: 20,
    paddingRight: 20,
    border: "1px solid #E7E7E8",
    borderRadius: 16,
  },
  titlePage: {
    fontWeight: "bold",
    color: "#969BAB",
    fontSize: 16,
    lineHeight: "30px",
    // fontFamily: 'Nunito Sans, sans-serif',
    textAlign: "center",
  },
  subTitle: {
    fontWeight: "bold",
    color: "#1B2030",
    fontSize: 21,
    lineHeight: "32px",
    textAlign: "center",
  },
  sizeBox: {
    marginTop: 18,
  },
  inputBoxes: {
    marginTop: 38,
  },
  optionInput: {
    marginTop: 20,
    marginBottom: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  optionCheck: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "center",
  },
  checkContent: {
    marginLeft: -10,
  },
  checkText: {
    fontSize: 16,
    lineHeight: "21px",
    color: "#1B2030",
  },
  forgotText: {
    fontSize: 16,
    lineHeight: "21px",
    color: "#1B2030",
    textDecorationLine: "underline",
    fontWeight: "bold",
  },
  submitBtn: {
    width: "100%",
    height: 50,
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: "#1B2030",
    borderRadius: 8,
    borderWidth: 0,
  },
  wrapperLink: {
    marginTop: 38,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  linkText: {
    marginLeft: 10,
    fontSize: 16,
    lineHeight: "21px",
    color: "#1B2030",
    textDecorationLine: "underline",
    fontWeight: "bold",
  },
  errorText: {
    fontSize: 14,
    color: "red",
  },
  captchaContent: {
    marginTop: 20,
    width: "100%",
  },
  forgotLink: {
    cursor: "pointer",
  },
  captcha: {
    backgroundColor: "red",
    width: "100%",
    // margin: 100,
  },
});

export default SigninForm;
