import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';

import FileRed from '../../../assets/Icons/fileTextRed.png';
import FileGreen from '../../../assets/Icons/fileTextGreen.png';
import Coin from '../../../assets/Icons/coin.png';
import Dollar from '../../../assets/Icons/dollar.png';

const Card = ({ index, title, subtitle, value }) => {
  const classes = useStyles();

  const _renderImage = (index) => {
    if (index === 1) return FileGreen;
    if (index === 2) return FileRed;
    if (index === 3) return Coin;
    if (index === 4) return Dollar;
  };

  const _renderBackground = (index) => {
    if (index === 1) return classes.wrapperIcon;
    if (index === 2) return classes.wrapperIconSecond;
    if (index === 3) return classes.wrapperIconThird;
    if (index === 4) return classes.wrapperIconFour;
  };

  return (
    <>
      <Box className={classes.wrapperCardContent}>
        <Box className={_renderBackground(index)}>
          <img src={_renderImage(index)} />
        </Box>
        <Box className={classes.wrapperText}>
          <Typography className={classes.titleCount}>{title}</Typography>
          <Typography className={classes.titleCount}>{subtitle}</Typography>
          <Typography className={classes.subTitleCount}>{value}</Typography>
        </Box>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapperCardContent: {
    width: '100%',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  wrapperIcon: {
    width: 60,
    height: 77,
    borderRadius: 8,
    backgroundColor: 'rgba(143, 215, 174, 0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapperIconSecond: {
    width: 60,
    height: 77,
    borderRadius: 8,
    backgroundColor: '#FFE9E8',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapperIconThird: {
    width: 60,
    height: 77,
    borderRadius: 8,
    backgroundColor: 'rgba(155, 178, 250, 0.26)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapperIconFour: {
    width: 60,
    height: 77,
    borderRadius: 8,
    backgroundColor: 'rgba(123, 97, 255, 0.12)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapperText: {
    marginLeft: 20,
  },
  titleCount: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
  },
  subTitleCount: {
    fontSize: 21,
    lineHeight: '31px',
    fontWeight: 'bold',
    color: '#000000',
  },
}));

export default Card;
