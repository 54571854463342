import * as type from './types';
import request from '../../utils/request';

export const setBlankCreate = () => (dispatch) => {
  dispatch({ type: type.CLIENTVENDOR_MESSAGE, payload: '' });
};

export const sendClientVendor = (token, data) => (dispatch) => {
  dispatch({ type: type.CLIENTVENDOR_LOADING, payload: true });

  request({
    method: 'post',
    url: `client`,
    data,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: type.CLIENTVENDOR_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: type.CLIENTVENDOR_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error send client vendor', err);
      dispatch({
        type: type.CLIENTVENDOR_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: type.CLIENTVENDOR_LOADING, payload: false });
    });
};

export const getClientVendor = (token, params) => (dispatch) => {
  dispatch({ type: type.CLIENTVENDOR_GET_LOADING, payload: true });

  request({
    method: 'get',
    url: `client`,
    params,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      const data = res?.data;
      // console.log(data);
      dispatch({ type: type.CLIENTVENDOR_GET_DATA, payload: data });
      dispatch({
        type: type.CLIENTVENDOR_GET_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: type.CLIENTVENDOR_GET_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get client vendor', err);
      dispatch({ type: type.CLIENTVENDOR_GET_LOADING, payload: false });
    });
};

export const setBlankDelMesage = () => (dispatch) => {
  dispatch({ type: type.CLIENTVENDOR_DELETE_MESSAGE, payload: '' });
};

export const deleteClientVendor = (token, id) => (dispatch) => {
  dispatch({ type: type.CLIENTVENDOR_DELETE_LOADING, payload: true });

  request({
    method: 'post',
    url: `client/${id}/delete`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: type.CLIENTVENDOR_DELETE_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: type.CLIENTVENDOR_DELETE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error send client vendor', err);
      dispatch({ type: type.CLIENTVENDOR_DELETE_LOADING, payload: false });
    });
};

export const getDetailClientVendor = (token, id) => (dispatch) => {
  dispatch({ type: type.CLIENTVENDOR_DETAIL_LOADING, payload: true });

  request({
    method: 'get',
    url: `client/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log(res);
      dispatch({ type: type.CLIENTVENDOR_DETAIL_DATA, payload: res?.data });
      dispatch({
        type: type.CLIENTVENDOR_DELETE_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: type.CLIENTVENDOR_DETAIL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get detail client vendor', err);
      dispatch({ type: type.CLIENTVENDOR_DETAIL_LOADING, payload: false });
    });
};

export const searchClientVendor = (token, data) => (dispatch) => {
  dispatch({ type: type.CLIENTVENDOR_SEARCH_LOADING, payload: true });

  request({
    method: 'post',
    url: `client/search`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      // console.log(res);
      dispatch({ type: type.CLIENTVENDOR_SEARCH_DATA, payload: res?.data });
      dispatch({
        type: type.CLIENTVENDOR_DELETE_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: type.CLIENTVENDOR_SEARCH_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error search client vendor', err);
      dispatch({ type: type.CLIENTVENDOR_SEARCH_LOADING, payload: false });
    });
};

export const setBlankSearchData = () => (dispatch) => {
  dispatch({ type: type.CLIENTVENDOR_SEARCH_DATA, payload: null });
};
