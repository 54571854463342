import SvgIcon from '@material-ui/core/SvgIcon';

export const SortArrow = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M3.708 6.417h6.736a.585.585 0 00.438-.971l-3.358-3.79a.58.58 0 00-.872 0l-3.378 3.79a.583.583 0 00.434.97z"
        fill="#127EEE"
      />
      <path
        d="M6.822 12.488a.58.58 0 01-.197-.145L3.267 8.555a.585.585 0 01.438-.972h6.735c.504 0 .77.595.434.97l-3.377 3.79a.585.585 0 01-.675.145z"
        fill="#D8D8D8"
      />
    </SvgIcon>
  );
};
