import React from 'react';
import { makeStyles, withStyles, Box } from '@material-ui/core';
import moment from 'moment';
import Lib from 'src/utils/Lib';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

// components
import { ContentText } from '../../../../libs/Typography';

// generate fixed table
const StickyTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#fff',
    color: theme.palette.common.white,
    right: 0,
    position: 'sticky',
    // zIndex: 99,
  },
  body: {
    backgroundColor: '#fff',
    minWidth: '20px',
    width: 128,
    left: 0,
    position: 'sticky',
    // zIndex: 99,
  },
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#fff',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff',
    },
  },
}))(TableRow);

const TableCustom = ({ item, index }) => {
  const classes = useStyles();
  
  return (
    <>
      <StyledTableRow
        className={
          index % 2 == 0
            ? classes.tableContentData
            : classes.tableContentDataDua
        }
        key={index}
      >
        <StickyTableCell style={{ width: 80 }}>
          <StyledTableCell numeric align="left" className={classes.cell}>
            <ContentText
              content={item?.name}
              styling={classes.textUsername}
            />
            {item?.message && (
              <ContentText
                content={`Note: ${item?.message}`}
                styling={classes.textPosition}
              />
            )}
            
          </StyledTableCell>
        </StickyTableCell>
        {item?.user?.map((check) => (
          <StyledTableCell>
            <Box className={classes.checkContent}>
              <Box className={classes.checkLabel}>
                <Box
                  className={
                    (check?.status === 'approved')
                      ? classes.statusApprove
                      : classes.statusRevise
                  }
                >
                  {(check?.status === 'approved') ? 'Checked' : 'Revise'}
                </Box>
                
              </Box>
              <Box className={classes.textDate}>
                  {moment(check?.updated_at).format('DD-MM-YYYY HH:mm')}
                </Box>
              {/* {check?.status !== 'approved' && (
                <Box className={classes.noteContent}>
                  <ContentText
                    content={'Note :'}
                    styling={classes.textNoteBold}
                  />
                  <ContentText
                    content={`${check?.notes}`}
                    styling={classes.textNote}
                  />
                </Box>
              )} */}
            </Box>
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  textUsername: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 400,
    color: '#1B2030',
  },
  textPosition: {
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 400,
    color: '#969BAB',
  },
  checkContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  checkLabel: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  statusApprove: {
    fontSize: 12,
    lineHeight: '26px',
    fontWeight: 700,
    color: '#fff',
    background: '#27AE60',
    borderRadius: 100,
    padding: '0px 8px',
    textTransform: 'uppercase',
  },
  statusRevise: {
    fontSize: 12,
    lineHeight: '26px',
    fontWeight: 700,
    color: '#fff',
    background: '#FD453A',
    borderRadius: 100,
    padding: '0px 8px',
    textTransform: 'uppercase',
  },
  textDate: {
    fontSize: 14,
    lineHeight: '26px',
    fontWeight: 400,
    color: '#1B203',
    marginLeft: '9.5px',
  },
  noteContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  textNoteBold: {
    fontSize: 14,
    lineHeight: '26px',
    fontWeight: 'bold',
    color: '#1B203',
  },
  textNote: {
    fontSize: 14,
    lineHeight: '26px',
    fontWeight: 400,
    color: '#1B203',
    marginLeft: '9.5px',
  },
  tableContentData: {
    backgroundColor: '#F8FAFB',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    border: 'none',
    height: 60,
    borderBottom: 'none',
    '&.MuiTableRow-root .MuiTableCell-root.MuiTableCell-body': {
      borderBottom: 'none',
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 10,
      paddingBottom: 10,
    },
    '&.MuiTableRow-root .MuiTableCell-sizeSmall': {
      paddingRight: 0,
      borderBottom: 'none',
    },
  },
  tableContentDataDua: {
    backgroundColor: '#fff',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    border: 'none',
    height: 60,
    borderBottom: 'none',
    '&.MuiTableRow-root .MuiTableCell-sizeSmall': {
      paddingRight: 0,
      borderBottom: 'none',
    },
    '&.MuiTableRow-root .MuiTableCell-root.MuiTableCell-body': {
      borderBottom: 'none',
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
}));

export default TableCustom;
