// send Client Vendor
export const CLIENTVENDOR_LOADING = 'CLIENTVENDOR/CLIENTVENDOR_LOADING';
export const CLIENTVENDOR_MESSAGE = 'CLIENTVENDOR/CLIENTVENDOR_MESSAGE';

// Get Vendor Data
export const CLIENTVENDOR_GET_DATA = 'CLIENTVENDOR/CLIENTVENDOR_GET_DATA';
export const CLIENTVENDOR_GET_LOADING = 'CLIENTVENDOR/CLIENTVENDOR_GET_LOADING';
export const CLIENTVENDOR_GET_MESSAGE = 'CLIENTVENDOR/CLIENTVENDOR_GET_MESSAGE';

// Delete Vendor Data
export const CLIENTVENDOR_DELETE_LOADING =
  'CLIENTVENDOR/CLIENTVENDOR_DELETE_LOADING';
export const CLIENTVENDOR_DELETE_MESSAGE =
  'CLIENTVENDOR/CLIENTVENDOR_DELETE_MESSAGE';

// get vendor detail
export const CLIENTVENDOR_DETAIL_DATA = 'CLIENTVENDOR/CLIENTVENDOR_DETAIL_DATA';
export const CLIENTVENDOR_DETAIL_LOADING =
  'CLIENTVENDOR/CLIENTVENDOR_DETAIL_LOADING';
export const CLIENTVENDOR_DETAIL_MESSAGE =
  'CLIENTVENDOR/CLIENTVENDOR_DETAIL_MESSAGE';

// Search Client Vendor
export const CLIENTVENDOR_SEARCH_DATA = 'CLIENTVENDOR/CLIENTVENDOR_SEARCH_DATA';
export const CLIENTVENDOR_SEARCH_LOADING =
  'CLIENTVENDOR/CLIENTVENDOR_SEARCH_LOADING';
export const CLIENTVENDOR_SEARCH_MESSAGE =
  'CLIENTVENDOR/CLIENTVENDOR_SEARCH_MESSAGE';
