import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    paddingTop: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 8,
    minWidth: 300,
    maxWidth: 465,
  },
  titleMessage: {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '26px',
    paddingBottom: 20,
    borderBottom: '1px solid #E7E7E8',
  },
  titleBody: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  wrapperBtnContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  btnApprove: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 10,
    paddingLeft: 10,
    borderRadius: 8,
    backgroundColor: 'black',
    cursor: 'pointer',
  },
  textApprove: {
    fontSize: 14,
    lineHeight: '26px',
    fontWeight: 'bold',
    color: ' #fff',
  },
  btnCancel: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 10,
    paddingLeft: 10,
    borderRadius: 8,
    cursor: 'pointer',
  },
  textCancle: {
    fontSize: 14,
    lineHeight: '26px',
    fontWeight: 'bold',
    color: '#A60000',
  },
}));

export default function NotifApprovalDashboard({
  open,
  handleGoToApproval,
  close,
}) {
  const classes = useStyles();

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={close}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className={classes.paper}>
            <Typography
              className={classes.titleMessage}
              variant="body1"
              component="p"
            >
              Information
            </Typography>
            <Typography
              className={classes.titleBody}
              variant="body1"
              component="p"
            >
              There is a item must be approve
            </Typography>
            <Box className={classes.wrapperBtnContent}>
              <Box className={classes.btnApprove} onClick={handleGoToApproval}>
                <Typography
                  className={classes.textApprove}
                  variant="body1"
                  component="p"
                >
                  Go To Approve
                </Typography>
              </Box>
              <Box className={classes.btnCancel} onClick={close}>
                <Typography
                  className={classes.textCancle}
                  variant="body1"
                  component="p"
                >
                  Close
                </Typography>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
