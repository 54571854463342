import React from 'react';
import { makeStyles } from '@material-ui/core';

import { getDeviceType } from '../../utils/get_device_type';

// Components
import LayoutDashboard from '../../components/LayoutDashboard';
import ClientVendorTableData from '../../components/ClientVendorTableData';

export const ClientVendor = () => {
  const classes = useStyles();

  const [deviceType, setDeviceType] = React.useState('');

  React.useEffect(() => {
    const deviceType = getDeviceType();

    setDeviceType(deviceType);
  });

  return (
    <LayoutDashboard page="Client/Vendors" deviceType={deviceType}>
      <div className={classes.pageContainer}>
        <ClientVendorTableData />
      </div>
    </LayoutDashboard>
  );
};

const useStyles = makeStyles({
  pageContainer: {
    marginBottom: 30,
    paddingTop: 97 - 24,
    minHeight: '84vh',
  },

  pageContainerMobile: {
    maxWidth: '100vw',
    marginBottom: 30,
    paddingTop: 97 - 24,

    // marginLeft: -24,
  },
  sizebox: {
    marginTop: 20,
  },
  sizeboxMenu: {
    marginTop: 27,
  },
});

export default ClientVendor;
