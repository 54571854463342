export const LOADING = 'auth/Loading';
export const FETCHING = 'auth/Fetching';
export const SET_PROFILE = 'auth/SetProfile';
export const AUTH_LOGIN = 'auth/AUTH_LOGIN';
export const MESSAGE = 'auth/message';
export const LOGOUT = 'auth/Logout';
export const LOGOUT_MESSAGE = 'auth/Logout_Mesage';

// verify accout
export const VERIFY_LOADING = 'AUTH/VERIFY_LOADING';
export const VERIFY_MESSSAGE = 'AUTH/VERIFY_MESSSAGE';
