import React from 'react';
import { Grid, Typography, Box, makeStyles, Checkbox } from '@material-ui/core';
import { dateFormater } from '../../../../utils/formatter';

// components
import ApprovalItem from './ApprovalItem';

//style
import './style.css'

const ListItem = ({
  index,
  item,
  handleChange,
  _renderStatusApprove,
  handleShowDetail,
  EyeBlue,
  userData,
}) => {
  const classes = useStyles();
  return (
    <Grid xs={12} sm={12} md={12} container key={index}>
      <Box
        className={
          item?.status === 'approved' ? 'wrapper-approved' : 'wrapper-item'
        }
      >
        <Box className={classes.wrapperCheckbox}>
          <Checkbox
            disabled={
              item?.status === 'approved' ||
              item?.read_only ||
              userData?.user?.role[0] === 'superadmin'
                ? true
                : false
            }
            name={`${item?.id}`}
            checked={item?.isChecked || false}
            className={classes.checkContent}
            color="secondary"
            onClick={handleChange}
            inputProps={{
              'aria-label': 'checkbox with default color',
            }}
          />
        </Box>
        <Box className={classes.wrapperDetail}>
          <Grid container md={12} xs={12} sm={12}>
            <Grid md={3} xs={3} sm={3}>
              <Typography
                className={classes.labelText}
                variant="body1"
                component="p"
              >
                {`No. ${item?.doc_num} / ${item?.entity?.code} / ${
                  item?.department?.code ?? '-'
                }`}
              </Typography>
            </Grid>
            <Grid container md={7} xs={5} sm={5} className={classes.wrapperApproval}>
              <Grid md={3} xs={5} sm={5} className={classes.wrapperApprovalTitel}>
                <Typography
                  className={classes.labelText}
                  variant="body1"
                  component="p"
                >
                  Approved By
                </Typography>
              </Grid>
            </Grid>
            <Grid md={2} xs={4} sm={4}>
              <Box className={classes.lastItem}>
                <Typography
                  className={classes.labelText}
                  variant="body1"
                  component="p"
                >
                  {`Submitted: ${dateFormater(item?.created_at, 'DD-MM-YYYY')}`}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box style={{ marginTop: 7.5 }} />
          <Grid container md={12} xs={12} sm={12}>
            <Grid md={3} xs={3} sm={3}>
              <Typography
                className={classes.valueTextDesc}
                variant="body1"
                component="p"
              >
                {item?.po?.pr?.purpose_of_request ?? '-'}
              </Typography>
            </Grid>
            <Grid container md={7} xs={5} sm={5} className={classes.wrapperApproval}>
              {item?.approval?.map((app, index) => (
                <ApprovalItem
                  index={index}
                  app={app}
                  _renderStatusApprove={_renderStatusApprove}
                />
              ))}
            </Grid>
            <Grid md={2} xs={4} sm={4}>
              <Box className={classes.textRightContent}>
                <Box
                  className={classes.btnDetail}
                  onClick={() => handleShowDetail(item)}
                >
                  <Box>
                    <img src={EyeBlue} alt="image not found" />
                  </Box>
                  <Typography
                    className={classes.btnDetailText}
                    variant="body1"
                    component="p"
                  >
                    View APDP Detail
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};

const useStyles = makeStyles({
  // Styling Item
  wrapperCheckbox: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 12,
    paddingBottom: 12,
  },
  wrapperDetail: {
    flex: 1,
    paddingRight: 20,
    paddingTop: 16,
    paddingBottom: 18,
  },
  labelText: {
    fontSize: 14,
    lineHeight: '26px',
    // letterSpacing: '0.01em',
    color: '#969BAB',
  },
  lastItem: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  valueTextDesc: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
    paddingRight: 20,
  },
  valueText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
    marginRight: 10,
  },
  statusContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  textRightContent: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  btnDetail: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
  },
  btnDetailText: {
    color: '#127EEE',
    marginLeft: 8,
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  checkContent: {
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#2B3595',
    },
  },
  wrapperApproval: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  wrapperApprovalTitel: {
    minWidth: 100,
  },
});

export default ListItem;
