import * as types from './types';

// Just Example
export const settingsReducers = (
  state = {
    permission_isLoading: false,
    permission_data: null,
    background_color: '',
    role_createIsLoading: false,
    role_createMessage: '',
    role_deleteIsLoading: false,
    role_deleteMessage: '',
    role_isLoading: false,
    role_data: null,
    role_detailIsLoading: false,
    role_detail: null,
    role_updateIsLoading: false,
    role_updateMesssage: '',
    entity_isLoading: false,
    entity_data: null,
    entity_createIsLoading: false,
    entity_createMessage: '',
    entity_deteleIsLoading: false,
    entity_deleteMessage: '',
    entity_detailIsLoading: false,
    entity_detail: null,
    department_isLoading: false,
    department_data: null,
    department_createIsLoading: false,
    department_createMessage: '',
    department_deleteIsLoading: false,
    department_deleteMessage: '',
    department_detailIsLoading: false,
    department_detail: null,
    department_idIsLoading: false,
    department_idData: null,
    position_createIsLoading: false,
    position_createMessage: '',
    position_isLoading: false,
    position_data: null,
    position_deleteIsLoading: false,
    position_deleteMessage: '',
    position_detailIsLoading: false,
    position_detail: null,
    checklist_createIsLoading: false,
    checklist_createMessage: '',
    checklist_isLoading: false,
    checklist_data: null,
    checklist_delIsLoading: false,
    checklist_delMessage: '',
    checklist_detailIsLoading: false,
    checklist_detail: null,
    checklist_updateIsLoading: false,
    checklist_updateMessage: '',
    activities_createIsLoading: false,
    activities_createMessage: '',
    activities_isLoading: false,
    activities_data: null,
    activities_delIsLoading: false,
    activities_delMessage: '',
    activities_detailIsLoading: false,
    activities_detail: null,
    activities_updateIsLoading: false,
    activities_updateMessage: '',
    report_isLoading: false,
    report_data: null,
    report_detailIsLoading: false,
    report_detail: null,
    report_updateIsLoading: false,
    report_updateMessage: '',
    distributor_isLoading: false,
    distributor_data: null,
    distributor_createIsLoading: false,
    distributor_createMessage: '',
    distributor_delIsLoading: false,
    distributor_delMessage: '',
    distributor_detailIsLoading: false,
    distributor_detail: null,
    distributor_updateIsLoading: false,
    distributor_updateMessage: '',
    suppiler_isLoading: false,
    supplier_data: null,
    supplier_createIsLoading: false,
    supplier_createMessage: '',
    supplier_delIsLoading: false,
    supplier_delMessage: '',
    supplier_detailIsLoading: false,
    supplier_detail: null,
    supplier_updateIsLoading: false,
    supplier_updateMessage: '',
    clientCategory_createIsLoading: false,
    clientCategory_createMessage: '',
    clientCategory_IsLoading: false,
    clientCategory_data: null,
    clientCategory_delIsLoading: false,
    clientCategory_delMessage: '',
    clientCategory_detailIsLoading: false,
    clientCategory_detail: null,
    clientCategory_updateIsLoading: false,
    clientCategory_updateMessage: '',
    purchaseType_createIsLoading: false,
    purchaseType_createMessage: '',
    purchaseType_IsLoading: false,
    purchaseType_data: null,
    purchaseType_delIsLoading: false,
    purchaseType_delMessage: '',
    purchaseType_detailIsLoading: false,
    purchaseType_detail: null,
    purchaseType_updateIsLoading: false,
    purchaseType_updateMessage: '',
  },
  action
) => {
  switch (action.type) {
    // get perimisiion
    case types.GET_PERMISSION_LOADING:
      return {
        ...state,
        permission_isLoading: action.payload,
      };
    case types.GET_PERMISSION_DATA:
      return {
        ...state,
        permission_data: action.payload,
      };
    case types.SET_BACKGROUND_COLOR:
      return {
        ...state,
        background_color: action.payload,
      };

    // create role
    case types.CREATE_ROLE_LOADING:
      return {
        ...state,
        role_createIsLoading: action.payload,
      };
    case types.CREATE_ROLE_MESSAGE:
      return {
        ...state,
        role_createMessage: action.payload,
      };
    // delete role
    case types.DELETE_ROLE_LOADING:
      return {
        ...state,
        role_deleteIsLoading: action.payload,
      };
    case types.DELETE_ROLE_MESSAGE:
      return {
        ...state,
        role_deleteMessage: action.payload,
      };

    // get role
    case types.GET_ROLES_LOADING:
      return {
        ...state,
        role_isLoading: action.payload,
      };
    case types.GET_ROLES_DATA:
      return {
        ...state,
        role_data: action.payload,
      };

    // get role detail
    case types.DETAIL_ROLES_LOADING:
      return {
        ...state,
        role_detailIsLoading: action.payload,
      };
    case types.DETAIL_ROLES_DATA:
      return {
        ...state,
        role_detail: action.payload,
      };

    // update role
    case types.UPDATE_ROLE_LOADING:
      return {
        ...state,
        role_updateIsLoading: action.payload,
      };
    case types.UPDATE_ROLE_MESSAGE:
      return {
        ...state,
        role_updateMesssage: action.payload,
      };

    // get Entity
    case types.GET_ENTITY_LOADING:
      return {
        ...state,
        entity_isLoading: action.payload,
      };
    case types.GET_ENTITY_DATA:
      return {
        ...state,
        entity_data: action.payload,
      };

    // create Entity
    case types.CREATE_ENTITY_LOADING:
      return {
        ...state,
        entity_createIsLoading: action.payload,
      };
    case types.CREATE_ENTITY_MESSAGE:
      return {
        ...state,
        entity_createMessage: action.payload,
      };

    // detele entity
    case types.DELETE_ENTITY_LOADING:
      return {
        ...state,
        entity_deteleIsLoading: action.payload,
      };
    case types.DELETE_ENTITY_MESSAGE:
      return {
        ...state,
        entity_deleteMessage: action.payload,
      };
    // get detail entity
    case types.DETAIL_ENTITY_LOADING:
      return {
        ...state,
        entity_detailIsLoading: action.payload,
      };
    case types.DETAIL_ENTITY_DATA:
      return {
        ...state,
        entity_detail: action.payload,
      };
    // get all department
    case types.GET_DEPARTMENT_LOADING:
      return {
        ...state,
        department_isLoading: action.payload,
      };
    case types.GET_DEPARTMENT_DATA:
      return {
        ...state,
        department_data: action.payload,
      };
    // create department
    case types.CREATE_DEPARTMENT_LOADING:
      return {
        ...state,
        department_createIsLoading: action.payload,
      };
    case types.CREATE_DEPARTMENT_MESSAGE:
      return {
        ...state,
        department_createMessage: action.payload,
      };
    // delete department
    case types.DELETE_DEPARTMENT_LOADING:
      return {
        ...state,
        department_deleteIsLoading: action.payload,
      };
    case types.DELETE_DEPARTMENT_MESSAGE:
      return {
        ...state,
        department_deleteMessage: action.payload,
      };
    // get detail department
    case types.DETAIL_DEPARTMENT_LOADING:
      return {
        ...state,
        department_detailIsLoading: action.payload,
      };
    case types.DETAIL_DEPARTMENT_DATA:
      return {
        ...state,
        department_detail: action.payload,
      };
    // get  department by entity id
    case types.GET_DEPARTMENT_ID_LOADING:
      return {
        ...state,
        department_idIsLoading: action.payload,
      };
    case types.GET_DEPARTMENT_ID_DATA:
      return {
        ...state,
        department_idData: action.payload,
      };
    // create position
    case types.CREATE_POSITION_LOADING:
      return {
        ...state,
        position_createIsLoading: action.payload,
      };
    case types.CREATE_POSITION_MESSAGE:
      return {
        ...state,
        position_createMessage: action.payload,
      };
    // get all position
    case types.GET_POSITION_LOADING:
      return {
        ...state,
        position_isLoading: action.payload,
      };
    case types.GET_POSITION_DATA:
      return {
        ...state,
        position_data: action.payload,
      };
    // delete position
    case types.DELETE_POSITION_LOADING:
      return {
        ...state,
        position_deleteIsLoading: action.payload,
      };
    case types.DELETE_POSITION_MESSAGE:
      return {
        ...state,
        position_deleteMessage: action.payload,
      };
    // detail position
    case types.DETAIL_POSITION_LOADING:
      return {
        ...state,
        position_detailIsLoading: action.payload,
      };
    case types.DETAIL_POSITION_DATA:
      return {
        ...state,
        position_detail: action.payload,
      };

    // master data
    // create checklist
    case types.CREATE_CHECKLIST_LOADING:
      return {
        ...state,
        checklist_createIsLoading: action.payload,
      };
    case types.CREATE_CHECKLIST_MESSAGE:
      return {
        ...state,
        checklist_createMessage: action.payload,
      };
    // get all checklist
    case types.GET_CHECKLIST_LOADING:
      return {
        ...state,
        checklist_isLoading: action.payload,
      };
    case types.GET_CHECKLIST_DATA:
      return {
        ...state,
        checklist_data: action.payload,
      };
    // get checklist by id
    case types.DETAIL_CHECKLIST_LOADING:
      return {
        ...state,
        checklist_detailIsLoading: action.payload,
      };
    case types.DETAIL_CHECKLIST_DATA:
      return {
        ...state,
        checklist_detail: action.payload,
      };
    // delete checklist
    case types.DELETE_CHECKLIST_LOADING:
      return {
        ...state,
        checklist_delIsLoading: action.payload,
      };
    case types.DELETE_CHECKLIST_MESSAGE:
      return {
        ...state,
        checklist_delMessage: action.payload,
      };
    // update checklist
    case types.UPDATE_CHECKLIST_LOADING:
      return {
        ...state,
        checklist_updateIsLoading: action.payload,
      };
    case types.UPDATE_CHECKLIST_MESSAGE:
      return {
        ...state,
        checklist_updateMessage: action.payload,
      };

    // create activities
    case types.CREATE_ACTIVITIES_LOADING:
      return {
        ...state,
        activities_createIsLoading: action.payload,
      };
    case types.CREATE_ACTIVITIES_MESSAGE:
      return {
        ...state,
        activities_createMessage: action.payload,
      };

    // get all activities
    case types.GET_ACTIVITIES_LOADING:
      return {
        ...state,
        activities_isLoading: action.payload,
      };
    case types.GET_ACTIVITIES_DATA:
      return {
        ...state,
        activities_data: action.payload,
      };

    // delete activities
    case types.DELETE_ACTIVITIES_LOADING:
      return {
        ...state,
        activities_delIsLoading: action.payload,
      };
    case types.DELETE_ACTIVITIES_MESSAGE:
      return {
        ...state,
        activities_delMessage: action.payload,
      };

    // get by id activities
    case types.DETAIL_ACTIVITIES_LOADING:
      return {
        ...state,
        activities_detailIsLoading: action.payload,
      };
    case types.DETAIL_ACTIVITIES_DATA:
      return {
        ...state,
        activities_detail: action.payload,
      };
    // get by id activities
    case types.UPDATE_ACTIVITIES_LOADING:
      return {
        ...state,
        activities_updateIsLoading: action.payload,
      };
    case types.UPDATE_ACTIVITIES_MESSAGE:
      return {
        ...state,
        activities_updateMessage: action.payload,
      };
    // get all report
    case types.GET_REPORT_LOADING:
      return {
        ...state,
        report_isLoading: action.payload,
      };
    case types.GET_REPORT_DATA:
      return {
        ...state,
        report_data: action.payload,
      };
    // get  report by id
    case types.DETAIL_REPORT_LOADING:
      return {
        ...state,
        report_detailIsLoading: action.payload,
      };
    case types.DETAIL_REPORT_DATA:
      return {
        ...state,
        report_detail: action.payload,
      };
    // update report
    case types.UPDATE_REPORT_LOADING:
      return {
        ...state,
        report_updateIsLoading: action.payload,
      };
    case types.UPDATE_REPORT_MESSAGE:
      return {
        ...state,
        report_updateMessage: action.payload,
      };
    // get all distibutor
    case types.GET_DISTRIBUTOR_LOADING:
      return {
        ...state,
        distributor_isLoading: action.payload,
      };
    case types.GET_DISTRIBUTOR_DATA:
      return {
        ...state,
        distributor_data: action.payload,
      };
    // create distibutor
    case types.CREATE_DISTRIBUTOR_LOADING:
      return {
        ...state,
        distributor_createIsLoading: action.payload,
      };
    case types.CREATE_DISTRIBUTOR_MESSAGE:
      return {
        ...state,
        distributor_createMessage: action.payload,
      };

    // delete distibutor
    case types.DELETE_DISTRIBUTOR_LOADING:
      return {
        ...state,
        distributor_delIsLoading: action.payload,
      };
    case types.DELETE_DISTRIBUTOR_MESSAGE:
      return {
        ...state,
        distributor_delMessage: action.payload,
      };

    // get distributor by id
    case types.DETAIL_DISTRIBUTOR_LOADING:
      return {
        ...state,
        distributor_detailIsLoading: action.payload,
      };
    case types.DETAIL_DISTRIBUTOR_DATA:
      return {
        ...state,
        distributor_detail: action.payload,
      };

    // update distibutor
    case types.UPDATE_DISTRIBUTOR_LOADING:
      return {
        ...state,
        distributor_updateIsLoading: action.payload,
      };
    case types.UPDATE_DISTRIBUTOR_MESSAGE:
      return {
        ...state,
        distributor_updateMessage: action.payload,
      };

    // get all supplier
    case types.GET_SUPPLIER_LOADING:
      return {
        ...state,
        suppiler_isLoading: action.payload,
      };
    case types.GET_SUPPLIER_DATA:
      return {
        ...state,
        supplier_data: action.payload,
      };
    // create supplier
    case types.CREATE_SUPPLIER_LOADING:
      return {
        ...state,
        supplier_createIsLoading: action.payload,
      };
    case types.CREATE_SUPPLIER_MESSAGE:
      return {
        ...state,
        supplier_createMessage: action.payload,
      };

    // delete supplier
    case types.DELETE_SUPPLIER_LOADING:
      return {
        ...state,
        supplier_delIsLoading: action.payload,
      };
    case types.DELETE_SUPPLIER_MESSAGE:
      return {
        ...state,
        supplier_delMessage: action.payload,
      };

    // get supplier by id
    case types.DETAIL_SUPPLIER_LOADING:
      return {
        ...state,
        supplier_detailIsLoading: action.payload,
      };
    case types.DETAIL_SUPPLIER_DATA:
      return {
        ...state,
        supplier_detail: action.payload,
      };

    // delete supplier
    case types.UDPATE_SUPPLIER_LOADING:
      return {
        ...state,
        supplier_updateIsLoading: action.payload,
      };
    case types.UPDATE_SUPPLIER_MESSAGE:
      return {
        ...state,
        supplier_updateMessage: action.payload,
      };

    // create client category
    case types.CREATE_CLIENT_CATEGORY_LOADING:
      return {
        ...state,
        clientCategory_createIsLoading: action.payload,
      };
    case types.CREATE_CLIENT_CATEGORY_MESSAGE:
      return {
        ...state,
        clientCategory_createMessage: action.payload,
      };
    // get client category
    case types.GET_CLIENT_CATEGORY_LOADING:
      return {
        ...state,
        clientCategory_IsLoading: action.payload,
      };
    case types.GET_CLIENT_CATEGORY_DATA:
      return {
        ...state,
        clientCategory_data: action.payload,
      };

    // delete client category
    case types.DELETE_CLIENT_CATEGORY_LOADING:
      return {
        ...state,
        clientCategory_delIsLoading: action.payload,
      };
    case types.DELETE_CLIENT_CATEGORY_MESSAGE:
      return {
        ...state,
        clientCategory_delMessage: action.payload,
      };
    //  get detail client category
    case types.DETAIL_CLIENT_CATEGORY_LOADING:
      return {
        ...state,
        clientCategory_detailIsLoading: action.payload,
      };
    case types.DETAIL_CLIENT_CATEGORY_DATA:
      return {
        ...state,
        clientCategory_detail: action.payload,
      };
    //  update client category
    case types.UPDATE_CLIENT_CATEGORY_LOADING:
      return {
        ...state,
        clientCategory_updateIsLoading: action.payload,
      };
    case types.UPDATE_CLIENT_CATEGORY_MESSAGE:
      return {
        ...state,
        clientCategory_updateMessage: action.payload,
      };
    //  create purchase type
    case types.CREATE_PURCHASE_TYPE_LOADING:
      return {
        ...state,
        purchaseType_createIsLoading: action.payload,
      };
    case types.CREATE_PURCHASE_TYPE_MESSAGE:
      return {
        ...state,
        purchaseType_createMessage: action.payload,
      };
    //  get all purchase type
    case types.GET_PURCHASE_TYPE_LOADING:
      return {
        ...state,
        purchaseType_IsLoading: action.payload,
      };
    case types.GET_PURCHASE_TYPE_DATA:
      return {
        ...state,
        purchaseType_data: action.payload,
      };
    //  delete purchase type
    case types.DELETE_PURCHASE_TYPE_LOADING:
      return {
        ...state,
        purchaseType_delIsLoading: action.payload,
      };
    case types.DELETE_PURCHASE_TYPE_MESSAGE:
      return {
        ...state,
        purchaseType_delMessage: action.payload,
      };
    //  detail purchase type
    case types.DETAIL_PURCHASE_TYPE_LOADING:
      return {
        ...state,
        purchaseType_detailIsLoading: action.payload,
      };
    case types.DETAIL_PURCHASE_TYPE_DATA:
      return {
        ...state,
        purchaseType_detail: action.payload,
      };
    //  update purchase type
    case types.UPDATE_PURCHASE_TYPE_LOADING:
      return {
        ...state,
        purchaseType_updateIsLoading: action.payload,
      };
    case types.UPDATE_PURCHASE_TYPE_MESSAGE:
      return {
        ...state,
        purchaseType_updateMessage: action.payload,
      };

    default:
      return state;
  }
};
