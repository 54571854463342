import React from "react";
import { makeStyles, withStyles, Box } from "@material-ui/core";
import { get } from "lodash";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { dateFormater } from "../../../../utils/formatter";

// components
import { ContentText } from "../../../../libs/Typography";
import moment from "moment";

// generate fixed table
const StickyTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: theme.palette.common.white,
    right: 0,
    position: "sticky",
    // zIndex: 99,
  },
  body: {
    backgroundColor: "#fff",
    minWidth: "50px",
    width: 100,
    left: 0,
    position: "sticky",
    // zIndex: 99,
  },
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#fff",
    },
  },
}))(TableRow);

const keyMap = {
    bast_file_approved: "Berita Acara Serah Terima",
    invoice_file_approved: "Invoice",
    tax_file_approved: "Bukti Potong Pajak",
  }

const TableCustom = ({ item, index }) => {
  const classes = useStyles();

  const firstItem = get(item, '[0]', {})
  
  const checklistName = keyMap[Object.keys(firstItem)[0]]
  
  return (
    <>
      <StyledTableRow
        className={
          index % 2 == 0
            ? classes.tableContentData
            : classes.tableContentDataDua
        }
        key={index}
      >
        <StickyTableCell style={{ width: 80 }}>
          <StyledTableCell numeric align="left" className={classes.cell}>
          <ContentText
              content={checklistName}
              styling={classes.textUsername}
            />
          </StyledTableCell>
        </StickyTableCell>
        {item.map((obj, i) =>
        <StyledTableCell numeric align="left" className={classes.cell}>
          <Box className={classes.checkContent}>
            <Box className={classes.checkLabel}>
              <Box
                className={
                  obj[Object.keys(obj)[0]]
                    ? classes.statusApprove
                    : classes.statusRevise
                }
              >
                {obj[Object.keys(obj)[0]] ? "approved" : "revise"}
                
              </Box>
              
            </Box>
            <Box className={classes.textDate}>
                {moment(obj?.updated_at).format("DD-MM-YYYY HH:mm")}
              </Box>
          </Box>
        </StyledTableCell>)}
      </StyledTableRow>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  textUsername: {
    fontSize: 16,
    lineHeight: "21px",
    fontWeight: 400,
    color: "#1B2030",
  },
  textPosition: {
    fontSize: 14,
    lineHeight: "18px",
    fontWeight: 400,
    color: "#969BAB",
  },
  checkContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  checkLabel: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  statusApprove: {
    fontSize: 12,
    lineHeight: "26px",
    fontWeight: 700,
    color: "#fff",
    background: "#27AE60",
    borderRadius: 100,
    padding: "0px 8px",
    textTransform: "uppercase",
  },
  statusRevise: {
    fontSize: 12,
    lineHeight: "26px",
    fontWeight: 700,
    color: "#fff",
    background: "#FD453A",
    borderRadius: 100,
    padding: "0px 8px",
    textTransform: "uppercase",
  },
  textDate: {
    fontSize: 14,
    lineHeight: "26px",
    fontWeight: 400,
    color: "#1B203",
    marginLeft: "9.5px",
  },
  noteContent: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  textNoteBold: {
    fontSize: 14,
    lineHeight: "26px",
    fontWeight: "bold",
    color: "#1B203",
  },
  textNote: {
    fontSize: 14,
    lineHeight: "26px",
    fontWeight: 400,
    color: "#1B203",
    marginLeft: "9.5px",
  },
  tableContentData: {
    backgroundColor: "#F8FAFB",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    border: "none",
    height: 60,
    borderBottom: "none",
    "&.MuiTableRow-root .MuiTableCell-root.MuiTableCell-body": {
      borderBottom: "none",
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 10,
      paddingBottom: 10,
    },
    "&.MuiTableRow-root .MuiTableCell-sizeSmall": {
      paddingRight: 0,
      borderBottom: "none",
    },
  },
  tableContentDataDua: {
    backgroundColor: "#fff",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    border: "none",
    height: 60,
    borderBottom: "none",
    "&.MuiTableRow-root .MuiTableCell-sizeSmall": {
      paddingRight: 0,
      borderBottom: "none",
    },
    "&.MuiTableRow-root .MuiTableCell-root.MuiTableCell-body": {
      borderBottom: "none",
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
}));

export default TableCustom;
