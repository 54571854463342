import React from 'react';
import { makeStyles } from '@material-ui/core';

// Component
import LayoutDashboard from '../../components/LayoutDashboard';
import PrTableData from '../../components/PrTableData';

const PurchaseRequistor = () => {
  const classes = useStyles();

  return (
    <LayoutDashboard page="Purchase Requisition">
      <div className={classes.pageContainer}>
        <PrTableData />
      </div>
    </LayoutDashboard>
  );
};

const useStyles = makeStyles({
  pageContainer: {
    marginBottom: 30,
    paddingTop: 97 - 24,
    minHeight: '85vh',
  },
});

export default PurchaseRequistor;
