import React from 'react';
import { Grid, makeStyles, Box, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useRouter } from '../../../../utils/useRouter';
import { useSelector, useDispatch } from 'react-redux';
import * as settingAction from '../../../../redux/Settings/actions';

// Components
import InputGroup from '../../../inputGroup';
import ModalConfirm from '../ModalComfirm';
import ModalSuccess from '../../../ModalSuccess';
import CustomizedSnackbars from '../../../PopupMessage';
import SortArrow from '../../../SortArrow';
import PermissionItem from './subComponents/PermissionItem';
import { CircleLoading } from '../../../Loading/CircleLoading';

const schema = yup.object().shape({
  LevelName: yup.string().required(),
});

const LevelEdit = () => {
  const classes = useStyles();
  const router = useRouter();
  const { id } = router.query;
  const dispacth = useDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Global Variable
  const { access_token } = useSelector((state) => state.auth);
  const {
    permission_data,
    role_detail,
    role_detailIsLoading,
    role_updateMesssage,
    role_updateIsLoading,
  } = useSelector((state) => state.settings);

  const onSubmit = (data) => {
    // console.log(data);
    const { Description, LevelName } = data;

    let CheckData = permisionCheck?.filter(
      (dt, index) => dt.isChecked === true
    );

    let tempArr = [];
    CheckData.map((check, index) => {
      let id = check.id;
      tempArr.push(id);
    });

    let permissionText = tempArr.join(';');

    let SendData = {
      name: LevelName,
      description: Description,
      permission: permissionText,
    };

    setTempSendData(SendData);
    setShowModalConfirm(true);
  };

  // fot initial state
  const [entityFilter, setEntityFilter] = React.useState(0);
  const [departmentFilter, setDepartmentFilter] = React.useState(0);
  const [permisionCheck, setPermisionCheck] = React.useState([]);
  const [dataInput, setDataInput] = React.useState(null);
  const [tempSendData, setTempSendData] = React.useState(null);
  const [tempPerssion, setTempPermission] = React.useState([]);

  const handleChangeEntity = (event) => {
    setEntityFilter(event.target.value);
  };
  const handleChangeDepartment = (event) => {
    setDepartmentFilter(event.target.value);
  };

  const handleCheckPermission = (e) => {
    const { checked, name } = e.target;
    let tempData = permisionCheck.map((row, index) =>
      row.id == name ? { ...row, isChecked: checked } : row
    );
    setPermisionCheck(tempData);
  };

  // state for modal
  const [showModalConfirm, setShowModalConfirm] = React.useState(false);
  const [showModalSuccess, setShowModalSuccess] = React.useState(false);

  // handle message
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };
  //  end handle Message

  // for wacth value inside useform
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      // console.log(value, name, type)
      setDataInput(value)
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  React.useEffect(() => {
    dispacth(settingAction.getPermission(access_token));
  }, []);

  React.useEffect(() => {
    setPermisionCheck(permission_data?.permission);
    dispacth(settingAction.getDetailRole(access_token, id));
  }, [permission_data]);

  // React.useEffect(() => {
  //   dispacth(settingAction.getDetailRole(access_token, id));
  // }, []);

  React.useEffect(() => {
    setValue('LevelName', role_detail?.roles?.name);
    setValue('Description', role_detail?.roles?.description);

    if (permisionCheck.length > 0) {
      let tempArr = [...permisionCheck];

      permisionCheck.map((item, index) => {
        let tempData = role_detail?.roles?.permissions?.map((row) => {
          if (row.id == item.id) {
            permisionCheck[index]['isChecked'] = true;
          }
        });
      });
      setTempPermission(tempArr);
    }
  }, [role_detail]);

  React.useEffect(() => {
    if (role_updateMesssage) {
      if (role_updateMesssage === 'Update Roles') {
        setShowModalSuccess(true);
      } else if (role_updateMesssage !== '') {
        setShow(true);
        setType('error');
        setNotifText(role_updateMesssage);
      }
      dispacth(settingAction.setBlankUpdateRole());
    }
  }, [role_updateMesssage]);

  return (
    <>
      <CustomizedSnackbars
        show={show}
        handleClose={handleClose}
        message={notifText}
        type={type}
      />
      <ModalConfirm
        open={showModalConfirm}
        handleCloseModalConfirm={() => setShowModalConfirm(false)}
        onPress={() =>
          dispacth(settingAction.updateRole(access_token, id, tempSendData))
        }
      />
      <ModalSuccess
        open={showModalSuccess}
        onPress={() => router.push('/organize')}
      />
      <Grid md={12} className={classes.wrapperForm}>
        <Box className={classes.sectionHeader}>
          <Typography className={classes.headerTitle}>Edit Level</Typography>
        </Box>
        <Box className={classes.sizeBorder} />
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Grid md={12} className={classes.wrapperBody}>
            <InputGroup
              label="LevelName"
              placeholder="input Text"
              register={register}
              required
              type="text"
              isRequire={true}
            />
            <p className={classes.errorText}>{errors.LevelName?.message}</p>
            <Box className={classes.sizeTop} />
            <InputGroup
              label="Description"
              placeholder="input Text"
              register={register}
              required
              type="text"
            />
            <p className={classes.errorText}>{errors.EntityName?.message}</p>
            <Box className={classes.sizeTop} />
            <Box className={classes.headerChecklist}>
              <Typography className={classes.label}>Checklist</Typography>
              <Typography className={classes.labelGrey}>{`${
                permisionCheck
                  ? permisionCheck?.filter((dt, index) => dt.isChecked === true)
                      .length
                  : 0
              } items selected`}</Typography>
            </Box>
            <Box className={classes.sizeTop} />
            <Grid md={12} container className={classes.wrapperHeader}>
              <Grid md={5} className={classes.wrapperHeaderList}>
                <Typography className={classes.label}>Menu</Typography>
                <SortArrow />
              </Grid>
              <Grid md={5} className={classes.wrapperHeaderList}>
                <Typography className={classes.label}>Feature</Typography>
                <SortArrow />
              </Grid>
              <Grid md={2} className={classes.wrapperHeaderList}>
                <Typography className={classes.label}>Access</Typography>
              </Grid>
            </Grid>
            <Grid md={12} className={classes.wrapperListItem}>
              {permisionCheck?.map((item, index) => (
                <PermissionItem
                  item={item}
                  index={index}
                  key={index}
                  handleCheckPermission={handleCheckPermission}
                />
              ))}
            </Grid>
          </Grid>
          <Box className={classes.sizeBorder} />
          <Grid md={12} className={classes.wrapperFooter}>
            <input
              className={
                dataInput?.LevelName &&
                permisionCheck?.filter((dt, index) => dt.isChecked === true)
                  .length > 0
                  ? classes.submitBtn
                  : classes.submitBtnFalse
              }
              type="submit"
              value="Submit"
            />
            {role_updateIsLoading ? (
              <Box className={classes.loadingContent}>
                <CircleLoading />
              </Box>
            ) : (
              <Box
                className={classes.btnCancel}
                onClick={() => router.push('/organize')}
              >
                <Typography>Cancel</Typography>
              </Box>
            )}
          </Grid>
        </form>
      </Grid>
    </>
  );
};
const useStyles = makeStyles({
  wrapperForm: {
    width: '100%',
    maxWidth: 605,
    backgroundColor: '#fff',
    margin: 'auto',
    borderRadius: 8,
  },
  sectionHeader: {
    padding: 20,
  },
  headerTitle: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  sizeBorder: {
    borderBottom: '1px solid #E7E7E8',
  },
  wrapperBody: {
    padding: 20,
  },
  sizeTop: {
    marginTop: 20,
  },
  wrapperFooter: {
    padding: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  submitBtn: {
    width: 193,
    height: 50,
    borderRadius: 8,
    backgroundColor: '#1B2030',
    color: '#fff',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  submitBtnFalse: {
    width: 193,
    height: 50,
    borderRadius: 8,
    backgroundColor: '#969BAB',
    color: '#fff',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  btnCancel: {
    marginLeft: 20,
    width: 193,
    height: 50,
    borderRadius: 8,
    backgroundColor: '#fff',
    color: '#1B2030',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  // styling Form select
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 5,
    paddingBottom: 5,
    // maxWidth: 220,
    width: '100%',
    // minWidth: 160,
    height: 50,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      // marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#969BAB',
      paddingRight: 2,
      background: 'none',
    },
  },
  labelEntity: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
    marginBottom: 12,
  },
  label: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
  },
  labelGrey: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
  },
  headerChecklist: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  wrapperHeader: {
    height: 60,
    borderTop: '1px solid #E7E7E8',
    borderRight: '1px solid #E7E7E8',
    borderLeft: '1px solid #E7E7E8',
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  },
  wrapperHeaderList: {
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  wrapperListItem: {
    maxHeight: 400,
    overflow: 'auto',
    borderBottom: '1px solid #E7E7E8',
    borderRight: '1px solid #E7E7E8',
    borderLeft: '1px solid #E7E7E8',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
  },
  errorText: {
    color: 'red',
  },
  loadingContent: {
    marginLeft: 12,
  },
});

export default LevelEdit;
