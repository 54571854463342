import React from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';

const CompanyInfromation = ({ data }) => {
  const classes = useStyles();

  return (
    <>
      <Grid md={12} className={classes.wrapperVendorInformation}>
        <Typography className={classes.vendorName}>
          {data?.company ?? '-'}
        </Typography>
        <Typography className={classes.vendorAddress}>
          {data?.address ?? '-'}
        </Typography>
        <Box className={classes.wrapperContact}>
          <Grid container>
            <Grid md={3} className={classes.contactTitle}>
              Phone
            </Grid>
            <Grid md={9} className={classes.contactDesc}>
              : {data?.phone ?? '-'}
            </Grid>
          </Grid>
          <Grid container>
            <Grid md={3} className={classes.contactTitle}>
              Fax
            </Grid>
            <Grid md={9} className={classes.contactDesc}>
              : {data?.fax ?? '-'}
            </Grid>
          </Grid>
          <Grid container>
            <Grid md={3} className={classes.contactTitle}>
              CP
            </Grid>
            <Grid md={9} className={classes.contactDesc}>
              : {data?.contact_person ?? '-'}
            </Grid>
          </Grid>
          <Grid container>
            <Grid md={3} className={classes.contactTitle}>
              Npwp
            </Grid>
            <Grid md={9} className={classes.contactDesc}>
              : {data?.npwp ?? '-'}
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapperVendorInformation: {
    marginTop: 26,
    paddingTop: 27,
    paddingBottom: 24,
    paddingLeft: 25,
    backgroundColor: '#F8FAFB',
  },
  vendorName: {
    fontSize: 16,
    lineHeight: '30px',
    fontWeight: 'bold',
    color: '#000000',
  },
  vendorAddress: {
    fontSize: 16,
    lineHeight: '30px',
    color: '#000000',
  },
  wrapperContact: {
    marginTop: 17,
  },
  contactTitle: {
    fontSize: 16,
    lineHeight: '30px',
    fontWeight: 'bold',
    color: '#000000',
  },
  contactDesc: {
    fontSize: 16,
    lineHeight: '30px',
    color: '#000000',
  },
}));

export default CompanyInfromation;
