import * as types from './types';

import request from '../../utils/request';

export const getProformaData = (token, params) => (dispatch) => {
  dispatch({ type: types.PROFORMA_DATA_LOADING, payload: true });

  request({
    method: 'get',
    url: 'proforma-invoice',
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get proforma data');
      dispatch({ type: types.PROFORMA_SET_DATA, payload: res?.data });
      dispatch({ type: types.PROFORMA_DATA_MESSAGE, payload: res?.message });
      dispatch({ type: types.PROFORMA_DATA_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get data proforma', err);
      dispatch({ type: types.PROFORMA_DATA_LOADING, payload: false });
    });
};

export const getProformaDetail = (token, id) => (dispatch) => {
  dispatch({ type: types.PROFORMA_DETAIL_LOADING, payload: true });

  request({
    method: 'get',
    url: `proforma-invoice/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get proforma detail');
      dispatch({ type: types.PROFORMA_DETAIL_DATA, payload: res?.data });
      dispatch({ type: types.PROFORMA_DETAIL_MESSAGE, payload: res?.message });
      dispatch({ type: types.PROFORMA_DETAIL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get detail proforma', err);
      dispatch({ type: types.PROFORMA_DETAIL_LOADING, payload: false });
    });
};

export const reviseProforma = (token, data) => (dispatch) => {
  dispatch({ type: types.PROFORMA_REVISE_LOADING, payload: true });

  request({
    method: 'put',
    url: `approval-transaction/update`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('revise proforma');
      dispatch({ type: types.PROFORMA_REVISE_MESSAGE, payload: res?.message });
      dispatch({ type: types.PROFORMA_REVISE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error revise proforma', err);
      dispatch({ type: types.PROFORMA_REVISE_LOADING, payload: false });
    });
};

export const setBlankRevise = () => (dispatch) => {
  dispatch({ type: types.PROFORMA_REVISE_MESSAGE, payload: '' });
};

export const addProforma = (token, data) => (dispatch) => {
  dispatch({ type: types.PROFORMA_ADD_LOADING, payload: true });

  request({
    method: 'post',
    url: `/proforma-invoice/add`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-type': 'multipart/form-data',
    },
  })
    .then((res) => {
      console.log('add proforma');
      dispatch({ type: types.PROFORMA_ADD_MESSAGE, payload: res?.message });
      dispatch({ type: types.PROFORMA_ADD_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error add proforma', err);
      dispatch({
        type: types.PROFORMA_ADD_PO_MESSAGE,
        payload: err?.data?.errors?.po_id,
      });
      dispatch({
        type: types.PROFORMA_ADD_CLIENT_MESSAGE,
        payload: err?.data?.errors?.client_id,
      });
      dispatch({
        type: types.PROFORMA_ADD_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({
        type: types.PROFORMA_ADD_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: types.PROFORMA_ADD_LOADING, payload: false });
    });
};

export const setBlankAdd = () => (dispatch) => {
  dispatch({ type: types.PROFORMA_ADD_MESSAGE, payload: '' });
};

export const deleteProforma = (token, id) => (dispatch) => {
  dispatch({ type: types.PROFORMA_DELETE_LOADING, payload: true });

  request({
    method: 'delete',
    url: `proforma-invoice/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('delete proforma');
      console.log(res);
      dispatch({ type: types.PROFORMA_DELETE_MESSAGE, payload: res?.message });
      dispatch({ type: types.PROFORMA_DELETE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error delete proforma', err);
      dispatch({
        type: types.PROFORMA_DELETE_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: types.PROFORMA_DELETE_LOADING, payload: false });
    });
};

export const setBlankDelete = () => (dispatch) => {
  dispatch({ type: types.PROFORMA_DELETE_MESSAGE, payload: '' });
};

export const cancleProforma = (token, id) => (dispatch) => {
  dispatch({ type: types.PROFORMA_CANCEL_LOADING, payload: true });

  request({
    method: 'put',
    url: `proforma-invoice/${id}/cancel`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('cancel proforma');
      dispatch({ type: types.PROFORMA_CANCEL_MESSAGE, payload: res?.message });
      dispatch({ type: types.PROFORMA_CANCEL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error cancel proforma', err);
      dispatch({ type: types.PROFORMA_CANCEL_LOADING, payload: false });
    });
};

export const setBlankCancel = () => (dispatch) => {
  dispatch({ type: types.PROFORMA_CANCEL_MESSAGE, payload: '' });
};

export const addProformaSecond = (token, paramData) => (dispatch) => {
  dispatch({ type: types.PROFORMA_ADD_LOADING, payload: true });

  let data = new FormData();
  data.append('po_id', paramData.po_id);
  data.append('client_id', paramData.client_id);
  data.append('amount', paramData.amount);
  data.append('invoice_num', paramData.invoice_num);
  data.append('invoice_date', paramData.invoice_date);
  if (paramData.description) {
    data.append('description', paramData.description);
  }
  paramData.file.map((img, index) => {
    data.append('file[]', img);
  });

  request({
    method: 'post',
    url: `proforma-invoice/add`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('add proforma');
      dispatch({ type: types.PROFORMA_ADD_MESSAGE, payload: res?.message });
      dispatch({ type: types.PROFORMA_ADD_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error add proforma', err);

      dispatch({
        type: types.PROFORMA_ADD_PO_MESSAGE,
        payload: err?.data?.errors?.po_id,
      });
      dispatch({
        type: types.PROFORMA_ADD_CLIENT_MESSAGE,
        payload: err?.data?.errors?.client_id,
      });
      dispatch({
        type: types.PROFORMA_ADD_MESSAGE,
        payload: err?.data?.message,
      });

      dispatch({ type: types.PROFORMA_ADD_LOADING, payload: false });
    });
};

export const setBlankAddSecond = () => (dispatch) => {
  dispatch({ type: types.PROFORMA_ADD_MESSAGE, payload: '' });
};
export const setBlankAddPO = () => (dispatch) => {
  dispatch({ type: types.PROFORMA_ADD_PO_MESSAGE, payload: '' });
};
export const setBlankAddClient = () => (dispatch) => {
  dispatch({ type: types.PROFORMA_ADD_CLIENT_MESSAGE, payload: '' });
};

export const updateProformaVendor = (token, id, data) => (dispatch) => {
  dispatch({ type: types.PROFORMA_UPDATE_VENDOR_LOADING, payload: true });

  request({
    method: 'post',
    url: `proforma-invoice/${id}/update`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: types.PROFORMA_UPDATE_VENDOR_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: types.PROFORMA_UPDATE_VENDOR_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error update proforma vendor', err);
      dispatch({
        type: types.PROFORMA_UPDATE_VENDOR_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: types.PROFORMA_UPDATE_VENDOR_LOADING, payload: false });
    });
};

export const setBlankUpdateVendor = () => (dispatch) => {
  dispatch({
    type: types.PROFORMA_UPDATE_VENDOR_MESSAGE,
    payload: '',
  });
};

export const approvalProforma = (token, id, data) => (dispatch) => {
  dispatch({ type: types.PROFORMA_APPROVAL_LOADING, payload: true });

  request({
    method: 'post',
    url: `proforma-invoice/${id}/approval`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: types.PROFORMA_APPROVAL_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: types.PROFORMA_APPROVAL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error approval proforma ', err);
      dispatch({
        type: types.PROFORMA_APPROVAL_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: types.PROFORMA_APPROVAL_LOADING, payload: false });
    });
};

export const setBlankApproval = () => (dispatch) => {
  dispatch({
    type: types.PROFORMA_APPROVAL_MESSAGE,
    payload: '',
  });
};

export const updateProforma = (token, id, data) => (dispatch) => {
  dispatch({ type: types.PROFORMA_UPDATE_VENDOR_LOADING, payload: true });

  request({
    method: 'post',
    url: `proforma-invoice/${id}/update`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-type': 'multipart/form-data',
    },
  })
    .then((res) => {
      dispatch({
        type: types.PROFORMA_UPDATE_VENDOR_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: types.PROFORMA_UPDATE_VENDOR_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error approval proforma ', err);
      dispatch({
        type: types.PROFORMA_UPDATE_VENDOR_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: types.PROFORMA_UPDATE_VENDOR_LOADING, payload: false });
    });
};

export const setBlankUpdate = () => (dispatch) => {
  dispatch({
    type: types.PROFORMA_UPDATE_VENDOR_MESSAGE,
    payload: '',
  });
};

export const uploadProforma = (token, id, data) => (dispatch) => {
  dispatch({ type: types.PROFORMA_UPLOAD_LOADING, payload: true });

  request({
    method: 'post',
    url: `proforma-invoice/${id}/bast`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-type': 'multipart/form-data',
    },
  })
    .then((res) => {
      dispatch({
        type: types.PROFORMA_UPLOAD_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: types.PROFORMA_UPLOAD_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error upload dkt proforma ', err);
      dispatch({
        type: types.PROFORMA_UPLOAD_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: types.PROFORMA_UPLOAD_LOADING, payload: false });
    });
};

export const setBlankUpload = () => (dispatch) => {
  dispatch({
    type: types.PROFORMA_UPLOAD_MESSAGE,
    payload: '',
  });
};

export const approvalProformaBast = (token, id, data) => (dispatch) => {
  dispatch({ type: types.PROFORMA_APPROVAL_BAST_LOADING, payload: true });

  request({
    method: 'post',
    url: `proforma-invoice/${id}/bast-approval`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: types.PROFORMA_APPROVAL_BAST_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: types.PROFORMA_APPROVAL_BAST_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error approval bast proforma ', err);
      dispatch({
        type: types.PROFORMA_APPROVAL_BAST_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: types.PROFORMA_APPROVAL_BAST_LOADING, payload: false });
    });
};

export const setBlankApprovalBast = () => (dispatch) => {
  dispatch({
    type: types.PROFORMA_APPROVAL_BAST_MESSAGE,
    payload: '',
  });
};

export const processGRPO = (token, id, data) => (dispatch) => {
  dispatch({ type: types.PROFORMA_PROCESS_GRPO_LOADING, payload: true });

  request({
    method: 'post',
    url: `proforma-invoice/${id}/proceed-grpo`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({
        type: types.PROFORMA_PROCESS_GRPO_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: types.PROFORMA_PROCESS_GRPO_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error process grpo proforma ', err);
      dispatch({
        type: types.PROFORMA_PROCESS_GRPO_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: types.PROFORMA_PROCESS_GRPO_LOADING, payload: false });
    });
};

export const setBlankProcessGRPO = () => (dispatch) => {
  dispatch({
    type: types.PROFORMA_PROCESS_GRPO_MESSAGE,
    payload: '',
  });
};
