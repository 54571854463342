import React from 'react';
import { Box, Grid, Typography, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from '../../utils/useRouter';
import * as yup from 'yup';
import request from '../../utils/request';

// Component
import InputEmail from '../InputEmail';
import MessageAuth from '../MessageAuth';
import CustomizedSnackbars from '../PopupMessage';

const schema = yup.object().shape({
  Email: yup.string().required(),
});

const ForgotForm = (props) => {
  const router = useRouter();
  const classes = useStyle();

  const [showMessage, setShowMassege] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
    setNotifText('');
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    // alert(JSON.stringify(data));
    request({
      method: 'post',
      url: 'auth/forgot-password',
      data: {
        email: data.Email,
      },
    })
      .then((res) => {
        console.log('----- try reset password -------');
        setShowMassege(true);
        setEmail(data.Email);
      })
      .catch((err) => {
        setType('error');
        setShow(true);
        setNotifText(err.message);
      });
  };

  const onResend = () => {
    // alert(JSON.stringify(data));
    request({
      method: 'post',
      url: 'auth/forgot-password',
      data: {
        email: email,
      },
    })
      .then((res) => {
        console.log('----- try reset password -------');
        // setShowMassege(true);
        setType('success');
        setShow(true);
        setNotifText('Email resend success');
      })
      .catch((err) => {
        setType('error');
        setShow(true);
        setNotifText(err.data.message);
      });
  };

  const handleLinkLogin = () => {
    router.push('/login');
  };

  // const handleLinkEmail = () => {
  //   hr
  // }

  return (
    <Box className={classes.wrapperForm}>
      <Grid container direction="column">
        {!showMessage ? (
          <>
            <Typography
              className={classes.titlePage}
              variant="body1"
              component="p"
            >
              Forgot password
            </Typography>
            <Typography
              className={classes.subtitle}
              variant="body1"
              component="p"
            >
              Enter your registered email below, we will send an email with
              instruction s yo reset your password.
            </Typography>
            <Box className={classes.inputBoxes} />
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputEmail
                label="Email"
                placeholder="Your email address"
                register={register}
                required
                type="text"
                error={errors.Username ? 'error' : null}
              />
              <p className={classes.errorText}>{errors.Email?.message}</p>
              <input
                className={classes.submitBtn}
                type="submit"
                value="Send me an email"
              />
            </form>
            <Box className={classes.wrapperLink}>
              <Typography
                className={classes.checkText}
                variant="body1"
                component="p"
              >
                Remember password?
              </Typography>
              <a className={classes.linkText} onClick={handleLinkLogin}>
                Login
              </a>
            </Box>
            <Box className={classes.wrapperLinkEmail}>
              <Typography
                className={classes.checkText}
                variant="body1"
                component="p"
              >
                Need help?
              </Typography>
              <a
                className={classes.linkText}
                href="mailto:devops@dktindonesia.org"
                onclick="window.location.href='np.html'"
              >
                Contact us
              </a>
            </Box>
          </>
        ) : (
          <MessageAuth reset={false} onResend={onResend} />
        )}
        <CustomizedSnackbars
          show={show}
          handleClose={handleClose}
          message={notifText}
          type={type}
        />
      </Grid>
    </Box>
  );
};

const useStyle = makeStyles({
  wrapperForm: {
    width: 359,
    backgroundColor: '#fff',
    marginTop: 49,
    paddingTop: 31,
    paddingBottom: 31,
    paddingLeft: 20,
    paddingRight: 20,
    border: '1px solid #E7E7E8',
    borderRadius: 16,
  },
  titlePage: {
    fontWeight: 'bold',
    color: '#1B2030',
    fontSize: 21,
    lineHeight: '32px',
    textAlign: 'center',
  },
  subtitle: {
    marginTop: 10,
    fontSize: 16,
    lineHeight: '26px',
    fontFamily: 'Roboto, sans-serif',
    color: '#969BAB',
    textAlign: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    letterSpacing: '0.01em',
  },
  inputBoxes: {
    marginTop: 30,
  },
  submitBtn: {
    width: '100%',
    height: 50,
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    backgroundColor: '#1B2030',
    borderRadius: 8,
    borderWidth: 0,
    marginTop: 20,
  },
  wrapperLink: {
    marginTop: 38,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapperLinkEmail: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
  },
  checkText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
  },
  linkText: {
    marginLeft: 10,
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
    textDecorationLine: 'underline',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  errorText: {
    fontSize: 14,
    color: 'red',
  },
});

export default ForgotForm;
