import React from 'react';
import { makeStyles, Grid, Typography, Box } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import axios from 'axios';
import { END_POINT } from '../../config/EndPoint';
import { useRouter } from '../../utils/useRouter';
import Select from '@material-ui/core/Select';
import { useSelector, useDispatch } from 'react-redux';
import * as actions_2 from '../../redux/Utility/actions';
import * as actions from '../../redux/ClientVendor/actions';
import { dateFormater } from '../../utils/formatter';

// Components
import Buttonsubmit from '../Button/ButtonSubmit';
import InputGroup from '../inputGroup';
import { ContactPerson } from './components/ContactPerson';
import { PaymentInformation } from './components/PaymentInformation';
import CustomizedSnackbars from '../PopupMessage';
import ModalSingleUpload from '../ModalSingleUpload';
import ModalConfirmVendor from '../ModalConfirmVendor';
import ModalSuccess from '../ModalSuccess';

// Icons
import UnduhSecond from '../../assets/Icons/unduhSecond.png';
import Trash from '../../assets/Icons/trash.png';
// Svg
import { DownArrow } from '../../assets/Icons/svg/DownArrow';
import { SortArrow } from '../../assets/Icons/svg/SortArrow';
import Plus from '../../assets/Icons/plus.png';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup.object().shape({
  Name: yup.string().required(),
  CodeNo: yup.string().required(),
  Address: yup.string().required(),
  Email: yup.string().email('Must be a valid email').required(),
  Fax: yup.string().matches(phoneRegExp, 'FAX number is not valid').required(),
  NPWP: yup
    .string()
    .matches(phoneRegExp, 'NPWP number is not valid')
    .required(),
  Phone: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required(),
});

const EditForm = () => {
  const classes = useStyles();
  const dispacth = useDispatch();
  const router = useRouter();
  const { id } = router.query;

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Global Variable
  const { access_token } = useSelector((state) => state.auth);
  const { bank_data, groupcode_data, entity_data } = useSelector(
    (state) => state.utility
  );
  const { message, detailData, detailIsloading, isLoading } = useSelector(
    (state) => state.clientVendor
  );

  const watchAllFields = watch(); // when pass nothing as argument, you are watching everything

  const [dataInput, setDataInput] = React.useState(null);
  const [categoryFilter, setCategoryFilter] = React.useState(0);
  const [groupcodeFilter, setGroupcodeFilter] = React.useState(0);
  const [btnDisable, setBtnDisable] = React.useState(false);
  const [entityFilter, setEntityFilter] = React.useState(0);

  const [tempSendData, setTempSendData] = React.useState(null);
  const [showModalConfirm, setShowModalConfirm] = React.useState(false);
  const [showModalSuccess, setShowModalSuccess] = React.useState(false);

  const onSubmit = (data) => {
    // console.log(data);
    const { Name, Address, Email, Fax, NPWP, Phone, CodeNo } = data;

    // check null for input contacts
    let checkContactName = inputContacts.map((contact) => contact.name == '');
    let checkContactPosition = inputContacts.map(
      (contact) => contact.position == ''
    );
    let checkContactPhone = inputContacts.map((contact) => contact.phone == '');
    let checkContactEmail = inputContacts.map((contact) => contact.email == '');
    let checkContactKTP = inputContacts.map((contact) => contact.ktp == '');

    // check if contact phone and ktp have a alpabet
    let checkStringContactPhone = inputContacts.map((contact) =>
      /[a-zA-Z]/.test(contact.phone)
    );
    let checkStringContactKTP = inputContacts.map((contact) =>
      /[a-zA-Z]/.test(contact.ktp)
    );

    // check if email not valid
    let checkStringContactEmail = inputContacts.map((contact) =>
      /\S+@\S+\.\S+/.test(contact.email)
    );

    //  check null for input Payment
    let checkPaymentNumber = inputPayments.map(
      (payment) => payment.number == ''
    );
    let checkPaymentBank = inputPayments.map(
      (payment) => payment.bank_id == '0'
    );

    if (categoryFilter === 0 || groupcodeFilter === 0) {
      setType('error');
      setShow(true);
      setNotifText('Check your category and group code field');
    } else if (
      checkContactName.includes(true) ||
      checkContactPosition.includes(true) ||
      checkContactPhone.includes(true) ||
      checkContactEmail.includes(true) 
    ) {
      setType('error');
      setShow(true);
      setNotifText('Check your contact person field, contact cannot be null');
    } else if (
      checkStringContactPhone.includes(true) 
    ) {
      setType('error');
      setShow(true);
      setNotifText(
        'Check your contact person ktp or phone, field must be numeric'
      );
    } else if (checkStringContactEmail.includes(false)) {
      setType('error');
      setShow(true);
      setNotifText('Check your contact person email, must be valid email');
    } 
    // else if (
    //   checkPaymentBank.includes(true) ||
    //   checkPaymentNumber.includes(true)
    // ) {
    //   setType('error');
    //   setShow(true);
    //   setNotifText(
    //     'Check your payment information, payment information cannot be null'
    //   );
    // } 
    // else if (documentUploadId.length !== inputContacts.length + 1) {
    //   setType('error');
    //   setShow(true);
    //   setNotifText(
    //     'There is file must be upload, Please Check your upload file'
    //   );
    // } 
    else {
      // console.log('validating complete');
      // console.log(documentUploadId);
      let paymentArr = [];
      inputPayments.map((pay, index) => {
        let temp = {
          number: pay.number,
          bank_id: pay.bank_id,
        };

        if(temp.bank_id === "0"){
          temp.bank_id = null
        }
        
        if(pay.id) temp.id = pay.id

        paymentArr.push(temp);
      });

      let fileArr = [];
      documentUploadId.map((doc, index) => {
        let temp = {
          id: doc.id,
        };
        fileArr.push(temp);
      });

      let params = {
        id: detailData?.client?.id,
        name: Name,
        address: Address,
        email: Email,
        phone: Phone,
        fax: Fax,
        npwp: NPWP,
        status: 'valid',
        category: categoryFilter,
        group_code_id: groupcodeFilter,
        entity_id: entityFilter,
        vendor_code: CodeNo,
        cp: inputContacts,
        payment_info: paymentArr,
        file: fileArr,
      };

      if (entityFilter === 0) delete params.entity_id;

      // console.log(params);
      setTempSendData(params);
      setShowModalConfirm(true);
      // dispacth(actions.sendClientVendor(access_token, params));
    }
  };

  const sendClientVendor = () => {
    // console.log(tempSendData);
    dispacth(actions.sendClientVendor(access_token, tempSendData));
  };

  // this for handle contact person
  const [inputContacts, setInputContact] = React.useState([
    {
      name: '',
      position: '',
      phone: '',
      email: '',
      ktp: '',
    },
  ]);

  const handleChangeInput = (index, event) => {
    const values = [...inputContacts];
    values[index][event.target.name] = event.target.value;
    setInputContact(values);
  };

  const handleAddInput = () => {
    setInputContact([
      ...inputContacts,
      {
        name: '',
        position: '',
        phone: '',
        email: '',
        ktp: '',
      },
    ]);
  };

  const handleRemoveInput = (index) => {
    if (inputContacts.length < 2) {
      setType('error');
      setShow(true);
      setNotifText('you must enter minimum 1 contact person');
    } else {
      const values = [...inputContacts];
      values.splice(index, 1);
      setInputContact(values);
    }
  };

  // end handle contact person

  // this for handle Payment information
  const [inputPayments, setInputPayment] = React.useState([
    { number: '', bank_id: '0', bank_name: '', bank_avatar: '' },
  ]);

  const handleChangeInputPayment = (index, event) => {
    const values = [...inputPayments];
    values[index][event.target.name] = event.target.value;
    setInputPayment(values);
  };

  const handleAddPayment = () => {
    setInputPayment([
      ...inputPayments,
      { number: '', bank_id: '0', bank_name: '', bank_avatar: '' },
    ]);
  };

  const handleRemovePayment = (index) => {
    if (inputPayments.length < 2) {
      setType('error');
      setShow(true);
      setNotifText('you must enter minimum 1 bank account');
    } else {
      const values = [...inputPayments];
      values.splice(index, 1);
      setInputPayment(values);
    }
  };

  // end handle payment information

  // Handle Search bank name

  const [vendor, setVendor] = React.useState({ name: '' });
  // const [isVendorFocus, setIsVendorFocus] = React.useState(false);
  // const [showVendor, setShowVendor] = React.useState(false);
  const [vendorData, setVendorData] = React.useState(null);
  const [searchText, setSearchText] = React.useState('');

  const selectVendor = (data, index) => {
    setVendor(data);

    const values = [...inputPayments];
    values[index]['bank_id'] = data.id;
    values[index]['bank_name'] = data.name;
    values[index]['bank_avatar'] = data.avatar_url;
    setInputPayment(values);

    // setIsVendorFocus(false);
    // setShowVendor(false);
    setSearchText('');
  };

  React.useEffect(() => {
    if (searchText !== '') {
      let TempArr = [];
      vendorData?.map((vendor, index) => {
        let tempName = vendor.name.toLowerCase();
        if (tempName.includes(searchText.toLocaleLowerCase())) {
          TempArr.push(vendor);
        }
      });
      setVendorData(TempArr);
    } else {
      setVendorData(bank_data);
    }
  }, [searchText]);

  // end search bank name

  // handle for upload file
  const [showModalSingleUpload, setShowModalSingleUpload] =
    React.useState(false);

  const handleCloseModalSingleUpload = () => {
    setShowModalSingleUpload(false);
  };

  const [imageColletion, setImageCollection] = React.useState([]);
  const [uploadLoading, setUploadLoading] = React.useState(false);
  const [percent, setPercent] = React.useState(0);
  const [totalFile, setTotalFile] = React.useState(0);
  const [uploadedFile, setUploadedFile] = React.useState(0);
  const [documentUploadId, setDocumentUploadId] = React.useState([]);

  const onFileChange = (e) => {
    let tempFile = e.target.files;
    // console.log(tempFile);

    setImageCollection((prev) => [...prev, ...tempFile]);
  };

  const handleRemoveFile = (indexOf) => {
    let filteImage = imageColletion.filter((img, index) => index !== indexOf);

    setImageCollection(filteImage);
  };

  const handleUploadDocumentId = (doc) => {
    setDocumentUploadId((prev) => [
      ...prev,
      { id: doc.id, fileName: doc.file_name, url: doc.url },
    ]);
  };

  const handleRemoveUploadDocumentId = (index) => {
    const values = [...documentUploadId];
    values.splice(index, 1);
    setDocumentUploadId(values);
  };

  const handleUploadFile = () => {
    if (imageColletion) {
      try {
        setUploadLoading(true);

        const sendData = new FormData();
        imageColletion.map((img, index) => {
          sendData.append('file[]', img);
        });

        const config = {
          headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${access_token}`,
          },
          onUploadProgress: (progressEvent) => {
            var percentComplete = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setPercent(percentComplete);
          },
        };

        axios
          .post(
            `${END_POINT}files?doc_type=${'Client'}&doc_id=${0}`,
            sendData,
            config
          )
          .then((res) => {
            console.log(res);
            setPercent(0);
            setUploadLoading(false);
            handleUploadDocumentId(res?.data?.data[1]);
            // setShowModalUpload(false);
            setImageCollection([]);
            setShowModalSingleUpload(false);
          });
      } catch (error) {
        console.log('error upload file');
        setUploadLoading(false);
      }
      // dispacth(actions_2.uploadFile(access_token, sendData));
    } else {
      console.log('chosses an image');
    }
  };

  // end handle upload file

  // handle message
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };
  // end handle message

  const handleCloseModalConfirm = () => {
    setShowModalConfirm(false);
  };

  const handleEntityFilter = (event) => {
    setEntityFilter(event.target.value);
  };

  const handleChangeCategory = (event) => {
    setCategoryFilter(event.target.value);
  };

  const handleChangeGroupCode = (event) => {
    setGroupcodeFilter(event.target.value);
  };

  const handleSeeImage = (url) => {
    window.open(url);
  };

  // handle Detect input form
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      // console.log(value, name, type)
      setDataInput(value)
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  React.useEffect(() => {
    dispacth(actions_2.getBank(access_token));
    dispacth(actions_2.getDistributorGroupCode(access_token));
    dispacth(actions_2.getEntity(access_token));
    dispacth(actions.getDetailClientVendor(access_token, id));
  }, []);

  React.useEffect(() => {
    setValue('Name', detailData?.client?.name);
    setValue('Address', detailData?.client?.address);
    setValue('Email', detailData?.client?.email);
    setValue('Phone', detailData?.client?.phone);
    setValue('Fax', detailData?.client?.fax);
    setValue('NPWP', detailData?.client?.npwp);
    setValue('CodeNo', detailData?.client?.vendor_code);

    setCategoryFilter(detailData?.client?.category);
    setGroupcodeFilter(detailData?.client?.group_code?.id);
    setEntityFilter(detailData?.client?.entity_id);

    // set Contact
    let contactArr = [];
    detailData?.client?.cp_client?.map((contact, index) => {
      let tempContact = {
        name: contact?.name,
        position: contact?.position,
        phone: contact?.phone,
        email: contact?.email,
        ktp: contact?.ktp,
        id: contact.id,
      };
      contactArr.push(tempContact);
    });

    setInputContact(contactArr);
    // set input payment
    let payArr = [];
    detailData?.client?.payment_info?.map((pay, index) => {
      let payTemp = {
        number: pay?.number,
        bank_id: pay?.bank_id,
        bank_name: pay?.bank?.name ?? '-',
        bank_avatar: pay?.bank?.avatar_url ?? '-',
        id: pay?.id
      };
      payArr.push(payTemp);
    });
    setInputPayment(payArr);

    // set File
    let fileArr = [];
    detailData?.client?.files?.map((file, index) => {
      let tempFile = {
        id: file?.id,
        fileName: file?.file_name,
        url: file?.url,
      };
      fileArr.push(tempFile);
    });

    setDocumentUploadId(fileArr);
  }, [detailData]);

  React.useEffect(() => {
    // setVendorData(vendorRows);
    setVendorData(bank_data);
  }, [bank_data]);

  React.useEffect(() => {
    if (
      dataInput?.Name !== '' &&
      dataInput?.Address !== '' &&
      dataInput?.Email !== '' &&
      dataInput?.Fax !== '' &&
      dataInput?.NPWP &&
      dataInput?.Phone
    ) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [dataInput]);

  React.useEffect(() => {
    if (message) {
      if (message === 'Save client Success') {
        setShowModalSuccess(true);
      } else if (message !== '' && message !== 'Save client Success') {
        setType('error');
        setShow(true);
        setNotifText(message);
      }
      dispacth(actions.setBlankCreate());
    }
  }, [message]);

  const _renderStatus = (status) => {
    if (status === 'invalid') {
      return (
        <Typography className={classes.titleStatusInvalid}>invalid</Typography>
      );
    } else {
      return (
        <Typography className={classes.titleStatusValid}>valid</Typography>
      );
    }
  };

  return (
    <>
      <CustomizedSnackbars
        show={show}
        handleClose={handleClose}
        message={notifText}
        type={type}
      />

      <ModalSingleUpload
        open={showModalSingleUpload}
        close={handleCloseModalSingleUpload}
        onPress={handleUploadFile}
        isLoading={uploadLoading}
        imageColletion={imageColletion}
        handleRemoveFile={handleRemoveFile}
        onFileChange={onFileChange}
        uploadPercent={percent}
      />
      <ModalConfirmVendor
        open={showModalConfirm}
        close={handleCloseModalConfirm}
        onPress={sendClientVendor}
        isLoading={isLoading}
      />
      <ModalSuccess
        open={showModalSuccess}
        onPress={() => router.push('/client-vendor')}
      />
      <Grid md={12} className={classes.mainContainer}>
        <Grid md={12} className={classes.Content} container>
          <Grid md={6}>
            <Typography
              className={classes.titleScreen}
              // onClick={() => setShowModalConfirm(true)}
            >
              {`ID. ${detailData?.client?.id}`}
            </Typography>
          </Grid>
          <Grid md={6} className={classes.wrapperTitleStatus}>
            <Typography className={classes.titleDate}>
              {dateFormater(detailData?.client?.created_at, 'DD-MM-YYYY hh:mm')}
            </Typography>
            {_renderStatus(detailData?.client?.status)}
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className={classes.sizeBorder} />
          <Grid md={3} className={classes.wrapperEntity}>
            <Typography className={classes.labelText}>Category</Typography>
            <Box className={classes.selectContainerLeft}>
              <FormControl className={classes.formControl}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={entityFilter}
                  onChange={handleEntityFilter}
                  IconComponent={() => <DownArrow />}
                >
                  {entity_data?.map((ent, index) => (
                    <MenuItem key={index} value={ent.id}>
                      <Typography
                        className={classes.bodyDataText}
                        variant="body1"
                        component="p"
                      >
                        {ent.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid md={12} className={classes.Content}>
            <Typography className={classes.textVendor}>
              Vendor Information
            </Typography>
            {/* render content first */}
            <Grid md={12} container className={classes.bodyCotent}>
              <Grid md={6} container>
                <Grid md={6} item className={classes.inputPadRight}>
                  <InputGroup
                    label="CodeNo"
                    placeholder="Enter Code"
                    register={register}
                    required
                    type="text"
                    // disable={true}
                  />
                  <p className={classes.errorText}>{errors.CodeNo?.message}</p>
                </Grid>
                <Grid md={6} item className={classes.inputPadRightLeft}>
                  <InputGroup
                    label="Name"
                    placeholder="Enter Name"
                    register={register}
                    required
                    type="text"
                  />
                  <p className={classes.errorText}>{errors.Name?.message}</p>
                </Grid>
              </Grid>
              <Grid md={6} item className={classes.inputPadLeft}>
                <InputGroup
                  label="Address"
                  placeholder="Enter Address"
                  register={register}
                  required
                  type="text"
                />
                <p className={classes.errorText}>{errors.Address?.message}</p>
              </Grid>
            </Grid>
            {/* render conten second */}
            <Grid md={12} container className={classes.bodyCotent}>
              <Grid md={6} container>
                <Grid md={6} className={classes.inputPadRight} item>
                  <InputGroup
                    label="Email"
                    placeholder="Enter Email"
                    register={register}
                    required
                    type="text"
                  />
                  <p className={classes.errorText}>{errors.Email?.message}</p>
                </Grid>
                <Grid md={6} className={classes.inputPadRightLeft} item>
                  <InputGroup
                    label="Phone"
                    placeholder="Enter Phone"
                    register={register}
                    required
                    type="text"
                  />
                  <p className={classes.errorText}>{errors.Phone?.message}</p>
                </Grid>
              </Grid>
              <Grid md={6} container>
                <Grid md={6} item className={classes.inputPadRightLeft}>
                  <InputGroup
                    label="Fax"
                    placeholder="Enter Fax"
                    register={register}
                    required
                    type="text"
                  />
                  <p className={classes.errorText}>{errors.Fax?.message}</p>
                </Grid>
                <Grid md={6} item className={classes.inputPadLeft}>
                  <InputGroup
                    label="NPWP"
                    placeholder="Enter NPWP"
                    register={register}
                    required
                    type="text"
                    // disable={true}
                  />
                  <p className={classes.errorText}>{errors.NPWP?.message}</p>
                </Grid>
              </Grid>
            </Grid>
            <Grid md={12} container className={classes.bodyCotent}>
              <Grid md={6} item>
                <Typography className={classes.labelText}>Category</Typography>
                <Box className={classes.selectContainerLeft}>
                  <FormControl className={classes.formControl}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={categoryFilter}
                      onChange={handleChangeCategory}
                      IconComponent={() => <DownArrow />}
                    >
                      <MenuItem value={0}>Select</MenuItem>
                      <MenuItem value={'distributor'}>
                        <Typography className={classes.bodyDataText}>
                          Distributor
                        </Typography>
                      </MenuItem>
                      <MenuItem value={'supplier'}>
                        <Typography className={classes.bodyDataText}>
                          Supplier
                        </Typography>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid md={6} item>
                <Typography className={classes.labelText}>
                  Group Code
                </Typography>
                <Box className={classes.selectContainerRight}>
                  <FormControl className={classes.formControl}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={groupcodeFilter}
                      onChange={handleChangeGroupCode}
                      IconComponent={() => <DownArrow />}
                    >
                      <MenuItem value={0}>Select</MenuItem>
                      {groupcode_data?.distributorgc?.map((code, index) => (
                        <MenuItem value={code.id}>
                          <Typography className={classes.bodyDataText}>
                            {code.name}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid md={12} className={classes.bodyContact}>
            <Typography className={classes.textVendor}>
              Contact Person
            </Typography>
            <Grid md={12} container className={classes.formContact}>
              <Grid md={12} container className={classes.inputContent}>
                <Grid md>
                  <Typography className={classes.labelText}>Name</Typography>
                </Grid>
                <Grid md>
                  <Typography className={classes.labelText}>
                    Position
                  </Typography>
                </Grid>
                <Grid md>
                  <Typography className={classes.labelText}>
                    Mobile Phone
                  </Typography>
                </Grid>
                <Grid md>
                  <Typography className={classes.labelText}>Email</Typography>
                </Grid>
                <Grid md>
                  <Typography className={classes.labelText}>KTP</Typography>
                </Grid>
              </Grid>
            </Grid>
            {inputContacts?.map((inputContact, index) => (
              <ContactPerson
                inputContact={inputContact}
                index={index}
                handleChangeInput={handleChangeInput}
                handleRemoveInput={handleRemoveInput}
              />
            ))}
            <Box className={classes.btnAddContact} onClick={handleAddInput}>
              <img src={Plus} />
              <Typography className={classes.textDocumentBlue}>
                Add more contact
              </Typography>
            </Box>
          </Grid>
          <Grid md={12} className={classes.bodyPayment}>
            <Typography className={classes.textVendor}>
              Payment Information
            </Typography>
            <Grid md={12} container>
              <Grid md={6} item>
                <Typography className={classes.textLabel}>
                  Account No.
                </Typography>
              </Grid>
              <Grid md={6} item>
                <Typography className={classes.textLabel}>Bank Name</Typography>
              </Grid>
            </Grid>
            {inputPayments?.map((inputPayment, index) => (
              <PaymentInformation
                inputPayment={inputPayment}
                index={index}
                handleChangeInputPayment={handleChangeInputPayment}
                handleRemovePayment={handleRemovePayment}
                // configure search bank name
                // setIsVendorFocus={setIsVendorFocus}
                // isVendorFocus={isVendorFocus}
                // showVendor={showVendor}
                // setShowVendor={setShowVendor}
                setSearchText={setSearchText}
                vendorData={vendorData}
                searchText={searchText}
                vendor={vendor}
                selectVendor={selectVendor}
              />
            ))}
            <Box className={classes.btnAddContact} onClick={handleAddPayment}>
              <img src={Plus} />
              <Typography className={classes.textDocumentBlue}>
                Add more account
              </Typography>
            </Box>
          </Grid>
          {/* Section upload */}
          <Grid md={12} className={classes.bodyUpload}>
            <Typography className={classes.textVendor}>Attactment</Typography>
            <Grid md={12} className={classes.uploadContent}>
              <Box className={classes.wrapperUpload}>
                <Box className={classes.fileUploadContent}>
                  <Typography className={classes.textFile}>
                    NPWP (max. 2MB)
                  </Typography>
                  <Typography className={classes.textFileRed}>*</Typography>
                </Box>
                {documentUploadId[0] ? (
                  <Box className={classes.wrapperFilename}>
                    <Box
                      className={classes.textFileName}
                      onClick={() => handleSeeImage(documentUploadId[0].url)}
                    >
                      <Typography>{documentUploadId[0].fileName}</Typography>
                    </Box>
                    <img
                      src={Trash}
                      onClick={() => handleRemoveUploadDocumentId(0)}
                    />
                  </Box>
                ) : (
                  <Box
                    className={classes.btnUpload}
                    onClick={() => setShowModalSingleUpload(true)}
                  >
                    <img src={UnduhSecond} />
                    <Typography className={classes.btnUploadTex}>
                      Attach File
                    </Typography>
                  </Box>
                )}
              </Box>
              {inputContacts.map((_, index) => (
                <Box className={classes.wrapperUpload}>
                  <Box className={classes.fileUploadContent}>
                    <Typography className={classes.textFile}>
                      KTP Contact Person (max. 2MB)
                    </Typography>
                    <Typography className={classes.textFileRed}>*</Typography>
                  </Box>
                  {documentUploadId[index + 1] ? (
                    <Box className={classes.wrapperFilename}>
                      <Typography className={classes.textFileName}>
                        {documentUploadId[index + 1].fileName}
                      </Typography>
                      <img
                        src={Trash}
                        onClick={() => handleRemoveUploadDocumentId(index + 1)}
                      />
                    </Box>
                  ) : (
                    <Box
                      className={classes.btnUpload}
                      onClick={() => setShowModalSingleUpload(true)}
                    >
                      <img src={UnduhSecond} />
                      <Typography className={classes.btnUploadTex}>
                        Attach File
                      </Typography>
                    </Box>
                  )}
                </Box>
              ))}
            </Grid>
            <Typography className={classes.noteUpload}>
              * make sure the order in uploading files is correct, if you are
              wrong upload file please remove all file first , then upload again
            </Typography>
          </Grid>
          <Box className={classes.sizeBorder} />
          <Grid md={12} className={classes.Content}>
            <Buttonsubmit
              isOn={btnDisable}
              onPress={() => console.log('submit')}
            />
          </Grid>
        </form>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: '#fff',
    borderRadius: 8,
  },
  Content: {
    padding: 20,
  },
  titleScreen: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#1B2030',
  },
  wrapperTitleStatus: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'right',
  },
  titleDate: {
    fontSize: 14,
    lineHeight: '26px',
    color: '#1B2030',
  },
  titleStatusValid: {
    marginLeft: 8,
    textTransform: 'uppercase',
    backgroundColor: '#27AE60',
    borderRadius: 100,
    paddingLeft: 8,
    paddingRight: 8,
    color: '#fff',
    fontSize: 12,
    lineHeight: '26px',
    fontWeight: 'bold',
  },
  titleStatusInvalid: {
    marginLeft: 8,
    textTransform: 'uppercase',
    backgroundColor: '#969BAB',
    borderRadius: 100,
    paddingLeft: 8,
    paddingRight: 8,
    color: '#fff',
    fontSize: 12,
    lineHeight: '26px',
    fontWeight: 'bold',
  },

  sizeBorder: {
    borderTop: '1px solid #E7E7E8',
  },
  textVendor: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    fontWeight: 'bold',
  },
  bodyCotent: {
    marginTop: 20,
  },
  labelText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
  },
  inputDisabel: {
    marginTop: 12,
    width: '100%',
    height: 50,
    backgroundColor: '#EBEFF0',
    border: '1px solid #E7E7E8',
    borderRadius: 8,
  },
  errorText: {
    fontSize: 14,
    color: 'red',
  },
  inputPadLeft: {
    paddingLeft: 10,
  },
  inputPadRight: {
    paddingRight: 10,
  },
  inputPadRightLeft: {
    paddingRight: 10,
    paddingLeft: 10,
  },
  // styling Form select
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 6,
    paddingBottom: 5,
    // maxWidth: 220,
    width: '100%',
    // minWidth: 160,
    height: 50,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#969BAB',
      paddingRight: 2,
      background: 'none',
    },
  },
  selectContainerLeft: {
    paddingRight: 10,
    marginTop: 12,
  },
  selectContainerRight: {
    paddingLeft: 10,
    marginTop: 12,
  },
  bodyContact: {
    padding: 20,
    marginTop: 10,
    marginBottom: 10,
  },
  formContact: {
    marginTop: 20,
  },
  inputContent: {
    width: '100%',
  },
  btnAddContact: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 11,
    cursor: 'pointer',
    maxWidth: 220,
  },
  textDocumentBlue: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#127EEE',
    marginLeft: 11,
  },
  bodyPayment: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  bodyUpload: {
    marginTop: 31,
    paddingLeft: 20,
    paddingRight: 20,
  },
  uploadContent: {
    marginTop: 20,
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 20,
    paddingRight: 30,
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    marginBottom: 20,
  },
  wrapperUpload: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
  },
  fileUploadContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  textFile: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
  },
  textFileName: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
    textDecorationLine: 'underline',
    cursor: 'pointer',
    marginRight: 10,
  },
  textFileRed: {
    fontSize: 16,
    lineHeight: '21px',
    color: 'red',
  },
  btnUpload: {
    cursor: 'pointer',
    paddingLeft: 23,
    paddingRight: 23,
    paddingTop: 9.5,
    paddingBottom: 9.5,
    backgroundColor: '#127EEE',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
  },
  btnUploadTex: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
    marginLeft: 12,
  },
  textLabel: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
    marginTop: 20,
  },
  noteUpload: {
    fontSize: 16,
    lineHeight: '21px',
    color: 'red',
    textAlign: 'center',
  },
  wrapperFilename: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
  },
  wrapperEntity: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingBottom: 10,
  },
}));

const vendorRows = [
  { id: 1, name: 'Citra Titipan' },
  { id: 2, name: 'Cahaya Impian' },
  { id: 3, name: 'Triangle Sinar' },
  { id: 4, name: 'Sinar Mas' },
  { id: 5, name: 'Sukamaju Bali' },
  { id: 6, name: 'Sri Raha Wangi' },
  { id: 7, name: 'Sri Raha Wangi' },
  { id: 8, name: 'Sri Raha Wangi' },
  { id: 9, name: 'Sri Raha Wangi' },
  { id: 10, name: 'Sri Raha Wangi' },
];

export default EditForm;
