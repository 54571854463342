import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Skeleton from '@material-ui/lab/Skeleton';

// components
import CheckboxItem from './CheckboxItem';
import { ContentText } from '../../../libs/Typography';

// iconst
import Trash from '../../../assets/Icons/trash.png';
import './index.css';

const ChecklistItem = ({
  item,
  index,
  addMoreActivities,
  howLenght,
  deleteActivities,
  checklist_data,
  checklist_isLoading,
  activities_data,
  activities_isLoading,
  handleChecklistChange,
  handleChecklistChexBox,
  tempChecklist,
  isLoading,
  status,
}) => {
  const classes = useStyles();
  const [isShow, setIsShow] = React.useState(true);
  const [filterShortChecklist, setFilterShortChecklist] = React.useState('');

  const getChecklistFilterer = () => {
    return (check, indexChec) => {
      if (!filterShortChecklist) return true;

      return check?.name.toLocaleLowerCase().includes(filterShortChecklist.toLocaleLowerCase());
    }
  }

  const TheCheckboxItem = ({ check, indexChec, isShort, setShowSearch }) => {
    return (
      <CheckboxItem
        key={indexChec}
        item={item}
        index={index}
        check={check}
        indexChec={indexChec}
        handleChecklistChexBox={handleChecklistChexBox}
        tempChecklist={tempChecklist}
        status={status}
        isShort={isShort}
        setShowSearch={setShowSearch}
      />
    );
  }

  const searchFilter = getChecklistFilterer();

  return (
    <>
      {checklist_isLoading || activities_isLoading || isLoading ? (
        <Skeleton variant="text" width={'100%'} height={50} />
      ) : (
        <>
          <Box className={classes.wrapperCheckListItem}>
            <ContentText styling={classes.titleId} content={`Activity`} />
            <Box className={classes.wrapperSelect}>
              <FormControl
                className={
                  status !== 'need_confirm' || status !== 'rejected'
                    ? classes.formControlFull
                    : classes.formControl
                }
                key={index}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={item?.activity_id}
                  name={`${item?.id}`}
                  disabled={
                    status == 'need_confirm' || status == 'rejected'
                      ? false
                      : true
                  }
                  onChange={handleChecklistChange}
                  placeholder="Select Approval"
                >
                  <MenuItem value={0}>
                    <ContentText
                      styling={classes.bodyDataText}
                      content={`Select Activity`}
                    />
                  </MenuItem>
                  {activities_data?.activities?.map(
                    (activity, activityIndex) => (
                      <MenuItem value={activity?.id} key={activityIndex}>
                        <ContentText
                          styling={classes.bodyDataText}
                          content={activity?.name}
                        />
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              {status == 'need_confirm' || status == 'rejected' ? (
                <Box
                  className={classes.iconDelete}
                  onClick={() => deleteActivities(item.id)}
                >
                  <img src={Trash} alt="none" />
                </Box>
              ) : null}
            </Box>
            <Box
              className={classes.wrapperShowContent}
              onClick={() => setIsShow(!isShow)}
            >
              <ContentText
                styling={classes.bodyTextBlue}
                content={
                  isShow ? 'Hide Checklist Document' : 'Show Checklist Document'
                }
              />
            </Box>
          </Box>
          {isShow ? (
            <>
              <ContentText
                content={`List item Checklist Document`}
                styling={classes.titleId}
              />
              <Box  >
                <input
                  className='search-checklist-text-input'
                  type="text"
                  value={filterShortChecklist}
                  onChange={(e) => setFilterShortChecklist(e.target.value)}
                  placeholder='Search item Checklist'
                />
              </Box>
              <Box className={classes.wrapperBoxCheclist}>
                {checklist_data?.ChecklistAttachment?.filter(searchFilter).map(
                  (check, indexChec) => (
                    <TheCheckboxItem
                      check={check}
                      indexChec={indexChec}
                      isShort={true}
                    />
                  )
                )}
              </Box>
              <Box className={classes.wrapperBoxCheclist}>
                {checklist_data?.ChecklistAttachment?.filter(searchFilter).map(
                  (check, indexChec) => (
                    <TheCheckboxItem
                      check={check}
                      indexChec={indexChec}
                      isShort={false}
                    />
                  )
                )}
              </Box>
            </>
          ) : null}
        </>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  accordionMenu: {
    width: '95%',
  },
  wrapperCheckListItem: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  wrapperSelect: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  iconDelete: {
    width: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
    cursor: 'pointer',
  },
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 5,
    paddingBottom: 5,
    // maxWidth: 220,
    width: '95%',
    // minWidth: 160,
    maxHeight: 50,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      // marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#969BAB',
      paddingRight: 2,
      backgroundColor: '#F8FAFB',
    },
  },
  formControlFull: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 5,
    paddingBottom: 5,
    // maxWidth: 220,
    width: '100%',
    // minWidth: 160,
    maxHeight: 50,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      // marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#969BAB',
      paddingRight: 2,
      backgroundColor: '#F8FAFB',
    },
  },
  titleId: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
    marginBottom: 12,
  },
  wrapperShowContent: {
    marginTop: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  bodyTextBlue: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#127EEE',
    fontWeight: 'bold',
  },
  wrapperCheclistMenu: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  wrapperBoxCheclist: {
    maxHeight: 250,
    overflowY: 'auto',
    // marginBottom: 20,
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
  },
}));

export default ChecklistItem;
