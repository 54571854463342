import * as type from './types';
import request from '../../utils/request';

export const getInternalUser = (token, params) => (dispatch) => {
  dispatch({ type: type.SET_INTERNAL_LOADING, payload: true });

  request({
    method: 'get',
    url: `users`,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      const data = res?.data?.user;
      // console.log(data);
      dispatch({ type: type.SET_INTERNAL_USER, payload: data });
      dispatch({ type: type.SET_INTERNAL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get Internal User', err);
      dispatch({ type: type.SET_INTERNAL_LOADING, payload: false });
    });
};

// get all internal for approval lis
export const getInternalAllUser = (token) => (dispatch) => {
  dispatch({ type: type.GET_ALL_INTERNAL_LOADING, payload: true });

  request({
    method: 'get',
    url: `users/get/all`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      const data = res?.data?.user;
      dispatch({ type: type.GET_ALL_INTERNAL_DATA, payload: data });
      dispatch({ type: type.GET_ALL_INTERNAL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get Internal all User', err);
      dispatch({ type: type.GET_ALL_INTERNAL_LOADING, payload: false });
    });
};

export const getAdminUser = (token, params) => (dispatch) => {
  dispatch({ type: type.SET_ADMIN_LOADING, payload: true });

  request({
    method: 'get',
    url: `users`,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      // console.log(res.data);
      const data = res?.data?.user;
      dispatch({ type: type.SET_ADMIN_USER, payload: data });
      dispatch({ type: type.SET_ADMIN_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get Admin User', err);
      dispatch({ type: type.SET_ADMIN_LOADING, payload: false });
    });
};

export const setBlankMessage = () => (dispatch) => {
  dispatch({ type: type.SET_ADMIN_MESSAGE, payload: '' });
};

export const createAdminUser =
  (token, username, email, password, password_confirmation, file) =>
  (dispatch) => {
    dispatch({ type: type.SET_ADMIN_CREATE_LOADING, payload: true });

    const data = new FormData();
    data.append('username', username);
    data.append('name', username);
    data.append('email', email);
    data.append('password', password);
    data.append('role', 1);
    data.append('file', file);

    request({
      method: 'post',
      // url: `users?username=${username}&email=${email}&name=${username}&password=${password}&role=1&entity=1&department_id=1&position_id=1&nik=111111111111111`,
      url: `users`,
      data,
      headers: {
        // 'Content-type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log(res);
        // const data = res?.data?.user;
        dispatch({ type: type.SET_ADMIN_CREATE_DATA, payload: res.data });
        dispatch({ type: type.SET_ADMIN_MESSAGE, payload: res.message });
        dispatch({ type: type.SET_ADMIN_CREATE_LOADING, payload: false });
      })
      .catch((err) => {
        console.log('---Error creat Admin User', err);
        dispatch({ type: type.SET_ADMIN_MESSAGE, payload: err?.data?.message });
        dispatch({ type: type.SET_ADMIN_CREATE_LOADING, payload: false });
      });
  };

export const getProfileAdmin = (token, id) => (dispatch) => {
  dispatch({ type: type.SET_ADMIN_PROFILE_LOADING, payload: true });

  request({
    method: 'get',
    url: `users/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      // console.log(res);
      // const data = res?.data?.user;
      dispatch({ type: type.SET_ADMIN_PROFILE, payload: res.data });
      dispatch({ type: type.SET_ADMIN_PROFILE_MESSAGE, payload: res.message });
      dispatch({ type: type.SET_ADMIN_PROFILE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get profile Admin User', err);
      dispatch({ type: type.SET_ADMIN_PROFILE_LOADING, payload: false });
    });
};

export const setBlankMessageAdminEdit = () => (dispatch) => {
  dispatch({ type: type.SET_ADMIN_PROFILE_MESSAGE, payload: '' });
};

export const updateProfileAdmin = (token, userId, params) => (dispatch) => {
  dispatch({ type: type.SET_ADMIN_PROFILE_LOADING, payload: true });

  request({
    method: 'post',
    url: `users/${userId}/update`,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-type': 'multipart/form-data',
    },
  })
    .then((res) => {
      dispatch({
        type: type.SET_ADMIN_PROFILE_MESSAGE,
        payload: res.message,
      });
      dispatch({ type: type.SET_ADMIN_PROFILE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error edit profile Admin User', err);
      dispatch({ type: type.SET_ADMIN_PROFILE_LOADING, payload: false });
    });
};

export const setBlankMessageInternalCreate = () => (dispatch) => {
  dispatch({ type: type.SET_INTERNAL_CREATE_MESSAGE, payload: '' });
};

export const createInternalUser = (token, dataForm, permChecklist) => (dispatch) => {
  dispatch({ type: type.SET_INTERNAL_CREATE_LOADING, payload: true });

  const data = new FormData();
  data.append('username', dataForm.username);
  data.append('email', dataForm.email);
  data.append('name', dataForm.name);
  data.append('password', dataForm.password);
  data.append('role', dataForm.role);
  data.append('entity', dataForm.entity);
  data.append('department_id', dataForm.department_id);
  data.append('position_id', dataForm.position_id);
  if (dataForm.nik) {
    data.append('nik', dataForm.nik);
  }
  if (dataForm.phone) {
    data.append('phone', dataForm.phone);
  }
  if(dataForm.file){
    data.append('file', dataForm.file);
  }
  if(permChecklist){
    for(const i in permChecklist){
      data.append(i, permChecklist[i])
    }
  }
  request({
    method: 'post',
    // url: `users?username=${username}&email=${email}&name=${username}&password=${password}&role=1&entity=1&department_id=1&position_id=1&nik=111111111111111`,
    url: `users`,
    data,
    headers: {
      // 'Content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log(res);
      // const data = res?.data?.user;
      dispatch({ type: type.SET_INTERNAL_CREATE_DATA, payload: res.data });
      dispatch({
        type: type.SET_INTERNAL_CREATE_MESSAGE,
        payload: res.message,
      });
      dispatch({ type: type.SET_INTERNAL_CREATE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error creat Internal User', err);
      dispatch({
        type: type.SET_INTERNAL_CREATE_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: type.SET_INTERNAL_CREATE_LOADING, payload: false });
    });
};

export const editInternalUser = (token, dataForm) => (dispatch) => {
  dispatch({ type: type.SET_INTERNAL_LOADING, payload: true });

  const data = new FormData();
  data.append('username', dataForm.username);
  data.append('email', dataForm.email);
  data.append('name', dataForm.name);
  data.append('password', dataForm.password);
  data.append('role', dataForm.role);
  data.append('entity', dataForm.entity);
  data.append('department_id', dataForm.department_id);
  data.append('nik', dataForm.nik);
  data.append('phone', dataForm.phone);
  data.append('status', dataForm.status);
  data.append('file', dataForm.file);

  request({
    method: 'post',
    // url: `users?username=${username}&email=${email}&name=${username}&password=${password}&role=1&entity=1&department_id=1&position_id=1&nik=111111111111111`,
    url: `users/${dataForm.id}/update`,
    data,
    headers: {
      'Content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log(res);
      // const data = res?.data?.user;
      dispatch({ type: type.SET_INTERNAL_CREATE_DATA, payload: res.data });
      dispatch({
        type: type.SET_INTERNAL_MESSAGE,
        payload: res.message,
      });
      dispatch({ type: type.SET_INTERNAL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error edit Internal User', err);
      dispatch({
        type: type.SET_INTERNAL_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: type.SET_INTERNAL_LOADING, payload: false });
    });
};

export const setDefaultMessage = () => (dispatch) => {
  dispatch({ type: type.MESSAGE, payload: '' });
};

export const deleteUser = (token, id) => (dispatch) => {
  dispatch({ type: type.LOADING, payload: true });

  request({
    method: 'post',
    url: `users/delete?id=${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('message', res.message);
      dispatch({
        type: type.MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: type.LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error edit Internal User', err);
      dispatch({
        type: type.MESSAGE,
        payload: err?.message,
      });
      dispatch({ type: type.LOADING, payload: false });
    });
};

// create external user distibutor
export const createExternalUserDistributor =
  (token, dataForm) => (dispatch) => {
    dispatch({ type: type.SET_EXTERNAL_LOADING, payload: true });

    const data = new FormData();
    // section distributor
    data.append('code', dataForm.code);
    data.append('address', dataForm.address);
    data.append(
      'distributor_group_code_id',
      dataForm.distributor_group_code_id
    );
    data.append('fax', dataForm.fax);
    data.append('client_id', dataForm.client_id);
    // section user
    data.append('username', dataForm.username);
    data.append('password', dataForm.password);
    data.append('name', dataForm.name);
    data.append('email', dataForm.email);
    if (dataForm.phone) {
      data.append('phone', dataForm.phone);
    }
    if(dataForm.file){
      data.append('file', dataForm.file);
    }
    request({
      method: 'post',
      url: `distributors`,
      data,
      headers: {
        // 'Content-type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log(res);
        dispatch({ type: type.SET_EXTERNAL_DATA, payload: res.data });
        dispatch({
          type: type.SET_EXTERNAL_MESSAGE,
          payload: res?.message,
        });
        dispatch({ type: type.SET_EXTERNAL_LOADING, payload: false });
      })
      .catch((err) => {
        console.log('---Error create external User Distributor', err);
        dispatch({
          type: type.SET_EXTERNAL_MESSAGE,
          payload: err?.data?.message,
        });
        dispatch({ type: type.SET_EXTERNAL_LOADING, payload: false });
      });
  };

// create external user supplier
export const createExternalUserSupplier = (token, dataForm) => (dispatch) => {
  dispatch({ type: type.SET_EXTERNAL_LOADING, payload: true });

  const data = new FormData();
  // section distributor
  data.append('code', dataForm.code);
  data.append('address', dataForm.address);
  data.append('supplier_group_code_id', dataForm.supplier_group_code_id);
  data.append('fax', dataForm.fax);
  data.append('client_id', dataForm.client_id);
  // section user
  data.append('username', dataForm.username);
  data.append('password', dataForm.password);
  data.append('name', dataForm.name);
  data.append('email', dataForm.email);
  if (dataForm.phone) {
    data.append('phone', dataForm.phone);
  }
  if (dataForm.file) {
    data.append('file', dataForm.file);
  }
  request({
    method: 'post',
    url: `suppliers`,
    data,
    headers: {
      // 'Content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log(res);
      dispatch({ type: type.SET_EXTERNAL_DATA, payload: res.data });
      dispatch({
        type: type.SET_EXTERNAL_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: type.SET_EXTERNAL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error create external User Supplier', err);
      dispatch({
        type: type.SET_EXTERNAL_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: type.SET_EXTERNAL_LOADING, payload: false });
    });
};

export const getExternalUser = (token, params) => (dispatch) => {
  dispatch({ type: type.GET_EXTERNAL_USER_LOADING, payload: true });

  request({
    method: 'get',
    url: `users`,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      const data = res?.data?.user;
      // console.log(data);
      dispatch({ type: type.GET_EXTERNAL_USER_DATA, payload: data });
      dispatch({ type: type.GET_EXTERNAL_USER_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get external User', err);
      dispatch({ type: type.GET_EXTERNAL_USER_LOADING, payload: false });
    });
};

export const setBlankExernalMessage = () => (dispatch) => {
  dispatch({ type: type.SET_EXTERNAL_MESSAGE, payload: '' });
};

// detele external user
export const delExternalUserDistibutor = (token, id) => (dispatch) => {
  dispatch({ type: type.DEL_EXTERNAL_USER_LOADING, payload: true });

  request({
    method: 'post',
    url: `distributors/${id}/delete`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      const data = res?.message;
      // console.log(data);
      dispatch({ type: type.DEL_EXTERNAL_USER_MESSAGE, payload: data });
      dispatch({ type: type.DEL_EXTERNAL_USER_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error delete external User Distributor', err);
      dispatch({ type: type.DEL_EXTERNAL_USER_LOADING, payload: false });
    });
};

export const delExternalUserSupplier = (token, id) => (dispatch) => {
  dispatch({ type: type.DEL_EXTERNAL_USER_LOADING, payload: true });

  request({
    method: 'post',
    url: `suppliers/${id}/delete`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      const data = res?.message;
      // console.log(data);
      dispatch({ type: type.DEL_EXTERNAL_USER_MESSAGE, payload: data });
      dispatch({ type: type.DEL_EXTERNAL_USER_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error delete external User Supplier', err);
      dispatch({ type: type.DEL_EXTERNAL_USER_LOADING, payload: false });
    });
};

export const setBlankDelExernalMessage = () => (dispatch) => {
  dispatch({ type: type.DEL_EXTERNAL_USER_MESSAGE, payload: '' });
};

// get detail profile
export const getProfileExternalDistributor = (token, id) => (dispatch) => {
  dispatch({ type: type.GET_EXTERNAL_DETAIL_LOADING, payload: true });

  request({
    method: 'get',
    url: `distributors/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      // console.log(res);
      // const data = res?.data?.user;
      dispatch({ type: type.GET_EXTERNAL_DETAIL, payload: res.data });
      dispatch({
        type: type.GET_EXTERNAL_DETAIL_MESSAGE,
        payload: res.message,
      });
      dispatch({ type: type.GET_EXTERNAL_DETAIL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get profile external User Distributor', err);
      dispatch({ type: type.GET_EXTERNAL_DETAIL_LOADING, payload: false });
    });
};

export const getProfileExternalSupplier = (token, id) => (dispatch) => {
  dispatch({ type: type.GET_EXTERNAL_DETAIL_LOADING, payload: true });

  request({
    method: 'get',
    url: `suppliers/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      // console.log(res);
      // const data = res?.data?.user;
      dispatch({ type: type.GET_EXTERNAL_DETAIL, payload: res.data });
      dispatch({
        type: type.GET_EXTERNAL_DETAIL_MESSAGE,
        payload: res.message,
      });
      dispatch({ type: type.GET_EXTERNAL_DETAIL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get profile external User Distributor', err);
      dispatch({ type: type.GET_EXTERNAL_DETAIL_LOADING, payload: false });
    });
};

// Edit External User
export const editExternalUserDistributor = (token, dataForm) => (dispatch) => {
  dispatch({ type: type.SET_EXTERNAL_LOADING, payload: true });

  const data = new FormData();
  // section distributor
  data.append('code', dataForm.code);
  data.append('address', dataForm.address);
  data.append('distributor_group_code_id', dataForm.distributor_group_code_id);
  data.append('fax', dataForm.fax);
  data.append('client_id', dataForm.client_id);
  // section user
  data.append('username', dataForm.username);
  if (dataForm.password) {
    data.append('password', dataForm.password);
  }
  data.append('name', dataForm.name);
  data.append('email', dataForm.email);
  if (dataForm.phone) {
    data.append('phone', dataForm.phone);
  }
  data.append('file', dataForm.file);

  request({
    method: 'post',
    url: `distributors`,
    data,
    headers: {
      // 'Content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log(res);
      dispatch({ type: type.SET_EXTERNAL_DATA, payload: res.data });
      dispatch({
        type: type.SET_EXTERNAL_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: type.SET_EXTERNAL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error create external User Distributor', err);
      dispatch({
        type: type.SET_EXTERNAL_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: type.SET_EXTERNAL_LOADING, payload: false });
    });
};

// edit external user supplier
export const editExternalUserSupplier = (token, dataForm) => (dispatch) => {
  dispatch({ type: type.SET_EXTERNAL_LOADING, payload: true });

  const data = new FormData();
  // section distributor
  data.append('code', dataForm.code);
  data.append('address', dataForm.address);
  data.append('supplier_group_code_id', dataForm.supplier_group_code_id);
  data.append('fax', dataForm.fax);
  data.append('client_id', dataForm.client_id);
  // section user
  data.append('username', dataForm.username);
  if (dataForm.password) {
    data.append('password', dataForm.password);
  }
  data.append('name', dataForm.name);
  data.append('email', dataForm.email);
  if (dataForm.phone) {
    data.append('phone', dataForm.phone);
  }
  data.append('file', dataForm.file);

  request({
    method: 'post',
    url: `suppliers`,
    data,
    headers: {
      // 'Content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log(res);
      dispatch({ type: type.SET_EXTERNAL_DATA, payload: res.data });
      dispatch({
        type: type.SET_EXTERNAL_MESSAGE,
        payload: res?.message,
      });
      dispatch({ type: type.SET_EXTERNAL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error create external User Supplier', err);
      dispatch({
        type: type.SET_EXTERNAL_MESSAGE,
        payload: err?.data?.message,
      });
      dispatch({ type: type.SET_EXTERNAL_LOADING, payload: false });
    });
};
