import React from 'react';
import { makeStyles, Box, Grid, Typography } from '@material-ui/core';
import { useRouter } from '../../utils/useRouter';

// Icons
import PlusBtn from '../../assets/Icons/plusBtn.png';

// Components
import ListInternalUser from './Components/UserListing/ListInternalUser';
import ListExternalUser from './Components/UserListing/ListExternalUser';
import ListAdminUser from './Components/UserListing/ListAdminUser';

const UserTableMenu = () => {
  const classes = useStyles();
  const router = useRouter();

  const [index, setIndex] = React.useState(0);

  const _renderListData = (index) => {
    if (index === 0) {
      return <ListAdminUser router={router} />;
    } else if (index === 1) {
      return <ListInternalUser router={router} />;
    } else {
      return <ListExternalUser router={router} />;
    }
  };

  const handleCreatUser = (index) => {
    if (index === 0) {
      router.push('/create-admin');
      // router.push('/create-internal');
    } else if (index === 1) {
      router.push('/create-internal');
    } else {
      router.push('/create-external');
    }
  };

  return (
    <>
      <Grid md={12} className={classes.mainContent}>
        <Grid md={12} className={classes.headerContent}>
          <Typography
            className={classes.headerTitle}
            variant="body1"
            component="p"
          >
            User List
          </Typography>
        </Grid>
        <Grid
          md={12}
          container
          className={classes.wrapperTab}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid xs={12} md={9} container className={classes.tabContent}>
            <Box className={classes.pointer} onClick={() => setIndex(0)}>
              <Typography
                className={
                  index === 0 ? classes.tabTitleActive : classes.tabTitle
                }
                variant="body1"
                component="p"
              >
                User Admin
              </Typography>
            </Box>
            <Box className={classes.pointer} onClick={() => setIndex(1)}>
              <Typography
                className={
                  index === 1 ? classes.tabTitleActive : classes.tabTitle
                }
                variant="body1"
                component="p"
              >
                Internal User
              </Typography>
            </Box>
            <Box className={classes.pointer} onClick={() => setIndex(2)}>
              <Typography
                className={
                  index === 2 ? classes.tabTitleActive : classes.tabTitle
                }
                variant="body1"
                component="p"
              >
                External User
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} md={3} className={classes.btnContent}>
            <Box
              className={classes.btnAdd}
              onClick={() => handleCreatUser(index)}
            >
              <Box className={classes.elipsMobile}>
                <img src={PlusBtn} alt="image not found" />
              </Box>
              <Typography
                variant="body1"
                component="p"
                className={classes.btnText}
              >
                Add New User
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {_renderListData(index)}
    </>
  );
};

const useStyles = makeStyles({
  mainContent: {
    backgroundColor: '#fff',
    borderRadius: 8,
  },
  headerContent: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingBottom: 20,
    borderBottom: '1px solid #E7E7E8',
  },
  headerTitle: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  tabTitleActive: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingRight: 20,
    paddingLeft: 20,
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#127EEE',
    borderBottom: '3px solid #127EEE',
    '&.MuiTypography-root:hover': {
      backgroundColor: '#F3F5F6',
    },
  },
  tabTitle: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingRight: 20,
    paddingLeft: 20,
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#969BAB',
    '&.MuiTypography-root:hover': {
      backgroundColor: '#F3F5F6',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  btnContent: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: 20,
  },
  btnAdd: {
    backgroundColor: '#1B2030',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '9.5px 14px',
    borderRadius: 8,
    cursor: 'pointer',
  },
  btnText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#fff',
    marginLeft: 20,
  },
});

export default UserTableMenu;
