import * as types from './types';

import request from '../../utils/request';

export const getSAPnotification = (token) => (dispatch) => {
  dispatch({ type: types.PR_NOTIF_LOADING, payload: true });

  request({
    method: 'get',
    url: 'purchase-requisition/notification',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get notif');
      dispatch({ type: types.PR_NOTIF_DATA, payload: res?.data?.draft });
      dispatch({
        type: types.PR_NOTIF_DASHBOARD_DATA,
        payload: res?.data?.draft,
      });
      dispatch({ type: types.PR_NOTIF_MESSAGE, payload: res?.message });
      dispatch({ type: types.PR_NOTIF_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get notif PR', err);
      dispatch({ type: types.PR_NOTIF_LOADING, payload: false });
    });
};

export const getPRdata = (token, params) => (dispatch) => {
  dispatch({ type: types.PR_DATA_LOADING, payload: true });

  request({
    method: 'get',
    url: `purchase-requisition`,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get PR data');

      dispatch({ type: types.PR_SET_DATA, payload: res?.data });
      dispatch({ type: types.PR_DATA_MESSAGE, payload: res?.message });
      dispatch({ type: types.PR_DATA_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get data PR', err);
      dispatch({ type: types.PR_DATA_LOADING, payload: false });
    });
};

export const getPRDetail = (token, id) => (dispatch) => {
  dispatch({ type: types.PR_DETAIL_LOADING, payload: true });

  request({
    method: 'get',
    url: `purchase-requisition/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get PR Detail data');
      // console.log(res);
      dispatch({ type: types.PR_DETAIL_DATA, payload: res?.data });
      dispatch({ type: types.PR_DETAIL_MESSAGE, payload: res?.message });
      dispatch({ type: types.PR_DETAIL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get data PR detail', err);
      dispatch({ type: types.PR_DETAIL_LOADING, payload: false });
    });
};

export const getPRSAPdata = (token, params) => (dispatch) => {
  dispatch({ type: types.PR_SYNC_LOADING, payload: true });

  request({
    method: 'get',
    url: `purchase-requisition`,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get SAP PR data');

      dispatch({ type: types.PR_SYNC_DATA, payload: res?.data });
      dispatch({ type: types.PR_SYNC_MESSAGE, payload: res?.message });
      dispatch({ type: types.PR_SYNC_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get SAP data PR', err);
      dispatch({ type: types.PR_SYNC_LOADING, payload: false });
    });
};

export const deletePR = (token, id) => (dispatch) => {
  dispatch({ type: types.PR_DELETE_LOADING, payload: true });

  request({
    method: 'delete',
    url: `purchase-requisition/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get PR Detail data');
      // console.log(res);
      dispatch({ type: types.PR_DELETE_MESSAGE, payload: res?.message });
      dispatch({ type: types.PR_DELETE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get data PR detail', err);
      dispatch({ type: types.PR_DELETE_LOADING, payload: false });
    });
};

export const setBlankDelelete = () => (dispatch) => {
  dispatch({ type: types.PR_DELETE_MESSAGE, payload: '' });
};

export const publishPR = (token, data) => (dispatch) => {
  dispatch({ type: types.PR_PUBLISH_LOADING, payload: true });

  request({
    method: 'put',
    url: `purchase-requisition/publish`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('publish PR data');
      dispatch({ type: types.PR_PUBLISH_MESSAGE, payload: res?.message });
      dispatch({ type: types.PR_PUBLISH_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error publish PR ', err);
      dispatch({ type: types.PR_PUBLISH_LOADING, payload: false });
    });
};

export const setBlankPulishPr = () => (dispatch) => {
  dispatch({ type: types.PR_PUBLISH_MESSAGE, payload: '' });
};

export const cancelPR = (token, id) => (dispatch) => {
  dispatch({ type: types.PR_CANCEL_LOADING, payload: true });

  request({
    method: 'put',
    url: `purchase-requisition/${id}/cancel`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('Cancel PR data');
      console.log(res);
      dispatch({ type: types.PR_CANCEL_MESSAGE, payload: res?.message });
      dispatch({ type: types.PR_CANCEL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error cancel PR ', err);
      dispatch({ type: types.PR_CANCEL_MESSAGE, payload: err?.data?.message });
      dispatch({ type: types.PR_CANCEL_LOADING, payload: false });
    });
};

export const setBlankCancel = () => (dispatch) => {
  dispatch({ type: types.PR_CANCEL_MESSAGE, payload: '' });
};

export const assigmentPR = (token, id, data) => (dispatch) => {
  dispatch({ type: types.PR_ASSIGNMENT_LOADING, payload: true });

  request({
    method: 'put',
    url: `purchase-requisition/${id}/confirm-assignment`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('confirm assigment PR data');
      // console.log(res);
      dispatch({ type: types.PR_ASSIGNMENT_MESSAGE, payload: res?.message });
      dispatch({ type: types.PR_ASSIGNMENT_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error confirm assigment PR ', err);
      dispatch({ type: types.PR_ASSIGNMENT_MESSAGE, payload: err?.message });
      dispatch({ type: types.PR_ASSIGNMENT_LOADING, payload: false });
    });
};

export const setAssigmentMessage = () => (dispatch) => {
  dispatch({ type: types.PR_ASSIGNMENT_MESSAGE, payload: '' });
};
