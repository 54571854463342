import SvgIcon from '@material-ui/core/SvgIcon';

export const DownArrow = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M6.99216 9.99831C6.82555 9.99864 6.6641 9.94054 6.53581 9.83411L2.25759 6.26453C2.11197 6.14335 2.0204 5.96921 2.00302 5.78044C1.98563 5.59166 2.04386 5.4037 2.16489 5.2579C2.28592 5.11211 2.45984 5.02043 2.64839 5.00302C2.83693 4.98562 3.02466 5.04392 3.17027 5.16509L6.99216 8.36344L10.814 5.27932C10.887 5.22002 10.9709 5.17573 11.061 5.14901C11.1511 5.12229 11.2455 5.11365 11.339 5.12361C11.4324 5.13356 11.5229 5.1619 11.6054 5.207C11.6878 5.2521 11.7606 5.31307 11.8194 5.38641C11.8847 5.45981 11.9342 5.54593 11.9647 5.63937C11.9952 5.7328 12.0061 5.83153 11.9967 5.92938C11.9874 6.02724 11.9579 6.1221 11.9102 6.20802C11.8625 6.29395 11.7976 6.36908 11.7196 6.42873L7.44137 9.87695C7.3094 9.96655 7.15124 10.0093 6.99216 9.99831Z"
        fill="#2F353A"
      />
    </SvgIcon>
  );
};
