import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles, Typography } from "@material-ui/core";
import { formatRupiah, dateFormater } from "../../../utils/formatter";

const TableData = ({ index, row }) => {
  const classes = useStyles();

  const _renderStatus = (status) => {
    if (status === "open") {
      return <div className={classes.statusOpen}>{status}</div>;
    } else if (status === "cancelled" || status === "closed") {
      return <div className={classes.statusClosed}>{status}</div>;
    } else if (status === "rejected") {
      return <div className={classes.statusReject}>{status}</div>;
    } else if (status === "need_confirm") {
      return <div className={classes.statusNeed}>CONFIRM APPROVAL</div>;
    } else if (status === "waiting") {
      return <div className={classes.statusWating}>WAITING APPROVAL</div>;
    } else {
      return <div className={classes.statusApprove}>{status}</div>;
    }
  };
  const renderContent = () => {
    return (
      <>
        <TableRow
          className={
            index % 2 == 0
              ? classes.tableContentData
              : classes.tableContentDataDua
          }
        >
          <TableCell>{row.docNum}</TableCell>
          <TableCell>
            <Typography className={classes.requestText}>
              {row.request}
            </Typography>{" "}
          </TableCell>
          <TableCell>{row.department}</TableCell>
          <TableCell>{row.entity}</TableCell>
          <TableCell>
            <Typography className={classes.activityText}>
              {row.activity}
            </Typography>
          </TableCell>
          <TableCell>
            {row?.currency === "IDR"
              ? formatRupiah(row.total ?? "0")
              : row?.total ?? "0"}
          </TableCell>
          <TableCell>{_renderStatus(row.status)}</TableCell>
          <TableCell>{row.date}</TableCell>
        </TableRow>
      </>
    );
  };

  return renderContent();
};
const useStyles = makeStyles({
  tableContentData: {
    backgroundColor: "#F8FAFB",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    border: "none",
    height: 70,
    borderBottom: "none",
    "&.MuiTableRow-root .MuiTableCell-root.MuiTableCell-body": {
      borderBottom: "none",
      // paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 10,
      paddingBottom: 10,
    },
    "&.MuiTableRow-root .MuiTableCell-sizeSmall": {
      paddingRight: 0,
      borderBottom: "none",
    },
  },
  tableContentDataDua: {
    backgroundColor: "#fff",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    border: "none",
    height: 70,
    borderBottom: "none",
    "&.MuiTableRow-root .MuiTableCell-sizeSmall": {
      paddingRight: 0,
      borderBottom: "none",
    },
    "&.MuiTableRow-root .MuiTableCell-root.MuiTableCell-body": {
      borderBottom: "none",
      // paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  statusOpen: {
    backgroundColor: "#127EEE",
    color: "#fff",
    textAlign: "center",
    borderRadius: 100,
    maxWidth: 58,
    width: "100%",
  },
  statusClosed: {
    backgroundColor: "#969BAB",
    width: "100%",
    maxWidth: 73,
    textAlign: "center",
    borderRadius: 100,
    textTransform: "uppercase",
    color: "#fff",
    fontSize: 12,
    lineHeight: "26px",
    fontWeight: "bold",
  },
  statusCancel: {
    backgroundColor: "#969BAB",
    color: "#fff",
    textAlign: "center",
    borderRadius: 100,
    maxWidth: 96,
    width: "100%",
  },
  statusReject: {
    backgroundColor: "#F12B2C",
    width: "100%",
    maxWidth: 73,
    textAlign: "center",
    borderRadius: 100,
    textTransform: "uppercase",
    color: "#fff",
    fontSize: 12,
    lineHeight: "26px",
    fontWeight: "bold",
  },
  statusApprove: {
    backgroundColor: "#27AE60",
    width: "100%",
    maxWidth: 93,
    textAlign: "center",
    borderRadius: 100,
    textTransform: "uppercase",
    color: "#fff",
    fontSize: 12,
    lineHeight: "26px",
    fontWeight: "bold",
  },
  statusNeed: {
    width: "100%",
    maxWidth: 150,
    backgroundColor: "#F12B2C",
    textAlign: "center",
    borderRadius: 100,
    textTransform: "uppercase",
    color: "#fff",
    fontSize: 12,
    lineHeight: "26px",
    fontWeight: "bold",
  },
  statusWating: {
    width: "100%",
    maxWidth: 150,
    backgroundColor: "#FFB800",
    textAlign: "center",
    borderRadius: 100,
    textTransform: "uppercase",
    color: "#fff",
    fontSize: 12,
    lineHeight: "26px",
    fontWeight: "bold",
  },
  requestText: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: 250,
  },
  activityText: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: 160,
  },
});

export default TableData;
