import React from 'react';
import { makeStyles, Grid, Box, LinearProgress } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import * as proforma from '../../redux/Proforma/actions';
import { useRouter } from '../../utils/useRouter';
import { formatRupiah, reverseFormat } from '../../utils/formatter';
import { result, uniqBy, lowerCase, get, isEmpty, startCase } from 'lodash';

// Components
import { ContentText } from '../../libs/Typography';
import HeaderTitle from './components/HeaderTitle';
import SectionSearch from './components/SectionSearch';
import BAST from './components/BAST';
import BASTITEM from './components/BASTITEM';
import ReportDoc from './components/ReportDoc';
import BASTPreview from '../PDFpreview/BASTPreview';
import ApprovalHistory from './components/ApprovalHistory';
import ModalConfirm from './components/ModalComfirm';
import ModalConfirmCancel from './components/ModalComfirmCancel';
import CustomizedSnackbars from '../PopupMessage';
import ModalSuccess from '../ModalSuccess';
import ModalConfirmUpload from './components/ModalComfirmUpload';
import ApprovalHistory2 from './components/ApprovaHistory2';
import Lib from 'src/utils/Lib';
import request from 'src/utils/request';

//global
import globalConfig from 'src/config/global';

// icons
import Print from '../../assets/Icons/print.png';
import EyeWhite from '../../assets/Icons/EyeWhite.png';

const schema = yup.object().shape({
  Amount: yup
    .string()
    .required('field required')
    .typeError('Type must be number'),
  PoType: yup.string(),
  NOBASTDKT: yup.string().required('field required'),
  NOBASTVENDOR: yup.string().nullable(true),
  JOBDESC: yup.string().required('field required'),
  // pihak dkt
  NamaDKT: yup.string().required('field required'),
  JabatanDKT: yup.string().required('field required'),
  NamaPerusahaanDKT: yup.string().required('field required'),
  DKTAddress: yup.string().required('field required'),
  // pihak vendor
  NamaVendor: yup.string().required('field required'),
  JabatanVendor: yup.string().required('field required'),
  NamaPerusahaanVendor: yup.string().required('field required'),
  VendorAddress: yup.string().required('field required'),
  // sumary
  NamaKegiatan: yup.string().required('field required'),
  Periode: yup.string().required('field required'),
  PONumber: yup.string(),
  AmountBeforeTax: yup.string(),
  TAX: yup.string(),
  TotalWithTax: yup.string(),
  JumlahNilaiPekerjaan: yup.string(),
  SisaPO: yup.string(),
});

const DetailProforma = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispacth = useDispatch();
  const { id } = router.query;

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Global Variable
  const { access_token, data } = useSelector((state) => state.auth);
  const {
    detail_data,
    detai_isLoading,
    approval_isLoading,
    approval_message,
    cancel_isLoading,
    cancel_message,
    upload_isLoading,
    upload_message,
    approval_bast_isLoading,
    approval_bast_message,
    process_isLoading,
    process_message,
  } = useSelector((state) => state.proforma);

  const [dataInput, setDataInput] = React.useState(null);
  const [dataPO, setDataPO] = React.useState(null);
  const [vendorAddress, setVendorAddress] = React.useState('');
  const [DKTAddress, setDKTAddress] = React.useState('');
  const [bastDate, setBastDate] = React.useState({
    day: 0,
    monthNow: 0,
    yearNow: 0,
  });
  const [nilaiBAST, setNilaiBAST] = React.useState([]);
  const [tempCategory, setTempCategory] = React.useState([]);
  const [tempFileDKT, setTempFileDKT] = React.useState([]);
  const [showPreview, setShowPreview] = React.useState(false);
  const [previewData, setPreviewData] = React.useState(null);
  const [canSubmit, setCanSubmit] = React.useState(false);
  const [tempSendData, setTempSendData] = React.useState(null);
  const [uploadSendData, setUploadSendData] = React.useState(null);
  const [reviewSendData, setReviewSendData] = React.useState(null);
  const [totalWork, setTotalWork] = React.useState(0);

  const [showModalConfirm, setShowModalConfirm] = React.useState(false);
  const [showModalCancel, setShowModalCancel] = React.useState(false);
  const [showModalSuccess, setShowModalSuccess] = React.useState(false);
  const [showModalUpload, setShowModalUpload] = React.useState(false);
  const [showModalReview, setShoModalReview] = React.useState(false);
  const [countRevise, setCountRevise] = React.useState(false);
  const [meessageConfirm, setMessageConfirm] = React.useState('');
  const [historyData, setHistoryData] = React.useState(null);
  const [historyData2, setHistoryData2] = React.useState(null);
  const [historyDkT, setHistoryDkt] = React.useState(null);
  const [detailFile, setDetailFile] = React.useState(false);
  const [detailBAST, setDetailBAST] = React.useState(false);

  const [canReviewDKT, setCanReviewDKT] = React.useState(false);
  const [isReviewDone, setReviewDone] = React.useState(false);
  const [poStatus, setPoStatus] = React.useState(false);
  const [btnProcessGRPO, setBtnProcessGRPO] = React.useState(true);
  const [GRPOParams, setGrpoParam] = React.useState(true);
  const [showModalGRPOSuccess, setShowModalGRPOSuccess] = React.useState(false);
  const [showModalConfirmSuccess, setModalConfirmSuccess] = React.useState(false);
  const [loadingConfirm, setLoadingConfirm] = React.useState(false);
  const [invoiceField, setInvoiceField] = React.useState({});

  // handle message
  const handleMessage = (type, show, message) => {
    setType(`${type}`);
    setShow(show);
    setNotifText(message);
  };

  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };

  // end handle Message

  const onChangeVendorAddress = (e) => {
    setVendorAddress(e.target.value);
  };

  const onChangeDKTAddress = (e) => {
    setDKTAddress(e.target.value);
  };

  const handleSetDataPO = (po) => {
    setDataPO(po);
  };

  const handleSetBastDate = (data) => {
    setBastDate(data);
  };

  const handleChangeCheckBox = (e) => {
    let name = e.target.name;
    let check = e.target.checked;

    let values = [...tempCategory];

    let result = values.map((item) => {
      if (item.id == name) {
        return { ...item, isCheck: check };
      } else {
        return item;
      }
    });

    setTempCategory(result);
  };

  const handleChangeCheckBoxDkt = (e) => {
    let name = e.target.name;
    let check = e.target.checked;

    let values = [...tempFileDKT];

    let result = values.map((item) => {
      if (item.id == name) {
        return { ...item, isCheck: check };
      } else {
        return item;
      }
    });
    
    setTempFileDKT(result);
  };


  const handleChangeComment = (e) => {
    let values = [...tempCategory];
    let result = values.map((item) => {
      if (item.id == e.target.name) {
        return { ...item, note: e.target.value };
      } else {
        return item;
      }
    });

    setTempCategory(result);
  };

  const handlePreview = () => {
    let state = { ...detail_data, isPreview: true };
    setPreviewData(state);
    setShowPreview(true);
  };

  const handlePrint = () => {
    window.open(`/bast/${detail_data?.id}/preview`);
  };

  const handleAddFile = (e) => {
    let indexCategory = e.target.name;
    let values = [...tempCategory];
    values[indexCategory]?.file?.push(e.target.files[0]);

    setTempCategory(values);
  };

  const handleRemoveFile = (categoryIndex, fileIndex) => {
    let values = [...tempCategory];
    values[categoryIndex]?.file?.splice(fileIndex, 1);

    setTempCategory(values);
  };

  const handleAddMoreFile = (e) => {
    let values = [...tempCategory];

    let tempId = e.target.name.split(',');

    values[tempId[0]]?.file?.push(e.target.files[0]);

    setTempCategory(values);
  };

  // add file for DKT
  const handleAddDktFile = (e) => {
    let indexCategory = e.target.name;
    let values = [...tempFileDKT];

    values[indexCategory]?.file?.push(e.target.files[0]);

    setTempFileDKT(values);
  };

  const handleRemoveDktFile = (categoryIndex, fileIndex) => {
    let values = [...tempFileDKT];
    values[categoryIndex]?.file?.splice(fileIndex, 1);

    setTempFileDKT(values);
  };

  const onSubmit = () => {
    let sendData = new FormData();
    sendData.append('user_id', data?.user?.id);

    let tempChecklist = [];
    tempCategory?.map((item, index) => {
      let tempStatus = `checklist[${item.checklist_doc_id}][status]`;
      let tempNotes = `checklist[${item.checklist_doc_id}][notes]`;
      sendData.append(`${tempStatus}`, item.isCheck ? 'approved' : 'revise');
      sendData.append(`${tempNotes}`, item.note);
    });

    let validationSumbit = [];
    tempCategory.map((item) => {
      if (item.isCheck === false) {
        validationSumbit.push(false);
      } else {
        validationSumbit.push(true);
      }
    });

    let countRevise = validationSumbit.filter((item) => item === false);

    if (data?.user?.department?.code !== 'FIN' && countRevise.length > 0) {
      setType('error');
      setShow(true);
      setNotifText('Please Verified the report document checklist');
    } else {
      setCountRevise(countRevise.length);

      if (data?.user?.department?.code === 'OPS') {
        setMessageConfirm('We will continue this Proforma Inovice to Finance');
      } else if (data?.user?.department?.code === 'FIN') {
        setMessageConfirm('We will return this Proforma Inovice to Vendor');
      } else {
        setMessageConfirm(
          'We will continue this Proforma Inovice to Operation'
        );
      }
      setTempSendData(sendData);
      setShowModalConfirm(true);
    }
  };

  const onUploadDKT = () => {
    let Form = new FormData();
    Form.append('user_id', data?.user?.id);

    let validArr = [];

    tempFileDKT.map((item) => {
      if (item?.file?.length < 1) {
        validArr.push(false);
      }
      let tempName = item.identity;
      item?.file?.map((file) => {
        Form.append(`${tempName}`, file);
      });
    });
    
    if(tempFileDKT.find(val => val.identity === "invoice_file")){
      // console.log(invoiceField)
      if(!invoiceField.invoice_no){
        handleMessage('error', true, '`Nomor invoice` cannot be empty');
        return
      } else if(!invoiceField.invoice_price){
        handleMessage('error', true, '`Jumlah yang ditagihkan` cannot be empty');
        return
      }

      Form.append('invoice_code', invoiceField.invoice_no);
      Form.append('invoice_price', invoiceField.invoice_price);
    }

    if (validArr.length < 1) {
      setUploadSendData(Form);
      setShowModalUpload(true);
    } else {
      handleMessage('error', true, 'Please check your upload file');
    }
  };

  const onReviewDkt = () => {
    let sendParams = new FormData();
    sendParams.append('user_id', data?.user?.id);

    tempFileDKT.map((item) => {
      sendParams.append(`${item?.approval_id}`, item?.isCheck ? 1 : 0);
    });

    if (data?.user?.department?.code === 'OPS') {
      setMessageConfirm('We will continue this Proforma Inovice to Finance');
    } else if (data?.user?.department?.code === 'FIN') {
      setMessageConfirm('finish this, you can process to GRPO');
    } else {
      setMessageConfirm('We will continue this Proforma Inovice to Operation');
    }

    let validationSumbit = [];
    tempFileDKT.map((item) => {
      if (item.isCheck === false) {
        validationSumbit.push(false);
      } else {
        validationSumbit.push(true);
      }
    });

    let countRevise = validationSumbit.filter((item) => item === false);

    setCountRevise(countRevise.length);

    setReviewSendData(sendParams);
    if(get(data, ['user', 'department', 'code']) === 'FIN'){
      dispacth(
        proforma.approvalProformaBast(access_token, id, sendParams)
      )
    } else {
      setShoModalReview(true);
    }
  };

  const onProcessGRPO = () => {
    let params = new FormData();

    params.append('user_id', data?.user?.id);
    setGrpoParam(params)
    setShoModalReview(true);
  };

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      // console.log(value)
      setDataInput(value)
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  React.useEffect(() => {
    dispacth(proforma.getProformaDetail(access_token, id));
  }, []);

  const onConfirmed = () => {
    setLoadingConfirm(true)
    
    request({
      method: 'post',
      url: `proforma-invoice/${id}/confirmed`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data: {},
    })
      .then((res) => {
        setLoadingConfirm(false)
        setModalConfirmSuccess(true)
      })
      .catch((err) => {
        setLoadingConfirm(false)
        setType('error');
        setShow(true);
        setNotifText(err.message);
      });
  }

  const allowEdit = (s) => {
    const isFinance = get(data, ['user', 'department', 'code']) === 'FIN'
    
    if(isFinance) return true

    if(get(data, ['user', 'role'], []).includes('superadmin')) return true
    
    if(!get(s ,['approval_checklist'], []).find(e => e?.user?.username === get(data, ['user', 'username']))) {
      return true
    }

    return false
  }

  const allowIgnoreChecklist = (obj) => {
    
    if(!globalConfig.proforma.ignoreChecklist.includes(obj?.checklist_attachment?.name)) return true

    if(isEmpty(obj.statuses)) return false

    return true
  }

  const getListExclude = (docType) => {
    let list = globalConfig.proforma.checklistExclude

    if(docType === 'item') list = globalConfig.proforma.checklistItemExclude

    return list
  }

  const getCheckedBAST = (hasHistory, name) => {
    if(hasHistory){
      return hasHistory[name] === 1
    }

    return false

  }

  React.useEffect(() => {
    if (detail_data) {
      if(detail_data?.status === 'approved'){
        setBtnProcessGRPO(false)
      }
      handleSetDataPO(detail_data?.po);
      
      if(detail_data?.po?.doc_type === 'service'){
        
        setValue('Amount', formatRupiah(detail_data?.po?.total_before_discount));
        setValue('PoType', detail_data?.po?.doc_type ? startCase(detail_data?.po?.doc_type) : 'none');
        setValue('NOBASTDKT', detail_data?.bast?.dkt_code);
        let date = detail_data?.bast?.dkt_date.split('-');
        handleSetBastDate({ day: date[2], monthNow: date[1], yearNow: date[0] });
        setValue('NOBASTVENDOR', detail_data?.bast?.vendor_code);
        setValue('JOBDESC', detail_data?.bast?.activity_name ?? 'belum ada');
        setValue('NamaVendor', detail_data?.bast?.name_1);
        setValue('JabatanVendor', detail_data?.bast?.position_1);
        setValue('NamaPerusahaanVendor', detail_data?.bast?.company_1);
        setValue('VendorAddress', detail_data?.bast?.address_1);
        setValue('NamaDKT', detail_data?.bast?.name_2);
        setValue('JabatanDKT', detail_data?.bast?.position_2);
        setValue('NamaPerusahaanDKT', detail_data?.bast?.company_2);
        setValue('DKTAddress', detail_data?.bast?.address_2);
        setValue('NamaKegiatan', detail_data?.bast?.activity_name);
        setValue('Periode', detail_data?.bast?.periode);
        setValue('PONumber', detail_data?.po?.doc_num);
        setValue(
          'AmountBeforeTax',
          formatRupiah(detail_data?.po?.total_before_discount)
        );
        setValue('TAX', formatRupiah(detail_data?.po?.vat));
        setValue('TotalWithTax', formatRupiah(detail_data?.po?.doc_total_amount));
      } else {
        setValue('Amount', formatRupiah(detail_data?.po?.total_before_discount));
        setValue('PoType', detail_data?.po?.doc_type ? startCase(detail_data?.po?.doc_type) : 'none');
      }
      let tempArr = [];
      detail_data?.bast?.details?.map((item, index) => {
        let data = { description: item?.description, value: item.amount };
        tempArr.push(data);
      });
      setNilaiBAST(tempArr);
      // filter category
      let categoryArr = [];

      const allowToEdit = allowEdit(detail_data)

      const isApprovedOrBast = detail_data?.status === 'bast' || detail_data?.status === 'approved'

      detail_data?.po?.checklists?.map((item, index) => {
        if(
          !getListExclude(detail_data?.po?.doc_type).includes(item?.checklist_attachment?.name
        )){ //filter
          if(allowIgnoreChecklist(item)){
            let tempData = {
              id: index,
              checklist_doc_id: item?.id,
              name: item?.checklist_attachment?.name,
              desc: item?.checklist_attachment?.description,
              check_id: item?.checklist_attachment?.id,
              identity: `checklist_${item?.id}`,
              allowToEdit: allowToEdit,
              isCheck: isApprovedOrBast || !!get(detail_data, ['approval_checklist'], []).find(e => e.master_checklist_id === item?.id && e.status === 'approved' && e?.user?.username === get(data, ['user', 'username'])),
              note: '',
              file: item?.statuses,
            };
            categoryArr.push(tempData);
          }
        }
      });
      // filtering category for upload
      setTempCategory(categoryArr);
      const history = Lib.generateApprovalList(detail_data.approval_checklist)
      const history2 = Lib.generateApprovalBAST(detail_data.approval_bast, get(detail_data, ['po', 'checklists']))

      // console.log(history2)
      // build data for history
      // let tempUser = [];
      // detail_data?.approval_checklist?.map((item, index) => {
      //   let tempData = item?.user;
      //   tempUser.push(tempData);
      // });

      // let uniqueUser = uniqBy(tempUser, 'id');

      // let newData = [];
      // uniqueUser.map((user) => {
      //   let dataEachuser = detail_data?.approval_checklist.filter(
      //     (item) => item.user.id === user.id
      //   );

      //   newData.push({ user: user, dataEachUser: dataEachuser });
      // });

      setHistoryData(history);
      setHistoryData2(history2);

      // section for bast file

      //Check if user has history bast approved
      const hasHistory = get(detail_data, ['approval_bast'], []).find(e => e.user?.id === get(data, ['user', 'id']))

      if(hasHistory){
        setReviewDone(true)
      } else {
        setReviewDone(false)
      }

      // const isBAST = get(detail_data, ['po', 'checklists'], []).find(e => e?.checklist_attachment?.name === globalConfig.proforma.checklistInclude)
      
      if (
        detail_data?.bast?.bast_file ||
        detail_data?.bast?.invoice_file ||
        detail_data?.bast?.tax_file
      ) {

        const dktFile = [
          {
            id: 1,
            name: 'Berita Acara Serah Terima',
            desc: 'Berita Acara Serah Terima',
            note: 'Dokumen BAST yang sudah ditandatangani kedua pihak',
            identity: 'bast_file',
            file: [detail_data?.bast?.bast_file],
            isCheck: getCheckedBAST(hasHistory, 'bast_file_approved'),
            approval_id: 'bast_approved',
          },
          {
            id: 2,
            name: 'Invoice',
            desc: 'Invoice',
            identity: 'invoice_file',
            file: [detail_data?.bast?.invoice_file],
            type: 'value',
            isCheck: getCheckedBAST(hasHistory, 'invoice_file_approved'),
            approval_id: 'invoice_approved',
          },
          {
            id: 3,
            name: 'Bukti Potong Pajak',
            desc: 'Bukti Potong Pajak',
            file: [detail_data?.bast?.tax_file],
            identity: 'tax_file',
            isCheck: getCheckedBAST(hasHistory, 'tax_file_approved'),
            approval_id: 'tax_approved',
          },
        ]
        
        // setTempFileDKT(dktFile);

        const tempDkt = []
      
        for (const i of globalConfig.proforma.checklistInclude){
          if(get(detail_data, ['po', 'checklists'], []).find(e => e?.checklist_attachment?.name === i)){
            tempDkt.push(dktFile.find(e => e.name === i))
          }
        }
  
        setTempFileDKT(tempDkt)
        setDetailBAST(true)
        setDetailFile(true);
      } else {
        const dktFile = [
          {
            id: 1,
            name: 'Berita Acara Serah Terima',
            desc: 'Berita Acara Serah Terima',
            note: 'Dokumen BAST yang sudah ditandatangani kedua pihak',
            identity: 'bast_file',
            file: [],
            isCheck: getCheckedBAST(hasHistory, 'bast_file_approved'),
            approval_id: 'bast_approved',
          },
          {
            id: 2,
            name: 'Invoice',
            desc: 'Invoice',
            identity: 'invoice_file',
            file: [],
            type: 'value',
            isCheck: getCheckedBAST(hasHistory, 'invoice_file_approved'),
            approval_id: 'invoice_approved',
          },
          {
            id: 3,
            name: 'Bukti Potong Pajak',
            desc: 'Bukti Potong Pajak',
            file: [],
            identity: 'tax_file',
            isCheck: getCheckedBAST(hasHistory, 'tax_file_approved'),
            approval_id: 'tax_approved',
          },
        ]
        
        // setTempFileDKT(dktFile);

        const tempDkt = []
      
        for (const i of globalConfig.proforma.checklistInclude){
          if(get(detail_data, ['po', 'checklists'], []).find(e => e?.checklist_attachment?.name === i)){
            tempDkt.push(dktFile.find(e => e.name === i))
          }
        }
  
        setTempFileDKT(tempDkt)

        if(detail_data?.status === 'bast'){
          setDetailFile(true);
        }else{
          setDetailFile(false);
        }
        setDetailBAST(false)
      }

      if (
        data?.user?.department?.code === 'FIN' ||
        data?.user?.department?.code === 'OPS'
      ) {
        setCanReviewDKT(true);
      } else {
        setCanReviewDKT(false);
      }
    }
  }, [detail_data]);

  React.useEffect(() => {
    if (nilaiBAST.length > 0) {
      let result = 0;
      nilaiBAST.map((item) => {
        return (result = result + parseInt(item.value));
      });
      setValue('JumlahNilaiPekerjaan', formatRupiah(result) ?? 'none');
      setTotalWork(formatRupiah(result));
    }
  }, [nilaiBAST]);

  React.useEffect(() => {
    let result =
      reverseFormat(dataInput?.Amount) -
      reverseFormat(dataInput?.JumlahNilaiPekerjaan);

    setValue('SisaPO', formatRupiah(result));
  }, [dataInput?.JumlahNilaiPekerjaan]);

  React.useEffect(() => {
    const d = new Date();
    setBastDate({
      day: d.getDate(),
      monthNow: d.getMonth(),
      yearNow: d.getFullYear(),
    });
  }, []);

  React.useEffect(() => {
    let validationSumbit = [];
    tempCategory.map((item) => {
      if (item.isCheck === false && !item.note) {
        validationSumbit.push(false);
      } else {
        validationSumbit.push(true);
      }
    });

    if (validationSumbit.includes(false)) {
      setCanSubmit(false);
    } else {
      setCanSubmit(true);
    }
  }, [tempCategory]);

  React.useEffect(() => {
    if (approval_message) {
      if (approval_message === 'Success Approved') {
        setShowModalSuccess(true);
      } else {
        setType('error');
        setShow(true);
        setNotifText(approval_message);
      }
      dispacth(proforma.setBlankApproval());
    }
  }, [approval_message]);

  React.useEffect(() => {
    if (cancel_message) {
      if (cancel_message === 'Update cancel status proforma invoice Success') {
        setShowModalCancel(false);
        router.push('/proforma-invoice');
      } else {
        setType('error');
        setShow(true);
        setNotifText(cancel_message);
      }
      dispacth(proforma.setBlankCancel());
    }
  }, [cancel_message]);


  React.useEffect(() => {
    if (upload_message) {
      if (upload_message === 'Success BAST Uploaded') {
        setShowModalSuccess(true);
      } else {
        setType('error');
        setShow(true);
        setNotifText(upload_message);
      }
      dispacth(proforma.setBlankUpload());
    }
  }, [upload_message]);

  React.useEffect(() => {

    if (approval_bast_message) {
      if (approval_bast_message === 'BAST Approved Success') {
        setShowModalSuccess(true);
        setReviewDone(true)
      } else {
        setType('error');
        setShow(true);
        setNotifText(approval_bast_message);
      }
      dispacth(proforma.setBlankApprovalBast());
    }
  }, [approval_bast_message]);

  React.useEffect(() => {
    if (process_message) {
      if (process_message === 'Proceed GRPO Notification success.') {
        if(get(data, ['user', 'department', 'code']) === 'FIN'){
          setShowModalGRPOSuccess(true);
        }else{
          setType('success');
          setShow(true);
          setNotifText(process_message);
          setBtnProcessGRPO(false)
        }
      } else {
        setType('error');
        setShow(true);
        setNotifText(process_message);
      }
      dispacth(proforma.setBlankProcessGRPO());
    }
  }, [process_message]);

  const onSuccessOk = () => {
    if(get(data, ['user', 'department', 'code']) === 'FIN' && get(detail_data, ['status']) !== 'approval_in_progress'){
      setShowModalSuccess(false)
    }else {
      router.push('/proforma-invoice')
    }
  }

  const isAllDktChecked = tempFileDKT.filter(e => e.isCheck).length === tempFileDKT.length
  
  return (
    <>
      <Grid xs={12} md={12} className={classes.mainContent}>
        <CustomizedSnackbars
          show={show}
          handleClose={handleClose}
          message={notifText}
          type={type}
        />
        <BASTPreview
          open={showPreview}
          close={() => setShowPreview(false)}
          data={previewData}
        />

        <ModalSuccess
          open={showModalGRPOSuccess}
          message="Proceed GRPO success."
          onPress={() => router.push('/proforma-invoice')}
        />
        <ModalSuccess
          open={showModalConfirmSuccess}
          message="Successfully Confirmed."
          onPress={() => router.push('/proforma-invoice')}
        />

        <ModalSuccess
          open={showModalSuccess}
          onPress={onSuccessOk}
        />
        <ModalConfirm
          open={showModalConfirm}
          close={() => setShowModalConfirm(false)}
          onPress={() => 
            {
              dispacth(proforma.approvalProforma(access_token, id, tempSendData))
              
              if (poStatus) dispacth(proforma.updateProforma(access_token, id, Lib.generateUpadteProformaQuery(detail_data, lowerCase(poStatus))))
            }
          }
          countRevise={countRevise}
          message={meessageConfirm}
          isLoading={approval_isLoading}
        />
        <ModalConfirm
          open={showModalReview}
          close={() => setShoModalReview(false)}
          onPress={() => {
              if(get(data, ['user', 'department', 'code']) === 'FIN'){
                dispacth(proforma.processGRPO(access_token, id, GRPOParams));
              }else {
                dispacth(proforma.approvalProformaBast(access_token, id, reviewSendData))
              }
            }
          }
          countRevise={countRevise}
          message={meessageConfirm}
          isLoading={(detailFile && canReviewDKT && get(data, ['user', 'department', 'code']) === 'FIN') ? process_isLoading : approval_bast_isLoading}
        />
        <ModalConfirmCancel
          open={showModalCancel}
          close={() => setShowModalCancel(false)}
          onPress={() => dispacth(proforma.cancleProforma(access_token, id))}
          isLoading={cancel_isLoading}
        />
        <ModalConfirmUpload
          open={showModalUpload}
          close={() => setShowModalUpload(false)}
          onPress={() =>
            dispacth(proforma.uploadProforma(access_token, id, uploadSendData))
          }
          isLoading={upload_isLoading}
        />
        <HeaderTitle
          variant
          number={detail_data?.po?.doc_type === 'service' ? detail_data?.bast?.proforma_invoice_id : detail_data?.id}
          status={detail_data?.status_label}
          date={detail_data?.bast?.dkt_date}
          loading={detai_isLoading}
        />

        {detai_isLoading ? (
          <LinearProgress />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <SectionSearch
              register={register}
              errors={errors}
              handleSetDataPO={handleSetDataPO}
              dataPO={dataPO}
              detail
            />
            {dataPO ? (
              <>
              {detail_data?.po?.doc_type === 'service' && (
                <>
                <BAST
                  register={register}
                  errors={errors}
                  vendorAddress={vendorAddress}
                  onChangeVendorAddress={onChangeVendorAddress}
                  DKTAddress={DKTAddress}
                  onChangeDKTAddress={onChangeDKTAddress}
                  handleSetBastDate={handleSetBastDate}
                  bastDate={bastDate}
                  poStatus={detail_data?.bast?.rest_po_status}
                  setPoStatus={setPoStatus}
                  detail
                />
                <BASTITEM
                  nilaiBAST={nilaiBAST}
                  setNilaiBAST={setNilaiBAST}
                  detail
                />
                <Box className={classes.btnContent}>
                  <Box className={classes.btnPeview} onClick={handlePreview}>
                    <img src={EyeWhite} alt="none" />
                    <ContentText
                      content={`Preview BAST`}
                      styling={classes.previewText}
                    />
                  </Box>
                  <Box className={classes.btnPrint} onClick={handlePrint}>
                    <img src={Print} alt="none" />
                    <ContentText
                      content={`Print`}
                      styling={classes.previewText}
                    />
                  </Box>
                </Box>
                <Box className={classes.sizeBorder} />
                </>)}

                <ReportDoc
                  tempCategory={tempCategory}
                  handleAddFile={handleAddFile}
                  handleRemoveFile={handleRemoveFile}
                  handleAddMoreFile={handleAddMoreFile}
                  tempFileDKT={tempFileDKT}
                  status={detail_data?.status_label}
                  detail
                  dataUser={data}
                  showSectionDKT={detail_data?.po?.doc_type === 'service'}
                  handleChangeCheckBox={handleChangeCheckBox}
                  handleChangeComment={handleChangeComment}
                  handleAddDktFile={handleAddDktFile}
                  handleRemoveDktFile={handleRemoveDktFile}
                  noInvoice={detail_data?.bast?.dkt_code}
                  totalWork={totalWork}
                  detail_data={detail_data}
                  detailFile={detailBAST}
                  handleChangeCheckBoxDkt={handleChangeCheckBoxDkt}
                  canReviewDKT={canReviewDKT}
                  setInvoiceField={setInvoiceField}
                  invoiceField={invoiceField}
                />

                <Box className={classes.containerAction}>
                  <Box className={classes.ActionContent}>
                    {detail_data?.status_label === 'Verification' && detail_data?.po?.doc_type === 'service' ? (
                      <input
                        type="submit"
                        disabled={!allowEdit(detail_data)}
                        className={
                          canSubmit ? classes.submitBtn : classes.submitBtnFalse
                        }
                        value="Review Done"
                      />
                    ) : detail_data?.status_label === 'Process BAST' &&
                        detail_data?.user_ids.includes(data?.user?.id) &&
                        data?.user?.department?.code !== 'FIN' ? (
                      <>
                        <Box
                          className={detail_data?.bast?.is_sent_email ? classes.submitBtnFalse : classes.submitBtn}
                          onClick={detail_data?.bast?.is_sent_email ? null : onUploadDKT}
                        >
                          <ContentText content={'Upload Document'} />
                        </Box>
                      </>
                    ) : null}
                    {detailFile && canReviewDKT ? (
                      <>
                        <Box
                          className={
                            (!isAllDktChecked || isReviewDone || approval_bast_isLoading) ? classes.submitBtnFalse : classes.submitBtn
                          }
                          onClick={(!isAllDktChecked || isReviewDone ||approval_bast_isLoading) ? null : onReviewDkt}
                        >
                          <ContentText content={'Review Done'} />
                        </Box>
                        {data?.user?.department?.code === 'FIN' && (
                            <Box
                              className={
                                (!isReviewDone || process_isLoading || !btnProcessGRPO)
                                  ? classes.submitBtnFalse
                                  : classes.submitBtn
                              }
                              onClick={
                                (!isReviewDone || process_isLoading || !btnProcessGRPO)
                                  ? null
                                  : onProcessGRPO
                              }
                            >
                              <ContentText
                                content={
                                  process_isLoading
                                    ? 'Sending Request'
                                    : 'Process GRPO'
                                }
                              />
                            </Box>
                          )}
                      </>
                    ) : null}

                    {/* button confirm */}
                    { detail_data?.po?.doc_type === 'item' && (
                      <Box 
                        className={(loadingConfirm || detail_data?.status === 'approved') ? classes.submitBtnFalse :classes.submitGreenBtn}
                        onClick={()=>detail_data?.status === 'approved' ? null : onConfirmed()}
                      >
                        <ContentText content='Confirmed' />
                      </Box>
                    )}

                  </Box>
                  {Lib.allowCancel(data) &&
                  <Box
                    className={classes.cancelBtnRed}
                    onClick={() => setShowModalCancel(true)}
                  >
                    <ContentText
                      styling={classes.cancelText}
                      content={'Cancel Proforma Invoice'}
                    />
                  </Box>}
                </Box>
              </>
            ) : null}
          </form>
        )}
      </Grid>
      {(detai_isLoading || detail_data?.po?.doc_type === 'item') ? null : (
        <>
          <ApprovalHistory
            // tempFileDKT={tempFileDKT}
            tempCategory={tempCategory}
            historyData={historyData}
          />
          <ApprovalHistory2 data={historyData2} />
        </>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  mainContent: {
    borderRadius: 8,
    background: '#fff',
  },
  btnContent: {
    padding: '30px 20px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  sizeBorder: {
    margin: '0px 20px',
    borderBottom: '1px solid #E7E7E8',
  },
  btnPeviewDisable: {
    borderRadius: 8,
    background: '#969BAB',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 181,
    padding: '9.5px 14px',
    cursor: 'pointer',
  },
  btnPeview: {
    borderRadius: 8,
    background: '#127EEE',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 181,
    padding: '9.5px 14px',
    cursor: 'pointer',
  },
  previewText: {
    marginLeft: 10,
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 700,
    color: '#fff',
  },
  btnPrint: {
    borderRadius: 8,
    background: '#127EEE',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 181,
    padding: '9.5px 14px',
    cursor: 'pointer',
    marginLeft: 20,
  },
  containerAction: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid #E7E7E8',
  },
  ActionContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 20,
  },
  cancelBtn: {
    height: 50,
    width: 193,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  submitBtn: {
    height: 50,
    width: 193,
    textAlign: 'center',
    background: '#1B2030',
    borderRadius: 8,
    color: '#fff',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: 20,
  },
  submitGreenBtn: {
    height: 50,
    width: 193,
    textAlign: 'center',
    background: 'green',
    borderRadius: 8,
    color: '#fff',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: 20,
  },
  submitBtnFalse: {
    height: 50,
    width: 193,
    textAlign: 'center',
    background: '#969BAB',
    borderRadius: 8,
    color: '#fff',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 700,
    marginRight: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cancelBtnRed: {
    height: 50,
    width: 200,
    cursor: 'pointer',
    marginLeft: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cancelText: {
    marginRight: 20,
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 700,
    color: '#FD453A',
  },
}));

export default DetailProforma;
