import React from 'react';
import { makeStyles, Box, Grid } from '@material-ui/core';

// Icons
import Trash from '../../../assets/Icons/trash.png';
import TrashRed from '../../../assets/Icons/trashRed.png';

function preventKeyDown(e){
  const keyCode = [38, 40, 189]
  if (keyCode.includes(e.which)) {
    e.preventDefault();
  }
}

const inputNumberProps = { 
  type: 'number', 
  min:"0", 
  onKeyDown: preventKeyDown 
}

export const ContactPerson = ({
  inputContact,
  index,
  handleChangeInput,
  handleRemoveInput,
}) => {
  const classes = useStyles();

  const [deleteHover, setDeleteHover] = React.useState(false);

  return (
    <Grid key={index} md={12} container className={classes.wrapperForm}>
      <Grid md={12} container>
        <Grid md>
          <input
            className={`${classes.inputMenu} inputstyle`}
            name="name"
            placeholder="Input text"
            value={inputContact.name}
            onChange={(event) => handleChangeInput(index, event)}
            maxLength={30}
          />
        </Grid>
        <Grid md>
          <input
            className={`${classes.inputMenu} inputstyle`}
            name="position"
            placeholder="Input text"
            value={inputContact.position}
            onChange={(event) => handleChangeInput(index, event)}
            maxLength={20}
          />
        </Grid>
        <Grid md>
          <input
            className={`${classes.inputMenu} inputstyle no-spin`}
            name="phone"
            placeholder="Input number"
            value={inputContact.phone}
            onChange={(event) => handleChangeInput(index, event)}
            maxLength={20}
            autoComplete={`new-label`}
            {...inputNumberProps}
          />
        </Grid>
        <Grid md>
          <input
            className={`${classes.inputMenu} inputstyle`}
            name="email"
            placeholder="Input text"
            value={inputContact.email}
            onChange={(event) => handleChangeInput(index, event)}
            maxLength={40}
            autoComplete={`new-label`}
          />
        </Grid>
        <Grid md className={classes.ContentFlex}>
          <input
            {...inputNumberProps}
            className={`${classes.inputMenuLast} inputstyle no-spin`}
            name="ktp"
            placeholder="Input number"
            value={inputContact.ktp}
            onChange={(event) => handleChangeInput(index, event)}
            maxLength={20}
            autoComplete={`new-label`}
          />
          <Box
            onMouseOver={() => setDeleteHover(true)}
            onMouseOut={() => setDeleteHover(false)}
            className={classes.deleteBtn}
            onClick={() => handleRemoveInput(index)}
          >
            <img src={deleteHover ? TrashRed : Trash} />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapperForm: {
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex',
    alignItems: 'center',
  },
  inputMenu: {
    width: '90%',
    height: 50,
    borderRadius: 8,
    paddingLeft: 20,
    border: '1px solid #E7E7E8',
    backgroundColor: '#F8FAFB',
    outline: 'none',
    '&.inputstyle:focus': {
      border: '1.5px solid #F2C94C',
      outline: 'none',
      boxShadow: '0px 0px 0px 4px rgba(242, 201, 76, 0.17);',
      borderRadius: 8,
    },
  },
  inputMenuLast: {
    width: '85%',
    height: 50,
    borderRadius: 8,
    paddingLeft: 20,
    border: '1px solid #E7E7E8',
    backgroundColor: '#F8FAFB',
    outline: 'none',
    '&.inputstyle:focus': {
      border: '1.5px solid #F2C94C',
      outline: 'none',
      boxShadow: '0px 0px 0px 4px rgba(242, 201, 76, 0.17);',
      borderRadius: 8,
    },
  },
  ContentFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  deleteBtn: {
    cursor: 'pointer',
    marginLeft: 12,
  },
}));
