import { isEmpty, get, groupBy } from "lodash";
import globalConfig from "src/config/global";
import Logo from 'src/assets/Images/logoForm.png';
import { ROOT_API_URL } from 'src/config/EndPoint';

function ellipsis(text, maxLength = 18) {
  if (!text) return text;
  else if (text.length < maxLength) return text;

  const extension = text.split(".").pop();

  const title = text.slice(0, maxLength);
  return (
    <span style={{ whiteSpace: "nowrap" }}>
      {title}....{extension}
    </span>
  );
}

function generateApprovalList(data){
  const files = []
  for(const obj of data){
    for(const statuses of get(obj, ['checklist', 'checklist_attachment', 'statuses'], [])){
      
      for(const file of get(statuses, ['files'], [])){
        
        files.push({ user: obj.user, ...file, message: obj.notes, doc: get(obj, ['checklist', 'checklist_attachment']), status: obj.status, updated_at: obj.updated_at })
      }

    }
  
  }
  
  const groupped = groupBy(files, 'id')
  
  const results = []

  for(const i in groupped){
    const message = get(groupped, `[${i}]`, []).find(e => e.message)

    let text = ''
    
    if(message) text = message.message

    results.push({ name: get(groupped[i], '[0].doc.name', ''), message: text, user: groupped[i] })
  }

  return results
}

function generateApprovalBAST(data, availableChecklist){
  const availableCheck = availableChecklist.map(r => r?.checklist_attachment?.name)
  
  const bast_file_approved = []
  const tax_file_approved = []
  const invoice_file_approved = []
  
  for(const obj of data){
    if(availableCheck.includes('Berita Acara Serah Terima')){
      bast_file_approved.push({ bast_file_approved: obj.bast_file_approved, user: obj.user, updated_at: obj.updated_at })
    }
    
    if(availableCheck.includes('Bukti Potong Pajak')){
      tax_file_approved.push({ tax_file_approved: obj.tax_file_approved, user: obj.user, updated_at: obj.updated_at })
    }

    if(availableCheck.includes('Invoice')){
      invoice_file_approved.push({ invoice_file_approved: obj.invoice_file_approved, user: obj.user, updated_at: obj.updated_at })
    }
  }
  const results = [bast_file_approved, tax_file_approved, invoice_file_approved]
  
  return results
}

function allowCancel(user){

  let allow = false
  const level = get(user, ['user', 'role'], [])

  for(const r of globalConfig.cancelButton.showForLevel){
    if(level.includes(r)){
      allow = true
    }
  }
  return allow
}

function generateUpadteProformaQuery(data, newQuery) {
  const wrappedData = new FormData();
  wrappedData.append(`_method`, "put");
  wrappedData.append(`bast_date`, data?.bast?.dkt_date);
  wrappedData.append(`name_1`, data?.bast?.name_1);
  wrappedData.append(`position_1`, data?.bast?.position_1);
  wrappedData.append(`company_1`, data?.bast?.company_1);
  wrappedData.append(`address_1`, data?.bast?.address_1);
  wrappedData.append(`name_2`, data?.bast?.name_2);
  wrappedData.append(`position_2`, data?.bast?.position_2);
  wrappedData.append(`company_2`, data?.bast?.company_2);
  wrappedData.append(`address_2`, data?.bast?.address_2);
  wrappedData.append(`periode`, data?.bast?.periode);
  wrappedData.append(`amount_po`, data?.bast?.amount_po);
  wrappedData.append(`amount_po_tax`, data?.bast?.amount_po_tax);
  wrappedData.append(`amount_po_total`, data?.bast?.amount_po_total);
  wrappedData.append(`bast_total`, data?.bast?.bast_total);
  wrappedData.append(`rest_po`, data?.bast?.rest_po);
  wrappedData.append(`activity_name`, data?.bast?.activity_name);
  wrappedData.append(`rest_po_status`, newQuery);

  if (!isEmpty(data?.bast?.details)) {
    let i = 0;
    for (const j of data?.bast?.details) {
      wrappedData.append(`bast_detail[${i}][description]`, j.description);
      wrappedData.append(`bast_detail[${i}][amount]`, j.amount);
      i += 1;
    }
  }

  return wrappedData;
}

function isSuperAdmin(data){
  if(get(data, ['user', 'role'], []).includes('superadmin')) return true

  return false
}

const getLogo = (data) => {
  if(globalConfig.entity[data?.entity?.code]){
    return (
      <img 
        src={`${ROOT_API_URL}/dist/images/${globalConfig.entity[data?.entity?.code].image}`} 
        width={90} 
        height={90} 
        alt=""
        style={{ marginTop: '10px' }}
      />)
  }
  return <img src={Logo} style={{ marginTop: '-12px' }} width={150} height={150} alt="" />
}

export default {
  ellipsis,
  generateUpadteProformaQuery,
  generateApprovalList,
  generateApprovalBAST,
  allowCancel,
  getLogo,
  isSuperAdmin,
};
