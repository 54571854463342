import React from 'react';
import { Box, Grid, makeStyles, Checkbox } from '@material-ui/core';

const PermissionItem = ({ index, item, handleCheckPermission }) => {
  const classes = useStyles();
  return (
    <>
      <Grid
        md={12}
        container
        className={
          index % 2 == 0 ? classes.wrapperList : classes.wrapperListSecond
        }
      >
        <Grid md={5} className={classes.itemList}>
          {item.description}
        </Grid>
        <Grid md={5} className={classes.itemList}>
          {item.display_name}
        </Grid>
        <Grid md={2} className={classes.itemList}>
          <Checkbox
            name={item.id}
            onChange={handleCheckPermission}
            color="primary"
            className={classes.checkbox}
          />
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  wrapperList: {
    height: 70,
    backgroundColor: '#F8FAFB',
    // borderRight: '1px solid #E7E7E8',
    // borderLeft: '1px solid #E7E7E8',
  },
  wrapperListSecond: {
    height: 70,
    backgroundColor: '#fff',
    // borderRight: '1px solid #E7E7E8',
    // borderLeft: '1px solid #E7E7E8',
  },
  itemList: {
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 20,
  },
  checkbox: {
    '&.MuiCheckbox-colorPrimary.Mui-checked': {
      color: '#2B3595',
    },
  },
});

export default PermissionItem;
