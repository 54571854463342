import React from 'react';
import { makeStyles, Grid, Box, TextareaAutosize } from '@material-ui/core';
import moment from 'moment';

// Components
import { ContentText } from '../../../../libs/Typography';
import InputGroup from '../../../../components/inputGroup';

// Icons
import DownArrow from '../../../../assets/Icons/downArrow.png';
import Cross from '../../../../assets/Icons/cross.png';
import { number } from 'yup';
import { startCase } from 'lodash';

const SectionDropDown = ({ title, data, value, disable, setValue, month }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [valueWant, setValueWant] = React.useState(value);

  const handleOpenModal = () => {
    setOpen(!open);
  };

  const handleOnclick = (item) => {
    setValueWant(item);
    if (setValue) {
      setValue(item);
    }
    setOpen(false);
  };

  const handleMonth = (item, monthNumber) => {
    setValueWant(item);
    if (setValue) {
      setValue(monthNumber);
    }
    setOpen(false);
  };

  return (
    <>
      <Box className={classes.sectionContiner}>
        <ContentText content={title} styling={classes.label} />
        <Box
          className={classes.boxContent}
          onClick={disable ? null : handleOpenModal}
        >
          <ContentText
            content={valueWant ?? `Select`}
            styling={classes.placeholderPO}
          />
          {valueWant ? (
            <img
              src={Cross}
              alt="none"
              className={classes.iconCross}
              onClick={disable ? null : () => setValueWant(null)}
            />
          ) : (
            <img src={DownArrow} alt="none" className={classes.icon} />
          )}
        </Box>
        {open && (
          <Box className={classes.card}>
            <Box className={classes.contentList}>
              {data.map((item, index) => (
                <Box
                  key={index}
                  className={classes.contentItem}
                  onClick={
                    month
                      ? () => handleMonth(item, index + 1)
                      : () => handleOnclick(item)
                  }
                >
                  {item}
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

const BAST = (props) => {
  const {
    register,
    errors,
    vendorAddress,
    onChangeVendorAddress,
    DKTAddress,
    onChangeDKTAddress,
    handleSetBastDate,
    detail,
    bastDate,
    poStatus,
  } = props;
  const classes = useStyles();

  const libmonth = [
    'January',
    'February',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember',
  ];
  const libStatusPO = ['Close', 'Open'];

  const [day, setDay] = React.useState(bastDate?.day);
  const [monthNow, setMonthNow] = React.useState(bastDate?.monthNow);
  const [yearNow, setYearNow] = React.useState(bastDate?.yearNow);
  const [endOfdate, setEndOfdate] = React.useState(31);

  let arrayTahun = [];

  let statusPo = libStatusPO[1]

  if(poStatus) statusPo = startCase(poStatus)

  const libStatusBAST = ['DRAFT', 'REVISE'];

  for (let i = 1995; i < yearNow; i++) {
    arrayTahun.push(i);
  }

  arrayTahun.reverse();

  React.useEffect(() => {
    if (!detail) {
      handleSetBastDate({ day, monthNow, yearNow })
    
      const customMoment = moment();
      customMoment.set({ year: yearNow });
      customMoment.set('month', monthNow - 1)

      const last_date = moment(customMoment).endOf("month");
      const dateNumber = parseInt(last_date.format("D"));
    
      setEndOfdate(dateNumber)
    
    }
  }, [day, monthNow, yearNow]);
  
  return (
    <Grid xs={12} md={12} className={classes.mainContainer}>
      <ContentText content={`BAST`} styling={classes.mainTitle} />
      <Grid xs={12} md={12} container className={classes.section}>
        <Grid item xs={6} md={6} className={classes.sectionLeft}>
          <InputGroup
            label="NOBASTDKT"
            placeholder="Will Generate automatic by sistem"
            register={register}
            required
            type="text"
            disable={true}
          />
          <p className={classes.errorText}>{errors?.NOBASTDKT?.message}</p>
        </Grid>
        <Grid container xs={6} md={6} className={classes.sectionRight}>
          <Grid item xs={4} md={4} className={classes.sectionTanggal}>
            <SectionDropDown
              title={'Tanggal'}
              data={Array.from({ length: parseInt(endOfdate) }, (_, i) => i + 1)}
              value={detail ? bastDate.day : day}
              setValue={setDay}
              disable={detail ? true : false}
            />
          </Grid>
          <Grid item xs={4} md={4} className={classes.sectionBulan}>
            <SectionDropDown
              title={'Bulan'}
              data={libmonth}
              value={libmonth[parseInt(monthNow - 1)]}
              setValue={setMonthNow}
              month
              disable={detail ? true : false}
            />
          </Grid>
          <Grid item xs={4} md={4} className={classes.sectionTahun}>
            <SectionDropDown
              title={'Tahun'}
              data={arrayTahun}
              value={yearNow}
              setValue={setYearNow}
              disable={detail ? true : false}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} md={12} className={classes.section} container>
        <Grid item xs={6} md={6} className={classes.sectionLeft}>
          <InputGroup
            label="NOBASTVENDOR"
            placeholder="input text"
            register={register}
            required
            type="text"
            disable={detail ? true : false}
          />
          <p className={classes.errorText}>{errors?.NOBASTVENDOR?.message}</p>
        </Grid>
        <Grid item xs={6} md={6} className={classes.sectionRight}>
          <InputGroup
            label="JOBDESC"
            placeholder="input text"
            register={register}
            required
            type="text"
            disable={detail ? true : false}
          />
          <p className={classes.errorText}>{errors?.JOBDESC?.message}</p>
        </Grid>
      </Grid>
      <Box className={classes.sizeBorder} />
      <Grid xs={12} md={12} container className={classes.section}>
        <Grid xs={6} md={6} item className={classes.sectionLeft}>
          <ContentText
            content={`Pihak Kesatu (Vendor Respresentative)`}
            styling={classes.mainTitle}
          />
        </Grid>
        <Grid xs={6} md={6} item className={classes.sectionRight}>
          <ContentText
            content={`Pihak Kedua (DKT Respresentative)`}
            styling={classes.mainTitle}
          />
        </Grid>
      </Grid>

      <Grid xs={12} md={12} className={classes.section} container>
        <Grid container xs={6} md={6} className={classes.sectionLeft}>
          <Grid xs={6} md={6} className={classes.padRight}>
            <InputGroup
              label="NamaVendor"
              expectLabel="Nama"
              placeholder="input text"
              register={register}
              required
              type="text"
              disable={detail ? true : false}
            />
            <p className={classes.errorText}>{errors?.NamaVendor?.message}</p>
          </Grid>
          <Grid xs={6} md={6} className={classes.padLeft}>
            <InputGroup
              label="JabatanVendor"
              expectLabel="Jabatan"
              placeholder="input text"
              register={register}
              required
              type="text"
              disable={detail ? true : false}
            />
            <p className={classes.errorText}>
              {errors?.JabatanVendor?.message}
            </p>
          </Grid>
        </Grid>

        <Grid container xs={6} md={6} className={classes.sectionRight}>
          <Grid xs={6} md={6} className={classes.padRight}>
            <InputGroup
              label="NamaDKT"
              expectLabel="Nama"
              placeholder="input text"
              register={register}
              required
              type="text"
              disable={detail ? true : false}
            />
            <p className={classes.errorText}>{errors?.NamaDKT?.message}</p>
          </Grid>
          <Grid xs={6} md={6} className={classes.padLeft}>
            <InputGroup
              label="JabatanDKT"
              expectLabel="Jabatan"
              placeholder="input text"
              register={register}
              required
              type="text"
              isRequire={true}
              disable={detail ? true : false}
            />
            <p className={classes.errorText}>{errors?.JabatanDKT?.message}</p>
          </Grid>
        </Grid>
      </Grid>

      <Grid xs={12} md={12} className={classes.section} container>
        <Grid item xs={6} md={6} className={classes.sectionLeft}>
          <InputGroup
            label="NamaPerusahaanVendor"
            expectLabel="Nama Perusahaan/Institusi"
            placeholder="input text"
            register={register}
            required
            type="text"
            isRequire={true}
            disable={detail ? true : false}
          />
          <p className={classes.errorText}>
            {errors?.NamaPerusahaanVendor?.message}
          </p>
        </Grid>
        <Grid item xs={6} md={6} className={classes.sectionRight}>
          <InputGroup
            label="NamaPerusahaanDKT"
            expectLabel="Nama Perusahaan/Institusi"
            placeholder="input text"
            register={register}
            required
            type="text"
            isRequire={true}
            disable={detail ? true : false}
          />
          <p className={classes.errorText}>
            {errors?.NamaPerusahaanDKT?.message}
          </p>
        </Grid>
      </Grid>
      <Grid xs={12} md={12} className={classes.section} container>
        <Grid item xs={6} md={6} className={classes.sectionLeft}>
          <ContentText
            content={`Alamat Perusahaan/Intitusi`}
            styling={classes.label}
          />
          <TextareaAutosize
            className={classes.inputMenuTextArea}
            // aria-label="empty textarea"
            placeholder="Input text"
            minRows={5}
            cols={12}
            {...register('VendorAddress')}
            readOnly={detail ? true : false}
            maxLength={160}
          />
          <p className={classes.errorText}>{errors?.VendorAddress?.message}</p>
        </Grid>
        <Grid item xs={6} md={6} className={classes.sectionRight}>
          <ContentText
            content={`Alamat Perusahaan/Intitusi`}
            styling={classes.label}
          />
          <TextareaAutosize
            className={classes.inputMenuTextArea}
            // aria-label="empty textarea"
            placeholder="Input text"
            minRows={5}
            cols={12}
            {...register('DKTAddress')}
            readOnly={detail ? true : false}
            maxLength={160}
          />
          <p className={classes.errorText}>{errors?.DKTAddress?.message}</p>
        </Grid>
      </Grid>

      <Grid xs={12} md={12} container className={classes.section}>
        <Grid xs={6} md={6} item className={classes.sectionLeft}>
          <ContentText
            content={`Sumary Purchase Order`}
            styling={classes.mainTitle}
          />
        </Grid>
      </Grid>
      <Grid xs={12} md={12} className={classes.section} container>
        <Grid item xs={6} md={6} className={classes.sectionLeft}>
          <InputGroup
            label="NamaKegiatan"
            expectLabel="Nama Kegiatan/Pekerjaan/Jasa (Sesuai PO/Kontrak)"
            placeholder="input text"
            register={register}
            required
            type="text"
            isRequire={true}
            disable={true}
          />
          <p className={classes.errorText}>{errors?.NamaKegiatan?.message}</p>
        </Grid>
        <Grid item xs={6} md={6} className={classes.sectionRight}>
          <InputGroup
            label="Periode"
            placeholder="input text"
            register={register}
            required
            type="text"
            isRequire={true}
            disable={detail ? true : false}
          />
          <p className={classes.errorText}>{errors?.Periode?.message}</p>
        </Grid>
      </Grid>
      <Grid xs={12} md={12} className={classes.section} container>
        <Grid container xs={6} md={6} className={classes.sectionLeft}>
          <Grid xs={6} md={6} className={classes.padRight}>
            <InputGroup
              label="PONumber"
              expectLabel="PO Number"
              placeholder="input text"
              register={register}
              required
              type="text"
              disable={true}
              isRequire={true}
            />
            <p className={classes.errorText}>{errors?.PONumber?.message}</p>
          </Grid>
          <Grid xs={6} md={6} className={classes.padLeft}>
            <InputGroup
              label="AmountBeforeTax"
              expectLabel="Amount (before Tax)"
              placeholder="input text"
              register={register}
              required
              type="text"
              disable={true}
              isRequire={true}
              align="right"
            />
            <p className={classes.errorText}>
              {errors?.AmountBeforeTax?.message}
            </p>
          </Grid>
        </Grid>
        <Grid container xs={6} md={6} className={classes.sectionRight}>
          <Grid xs={6} md={6} className={classes.padRight}>
            <InputGroup
              label="TAX"
              placeholder="input text"
              register={register}
              required
              type="text"
              disable={true}
              align="right"
            />
            <p className={classes.errorText}>{errors?.TAX?.message}</p>
          </Grid>
          <Grid xs={6} md={6} className={classes.padLeft}>
            <InputGroup
              label="TotalWithTax"
              expectLabel="Total (Include Tax)"
              placeholder="input text"
              register={register}
              required
              type="text"
              isRequire={true}
              disable={true}
              align="right"
            />
            <p className={classes.errorText}>{errors?.TotalWithTax?.message}</p>
          </Grid>
        </Grid>
      </Grid>

      <Grid xs={12} md={12} className={classes.section} container>
        <Grid container xs={6} md={6} className={classes.sectionLeft}>
          <Grid xs={6} md={6} className={classes.padRight}>
            <SectionDropDown
              value={libStatusBAST[0]}
              title={'Status BAST'}
              data={libStatusBAST}
              disable={true}
            />
          </Grid>
          <Grid xs={6} md={6} className={classes.padLeft}>
            <SectionDropDown
              title={'Status PO'}
              data={libStatusPO}
              value={statusPo}
              disable={true}
            />
          </Grid>
        </Grid>
        <Grid container xs={6} md={6} className={classes.sectionRight}>
          <Grid xs={6} md={6} className={classes.padRight}>
            <InputGroup
              label="JumlahNilaiPekerjaan"
              expectLabel="Jumlah Nilai Perkerjaan"
              placeholder="Will generate after Nilai BAST"
              register={register}
              required
              type="text"
              isRequire={true}
              disable={true}
              align="right"
            />
            <p className={classes.errorText}>
              {errors?.JumlahNilaiPekerjaan?.message}
            </p>
          </Grid>
          <Grid xs={6} md={6} className={classes.padLeft}>
            <InputGroup
              label="SisaPO"
              expectLabel="Sisa PO"
              placeholder="input text"
              register={register}
              required
              type="text"
              isRequire={true}
              disable={true}
              align="right"
            />
            <p className={classes.errorText}>{errors?.SisaPO?.message}</p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: '30px 20px',
    background: '#fff',
  },
  mainTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 700,
    color: '#969BAB',
  },
  section: {
    marginTop: 20,
  },
  sectionLeft: {
    paddingRight: 15,
  },
  sectionRight: {
    paddingLeft: 15,
  },
  sectionTanggal: {
    paddingRight: 5,
    position: 'relative',
  },
  sectionBulan: {
    paddingRight: 5,
    paddingLeft: 5,
    position: 'relative',
  },
  sectionTahun: {
    paddingLeft: 5,
    position: 'relative',
  },
  // Section Dropdonw
  SectionContainer: {
    position: 'relative',
  },
  label: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 700,
    color: '#1B2030',
  },
  icon: {
    width: 14,
    height: 12,
  },
  iconCross: {
    width: 20,
    height: 20,
  },
  boxContent: {
    marginTop: 12,
    width: '100%',
    height: 50,
    border: '1px solid #E7E7E8',
    background: '#F8FAFB',
    borderRadius: 8,
    cursor: 'pointer',
    position: 'relative',
    padding: '14px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  card: {
    marginTop: 4,
    width: '98%',
    background: '#fff',
    position: 'absolute',
    borderRadius: 8,
    border: ' 1px solid #E7E7E8',
    padding: '5px 11px',
    minHeight: 150,
    maxHeight: 202,
    paddingRight: 5,
  },
  contentList: {
    maxHeight: `calc(202px - 54px)`,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
  },
  contentItem: {
    height: 46,
    width: '100%',
    cursor: 'pointer',
    paddingTop: '15px',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '21px',
    color: '#1B2030',
  },
  // end of select
  sizeBorder: {
    marginTop: 30,
  },
  padLeft: {
    position: 'relative',
    paddingLeft: 5,
  },
  padRight: {
    position: 'relative',
    paddingRight: 5,
  },
  inputMenuTextArea: {
    marginTop: 12,
    width: '100% !important',
    paddingLeft: 15,
    paddingTop: 14,
    paddingRight: 21,
    borderRadius: 8,
    border: '1px solid #E7E7E8',
    backgroundColor: '#F8FAFB',
    outline: 'none',
    '&.inputstyle:focus': {
      border: '1.5px solid #F2C94C',
      outline: 'none',
      boxShadow: '0px 0px 0px 4px rgba(242, 201, 76, 0.17);',
      borderRadius: 8,
    },
  },
  errorText: {
    fontSize: 14,
    color: 'red',
    marginTop: 8,
  },
}));

export default BAST;
