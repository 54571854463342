import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, LinearProgress } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

// images
import Danger from '../../assets/Icons/Danger.png';
import DangerBlue from '../../assets/Icons/DangerBlue.png';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 20,
      paddingRight: 20,
      width: '100vw',
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    paddingTop: 50,
    paddingBottom: 26,
    borderRadius: 18,
    width: 467,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100vw',
    },
  },
  notifText: {
    marginTop: 32,
    fontSize: 36,
    lineHeight: '49px',
    color: '#000000',
    fontWeight: 'bold',
    marginBottom: 22,
  },
  textConfirm: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
  },
  textConfirmBold: {
    marginLeft: 4,
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
    fontWeight: 'bold',
  },
  borderLine: {
    marginTop: 53,
    width: '100%',
    border: '1px solid #E7E7E8',
  },
  btnContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 20,
    paddingTop: 17,
    paddingBottom: 14,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  btnSubmit: {
    width: 183,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1B2030',
    borderRadius: 8,
    cursor: 'pointer',
    marginLeft: 18,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      maxWidth: 150,
    },
  },
  btnCancelFalse: {
    color: '#969BAB',
    cursor: 'not-allowed',
    fontSize: 16,
    lineHeight: '27px',
  },
  btnSubmitFalse: {
    width: 183,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#969BAB',
    borderRadius: 8,
    marginLeft: 18,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      maxWidth: 150,
    },
  },

  btnSubmitText: {
    fontSize: 16,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#fff',
  },
  btnCancel: {
    width: 183,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    borderRadius: 8,
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      maxWidth: 150,
    },
  },
  btnCancelText: {
    fontSize: 16,
    lineHeight: '27px',
    color: '#1B2030',
  },
  wrapperText: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
}));

export default function ModalApproveMessage({
  open,
  close,
  isApprove,
  title,

  onPress,
  isLoading,
}) {
  const classes = useStyles();

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={close}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div>
              <img
                src={isApprove ? DangerBlue : Danger}
                alt="image not found"
              />
            </div>
            <Typography
              className={classes.notifText}
              variant="body1"
              component="p"
            >
              {`Approve ${title}?`}
            </Typography>
            <Box className={classes.wrapperText}>
              <Typography
                className={classes.textConfirm}
                variant="body1"
                component="p"
              >
                All selected item will be
              </Typography>
              <Typography
                className={classes.textConfirmBold}
                variant="body1"
                component="p"
              >
                {isApprove ? 'approved.' : 'rejected.'}
              </Typography>
            </Box>
            <Typography
              className={classes.textConfirm}
              variant="body1"
              component="p"
            >
              Do you want to continue ?
            </Typography>
            <Typography
              className={classes.textConfirm}
              variant="body1"
              component="p"
            >
            </Typography>
            {isLoading ? (<LinearProgress color='secondary' style={{ width: '80%' }} />) : null }
            <div className={classes.borderLine} />
            <div className={classes.btnContent}>
              <div className={classes.btnCancel} onClick={isLoading ? null : close}>
                <Typography
                  className={isLoading ? classes.btnCancelFalse : classes.btnCancelText}
                  variant="body1"
                  component="p"
                >
                  Cancel
                </Typography>
              </div>
              <div
                className={
                  isLoading ? classes.btnSubmitFalse : classes.btnSubmit
                }
                onClick={onPress}
              >
                <Typography
                  className={classes.btnSubmitText}
                  variant="body1"
                  component="p"
                >
                  Yes
                </Typography>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
