import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box } from '@material-ui/core';

// icons
import Paper from 'src/assets/Icons/paper.png';

const Attach = ({ files }) => {
  const classes = useStyles();

  const handleSeeImage = (url) => {
    window.open(url);
  };

  return (
    <Box className={classes.wrapperBody}>
      <Grid container>
        <Grid xs={12}>
          <Typography
            className={classes.bodyTitle}
            variant="body1"
            component="p"
          >
            {files.length > 0
              ? 'Document Attachment'
              : 'No Document Attachment'}
          </Typography>

          {files?.map((file, index) => (
            <>
              <Box key={index} className={classes.sizebox} />
              <Box
                className={classes.wrapperLink}
                onClick={() => handleSeeImage(file?.url)}
              >
                <Box>
                  <img src={Paper} alt="image not found" />
                </Box>
                <Typography
                  className={classes.bodyUrlText}
                  variant="body1"
                  component="p"
                >
                  {`${file?.file_name} (2MB)`}
                </Typography>
              </Box>
            </>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapperBody: {
    paddingTop: 27,
    paddingLeft: 20,
    paddingRight: 20,
    // paddingBottom: 97,
    maxHeight: 412,
    minHeight: 412,
    [theme.breakpoints.down('md')]: {
      minHeight: '78vh',
    },
  },
  bodyTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  bodyUrlText: {
    marginLeft: 10,
    fontSize: 16,
    lineHeight: '21px',
    textDecorationLine: 'underline',
    color: '#1B2030',
  },
  wrapperLink: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
  },
  sizebox: {
    marginTop: 39,
  },
}));

export default Attach;
