import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { dateFormater, formatRupiah } from '../../../utils/formatter';
import Lib from 'src/utils/Lib';

// Images
import Logo from '../../../assets/Images/logoForm.png';
import QRCODE from '../../../assets/Images/QrCode.png';
import LogoFooter from '../../../assets/Images/logoFooter.png';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 50,
    overflow: 'scroll',
    height: '100%',
    display: 'block',
  },
  paper: {
    backgroundColor: '#fff',
    height: 'auto',
    width: 793,
    maxWidth: 793,
    margin: '0 auto',
    minHeight: 1122,
    maxHeight: 1122,
    background: '#fff',
    position: 'relative',
  },
}));

export default function Assets({ open, close, data }) {
  const classes = useStyles();

  // Configure to the vaue inside paper PDF
  // change in here will effect the value

  let NoGRPO = data?.doc_num ?? '-';
  let NoPO = data?.po?.doc_num ?? '-';
  let docDate = dateFormater(data?.doc_date, 'DD-MMMM-YYYY') ?? '-';
  let noRef = data?.customer_ref ?? '-';

  // supplier
  let supplierName = data?.customer_name ?? '-';
  let supplierCode = data?.customer_code ?? '-';
  let supllierAddress = data?.vendor_address ?? '-';
  let supllierPhone = data?.vendor_phone ?? '-';
  let supllierFax = data?.vendor_fax ?? '-';
  let supllierCP = data?.contact_person ?? '-';
  let supllierTax = data?.npwp ?? '-';

  // Deliver To
  let companyName = data?.company_name ?? '-';
  let companyAddress =
    `${data?.building ?? ''} ${data?.company_street ?? ''} ${
      data?.company_city ?? ''
    }` ?? '-';
  let companyPhone = data?.company_phone_1 ?? '-';
  let companyFAX = data?.company_fax ?? '-';
  let companyTax = data?.company_npwp ?? '-';

  // data row 1
  let NumberOne = data?.items[0]?.id ?? '-';
  let descriptionOne = data?.items[0]?.description ?? '-';
  let deliverDateOne =
    `${data?.items[0]?.delivery_date_detail} ${data?.items[0]?.doc_time}` ??
    '-';
  let discountOne = data?.items[0]?.line_discount_percent ?? '-';
  let priceOne = data?.items[0]?.price ?? '-';
  let amountOne = data?.items[0]?.line_amount ?? '-';

  // data row 1
  let NumberTwo = data?.items[1]?.id ?? '-';
  let descriptionTwo = data?.items[1]?.description ?? '-';
  let deliverDateTwo =
    `${data?.items[1]?.delivery_date_detail} ${data?.items[1]?.doc_time}` ??
    '-';
  let discountTwo = data?.items[1]?.line_discount_percent ?? '-';
  let priceTwo = data?.items[1]?.price ?? '-';
  let amountTwo = data?.items[1]?.line_amount ?? '-';

  let TermBuying = data?.items[0]?.payment_term ?? '-';
  let remark = data?.remarks ?? '-';

  let total = data?.amount_hd ?? '-';
  let vatAmount = data?.items[0]?.vat_amount ?? '-';
  let fregith = data?.items[0]?.freight ?? '-';
  let grandTotal = data?.doc_total_amount ?? '-';

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={close}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <table style={{ borderSpacing: 0, padding: 0, width: '100%' }}>
              <tr>
                <td>
                  <table
                    style={{
                      margin: 0,
                      width: '100%',
                      borderSpacing: 0,
                      padding: 0,
                      height: 13,
                    }}
                  >
                    <tr>
                      <td
                        style={{
                          width: '30%',
                          backgroundColor: '#1b2030',
                          height: 13,
                        }}
                      ></td>
                      <td
                        style={{
                          width: '10%',
                          backgroundColor: '#e61724',
                          height: 13,
                        }}
                      ></td>
                      <td
                        style={{
                          width: '60%',
                          backgroundColor: '#ac121c',
                          height: 13,
                        }}
                      ></td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table style={{ borderSpacing: 0, padding: 0, width: '100%' }}>
              <tr>
                <td>
                  <table
                    style={{
                      margin: 0,
                      width: '100%',
                      borderSpacing: 0,
                      height: 18,
                      padding: 0,
                      marginTop: 30,
                      position: 'relative',
                    }}
                  >
                    <tr>
                      <td
                        colSpan="4"
                        style={{
                          paddingLeft: 27,
                          fontSize: 21,
                          lineHeight: '32px',
                          fontWeight: 'bold',
                          color: '#fd453a',
                        }}
                      >
                        GOOD RECEIPT NOTE
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          paddingTop: 15,
                          paddingLeft: 27,
                          fontSize: 12,
                          lineHeight: '27px',
                          color: '#969bab',
                          width: '28%',
                        }}
                      >
                        GRPO No.{' '}
                        <span
                          style={{
                            fontSize: 12,
                            lineHeight: '27px',
                            color: '#969bab',
                            fontWeight: 'bold',
                          }}
                        >
                          {NoGRPO}
                        </span>
                      </td>
                      <td
                        style={{
                          paddingTop: 15,
                          paddingLeft: 32,
                          fontSize: 12,
                          lineHeight: '16px',
                          color: '#969bab',
                          fontWeight: 'bold',
                          width: '25%',
                        }}
                      >
                        {docDate}
                      </td>
                      <td
                        style={{
                          paddingTop: 15,
                          paddingLeft: 32,
                          fontSize: 12,
                          lineHeight: '16px',
                          color: '#969bab',
                          width: '28%',
                        }}
                      >
                        {`PO No `}
                        <span
                          style={{
                            fontSize: 12,
                            lineHeight: '27px',
                            color: '#969bab',
                            fontWeight: 'bold',
                          }}
                        >
                          {NoPO}
                        </span>
                      </td>
                      <td
                        style={{
                          paddingTop: 15,
                          paddingLeft: 32,
                          fontSize: 12,
                          lineHeight: '16px',
                          color: '#969bab',
                          fontWeight: 'bold',
                        }}
                      >
                        {`Ref No. ${noRef}`}
                      </td>
                    </tr>
                  </table>
                </td>
                <td
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                  }}
                >
                  <div
                    style={{
                      position: 'relative',
                      top: 0,
                      // marginTop: -12,
                      paddingRight: 27,
                    }}
                  >
                    {Lib.getLogo(data)}
                  </div>
                </td>
              </tr>
            </table>
            <table
              style={{
                borderSpacing: 0,
                padding: 0,
                width: '100%',
                marginTop: 22,
              }}
            >
              <tr>
                <td
                  style={{
                    width: '36%',
                    fontSize: 12,
                    paddingLeft: 27,
                    lineHeight: '16px',
                    color: '#000000',
                    fontWeight: 'bold',
                  }}
                >
                  Supplier to :
                </td>
                <td
                  style={{
                    width: '64%',
                    fontSize: 12,
                    paddingLeft: 27,
                    lineHeight: '16px',
                    color: '#000000',
                    fontWeight: 'bold',
                  }}
                >
                  Deliver to:
                </td>
              </tr>
            </table>
            <table
              style={{
                borderSpacing: 0,
                padding: 0,
                // width: '100%',
                marginTop: 10,
                marginLeft: 27,
              }}
            >
              <tr>
                <td
                  style={{
                    width: 275,
                    padding: 10,
                    backgroundColor: '#F8FAFB',
                    verticalAlign: 'top',
                  }}
                >
                  <p
                    style={{
                      fontSize: 12,
                      lineHeight: '16px',
                      color: '#000000',
                      fontWeight: 'bold',
                    }}
                  >
                    {supplierCode}
                  </p>
                  <p
                    style={{
                      fontSize: 12,
                      lineHeight: '16px',
                      color: '#000000',
                      fontWeight: 'bold',
                    }}
                  >
                    {supplierName}
                  </p>
                  <p
                    style={{
                      fontSize: 12,
                      lineHeight: '16px',
                      color: '#000000',
                    }}
                  >
                    {supllierAddress}
                  </p>
                  <tr>
                    <td
                      style={{
                        width: 95,
                        fontSize: 12,
                        lineHeight: '24px',
                        color: '#000000',
                        paddingTop: 9,
                        paddingBottom: 3,
                        fontWeight: 'bold',
                      }}
                    >
                      Phone
                    </td>
                    <td
                      style={{
                        fontSize: 12,
                        lineHeight: '24px',
                        color: '#000000',
                        paddingTop: 9,
                        paddingBottom: 3,
                      }}
                    >
                      : {supllierPhone}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: 95,
                        fontSize: 12,
                        lineHeight: '24px',
                        color: '#000000',
                        paddingTop: 3,
                        paddingBottom: 3,
                        fontWeight: 'bold',
                      }}
                    >
                      Fax
                    </td>
                    <td
                      style={{
                        fontSize: 12,
                        lineHeight: '24px',
                        color: '#000000',
                        paddingTop: 3,
                        paddingBottom: 3,
                      }}
                    >
                      : {supllierFax}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: 95,
                        fontSize: 12,
                        lineHeight: '24px',
                        color: '#000000',
                        paddingTop: 3,
                        paddingBottom: 3,
                        fontWeight: 'bold',
                      }}
                    >
                      CP
                    </td>
                    <td
                      style={{
                        fontSize: 12,
                        lineHeight: '24px',
                        color: '#000000',
                        paddingTop: 3,
                        paddingBottom: 3,
                        verticalAlign: 'top',
                      }}
                    >
                      : {supllierCP}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: 95,
                        fontSize: 12,
                        lineHeight: '24px',
                        color: '#000000',
                        paddingTop: 3,
                        paddingBottom: 3,
                        fontWeight: 'bold',
                      }}
                    >
                      Npwp
                    </td>
                    <td
                      style={{
                        fontSize: 12,
                        lineHeight: '24px',
                        color: '#000000',
                        paddingTop: 3,
                        paddingBottom: 3,
                        verticalAlign: 'top',
                      }}
                    >
                      : {supllierTax}
                    </td>
                  </tr>
                </td>
                {/* Section Two */}
                <td
                  style={{
                    width: 275,
                    padding: 10,
                    backgroundColor: '#F8FAFB',
                    verticalAlign: 'top',
                  }}
                >
                  {/* <p
                    style={{
                      fontSize: 12,
                      lineHeight: '16px',
                      color: '#000000',
                      fontWeight: 'bold',
                    }}
                  >
                    {companyName}
                  </p> */}
                  <p
                    style={{
                      fontSize: 12,
                      lineHeight: '16px',
                      color: '#000000',
                    }}
                  >
                    {companyAddress}
                  </p>
                  <tr>
                    <td
                      style={{
                        width: 95,
                        fontSize: 12,
                        lineHeight: '24px',
                        color: '#000000',
                        paddingTop: 9,
                        paddingBottom: 3,
                        fontWeight: 'bold',
                      }}
                    >
                      Phone
                    </td>
                    <td
                      style={{
                        fontSize: 12,
                        lineHeight: '24px',
                        color: '#000000',
                        paddingTop: 9,
                        paddingBottom: 3,
                      }}
                    >
                      : {companyPhone}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: 95,
                        fontSize: 12,
                        lineHeight: '24px',
                        color: '#000000',
                        paddingTop: 3,
                        paddingBottom: 3,
                        fontWeight: 'bold',
                      }}
                    >
                      Fax
                    </td>
                    <td
                      style={{
                        fontSize: 12,
                        lineHeight: '24px',
                        color: '#000000',
                        paddingTop: 3,
                        paddingBottom: 3,
                      }}
                    >
                      : {companyFAX}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: 95,
                        fontSize: 12,
                        lineHeight: '24px',
                        color: '#000000',
                        paddingTop: 3,
                        paddingBottom: 3,
                        fontWeight: 'bold',
                      }}
                    >
                      Npwp
                    </td>
                    <td
                      style={{
                        fontSize: 12,
                        lineHeight: '24px',
                        color: '#000000',
                        paddingTop: 3,
                        paddingBottom: 3,
                        verticalAlign: 'top',
                      }}
                    >
                      : {companyTax}
                    </td>
                  </tr>
                </td>
                {/* Section three */}
                {/* <td
                  style={{
                    width: '28%',
                    padding: 10,
                    maxWidth: 200,
                    paddingLeft: 48,
                    verticalAlign: 'top',
                  }}
                >
                  <div
                    style={{
                      width: 140,
                      height: 137,
                      border: '1px solid #000000',
                      borderRadius: 8,

                      paddingBottom: 1,
                    }}
                  >
                    <img
                      style={{
                        marginTop: 13,
                        marginLeft: 28,
                        marginRight: 28,
                        width: 82.5,
                        height: 82.5,
                      }}
                      src={QRCODE}
                      alt=""
                    />
                    <p
                      style={{
                        marginTop: 13,
                        marginRight: 22,
                        marginLeft: 22,
                        fontSize: 11,
                        lineHeight: '26px',
                        fontWeight: 'bold',
                        color: '#1B2030',
                      }}
                    >
                      PR210810999999
                    </p>
                  </div>
                  <p
                    style={{
                      fontSize: 12,
                      lineHeight: '16px',
                      color: '#969BAB',
                      textAlign: 'right',
                      fontWeight: 'bold',
                      marginTop: 18,
                      paddingRight: 20,
                    }}
                  >
                    Currency: IDR
                  </p>
                </td> */}
              </tr>
            </table>
            {/* Table Data */}
            <table
              style={{
                borderSpacing: 0,
                padding: 0,
                width: '100%',
                marginTop: 21,
              }}
            >
              <tr>
                <td style={{ paddingLeft: 24, paddingRight: 27 }}>
                  <table style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th
                          style={{
                            width: '5%',
                            height: 44,
                            backgroundColor: '#333642',
                            color: '#fff',
                            fontSize: 12,
                            lineHeight: '16px',
                            fontWeight: 'bold',
                            borderTopLeftRadius: 8,
                            paddingLeft: 4,
                            paddingRight: 10,
                          }}
                        >
                          No
                        </th>
                        <th
                          style={{
                            width: '15%',
                            height: 44,
                            backgroundColor: '#333642',
                            color: '#fff',
                            fontSize: 12,
                            lineHeight: '16px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            paddingLeft: 4,
                            paddingRight: 10,
                          }}
                        >
                          Item
                        </th>
                        <th
                          style={{
                            width: '40%',
                            height: 44,
                            backgroundColor: '#333642',
                            color: '#fff',
                            fontSize: 12,
                            lineHeight: '16px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            paddingLeft: 4,
                            paddingRight: 10,
                          }}
                        >
                          Description
                        </th>
                        <th
                          style={{
                            width: '10%',
                            height: 44,
                            backgroundColor: '#333642',
                            color: '#fff',
                            fontSize: 12,
                            lineHeight: '16px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            paddingLeft: 4,
                            paddingRight: 10,
                          }}
                        >
                          Quantity
                        </th>
                        <th
                          style={{
                            width: '10%',
                            height: 44,
                            backgroundColor: '#333642',
                            color: '#fff',
                            fontSize: 12,
                            lineHeight: '16px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            paddingLeft: 4,
                            paddingRight: 10,
                          }}
                        >
                          UoM
                        </th>

                        <th
                          style={{
                            width: '10%',
                            height: 44,
                            backgroundColor: '#333642',
                            color: '#fff',
                            fontSize: 12,
                            lineHeight: '16px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            paddingLeft: 4,
                            paddingRight: 10,
                            borderTopRightRadius: 8,
                          }}
                        >
                          Whs
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.items?.map((item, index) => (
                        <tr>
                          <td
                            style={{
                              fontSize: 12,
                              lineHeight: '16px',
                              backgroundColor: '#F8FAFB',
                              minHeight: 40,
                              borderLeft: '1px solid #E7E7E8',
                              textAlign: 'center',
                              paddingLeft: 4,
                              paddingRight: 10,
                              paddingTop: 12,
                              paddingBottom: 12,
                            }}
                          >
                            {index+1}
                          </td>
                          <td
                            style={{
                              fontSize: 12,
                              lineHeight: '16px',
                              backgroundColor: '#F8FAFB',
                              minHeight: 40,
                              paddingLeft: 4,
                              paddingRight: 10,
                              paddingTop: 12,
                              paddingBottom: 12,
                            }}
                          >
                            {item.item_code}
                          </td>

                          <td
                            style={{
                              fontSize: 12,
                              lineHeight: '16px',
                              backgroundColor: '#F8FAFB',
                              minHeight: 40,
                              paddingLeft: 4,
                              paddingRight: 10,
                              paddingTop: 12,
                              paddingBottom: 12,
                            }}
                          >
                            {item.description}
                          </td>
                          <td
                            style={{
                              fontSize: 12,
                              lineHeight: '16px',
                              backgroundColor: '#F8FAFB',
                              minHeight: 40,
                              paddingLeft: 4,
                              paddingRight: 10,
                              paddingTop: 12,
                              paddingBottom: 12,
                              textAlign: 'right',
                            }}
                          >
                            {item.quantity}
                          </td>
                          <td
                            style={{
                              fontSize: 12,
                              lineHeight: '16px',
                              backgroundColor: '#F8FAFB',
                              minHeight: 40,
                              paddingLeft: 4,
                              paddingRight: 10,
                              paddingTop: 12,
                              paddingBottom: 12,
                              textAlign: 'right',
                            }}
                          >
                            {item.uom}
                          </td>

                          <td
                            style={{
                              fontSize: 12,
                              lineHeight: '16px',
                              backgroundColor: '#F8FAFB',
                              minHeight: 40,
                              paddingLeft: 4,
                              paddingRight: 10,
                              paddingTop: 12,
                              paddingBottom: 12,
                              textAlign: 'right',
                              borderRight: '1px solid #E7E7E8',
                            }}
                          >
                            {item.warehouse_code}
                          </td>
                        </tr>
                      ))}

                      {/* second data */}

                      <tr>
                        <td
                          colSpan={2}
                          style={{
                            fontSize: 12,
                            lineHeight: '16px',
                            backgroundColor: '#fff',
                            minHeight: 40,
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 12,
                            paddingBottom: 12,
                            borderTop: '1px solid #E7E7E8',
                            borderLeft: '1px solid #E7E7E8',
                            borderBottom: '1px solid #E7E7E8',
                            textAlign: 'left',
                            fontWeight: 'bold',
                            borderBottomLeftRadius: 8,
                          }}
                        >
                          Total Quantity
                        </td>
                        <td
                          colSpan={4}
                          style={{
                            fontSize: 12,
                            lineHeight: '16px',
                            backgroundColor: '#fff',
                            minHeight: 40,
                            paddingLeft: 200,
                            paddingRight: 40,
                            paddingTop: 12,
                            paddingBottom: 12,
                            borderTop: '1px solid #E7E7E8',
                            borderRight: '1px solid #E7E7E8',
                            borderBottom: '1px solid #E7E7E8',
                            textAlign: 'left',
                            fontWeight: 'bold',
                            borderBottomRightRadius: 8,
                          }}
                        >
                          {grandTotal}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </table>
            <table
              style={{
                borderSpacing: 0,
                padding: 0,
                width: '100%',
                marginTop: 20,
              }}
            >
              <tr>
                <td
                  style={{
                    width: '100%',
                    paddingLeft: 27,
                    paddingRight: 27,
                  }}
                >
                  <table
                    style={{
                      borderSpacing: 0,
                      padding: 0,
                      width: '100%',
                    }}
                  >
                    <tr>
                      <td style={{ width: '70%', verticalAlign: 'top' }}>
                        <div style={{ maxWidth: '85%' }}>
                          <p
                            style={{
                              fontSize: 12,
                              lineHeight: '16px',
                              fontWeight: 'bold',
                            }}
                          >
                            Remark
                          </p>
                          <p style={{ fontSize: 12, lineHeight: '16px' }}>
                            {`${remark}`}
                          </p>
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>

            {/* <table
              style={{
                borderSpacing: 0,
                padding: 0,
                width: '100%',
                marginTop: 20,
                backgroundColor: '#F3F5F6',
                position: 'absolute',
                bottom: 0,
              }}
            >
              <tr>
                <td
                  style={{
                    backgroundColor: '#F3F5F6',
                    paddingTop: 20,
                    paddingBottom: 16,
                    paddingLeft: 19,
                    paddingRight: 20,
                  }}
                >
                  <table>
                    <tr>
                      <td style={{ width: '8%' }}>
                        <img
                          src={LogoFooter}
                          style={{ width: 45, height: 38 }}
                          alt=""
                        />
                      </td>
                      <td style={{ width: '38%' }}>
                        <p
                          style={{
                            fontSize: 11,
                            lineHeight: '20px',
                            color: '#85878E',
                          }}
                        >
                          Graha Sucofindo Lt 12 Jl. Raya Pasar Minggu Kav. 34,
                          DKI Jakarta 12780, Indonesia
                        </p>
                      </td>
                      <td
                        style={{
                          width: '25%',
                          verticalAlign: 'top',
                          paddingLeft: 18,
                        }}
                      >
                        <tr>
                          <td
                            style={{
                              width: 33,
                              fontSize: 11,
                              lineHeight: '20px',
                              color: '#85878E',
                              fontWeight: 'bold',
                            }}
                          >
                            Phone
                          </td>
                          <td
                            style={{
                              fontSize: 11,
                              lineHeight: '20px',
                              color: '#85878E',
                              paddingLeft: 12,
                            }}
                          >
                            : +6221-7986569/71
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: 33,
                              fontSize: 11,
                              lineHeight: '20px',
                              color: '#85878E',
                              fontWeight: 'bold',
                            }}
                          >
                            Fax
                          </td>
                          <td
                            style={{
                              paddingLeft: 12,
                              fontSize: 11,
                              lineHeight: '20px',
                              color: '#85878E',
                            }}
                          >
                            : +6221-7986570
                          </td>
                        </tr>
                      </td>
                      <td
                        style={{
                          width: '25%',
                          verticalAlign: 'top',
                          paddingLeft: 10,
                        }}
                      >
                        <tr>
                          <td
                            style={{
                              width: 33,
                              fontSize: 11,
                              lineHeight: '20px',
                              color: '#85878E',
                              fontWeight: 'bold',
                            }}
                          >
                            Npwp
                          </td>
                          <td
                            style={{
                              fontSize: 11,
                              lineHeight: '20px',
                              color: '#85878E',
                              paddingLeft: 12,
                            }}
                          >
                            : 10.209.321.2-098.000
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: 33,
                              fontSize: 11,
                              lineHeight: '20px',
                              color: '#85878E',
                              fontWeight: 'bold',
                            }}
                          >
                            Website
                          </td>
                          <td
                            style={{
                              paddingLeft: 12,
                              fontSize: 11,
                              lineHeight: '20px',
                              color: '#85878E',
                            }}
                          >
                            : https://dktindonesia.org/
                          </td>
                        </tr>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table> */}
          </div>
        </Fade>
      </Modal>
    </>
  );
}
