import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';

import Cross from '../../assets/Icons/cross.png';
import EyeBlue from '../../assets/Icons/eyeBlue.png';
import QRCODE from '../../assets/Images/QrCode.png';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    borderRadius: 8,
    width: '45%',
  },
  titleTableContent: {
    paddingTop: 26,
    paddingBottom: 28,
    paddingRight: 20,
    paddingLeft: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleTable: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  borderLine: {
    border: '1px solid #E7E7E8',
  },
  btnContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 20,
    paddingTop: 17,
    paddingBottom: 14,
  },
  btnSubmit: {
    width: 183,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1B2030',
    borderRadius: 8,
    cursor: 'pointer',
  },
  btnSubmitText: {
    fontSize: 16,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#fff',
  },
  btnCancel: {
    width: 183,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 32,
    borderRadius: 8,
    cursor: 'pointer',
  },
  btnCancelText: {
    fontSize: 16,
    lineHeight: '27px',
    color: '#1B2030',
  },
  btnLinkContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: 40,
  },
  btnLinkDetail: {
    color: '#127EEE',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    marginLeft: 13,
    marginTop: -4,
  },
  informationContent: {
    paddingLeft: 20,
    paddingTop: 30,
    paddingBottom: 44,
  },
  infoTitle: {
    color: '#969BAB',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  infoDesc: {
    color: '#1B2030',
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    marginBottom: 15,
  },
  codeContent: {
    paddingTop: 66,
  },
  btnCross: {
    cursor: 'pointer',
  },
}));

export default function ModalQRCode({
  open,
  handleCloseModalQRCode,
  onSeePriview,
  title,
  qrCode,
  isLoading,
  no,
  name,
  activity,
  total,
  onClose,
}) {
  const classes = useStyles();

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        // onClose={handleCloseModalQRCode}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Grid container>
              <Grid md={12}>
                <div className={classes.titleTableContent}>
                  <Typography
                    className={classes.titleTable}
                    variant="body1"
                    component="p"
                  >
                    QR Code
                  </Typography>
                  <div className={classes.btnCross} onClick={onClose}>
                    <img src={Cross} alt="image not found" />
                  </div>
                </div>
                <div className={classes.borderLine}></div>
                <Grid container>
                  <Grid md={8}>
                    <div className={classes.informationContent}>
                      <Typography
                        className={classes.infoTitle}
                        variant="body1"
                        component="p"
                      >
                        No
                      </Typography>
                      <Typography
                        className={classes.infoDesc}
                        variant="body1"
                        component="p"
                      >
                        {no}
                      </Typography>
                      <Typography
                        className={classes.infoTitle}
                        variant="body1"
                        component="p"
                      >
                        Purpose of Request
                      </Typography>
                      <Typography
                        className={classes.infoDesc}
                        variant="body1"
                        component="p"
                      >
                        {name}
                      </Typography>
                      <Typography
                        className={classes.infoTitle}
                        variant="body1"
                        component="p"
                      >
                        Activity
                      </Typography>
                      <Typography
                        className={classes.infoDesc}
                        variant="body1"
                        component="p"
                      >
                        {activity}
                      </Typography>
                      <Typography
                        className={classes.infoTitle}
                        variant="body1"
                        component="p"
                      >
                        Grand Total
                      </Typography>
                      <Typography
                        className={classes.infoDesc}
                        variant="body1"
                        component="p"
                      >
                        {total}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid md={4}>
                    <div className={classes.codeContent}>
                      {isLoading ? (
                        <div
                          style={{
                            width: 180,
                            height: 180,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <CircularProgress />
                        </div>
                      ) : qrCode ? (
                        <>
                          <div style={{ width: 180, height: 180 }}>
                            <img
                              style={{ width: 180, height: 180 }}
                              src={`data:image/jpeg;base64,${qrCode}`}
                              alt="image not found"
                            />
                          </div>
                        </>
                      ) : (
                        <div style={{ width: 180, height: 180 }}>
                          <Typography>Not Found</Typography>
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
                {/* <div className={classes.borderLine}></div>
                <div className={classes.btnContent}>
                  <div className={classes.btnSubmit}>
                    <Typography
                      className={classes.btnSubmitText}
                      variant="body1"
                      component="p"
                    >
                      Send to SAP
                    </Typography>
                  </div>
                  <div
                    className={classes.btnCancel}
                    onClick={handleCloseModalQRCode}
                  >
                    <Typography
                      className={classes.btnCancelText}
                      variant="body1"
                      component="p"
                    >
                      Cancel
                    </Typography>
                  </div>
                  <div
                    className={classes.btnLinkContent}
                    onClick={onSeePriview}
                  >
                    <div>
                      <img src={EyeBlue} alt="image not found" />
                    </div>
                    <Typography
                      className={classes.btnLinkDetail}
                      variant="body1"
                      component="p"
                    >
                      {`View ${title} information`}
                    </Typography>
                  </div>
                </div> */}
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
