// department
export const SET_DEPARTMENT_DATA = 'UTILITY/SET_DEPARTMENT_DATA';
export const SET_DEPARTMENT_LOADING = 'UTILITY/SET_DEPARTMENT_LOADING';

// get department by id entity
export const SET_DEPARTMENT_ID_DATA = 'UTILITY/SET_DEPARTMENT_ID_DATA';
export const SET_DEPARTMENT_ID_LOADING = 'UTILITY/SET_DEPARTMENT_ID_LOADING';

// level
export const SET_LEVEL_DATA = 'UTILITY/SET_LEVEL_DATA';
export const SET_LEVEL_LOADING = 'UTILITY/SET_LEVEL_LOADING';

// entity
export const SET_ENTITY_DATA = 'UTILITY/SET_ENTITY_DATA';
export const SET_ENTITY_LOADING = 'UTILITY/SET_ENTITY_LOADING';

// role
export const SET_ROLE_LOADING = 'UTILITY/SET_ROLE_LOADING';
export const SET_ROLE_DATA = 'UTILITY/SET_ROLE_DATA';

// position
export const SET_POSITION_LOADING = 'UTILITY/SET_POSITION_LOADING';
export const SET_POSITION_DATA = 'UTILITY/SET_POSITION_DATA';

// activity
export const SET_ACTIVITY_LOADING = 'UTILITY/SET_ACTIVITY_LOADING';
export const SET_ACTIVITY_DATA = 'UTILITY/SET_ACTIVITY_DATA';

// Category
export const SET_CATEGORY_LOADING = 'UTILITY/SET_CATEGORY_LOADING';
export const SET_CATEGORY_DATA = 'UTILITY/SET_CATEGORY_DATA';

// Generate QR code
export const GET_QRCODE = 'UTILITY/GET_QRCODE';
export const GET_QRCODE_LOADING = 'UTILITY/GET_QRCODE_LOADING';

// Upload file
export const FILE_UPLOAD_MESSAGE = 'UTILITY/FILE_UPLOAD_MESSAGE';
export const FILE_UPLOAD_LOADING = 'UTILITY/FILE_UPLOAD_LOADING';
export const FILE_UPLOAD_COUNT_TOTAL = 'UTILITY/FILE_UPLOAD_COUNT_TOTAL';
export const FILE_UPLOAD_COUNT_UPLODED = 'UTILITY/FILE_UPLOAD_COUNT_UPLODED';
export const FILE_UPLOAD_COUNT_PERCENT = 'UTILITY/FILE_UPLOAD_COUNT_PERCENT';

// Read file
export const FILE_READ_MESSAGE = 'UTILITY/FILE_READ_MESSAGE';
export const FILE_READ_LOADING = 'UTILITY/FILE_READ_LOADING';
export const FILE_READ_DATA = 'UTILITY/FILE_READ_DATA';

// Read file second
export const FILE_READ_MESSAGE_SECOND = 'UTILITY/FILE_READ_MESSAGE_SECOND';
export const FILE_READ_LOADING_SECOND = 'UTILITY/FILE_READ_LOADING_SECOND';
export const FILE_READ_DATA_SECOND = 'UTILITY/FILE_READ_DATA_SECOND';

// Read file third
export const FILE_READ_MESSAGE_THIRD = 'UTILITY/FILE_READ_MESSAGE_THIRD';
export const FILE_READ_LOADING_THIRD = 'UTILITY/FILE_READ_LOADING_THIRD';
export const FILE_READ_DATA_THIRD = 'UTILITY/FILE_READ_DATA_THIRD';

// delete file
export const FILE_DELETE_MESSAGE = 'UTILITY/FILE_DELETE_MESSAGE';
export const FILE_DELETE_LOADING = 'UTILITY/FILE_DELETE_LOADING';

// Export Cvs
export const MESSAGE_CSV = 'UTILITY/MESSAGE_CSV';
export const LOADING_CSV = 'UTILITY/LOADING_CSV';
export const DATA_CSV = 'UTILITY/DATA_CSV';

// Distributor Code
export const GROUP_CODE_LOADING = 'GROUP/GROUP_CODE_LOADING';
export const GROUP_CODE_DATA = 'GROUP/GROUP_CODE_DATA';

// supplier Code
export const SUPPLIER_GROUP_CODE_LOADING =
  'SUPPLIER/SUPPLIER_GROUP_CODE_LOADING';
export const SUPPLIER_GROUP_CODE_DATA = 'SUPPLIER/SUPPLIER_GROUP_CODE_DATA';

// get supplier code ID
export const SUPPLIER_GET_ID_LOADING = 'SUPPLIER/SUPPLIER_GET_ID_LOADING';
export const SUPPLIER_GET_ID_DATA = 'SUPPLIER/SUPPLIER_GET_ID_DATA';

// Bank
export const BANK_LOADING = 'BANK/BANK_LOADING';
export const BANK_DATA = 'BANK/BANK_DATA';

// get payment info by vendor code
export const GET_PAYMENT_CODE_LOADING = 'UTILITY/GET_PAYMENT_CODE_LOADING';
export const GET_PAYMENT_CODE_DATA = 'UTILITY/GET_PAYMENT_CODE_DATA';

// get all bank for all user level
export const GET_ALL_BANK_LOADING = 'BANK/GET_ALL_BANK_LOADING';
export const GET_ALL_BANK_DATA = 'BANK/GET_ALL_BANK_DATA';
