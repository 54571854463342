import React, { useCallback } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import * as vendor from '../../../redux/ClientVendor/actions';
import { debounce } from 'lodash';

// components
import { ContentText } from '../../../libs/Typography';
import { CircleLoadingSecondary } from '../../Loading/CircleLoading';

// icons
import DownArrow from '../../../assets/Icons/downArrow.png';
import Search from '../../../assets/Icons/Search.png';
import Cross from '../../../assets/Icons/cross.png';

const SearchVendor = ({ handleChooseVandor }) => {
  const classes = useStyles();
  const dispacth = useDispatch();

  // Global Variable
  const { access_token } = useSelector((state) => state.auth);
  const { data, searchData, searchIsLoading } = useSelector(
    (state) => state.clientVendor
  );

  const [open, setOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [select, setSelect] = React.useState(null);
  const [vendorData, setVendorData] = React.useState([]);

  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), []);

  const handleOpenModal = () => {
    setOpen(!open);
  };

  const handleChange = (e) => {
    setSearchText(e.target.value);
    debounceFn(e.target.value);
  };

  function handleDebounceFn(inputValue) {
    
    if(!inputValue) {
      dispacth(vendor.getClientVendor(access_token));
    } else {
      dispacth(vendor.searchClientVendor(access_token, { name: inputValue }));
    }
}

  const handleRemove = (e) => {
    e.stopPropagation()
    setSelect(null);
    handleChooseVandor({})
  };

  const handleOnclick = (item) => {
    setSelect(item);
    handleChooseVandor(item)
    // onSetPO(item);
    setOpen(false);
  };

  // React.useEffect(() => {
  //   if (!searchText) {
  //     setVendorData(data?.client?.data);
  //     dispacth(vendor.setBlankSearchData());
  //   }
  // }, [searchText]);

  React.useEffect(() => {
    dispacth(vendor.getClientVendor(access_token, { limit: 150 }));
  }, []);

  React.useEffect(() => {
    setVendorData(data?.client?.data);
  }, [data]);

  React.useEffect(() => {
    setVendorData(searchData?.client);
  }, [searchData]);

  return (
    <Box className={classes.sectionContainer}>
      <Box className={classes.boxContent} onClick={handleOpenModal}>
        <ContentText
          content={select?.name ?? `Select Vendor`}
          styling={classes.placeholder}
        />
        {select ? (
          <img
            src={Cross}
            alt="none"
            className={classes.iconCross}
            onClick={handleRemove}
          />
        ) : (
          <img src={DownArrow} alt="none" className={classes.icon} />
        )}
      </Box>
      {open && (
        <Box className={classes.card}>
          <Box className={classes.inpuContent}>
            <input
              defaultValue={searchText}
              onChange={handleChange}
              className={classes.inputSearch}
              placeholder="Select"
            />
            {searchIsLoading ? (
              <Box className={classes.loadingContent}>
                <CircleLoadingSecondary />
              </Box>
            ) : (
              <img src={Search} alt="none" className={classes.iconSearch} />
            )}
          </Box>
          <Box className={classes.contentList}>
            {vendorData?.map((item, index) => (
              <Box
                key={index}
                className={classes.contentItem}
                onClick={() => handleOnclick(item)}
              >
                {item?.name}
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    position: 'relative',
  },
  boxContent: {
    width: '100%',
    height: 40,
    border: '1px solid #E7E7E8',
    background: '#F8FAFB',
    borderRadius: 8,
    cursor: 'pointer',
    position: 'relative',
    padding: '14px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: 205,
  },
  placeholder: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 400,
    color: '#969BAB',
  },
  icon: {
    width: 14,
    height: 12,
  },
  iconCross: {
    width: 20,
    height: 20,
  },
  card: {
    marginTop: 4,
    width: '100%',
    background: '#fff',
    position: 'absolute',
    borderRadius: 8,
    border: ' 1px solid #E7E7E8',
    padding: '5px 8px',
    minHeight: 150,
    maxHeight: 202,
    zIndex: 9000,
  },
  inputSearch: {
    width: '100%',
    borderRadius: 8,
    height: 40,
    padding: '9.5px 8px',
    background: '#F8FAFB',
    border: '1px solid #E7E7E8',
    '&:focus': {
      border: '1.5px solid #F2C94C',
      outline: 'none',
      boxShadow: '0px 0px 0px 4px rgba(242, 201, 76, 0.17);',
      borderRadius: 8,
    },
  },
  iconSearch: {
    position: 'absolute',
    right: 0,
    marginTop: 10,
    marginRight: 20,
  },
  contentList: {
    marginTop: 6,
    maxHeight: `calc(202px - 54px)`,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
  },
  contentItem: {
    height: 46,
    width: '100%',
    cursor: 'pointer',
    paddingTop: '15px',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
    color: '#1B2030',
  },
  loadingContent: {
    position: 'absolute',
    right: 0,
    marginTop: '-30px',
    marginRight: 10,
  },
}));

export default SearchVendor;
