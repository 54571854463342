import React from 'react';
import { makeStyles } from '@material-ui/core';

// components
import { ContentText } from '../../../libs/Typography';
// icons
import PlusBtn from '../../../assets/Icons/plusBtn.png';

const HeaderTitle = ({ title, btnText, onPress }) => {
  const classes = useStyles();

  return (
    <div className={classes.titleTableContent}>
      <ContentText content={title} styling={classes.titleTable} />
      <div className={classes.contentRigth}>
        <div className={classes.btnAsnyc} onClick={onPress}>
          <img src={PlusBtn} alt="image not found" />
          <ContentText content={btnText} styling={classes.btnText} />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  titleTableContent: {
    paddingRight: 22,
    paddingLeft: 22,
    paddingTop: 27,
    paddingBottom: 27,
    backgroundColor: '#ffff',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleTable: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  contentRigth: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  btnAsnyc: {
    backgroundColor: '#1B2030',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 156,
    padding: '14.5px 14.5px',
    borderRadius: 8,
    cursor: 'pointer',
  },
  btnText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
    marginLeft: 8,
  },
}));

export default HeaderTitle;
