import * as types from './types';

// Just Example
export const poReducers = (
  state = {
    notifLoading: false,
    notifMesage: '',
    notfiData: null,
    po_dashboardNotif: null,
    data_po: null,
    isLoading: false,
    message: '',
    po_detail: null,
    detail_message: '',
    detail_isLoading: false,
    po_sap: null,
    po_sapLoading: false,
    po_sapMessage: '',
    del_poLoading: false,
    del_poMessage: '',
    publish_poLoading: false,
    publish_poMessage: '',
    cancel_poLoading: false,
    cancel_poMessage: '',
    assignment_poloading: false,
    assigment_pomessage: '',
    search_poIsLoading: false,
    search_poData: null,
    confirm_checklist_isLoading: false,
    confirm_checklist_Message: '',
  },
  action
) => {
  switch (action.type) {
    // notif PO
    case types.PO_NOTIF_LOADING:
      return { ...state, notifLoading: action.payload };

    case types.PO_NOTIF_MESSAGE:
      return { ...state, notifMesage: action.payload };

    case types.PO_NOTIF_DATA:
      return { ...state, notfiData: action.payload };

    case types.PO_NOTIF_DASHBOARD_DATA:
      return { ...state, po_dashboardNotif: action.payload };

    // PO data
    case types.PO_SET_DATA:
      return { ...state, data_po: action.payload };

    case types.PO_DATA_MESSAGE:
      return { ...state, message: action.payload };

    case types.PO_DATA_LOADING:
      return { ...state, isLoading: action.payload };

    // detail po
    case types.PO_DETAIL_DATA:
      return { ...state, po_detail: action.payload };

    case types.PO_DETAIL_MESSAGE:
      return { ...state, detail_message: action.payload };

    case types.PO_DETAIL_LOADING:
      return { ...state, detail_isLoading: action.payload };

    // Sync SAP PO
    case types.PO_SYNC_DATA:
      return { ...state, po_sap: action.payload };

    case types.PO_SYNC_MESSAGE:
      return { ...state, po_sapMessage: action.payload };

    case types.PO_SYNC_LOADING:
      return { ...state, po_sapLoading: action.payload };

    // delete PO
    case types.PO_DELETE_LOADING:
      return { ...state, del_poLoading: action.payload };

    case types.PO_DELETE_MESSAGE:
      return { ...state, del_poMessage: action.payload };

    // publish PO
    case types.PO_PUBLISH_LOADING:
      return { ...state, publish_poLoading: action.payload };

    case types.PO_PUBLISH_MESSAGE:
      return { ...state, publish_poMessage: action.payload };

    // Cancel PO
    case types.PO_CANCEL_LOADING:
      return { ...state, cancel_poLoading: action.payload };

    case types.PO_CANCEL_MESSAGE:
      return { ...state, cancel_poMessage: action.payload };

    // Assignment PO
    case types.PO_ASSIGNMENT_LOADING:
      return { ...state, assignment_poloading: action.payload };

    case types.PO_ASSIGNMENT_MESSAGE:
      return { ...state, assigment_pomessage: action.payload };

    // search PO
    case types.PO_SEARCH_LOADING:
      return { ...state, search_poIsLoading: action.payload };

    case types.PO_SEARCH_DATA:
      return { ...state, search_poData: action.payload };

    // confirm checklist attachment
    case types.PO_CONFIRM_CHECKLIST_LOADING:
      return { ...state, confirm_checklist_isLoading: action.payload };

    case types.PO_CONFIRM_CHECKLIST_MESSAGE:
      return { ...state, confirm_checklist_Message: action.payload };

    default:
      return state;
  }
};
