import React from 'react';
import { makeStyles, Grid, Box, LinearProgress } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import * as proforma from '../../redux/Proforma/actions';
import { useRouter } from '../../utils/useRouter';
import {
  formatRupiah,
  reverseFormat,
  dateFormater,
} from '../../utils/formatter';
import { get, isEmpty, startCase, uniqBy } from 'lodash';

// Components
import { ContentText } from '../../libs/Typography';
import HeaderTitle from './components/HeaderTitle';
import SectionSearch from './components/SectionSearch';
import BAST from './components/BAST';
import BASTITEM from './components/BASTITEM';
import ReportDoc from './components/ReportDoc';
import BASTPreview from '../../components/PDFpreview/BASTPreview';
import CustomizedSnackbars from '../../components/PopupMessage';
import ModalConfirm from './components/ModalComfirm';
import ModalSuccess from '../../components/ModalSuccess';

// icons
import Print from '../../assets/Icons/print.png';
import EyeWhite from '../../assets/Icons/EyeWhite.png';

//global
import globalConfig from 'src/config/global';

const schema = yup.object().shape({
  Amount: yup
    .string()
    .required('field required')
    .typeError('Type must be number'),
  PoType: yup.string(),
  NOBASTDKT: yup.string().required('field required'),
  NOBASTVENDOR: yup.string().nullable(true),
  JOBDESC: yup.string().required('field required'),
  // pihak dkt
  NamaDKT: yup.string().required('field required'),
  JabatanDKT: yup.string().required('field required'),
  NamaPerusahaanDKT: yup.string().required('field required'),
  DKTAddress: yup.string().required('field required'),
  // pihat vendor
  NamaVendor: yup.string().required('field required'),
  JabatanVendor: yup.string().required('field required'),
  NamaPerusahaanVendor: yup.string().required('field required'),
  VendorAddress: yup.string().required('field required'),
  // sumary
  NamaKegiatan: yup.string().required('field required'),
  Periode: yup.string().required('field required'),
  PONumber: yup.string(),
  AmountBeforeTax: yup.string(),
  TAX: yup.string(),
  TotalWithTax: yup.string(),
  JumlahNilaiPekerjaan: yup.string(),
  SisaPO: yup.string(),
});

const DetailProforma = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispacth = useDispatch();
  const { id } = router.query;

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Global Variable
  const { access_token, data } = useSelector((state) => state.auth);
 
  const {
    detail_data,
    detai_isLoading,
    update_vendor_Isloading,
    update_vendor_message,
  } = useSelector((state) => state.proforma);
  
  const messageStep = get(detail_data, ['status_label']) === 'Process BAST' && get(data, ['user','supplier_id'])
  
  const [dataInput, setDataInput] = React.useState(null);
  const [dataPO, setDataPO] = React.useState(null);
  const [vendorAddress, setVendorAddress] = React.useState('');
  const [DKTAddress, setDKTAddress] = React.useState('');
  const [bastDate, setBastDate] = React.useState({
    day: 0,
    monthNow: 0,
    yearNow: 0,
  });
  const [nilaiBAST, setNilaiBAST] = React.useState([]);
  const [tempCategory, setTempCategory] = React.useState([]);
  const [tempFileDKT, setTempFileDKT] = React.useState([]);
  const [showPreview, setShowPreview] = React.useState(false);
  const [previewData, setPreviewData] = React.useState(null);

  const [paramsData, setParamsData] = React.useState(null);
  const [showModalConfirm, setShowModalConfirm] = React.useState(false);
  const [showModalSuccess, setShowModalSuccess] = React.useState(false);

  // handle message

  const handleMessage = (type, show, message) => {
    setType(`${type}`);
    setShow(show);
    setNotifText(message);
  };
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };

  // end handle message

  const onChangeVendorAddress = (e) => {
    setVendorAddress(e.target.value);
  };

  const onChangeDKTAddress = (e) => {
    setDKTAddress(e.target.value);
  };

  const handleSetDataPO = (po) => {
    setDataPO(po);
  };

  const handleSetBastDate = (data) => {
    setBastDate(data);
  };

  const handlePreview = () => {
    let state = { ...detail_data, isPreview: true };
    setPreviewData(state);
    setShowPreview(true);
  };

  const handlePrint = () => {
    window.open(`/bast/${detail_data?.id}/preview`);
  };

  const handleAddFile = (e) => {
    let indexCategory = e.target.name;
    let values = [...tempCategory];

    if (detail_data?.status_label === 'Revision') {
      values[indexCategory]?.reupload?.push(e.target.files[0]);
    } else {
      values[indexCategory]?.file?.push(e.target.files[0]);
    }

    setTempCategory(values);
  };

  const handleRemoveFile = (categoryIndex, fileIndex, remoteDelete) => {
    let values = [...tempCategory];
    if(remoteDelete){
      const findFile = values[categoryIndex].file.find(e => e.id === fileIndex)
      findFile.deleted = true
    } else {
      if (detail_data?.status_label === 'Revision') {
        values[categoryIndex]?.reupload?.splice(fileIndex, 1);
      } else {
        values[categoryIndex]?.file?.splice(fileIndex, 1);
      }
    }

    setTempCategory(values);
  };

  const handleAddMoreFile = (e) => {
    let values = [...tempCategory];

    let tempId = e.target.name.split(',');

    if (detail_data?.status_label === 'Revision') {
      values[tempId[0]]?.reupload?.push(e.target.files[0]);
    } else {
      values[tempId[0]]?.file?.push(e.target.files[0]);
    }

    setTempCategory(values);
  };

  const onSubmit = () => {
    let params = new FormData();

    let dateFormat = detail_data?.bast?.dkt_date;

    params.append('_method', 'PUT');
    params.append('po_id', dataPO?.id);
    params.append('client_id', data?.user?.client_id);
    params.append('bast_date', dateFormat);
    params.append('vendor_code', detail_data?.bast?.vendor_code);
    params.append('name_1', detail_data?.bast?.name_1);
    params.append('position_1', detail_data?.bast?.position_1);
    params.append('company_1', detail_data?.bast?.company_1);
    params.append('address_1', detail_data?.bast?.address_1);
    params.append('name_2', detail_data?.bast?.name_2);
    params.append('position_2', detail_data?.bast?.position_2);
    params.append('company_2', detail_data?.bast?.company_2);
    params.append('address_2', detail_data?.bast?.address_2);
    params.append('periode', detail_data?.bast?.periode);
    params.append('amount_po', detail_data?.bast?.amount_po);
    params.append('amount_po_tax', detail_data?.bast?.amount_po_tax);
    params.append('amount_po_total', detail_data?.bast?.amount_po_total);
    params.append('bast_total', detail_data?.bast?.bast_total);
    params.append('rest_po', detail_data?.bast?.rest_po);
    params.append('activity_name', detail_data?.bast?.activity_name);

    nilaiBAST.map((item, index) => {
      params.append(`bast_detail[${[index]}][description]`, item.description);
      params.append(`bast_detail[${[index]}][amount]`, item.value);
    });

    let validArr = [];
    const files_deleted = []

    tempCategory?.map((item, index) => {
      if (item?.reupload?.length < 1) {
        validArr.push(false);
      } else {
        validArr.push(true);
      }

      let tempName = item?.identity;
      item?.reupload?.map((fileItem, fileIndex) => {
        params.append(`${tempName}[]`, fileItem ?? '');
      });

      item.file.filter(e => e.deleted).map(val => files_deleted.push(val.file_id))    
    });

    files_deleted.map((e, i) => params.append(`files_deleted[${i}]`, e))

    if (validArr.includes(true)) {
      setParamsData(params);
      for (let key of params.keys()) {
        console.log(key);
      }
      setShowModalConfirm(true);
    } else {
      handleMessage('error', true, 'Please check your upload file');
    }
  };

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => setDataInput(value));
    return () => subscription.unsubscribe();
  }, [watch]);

  // React.useState(() => {
  //   // dummy category
  //   setTempFileDKT();
  // }, []);

  React.useEffect(() => {
    dispacth(proforma.getProformaDetail(access_token, id));
  }, []);

  const allowIgnoreChecklist = (obj) => {
    
    if(!globalConfig.proforma.ignoreChecklist.includes(obj?.checklist_attachment?.name)) return true

    if(isEmpty(obj.statuses)) return false

    return true
  }

  const getListExclude = (docType) => {
    let list = globalConfig.proforma.checklistExclude

    if(docType === 'item') list = globalConfig.proforma.checklistItemExclude

    return list
  }

  React.useEffect(() => {
    if (detail_data) {
      handleSetDataPO(detail_data?.po);

      if(detail_data?.po?.doc_type === 'service'){
        setValue('Amount', formatRupiah(detail_data?.po?.total_before_discount));
        setValue('PoType', detail_data?.po?.doc_type ? startCase(detail_data?.po?.doc_type) : 'none');
        setValue('NOBASTDKT', detail_data?.bast?.dkt_code);
        let date = detail_data?.bast?.dkt_date.split('-');
        handleSetBastDate({ day: date[2], monthNow: date[1], yearNow: date[0] });
        setValue('NOBASTVENDOR', detail_data?.bast?.vendor_code);
        setValue('JOBDESC', detail_data?.bast?.activity_name ?? 'belum ada');
        setValue('NamaVendor', detail_data?.bast?.name_1);
        setValue('JabatanVendor', detail_data?.bast?.position_1);
        setValue('NamaPerusahaanVendor', detail_data?.bast?.company_1);
        setValue('VendorAddress', detail_data?.bast?.address_1);
        setValue('NamaDKT', detail_data?.bast?.name_2);
        setValue('JabatanDKT', detail_data?.bast?.position_2);
        setValue('NamaPerusahaanDKT', detail_data?.bast?.company_2);
        setValue('DKTAddress', detail_data?.bast?.address_2);
        setValue('NamaKegiatan', detail_data?.bast?.activity_name);
        setValue('Periode', detail_data?.bast?.periode);
        setValue('PONumber', detail_data?.po?.doc_num);
        setValue(
          'AmountBeforeTax',
          formatRupiah(detail_data?.po?.total_before_discount)
        );
        setValue('TAX', formatRupiah(detail_data?.po?.vat));
        setValue('TotalWithTax', formatRupiah(detail_data?.po?.doc_total_amount));
      } else {
        setValue('Amount', formatRupiah(detail_data?.po?.total_before_discount));
        setValue('PoType', detail_data?.po?.doc_type ? startCase(detail_data?.po?.doc_type) : 'none');
      }
      let tempArr = [];
      detail_data?.bast?.details?.map((item, index) => {
        let data = { description: item?.description, value: item.amount };
        tempArr.push(data);
      });
      setNilaiBAST(tempArr);
      // filter category
      let categoryArr = [];
      detail_data?.po?.checklists?.map((item, index) => {
        if(!getListExclude(detail_data?.po?.doc_type).includes(item?.checklist_attachment?.name)){ //filter
          if(allowIgnoreChecklist(item)){
            let tempData = {
              id: index,
              tempId: item?.id,
              name: item?.checklist_attachment?.name,
              desc: item?.checklist_attachment?.description,
              check_id: item?.checklist_attachment?.id,
              identity: `checklist_${item?.id}`,
              note: '',
              file: item?.statuses,
              status: '',
              reupload: [],
            };
            categoryArr.push(tempData);
          }
        }
      });
      // filtering category for upload
      // setTempCategory(categoryArr);

      const tempDkt = []
      
      for (const i of globalConfig.proforma.checklistInclude){
        
        if(get(detail_data, ['po', 'checklists'], []).find(e => e?.checklist_attachment?.name === i)){
          tempDkt.push(globalConfig.proforma.dkt_checklist[i])
        }
      }
      setTempFileDKT(tempDkt)

      if (detail_data?.status_label === 'Revision') {
        // filter for search revision category
        let valueStatus = detail_data?.approval_checklist?.filter(
          (item) => item?.status === 'revise'
        );

        let tempChecklist = [];

        // categoryArr?.map((item) => {
        //   valueStatus.map((val) => {
        //     if (val.master_checklist_id == item.tempId) {
        //       tempChecklist.push({
        //         ...item,
        //         status: val?.status,
        //         notes: val?.notes,
        //       });
        //     } else {
        //       tempChecklist.push({ ...item });
        //     }
        //   });
        // });

        let tempA = [];
        valueStatus.map((val) => {
          let result = categoryArr.filter(
            (item) => item.tempId === val.master_checklist_id
          );
          console.log('index', categoryArr.indexOf(result[0]));
          let indexOfresult = categoryArr.indexOf(result[0]);
          categoryArr[indexOfresult] = {
            ...result[0],
            status: val?.status,
            notes: val?.notes,
          };
        });

        setTempCategory(categoryArr);
      } else {
        setTempCategory(categoryArr);
      }
    }
  }, [detail_data]);

  React.useEffect(() => {
    if (nilaiBAST.length > 0) {
      let result = 0;
      nilaiBAST.map((item) => {
        return (result = result + parseInt(item.value));
      });
      setValue('JumlahNilaiPekerjaan', formatRupiah(result) ?? 'none');
    }
  }, [nilaiBAST]);

  React.useEffect(() => {
    let result =
      reverseFormat(dataInput?.Amount) -
      reverseFormat(dataInput?.JumlahNilaiPekerjaan);

    setValue('SisaPO', formatRupiah(result));
  }, [dataInput?.JumlahNilaiPekerjaan]);

  React.useEffect(() => {
    const d = new Date();
    setBastDate({
      day: d.getDate(),
      monthNow: d.getMonth(),
      yearNow: d.getFullYear(),
    });
  }, []);

  React.useEffect(() => {
    if (update_vendor_message) {
      if (update_vendor_message === 'Update proforma invoice Success') {
        setShowModalSuccess(true);
      } else {
        setType('error');
        setShow(true);
        setNotifText(update_vendor_message);
      }
      dispacth(proforma.setBlankUpdate());
    }
  }, [update_vendor_message]);

  return (
    <>
      <Grid xs={12} md={12} className={classes.mainContent}>
        <CustomizedSnackbars
          show={show}
          handleClose={handleClose}
          message={notifText}
          type={type}
        />

        <ModalConfirm
          open={showModalConfirm}
          handleCloseModalConfirm={() => setShowModalConfirm(false)}
          isLoading={update_vendor_Isloading}
          onPress={() =>
            // console.log(paramsData)
            dispacth(proforma.updateProforma(access_token, id, paramsData))
          }
        />
        <ModalSuccess
          open={showModalSuccess}
          onPress={() => router.push('/invoice')}
        />
        <BASTPreview
          open={showPreview}
          close={() => setShowPreview(false)}
          data={previewData}
        />
        <HeaderTitle
          variant
          number={detail_data?.bast?.proforma_invoice_id ?? detail_data?.id}
          status={detail_data?.status_label}
          date={detail_data?.bast?.dkt_date}
          loading={detai_isLoading}
        />
        { messageStep && !detai_isLoading && (<div className={classes.descStep}>
          Please process the BAST (00028/BAST/DKT/3/22) for proforma invoice by follow the steps below:<br/>
          1. Make sure the BAST data is already contains the appropriate data<br/>
          2. On section BAST, click PRINT<br/>
          3. Sign and put the company stamp<br/>
          4. Compile BAST, Invoice, and Tax document into one envelop and send to DKT
        </div>)}

        {detai_isLoading ? (
          <LinearProgress />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <SectionSearch
              register={register}
              errors={errors}
              handleSetDataPO={handleSetDataPO}
              dataPO={dataPO}
              detail
            />
            {dataPO ? (
              <>
              {detail_data?.po?.doc_type === 'service' && (
                <>
                <BAST
                  register={register}
                  errors={errors}
                  vendorAddress={vendorAddress}
                  onChangeVendorAddress={onChangeVendorAddress}
                  DKTAddress={DKTAddress}
                  onChangeDKTAddress={onChangeDKTAddress}
                  handleSetBastDate={handleSetBastDate}
                  bastDate={bastDate}
                  poStatus={detail_data?.bast?.rest_po_status}
                  detail
                />
                <BASTITEM
                  nilaiBAST={nilaiBAST}
                  setNilaiBAST={setNilaiBAST}
                  detail
                />
                <Box className={classes.btnContent}>
                  <Box className={classes.btnPeview} onClick={handlePreview}>
                    <img src={EyeWhite} alt="none" />
                    <ContentText
                      content={`Preview BAST`}
                      styling={classes.previewText}
                    />
                  </Box>
                  <Box
                    className={
                      // detail_data?.status_label === 'Process BAST'? 
                      classes.btnPrint
                        // : classes.btnPrintFalse
                    }
                    onClick={
                      // detail_data?.status_label === 'Process BAST'
                      //   ? 
                        handlePrint
                        // : null
                    }
                  >
                    <img src={Print} alt="none" />
                    <ContentText
                      content={`Print`}
                      styling={classes.previewText}
                    />
                  </Box>
                </Box>
                <Box className={classes.sizeBorder} />
                </>)}
                <ReportDoc
                  tempCategory={tempCategory}
                  handleAddFile={handleAddFile}
                  handleRemoveFile={handleRemoveFile}
                  handleAddMoreFile={handleAddMoreFile}
                  tempFileDKT={tempFileDKT}
                  detail
                  isRevision={detail_data?.status_label === 'Revision'}
                />
                {detail_data?.status_label === 'Revision' && (
                  <Box className={classes.ActionContent}>
                    <input
                      type="submit"
                      className={classes.submitBtn}
                      value="Update"
                    />
                    <Box
                      className={classes.cancelBtn}
                      onClick={() => router.push('/invoice')}
                    >
                      <ContentText content={'Cancel'} />
                    </Box>
                  </Box>
                )}
              </>
            ) : null}
          </form>
        )}
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  descStep: {
    margin: '0px 20px 20px 20px',
  },
  mainContent: {
    borderRadius: 8,
    background: '#fff',
  },
  btnContent: {
    padding: '30px 20px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  sizeBorder: {
    margin: '0px 20px',
    borderBottom: '1px solid #E7E7E8',
  },
  btnPeviewDisable: {
    borderRadius: 8,
    background: '#969BAB',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 181,
    padding: '9.5px 14px',
    cursor: 'pointer',
  },
  btnPeview: {
    borderRadius: 8,
    background: '#127EEE',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 181,
    padding: '9.5px 14px',
    cursor: 'pointer',
  },
  previewText: {
    marginLeft: 10,
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 700,
    color: '#fff',
  },
  btnPrint: {
    borderRadius: 8,
    background: '#127EEE',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 181,
    padding: '9.5px 14px',
    cursor: 'pointer',
    marginLeft: 20,
  },
  btnPrintFalse: {
    borderRadius: 8,
    background: '#969BAB',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 181,
    padding: '9.5px 14px',
    cursor: 'pointer',
    marginLeft: 20,
  },
  ActionContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 20,
    borderTop: '1px solid #E7E7E8',
  },
  cancelBtn: {
    height: 50,
    width: 193,
    cursor: 'pointer',
    marginLeft: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  submitBtn: {
    height: 50,
    width: 193,
    textAlign: 'center',
    background: '#1B2030',
    borderRadius: 8,
    color: '#fff',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 700,
  },
}));

export default DetailProforma;
