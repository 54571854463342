import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { getDeviceType } from '../../utils/get_device_type';
import { useRouter } from '../../utils/useRouter';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../redux/Auth/actions';
import AsyncStorage from '@callstack/async-storage';

// Component
import LayoutDashboard from '../../componentsExternal/LayoutDashboard';
import NotifDashboard from '../../componentsExternal/NotifDashboard';
import DashboardTableMenu from '../../componentsExternal/DashboardTableMenu';
import DashboardTableData from '../../componentsExternal/DashboardTableData';
import NotifApprovalDashboard from '../../componentsExternal/NotifApprovalDashboard';

const DashboardExternalHome = () => {
  const classes = useStyles();
  const router = useRouter();

  const [index, setIndex] = React.useState(1);
  const [showMessageApproval, setShowMessageApproval] = React.useState(false);

  const [deviceType, setDeviceType] = React.useState('');

  const _renderBoxMenu = (index) => {
    if (index === 1) {
      return 'Purchase Requisition (PR)';
    } else if (index === 2) {
      return 'Purchase Order (PO)';
    } else if (index === 3) {
      return 'GRPO';
    } else {
      return 'Invoice';
    }
  };

  React.useEffect(() => {
    const deviceType = getDeviceType();

    setDeviceType(deviceType);
  });

  const handleCloseMessageApproval = () => {
    setShowMessageApproval(false);
  };

  const handleGoToApproval = () => {
    router.push('/approval');
  };

  return (
    <LayoutDashboard page="Dashboard" deviceType={deviceType}>
      <NotifApprovalDashboard
        open={showMessageApproval}
        close={handleCloseMessageApproval}
        handleGoToApproval={handleGoToApproval}
      />
      <div
        className={
          deviceType === 'mobile'
            ? classes.pageContainerMobile
            : classes.pageContainer
        }
      >
        <NotifDashboard deviceType={deviceType} />
        <Box className={classes.sizebox} />
        <p>{`deviceType ${deviceType}`}</p>
        <DashboardTableMenu
          index={index}
          setIndex={setIndex}
          _renderBoxMenu={_renderBoxMenu}
          deviceType={deviceType}
        />
        <Box className={classes.sizeboxMenu} />
        <DashboardTableData index={index} />
      </div>
    </LayoutDashboard>
  );
};

const useStyles = makeStyles({
  pageContainer: {
    marginBottom: 30,
    paddingTop: 97 - 24,
  },
  pageContainerMobile: {
    maxWidth: '100vw',
    marginBottom: 30,
    paddingTop: 97 - 24,
    // marginLeft: -24,
  },
  sizebox: {
    marginTop: 20,
  },
  sizeboxMenu: {
    marginTop: 27,
  },
});

export default DashboardExternalHome;
