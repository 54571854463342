import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";

// icons
import UpArrow from "src/assets/Icons/upRow.png";
import DownArrow from "src/assets/Icons/downRow.png";

const SortArrow = ({ pressUp, pressDown }) => {
  const [isAsc, setOrder] = useState(true);

  const classes = useStyles();

  const onPressUp = () => {
    setOrder(true);
    pressUp();
  };

  const onPressDown = () => {
    setOrder(false);
    pressDown();
  };

  const renderArrow = () => {
    return (
      <div className={`${classes.wrapperArrow} ${!isAsc ? classes.rotateArrow : ''} `}>
        <img
          onClick={isAsc ? onPressUp : onPressDown}
          src={UpArrow}
          className={classes.upArrowIcon}
          alt=""
        />

        <img
          onClick={isAsc ? onPressDown : onPressUp}
          src={DownArrow}
          className={classes.downArrowIcon}
          alt=""
        />
      </div>
    );
  };

  return renderArrow();
};

const useStyles = makeStyles({
  wrapperArrow: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: 8,
  },
  rotateArrow: {
    transform: 'rotate(180deg)',
  },
  upArrowIcon: {
    height: 7,
    cursor: "pointer",
  },
  downArrowIcon: {
    height: 7,
    cursor: "pointer",
  },
});

export default SortArrow;
