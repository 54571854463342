import moment from 'moment';
// import localization from 'moment/locale/id';

export const dateFormater = (date, customFormat = 'YYYY-MM-DD', english) => {
  let idLocale = require('moment/locale/id');
  let id;
  // if (english) {
  //   return moment(date).locale('en').format(customFormat);
  // } else {
  return moment(date).locale('id', idLocale).format(customFormat);
  // }
};

export const addDateFromNow = (
  now = new Date(),
  count = 1,
  prefix = 'days'
) => {
  return moment(now, 'YYYY-MM-DD').add(prefix, count);
};

export const formatRupiah = (angka) => {
  const amount = Number(angka) || 0
  const rupiah = new Intl.NumberFormat("id-ID", {
    currency: "IDR"
  }).format(amount);
  return rupiah;
};

export const formatFileCount = (fileNumber) => {
  let result = fileNumber / 1000000;

  return result.toFixed(2);
};

export const hoursCounting = (hours) => {
  let Days = Math.floor(hours / 24);
  let Remainder = hours % 24;
  let Hours = Math.floor(Remainder);
  // let Minutes = Math.floor(60 * (Remainder - Hours));
  let Month = Math.floor(Days / 30);
  let year = Math.floor(Month / 12);

  if (year > 12) {
    return `more than 12 years`;
  }
  if (Month > 12) {
    return `${year} Years`;
  }
  if (Days > 30) {
    return `${Month} month`;
  }
  if (hours > 24) {
    return `${Days} days ${Hours} hours`;
  }
  return `${hours} hours`;
};

export const reverseFormat = (number) => {
  let result = number?.replaceAll('.', '');
  return result;
};

export const restructureItemApproval = (item, approval_transactions) => {
  const renderItem = [];

  const key = approval_transactions ? "approval_transactions" : "approval";

  let minus = 0;
  if (item?.[key]?.length)
    for (let i = 1; i < 5; i++) {
      const approvalItem = item[key].find((theItem) => theItem.level === i);

      if (!approvalItem) minus++;

      renderItem.push([approvalItem, i - minus - 1]);
    }

  return renderItem;
}

export const parseRestructuredItemApproval = (app) => {
  const noValues = [undefined, null];
  let item, newIndex;

  if (!noValues.includes(app?.[0])) item = app[0];
  if (!noValues.includes(app?.[1])) newIndex = app[1];

  return { item, newIndex };
}
