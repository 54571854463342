import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../redux/Auth/actions';
import { useRouter } from '../../utils/useRouter';
import request from '../../utils/request';

// Component
import MessageAuth from '../MessageAuth';
import CustomizedSnackbars from '../PopupMessage';

const VerifyForm = (props) => {
  const router = useRouter();
  const classes = useStyle();
  const dispacth = useDispatch();

  const { email } = router.query;

  // Global variable
  const { verify_message, verify_isLoading } = useSelector(
    (state) => state.auth
  );

  const [showMessage, setShowMassege] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
    setNotifText('');
  };

  const handleVerifyAccount = () => {
    let data = {
      email,
    };
    console.log(data);
    dispacth(actions.verifyUser(data));
  };

  React.useEffect(() => {
    if (verify_message) {
      if (verify_message === 'Verify success') {
        router.push('/login');
      } else {
        setShow(true);
        setType('error');
        setNotifText(verify_message);
      }
      dispacth(actions.setBlankVerify());
    }
  }, [verify_message]);

  return (
    <Box className={classes.wrapperForm}>
      <Grid container direction="column">
        <CustomizedSnackbars
          show={show}
          handleClose={handleClose}
          message={notifText}
          type={type}
        />
        <>
          <Typography
            className={classes.titlePage}
            variant="body1"
            component="p"
          >
            Verify User
          </Typography>
          <Typography
            className={classes.subtitle}
            variant="body1"
            component="p"
          >
            just press the button below then your account will be active
          </Typography>
          <Box className={classes.inputBoxes} />
          <input
            className={classes.submitBtn}
            type="submit"
            value="Verify Account"
            onClick={handleVerifyAccount}
          />
          <Box className={classes.wrapperLinkEmail}>
            <Typography
              className={classes.checkText}
              variant="body1"
              component="p"
            >
              Need help?
            </Typography>
            <a
              className={classes.linkText}
              href="mailto:devops@dktindonesia.org"
              onclick="window.location.href='np.html'"
            >
              Contact us
            </a>
          </Box>
        </>
      </Grid>
    </Box>
  );
};

const useStyle = makeStyles({
  wrapperForm: {
    width: 359,
    backgroundColor: '#fff',
    marginTop: 49,
    paddingTop: 31,
    paddingBottom: 31,
    paddingLeft: 20,
    paddingRight: 20,
    border: '1px solid #E7E7E8',
    borderRadius: 16,
  },
  titlePage: {
    fontWeight: 'bold',
    color: '#1B2030',
    fontSize: 21,
    lineHeight: '32px',
    textAlign: 'center',
  },
  subtitle: {
    marginTop: 10,
    fontSize: 16,
    lineHeight: '26px',
    fontFamily: 'Roboto, sans-serif',
    color: '#969BAB',
    textAlign: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    letterSpacing: '0.01em',
  },
  inputBoxes: {
    marginTop: 30,
  },
  submitBtn: {
    width: '100%',
    height: 50,
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    backgroundColor: '#1B2030',
    borderRadius: 8,
    borderWidth: 0,
    marginTop: 10,
  },
  wrapperLink: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapperLinkEmail: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
  checkText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
  },
  linkText: {
    marginLeft: 10,
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
    textDecorationLine: 'underline',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  errorText: {
    fontSize: 14,
    color: 'red',
  },
});

export default VerifyForm;
