import React from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  withStyles,
  Box,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { dateFormater } from '../../utils/formatter';
import { useRouter } from '../../utils/useRouter';
import Pagination from '@material-ui/lab/Pagination';
import { useSelector, useDispatch } from 'react-redux';
import * as proforma from '../../redux/Proforma/actions';
import * as actions_2 from '../../redux/Utility/actions';
import * as actions_3 from '../../redux/ClientVendor/actions';
import DesktopPagination from '../Pagination/DesktopPagination';
import { SortingASC, SortingDESC } from '../../utils/SortFunc';

// compontent
import { Row } from './TableCustom/index';
import ModalSyncSAP from '../ModalSyncSAP';
import ModalConfirm from '../ModalComfirm';
import DatePicker from '../DatePicker';
import { TabelLoading, TableNoData } from '../Loading/TableLoading';
import { CircleLoadingSecondary } from '../Loading/CircleLoading';
import ModalDeleteItem from '../ModalDeleteItem';
import CustomizedSnackbars from '../PopupMessage';
import SortArrow from '../SortArrow';
import SearchVendor from './SearchVendor';
import TitleWithSearchNo from '../TableData-Common/titleWithSearchNo';

// Icons
import PlusBtn from '../../assets/Icons/plusBtn.png';
import Asnyc from '../../assets/Icons/async.png';
import DateIcon from '../../assets/Icons/date.png';
import Export from '../../assets/Icons/export.png';
import Search from '../../assets/Icons/Search.png';
import Trash from '../../assets/Icons/trash.png';

// Svg
import { DownArrow } from '../../assets/Icons/svg/DownArrow';

// generate fixed table
const StickyTableCell = withStyles((theme) => ({
  head: {
    right: 0,
    // position: 'sticky',
    // zIndex: theme.zIndex.appBar + 2,
  },
  body: {
    backgroundColor: '#ddd',
    minWidth: '50px',
    right: 0,
    position: 'sticky',
    zIndex: 99,
  },
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ProformaTableData = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispacth = useDispatch();

  // Create format for table and pagination
  const [page, setPage] = React.useState(1);
  const [pagination, setPagination] = React.useState(1);
  const [fromRow, setFromRow] = React.useState(1);
  const [toRow, setToRow] = React.useState(1);
  const [TotalRow, setTotalRow] = React.useState(1);
  const [limit, setLimit] = React.useState(10);

  // initial state
  const [rowData, setRowData] = React.useState([]);
  const [sortingData, setSortingData] = React.useState([]);
  const [activityFilter, setActivityFilter] = React.useState('All Activity');
  const [status, setStatus] = React.useState('All_Status');
  const [departementFilter, setDepartementFilter] = React.useState(0);
  const [entityFilter, setEntityFilter] = React.useState(0);
  const [currencyFilter, setCurrentcyFilter] = React.useState('All Currency');
  const [categoryFilter, setCategoryFilter] = React.useState('All Category');

  const [rowSAP, setRowSAP] = React.useState([]);
  const [tempId, setTempId] = React.useState(null);

  const [docNumParam, setDocNumParam] = React.useState('');

  const [date, setDate] = React.useState({
    start: null,
    end: null,
  });

  const [showModalSync, setModalSyncSAP] = React.useState(false);
  const [showModalConfirm, setModalConfirm] = React.useState(false);

  // handle message
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };

  // end handle Message

  // Global Variable
  const { access_token } = useSelector((state) => state.auth);
  const {
    isLoading,
    message,
    data_proforma,
    delete_message,
    delete_isLoading,
  } = useSelector((state) => state.proforma);

  const { data, searchData, searchIsLoading } = useSelector(
    (state) => state.clientVendor
  );
  const {
    department_data,
    entity_data,
    activity_data,
    csv_loading,
    category_data,
  } = useSelector((state) => state.utility);

  // for handle search input vendor

  const [deleteHover, setDeleteHover] = React.useState(false);
  const [isVendorFocus, setIsVendorFocus] = React.useState(false);
  const [showVendor, setShowVendor] = React.useState(false);

  const handleChooseVandor = (data, index) => {
    setVendor(data)
  };

  const [vendor, setVendor] = React.useState(null);
  const [vendorData, setVendorData] = React.useState(null);
  const [searchText, setSearchText] = React.useState('');


  React.useEffect(() => {
    if (searchText !== '') {
      // let TempArr = [];
      // vendorData?.map((vendor, index) => {
      //   let tempName = vendor.name.toLowerCase();
      //   if (tempName.includes(searchText.toLocaleLowerCase())) {
      //     TempArr.push(vendor);
      //   }
      // });
      // setVendorData(TempArr);
      let data = { name: searchText };
      dispacth(actions_3.searchClientVendor(access_token, data));
    } else {
      setVendorData(data?.client?.data);
      dispacth(actions_3.setBlankSearchData());
    }
  }, [searchText]);

  // React.useEffect(() => {
  //   dispacth(actions_3.getClientVendor(access_token, { limit: 150 }));
  // }, []);

  React.useEffect(() => {
    setVendorData(data?.client?.data);
  }, [data]);

  React.useEffect(() => {
    setVendorData(searchData?.client);
  }, [searchData]);

  // end handle serach vendor

  // handle Delete Item
  const [showModalDelete, setShowModalDetele] = React.useState(false);
  const [tempDeleteId, setTempDeleteId] = React.useState(0);

  const handleDelete = (id) => {
    setTempDeleteId(id);
    setShowModalDetele(true);
  };

  const reqDeleteitem = () => {
    // action for request delete item
    // console.log(tempDeleteId);
    dispacth(proforma.deleteProforma(access_token, tempDeleteId));
  };

  // end handle delete

  const handleChangeDepartement = (event) => {
    setDepartementFilter(event.target.value);
  };
  const handleChangeEntity = (event) => {
    setEntityFilter(event.target.value);
  };

  const handleChangeActivity = (event) => {
    setActivityFilter(event.target.value);
  };

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
  };

  const handleChangeCurrency = (event) => {
    setCurrentcyFilter(event.target.value);
  };

  const handleChangeCategory = (event) => {
    setCategoryFilter(event.target.value);
  };

  const handleChangePagination = (event, value) => {
    setPagination(value);
  };

  const handleShowModal = () => {
    setModalSyncSAP(true);
  };
  const handleCloseModal = () => {
    setModalSyncSAP(false);
  };

  const handleShowConfirm = () => {
    setModalConfirm(true);
    setModalSyncSAP(false);
  };

  const handleCloseModalConfirm = () => {
    setModalConfirm(false);
  };

  const handleDetailData = (id) => {
    router.push(`/proforma-invoice-detail/proforma=${id}`);
  };

  const handleExportCsv = () => {
    let params = {
      doc_type: 'Proforma',
      date_start: dateFormater(date.start, 'YYYY-MM-DD'),
      date_end: dateFormater(date.end, 'YYYY-MM-DD'),
      status: status,
    };

    if (!date.start || !date.end) {
      delete params.date_start;
      delete params.date_end;
    }

    if (status === 'All_Status') delete params.status;

    dispacth(actions_2.exportCSV(access_token, params));
  };

  // function request
  const handleAddProforma = () => {
    router.push('/proforma-invoice-create');
  };

  const handleGETProformadata = () => {
    let params = {
      date_start: dateFormater(date.start, 'YYYY-MM-DD'),
      date_end: dateFormater(date.end, 'YYYY-MM-DD'),
      status: status,
      client_id: vendor?.id,
      page: pagination,
      order_by: 'id',
      order_dir: 'desc',
      limit: limit,
      // is_draft: 0,
    };

    if (docNumParam.length) params.doc_num = docNumParam;

    if (!date.start || !date.end) {
      delete params.date_start;
      delete params.date_end;
    }

    if (status === 'All_Status') delete params.status;

    if (!vendor) delete params.vendor_id;

    dispacth(proforma.getProformaData(access_token, params));
  };

  // React.useEffect(() => {
  //   let params = {
  //     order_by: 'id',
  //     order_dir: 'desc',
  //     page: 1,
  //     limit: limit,
  //   };

  //   dispacth(proforma.getProformaData(access_token, params));
  // }, []);

  React.useEffect(() => {
    setRowData(sortingData);
  }, [sortingData]);

  React.useEffect(() => {
    handleGETProformadata();
  }, [date, status, vendor, pagination, limit]);

  React.useEffect(() => {
    let tempArr = [];

    data_proforma?.data?.map((data, index) => {
      let tempData = {
        id: data?.id,
        indexId: index,
        poId: data?.bast?.dkt_code,
        invoiceId: data?.po?.doc_num,
        name: data?.supplier?.client?.name,
        service_name: data?.description ?? '-',
        invoice_date: data?.bast?.dkt_date,
        total: data?.amount,
        status: data?.status_label,
        date: data?.created_at,
      };
      tempArr.push(tempData);
    });

    let countPage = Math.ceil(data_proforma?.total / limit);
    setPage(countPage);
    if (pagination > countPage) {
      setPagination(countPage);
    }
    setFromRow(data_proforma?.from);
    setToRow(data_proforma?.to);
    setTotalRow(data_proforma?.total);
    setRowData(tempArr);
    // setRowData(rows);
  }, [data_proforma]);

  React.useEffect(() => {
    if (delete_message) {
      if (delete_message === 'Delete Prorma Invoice Success') {
        handleGETProformadata();
        setShowModalDetele(false);
      } else if (
        delete_message !== '' &&
        delete_message !== 'Delete Prorma Invoice Success'
      ) {
      }
      dispacth(proforma.setBlankDelete());
    }
  }, [delete_message]);

  return (
    <>
      <Grid container md={12} sm={12} xs={12} style={{ maxWidth: '100vw' }}>
        <Grid md={12} sm={12} xs={12}>
          <CustomizedSnackbars
            show={show}
            handleClose={handleClose}
            message={notifText}
            type={type}
          />
          <ModalConfirm
            open={showModalConfirm}
            setModalConfirm={setModalConfirm}
            handleCloseModalConfirm={handleCloseModalConfirm}
          />
          <ModalDeleteItem
            open={showModalDelete}
            close={() => setShowModalDetele(false)}
            id={tempDeleteId}
            onPress={reqDeleteitem}
            isLoading={delete_isLoading}
          />
          <ModalSyncSAP
            open={showModalSync}
            setModalSyncSAP={setModalSyncSAP}
            handleCloseModal={handleCloseModal}
            handleShowConfirm={handleShowConfirm}
            data={rowSAP}
          />
          <div className={classes.titleTableContent}>
            <TitleWithSearchNo
              Title={<Typography
                className={classes.titleTable}
                variant="body1"
                component="p"
              >
                PROFORMA INVOICE
              </Typography>}
              docNumParam={docNumParam}
              setDocNumParam={setDocNumParam}
              sendApiQuery={handleGETProformadata}
            />
            
            {/* <div className={classes.contentRigth}>
              <div className={classes.btnAsnyc} onClick={handleAddProforma}>
                <img src={PlusBtn} alt="image not found" />
                <Typography
                  className={classes.btnText}
                  variant="body1"
                  component="p"
                >
                  Add Proforma Invoice
                </Typography>
              </div>
            </div> */}
          </div>
        </Grid>
        <Box className={classes.filterContent}>
          {/* <div className={classes.filterResponsive}> */}
            <Box className={classes.dateContent}>
              <div className={classes.dateRangeIcon}>
                <img src={DateIcon} alt="image not found" />
              </div>
              <div className={classes.containerDate}>
                <DatePicker
                  onDatePick={(pickedDay) => {
                    setDate(pickedDay);
                  }}
                  placeholder="Select Date Range"
                />
              </div>
            </Box>

            <SearchVendor handleChooseVandor={handleChooseVandor} />

            <Box className={classes.filterStatusContent}>
              <FormControl className={classes.formControl}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  IconComponent={() => <DownArrow />}
                  value={status}
                  onChange={handleChangeStatus}
                >
                  <MenuItem value={'All_Status'}>All Status</MenuItem>
                  <MenuItem value={'open'}>Open</MenuItem>
                  <MenuItem value={'closed'}>Closed</MenuItem>
                  <MenuItem value={'cancelled'}>Cancelled</MenuItem>
                </Select>
              </FormControl>
            </Box>
          {/* </div> */}
          <Box className={classes.filterBlankArea}>{/* black area */}</Box>
          <Box className={classes.exportContent}>
            <div className={classes.btnExportBlue} onClick={handleExportCsv}>
              {csv_loading ? (
                <Box className={classes.loadingExport}>
                  <CircleLoadingSecondary />
                </Box>
              ) : (
                <img src={Export} alt="image not found" />
              )}
              <Typography
                className={classes.btnText}
                variant="body1"
                component="p"
              >
                Export .csv
              </Typography>
            </div>
          </Box>
        </Box>

        <div style={{ width: '100%' }}>
          <Grid md={12}>
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        Bast No.
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'invoiceId', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'invoiceId', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        PO No.
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'poId', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'poId', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        Vendor Name
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'name', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'name', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        Service Desc.
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'service_name', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'service_name', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        Invoice Date
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'invoice_date', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'invoice_date', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        Total
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'total', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'total', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        Status
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'status', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'status', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        Date
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'date', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'date', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>

                    <StickyTableCell className={classes.head}>
                      <StyledTableCell className={classes.head} numeric>
                        Action
                      </StyledTableCell>
                    </StickyTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? null : rowData.length > 0 ? (
                    rowData?.map((row, index) => (
                      <Row
                        key={index}
                        index={index}
                        row={row}
                        handleDetailData={handleDetailData}
                        handleDelete={handleDelete}
                      />
                    ))
                  ) : (
                    <TableNoData colspan={12} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {isLoading ? <TabelLoading /> : null}
          </Grid>
        </div>
        {isLoading ? null : rowData?.length > 0 ? (
          <DesktopPagination
            toRow={toRow}
            fromRow={fromRow}
            TotalRow={TotalRow}
            page={page}
            handleChangePagination={handleChangePagination}
            limit={limit}
            handleChangeLimit={handleChangeLimit}
            pagination={pagination}
          />
        ) : null}
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  // cutome table
  filterResponsive: {
    display: 'flex',
    overflowX: 'auto',
    overflowY: 'hidden',
    minWidth: '348px',
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  wrapperTableHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  wrapperTableHead: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  iconSort: {
    paddingTop: 15,
    width: 10,
    marginLeft: 5,
  },
  head: {
    backgroundColor: '#fff',
    fontSize: 16,
    lineHeight: '30px',
    fontWeight: 'bold',
    color: '#000000',
    borderBottom: 'none',
    '&:nth-child(1)': {
      minWidth: 100,
      borderBottom: 'none',
    },
    '&:nth-child(2)': {
      minWidth: 130,
    },
    '&:nth-child(3)': {
      minWidth: 150,
    },
    '&:nth-child(4)': {
      minWidth: 180,
    },
    '&:nth-child(5)': {
      minWidth: 180,
    },
    '&:nth-child(6)': {
      minWidth: 130,
    },
    '&:nth-child(7)': {
      minWidth: 120,
    },
    '&:nth-child(8)': {
      minWidth: 200,
    },
    '&:nth-child(9)': {
      minWidth: 100,
    },
  },
  tableContainer: {
    // maxHeight: '400px',
    zIndex: 80,
    Width: '100%',
    '&.MuiTableContainer-root .MuiTableRow-root .MuiTableCell-root.MuiTableCell-head':
      {
        paddingLeft: 8,
      },
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
  },
  cell: {
    minWidth: '100px',
  },
  // end custom table
  titleTableContent: {
    paddingRight: 22,
    paddingLeft: 22,
    paddingTop: 27,
    paddingBottom: 27,
    backgroundColor: '#ffff',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleTable: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  contentRigth: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  btnAsnyc: {
    backgroundColor: '#1B2030',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '14px 20px',
    borderRadius: 8,
    cursor: 'pointer',
  },
  btnAsnycDisable: {
    backgroundColor: '#969BAB',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '14px 20px',
    borderRadius: 8,
  },
  btnExport: {
    backgroundColor: '#127EEE',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '14px 20px',
    borderRadius: 8,
    cursor: 'pointer',
  },
  btnExportBlue: {
    backgroundColor: '#127EEE',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 14px',
    borderRadius: 8,
    cursor: 'pointer',
    maxHeight: 40,
    maxWidth: 154,
  },
  btnText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
    marginLeft: 8,
  },
  notifContent: {
    marginRight: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  notifText: {
    fontWeight: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#1B2030',
    marginLeft: 13,
  },

  iconAction: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 20,
  },
  Action: {
    cursor: 'pointer',
  },
  tableContent: {
    backgroundColor: '#fff',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderBottom: 'none !important',
    position: 'relative',
    maxWidth: 1440,
  },
  tableHead: {
    fontSize: 16,
    lineHeight: '30px',
    color: '#000000',
    fontWeight: 'bold',
    // position: 'relative',
    // maxWidth: 1440,
    overflowX: 'scroll',

    '&.MuiTableRow-root .MuiTableCell-root': {
      borderBottom: 'none',
      fontSize: 16,
      lineHeight: '30px',
      color: '#000000',
      fontWeight: 'bold',
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 16,
      paddingBottom: 16,
    },
    '&.MuiTableRow-root .MuiTableCell-head:nth-last-child(1)': {
      position: '-webkit-sticky',
      position: 'sticky',
      background: '#fff',
      left: 0,
      zIndex: 1,
    },
  },
  // Pagination styling
  descTable: {
    paddingTop: 11.5,
    paddingBottom: 11.5,
  },
  descTableText: {
    color: '#969BAB',
    fontSize: 16,
    lineHeight: '21px',
  },
  paginationContent: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pagination: {
    borderRadius: 0,
    '&.MuiPagination-root .MuiPagination-ul .MuiPaginationItem-root': {
      margin: 0,
    },
    borderRadius: 0,
    '&.MuiPagination-root .MuiPagination-ul .MuiPaginationItem-rounded': {
      borderRadius: 0,
    },
    '&.MuiPagination-root .MuiPagination-ul .MuiButtonBase-root': {
      backgroundColor: '#fff',
      color: '#969BAB',
    },
    '&.MuiPagination-root .MuiPagination-ul .MuiButtonBase-root.Mui-selected': {
      backgroundColor: '#1B2030',
      color: '#fff',
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-child(1)': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-child(1) button': {
      color: '#1B2030',
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-last-child(1)': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-last-child(1) button': {
      color: '#1B2030',
    },
  },
  wrapperMenuContent: {
    width: '100%',
  },
  // Filter Content
  filterContent: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: '#fff',
    borderTop: '1px solid #E7E7E8',
    borderBottom: '1px solid #E7E7E8',
    width: '100%',

    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
    // position: 'relative',
  },
  dateContent: {
    // width: '100%',
    minWidth: 265,
    // backgroundColor: 'red',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingRight: 5,
  },
  dateRangeIcon: {
    width: 58,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E7E7E8',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  containerDate: {
    width: `100%`,
    maxWidth: `calc(100% - 58px)`,
  },
  // styling select
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 5,
    paddingBottom: 5,
    // maxWidth: 220,
    width: '100%',
    // minWidth: 160,
    maxHeight: 40,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      // marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#969BAB',
      paddingRight: 2,
      background: 'none',
    },
  },
  filterCategoryContent: {
    width: '100%',
    maxWidth: 190,
    minWidth: 185,
    paddingLeft: 5,
    paddingRight: 5,
  },
  filterDepartmentContent: {
    width: '100%',
    maxWidth: 172,
    minWidth: 170,
    paddingLeft: 5,
    paddingRight: 5,
  },
  filterEntityContent: {
    paddingLeft: 5,
    paddingRight: 5,
    width: '100%',
    maxWidth: 133,
    minWidth: 130,
  },
  filterActivityContent: {
    paddingLeft: 5,
    paddingRight: 5,
    width: '100%',
    maxWidth: 133,
    minWidth: 130,
  },
  filterCurrencyContent: {
    paddingLeft: 5,
    paddingRight: 5,
    width: '100%',
    maxWidth: 133,
    minWidth: 130,
  },
  filterStatusContent: {
    paddingLeft: 5,
    paddingRight: 5,
    width: '100%',
    maxWidth: 133,
    minWidth: 130,
  },
  exportContent: {
    marginRight: 24,
    paddingRight: 20,
    width: '100%',
    maxWidth: 164,
    minWidth: 154,
    position: 'absolute',
    right: 0,
    zIndex: 90,
    backgroundColor: '#fff',
  },
  filterBlankArea: {
    minWidth: 150,
  },
  // styling menu select
  boxDepartment: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  bodyDataSubText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    marginLeft: 4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  loadingExport: {
    width: 20,
    height: 20,
    maxHeight: 20,
    maxWidth: 20,
  },
  // Styling for Input vendor
  inputVendor: {
    width: '100%',
    height: 40,
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    paddingLeft: 15,
    '&.focus-visible': {
      border: '1px solid #E7E7E8',
      outline: 'none',
    },
  },
  filterVendorContent: {
    paddingLeft: 5,
    paddingRight: 5,
    width: '100%',
    maxWidth: 250,
    minWidth: 250,
    // position: 'relative',
  },
  iconDownArrow: {
    position: 'absolute',
    zIndex: 100,
    marginTop: -25,
    marginLeft: 200,
  },
  iconTrash: {
    position: 'absolute',
    zIndex: 100,
    marginTop: -30,
    marginLeft: 150,
    cursor: 'pointer',
  },
  exportContent: {
    marginRight: 24,
    paddingRight: 20,
    width: '100%',
    maxWidth: 164,
    minWidth: 154,
    position: 'absolute',
    right: 0,
    zIndex: 90,
    backgroundColor: '#fff',
  },
  filterBlankArea: {
    minWidth: 150,
  },
  contentDataVendor: {
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid #E7E7E8',
    minHeight: 202,
    maxHeight: 202,
    position: 'absolute',
    maxWidth: 245,
    borderRadius: 8,
    padding: 6,
    zIndex: 99999,
  },
  contentVendorSearch: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    width: '100%',
    border: '1px solid #E7E7E8',
    paddingRight: 15,
    borderRadius: 8,
  },
  searchVendor: {
    flex: 1,
    height: 40,
    border: 'none',
    outline: 'none',
    paddingLeft: 12,
  },
  listVendorName: {
    marginTop: 8,
    minHeight: `calc(202px - 56px)`,
    maxHeight: `calc(202px - 56px)`,
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.4em',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
      borderRadius: 4,
    },
  },
  TitleVendor: {
    height: 46,
    marginTop: 2,
    marginBottom: 2,
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
    cursor: 'pointer',
  },
}));

const rows = [
  {
    id: 1,
    poId: 23891,
    invoiceId: 674821,
    name: 'Core Tecknologi, PT',
    service_name: 'IT Project',
    invoice_date: '12/07/2021',
    total: '3150000',
    status: 'open',
    date: '12/07/2021 09:34',
  },
  {
    id: 2,
    poId: 23891,
    invoiceId: 674821,
    name: 'Core Tecknologi, PT',
    service_name: 'IT Project',
    invoice_date: '12/07/2021',
    total: '3150000',
    status: 'closed',
    date: '12/07/2021 09:34',
  },
];
export default ProformaTableData;
