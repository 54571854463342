import * as types from './types';

// Just Example
export const grpoReducers = (
  state = {
    notifLoading: false,
    notifMesage: '',
    notfiData: null,
    grpo_dashboardNotif: null,
    data_grpo: null,
    isLoading: false,
    message: '',
    grpo_detail: null,
    detail_message: '',
    detail_isLoading: false,
    grpo_sap: null,
    grpo_sapLoading: false,
    grpo_sapMessage: '',
    del_grpoLoading: false,
    del_grpoMessage: '',
    publish_grpoLoading: false,
    publish_grpoMessage: '',
    cancel_grpoLoading: false,
    cancel_grpoMessage: '',
    assignment_loading: false,
    assigment_message: '',
  },
  action
) => {
  switch (action.type) {
    // notif GRPO
    case types.GRPO_NOTIF_LOADING:
      return { ...state, notifLoading: action.payload };

    case types.GRPO_NOTIF_MESSAGE:
      return { ...state, notifMesage: action.payload };

    case types.GRPO_NOTIF_DATA:
      return { ...state, notfiData: action.payload };

    case types.GRPO_NOTIF_DASHBOARD_DATA:
      return { ...state, grpo_dashboardNotif: action.payload };

    // GRPO data
    case types.GRPO_SET_DATA:
      return { ...state, data_grpo: action.payload };

    case types.GRPO_DATA_MESSAGE:
      return { ...state, message: action.payload };

    case types.GRPO_DATA_LOADING:
      return { ...state, isLoading: action.payload };

    // detail grpo
    case types.GRPO_DETAIL_DATA:
      return { ...state, grpo_detail: action.payload };

    case types.GRPO_DETAIL_MESSAGE:
      return { ...state, detail_message: action.payload };

    case types.GRPO_DETAIL_LOADING:
      return { ...state, detail_isLoading: action.payload };

    // Sync SAP GRPO
    case types.GRPO_SYNC_DATA:
      return { ...state, grpo_sap: action.payload };

    case types.GRPO_SYNC_MESSAGE:
      return { ...state, grpo_sapMessage: action.payload };

    case types.GRPO_SYNC_LOADING:
      return { ...state, grpo_sapLoading: action.payload };

    // delete GRPO
    case types.GRPO_DELETE_LOADING:
      return { ...state, del_grpoLoading: action.payload };

    case types.GRPO_DELETE_MESSAGE:
      return { ...state, del_grpoMessage: action.payload };

    // publish GRPO
    case types.GRPO_PUBLISH_LOADING:
      return { ...state, publish_grpoLoading: action.payload };

    case types.GRPO_PUBLISH_MESSAGE:
      return { ...state, publish_grpoMessage: action.payload };

    // Cancel GRPO
    case types.GRPO_CANCEL_LOADING:
      return { ...state, cancel_grpoLoading: action.payload };

    case types.GRPO_CANCEL_MESSAGE:
      return { ...state, cancel_grpoMessage: action.payload };

    // Assignment GRPO
    case types.GRPO_ASSIGNMENT_LOADING:
      return { ...state, assignment_loading: action.payload };

    case types.GRPO_ASSIGNMENT_MESSAGE:
      return { ...state, assigment_message: action.payload };

    default:
      return state;
  }
};
