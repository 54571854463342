import React from "react";
import "./styles.css";
import { useRouter } from "../../utils/useRouter";
import { useSelector, useDispatch } from "react-redux";
import { startCase, get } from "lodash";
import moment from "moment";

import request from "src/utils/request";
import { formatRupiah } from "src/utils/formatter";

import * as proforma from "../../redux/Proforma/actions";

const BAST = () => {
  const router = useRouter();
  const dispacth = useDispatch();

  const { id, preview } = router.query;
  const [hari, setHari] = React.useState("Senin");
  const [day, setDay] = React.useState(0);
  const [moth, setMonth] = React.useState(0);
  const [year, setYaer] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [qrCode, setQRcode] = React.useState();

  const libmonth = [
    "January",
    "February",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  const libDay = [
    "Minggu",
    "Senin",
    "Selasa",
    "Rabu",
    "Kamis",
    "Jumat",
    "Sabtu",
  ];

  // Global Variable
  const { access_token } = useSelector((state) => state.auth);
  const { detail_data } = useSelector((state) => state.proforma);

  React.useEffect(() => {
    dispacth(proforma.getProformaDetail(access_token, id));
  }, []);

  React.useEffect(() => {
    if (detail_data) {
      let date = detail_data?.bast?.dkt_date.split("-");
      setDay(date[2]);
      setMonth(libmonth[parseInt(date[1] - 1)]);
      setYaer(date[0]);
      let d = new Date(date);
      setHari(libDay[d.getDay()]);

      let result = 0;
      detail_data?.bast?.details?.map((item) => {
        result += item.amount;
      });

      setTotal(result);
      getQRcode();
    }
  }, [detail_data]);

  const getQRcode = () => {
    request({
      method: "get",
      url: `proforma-invoice/${id}/bast-qrcode`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data: {},
    })
      .then((res) => {
        setQRcode(res);
        // setTimeout(()=>window.print(), 1000)
      })
      .catch((err) => {
        // setTimeout(()=>window.print(), 1000)
      });
  };

  React.useEffect(() => {
    if (qrCode) {
      window.print();
    }
  }, [qrCode]);

  return (
    <>
      <div className="mainContent bast-preview">
        <div id="bast_page">
          <div>
            <h1 className="bast_title center">BERITA ACARA SERAH TERIMA</h1>
            <p className="center" style={{ margin: 0 }}>
              Nomor : {detail_data?.bast?.dkt_code}
            </p>{" "}
            {detail_data?.bast?.vendor_code && <p className="center" style={{ margin: 0 }}>
            Nomor BAST Vendor : {detail_data?.bast?.vendor_code}
            </p>}
            {qrCode && (
              <img
                src={`data:image/jpeg;base64,${qrCode}`}
                style={{ marginTop: "-70px", float: "right" }}
                width={60}
              />
            )}
          </div>
          {/* <p className="center" style={{ margin: 0 }}>
                Nomor BAST Vendor <em>(optional)</em> :{' '}
                {detail_data?.bast?.vendor_code}
              </p> */}
          <br />
          <p>
            Pada hari ini {hari} tanggal {day} bulan {moth} tahun {year} (
            {moment(detail_data?.bast?.dkt_date).format("DD-MM-YYYY")}) telah
            dilakukan serah terima kegiatan/pekerjaan/jasa{" "}
            {detail_data?.bast?.activity_name} yang diwakili oleh:
          </p>
          <div style={{ fontWeight: "700" }}>
            <ol type="I" style={{ fontSize: "16px" }}>
              <li>
                PIHAK KESATU
                <div>
                  <table>
                    <tr>
                      <td>
                        <p style={{ marginRight: 4, width: "175px" }}>Nama</p>
                      </td>
                      <td>:</td>
                      <td>
                        <p style={{ padding: "0px 4px" }}>
                          {detail_data?.bast?.name_1}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p style={{ marginRight: 4, width: "175px" }}>
                          Jabatan
                        </p>
                      </td>
                      <td>:</td>
                      <td>
                        <p style={{ padding: "0px 4px" }}>
                          {detail_data?.bast?.position_1}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p style={{ marginRight: 4, width: "175px" }}>
                          Nama Perusahaan
                        </p>
                      </td>
                      <td>:</td>
                      <td>
                        <p style={{ padding: "0px 4px" }}>
                          {detail_data?.bast?.company_1}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p style={{ marginRight: 4, width: "175px" }}>Alamat</p>
                      </td>
                      <td>:</td>
                      <td>
                        <p style={{ padding: "0px 4px" }}>
                          {detail_data?.bast?.address_1}
                        </p>
                      </td>
                    </tr>
                  </table>
                </div>
                <br />
              </li>
              <li>
                PIHAK KEDUA
                <div>
                  <table>
                    <tr>
                      <td>
                        <p style={{ marginRight: 4, width: "175px" }}>Nama</p>
                      </td>
                      <td>:</td>
                      <td>
                        <b style={{ padding: "0px 4px" }}>
                          {detail_data?.bast?.name_2}
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p style={{ marginRight: 4, width: "175px" }}>
                          Jabatan
                        </p>
                      </td>
                      <td>:</td>
                      <td>
                        <p style={{ padding: "0px 4px" }}>
                          {detail_data?.bast?.position_2}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p style={{ marginRight: 4, width: "175px" }}>
                          Nama Perusahaan
                        </p>
                      </td>
                      <td>:</td>
                      <td>
                        <p style={{ padding: "0px 4px" }}>
                          {detail_data?.bast?.company_2}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p style={{ marginRight: 4, width: "175px" }}>Alamat</p>
                      </td>
                      <td>:</td>
                      <td>
                        <p style={{ padding: "0px 4px" }}>
                          {detail_data?.bast?.address_2}
                        </p>
                      </td>
                    </tr>
                  </table>
                </div>
              </li>
            </ol>
          </div>
          <p style={{ padding: "15px 0px" }}>
            Dengan ini PIHAK KESATU menyerahkan laporan penyelesaian
            kegiatan/pekerjaan/jasa kepada PIHAK KEDUA dengan detail sebagai
            berikut:
          </p>
          <div style={{ marginLeft: "25px", marginRight: "50px" }}>
            <table>
              <tr>
                <td>
                  <p style={{ marginRight: 4 }}>
                    Jenis Kegiatan/Pekerjaan/Jasa
                  </p>
                </td>
                <td> : </td>
                <td>
                  <p style={{ marginLeft: 4 }}>
                    {detail_data?.bast?.activity_name}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p style={{ marginRight: 4 }}>Periode</p>
                </td>
                <td> : </td>
                <td>
                  <p style={{ marginLeft: 4 }}>{detail_data?.bast?.periode}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p style={{ marginRight: 4 }}>Nomor Purchase Order</p>
                </td>
                <td> : </td>
                <td>
                  <p style={{ marginLeft: 4 }}>{detail_data?.po?.doc_num}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p style={{ marginRight: 4 }}>Nilai Purchase Order</p>
                </td>

                <td> : </td>
                <td>
                  <b style={{ marginLeft: 4 }}>
                    Rp
                    {formatRupiah(
                      get(detail_data, ["po", "total_before_discount"], "")
                    )}
                  </b>
                </td>
              </tr>
            </table>
            <p style={{ marginTop: 8 }}>
              Nilai BAST sesuai dengan detil PO sebagai berikut :
            </p>
            <table id="tabel-detil-po" style={{ marginTop: 6 }}>
              <tr>
                <th width="5%">No.</th>
                <th width="70%">Deskripsi</th>
                <th width="25%">
                  Nilai Pekerjaan
                  <br />
                  <small>
                    <em>(excl. Ppn)</em>
                  </small>
                </th>
              </tr>
              {detail_data?.bast?.details?.map((item, index) => (
                <tr key={index}>
                  <td widtd="5%">{index + 1}</td>
                  <td widtd="70%">{item.description}</td>
                  <td widtd="25%" style={{ textAlign: "right" }}>
                    Rp{formatRupiah(get(item, ["amount"], ""))}
                  </td>
                </tr>
              ))}

              <tr>
                <td widtd="75%" colspan="2" style={{ textAlign: "right" }}>
                  <b>TOTAL</b>
                </td>
                <td widtd="25%" style={{ textAlign: "right" }}>
                  <b>Rp{formatRupiah(total || "")}</b>
                </td>
              </tr>
            </table>
            <br />
            <table>
              <tr>
                <td>
                  Sisa Nilai PO <em>(excl. Ppn)</em>
                </td>
                <td>
                  <p style={{ padding: "0px 4px" }}>:</p>
                </td>
                <td>{detail_data?.bast?.rest_po}</td>
              </tr>
              <tr>
                <td>Status Sisa PO (Open/Close)</td>
                <td>
                  <p style={{ padding: "0px 4px" }}>:</p>
                </td>
                <td>{startCase(detail_data?.bast?.rest_po_status)}</td>
              </tr>
              <tr>
                <td>Dokumen Kelengkapan (Terlampir)</td>
                <td>
                  <p style={{ padding: "0px 4px" }}>:</p>
                </td>
                <td></td>
              </tr>
            </table>
          </div>
          <br />
          <p>
            Demikian Berita Acara Serah Terima kegiatan/pekerjaan/jasa ini
            dibuat dengan sebenarnya yang akan digunakan sebagai dokumen
            pendukung dalam proses pembayaran.
          </p>
          <table style={{ width: "100%", marginTop: 20 }}>
            <tr>
              <td width="30%">Pihak Kesatu</td>
              <td width="40%"></td>
              <td width="30%">Pihak Kedua</td>
            </tr>
            <tr>
              <td width="30%" style={{ height: 50 }}></td>
              <td width="40%"></td>
              <td width="30%"></td>
            </tr>
            <tr>
              <td width="30%">
                <p style={{ margin: 0 }}>{detail_data?.bast?.name_1}</p>
                <br />
                {detail_data?.bast?.position_1}
              </td>
              <td width="40%"></td>
              <td width="30%">
                <p style={{ margin: 0 }}>{detail_data?.bast?.name_2}</p>
                <br />
                {detail_data?.bast?.position_2}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </>
  );
};

export default BAST;
