import * as types from './types';

// Just Example
export const opReducers = (
  state = {
    notifLoading: false,
    notifMesage: '',
    notfiData: null,
    op_dashboardNotif: null,
    op_sap: null,
    op_sapLoading: false,
    op_sapMessage: '',
    data_op: null,
    isLoading: false,
    message: '',
    detail_opisLoading: false,
    detail_opMessage: '',
    detail_opData: null,
    publish_opIsLoading: false,
    publish_opMessage: '',
    confirm_opIsLoading: false,
    confirm_opMesssage: '',
    delete_opIsLoading: false,
    delete_opMessage: '',
  },
  action
) => {
  switch (action.type) {
    // notif OP
    case types.OP_NOTIF_LOADING:
      return { ...state, notifLoading: action.payload };

    case types.OP_NOTIF_MESSAGE:
      return { ...state, notifMesage: action.payload };

    case types.OP_NOTIF_DATA:
      return { ...state, notfiData: action.payload };

    case types.OP_NOTIF_DASHBOARD_DATA:
      return { ...state, op_dashboardNotif: action.payload };

    // SYNC data OP
    case types.OP_SYNC_LOADING:
      return { ...state, op_sapLoading: action.payload };

    case types.OP_SYNC_MESSAGE:
      return { ...state, op_sapMessage: action.payload };

    case types.OP_SYNC_DATA:
      return { ...state, op_sap: action.payload };

    // get data OP
    case types.OP_DATA_LOADING:
      return { ...state, isLoading: action.payload };

    case types.OP_DATA_MESSAGE:
      return { ...state, message: action.payload };

    case types.OP_SET_DATA:
      return { ...state, data_op: action.payload };

    // get detail data OP
    case types.OP_DETAIL_LOADING:
      return { ...state, detail_opisLoading: action.payload };

    case types.OP_DETAIL_MESSAGE:
      return { ...state, detail_opMessage: action.payload };

    case types.OP_DETAIL_DATA:
      return { ...state, detail_opData: action.payload };

    // publish op
    case types.PUBLISH_OP_LOADING:
      return { ...state, publish_opIsLoading: action.payload };

    case types.PUBLISH_OP_MESSAGE:
      return { ...state, publish_opMessage: action.payload };

    // confirm op
    case types.CONFIRM_OP_LOADING:
      return { ...state, confirm_opIsLoading: action.payload };

    case types.CONFIRM_OP_MESSAGE:
      return { ...state, confirm_opMesssage: action.payload };

    // delete op
    case types.DELETE_OP_LOADING:
      return { ...state, delete_opIsLoading: action.payload };

    case types.DELETE_OP_MESSAGE:
      return { ...state, delete_opMessage: action.payload };

    default:
      return state;
  }
};
