import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const Footer = () => {
  const classes = useStyles();

  return (
    <Box className={classes.footerContent}>
      <Typography className={classes.footerText}>
        © DKT Indonesia 2021. All right reserved.
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles({
  footerContent: {
    marginTop: 56,
    marginBottom: 37,
    backgroundColor: 'transpatent',
  },
  footerText: {
    fontFamily: 'Manrope, sans-serif',
    fontSize: 14,
    lineHeight: '24px',
    color: '#969BAB',
  },
});

export default Footer;
