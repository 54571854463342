import * as types from './types';

// Just Example
export const approvalReducers = (
  state = {
    // section pr
    isLoading: false,
    message: '',
    data_approval: null,

    detail_prIsLoading: false,
    detail_prMessage: '',
    detail_prData: null,

    update_prIsloading: false,
    update_prMessage: '',

    submit_prIsloading: false,
    submit_prMessage: '',

    // section CA
    isLoadingCA: false,
    messageCA: '',
    data_approvalCA: null,

    // section po
    isLoadingPO: false,
    messagePO: '',
    data_approvalPO: null,

    detail_poIsLoading: false,
    detail_poMessage: '',
    detail_poData: null,

    update_poIsloading: false,
    update_poMessage: '',

    submit_poIsloading: false,
    submit_poMessage: '',

    // section grpo
    isLoadingGRPO: false,
    messageGRPO: '',
    data_approvalGRPO: null,

    detail_grprIsLoading: false,
    detail_grprMessage: '',
    detail_grprData: null,

    update_grpoIsloading: false,
    update_grpoMessage: '',

    submit_grprIsloading: false,
    submit_grprMessage: '',

    // section general
    isLoadingGeneral: false,
    messageGeneral: '',
    data_approvalGeneral: null,

    detail_GeneralIsLoading: false,
    detail_GeneralMessage: '',
    detail_GeneralData: null,

    update_GeneralIsloading: false,
    update_GeneralMessage: '',

    submit_GeneralIsloading: false,
    submit_GeneralMessage: '',
  },
  action
) => {
  switch (action.type) {
    // get approval PR
    case types.APPROVAL_LOADING:
      return { ...state, isLoading: action.payload };
    case types.APPROVAL_DATA:
      return { ...state, data_approval: action.payload };
    case types.APPROVAL_MESSAGE:
      return { ...state, message: action.payload };

    // get approval pr id
    case types.PR_DETAIL_LOADING:
      return { ...state, detail_prIsLoading: action.payload };
    case types.PR_DETAIL_MESSAGE:
      return { ...state, detail_prMessage: action.payload };
    case types.PR_DETAIL_DATA:
      return { ...state, detail_prData: action.payload };

    // update approval pr
    case types.PR_UPDATE_LOADING:
      return { ...state, update_prIsloading: action.payload };
    case types.PR_UPDATE_MESSAGE:
      return { ...state, update_prMessage: action.payload };

    // submit approval pr
    case types.PR_SUBMIT_LOADING:
      return { ...state, submit_prIsloading: action.payload };
    case types.PR_SUBMIT_MESSAGE:
      return { ...state, submit_prMessage: action.payload };


      // get approval CA
    case types.CA_APPROVAL_LOADING:
      return { ...state, isLoadingCA: action.payload };
    case types.CA_APPROVAL_DATA:
      return { ...state, data_approvalCA: action.payload };
    case types.CA_APPROVAL_MESSAGE:
      return { ...state, messageCA: action.payload };

          // get approval CA id
    case types.PO_DETAIL_LOADING:
      return { ...state, detail_caIsLoading: action.payload };
    case types.CA_DETAIL_MESSAGE:
      return { ...state, detail_caMessage: action.payload };
    case types.CA_DETAIL_DATA:
      return { ...state, detail_caData: action.payload };

    // update approval CA
    case types.CA_UPDATE_LOADING:
      return { ...state, update_caIsloading: action.payload };
    case types.CA_UPDATE_MESSAGE:
      return { ...state, update_caMessage: action.payload };

    // submit approval CA
    case types.CA_SUBMIT_LOADING:
      return { ...state, submit_caIsloading: action.payload };
    case types.CA_SUBMIT_MESSAGE:
      return { ...state, submit_caMessage: action.payload };

    // get approval PO
    case types.PO_APPROVAL_LOADING:
      return { ...state, isLoadingPO: action.payload };
    case types.PO_APPROVAL_DATA:
      return { ...state, data_approvalPO: action.payload };
    case types.PO_APPROVAL_MESSAGE:
      return { ...state, messagePO: action.payload };

    // get approval po id
    case types.PO_DETAIL_LOADING:
      return { ...state, detail_poIsLoading: action.payload };
    case types.PO_DETAIL_MESSAGE:
      return { ...state, detail_poMessage: action.payload };
    case types.PO_DETAIL_DATA:
      return { ...state, detail_poData: action.payload };

    // update approval po
    case types.PO_UPDATE_LOADING:
      return { ...state, update_poIsloading: action.payload };
    case types.PO_UPDATE_MESSAGE:
      return { ...state, update_poMessage: action.payload };

    // submit approval po
    case types.PO_SUBMIT_LOADING:
      return { ...state, submit_poIsloading: action.payload };
    case types.PO_SUBMIT_MESSAGE:
      return { ...state, submit_poMessage: action.payload };

    // get approval GRPO
    case types.GRPO_APPROVAL_LOADING:
      return { ...state, isLoadingGRPO: action.payload };
    case types.GRPO_APPROVAL_DATA:
      return { ...state, data_approvalGRPO: action.payload };
    case types.GRPO_APPROVAL_MESSAGE:
      return { ...state, messageGRPO: action.payload };

    // get approval grpo id
    case types.GRPO_DETAIL_LOADING:
      return { ...state, detail_grpoIsLoading: action.payload };
    case types.GRPO_DETAIL_MESSAGE:
      return { ...state, detail_grpoMessage: action.payload };
    case types.GRPO_DETAIL_DATA:
      return { ...state, detail_grpoData: action.payload };

    // update approval grpo
    case types.GRPO_UPDATE_LOADING:
      return { ...state, update_grpoIsloading: action.payload };
    case types.GRPO_UPDATE_MESSAGE:
      return { ...state, update_grpoMessage: action.payload };

    // submit approval grpo
    case types.GRPO_SUBMIT_LOADING:
      return { ...state, submit_grpoIsloading: action.payload };
    case types.GRPO_SUBMIT_MESSAGE:
      return { ...state, submit_grpoMessage: action.payload };

    // get approval general
    case types.GENERAL_APPROVAL_LOADING:
      return { ...state, isLoadingGeneral: action.payload };
    case types.GENERAL_APPROVAL_DATA:
      return { ...state, data_approvalGeneral: action.payload };
    case types.GENERAL_APPROVAL_MESSAGE:
      return { ...state, messageGeneral: action.payload };

    // get approval grpo id
    case types.GENERAL_DETAIL_LOADING:
      return { ...state, detail_GeneralIsLoading: action.payload };
    case types.GENERAL_DETAIL_MESSAGE:
      return { ...state, detail_GeneralMessage: action.payload };
    case types.GENERAL_DETAIL_DATA:
      return { ...state, detail_GeneralData: action.payload };

    // update approval grpo
    case types.GENERAL_UPDATE_LOADING:
      return { ...state, update_GeneralIsloading: action.payload };
    case types.GENERAL_UPDATE_MESSAGE:
      return { ...state, update_GeneralMessage: action.payload };

    // submit approval grpo
    case types.GENERAL_SUBMIT_LOADING:
      return { ...state, submit_GeneralIsloading: action.payload };
    case types.GENERAL_SUBMIT_MESSAGE:
      return { ...state, submit_GeneralMessage: action.payload };

    default:
      return state;
  }
};
