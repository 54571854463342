import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import BootstrapTooltip from '../../../../Tooltip';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

// Icons
import Pen from '../../../../../assets/Icons/pen.png';
import PenBlk from '../../../../../assets/Icons/penBlk.png';
import Trash from '../../../../../assets/Icons/trash.png';
import TrashRed from '../../../../../assets/Icons/trashRed.png';

const ChecklistData = ({ row, index, handleDeleteItem, handleEditItem }) => {
  const classes = useStyles();

  // state for hover icon
  const [isTrashHover, setIsTrashHover] = React.useState(false);
  const [isPenHover, setIsPenHover] = React.useState(false);

  return (
    <>
      <TableRow
        className={
          index % 2 == 0
            ? classes.tableContentData
            : classes.tableContentDataDua
        }
      >
        <TableCell>{row.id}</TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.description}</TableCell>
        {/* <TableCell>{row.mandatory ? 'Yes' : 'No'}</TableCell> */}
        <TableCell>
          <Box className={classes.wrapperAction}>
            <Box
              className={classes.Action}
              onMouseEnter={() => setIsPenHover(true)}
              onMouseLeave={() => setIsPenHover(false)}
              onClick={() => handleEditItem(row.id)}
            >
              <BootstrapTooltip title="Edit">
                <img src={isPenHover ? PenBlk : Pen} alt="img pen" />
              </BootstrapTooltip>
            </Box>
            <Box
              className={classes.Action}
              onMouseEnter={() => setIsTrashHover(true)}
              onMouseLeave={() => setIsTrashHover(false)}
              onClick={() => handleDeleteItem(row.id)}
            >
              <BootstrapTooltip title="Remove">
                <img src={isTrashHover ? TrashRed : Trash} alt="img pen" />
              </BootstrapTooltip>
            </Box>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

const useStyles = makeStyles({
  wrapperAction: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
  },
  Action: {
    cursor: 'pointer',
  },
  tableContentData: {
    backgroundColor: '#F8FAFB',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    border: 'none',
    height: 70,
    borderBottom: 'none',
    '&.MuiTableRow-root .MuiTableCell-root.MuiTableCell-body': {
      borderBottom: 'none',
      // paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 10,
      paddingBottom: 10,
    },
    '&.MuiTableRow-root .MuiTableCell-sizeSmall': {
      paddingRight: 0,
      borderBottom: 'none',
    },
  },
  tableContentDataDua: {
    backgroundColor: '#fff',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    border: 'none',
    height: 70,
    borderBottom: 'none',
    '&.MuiTableRow-root .MuiTableCell-sizeSmall': {
      paddingRight: 0,
      borderBottom: 'none',
    },
    '&.MuiTableRow-root .MuiTableCell-root.MuiTableCell-body': {
      borderBottom: 'none',
      // paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
});

export default ChecklistData;
