import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  withStyles,
  Box,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { dateFormater } from '../../utils/formatter';
import { useRouter } from '../../utils/useRouter';
import Pagination from '@material-ui/lab/Pagination';
import { useSelector, useDispatch } from 'react-redux';

import * as car from '../../redux/CAR/actions';
import * as actions_2 from '../../redux/Utility/actions';
import { SortingASC, SortingDESC } from "../../utils/SortFunc";

// component
import { Row } from './TableCustom/index';
import ModalSyncSAP from '../ModalSyncSAP';
import ModalConfirm from '../ModalComfirm';
import DatePicker from '../DatePicker';
import { TabelLoading, TableNoData } from '../Loading/TableLoading';
import { CircleLoadingSecondary } from '../Loading/CircleLoading';
import ModalDeleteItem from '../ModalDeleteItem';
import ModalSuccess from '../ModalSuccess';
import SortArrow from '../SortArrow';

// Icons
import PlusBtn from '../../assets/Icons/plusBtn.png';
import DateIcon from '../../assets/Icons/date.png';
import Export from '../../assets/Icons/export.png';

// Svg
import { DownArrow } from '../../assets/Icons/svg/DownArrow';

// generate fixed table
const StickyTableCell = withStyles((theme) => ({
  head: {
    right: 0,
  },
  body: {
    backgroundColor: '#ddd',
    minWidth: '50px',
    right: 0,
    position: 'sticky',
    zIndex: 99,
  },
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const CashReportTableData = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispacth = useDispatch();

  // Create format for table and pagination
  const [rowData, setRowData] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(1);
  const [fromRow, setFromRow] = useState(1);
  const [toRow, setToRow] = useState(1);
  const [TotalRow, setTotalRow] = useState(1);

  const [activityFilter, setActivityFilter] = useState('All Activity');
  const [status, setStatus] = useState('All_Status');
  const [departementFilter, setDepartementFilter] = useState(0);
  const [entityFilter, setEntityFilter] = useState(0);
  const [currencyFilter, setCurrentcyFilter] = useState('All Currency');
  const [categoryFilter, setCategoryFilter] = useState('All Category');

  const [rowSAP, setRowSAP] = useState([]);
  const [tempId, setTempId] = useState(null);

  const [date, setDate] = useState({
    start: null,
    end: null,
  });

  const [showModalSync, setModalSyncSAP] = useState(false);
  const [showModalConfirm, setModalConfirm] = useState(false);
  const [showModalSuccess, setModalSuccess] = useState(false);

  // Global Variable
  const { access_token } = useSelector((state) => state.auth);
  const { isLoading, car_data, car_deleteMessage } = useSelector(
    (state) => state.car
  );

  const { csv_loading } = useSelector((state) => state.utility);

  // handle Delete Item
  const [showModalDelete, setShowModalDetele] = useState(false);
  const [tempDeleteId, setTempDeleteId] = useState(0);

  const handleDelete = (id) => {
    setTempDeleteId(id);
    setShowModalDetele(true);
  };

  const reqDeleteitem = () => {
    // action for request delete item
    dispacth(car.deleteCAR(access_token, tempDeleteId));
    setShowModalDetele(false);
  };

  // end handle delete

  const handleChangeDepartement = (event) => {
    setDepartementFilter(event.target.value);
  };
  const handleChangeEntity = (event) => {
    setEntityFilter(event.target.value);
  };

  const handleChangeActivity = (event) => {
    setActivityFilter(event.target.value);
  };

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleChangeCurrency = (event) => {
    setCurrentcyFilter(event.target.value);
  };

  const handleChangeCategory = (event) => {
    setCategoryFilter(event.target.value);
  };

  const handleChangePagination = (event, value) => {
    setPagination(value);
  };

  const handleShowModal = () => {
    setModalSyncSAP(true);
  };
  const handleCloseModal = () => {
    setModalSyncSAP(false);
  };

  const handleShowConfirm = () => {
    setModalConfirm(true);
    setModalSyncSAP(false);
  };

  const handleCloseModalConfirm = () => {
    setModalConfirm(false);
  };

  const handleDetailData = (id) => {
    router.push(`/cash-report-detail/cash=${id}`);
  };

  const handleExportCsv = () => {
    let params = {
      doc_type: 'CA',
      date_start: dateFormater(date.start, 'YYYY-MM-DD'),
      date_end: dateFormater(date.end, 'YYYY-MM-DD'),
      status: status,
    };

    if (!date.start || !date.end) {
      delete params.date_start;
      delete params.date_end;
    }

    if (status === 'All_Status') delete params.status;

    console.log(params);

    dispacth(actions_2.exportCSV(access_token, params));
  };

  // function request
  const handleAddCash = () => {
    router.push('/cash-report-create');
  };

  const handleGETCARdata = () => {
    let params = {
      date_start: dateFormater(date.start, 'YYYY-MM-DD'),
      date_end: dateFormater(date.end, 'YYYY-MM-DD'),
      status: status,
      page: pagination,
    };

    if (!date.start || !date.end) {
      delete params.date_start;
      delete params.date_end;
    }

    if (status === 'All_Status') delete params.status;

    dispacth(car.getCAR(access_token, params));
  };

  useEffect(()=> {
    dispacth(car.getCAR(access_token, { page: pagination }));
  }, [pagination])

  useEffect(() => {
    handleGETCARdata();
  }, [date, status]);

  useEffect(() => {
    let tempArr = [];

    car_data?.cashAdvance?.data?.map((data, index) => {
      let tempData = {
        id: data?.id,
        noCA: data?.id,
        subject: data?.subject,
        periode: `${data?.start_date} - ${data?.end_date}`,
        amount: data?.amount,
        status: data?.status,
        date: data?.created_at,
      };
      tempArr.push(tempData);
    });

    let countPage = Math.ceil(car_data?.cashAdvance?.total / 7);
    setPage(countPage);
    setFromRow(car_data?.cashAdvance?.from);
    setToRow(car_data?.cashAdvance?.to);
    setTotalRow(car_data?.cashAdvance?.total);
    setRowData(tempArr);
  }, [car_data]);

  useEffect(() => {
    if (car_deleteMessage === 'Delete Cash Advance Success') {
      setModalSuccess(true)
      handleGETCARdata();
    }
    dispacth(car.setBlankDel());
  }, [car_deleteMessage]);
  return (
    <>
      <Grid container md={12} style={{ maxWidth: '100vw' }}>
        <Grid md={12} sm={12} xs={12}>
          <ModalConfirm
            open={showModalConfirm}
            setModalConfirm={setModalConfirm}
            handleCloseModalConfirm={handleCloseModalConfirm}
          />
          <ModalDeleteItem
            open={showModalDelete}
            close={() => setShowModalDetele(false)}
            id={tempDeleteId}
            onPress={reqDeleteitem}
          />
          <ModalSuccess
            open={showModalSuccess}
            onPress={() => setModalSuccess(false)}
          />
          <ModalSyncSAP
            open={showModalSync}
            setModalSyncSAP={setModalSyncSAP}
            handleCloseModal={handleCloseModal}
            handleShowConfirm={handleShowConfirm}
            data={rowSAP}
          />
          <div className={classes.titleContent}>
            <Grid container>
              <Grid sm={6}>
              <Typography
                className={classes.titleTable}
                variant="body1"
                component="p"
              >
                CAR - CASH ADVANCE REPORT
              </Typography>
              </Grid>
              <Grid sm={6} className={classes.gridAddButton}>
                <div className={classes.btnAsnyc} onClick={handleAddCash}>
                  <img src={PlusBtn} alt="image not found" />
                  <Typography
                    className={classes.btnText}
                    variant="body1"
                    component="p"
                  >
                    Add CA Report
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Box className={classes.filterContent}>
          <Box className={classes.dateContent}>
            <div className={classes.dateRangeIcon}>
              <img src={DateIcon} alt="image not found" />
            </div>
            <div className={classes.containerDate}>
              <DatePicker
                onDatePick={(pickedDay) => {
                  setDate(pickedDay);
                }}
                placeholder="Select Date Range"
              />
            </div>
          </Box>

          <Box className={classes.filterStatusContent}>
            <FormControl className={classes.formControl}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                IconComponent={() => <DownArrow />}
                value={status}
                onChange={handleChangeStatus}
              >
                <MenuItem value={'All_Status'}>All Status</MenuItem>
                <MenuItem value={'open'}>Open</MenuItem>
                <MenuItem value={'approval_1'}>Approval 1</MenuItem>
                <MenuItem value={'approval_2'}>Approval 2</MenuItem>
                <MenuItem value={'approval_3'}>Approval 3</MenuItem>
                <MenuItem value={'approved'}>Approved</MenuItem>
                <MenuItem value={'close'}>Close</MenuItem>
                <MenuItem value={'need_confirm'}>In-progress</MenuItem>
                <MenuItem value={'cancelled'}>Cancelled</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.filterBlankArea}>{/* black area */}</Box>
          <Box className={classes.exportContent}>
            <div className={classes.btnExportBlue} onClick={handleExportCsv}>
              {csv_loading ? (
                <Box className={classes.loadingExport}>
                  <CircleLoadingSecondary />
                </Box>
              ) : (
                <img src={Export} alt="image not found" />
              )}
              <Typography
                className={classes.btnText}
                variant="body1"
                component="p"
              >
                Export .csv
              </Typography>
            </div>
          </Box>
        </Box>

        <div style={{ width: '100%' }}>
          <Grid md={12}>
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHead}>
                        No.
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'noCA', setRowData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'noCA', setRowData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHead}>
                        Subject or Activity
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'subject', setRowData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'subject', setRowData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHead}>
                        Periode
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'periode', setRowData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'periode', setRowData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHead}>
                        Amount
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'amount', setRowData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'amount', setRowData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHead}>
                        Status
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'status', setRowData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'status', setRowData)
                          }
                        />
                      </Box>
                    </StyledTableCell>

                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHead}>
                        Date
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'date', setRowData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'date', setRowData)
                          }
                        />
                      </Box>
                    </StyledTableCell>

                    <StickyTableCell className={classes.head}>
                      <StyledTableCell className={classes.headTitle} numeric>
                        Action
                      </StyledTableCell>
                    </StickyTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? null : rowData.length > 0 ? (
                    rowData?.map((row, index) => (
                      <Row
                        key={index}
                        index={index}
                        row={row}
                        handleDetailData={handleDetailData}
                        handleDelete={handleDelete}
                      />
                    ))
                  ) : (
                    <TableNoData colspan={12} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {isLoading ? <TabelLoading /> : null}
          </Grid>
        </div>
        {rowData.length > 0 ? (
          <Grid
            xs={12}
            md={12}
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: 15 }}
          >
            <Grid xs={6} md={6}>
              <div className={classes.descTable}>
                <Typography
                  className={classes.descTableText}
                  variant="body1"
                  component="p"
                >
                  {`Showing ${fromRow} to ${toRow} of ${TotalRow} entries`}
                </Typography>
              </div>
            </Grid>
            <Grid xs={6} md={6}>
              <div className={classes.paginationContent}>
                <Pagination
                  className={classes.pagination}
                  count={page}
                  color={'standard'}
                  shape="rounded"
                  onChange={handleChangePagination}
                />
              </div>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  // cutome table
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  wrapperTableHead: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  head: {
    backgroundColor: '#fff',
    fontSize: 16,
    lineHeight: '30px',
    fontWeight: 'bold',
    color: '#000000',
    borderBottom: 'none',
    '&:nth-child(1)': {
      minWidth: 100,
      borderBottom: 'none',
    },
    '&:nth-child(2)': {
      minWidth: 180,
    },
    '&:nth-child(3)': {
      minWidth: 220,
    },
    '&:nth-child(4)': {
      minWidth: 150,
    },
    '&:nth-child(5)': {
      minWidth: 150,
    },
    '&:nth-child(6)': {
      minWidth: 180,
    },
    '&:nth-child(7)': {
      minWidth: 100,
    },
  },
  tableContainer: {
    // maxHeight: '400px',
    Width: '100%',
    '&.MuiTableContainer-root .MuiTableRow-root .MuiTableCell-root.MuiTableCell-head':
      {
        paddingLeft: 8,
      },
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
  },
  headTitle: {
    maxWidth: 100,
    backgroundColor: '#fff !important',
    color: '#000000',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '30px',
    borderBottom: 'none',
  },
  cell: {
    minWidth: '100px',
  },
  // end custom table
  titleContent: {
    paddingRight: 22,
    paddingLeft: 22,
    paddingTop: 27,
    paddingBottom: 27,
    backgroundColor: '#ffff',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  titleTable: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  addButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  gridAddButton : {
    display: 'flex',
    justifyContent: 'end',
  },
  btnAsnyc: {
    backgroundColor: '#1B2030',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '14px 20px',
    borderRadius: 8,
    cursor: 'pointer',
    width: '192px',
  },
  btnExport: {
    backgroundColor: '#127EEE',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '14px 20px',
    borderRadius: 8,
    cursor: 'pointer',
  },
  btnExportBlue: {
    backgroundColor: '#127EEE',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 14px',
    borderRadius: 8,
    cursor: 'pointer',
    maxHeight: 40,
    maxWidth: 154,
  },
  btnText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
    marginLeft: 8,
  },
  notifContent: {
    marginRight: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  notifText: {
    fontWeight: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#1B2030',
    marginLeft: 13,
  },

  iconAction: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 20,
  },
  Action: {
    cursor: 'pointer',
  },
  tableContent: {
    backgroundColor: '#fff',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderBottom: 'none !important',
    position: 'relative',
    maxWidth: 1440,
  },
  tableHead: {
    fontSize: 16,
    lineHeight: '30px',
    color: '#000000',
    fontWeight: 'bold',
    // position: 'relative',
    // maxWidth: 1440,
    overflowX: 'scroll',

    '&.MuiTableRow-root .MuiTableCell-root': {
      borderBottom: 'none',
      fontSize: 16,
      lineHeight: '30px',
      color: '#000000',
      fontWeight: 'bold',
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 16,
      paddingBottom: 16,
    },
    '&.MuiTableRow-root .MuiTableCell-head:nth-last-child(1)': {
      position: '-webkit-sticky',
      position: 'sticky',
      background: '#fff',
      left: 0,
      zIndex: 1,
    },
  },
  // Pagination styling
  descTable: {
    paddingTop: 11.5,
    paddingBottom: 11.5,
  },
  descTableText: {
    color: '#969BAB',
    fontSize: 16,
    lineHeight: '21px',
  },
  paginationContent: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pagination: {
    borderRadius: 0,
    '&.MuiPagination-root .MuiPagination-ul .MuiPaginationItem-root': {
      margin: 0,
    },
    borderRadius: 0,
    '&.MuiPagination-root .MuiPagination-ul .MuiPaginationItem-rounded': {
      borderRadius: 0,
    },
    '&.MuiPagination-root .MuiPagination-ul .MuiButtonBase-root': {
      backgroundColor: '#fff',
      color: '#969BAB',
    },
    '&.MuiPagination-root .MuiPagination-ul .MuiButtonBase-root.Mui-selected': {
      backgroundColor: '#1B2030',
      color: '#fff',
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-child(1)': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-child(1) button': {
      color: '#1B2030',
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-last-child(1)': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-last-child(1) button': {
      color: '#1B2030',
    },
  },
  wrapperMenuContent: {
    width: '100%',
  },
  // Filter Content
  filterContent: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: '#fff',
    borderTop: '1px solid #E7E7E8',
    // borderBottom: '1px solid #E7E7E8',
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
    // position: 'relative',
  },
  dateContent: {
    // width: '100%',
    maxWidth: 275,
    minWidth: 275,
    // backgroundColor: 'red',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingRight: 5,
  },
  dateRangeIcon: {
    width: 58,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E7E7E8',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  containerDate: {
    width: `100%`,
    maxWidth: `calc(100% - 58px)`,
  },
  // styling select
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 5,
    paddingBottom: 5,
    // maxWidth: 220,
    width: '100%',
    // minWidth: 160,
    maxHeight: 40,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      // marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#969BAB',
      paddingRight: 2,
      background: 'none',
    },
  },
  filterCategoryContent: {
    width: '100%',
    maxWidth: 190,
    minWidth: 185,
    paddingLeft: 5,
    paddingRight: 5,
  },
  filterStatusContent: {
    paddingLeft: 5,
    paddingRight: 5,
    width: '100%',
    maxWidth: 168,
    minWidth: 168,
  },
  exportContent: {
    marginRight: 24,
    paddingRight: 20,
    width: '100%',
    maxWidth: 164,
    minWidth: 154,
    position: 'absolute',
    right: 0,
    zIndex: 90,
    backgroundColor: '#fff',
  },
  filterBlankArea: {
    minWidth: 150,
  },
  // styling menu select
  boxDepartment: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  bodyDataSubText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    marginLeft: 4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  loadingExport: {
    width: 20,
    height: 20,
    maxHeight: 20,
    maxWidth: 20,
  },
  // Styling for Input vendor
  inputVendor: {
    width: '100%',
    height: 40,
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    paddingLeft: 15,
    '&.focus-visible': {
      border: '1px solid #E7E7E8',
      outline: 'none',
    },
  },
  filterVendorContent: {
    paddingLeft: 5,
    paddingRight: 5,
    width: '100%',
    maxWidth: 250,
    minWidth: 250,
    // position: 'relative',
  },
  iconDownArrow: {
    position: 'absolute',
    zIndex: 100,
    marginTop: -25,
    marginLeft: 200,
  },
  iconTrash: {
    position: 'absolute',
    zIndex: 100,
    marginTop: -30,
    marginLeft: 150,
    cursor: 'pointer',
  },
  exportContent: {
    marginRight: 24,
    paddingRight: 20,
    width: '100%',
    maxWidth: 164,
    minWidth: 154,
    position: 'absolute',
    right: 0,
    zIndex: 90,
    backgroundColor: '#fff',
  },
  filterBlankArea: {
    minWidth: 150,
  },
  contentDataVendor: {
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid #E7E7E8',
    minHeight: 202,
    maxHeight: 202,
    position: 'absolute',
    maxWidth: 245,
    borderRadius: 8,
    padding: 6,
    zIndex: 99999,
  },
  contentVendorSearch: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    width: '100%',
    border: '1px solid #E7E7E8',
    paddingRight: 15,
    borderRadius: 8,
  },
  searchVendor: {
    flex: 1,
    height: 40,
    border: 'none',
    outline: 'none',
    paddingLeft: 12,
  },
  listVendorName: {
    marginTop: 8,
    minHeight: `calc(202px - 56px)`,
    maxHeight: `calc(202px - 56px)`,
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.4em',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
      borderRadius: 4,
    },
  },
  TitleVendor: {
    height: 46,
    marginTop: 2,
    marginBottom: 2,
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
    cursor: 'pointer',
  },
}));

const rows = [
  {
    id: 1,
    noCA: '0001',
    subject: 'Laporan Agustus',
    periode: '12/07/2021 - 15/07/2021',
    amount: '120000',
    status: 'need_confirm',
    date: '12/07/2021 09:34',
  },
  {
    id: 2,
    noCA: '0002',
    subject: 'Laporan Agustus',
    periode: '12/07/2021 - 15/07/2021',
    amount: '120000',
    status: 'waiting',
    date: '12/07/2021 09:34',
  },
  {
    id: 3,
    noCA: '0003',
    subject: 'Laporan Agustus',
    periode: '12/07/2021 - 15/07/2021',
    amount: '120000',
    status: 'rejected',
    date: '12/07/2021 09:34',
  },
  {
    id: 4,
    noCA: '0004',
    subject: 'Laporan Agustus',
    periode: '12/07/2021 - 15/07/2021',
    amount: '120000',
    status: 'cancel',
    date: '12/07/2021 09:34',
  },
  {
    id: 5,
    noCA: '0005',
    subject: 'Laporan Agustus',
    periode: '12/07/2021 - 15/07/2021',
    amount: '120000',
    status: 'approve',
    date: '12/07/2021 09:34',
  },
];
export default CashReportTableData;
