import React, { useReducer } from 'react';
import { makeStyles, Box, Grid, Typography, FormControlLabel, FormGroup, Checkbox, Input, Chip, ListItemText } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useRouter } from '../../../../utils/useRouter';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../../redux/UserManagement/actions';
import * as actions_2 from '../../../../redux/Utility/actions';
import axios from 'axios';
import { END_POINT } from '../../../../config/EndPoint';
import request from 'src/utils/request';

// Components
import InputGroup from '../../../inputGroup';
import CustomizedSnackbars from '../../../PopupMessage';
import ModalSuccessCreateUser from '../../../ModalSuccessCreateUser';
import { CircleLoading } from '../../../Loading/CircleLoading';

// Svg
import { DownArrow } from '../../../../assets/Icons/svg/DownArrow';

// Icons
import DefaultAvatar from '../../../../assets/Images/defaultAvatar.png';
import { get } from 'lodash';

const schema = yup.object().shape({
  Username: yup.string().required(),
  Email: yup
    .string()
    .email('Must be a valid email')
    .required('Email is required'),
  Phone: yup.number('Phone must be number').required('field must be number'),
  // Password: yup.string().required(),
  // ConfirmPWD: yup.string().required(),
  NIK: yup.string().required('NIK field Required'),
  FullName: yup.string().required('Name field Required'),
});

export const InternalEdit = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispacth = useDispatch();
  const { id } = router.query;

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // handle input file
  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const initialPage = { pr: false, po: false, grpo: false, proforma_invoice: false, ap_invoice: false, ap_dp: false, op: false, ca: false }

  const [dataInput, setDataInput] = React.useState(null);
  const [showModal, setShowModal] = React.useState(true);
  const [status, setStatus] = React.useState(10);
  const [username, setUsername] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [pwd, setPwd] = React.useState('');
  const [selectFile, setSelectFile] = React.useState();
  const [previewImage, setPreviewImage] = React.useState();
  const [isFilePicked, setIsFilePicked] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [checkPO, setCheckPO] = React.useState(false);
  const [checkPR, setCheckPR] = React.useState(false);
  const [checkOP, setCheckOP] = React.useState(false);
  const [checkGRPO, setCheckGRPO] = React.useState(false);
  const [checkAP, setCheckAP] = React.useState(false);

  const [permPO, setPermPO] = React.useState(false);
  const [permPR, setPermPR] = React.useState(false);
  const [permOP, setPermOP] = React.useState(false);
  const [permGRPO, setPermGRPO] = React.useState(false);
  const [permPInvoice, setPermPInvoice] = React.useState(false);
  const [permAPDP, setPermAPDP] = React.useState(false);
  const [permAPInvoice, setPermAPInvoice] = React.useState(false);
  const [permCA, setPermCA] = React.useState(false);
  
  const watchAllFields = watch(); // when pass nothing as argument, you are watching everything

  const mapCheckBox = [
    {
      api_key: 'pr_sync',
      name: 'PR Sync',
      setControl: (val) => setCheckPR(val),
      value: () => checkPR,
    },
    {
      api_key: 'po_sync',
      name: 'PO Sync',
      setControl: (val) => setCheckPO(val),
      value: () => checkPO,
    },
    {
      api_key: 'grpo_sync',
      name: 'GRPO Sync',
      setControl: (val) => setCheckGRPO(val),
      value: () => checkGRPO,
    },
    {
      api_key: 'ap_sync',
      name: 'AP/DP and AP Invoice Sync',
      setControl: (val) => setCheckAP(val),
      value: () => checkAP,
    },
    {
      api_key: 'op_sync',
      name: 'OP Sync',
      setControl: (val) => setCheckOP(val),
      value: () => checkOP,
    }, 
  ]

  const mapPage = [
    {
      name: 'Purchase Requistion',
      api_key: 'pr',
      setControl: (val) => setPermPR(val),
      value: () => permPR,
    },
    {
      name: 'Purchase Order',
      api_key: 'po',
      setControl: (val) => setPermPO(val),
      value: () => permPO,
    },
    {
      name: 'Good Receipt (GRPO)',
      api_key: 'grpo',
      setControl: (val) => setPermGRPO(val),
      value: () => permGRPO,
    },
    {
      name: 'Proforma Invoice',
      api_key: 'proforma_invoice',
      setControl: (val) => setPermPInvoice(val),
      value: () => permPInvoice,
    },
    {
      name: 'A/P Invoice',
      api_key: 'ap_invoice',
      setControl: (val) => setPermAPInvoice(val),
      value: () => permAPInvoice,
    },
    {
      name: 'A/P DP',
      api_key: 'ap_dp',
      setControl: (val) => setPermAPDP(val),
      value: () => permAPDP,
    },
    {
      name: 'Outgoing Payment',
      api_key: 'op',
      setControl: (val) => setPermOP(val),
      value: () => permOP,
    },
    {
      name: 'Cash Advance Report',
      api_key: 'ca',
      setControl: (val) => setPermCA(val),
      value: () => permCA,
    },
  ]

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      // console.log(value)
      setDataInput(value)
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = async (data) => {
    // console.log(data);
    const { Username, Email, Password, Phone, FullName, ConfirmPWD, NIK } =
      data;
    if (Password && Password !== ConfirmPWD) {
      setType('error');
      setShow(true);
      setNotifText('Password and Confirm Password must be same');
    } else if (
      entityValue.length === 0 ||
      level === 0 ||
      departementFilter === 0 ||
      positionFilter === 0
    ) {
      setType('error');
      setShow(true);
      setNotifText('please select Department, Level, Entity or Postion');
    } else {
      let statusName = status === 10 ? 'active' : 'nonactive';
      setIsLoading(true);
      try {
        const sendData = new FormData();
        sendData.append('username', Username);
        sendData.append('email', Email);
        if (Password) {
          sendData.append('password', Password);
        }
        sendData.append('name', FullName);
        sendData.append('department_id', departementFilter);
        sendData.append('role', level);
        sendData.append('entity', entityValue.map(w => { 
          const objEntity = entity_data.find( s => s.name === w)
          if(objEntity) return objEntity.id
         }).join(';'));
        sendData.append('position_id', positionFilter);
        sendData.append('phone', Phone);
        sendData.append('nik', NIK);
        sendData.append('status', statusName);
        
        for(const check of mapCheckBox){
          sendData.append(check.api_key, check.value() ? 1 : 0)
        }

        if (selectFile) {
          sendData.append('file', selectFile);
        }

        const config = {
          headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${access_token}`,
          },
        };

        await axios
          .post(`${END_POINT}users/${id}/update`, sendData, config)
          .then((res) => {
            // console.log(res);
            if (res?.data?.message == 'Change User Success') {
              updatePermission()
            } else {
              return;
            }
          })
          .catch((err) => {
            console.log('---Error edit Internal User', err);
            setIsLoading(false);
          });
      } catch (error) {
        console.log('error edit internal user');
        setIsLoading(false);
      }
      // dispacth(actions.editInternalUser(access_token, data));
    }
  };

  const updatePermission = () => {

    const paramPerm = {}

    for(const val of mapPage){
      if(val.value()){
        paramPerm[val.api_key] = 1
      } else {
        paramPerm[val.api_key] = 0
      }
    }

    request({
      method: 'put',
      url: `user-page-permissions/${id}`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data: paramPerm,
    })
      .then((res) => {
        router.push('/user-management');
        console.log(res)
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setType('error');
        setShow(true);
        setNotifText(err.message);
      });
  }
  const getPermission = (userId) => {

    request({
      method: 'get',
      url: `user-page-permissions/${userId}`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
      .then((res) => {
        for(const r of mapPage){
          if(res.data[r.api_key]) {
            r.setControl(true)
          } else {
            r.setControl(false)
          }
        }
      })
      .catch((err) => {
        console.log(err)
      });
  }
  // Global Variable
  const { access_token } = useSelector((state) => state.auth);
  const { adminProfileData, adminProfileMessage } = useSelector(
    (state) => state.userManagement
  );
  const { department_data, entity_data, role_data, position_data } =
    useSelector((state) => state.utility);

  // handle message
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };

  const handleCancleBtn = () => {
    router.push('/user-management');
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // handle select
  const [departementFilter, setDepartementFilter] = React.useState(0);
  const [positionFilter, setPositionFilter] = React.useState(0);
  const [entityFilter, setEntityFilter] = React.useState(0);
  const [level, setLevel] = React.useState(0);
  const [entityValue, setEntityValues] = React.useState([]);

  const handleChangeDepartement = (event) => {
    setDepartementFilter(event.target.value);
  };
  const handleChangePosition = (event) => {
    setPositionFilter(event.target.value);
  };
  const handleChangeEntity = (event) => {
    setEntityFilter(event.target.value);
  };
  const handleChangeLevel = (event) => {
    setLevel(event.target.value);
  };

    //multi entity
    const handleChangeMultiple = (event) => {
    
      const { value } = event.target;
      
      setEntityValues(value);
    };

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setSelectFile(fileUploaded);
    setPreviewImage(fileUploaded);
    setIsFilePicked(true);
  };

  React.useEffect(() => {
    dispacth(actions_2.getDepartment(access_token));
    dispacth(actions_2.getEntity(access_token));
    dispacth(actions_2.getRoles(access_token));
    dispacth(actions_2.getPosition(access_token));
  }, []);

  React.useEffect(() => {
    dispacth(actions.getProfileAdmin(access_token, id));
    getPermission(id)
  }, []);

  React.useEffect(() => {
    //sync checkbox
    if(adminProfileData){
      for(const check of mapCheckBox){
        if(adminProfileData?.user[check.api_key]){
          check.setControl(true)
        } else {
          check.setControl(false)
        }
      }
    }

    setValue('Username', adminProfileData?.user?.username);
    setValue('Email', adminProfileData?.user?.email);
    setValue('Phone', adminProfileData?.user?.phone);
    setValue('FullName', adminProfileData?.user?.name);
    setValue('NIK', adminProfileData?.user?.nik);
    setDepartementFilter(
      adminProfileData?.user?.department?.id == null
        ? 0
        : adminProfileData?.user?.department?.id
    );
    // setEntityFilter(
    //   adminProfileData?.user?.entity[0]?.id == null
    //     ? 0
    //     : adminProfileData?.user?.entity[0]?.id
    // );
    setEntityValues(get(adminProfileData, ['user', 'entity'], []).map(w => w.name))
    setPositionFilter(adminProfileData?.user?.position?.id);

    if (adminProfileData?.user?.status === 'active') {
      setStatus(10);
    } else {
      setStatus(20);
    }

    if (adminProfileData?.user?.role[0] === 'manager') {
      setLevel(3);
    } else if (adminProfileData?.user?.role[0] === 'staff') {
      setLevel(4);
    } else if (adminProfileData?.user?.role[0] === 'superadmin') {
      setLevel(1);
    } else {
      setLevel(2);
    }
  }, [adminProfileData]);

  const handleCheckSync = (name, value) => {
    const findControl = mapCheckBox.find(e => e.api_key === name)
    if(findControl){
      findControl.setControl(value)
    }
  }

  return (
    <>
      <CustomizedSnackbars
        show={show}
        handleClose={handleClose}
        message={notifText}
        type={type}
      />
      <Grid container md={12} style={{ marginBottom: 120 }}>
        {/* <ModalSuccessCreateUser open={showModal} close={handleCloseModal} /> */}
        <Grid md={12} style={{ maxWidth: 1180 }}>
          {/* {console.log()} */}
          <Grid md={12} className={classes.wrapperHeader}>
            <Typography
              variant="body1"
              component="p"
              className={classes.headerText}
            >
              User Detail
            </Typography>
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid md={12} container>
              <Grid md={8}>
                <Grid md={12} className={classes.wrapperBody}>
                  <Grid md={12}>
                    <InputGroup
                      label="FullName"
                      placeholder="Enter Full Name"
                      register={register}
                      required
                      type="text"
                      // error={errors.Password ? 'error' : null}
                    />
                    <p className={classes.errorText}>
                      {errors.FullName?.message}
                    </p>
                  </Grid>
                  <Grid
                    md={12}
                    container
                    style={{ marginTop: 20, paddingBottom: 26 }}
                  >
                    <Grid md={6} style={{ paddingRight: 10 }}>
                      <InputGroup
                        label="NIK"
                        placeholder="Enter NIK"
                        register={register}
                        required
                        type="number"
                        // error={errors.Password ? 'error' : null}
                      />
                      <p className={classes.errorText}>{errors.NIK?.message}</p>
                    </Grid>
                    <Grid md={6} style={{ paddingLeft: 10 }}>
                      <InputGroup
                        label="Phone"
                        placeholder="Enter Phone"
                        register={register}
                        required
                        type="number"
                        // error={errors.Phone ? 'error' : null}
                      />
                      <p className={classes.errorText}>
                        {errors.Phone?.message}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid md={12} container>
                    <Grid md={6} style={{ paddingRight: 10 }}>
                      <InputGroup
                        label="Username"
                        placeholder="Enter Username"
                        register={register}
                        required
                        type="text"
                        error={errors.Username ? 'error' : null}
                      />
                      <p className={classes.errorText}>
                        {errors.Username?.message}
                      </p>
                    </Grid>
                    <Grid md={6} style={{ paddingLeft: 10 }}>
                      <InputGroup
                        label="Email"
                        placeholder="Enter Email"
                        register={register}
                        required
                        type="text"
                        disable={true}
                        error={errors.Username ? 'error' : null}
                      />
                      <p className={classes.errorText}>
                        {errors.Email?.message}
                      </p>
                    </Grid>
                  </Grid>

                  <Grid md={12} container style={{ marginTop: 20 }}>
                    <Grid md={6} style={{ paddingRight: 10 }}>
                      <InputGroup
                        label="Password"
                        placeholder="Enter Password"
                        register={register}
                        required
                        type="password"
                        error={errors.Password ? 'error' : null}
                      />
                      <p className={classes.errorText}>
                        {errors.Password?.message}
                      </p>
                    </Grid>
                    {dataInput?.Password ? (
                      <Grid md={6} style={{ paddingLeft: 10 }}>
                        <InputGroup
                          label="ConfirmPWD"
                          placeholder="write a Password"
                          register={register}
                          required
                          type="password"
                          error={errors.Password ? 'error' : null}
                        />
                        <p className={classes.errorText}>
                          {errors.ConfirmPWD?.message}
                        </p>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
                <Box className={classes.boderDivider} />
                <Grid md={12} className={classes.wrapperCategory} container>
                  <Grid md={6} style={{ paddingRight: 10 }}>
                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.titleLabel}
                    >
                      Position*
                    </Typography>
                    <FormControl className={classes.formControl}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={positionFilter}
                        onChange={handleChangePosition}
                        IconComponent={() => <DownArrow />}
                      >
                        <MenuItem value={0}>Select</MenuItem>
                        {position_data?.positions?.map((dep, index) => (
                          <MenuItem key={index} value={dep.id}>
                            <Box className={classes.boxDepartment}>
                              <Typography
                                className={classes.bodyDataText}
                                variant="body1"
                                component="p"
                              >
                                {dep.code}
                              </Typography>
                              <Typography
                                className={classes.bodyDataSubText}
                                variant="body1"
                                component="p"
                              >
                                {`- ${dep.name}`}
                              </Typography>
                            </Box>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid md={6} style={{ paddingLeft: 10 }}>
                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.titleLabel}
                    >
                      Department*
                    </Typography>
                    <FormControl className={classes.formControl}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={departementFilter}
                        onChange={handleChangeDepartement}
                        IconComponent={() => <DownArrow />}
                      >
                        <MenuItem value={0}>Select</MenuItem>
                        {department_data?.map((dep, index) => (
                          <MenuItem key={index} value={dep.id}>
                            <Box className={classes.boxDepartment}>
                              <Typography
                                className={classes.bodyDataText}
                                variant="body1"
                                component="p"
                              >
                                {dep.code}
                              </Typography>
                              <Typography
                                className={classes.bodyDataSubText}
                                variant="body1"
                                component="p"
                              >
                                {`- ${dep.name}`}
                              </Typography>
                            </Box>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid md={12} className={classes.wrapperCategory} container>
                  <Grid md={6} style={{ paddingRight: 10 }}>
                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.titleLabel}
                    >
                      Level*
                    </Typography>
                    <FormControl className={classes.formControl}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={level}
                        onChange={handleChangeLevel}
                        IconComponent={() => <DownArrow />}
                      >
                        <MenuItem value={0}>Select</MenuItem>
                        {role_data?.map((role, index) => (
                          <MenuItem key={index} value={role.id}>
                            <Typography
                              className={classes.bodyDataText}
                              variant="body1"
                              component="p"
                            >
                              {role.name}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid md={6} style={{ paddingLeft: 10 }}>
                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.titleLabel}
                    >
                      Entity*
                    </Typography>
                    {/* <FormControl className={classes.formControl}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={entityFilter}
                        onChange={handleChangeEntity}
                        IconComponent={() => <DownArrow />}
                      >
                        <MenuItem value={0}>Select Entity</MenuItem>
                        {entity_data?.map((ent, index) => (
                          <MenuItem key={index} value={ent.id}>
                            <Typography
                              className={classes.bodyDataText}
                              variant="body1"
                              component="p"
                            >
                              {ent.name}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}
                    <FormControl className={classes.formMultiSelectControl}>
                      <Select
                        labelId="demo-mutiple-chip-label"
                        id="demo-mutiple-chip"
                        multiple
                        value={entityValue}
                        onChange={handleChangeMultiple}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={(selected) => (
                          <div className={classes.chips}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} className={classes.chip} />
                            ))}
                          </div>
                        )}
                        // MenuProps={MenuProps}
                      >
                        {entity_data?.map((obj, idx) => (
                          <MenuItem key={idx} value={obj.name}>
                            <Checkbox checked={entityValue.indexOf(obj.name) > -1} />
                            <ListItemText primary={obj.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Box className={classes.borderCustom} />
                <Grid md={12} className={classes.wrapperStatusContent}>
                  <Grid container md={12}>
                    <Grid md={12}>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.titleLabel}
                      >
                        Page Permission
                      </Typography>
                    </Grid>
                    <FormGroup className={classes.checkBoxSync}>
                      {mapPage.map((val, i) => {
                        return (
                          <FormControlLabel
                            control={<Checkbox checked={val.value()} onChange={(e, value) => val.setControl(value)} name={val.api_key} />}
                            label={val.name}
                          />
                        )
                      })}
                      
                    </FormGroup>
                  </Grid>
                </Grid>
                <Box className={classes.borderCustom} />
                <Grid md={12} className={classes.wrapperStatusContent}>
                  <Grid container md={12}>
                    <Grid md={12}>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.titleLabel}
                      >
                        Sync Permission
                      </Typography>
                    </Grid>
                    <FormGroup className={classes.checkBoxSync}>
                      <FormControlLabel
                        control={<Checkbox checked={checkPR} onChange={(e, val) => handleCheckSync('pr_sync', val)} name="pr_sync" />}
                        label="PR Sync"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={checkPO} onChange={(e, val) => handleCheckSync('po_sync', val)} name="po_sync" />}
                        label="PO Sync"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={checkGRPO} onChange={(e, val) => handleCheckSync('grpo_sync', val)} name="grpo_sync" />}
                        label="GRPO Sync"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={checkAP} onChange={(e, val) => handleCheckSync('ap_sync', val)} name="ap_invoice_sync" />}
                        label="AP/DP and AP Invoice Sync"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={checkOP} onChange={(e, val) => handleCheckSync('op_sync', val)} name="op_sync" />}
                        label="OP Sync"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Box className={classes.borderCustom} />
                <Grid md={12} className={classes.wrapperStatusContent}>
                  <Grid md={12} container style={{ marginTop: 6 }}>
                    <Grid md={6} style={{ paddingRight: 10 }} container>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.titleLabel}
                      >
                        Status
                      </Typography>
                      <FormControl className={classes.formControl}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={status}
                          onChange={handleChangeStatus}
                          IconComponent={() => <DownArrow />}
                        >
                          <MenuItem value={10}>Active</MenuItem>
                          <MenuItem value={20}>Inactive</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid md={3} style={{ paddingLeft: 11 }}>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.titleLabel}
                      >
                        Is Logged In?
                      </Typography>
                      <Box>
                        <Typography
                          variant="body1"
                          component="p"
                          className={
                            adminProfileData?.user?.is_logged_in === 1
                              ? classes.descTextStatus
                              : classes.descTextStatusNo
                          }
                        >
                          {adminProfileData?.user?.is_logged_in === 1
                            ? 'Yes'
                            : 'No'}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid md={3}>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.titleLabel}
                      >
                        Last Login
                      </Typography>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.descText}
                      >
                        {adminProfileData?.user?.last_login === null
                          ? '-'
                          : adminProfileData?.user?.last_login}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid md={4} className={classes.wrapperProfile}>
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.profileText}
                >
                  Avatar
                </Typography>
                <Box className={classes.avatarContent}>
                  {isFilePicked && previewImage ? (
                    <img
                      src={URL.createObjectURL(previewImage)}
                      className="imageAvatar"
                      alt="image not found"
                      style={{ width: '100%', height: 'auto' }}
                    />
                  ) : adminProfileData?.user?.avatar_base == '' ? (
                    <>
                      <img
                        src={DefaultAvatar}
                        className="imageAvatar"
                        alt="image not found"
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </>
                  ) : (
                    <img
                      src={`data:image/jpeg;base64,${adminProfileData?.user?.avatar_base}`}
                      className="imageAvatar"
                      alt="image not found"
                      style={{ width: '100%', height: 'auto' }}
                    />
                  )}
                </Box>
                <Box className={classes.btnUploadContent}>
                  <Box onClick={handleClick} className={classes.btnUpload}>
                    Choose file
                  </Box>
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    id="img"
                    name="img"
                    accept="image/*"
                    onChange={handleChange}
                    style={{ display: 'none' }}
                  />
                  {isFilePicked ? (
                    <>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.fileText}
                      >
                        {selectFile.name}
                      </Typography>
                    </>
                  ) : (
                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.fileText}
                    >
                      No file Choosen
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
            
            <Grid md={12} className={classes.wrapperFotter}>
              <input
                className={
                  // dataInput?.Username &&
                  // dataInput?.Email &&
                  // dataInput?.Password &&
                  // dataInput?.ConfirmPWD &&
                  // dataInput?.Phone &&
                  // entityFilter !== 10 &&
                  // level !== 10 &&
                  // departementFilter !== 10
                  isLoading ? classes.submitBtnFalse :
                  classes.submitBtn
                  // : classes.submitBtnFalse
                }
                type="submit"
                value="Submit"
              />
              <Box className={classes.cancelBtn} onClick={handleCancleBtn}>
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.cancleText}
                >
                  Cancel
                </Typography>
              </Box>
              {isLoading ? <CircleLoading /> : null}
            </Grid>
          </form>
        </Grid>
        <Grid md={4}>{/* Blank Space */}</Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  checkBoxSync: {
    display: 'contents',
  },
  wrapperHeader: {
    padding: 20,
    backgroundColor: '#fff',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottom: '1px solid #E7E7E8',
  },
  headerText: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  wrapperBody: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingBottom: 20,
    paddingRight: 19,
    backgroundColor: '#fff',
  },
  errorText: {
    fontSize: 14,
    color: 'red',
  },
  wrapperFotter: {
    padding: 20,
    backgroundColor: '#fff',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderTop: '1px solid #E7E7E8',
  },
  submitBtn: {
    width: 193,
    height: 50,
    borderRadius: 8,
    backgroundColor: '#1B2030',
    color: '#fff',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  submitBtnFalse: {
    width: 193,
    height: 50,
    borderRadius: 8,
    backgroundColor: '#969BAB',
    color: '#fff',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  cancelBtn: {
    width: 193,
    height: 50,
    marginLeft: 2,
    cursor: 'pointer',
    borderRadius: 8,
    color: '#1B2030',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  titleLabel: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
    fontWeight: 'bold',
  },
  wrapperInputDisabel: {
    width: '100%',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 14.5,
    paddingBottom: 14.5,
    backgroundColor: '#EBEFF0',
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    maxHeight: 50,
    marginTop: 12,
  },
  textNumberID: {
    width: '100%',
  },
  boderDivider: {
    marginLeft: 20,
    marginRight: 20,
    borderTop: '1px solid #E7E7E8',
  },
  wrapperCategory: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 26,
    paddingBottom: 26,
    backgroundColor: '#fff',
  },
  // Select styling
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 10,
    paddingRight: 5,
    paddingTop: 10,
    height: 50,
    marginTop: 12,
    paddingBottom: 5,
    // maxWidth: 220,
    width: '100%',
    // minWidth: 180,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#1B2030',
      paddingRight: 4,
    },
  },
  // Multi Select styling
  formMultiSelectControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 10,
    paddingRight: 5,
    paddingTop: 3,
    height: 50,
    marginTop: 12,
    paddingBottom: 5,
    // maxWidth: 220,
    width: '100%',
    // minWidth: 180,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      marginTop: 0,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#1B2030',
      paddingRight: 4,
    },
  },
  wrapperStatusContent: {
    padding: 20,
    backgroundColor: '#fff',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  borderCustom: {
    marginLeft: 20,
    marginRight: 20,
    borderBottom: '1px solid #E7E7E8',
  },
  descText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
    alignSelf: 'center',
    marginTop: 26,
  },
  descTextStatus: {
    fontSize: 12,
    lineHeight: '26px',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: '#27AE60',
    borderRadius: 100,
    width: 47,
    height: 26,
    textAlign: 'center',
    marginTop: 24,
  },
  descTextStatusNo: {
    fontSize: 12,
    lineHeight: '26px',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: '#FD453A',
    borderRadius: 100,
    width: 47,
    height: 26,
    textAlign: 'center',
    marginTop: 24,
  },
  wrapperProfile: {
    backgroundColor: '#fff',
    // borderBottom: '1px solid #E7E7E8',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 40,
  },
  profileText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
    fontWeight: 'bold',
  },
  avatarContent: {
    marginTop: 12,
    width: 150,
    height: 150,
    borderRadius: 8,
  },
  btnUploadContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 20,
  },
  btnUpload: {
    backgroundColor: '#127EEE',
    color: '#fff',
    width: 102,
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  fileText: {
    marginLeft: 20,
    fontSize: 14,
    lineHeight: '26px',
    color: '#1B2030',
  },
  textCurrent: {
    marginTop: 20,
    marginBottom: 11.5,
    fontSize: 14,
    lineHeight: '26px',
    color: '#1B2030',
  },
  // styling menu select
  boxDepartment: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  bodyDataSubText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    marginLeft: 4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  itemHide: {
    display: 'none',
  },
});

export default InternalEdit;
