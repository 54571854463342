import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';

// icons
import EyeBlue from '../../assets/Icons/eyeBlue.png';

// Svg
import { SortArrow } from '../../assets/Icons/svg/SortArrow';

const DashboardTableData = ({ index }) => {
  const classes = useStyles();

  const _renderTitle = (index) => {
    if (index === 1) {
      return 'PR';
    } else if (index === 2) {
      return 'PO';
    } else if (index === 3) {
      return 'GRPO';
    } else {
      return 'Invoice';
    }
  };

  return (
    <>
      <Grid container>
        <Grid xs={12} md={12}>
          <div className={classes.titleTableContent}>
            <Typography
              className={classes.titleTable}
              variant="body1"
              component="p"
            >
              {`${_renderTitle(index)} Last Submitted`}
            </Typography>
            <div className={classes.linkTable}>
              <img
                // className={classes.bellImage}
                src={EyeBlue}
                alt="image not found"
              />
              <a className={classes.linkTableText} href="#">
                View All
              </a>
            </div>
          </div>
          {/* <div style={{ width: '100%' }}> */}
          <DataGrid
            rows={rows}
            columns={columns}
            // disableColumnFilter={true}
            disableColumnMenu={true}
            pageSize={10}
            className={classes.tableContent}
            disableSelectionOnClick
            hideFooter={true}
            components={{
              ColumnSortedAscendingIcon: () => <SortArrow />,
              ColumnSortedDescendingIcon: () => <SortArrow />,
            }}
            autoHeight={true}
            getCellClassName={(params) => {
              if (params.field === 'status') {
                if (params.value === 'Open') {
                  return ' div open';
                } else if (params.value === 'Closed') {
                  return ' div closed';
                } else if (
                  params.value === 'Cancel' ||
                  params.value === 'Reject'
                ) {
                  return ' div reject';
                } else {
                  return ' div approval';
                }

                // console.log(params.value);
              }
            }}
          />
          {/* </div> */}
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  titleTableContent: {
    paddingRight: 22,
    paddingLeft: 22,
    paddingTop: 27,
    paddingBottom: 27,
    backgroundColor: '#ffff',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleTable: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  tableContent: {
    backgroundColor: '#fff',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    display: 'block',
    overflowX: 'auto',
    border: 'none',
    '&.MuiDataGrid-root .MuiDataGrid-cell': {
      borderBottom: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-row:nth-child(odd)': {
      backgroundColor: '#F8FAFB',
    },
    '&.MuiDataGrid-root .MuiDataGrid-row:nth-child(even)': {
      backgroundColor: '#FFFFFF',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#000000 !important',
      fontWeight: 'bold',
    },
    '&.MuiDataGrid-root .MuiDataGrid-iconButtonContainer .MuiButtonBase-root': {
      // backgroundColor: 'red',
      width: 20,
    },
    '&.MuiDataGrid-root .MuiDataGrid-iconButtonContainer .MuiButtonBase-root .MuiSvgIcon-root':
      {
        paddingTop: 5,
        paddingLeft: 5,
      },
    '&.MuiDataGrid-root .MuiDataGrid-cell--textLeft': {
      // fontSize: 16,
      color: '#000000',
      fontWeight: 'Normal',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnsContainer': {
      borderBottom: 'none',
      borderTop: '1px solid #E7E7E8',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .open div p': {
      backgroundColor: '#127EEE',
      color: '#fff',
      paddingLeft: 12,
      paddingRight: 12,
      borderRadius: 100,
    },
    '& .closed div p': {
      backgroundColor: '#969BAB',
      color: '#fff',
      paddingLeft: 12,
      paddingRight: 12,
      borderRadius: 100,
    },
    '& .approval div p': {
      backgroundColor: '#27AE60',
      color: '#fff',
      paddingLeft: 12,
      paddingRight: 12,
      borderRadius: 100,
      verticalAlign: 'top',
    },
    '& .reject div p': {
      backgroundColor: '#F12B2C',
      color: '#fff',
      paddingLeft: 12,
      paddingRight: 12,
      borderRadius: 100,
    },
  },
  linkTable: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  linkTableText: {
    textDecorationLine: 'none',
    color: '#127EEE',
    fontSize: 14,
    lineHeight: '26px',
    fontWeight: 'bold',
    marginLeft: 10,
  },
});

const columns = [
  { field: 'id', headerName: 'No', width: 75 },
  {
    field: 'name',
    headerName: 'Purpose of Request',
    minWidth: 200,
    flex: 2,
  },
  {
    field: 'department',
    headerName: 'Department',
    minWidth: 90,
    flex: 1,
  },
  {
    field: 'entity',
    headerName: 'Entity',
    width: 100,
  },
  {
    field: 'activity',
    headerName: 'Activity',
    width: 130,
  },
  {
    field: 'total',
    headerName: 'Total',
    width: 90,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 115,
    renderCell: (params) => (
      <div>
        <Typography className={params.value}>{params.value}</Typography>
      </div>
    ),
  },

  {
    field: 'date',
    headerName: 'Date',
    width: 155,
  },
];

const rows = [
  {
    id: 12321,
    name: 'Dolor at amet duis vestibulum',
    department: 'FINC',
    entity: 'DKTI',
    activity: 'TV Commercial',
    status: 'Open',
    total: '2930010',
    date: '12/07/2021 09:34',
  },
  {
    id: 12322,
    name: 'Dolor at amet duis vestibulum',
    department: 'FINC',
    entity: 'DKTI',
    activity: 'TV Commercial',
    status: 'Cancel',
    total: '2930010',
    date: '12/07/2021 09:34',
  },
  {
    id: 12323,
    name: 'Dolor at amet duis vestibulum',
    department: 'FINC',
    entity: 'DKTI',
    activity: 'TV Commercial',
    status: 'Approval 2',
    total: '2930010',
    date: '12/07/2021 09:34',
  },
  {
    id: 12324,
    name: 'Dolor at amet duis vestibulum',
    department: 'FINC',
    entity: 'DKTI',
    activity: 'TV Commercial',
    status: 'Approved',
    total: '2930010',
    date: '12/07/2021 09:34',
  },
  {
    id: 12325,
    name: 'Dolor at amet duis vestibulum',
    department: 'FINC',
    entity: 'DKTI',
    activity: 'TV Commercial',
    status: 'Closed',
    total: '2930010',
    date: '12/07/2021 09:34',
  },
];

export default DashboardTableData;
