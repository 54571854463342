import React from 'react';
import { makeStyles, Grid, Box, Typography } from '@material-ui/core';
import { useRouter } from '../../utils/useRouter';

// icons
import PlusBtn from '../../assets/Icons/plusBtn.png';

// Component
import EntityListing from './Components/Listing/EntityListing';
import DepartmentListing from './Components/Listing/DepartmentListing';
import PositionListing from './Components/Listing/PositionListing';
import LevelListing from './Components/Listing/LevelListing';

const Organize = () => {
  const classes = useStyles();
  const router = useRouter();

  const [index, setIndex] = React.useState(1);
  const [title, setTitle] = React.useState('Entity');

  React.useEffect(() => {
    if (index === 1) {
      setTitle('Entity');
    } else if (index === 2) {
      setTitle('Department');
    } else if (index === 3) {
      setTitle('Position');
    } else if (index === 4) {
      setTitle('Level');
    }
  }, [index]);

  const _renderListData = (index) => {
    if (index === 1) {
      return <EntityListing />;
    } else if (index === 2) {
      return <DepartmentListing />;
    } else if (index === 3) {
      return <PositionListing />;
    } else if (index === 4) {
      return <LevelListing />;
    }
  };

  const handleAddData = (index) => {
    if (index === 1) {
      router.push('/organize/create-entity');
    } else if (index === 2) {
      router.push('/organize/create-department');
    } else if (index === 3) {
      router.push('/organize/create-position');
    } else if (index === 4) {
      router.push('/organize/create-level');
    }
  };

  return (
    <>
      <Grid md={12} className={classes.wrapperContainer}>
        <Grid md={12} className={classes.wrapperMainTitle}>
          <Typography className={classes.mainTitle}>Organization</Typography>
        </Grid>
        <Grid md={12} className={classes.wrapperTab} container>
          <Grid md={10} className={classes.wrapperTabContent}>
            <Box
              className={
                index === 1 ? classes.tabColomActive : classes.tabColom
              }
              onClick={() => setIndex(1)}
            >
              <Typography
                className={
                  index === 1 ? classes.tabTextActive : classes.tabText
                }
              >
                Entity
              </Typography>
            </Box>
            <Box
              className={
                index === 2 ? classes.tabColomActive : classes.tabColom
              }
              onClick={() => setIndex(2)}
            >
              <Typography
                className={
                  index === 2 ? classes.tabTextActive : classes.tabText
                }
              >
                Department
              </Typography>
            </Box>
            <Box
              className={
                index === 3 ? classes.tabColomActive : classes.tabColom
              }
              onClick={() => setIndex(3)}
            >
              <Typography
                className={
                  index === 3 ? classes.tabTextActive : classes.tabText
                }
              >
                Position
              </Typography>
            </Box>
            <Box
              className={
                index === 4 ? classes.tabColomActive : classes.tabColom
              }
              onClick={() => setIndex(4)}
            >
              <Typography
                className={
                  index === 4 ? classes.tabTextActive : classes.tabText
                }
              >
                Level
              </Typography>
            </Box>
          </Grid>
          <Grid md={2} className={classes.wrapperBtnContent}>
            <Box
              className={classes.btnAdd}
              onClick={() => handleAddData(index)}
            >
              <img src={PlusBtn} alt="image plus" />
              <Typography
                className={classes.textbtn}
              >{`Add ${title}`}</Typography>
            </Box>
          </Grid>
        </Grid>
        {_renderListData(index)}
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapperContainer: {
    borderRadius: 8,
    backgroundColor: '#fff',
  },
  wrapperMainTitle: {
    padding: 20,
  },
  mainTitle: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  wrapperTab: {
    borderTop: '1px solid #E7E7E8',
    borderBottom: '1px solid #E7E7E8',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  wrapperTabContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  tabColomActive: {
    padding: 20,
    borderBottom: '3px solid #127EEE',
    cursor: 'pointer',
    height: 64,
  },
  tabColom: {
    padding: 20,
    cursor: 'pointer',
    height: 64,
  },
  tabTextActive: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#127EEE',
  },
  tabText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  wrapperBtnContent: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  btnAdd: {
    minWidth: 146,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#1B2030',
    borderRadius: 8,
    marginRight: 20,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 9.5,
    paddingBottom: 9.5,
    cursor: 'pointer',
  },
  textbtn: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
    marginLeft: 24,
  },
}));
export default Organize;
