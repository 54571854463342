import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Components
import Layout from '../../components/Layout';
import SigninForm from '../../components/SigninForm';
import Footer from '../../components/Footer';
import FooterLine from '../../components/FooterLine';

// image
import Logo from '../../assets/Images/logo.png';
import LandingImage from '../../assets/Images/landingImage.png';
import BaseImage from '../../assets/Images/bgBase.png';

export default function Signin(props) {
  // Just Example
  const classes = useStyles(props);

  return (
    <>
      <Layout>
        {/* <Container maxWidth="sm"> */}
        <Grid md={12} container className={classes.root}>
          <Grid lg={8} className={classes.sectionImage}>
            <Box className={classes.imageLanding}>
              <img src={LandingImage} />
            </Box>
          </Grid>
          <Grid
            lg={4}
            md={12}
            xs={12}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.wrapperContent}
          >
            <img
              className={classes.imageLogo}
              src={Logo}
              alt="image not found"
            />
            <SigninForm />
            <Footer />
          </Grid>
        </Grid>
        {/* </Container> */}
        <FooterLine />
      </Layout>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
  },
  wrapperContent: {
    paddingTop: 34.9,
  },
  imageLogo: {
    width: 136,
    height: 107,
  },
  imageLanding: {
    paddingTop: 197,
    // height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    position: 'relative',
    zIndex: 10,
  },
  sectionImage: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
      // backgroundImage: 'none',
    },
    // backgroundColor: 'red',
    backgroundImage: `url(${BaseImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    // height: '100%',
  },
}));
