import * as type from './types';
import request from '../../utils/request';
import _ from 'lodash';

export const getDepartment = (token) => (dispatch) => {
  dispatch({ type: type.SET_DEPARTMENT_LOADING, payload: true });

  request({
    method: 'get',
    url: 'departments',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      const data = res?.data?.departments;
      // console.log(res?.data);
      dispatch({ type: type.SET_DEPARTMENT_DATA, payload: data });
      dispatch({ type: type.SET_DEPARTMENT_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get department', err);
      dispatch({ type: type.SET_DEPARTMENT_LOADING, payload: false });
    });
};

export const getDepartmentById = (token, params) => (dispatch) => {
  dispatch({ type: type.SET_DEPARTMENT_ID_LOADING, payload: true });

  request({
    method: 'get',
    url: `departments/byentity`,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      const data = res?.data?.departments;
      // console.log(res?.data);
      dispatch({ type: type.SET_DEPARTMENT_ID_DATA, payload: data });
      dispatch({ type: type.SET_DEPARTMENT_ID_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get department by id', err);
      dispatch({ type: type.SET_DEPARTMENT_ID_LOADING, payload: false });
    });
};

export const setNullDepartmentById = () => (dispatch) => {
  dispatch({ type: type.SET_DEPARTMENT_ID_DATA, payload: null });
};

export const getEntity = (token) => (dispatch) => {
  dispatch({ type: type.SET_ENTITY_LOADING, payload: true });

  request({
    method: 'get',
    url: 'entities',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      const data = res?.data?.entities;
      // console.log(res?.data);
      dispatch({ type: type.SET_ENTITY_DATA, payload: data });
      dispatch({ type: type.SET_ENTITY_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get entity', err);
      dispatch({ type: type.SET_ENTITY_LOADING, payload: false });
    });
};

export const getRoles = (token) => (dispatch) => {
  dispatch({ type: type.SET_ROLE_LOADING, payload: true });

  request({
    method: 'get',
    url: 'roles/get-roles',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      const data = res?.data?.roles;
      // console.log(res?.data);
      dispatch({ type: type.SET_ROLE_DATA, payload: data });
      dispatch({ type: type.SET_ROLE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get role', err);
      dispatch({ type: type.SET_ROLE_LOADING, payload: false });
    });
};

export const getPosition = (token) => (dispatch) => {
  dispatch({ type: type.SET_POSITION_LOADING, payload: true });

  request({
    method: 'get',
    url: 'positions',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      const data = res?.data;
      // console.log(res?.data);
      dispatch({ type: type.SET_POSITION_DATA, payload: data });
      dispatch({ type: type.SET_POSITION_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get position', err);
      dispatch({ type: type.SET_POSITION_LOADING, payload: false });
    });
};

export const getActivity = (token) => (dispatch) => {
  dispatch({ type: type.SET_ACTIVITY_LOADING, payload: true });

  request({
    method: 'get',
    url: 'master-activity',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      const data = res?.data;
      // console.log(res?.data);
      dispatch({ type: type.SET_ACTIVITY_DATA, payload: data });
      dispatch({ type: type.SET_ACTIVITY_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get activity', err);
      dispatch({ type: type.SET_ACTIVITY_LOADING, payload: false });
    });
};

export const getCategory = (token) => (dispatch) => {
  dispatch({ type: type.SET_CATEGORY_LOADING, payload: true });

  request({
    method: 'get',
    url: 'purchase-type',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      const data = res?.data;
      // console.log(res?.data);
      dispatch({ type: type.SET_CATEGORY_DATA, payload: data });
      dispatch({ type: type.SET_CATEGORY_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get category', err);
      dispatch({ type: type.SET_CATEGORY_LOADING, payload: false });
    });
};

export const getQrCode = (token, data) => (dispatch) => {
  dispatch({ type: type.GET_QRCODE_LOADING, payload: true });

  request({
    method: 'post',
    url: `generate-qr`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.GET_QRCODE, payload: res });
      dispatch({ type: type.GET_QRCODE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get Qrcode', err);
      dispatch({ type: type.GET_QRCODE_LOADING, payload: false });
    });
};

// // error send data to API change into text inside page
// export const uploadFile = (token, sendData) => (dispatch) => {
//   dispatch({ type: type.FILE_UPLOAD_LOADING, payload: true });

//   request({
//     method: 'post',
//     url: 'files',
//     sendData,
//     headers: {
//       'Content-type': 'multipart/form-data',
//       Authorization: `Bearer ${token}`,
//     },
//     // options,
//   })
//     .then((res) => {
//       console.log('upload File');
//       console.log(res);
//       dispatch({ type: type.FILE_UPLOAD_MESSAGE, payload: res?.message });
//       dispatch({ type: type.FILE_UPLOAD_LOADING, payload: false });
//     })
//     .catch((err) => {
//       console.log('---Error file upload', err);
//       dispatch({ type: type.FILE_UPLOAD_LOADING, payload: false });
//     });
// };

export const readFile = (token, params) => (dispatch) => {
  dispatch({ type: type.FILE_READ_LOADING, payload: true });

  request({
    method: 'get',
    url: 'files',
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    // options,
  })
    .then((res) => {
      // console.log('red File');
      // console.log(res);
      dispatch({ type: type.FILE_READ_DATA, payload: res?.data?.files });
      dispatch({ type: type.FILE_READ_MESSAGE, payload: res?.message });
      dispatch({ type: type.FILE_READ_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error file read', err);
      dispatch({ type: type.FILE_READ_LOADING, payload: false });
    });
};

export const NullFile = () => (dispatch) => {
  dispatch({ type: type.FILE_READ_DATA, payload: null });
};

export const readFileSecond = (token, params) => (dispatch) => {
  dispatch({ type: type.FILE_READ_LOADING_SECOND, payload: true });

  request({
    method: 'get',
    url: 'files',
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    // options,
  })
    .then((res) => {
      console.log('red File Second');
      // console.log(res);
      dispatch({ type: type.FILE_READ_DATA_SECOND, payload: res?.data?.files });
      dispatch({ type: type.FILE_READ_MESSAGE_SECOND, payload: res?.message });
      dispatch({ type: type.FILE_READ_LOADING_SECOND, payload: false });
    })
    .catch((err) => {
      console.log('---Error file read', err);
      dispatch({ type: type.FILE_READ_LOADING_SECOND, payload: false });
    });
};

export const readFileThird = (token, params) => (dispatch) => {
  dispatch({ type: type.FILE_READ_LOADING_THIRD, payload: true });

  request({
    method: 'get',
    url: 'files',
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    // options,
  })
    .then((res) => {
      console.log('red File Third');
      // console.log(res.data);
      dispatch({ type: type.FILE_READ_DATA_THIRD, payload: res?.data?.files });
      dispatch({ type: type.FILE_READ_MESSAGE_THIRD, payload: res?.message });
      dispatch({ type: type.FILE_READ_LOADING_THIRD, payload: false });
    })
    .catch((err) => {
      console.log('---Error file read', err);
      dispatch({ type: type.FILE_READ_LOADING_THIRD, payload: false });
    });
};

export const NullFileSecond = () => (dispatch) => {
  dispatch({ type: type.LOADING_CSV, payload: null });
};

// export csv
export const exportCSV = (token, params) => (dispatch) => {
  dispatch({ type: type.LOADING_CSV, payload: true });

  request({
    method: 'post',
    url: 'export',
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    // options,
  })
    .then((res) => {
      // console.log('export csv');
      // console.log(res);
      var uri = 'data:text/csv;charset=utf-8,' + res;

      var downloadLink = document.createElement('a');
      downloadLink.href = uri;
      downloadLink.download = `${params.doc_type}.csv`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      dispatch({ type: type.DATA_CSV, payload: res?.data });
      dispatch({ type: type.MESSAGE_CSV, payload: res?.message });
      dispatch({ type: type.LOADING_CSV, payload: false });
    })
    .catch((err) => {
      console.log('---Error file read', err);
      dispatch({ type: type.LOADING_CSV, payload: false });
    });
};

export const setNullExporCSV = () => (dispatch) => {
  dispatch({ type: type.DATA_CSV, payload: null });
};

// GET Group code
export const getDistributorGroupCode = (token) => (dispatch) => {
  dispatch({ type: type.GROUP_CODE_LOADING, payload: true });

  request({
    method: 'get',
    url: 'distributor-group-code',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      const data = res?.data;
      // console.log(res?.data);
      dispatch({ type: type.GROUP_CODE_DATA, payload: data });
      dispatch({ type: type.GROUP_CODE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get group code', err);
      dispatch({ type: type.GROUP_CODE_LOADING, payload: false });
    });
};

// GET Supplier Group code
export const getSupplierGroupCode = (token) => (dispatch) => {
  dispatch({ type: type.SUPPLIER_GROUP_CODE_LOADING, payload: true });

  request({
    method: 'get',
    url: 'supplier-group-code',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      const data = res?.data;
      // console.log(res?.data);
      dispatch({ type: type.SUPPLIER_GROUP_CODE_DATA, payload: data });
      dispatch({ type: type.SUPPLIER_GROUP_CODE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get supplier group code', err);
      dispatch({ type: type.SUPPLIER_GROUP_CODE_LOADING, payload: false });
    });
};

// GET Supplier Group code
export const getSupplierDetail = (token, id) => (dispatch) => {
  dispatch({ type: type.SUPPLIER_GET_ID_LOADING, payload: true });

  request({
    method: 'get',
    url: `suppliers/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      const data = res?.data;
      // console.log(res?.data);
      dispatch({ type: type.SUPPLIER_GET_ID_DATA, payload: data });
      dispatch({ type: type.SUPPLIER_GET_ID_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get supplier detail', err);
      dispatch({ type: type.SUPPLIER_GET_ID_LOADING, payload: false });
    });
};

// GET Bank data
export const getBank = (token) => (dispatch) => {
  dispatch({ type: type.BANK_LOADING, payload: true });

  request({
    method: 'get',
    url: 'bank',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      const data = res?.data?.bank?.data;
      // console.log(res?.data);
      dispatch({ type: type.BANK_DATA, payload: data });
      dispatch({ type: type.BANK_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get bank data', err);
      dispatch({ type: type.BANK_LOADING, payload: false });
    });
};

// delete file
export const delFile = (token, id) => (dispatch) => {
  dispatch({ type: type.FILE_DELETE_LOADING, payload: true });

  request({
    method: 'post',
    url: `files/${id}/delete`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.FILE_DELETE_MESSAGE, payload: res?.message });
      dispatch({ type: type.FILE_DELETE_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error delete file', err);
      dispatch({ type: type.FILE_DELETE_LOADING, payload: false });
      dispatch({ type: type.FILE_DELETE_MESSAGE, payload: err?.data?.message });
    });
};

export const setBlankDelFile = () => (dispatch) => {
  dispatch({ type: type.FILE_DELETE_MESSAGE, payload: '' });
};

// get payment info by vendor code
export const paymentInfoCode = (token, params) => (dispatch) => {
  dispatch({ type: type.GET_PAYMENT_CODE_LOADING, payload: true });

  request({
    method: 'get',
    url: `payment-info/by/code`,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: type.GET_PAYMENT_CODE_DATA, payload: res?.data });
      dispatch({ type: type.GET_PAYMENT_CODE_LOADING, payload: false });
    })
    .catch((err) => {
      // console.log('---Error get paymet code file', err);
      dispatch({ type: type.GET_PAYMENT_CODE_DATA, payload: null });
      dispatch({ type: type.GET_PAYMENT_CODE_LOADING, payload: false });
    });
};

// get all bank for all level user
export const getAllBank = (token) => (dispatch) => {
  dispatch({ type: type.GET_ALL_BANK_LOADING, payload: true });

  request({
    method: 'get',
    url: `bank/get/all`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      const data = res?.data?.bank;
      dispatch({ type: type.GET_ALL_BANK_DATA, payload: data });
      dispatch({ type: type.GET_ALL_BANK_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('---Error get all bank', err);
      dispatch({ type: type.GET_ALL_BANK_LOADING, payload: false });
    });
};
