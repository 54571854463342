import React from 'react';
import { Grid, makeStyles, Box, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useRouter } from '../../../../utils/useRouter';
import { useSelector, useDispatch } from 'react-redux';
import * as settingAction from '../../../../redux/Settings/actions';

// Components
import InputGroup from '../../../inputGroup';
import ModalConfirm from '../ModalComfirm';
import ModalSuccess from '../../../ModalSuccess';
import CustomizedSnackbars from '../../../PopupMessage';

// Svg
import { DownArrow } from '../../../../assets/Icons/svg/DownArrow';

const schema = yup.object().shape({
  DepartmentName: yup.string().required(),
  Code: yup.string().required(),
});

const DepartmentEdit = () => {
  const classes = useStyles();
  const router = useRouter();
  const { id } = router.query;
  const dispacth = useDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Global Variable
  const { access_token } = useSelector((state) => state.auth);
  const {
    entity_data,
    department_createIsLoading,
    department_createMessage,
    department_detail,
  } = useSelector((state) => state.settings);

  const onSubmit = (data) => {
    const { DepartmentName, Code } = data;

    if (entityFilter === 0) {
      setShow(true);
      setType('error');
      setNotifText('Please select entity');
    } else {
      let sendData = {
        entity_id: entityFilter,
        code: Code,
        name: DepartmentName,
      };

      setTempSendData(sendData);
      setShowModalConfirm(true);
    }
  };

  // fot initial state
  const [entityFilter, setEntityFilter] = React.useState(0);
  const [dataInput, setDataInput] = React.useState(null);
  const [tempSendData, setTempSendData] = React.useState(null);

  const handleChangeEntity = (event) => {
    setEntityFilter(event.target.value);
  };

  // state for modal
  const [showModalConfirm, setShowModalConfirm] = React.useState(false);
  const [showModalSuccess, setShowModalSuccess] = React.useState(false);

  // handle message
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };
  //  end handle Message

  // for wacth value inside useform
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      // console.log(value, name, type)
      setDataInput(value)
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  React.useEffect(() => {
    dispacth(settingAction.getEntity(access_token));
  }, []);

  React.useEffect(() => {
    dispacth(settingAction.getDetailDepartment(access_token, id));
  }, []);

  React.useEffect(() => {
    setValue('Code', department_detail?.departments?.code);
    setValue('DepartmentName', department_detail?.departments?.name);

    setEntityFilter(department_detail?.departments?.entity_id);
  }, [department_detail]);

  React.useEffect(() => {
    if (department_createMessage) {
      if (department_createMessage === 'Save departments Success') {
        setShowModalSuccess(true);
      } else if (department_createMessage !== '') {
        setShow(true);
        setType('error');
        setNotifText(department_createMessage);
      }
      dispacth(settingAction.setBlankCreateDepartment());
    }
  }, [department_createMessage]);

  return (
    <>
      <CustomizedSnackbars
        show={show}
        handleClose={handleClose}
        message={notifText}
        type={type}
      />
      <ModalConfirm
        open={showModalConfirm}
        handleCloseModalConfirm={() => setShowModalConfirm(false)}
        onPress={() =>
          dispacth(settingAction.createDepartment(access_token, tempSendData))
        }
      />
      <ModalSuccess
        open={showModalSuccess}
        onPress={() => router.push('/organize')}
      />
      <Grid md={12} className={classes.wrapperForm}>
        <Box className={classes.sectionHeader}>
          <Typography className={classes.headerTitle}>
            Create Department
          </Typography>
        </Box>
        <Box className={classes.sizeBorder} />
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Grid md={12} className={classes.wrapperBody}>
            <InputGroup
              label="Code"
              placeholder="input Text"
              register={register}
              required
              type="text"
              isRequire={true}
            />
            <p className={classes.errorText}>{errors.Code?.message}</p>
            <Box className={classes.sizeTop} />
            <InputGroup
              label="DepartmentName"
              placeholder="input Text"
              register={register}
              required
              type="text"
              isRequire={true}
            />
            <p className={classes.errorText}>
              {errors.DepartmentName?.message}
            </p>
            <Box className={classes.sizeTop} />
            <Box>
              <Typography className={classes.labelEntity}>Entity*</Typography>
              <FormControl className={classes.formControl}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={entityFilter}
                  onChange={handleChangeEntity}
                  IconComponent={() => <DownArrow />}
                >
                  <MenuItem value={0}>All Entity</MenuItem>
                  {entity_data?.entities?.map((ent, index) => (
                    <MenuItem key={index} value={ent.id}>
                      <Typography
                        className={classes.bodyDataText}
                        variant="body1"
                        component="p"
                      >
                        {ent.code}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Box className={classes.sizeBorder} />
          <Grid md={12} className={classes.wrapperFooter}>
            <input
              className={
                dataInput?.Code &&
                dataInput?.DepartmentName &&
                entityFilter !== 0
                  ? classes.submitBtn
                  : classes.submitBtnFalse
              }
              type="submit"
              value="Submit"
            />
            <Box
              className={classes.btnCancel}
              onClick={() => router.push('/organize')}
            >
              <Typography>Cancel</Typography>
            </Box>
          </Grid>
        </form>
      </Grid>
    </>
  );
};
const useStyles = makeStyles({
  wrapperForm: {
    width: '100%',
    maxWidth: 605,
    backgroundColor: '#fff',
    margin: 'auto',
    borderRadius: 8,
  },
  sectionHeader: {
    padding: 20,
  },
  headerTitle: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  sizeBorder: {
    borderBottom: '1px solid #E7E7E8',
  },
  wrapperBody: {
    padding: 20,
  },
  sizeTop: {
    marginTop: 20,
  },
  wrapperFooter: {
    padding: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  submitBtn: {
    width: 193,
    height: 50,
    borderRadius: 8,
    backgroundColor: '#1B2030',
    color: '#fff',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  submitBtnFalse: {
    width: 193,
    height: 50,
    borderRadius: 8,
    backgroundColor: '#969BAB',
    color: '#fff',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
  },
  btnCancel: {
    marginLeft: 20,
    width: 193,
    height: 50,
    borderRadius: 8,
    backgroundColor: '#fff',
    color: '#1B2030',
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  // styling Form select
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 5,
    paddingBottom: 5,
    // maxWidth: 220,
    width: '100%',
    // minWidth: 160,
    height: 50,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      // marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#969BAB',
      paddingRight: 2,
      background: 'none',
    },
  },
  labelEntity: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
    marginBottom: 12,
  },
  errorText: {
    color: 'red',
  },
});

export default DepartmentEdit;
