import React, { useEffect } from 'react';
import { makeStyles, Grid, withStyles, Box } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { get } from 'lodash';

import { Row } from './TableCustom/index';
import { dateFormater, formatRupiah } from '../../utils/formatter';
import { useRouter } from '../../utils/useRouter';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../redux/PR/actions';
import * as actions_2 from '../../redux/Utility/actions';
import { SortingASC, SortingDESC } from '../../utils/SortFunc';
import Lib from 'src/utils/Lib';

// compontent
import DatePicker from '../DatePicker';
import { TabelLoading, TableNoData } from '../Loading/TableLoading';
import { CircleLoadingSecondary } from '../Loading/CircleLoading';
import ModalDeleteItem from '../ModalDeleteItem';
import CustomizedSnackbars from '../PopupMessage';
import DesktopPagination from '../Pagination/DesktopPagination';
import SortArrow from '../SortArrow';
import { ContentText } from '../../libs/Typography';
import TitleWithSearchNo from '../TableData-Common/titleWithSearchNo';

// Icons
import TandaSeru from '../../assets/Icons/tandaSeru.png';
import Asnyc from '../../assets/Icons/async.png';
import DateIcon from '../../assets/Icons/date.png';
import Export from '../../assets/Icons/export.png';

// Svg
import { DownArrow } from '../../assets/Icons/svg/DownArrow';

// generate fixed table
const StickyTableCell = withStyles((theme) => ({
  head: {
    right: 0,
    // position: 'sticky',
    // zIndex: theme.zIndex.appBar + 2,
  },
  body: {
    backgroundColor: '#ddd',
    minWidth: '50px',
    right: 0,
    position: 'sticky',
    zIndex: 99,
  },
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const PrTableData = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispacth = useDispatch();

  // Create format for table and pagination
  const [page, setPage] = React.useState(1);
  const [pagination, setPagination] = React.useState(1);
  const [fromRow, setFromRow] = React.useState(1);
  const [toRow, setToRow] = React.useState(1);
  const [TotalRow, setTotalRow] = React.useState(1);
  const [limit, setLimit] = React.useState(10);

  // initial state
  const [rowData, setRowData] = React.useState([]);
  const [sortingData, setSortingData] = React.useState([]);
  const [activityFilter, setActivityFilter] = React.useState('All Activity');
  const [status, setStatus] = React.useState('All_Status');
  const [departementFilter, setDepartementFilter] = React.useState(0);
  const [entityFilter, setEntityFilter] = React.useState(0);

  const [tempId, setTempId] = React.useState(null);
  const [entityList, setEntityList] = React.useState([])
  const [buttonSyncClicked, setButtonSyncClicked] = React.useState(false);

  const [docNumParam, setDocNumParam] = React.useState('');

  const [date, setDate] = React.useState({
    start: null,
    end: null,
  });

  // handle message
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };

  // end handle Message

  // Global Variable
  const { access_token, data } = useSelector((state) => state.auth);
  const {
    notifLoading,
    notfiData,
    data_pr,
    isLoading,
    pr_sap,
    del_prMessage,
    del_prLoading,
    publish_prMessage,
    publish_prLoading,
  } = useSelector((state) => state.pr);
  const { entity_data, activity_data, csv_loading, department_data } =
    useSelector((state) => state.utility);
  
  // handle Delete Item
  const [showModalDelete, setShowModalDetele] = React.useState(false);
  const [tempDeleteId, setTempDeleteId] = React.useState(0);

  const handleDelete = (id) => {
    if (data?.user?.role.length > 0) {
      if (data?.user?.role[0] == 'admin' || data?.user?.role[0] == 'staff') {
        setType('error');
        setShow(true);
        setNotifText('you not have permission to take this action');
      } else {
        setTempDeleteId(id);
        setShowModalDetele(true);
      }
    }
  };

  const handleDeletePR = () => {
    // console.log('delete', tempDeleteId);
    dispacth(actions.deletePR(access_token, tempDeleteId));
  };

  // end handle delete item

  const handleChangeDepartement = (event) => {
    setDepartementFilter(event.target.value);
  };
  const handleChangeEntity = (event) => {
    setEntityFilter(event.target.value);
  };

  const handleChangeActivity = (event) => {
    setActivityFilter(event.target.value);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
  };

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleDetailData = (id) => {
    router.push(`/purchase-requistor-detail/prId=${id}`);
  };

  const handleChangePagination = (event, value) => {
    setPagination(value);
  };

  const handleGETSyncSAP = () => {
    let params = { is_draft: 1 };

    dispacth(actions.getPRSAPdata(access_token, params));
    setButtonSyncClicked(true);
  };

  React.useEffect(() => {
    if (buttonSyncClicked && tempId.length) {
      handleSubmitSAP();
      setButtonSyncClicked(false);
    }
  }, [tempId]);

  const handleExportCsv = () => {
    let params = {
      doc_type: 'PR',
      date_start: dateFormater(date.start, 'YYYY-MM-DD'),
      date_end: dateFormater(date.end, 'YYYY-MM-DD'),
      status: status,
      entity_id: entityFilter,
    };

    if (!date.start || !date.end) {
      delete params.date_start;
      delete params.date_end;
    }

    if (status === 'All_Status') delete params.status;

    if (entityFilter === 0) delete params.entity_id;

    // console.log(params);

    dispacth(actions_2.exportCSV(access_token, params));
  };

  const handleGETPRdata = () => {
    let params = {
      date_start: dateFormater(date.start, 'YYYY-MM-DD'),
      date_end: dateFormater(date.end, 'YYYY-MM-DD'),
      status: status,
      department_id: departementFilter,
      entity_id: entityFilter,
      activity: activityFilter,
      page: pagination,
      is_draft: 0,
      order_dir: 'desc',
      order_by: 'id',
      limit: limit,
    };

    if (docNumParam.length) params.doc_num = docNumParam;

    if (!date.start || !date.end) {
      delete params.date_start;
      delete params.date_end;
    }

    if (status === 'All_Status') delete params.status;

    if (departementFilter === 0) delete params.department_id;

    if (entityFilter === 0) delete params.entity_id;

    if (activityFilter === 'All Activity') delete params.activity;

    // console.log(params);
    dispacth(actions.getPRdata(access_token, params));
  };

  const handleSubmitSAP = () => {
    let data = {
      ids: JSON.stringify(tempId),
    };

    dispacth(actions.publishPR(access_token, data));
  };

  useEffect(() => {
    dispacth(actions.getSAPnotification(access_token));
  }, []);

  useEffect(() => {
    if(!Lib.isSuperAdmin(data)) {
      setEntityList(get(data, ['user', 'entity'], []))
    } else {
      dispacth(actions_2.getEntity(access_token));
    }
    dispacth(actions_2.getActivity(access_token));
    dispacth(actions_2.getDepartment(access_token));
  }, []);

  useEffect(()=> {
    if(entity_data && Lib.isSuperAdmin(data)){
      setEntityList(entity_data)
    }
  }, [entity_data])

  useEffect(() => {
    setRowData(sortingData);
  }, [sortingData]);

  useEffect(() => {
    if (entityFilter !== 0) {
      let params = {
        entity_id: entityFilter,
      };
      dispacth(actions_2.getDepartmentById(access_token, params));
    } else {
      dispacth(actions_2.setNullDepartmentById());
    }
  }, [entityFilter]);

  // useEffect(() => {
  //   let params = {
  //     is_draft: 0,
  //     order_dir: 'desc',
  //     order_by: 'id',
  //     page: 1,
  //     limit: limit,
  //   };
  //   dispacth(actions.getPRdata(access_token, params));
  // }, []);

  useEffect(() => {
    let tempArr = [];

    data_pr?.data?.map((data, index) => {
      let tempData = {
        id: index,
        prId: data?.id,
        noPr: data?.doc_num,
        name: data?.purpose_of_request,
        department: data?.department?.code,
        entity: data?.entity?.code,
        currency: data?.currency,
        activity: data?.items[0]?.activity_name,
        total: data?.grand_total ?? '0',
        status: data?.status,
        date: `${data?.doc_date} ${data?.doc_time}`,
        qr_url: data?.qr_code,
        approval_one: {
          name: data?.approval_transactions[0]?.user?.name,
          position: data?.approval_transactions[0]?.user?.positions?.name,
          approve: data?.approval_transactions[0]?.approve,
          reject: data?.approval_transactions[0]?.reject,
          date: data?.approval_transactions[0]?.created_at,
        },
        approval_two: {
          name: data?.approval_transactions[1]?.user?.name,
          position: data?.approval_transactions[1]?.user?.positions?.name,
          approve: data?.approval_transactions[1]?.approve,
          reject: data?.approval_transactions[1]?.reject,
          date: data?.approval_transactions[1]?.created_at,
        },
        approval_three: {
          name: data?.approval_transactions[2]?.user?.name,
          position: data?.approval_transactions[2]?.user?.positions?.name,
          approve: data?.approval_transactions[2]?.approve,
          reject: data?.approval_transactions[2]?.reject,
          date: data?.approval_transactions[2]?.created_at,
        },
        approval_four: {
          name: data?.approval_transactions[3]?.user?.name,
          position: data?.approval_transactions[3]?.user?.positions?.name,
          approve: data?.approval_transactions[3]?.approve,
          reject: data?.approval_transactions[3]?.reject,
          date: data?.approval_transactions[3]?.created_at,
        },
        data,
      };
      tempArr.push(tempData);
    });

    let countPage = Math.ceil(data_pr?.total / limit);
    setPage(countPage);
    if (pagination > countPage) {
      setPagination(countPage);
    }
    setFromRow(data_pr?.from);
    setToRow(data_pr?.to);
    setTotalRow(data_pr?.total);
    setRowData(tempArr);
  }, [data_pr]);

  useEffect(() => {
    handleGETPRdata();
  }, [
    date,
    status,
    departementFilter,
    entityFilter,
    pagination,
    activityFilter,
    limit,
  ]);

  useEffect(() => {
    let tempArrId = [];

    pr_sap?.data?.map((data, index) => {
      if (data?.id) tempArrId.push(data.id);
    });

    setTempId(tempArrId);
  }, [pr_sap]);

  useEffect(() => {
    if (del_prMessage) {
      if (del_prMessage === 'Delete purchase requisition Success') {
        handleGETPRdata();
        setShowModalDetele(false);
      } else if (del_prMessage !== '') {
        setType('error');
        setShow(true);
        setNotifText(del_prMessage);
      }
      dispacth(actions.setBlankDelelete());
    }
  }, [del_prMessage]);

  useEffect(() => {
    if (publish_prMessage) {
      if (
        publish_prMessage === 'Purchase requisition published successfully.'
      ) {
        handleGETPRdata();
        dispacth(actions.getSAPnotification(access_token));
      }
      dispacth(actions.setBlankPulishPr());
    }
  }, [publish_prMessage]);

  return (
    <>
      <Grid container md={12} style={{ maxWidth: '100vw' }}>
        <Grid md={12}>
          <CustomizedSnackbars
            show={show}
            handleClose={handleClose}
            message={notifText}
            type={type}
          />
          <ModalDeleteItem
            open={showModalDelete}
            close={() => setShowModalDetele(false)}
            // id={tempDeleteId}
            onPress={handleDeletePR}
            isLoading={del_prLoading}
          />
          <div className={classes.titleTableContent}>
            <TitleWithSearchNo
              Title={<ContentText
                styling={classes.titleTable}
                content={`PR - Purchase Requisition`}
                variant={'body1'}
              />}
              docNumParam={docNumParam}
              setDocNumParam={setDocNumParam}
              sendApiQuery={handleGETPRdata}
            />

            {false /*get(data, ['user', 'pr_sync'])*/ ? (
            <div className={classes.contentRigth}>
              <div className={classes.notifContent}>
                {notifLoading ? (
                  <ContentText
                    styling={classes.notifText}
                    content={`Loading Read Data`}
                    variant={'body1'}
                  />
                ) : notfiData && notfiData > 0 ? (
                  <>
                    <img src={TandaSeru} alt="image not found" />
                    <ContentText
                      styling={classes.notifText}
                      content={`${notfiData} new data are available`}
                      variant={'body1'}
                    />
                  </>
                ) : (
                  <>
                    <ContentText
                      styling={classes.notifTextNull}
                      content={`No new data`}
                      variant={'body1'}
                    />
                  </>
                )}
              </div>

              <div
                className={
                  notfiData > 0 ? classes.btnAsnyc : classes.btnAsnycDisable
                }
                onClick={notfiData > 0 && !publish_prLoading ? handleGETSyncSAP : null}
              >
                <img src={Asnyc} alt="image not found" />
                <ContentText
                  styling={classes.btnText}
                  content={`Sync SAP Data`}
                  variant={'body1'}
                />
              </div>
            </div>) : null}
          </div>
        </Grid>
        <Box className={classes.filterContent}>
          <Box className={classes.dateContent}>
            <div className={classes.dateRangeIcon}>
              <img src={DateIcon} alt="image not found" />
            </div>
            <div className={classes.containerDate}>
              <DatePicker
                onDatePick={(pickedDay) => {
                  setDate(pickedDay);
                }}
                placeholder="Select Date Range"
              />
            </div>
          </Box>
          <Box className={classes.filterEntityContent}>
            <FormControl className={classes.formControl}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={entityFilter}
                onChange={handleChangeEntity}
                IconComponent={() => <DownArrow />}
              >
                <MenuItem value={0}>All Entity</MenuItem>
                {entityList?.map((ent, index) => (
                  <MenuItem key={index} value={ent.id}>
                    <ContentText
                      styling={classes.bodyDataText}
                      content={ent.code}
                      variant={'body1'}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.filterDepartmentContent}>
            <FormControl className={classes.formControl}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={departementFilter}
                onChange={handleChangeDepartement}
                IconComponent={() => <DownArrow />}
              >
                <MenuItem value={0}>All Department</MenuItem>
                {department_data?.map((dep, index) => (
                  <MenuItem key={index} value={dep.id}>
                    <Box className={classes.boxDepartment}>
                      <ContentText
                        styling={classes.bodyDataText}
                        content={dep.code}
                        variant={'body1'}
                      />
                      <ContentText
                        styling={classes.bodyDataSubText}
                        content={`- ${dep.name}`}
                        variant={'body1'}
                      />
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.filterActivityContent}>
            <FormControl className={classes.formControl}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={activityFilter}
                IconComponent={() => <DownArrow />}
                onChange={handleChangeActivity}
              >
                <MenuItem value={'All Activity'}>All Activity</MenuItem>
                {activity_data?.map((act, index) => (
                  <MenuItem key={index} value={act.activity_name}>
                    <ContentText
                      styling={classes.bodyDataText}
                      content={act.activity_name}
                      variant={'body1'}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box className={classes.filterStatusContent}>
            <FormControl className={classes.formControl}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                IconComponent={() => <DownArrow />}
                value={status}
                onChange={handleChangeStatus}
              >
                <MenuItem value={'All_Status'}>All Status</MenuItem>
                <MenuItem value={'open'}>Open</MenuItem>
                <MenuItem value={'need_confirm'}>Confirm Approval</MenuItem>
                <MenuItem value={'waiting'}>Waiting Approval</MenuItem>
                <MenuItem value={'approved'}>Approved</MenuItem>
                <MenuItem value={'rejected'}>Rejected</MenuItem>
                <MenuItem value={'cancelled'}>Cancelled</MenuItem>
                <MenuItem value={'closed'}>Closed</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.filterBlankArea}>{/* black area */}</Box>
          <Box className={classes.exportContent}>
            <div className={classes.btnExportBlue} onClick={handleExportCsv}>
              {csv_loading ? (
                <Box className={classes.loadingExport}>
                  <CircleLoadingSecondary />
                </Box>
              ) : (
                <img src={Export} alt="image not found" />
              )}
              <ContentText
                styling={classes.btnText}
                content={`Export .csv`}
                variant={'body1'}
              />
            </div>
          </Box>
        </Box>

        <div style={{ width: '100%' }}>
          <Grid md={12}>
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        No.
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'noPr', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'noPr', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        Purpose of Request
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'name', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'name', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        Department
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'department', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'department', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        Entity
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'entity', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'entity', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        Activity
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'activity', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'activity', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        Grand Total
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'total', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'total', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        Status
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'status', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'status', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHeader}>
                        Date
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'date', setSortingData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'date', setSortingData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StickyTableCell className={classes.head}>
                      <StyledTableCell className={classes.head} numeric>
                        Action
                      </StyledTableCell>
                    </StickyTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? null : rowData.length > 0 ? (
                    rowData?.map((row, index) => (
                      <Row
                        key={index}
                        index={index}
                        row={row}
                        handleDetailData={handleDetailData}
                        handleDelete={handleDelete}
                      />
                    ))
                  ) : (
                    <TableNoData colspan={9} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {isLoading ? <TabelLoading /> : null}
          </Grid>
        </div>
        {isLoading ? null : rowData?.length > 0 ? (
          <DesktopPagination
            toRow={toRow}
            fromRow={fromRow}
            TotalRow={TotalRow}
            page={page}
            handleChangePagination={handleChangePagination}
            limit={limit}
            handleChangeLimit={handleChangeLimit}
            pagination={pagination}
          />
        ) : null}
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  // cutome table
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  wrapperTableHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  head: {
    backgroundColor: '#fff',
    fontSize: 16,
    lineHeight: '30px',
    fontWeight: 'bold',
    color: '#000000',
    borderBottom: 'none',
    '&:nth-child(1)': {
      minWidth: 90,
      borderBottom: 'none',
    },
    '&:nth-child(2)': {
      minWidth: 198,
    },
    '&:nth-child(3)': {
      minWidth: 140,
    },
    '&:nth-child(4)': {
      minWidth: 110,
    },
    '&:nth-child(5)': {
      minWidth: 200,
    },
    '&:nth-child(6)': {
      minWidth: 150,
    },
    '&:nth-child(7)': {
      minWidth: 180,
    },
    '&:nth-child(8)': {
      minWidth: 170,
    },
  },
  tableContainer: {
    // maxHeight: '400px',
    Width: '100%',
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
  },
  cell: {
    minWidth: '100px',
  },
  // end custom table
  titleTableContent: {
    paddingRight: 22,
    paddingLeft: 22,
    paddingTop: 27,
    paddingBottom: 27,
    backgroundColor: '#ffff',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleTable: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  contentRigth: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  btnAsnyc: {
    backgroundColor: '#1B2030',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '14px 20px',
    borderRadius: 8,
    cursor: 'pointer',
  },
  btnAsnycDisable: {
    backgroundColor: '#969BAB',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '14px 20px',
    borderRadius: 8,
  },
  btnExport: {
    backgroundColor: '#127EEE',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '14px 20px',
    borderRadius: 8,
    cursor: 'pointer',
  },
  btnExportBlue: {
    backgroundColor: '#127EEE',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 14px',
    borderRadius: 8,
    cursor: 'pointer',
    maxHeight: 40,
    maxWidth: 154,
  },
  btnText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
    marginLeft: 8,
  },
  notifContent: {
    marginRight: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  notifText: {
    fontWeight: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#1B2030',
    marginLeft: 13,
  },
  notifTextNull: {
    fontWeight: 18,
    lineHeight: '27px',
    color: '#969BAB',
    marginLeft: 13,
  },
  filterStatusContent: {
    marginLeft: 10,
  },
  filterActifityContent: {
    marginLeft: 10,
  },
  filterActifityContentActivity: {
    marginLeft: 10,
    minWidth: 160,
    maxWidth: 170,
  },
  iconAction: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 20,
  },
  Action: {
    cursor: 'pointer',
  },
  tableContent: {
    backgroundColor: '#fff',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderBottom: 'none !important',
    position: 'relative',
    maxWidth: 1440,
  },
  tableHead: {
    fontSize: 16,
    lineHeight: '30px',
    color: '#000000',
    fontWeight: 'bold',
    // position: 'relative',
    // maxWidth: 1440,
    overflowX: 'scroll',
    '&.MuiTableRow-root .MuiTableCell-root': {
      borderBottom: 'none',
      fontSize: 16,
      lineHeight: '30px',
      color: '#000000',
      fontWeight: 'bold',
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 16,
      paddingBottom: 16,
    },
    '&.MuiTableRow-root .MuiTableCell-head:nth-last-child(1)': {
      position: '-webkit-sticky',
      position: 'sticky',
      background: '#fff',
      left: 0,
      zIndex: 1,
    },
  },
  // Pagination styling
  descTable: {
    paddingTop: 11.5,
    paddingBottom: 11.5,
  },
  descTableText: {
    color: '#969BAB',
    fontSize: 16,
    lineHeight: '21px',
  },
  paginationContent: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pagination: {
    borderRadius: 0,
    '&.MuiPagination-root .MuiPagination-ul .MuiPaginationItem-root': {
      margin: 0,
    },
    borderRadius: 0,
    '&.MuiPagination-root .MuiPagination-ul .MuiPaginationItem-rounded': {
      borderRadius: 0,
    },
    '&.MuiPagination-root .MuiPagination-ul .MuiButtonBase-root': {
      backgroundColor: '#fff',
      color: '#969BAB',
    },
    '&.MuiPagination-root .MuiPagination-ul .MuiButtonBase-root.Mui-selected': {
      backgroundColor: '#1B2030',
      color: '#fff',
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-child(1)': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-child(1) button': {
      color: '#1B2030',
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-last-child(1)': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-last-child(1) button': {
      color: '#1B2030',
    },
  },
  wrapperMenuContent: {
    width: '100%',
  },
  // Filter Content
  filterContent: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: '#fff',
    borderTop: '1px solid #E7E7E8',
    borderBottom: '1px solid #E7E7E8',
    width: '100%',
    // position: 'relative',
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
    // position: 'relative',
  },
  dateContent: {
    width: '100%',
    maxWidth: 225,
    // backgroundColor: 'red',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingRight: 5,
  },
  dateRangeIcon: {
    width: 58,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E7E7E8',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  containerDate: {
    width: `100%`,
    maxWidth: `calc(100% - 58px)`,
  },
  // styling Form select
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 5,
    // // paddingBottom: 5,
    // maxWidth: 220,
    width: '100%',
    // minWidth: 160,
    height: 40,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      marginTop: 4,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#969BAB',
      paddingRight: 2,
      background: 'none',
    },
  },
  filterCategoryContent: {
    width: '100%',
    maxWidth: 190,
    minWidth: 185,
    paddingLeft: 5,
    paddingRight: 5,
  },
  filterDepartmentContent: {
    width: '100%',
    maxWidth: 187,
    minWidth: 170,
    paddingLeft: 5,
    paddingRight: 5,
  },
  filterEntityContent: {
    paddingLeft: 5,
    paddingRight: 5,
    width: '100%',
    maxWidth: 150,
  },
  filterActivityContent: {
    paddingLeft: 5,
    paddingRight: 5,
    width: '100%',
    maxWidth: 180,
  },
  filterCurrencyContent: {
    paddingLeft: 5,
    paddingRight: 5,
    width: '100%',
    maxWidth: 133,
    minWidth: 130,
  },
  filterStatusContent: {
    paddingLeft: 5,
    paddingRight: 5,
    width: '100%',
    maxWidth: 180,
    minWidth: 130,
  },
  exportContent: {
    marginRight: 24,
    paddingRight: 20,
    width: '100%',
    maxWidth: 164,
    minWidth: 154,
    position: 'absolute',
    right: 0,
    zIndex: 90,
    backgroundColor: '#fff',
  },
  filterBlankArea: {
    minWidth: 150,
  },

  btnAsnycDisable: {
    backgroundColor: '#969BAB',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '14px 20px',
    borderRadius: 8,
  },
  // styling menu select
  boxDepartment: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  bodyDataSubText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    marginLeft: 4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  loadingExport: {
    width: 20,
    height: 20,
    maxHeight: 20,
    maxWidth: 20,
  },
}));

const rows = [
  {
    id: 12321,
    name: 'Dolor at amet duis vestibulum',
    department: 'FINC',
    entity: 'DKTI',
    activity: 'TV Commercial',
    status: 'Open',
    total: '2930010',
    date: '12/07/2021 09:34',
  },
  {
    id: 12322,
    name: 'Dolor at amet duis vestibulum',
    department: 'FINC',
    entity: 'DKTI',
    activity: 'TV Commercial',
    status: 'Cancel',
    total: '2930010',
    date: '12/07/2021 09:34',
  },
  {
    id: 12323,
    name: 'Dolor at amet duis vestibulum',
    department: 'FINC',
    entity: 'DKTI',
    activity: 'TV Commercial',
    status: 'Approval 2',
    total: '2930010',
    date: '12/07/2021 09:34',
  },
  {
    id: 12324,
    name: 'Dolor at amet duis vestibulum',
    department: 'FINC',
    entity: 'DKTI',
    activity: 'TV Commercial',
    status: 'Approved',
    total: '2930010',
    date: '12/07/2021 09:34',
  },
  {
    id: 12325,
    name: 'Dolor at amet duis vestibulum',
    department: 'FINC',
    entity: 'DKTI',
    activity: 'TV Commercial',
    status: 'Closed',
    total: '2930010',
    date: '12/07/2021 09:34',
  },
];
export default PrTableData;
