import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import BootstrapTooltip from '../Tooltip';

// icons
import Trash from '../../assets/Icons/trash.png';
import TrashRed from '../../assets/Icons/trashRed.png';

const TrashDelete = ({ onPress }) => {
  const classes = useStyles();
  const [isHover, setIsHover] = React.useState(false);
  return (
    <>
      <Box
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        className={classes.wrapperIconDelete}
        onClick={onPress}
      >
        <BootstrapTooltip title="Delete">
          <img
            className={classes.icons}
            src={isHover ? TrashRed : Trash}
            alt="image not found"
          />
        </BootstrapTooltip>
      </Box>
    </>
  );
};

const useStyles = makeStyles({
  wrapperIconDelete: {
    marginLeft: 32.5,
    cursor: 'pointer',
    width: 20,
    height: 20,
  },
  icons: {
    width: 20,
    height: 20,
  },
});

export default TrashDelete;
