import React from 'react';
import { makeStyles, Box, Grid, Typography } from '@material-ui/core';

// Icons
import Trash from '../../../assets/Icons/trash.png';
import TrashRed from '../../../assets/Icons/trashRed.png';
import Search from '../../../assets/Icons/Search.png';

// Svg
import { DownArrow } from '../../../assets/Icons/svg/DownArrow';

export const PaymentInformation = ({
  inputPayment,
  index,
  handleChangeInputPayment,
  handleRemovePayment,
  setSearchText,
  vendorData,
  vendor,
  searchText,
  selectVendor,
}) => {
  const classes = useStyles();

  const [deleteHover, setDeleteHover] = React.useState(false);
  const [isVendorFocus, setIsVendorFocus] = React.useState(false);
  const [showVendor, setShowVendor] = React.useState(false);

  const handleChooseBank = (data, index) => {
    selectVendor(data, index);
    setIsVendorFocus(false);
    setShowVendor(false);
  };

  return (
    <>
      <Grid
        md={12}
        key={index}
        container
        className={classes.wrapperPaymentForm}
      >
        <Grid md={6} className={classes.sectionLeft}>
          <input
            className={`${classes.inputMenu} inputstyle`}
            name="number"
            placeholder="Input Number"
            value={inputPayment.number}
            onChange={(event) => handleChangeInputPayment(index, event)}
            maxLength={30}
          />
        </Grid>
        <Grid md={6}>
          <Box className={classes.wrapperSelect}>
            <Box
              className={classes.filterVendorContent}
              // onBlur={() => setIsVendorFocus(false)}
            >
              {inputPayment?.bank_id ? (
                <Box className={classes.vendorBoxChosse}>
                  <img className={classes.bankAvatar} src={inputPayment.bank_avatar} />
                  <Typography className={classes.TitleVendor}>
                    {`-  ${inputPayment.bank_name}`}
                  </Typography>
                </Box>
              ) : null}
              <Box className={classes.iconDownArrow}>
                <DownArrow />
              </Box>
              <input
                className={classes.inputVendor}
                readOnly
                // value={inputPayment.bank_id}
                // value={inputPayment.bank_name}
                name="bank_id"
                autoComplete="on"
                onFocus={() => setIsVendorFocus(true)}
                placeholder={inputPayment?.bank_name !== '' ? '' : 'Select'}
                onBlur={() => setIsVendorFocus(false)}
              />
              {/* render for select vendor name */}
              {isVendorFocus || showVendor ? (
                <Box
                  onBlur={() => setIsVendorFocus(true)}
                  className={classes.contentDataVendor}
                  onMouseOver={() => setShowVendor(true)}
                  onMouseOut={() => setShowVendor(false)}
                >
                  <Box className={classes.contentVendorSearch}>
                    <input
                      className={classes.searchVendor}
                      autoComplete="on"
                      onFocus={() => setIsVendorFocus(true)}
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      placeholder="Search"
                      // onBlur={() => setIsVendorFocus(false)}
                    />
                    <img src={Search} alt="Search" />
                  </Box>
                  <Box className={classes.listVendorName}>
                    {vendorData?.map((data, vendorIndex) => (
                      <Box
                        key={vendorIndex}
                        // onClick={() => selectVendor(data, index)}
                        onClick={() => handleChooseBank(data, index)}
                        className={classes.vendorBox}
                      >
                        <img className={classes.bankAvatar} src={data.avatar_url} alt='logo' />
                        <Typography className={classes.TitleVendor}>
                          {`-  ${data.name}`}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ) : null}
            </Box>
            <Box
              onMouseOver={() => setDeleteHover(true)}
              onMouseOut={() => setDeleteHover(false)}
              className={classes.deleteBtn}
              onClick={() => handleRemovePayment(index)}
            >
              <img src={deleteHover ? TrashRed : Trash} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  bankAvatar: {
    maxWidth: '60px',
  },
  wrapperPaymentForm: {
    marginTop: 20,
    marginBottom: 20,
    postion: 'relative',
  },
  sectionLeft: {
    paddingRight: 20,
  },
  inputMenu: {
    width: '100%',
    height: 50,
    borderRadius: 8,
    paddingLeft: 20,
    border: '1px solid #E7E7E8',
    backgroundColor: '#F8FAFB',
    outline: 'none',
    '&.inputstyle:focus': {
      border: '1.5px solid #F2C94C',
      outline: 'none',
      boxShadow: '0px 0px 0px 4px rgba(242, 201, 76, 0.17);',
      borderRadius: 8,
    },
  },
  // styling Form select
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 6,
    paddingBottom: 5,
    // maxWidth: 220,
    width: '94%',
    // minWidth: 160,
    maxHeight: 50,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#969BAB',
      paddingRight: 2,
      background: 'none',
    },
  },
  wrapperSelect: {
    display: 'flex',
    alignItems: 'center',
  },
  deleteBtn: {
    cursor: 'pointer',
    marginLeft: 12,
  },
  // styling search select
  inputVendor: {
    cursor: 'pointer',
    width: '99%',
    height: 50,
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    position: 'relative',
    zIndex: 99,
    paddingLeft: 15,
    '&.focus-visible': {
      border: '1px solid #E7E7E8',
      outline: 'none',
    },
  },
  filterVendorContent: {
    // paddingLeft: 10,
    // paddingRight: 5,
    width: '94%',
    position: 'relative',
  },
  exportContent: {
    marginRight: 24,
    paddingRight: 20,
    width: '100%',
    // maxWidth: 164,
    // minWidth: 154,
    position: 'absolute',
    right: 0,
    zIndex: 90,
    backgroundColor: '#fff',
  },
  filterBlankArea: {
    minWidth: 150,
  },
  contentDataVendor: {
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid #E7E7E8',
    minHeight: 202,
    maxHeight: 202,
    position: 'absolute',
    // maxWidth: 660,
    marginTop: 4,
    borderRadius: 8,
    padding: 6,
    zIndex: 99999,
  },
  contentVendorSearch: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    width: '100%',
    border: '1px solid #E7E7E8',
    paddingRight: 15,
    borderRadius: 8,
  },
  searchVendor: {
    flex: 1,
    height: 40,
    border: 'none',
    outline: 'none',
    paddingLeft: 12,
  },
  listVendorName: {
    marginTop: 8,
    height: `calc(202px - 56px)`,
    maxHeight: `calc(202px - 56px)`,
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.4em',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
      borderRadius: 4,
    },
  },
  vendorBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: 46,
  },
  TitleVendor: {
    marginTop: 2,
    marginBottom: 2,
    fontSize: 16,
    marginLeft: 10,
    lineHeight: '21px',
    color: '#000000',
    cursor: 'pointer',
  },
  iconDownArrow: {
    position: 'absolute',
    paddingTop: 20,
    right: 0,
    paddingRight: 20,
    zIndex: 100,
  },
  vendorBoxChosse: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 50,
    paddingLeft: 20,
    zIndex: 100,
  },
}));
