import React from 'react';
import { makeStyles, Grid, Box, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import axios from 'axios';
import { get } from 'lodash';
import { END_POINT } from '../../config/EndPoint';
import { useRouter } from '../../utils/useRouter';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../redux/APInvoice/actions';
import * as actions_2 from '../../redux/Utility/actions';
import * as userManagement from '../../redux/UserManagement/actions';
import {
  dateFormater,
  formatFileCount,
  formatRupiah,
} from '../../utils/formatter';
import * as settingAction from 'src/redux/Settings/actions';

// Components
import ButtonTransparent from '../Button/ButtonTransparent';
import HistoryTableDetail from '../HistoryTableDetail';
import ButtonQrCode from '../Button/ButtonQrCode';
import ButtonCancel from '../Button/ButtonCancel';
import ModalCancelPr from '../ModalCancelPr';
import ModalSuccess from '../ModalSuccess';
import ModalQRCode from '../ModalQRCode';
import ModalUpload from '../ModalUpload';
import ModalConfirmUpload from '../ModalConfirmUpload';
import ButtonMandatory from '../Button/ButtonMandatory';
import ModalSingleUpload from '../ModalSingleUpload';
import SKAPImvoiceDetail from '../Skeleton/SKAPInvoiceDetail';
import Item from '../PDFpreview/APInvoicePreview/Item';
import Service from '../PDFpreview/APInvoicePreview/Service';
import { CircleLoading } from '../Loading/CircleLoading';
import CustomizedSnackbars from '../PopupMessage';
import TrashDelete from '../TrashDelete';
import ModalDeleteItem from '../ModalDeleteItem';
import SelectFilter from '../SelectFilter';
import { ContentText } from '../../libs/Typography';
import Lib from 'src/utils/Lib';

//config
import globalConfig from 'src/config/global';

// Icons
import EyeBlue from '../../assets/Icons/eyeBlue.png';
import Cross from '../../assets/Icons/cross.png';
import UploadBtn from '../../assets/Icons/uploadBtn.png';
import UnduhSecond from '../../assets/Icons/unduhSecond.png';
import CirclePlus from '../../assets/Icons/circlePlus.png';
import Paper from '../../assets/Icons/paper.png';
import PdfFile from '../../assets/Icons/pdfIcons.png';
// Svg
import { SortArrow } from '../../assets/Icons/svg/SortArrow';

const APInvoiceFormApproval = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispacth = useDispatch();
  const { id } = router.query;

  // Global Variable
  const { access_token, data } = useSelector((state) => state.auth);
  const {
    apinvoice_detail,
    detail_message,
    detail_isLoading,
    cancel_apinvoiceMessage,
    cancel_apinvoiceLoading,
    assigment_message,
    assignment_loading,
    ap_transactionIsloading,
    ap_transactionMessage,
  } = useSelector((state) => state.apinvoice);
  const {
    qrcode_data,
    qrcode_loading,
    read_loading,
    read_data,
    del_file_isLoading,
    del_file_message,
  } = useSelector((state) => state.utility);
  const { internal_all_data, internal_all_IsLoading } = useSelector(
    (state) => state.userManagement
  );

  // initial state
  const [fileData, setFileData] = React.useState([]);
  const [showFileData, setShowFileData] = React.useState(false);

  const [aprrovalOne, setArpprovalOne] = React.useState(null);

  const [tempDeleteId, setTempDeleteId] = React.useState(0);
  const [rowFinance, setRowFinance] = React.useState(null);
  const [rowApproval, setRowApproval] = React.useState(null);

  // handle Render By Status
  const [isNeed, seIsNeed] = React.useState(false);
  const [btnQRon, setBtnQRon] = React.useState(false);
  const [canUpload, setCanUpload] = React.useState(false);
  const [canDelete, setCanDelete] = React.useState(false);

  // handleshow modal
  const [showModalConfirm, setShowModalConfirm] = React.useState(false);
  const [showModalSuccess, setShowModalSuccess] = React.useState(false);
  const [showModalSuccessUpload, setShowModalSuccessUpload] =
    React.useState(false);
  const [showModalQRCode, setShowModalQRCode] = React.useState(false);
  const [showModalDetail, setShowModalDetail] = React.useState(false);
  const [isStatusNeed, setIsStatusNeed] = React.useState(false);
  const [showModalUpload, setShowModalUpload] = React.useState(false);
  const [showModalConfirmUpload, setShowModalConfirmUpload] =
    React.useState(false);
  const [showModalSingleUpload, setShowModalSingleUpload] =
    React.useState(false);
  const [showModalDelete, setShowModalDelete] = React.useState(false);
  const [showModalSuccesDel, setShowModalSuccessDel] = React.useState(false);

  // configure to handle show Pdf Preview
  const [showPDFpreview, setShowPDFpreview] = React.useState(false);
  const [showPDFServie, setShowPDFService] = React.useState(false);

  // configure to handle File for upload ap invoice
  const [imageColletion, setImageCollection] = React.useState([]);
  const [uploadLoading, setUploadLoading] = React.useState(false);
  const [isFileLoading, setIsFileLoading] = React.useState(false);
  const [percent, setPercent] = React.useState(0);
  const [totalFile, setTotalFile] = React.useState(0);
  const [uploadedFile, setUploadedFile] = React.useState(0);

  // handle message
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };

  // end handle Message

  const onFileChange = (e) => {
    let tempFile = e.target.files;

    // console.log(tempFile);

    setImageCollection((prev) => [...prev, ...tempFile]);
  };

  const handleRemoveFile = (indexOf) => {
    let filteImage = imageColletion.filter((img, index) => index !== indexOf);

    setImageCollection(filteImage);
  };

  const handleUploadFile = () => {
    if (imageColletion) {
      try {
        setUploadLoading(true);

        const sendData = new FormData();
        imageColletion.map((img, index) => {
          sendData.append('file[]', img);
        });

        const config = {
          headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${access_token}`,
          },
          onUploadProgress: (progressEvent) => {
            var percentComplete = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setPercent(percentComplete);
          },
        };

        axios
          .post(
            `${END_POINT}files?doc_type=${'AP'}&doc_id=${
              apinvoice_detail?.id
            }&entity_code=${apinvoice_detail?.po?.entity?.code}`,
            sendData,
            config
          )
          .then((res) => {
            // console.log(res);
            setPercent(0);
            setUploadLoading(false);
            setShowModalConfirmUpload(false);
            setShowModalUpload(false);
            setImageCollection([]);
            handleGetFileAPINVOICE();
            setShowModalSuccessUpload(true);
          });
      } catch (error) {
        console.log('error upload file');
        setUploadLoading(false);
      }
      // dispacth(actions_2.uploadFile(access_token, sendData));
    } else {
      console.log('chosses an image');
    }
  };

  // end configure file upload

  const handleCloseModalConfirm = () => {
    setShowModalConfirm(false);
  };
  const handleCloseModalSuccess = () => {
    setShowModalSuccess(false);
  };
  const handleCloseUploading = () => {
    setShowModalUpload(false);
    setShowModalConfirmUpload(false);
    setShowModalSuccessUpload(false);
  };
  const handleCloseModalQRCode = () => {
    setShowModalQRCode(false);
  };
  const handleCloseModalDetail = () => {
    setShowModalDetail(false);
  };
  const handleCloseModalUpload = () => {
    setShowModalUpload(false);
  };
  const handleCloseModalConfirmUpload = () => {
    setShowModalConfirmUpload(false);
  };
  const handleCloseModalSingleUpload = () => {
    setShowModalSingleUpload(false);
  };

  const handleClosePDFpreview = () => {
    setShowPDFpreview(false);
  };

  const handleClosePDFService = () => {
    setShowPDFService(false);
  };

  const baseHandleChangeApproval = (newValue, setFn) => {
    const setVal = newValue?.id ?? null;
    setFn(setVal);
  }

  // handle option select admin
  const handleChangeApprovalOne = (event, newValue) => {
    baseHandleChangeApproval(newValue, setArpprovalOne);
  };

  // configure btn action
  const handleShowPDFpreview = (docType) => {
    if(apinvoice_detail?.qr_code){
      window.open(apinvoice_detail.qr_code)
    } else {
      if (docType === 'service') {
        setShowPDFService(true);
      } else {
        setShowPDFpreview(true);
      }
    }
  };

  const handleCancelAPinvoice = (id) => {
    if (data?.user?.role.length > 0) {
      if (data?.user?.role[0] == 'admin' || data?.user?.role[0] == 'staff') {
        setType('error');
        setShow(true);
        setNotifText('you not have permission to take this action');
      } else {
        dispacth(actions.cancelAPINVOICE(access_token, id));
      }
    }
  };

  const handleSeeImage = (url) => {
    window.open(url);
  };

  const handleGetFileAPINVOICE = () => {
    setIsFileLoading(true);
    //this configure for get file when page load or upload from client
    // get file apinvoice
    let readDataAPINVOICE = {
      doc_type: 'AP',
      doc_id: apinvoice_detail?.id,
      entity_code: apinvoice_detail?.po?.entity?.code,
    };

    dispacth(actions_2.readFile(access_token, readDataAPINVOICE));
  };

  // back up for confirm assigment
  const handleSetTransactionApproval = () => {
    let data = [
      {
        transaction_type_id: `6`,
        doc_id: `${apinvoice_detail?.id}`,
        user_id: `${apinvoice_detail?.approver[0]?.user?.id}`,
        level: '1',
      },
    ];
    dispacth(actions.transactionAPINVOICE(access_token, data));
  };

  const handleConfirmAssigment = () => {
    const data = [];

    if (aprrovalOne) {
      data.push({
        transaction_type_id: '7',
        doc_id: apinvoice_detail?.id,
        user_id: aprrovalOne,
        level: '1',
      });
    }

    dispacth(
      actions.assigmentAPINVOICE(access_token, apinvoice_detail?.id, data)
    );
  };

  const handleDelete = (id) => {
    setTempDeleteId(id);
    setShowModalDelete(true);
  };
  const reqDeleteFile = () => {
    // console.log('del', tempDeleteId);
    dispacth(actions_2.delFile(access_token, tempDeleteId));
  };
  const handleSuccesDelete = () => {
    handleGetFileAPINVOICE();
    setShowModalSuccessDel(false);
  };

  const handleCloseQR = () => {
    setShowModalQRCode(false);
    dispacth(actions.getAPINVOICEDetail(access_token, id));
  };

  React.useEffect(() => {
    dispacth(actions_2.NullFile());
  }, []);

  React.useEffect(() => {
    dispacth(actions.getAPINVOICEDetail(access_token, id));
    setFileData([]);
  }, []);

  React.useEffect(() => {
    if (
      apinvoice_detail?.status !== 'need_confirm' &&
      apinvoice_detail?.approval_transactions?.length > 0
    ) {
      setIsStatusNeed(true);
    } else {
      setIsStatusNeed(false);
    }

    if (
      apinvoice_detail?.status === 'need_confirm' ||
      apinvoice_detail?.status === 'rejected'
    ) {
      setCanDelete(true);
      setCanUpload(true);
    } else {
      setCanDelete(false);
      setCanUpload(false);
    }

    apinvoice_detail?.qr_code ? setBtnQRon(false) : setBtnQRon(true);

    handleGetFileAPINVOICE();
    // for get internal user
    dispacth(userManagement.getInternalAllUser(access_token));

    if(apinvoice_detail?.entity?.color){
      dispacth(settingAction.setBackgroundColor(apinvoice_detail?.entity?.color))
    }

  }, [apinvoice_detail]);

    //unmount
    React.useEffect(()=>{
      return () => dispacth(settingAction.setBackgroundColor(''))
    },[])

  // Generarte Qr
  // Change with url Stating or Production late
  let url = `https://dkt.senisites.com/ap-invoice-detail/apInvoice=${apinvoice_detail?.id}`;

  const generateQrCode = () => {
    let sendData = {
      doc_type: 'AP',
      entity_id: apinvoice_detail?.entity_id,
      doc_id: apinvoice_detail?.id,
      url,
    };

    dispacth(actions_2.getQrCode(access_token, sendData));
    // console.log(sendData);
    setShowModalQRCode(true);
  };

  React.useEffect(() => {
    if (read_data && read_data.length > 0) {
      setShowFileData(true);
      setFileData(read_data);
    } else {
      setShowFileData(false);
      setFileData([]);
    }
    setIsFileLoading(false);
  }, [read_data]);

  React.useEffect(() => {
    if (assigment_message) {
      if (assigment_message === 'Confirm assignment ap invoice Success') {
        router.push('/ap-invoice');
      } else if (
        assigment_message !== '' &&
        assigment_message !== 'Confirm assignment ap invoice Success'
      ) {
        setType('error');
        setShow(true);
        setNotifText(cancel_apinvoiceMessage);
      }
      dispacth(actions.setBlankConfirm());
    }
  }, [assigment_message]);

  React.useEffect(() => {
    if (cancel_apinvoiceMessage) {
      if (
        cancel_apinvoiceMessage === 'Update cancel status AP Invoice Success'
      ) {
        setShowModalConfirm(false);
        setShowModalSuccess(true);
      } else if (cancel_apinvoiceMessage !== '') {
        setType('error');
        setShow(true);
        setNotifText(cancel_apinvoiceMessage);
      }
      dispacth(actions.setBlankCancel());
    }
  }, [cancel_apinvoiceMessage]);

  React.useEffect(() => {
    if (ap_transactionMessage) {
      if (ap_transactionMessage === 'Submit Approval Transaction Success') {
        router.push('/ap-invoice');
      } else if (
        ap_transactionMessage !== '' &&
        ap_transactionMessage !== 'Submit Approval Transaction Success'
      ) {
        setType('error');
        setShow(true);
        setNotifText(ap_transactionMessage);
      }
      dispacth(actions.setBlankTransaction());
    }
  }, [ap_transactionMessage]);

  React.useEffect(() => {
    if (del_file_message) {
      if (del_file_message === 'Delete file Success') {
        setShowModalDelete(false);
        setShowModalSuccessDel(true);
      } else if (
        del_file_message !== '' &&
        del_file_message !== 'Delete file Success'
      ) {
        setType('error');
        setShow(true);
        setNotifText(del_file_message);
      }
      dispacth(actions_2.setBlankDelFile());
    }
  }, [del_file_message]);

  // React.useEffect(() => {
  //   dispacth(userManagement.getInternalAllUser(access_token));
  // }, []);

  React.useEffect(() => {
    if (internal_all_data) {
      let filterUserFinance = internal_all_data.filter(
        (person) => person?.departments?.code === 'FIN'
      );

      setRowFinance(filterUserFinance);
      setListApproval(filterUserFinance)
      setArpprovalOne(
        apinvoice_detail?.approval_transactions[0]?.user?.id ?? null
      );
    } else {
      setListApproval([])
    }

  }, [internal_all_data]);

  const setListApproval = (rowFinance) => {

    if (aprrovalOne !== null) {
      let filterUser = rowFinance?.filter((user) => user?.id !== aprrovalOne);

      setRowApproval(filterUser);
    } else {
      setRowApproval(rowFinance);
    }
  }

  // for render styling
  const _renderStatus = (status) => {
    if (status === 'need_confirm') {
      return (
        <>
          <Typography className={classes.statusNeed}>
            Confirm Approval
          </Typography>
        </>
      );
    } else if (status === 'waiting') {
      return (
        <>
          <Typography className={classes.statusWaiting}>
            Waiting Approval
          </Typography>
        </>
      );
    } else if (status === 'cancelled' || status === 'closed') {
      return (
        <>
          <Typography className={classes.statusClose}>{status}</Typography>
        </>
      );
    } else if (status === 'approved') {
      return (
        <Typography className={classes.statusApprove}>{status}</Typography>
      );
    } else if (status === 'rejected') {
      return <Typography className={classes.statusReject}>{status}</Typography>;
    }
  };

  const _renderButtonAction = (status) => {
    
    if (status === 'closed' || status === 'approved') {
      return null
      // return (
      //   <ButtonQrCode
      //     isOn={btnQRon}
      //     onPress={generateQrCode}
      //     showModal={() => setShowModalQRCode(true)}
      //     handleCanclePR={() => setShowModalConfirm(true)}
      //     hiddenCancel={globalConfig.cancelButton.hideOnStatus.includes(status) || !Lib.allowCancel(data)}
      //     title="AP Invoce"
      //   />
      // );
    } else if (status === 'need_confirm' || status === 'waiting') {
      return (
        <ButtonTransparent
          onSubmit={() => setShowModalConfirmUpload(true)}
          setShowModalConfirm={setShowModalConfirm}
          hiddenCancel={!Lib.allowCancel(data)}
          text={'Cancel AP Invoice'}
        />
      );
    } else if (status === 'rejected' && Lib.allowCancel(data)) {
      return (
        <ButtonCancel
          title={'AP Invoice'}
          handleCanclePR={() => setShowModalConfirm(true)}
          approval={apinvoice_detail?.approval_transactions}
        />
      );
    }
  };

  return (
    <>
      {detail_isLoading ? (
        <SKAPImvoiceDetail />
      ) : (
        <>
          {/* {console.log('file', fileData)} */}
          <Grid container>
            <CustomizedSnackbars
              show={show}
              handleClose={handleClose}
              message={notifText}
              type={type}
            />
            {/* modal delete */}
            <ModalDeleteItem
              open={showModalDelete}
              close={() => setShowModalDelete(false)}
              id={tempDeleteId}
              onPress={reqDeleteFile}
              isLoading={del_file_isLoading}
            />
            <ModalSuccess
              open={showModalSuccesDel}
              onPress={handleSuccesDelete}
            />
            {/* for cancel AP invoice */}
            <ModalSuccess
              open={showModalSuccess}
              setModalConfirm={setShowModalSuccess}
              handleCloseModalSuccess={handleCloseModalSuccess}
              onPress={() => router.push('/ap-invoice')}
            />
            <ModalCancelPr
              success={() => setShowModalSuccess(true)}
              open={showModalConfirm}
              setModalConfirm={setShowModalConfirm}
              handleCloseModalConfirm={handleCloseModalConfirm}
              title="AP invoice"
              onPress={() => handleCancelAPinvoice(apinvoice_detail?.id)}
              isLoading={cancel_apinvoiceLoading}
            />
            <ModalQRCode
              open={showModalQRCode}
              handleCloseModalQRCode={handleCloseModalQRCode}
              title="APDP"
              onClose={handleCloseQR}
              qrCode={qrcode_data}
              isLoading={qrcode_loading}
              no={apinvoice_detail?.doc_num}
              name={apinvoice_detail?.po?.pr?.purpose_of_request ?? '-'}
              activity={apinvoice_detail?.items[0]?.activity_name ?? '-'}
              total={apinvoice_detail?.doc_total_amount}
            />
            <ModalUpload
              open={showModalUpload}
              handleCloseModalUpload={handleCloseModalUpload}
              onPress={() => setShowModalConfirmUpload(true)}
              imageColletion={imageColletion}
              handleRemoveFile={handleRemoveFile}
              onFileChange={onFileChange}
            />
            <ModalConfirmUpload
              open={showModalConfirmUpload}
              handleCloseModalConfirmUpload={handleCloseModalConfirmUpload}
              onPress={handleUploadFile}
              uploadPercent={percent}
              isLoading={uploadLoading}
              title="AP Invoice"
            />
            <Item
              open={showPDFpreview}
              close={handleClosePDFpreview}
              data={apinvoice_detail}
            />
            <Service
              open={showPDFServie}
              close={handleClosePDFService}
              data={apinvoice_detail}
            />

            <Grid md={12} sm={12} xs={12}>
              <Box className={classes.formTitle}>
                <Typography
                  className={classes.formNumber}
                  variant="body1"
                  component="p"
                >
                  {`AP Invoice No. ${apinvoice_detail?.doc_num}`}
                </Typography>
                <Box className={classes.wrapperStatusForm}>
                  <Typography className={classes.bodyDataText}>
                    {dateFormater(
                      `${apinvoice_detail?.doc_date} ${apinvoice_detail?.doc_time}`,
                      'DD-MM-YYYY HH:MM'
                    )}
                  </Typography>
                  {_renderStatus(apinvoice_detail?.status)}
                </Box>
              </Box>
              <Box className={classes.borderLine} />
            </Grid>
            <Grid md={8} sm={12} xs={12}>
              <Box className={classes.wrapperInformation}>
                <Box className={classes.informationContent}>
                  <Box className={classes.informationHeader}>
                    <Typography
                      className={classes.headerText}
                      variant="body1"
                      component="p"
                    >
                      AP Invoice Information
                    </Typography>
                    <Box
                      className={classes.informationHeaderLink}
                      onClick={() =>
                        handleShowPDFpreview(apinvoice_detail?.doc_type)
                      }
                      // onClick={handleLinkPDF}
                    >
                      <img
                        className={classes.bellImage}
                        src={PdfFile}
                        alt="image not found"
                      />
                      <Typography
                        className={classes.headerLink}
                        variant="body1"
                        component="p"
                      >
                        View AP
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.infromationBody}>
                    <Grid container md={12}>
                      <Grid md={6} sm={6} xs={6}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <Typography
                              className={classes.bodyTitle}
                              variant="body1"
                              component="p"
                            >
                              GRPO No.
                            </Typography>
                          </Box>
                          <Box className={classes.bodyData}>
                            <Typography
                              className={classes.bodyDataText}
                              variant="body1"
                              component="p"
                            >
                              {apinvoice_detail?.grpo?.doc_num ?? '-'}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid md={6} sm={6} xs={6}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <Typography
                              className={classes.bodyTitle}
                              variant="body1"
                              component="p"
                            >
                              PO No.
                            </Typography>
                          </Box>
                          <Box className={classes.bodyData}>
                            <Typography
                              className={classes.bodyDataText}
                              variant="body1"
                              component="p"
                            >
                              {apinvoice_detail?.po?.doc_num ?? '-'}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classes.infromationBody}>
                    <Grid container md={12}>
                      <Grid md={6} sm={4} xs={4}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <Typography
                              className={classes.bodyTitle}
                              variant="body1"
                              component="p"
                            >
                              Vendor Name
                            </Typography>
                          </Box>
                          <Box className={classes.bodyData}>
                            <Box className={classes.boxDepartment}>
                              <Typography
                                className={classes.bodyDataText}
                                variant="body1"
                                component="p"
                              >
                                {apinvoice_detail?.customer_name ?? '-'}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid md={3} sm={4} xs={4}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <Typography
                              className={classes.bodyTitle}
                              variant="body1"
                              component="p"
                            >
                              Currency
                            </Typography>
                          </Box>
                          <Box className={classes.bodyData}>
                            <Typography
                              className={classes.bodyDataText}
                              variant="body1"
                              component="p"
                            >
                              {apinvoice_detail?.currency}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid md={3} sm={4} xs={4}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <Typography
                              className={classes.bodyTitle}
                              variant="body1"
                              component="p"
                            >
                              Grand Total
                            </Typography>
                          </Box>
                          <Box className={classes.bodyData}>
                            <Typography
                              className={classes.bodyDataText}
                              variant="body1"
                              component="p"
                            >
                              {apinvoice_detail?.currency === 'IDR'
                                ? formatRupiah(
                                    apinvoice_detail?.doc_total_amount ?? '0'
                                  )
                                : apinvoice_detail?.doc_total_amount}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classes.infromationBody}>
                    <Grid container md={12}>
                      <Grid md={6} sm={6} xs={6}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <Typography
                              className={classes.bodyTitle}
                              variant="body1"
                              component="p"
                            >
                              Department
                            </Typography>
                          </Box>
                          <Box className={classes.bodyData}>
                            <Box className={classes.boxDepartment}>
                              <Typography
                                className={classes.bodyDataText}
                                variant="body1"
                                component="p"
                              >
                                {apinvoice_detail?.po?.department?.code ?? '-'}
                              </Typography>
                              <Typography
                                className={classes.bodyDataSubText}
                                variant="body1"
                                component="p"
                              >
                                {apinvoice_detail?.po?.department?.name ?? '-'}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid md={6} sm={6} xs={6}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <Typography
                              className={classes.bodyTitle}
                              variant="body1"
                              component="p"
                            >
                              Entity
                            </Typography>
                          </Box>
                          <Box className={classes.bodyData}>
                            <Box className={classes.boxDepartment}>
                              <Typography
                                className={classes.bodyDataText}
                                variant="body1"
                                component="p"
                              >
                                {apinvoice_detail?.po?.entity?.code ?? '-'}
                              </Typography>
                              <Typography
                                className={classes.bodyDataSubText}
                                variant="body1"
                                component="p"
                              >
                                {apinvoice_detail?.po?.entity?.name ?? '-'}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box className={classes.infromationBody}>
                    <Grid container md={12}>
                      <Grid md={6} sm={6} xs={6}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <Typography
                              className={classes.bodyTitle}
                              variant="body1"
                              component="p"
                            >
                              Activity
                            </Typography>
                          </Box>
                          <Box className={classes.bodyData}>
                            <Typography
                              className={classes.bodyDataText}
                              variant="body1"
                              component="p"
                            >
                              {apinvoice_detail?.po?.pr?.items?.length > 0
                                ? apinvoice_detail?.po?.pr?.items[0]
                                    ?.activity_name ?? '-'
                                : '-'}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid md={6} sm={6} xs={6}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <Typography
                              className={classes.bodyTitle}
                              variant="body1"
                              component="p"
                            >
                              Category
                            </Typography>
                          </Box>
                          <Box className={classes.bodyData}>
                            <Typography
                              className={classes.bodyDataText}
                              variant="body1"
                              component="p"
                            >
                              {apinvoice_detail?.doc_type ?? '-'}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  {apinvoice_detail?.approval_code && (
                    <Box className={classes.infromationBody}>
                      <Grid container md={12}>
                        <Grid md={6} sm={6} xs={6}>
                          <Box className={classes.wrapperBody}>
                            <Box className={classes.bodyContent}>
                              <Typography
                                className={classes.bodyTitle}
                                variant="body1"
                                component="p"
                              >
                                Approval Code
                              </Typography>
                            </Box>
                            <Box className={classes.bodyData}>
                              <Typography
                                className={classes.bodyDataText}
                                variant="body1"
                                component="p"
                              >
                                {apinvoice_detail?.approval_code}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  
                  <Box className={classes.infromationAttachment}>
                    <Grid container>
                        {apinvoice_detail?.po?.pr?.qr_code && (
                          <Grid md={2} sm={2} xs={4} style={{ marginBottom: 24 }}>
                          <Box
                            className={classes.informationHeaderLinkSecondary}
                            onClick={() =>
                              handleSeeImage(apinvoice_detail?.po?.pr?.qr_code)
                            }
                          >
                            <ContentText
                              styling={classes.headerLink}
                              variant={'body1'}
                              content={`View PR`}
                            />
                          </Box>
                          </Grid>
                        )}
                        {apinvoice_detail?.po?.qr_code && (
                          <Grid md={2} sm={2} xs={4} style={{ marginBottom: 24 }}>
                          <Box
                            className={classes.informationHeaderLinkSecondary}
                            onClick={() =>
                              handleSeeImage(apinvoice_detail?.po?.qr_code)
                            }
                          >
                            <ContentText
                              styling={classes.headerLink}
                              variant={'body1'}
                              content={`View PO`}
                            />
                          </Box>
                          </Grid>
                        )}
                        {apinvoice_detail?.grpo?.qr_code && (
                          <Grid md={2} sm={2} xs={4} style={{ marginBottom: 24 }}>
                          <Box
                            className={classes.informationHeaderLinkSecondary}
                            onClick={() =>
                              handleSeeImage(apinvoice_detail?.grpo?.qr_code)
                            }
                          >
                            <ContentText
                              styling={classes.headerLink}
                              variant={'body1'}
                              content={`View GRPO`}
                            />
                          </Box>
                          </Grid>
                        )}
                        <Grid md={12} sm={12} xs={12} style={{ marginBottom: 24 }}>
                        <Box>
                          <Typography
                            className={classes.AttachmentTitle}
                            variant="body1"
                            component="p"
                          >
                            AP Invoce Attachment
                          </Typography>
                        </Box>
                      </Grid>

                      {/* Mandatory Menu */}
                      <Grid xs={12} md={12} container>
                        <Grid md={12}>
                          <Typography
                            className={classes.uploadTitle}
                            variant="body1"
                            component="p"
                          >
                            Support Document
                          </Typography>
                          <Box className={classes.sizeTitle} />
                          {isFileLoading ? (
                            <CircleLoading />
                          ) : !showFileData &&
                            fileData.length < 1 &&
                            canUpload ? (
                            <>
                              <Box className={classes.btnUploadContent}>
                                <Box
                                  className={classes.btnUpload}
                                  onClick={() => setShowModalUpload(true)}
                                >
                                  <Box>
                                    <img
                                      className={classes.bellImage}
                                      src={UploadBtn}
                                      alt="image not found"
                                    />
                                  </Box>
                                  <Typography
                                    className={classes.btnUploadValue}
                                    variant="body1"
                                    component="p"
                                  >
                                    Upload File
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className={classes.sizeSubLink} />
                            </>
                          ) : (
                            fileData?.map((data, index) => (
                              <>
                                <Box
                                  key={index}
                                  className={classes.wrapperLink}
                                >
                                  <Box className={classes.wrapperLink}>
                                    <Box
                                      className={classes.wrapperLinkFile}
                                      onClick={() => handleSeeImage(data?.url)}
                                    >
                                      <Box>
                                        <img
                                          src={Paper}
                                          alt="image not found"
                                        />
                                      </Box>
                                      <Typography
                                        className={classes.bodyUrlText}
                                        variant="body1"
                                        component="p"
                                      >
                                        {`${data.file_name} `}
                                      </Typography>
                                      <Typography
                                        className={classes.textFileCount}
                                        variant="body1"
                                        component="p"
                                      >
                                        {`${formatFileCount(data?.size)} MB`}
                                      </Typography>
                                    </Box>
                                    {canDelete ? (
                                      <TrashDelete
                                        onPress={() => handleDelete(data?.id)}
                                      />
                                    ) : null}
                                  </Box>
                                  {index + 1 === fileData.length &&
                                  canUpload ? (
                                    <Box
                                      className={classes.btnAddMoreUpload}
                                      onClick={() => setShowModalUpload(true)}
                                    >
                                      <img
                                        style={{ marginRight: 8 }}
                                        src={CirclePlus}
                                        alt="image not found"
                                      />
                                      <Typography
                                        className={classes.bodyDataTextBlue}
                                      >
                                        Add More Document
                                      </Typography>
                                    </Box>
                                  ) : null}
                                </Box>
                                <Box className={classes.sizeSubLink} />
                              </>
                            ))
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Grid>
            {/* <Box className={classes.borderSide} /> */}
            <Grid md={4} sm={12} xs={12} style={{ backgroundColor: '#fff' }}>
              <Box className={classes.assignApprovalContent}>
                <Box className={classes.assignHeader}>
                  <Typography
                    className={classes.headerTitle}
                    variant="body1"
                    component="p"
                  >
                    Approve By
                  </Typography>
                </Box>
                <Box className={classes.filterActifityContent}>
                  <Box>
                    <Box className={classes.bodyContent}>
                      <Typography
                        className={classes.bodyTitle}
                        variant="body1"
                        component="p"
                      >
                        Approved By
                      </Typography>
                    </Box>
                    {internal_all_IsLoading ? (
                      <Skeleton variant="text" width={'100%'} />
                    ) : (
                      <SelectFilter
                        masterData={rowFinance}
                        data={rowApproval}
                        defaultValue={aprrovalOne}
                        onChange={handleChangeApprovalOne}
                        disabled={
                          apinvoice_detail?.status == 'need_confirm' ||
                          apinvoice_detail?.status == 'rejected'
                            ? false
                            : true
                        }
                      />
                    )}
                  </Box>
                </Box>
                {apinvoice_detail?.status === 'need_confirm' ? (
                  <Box
                    className={
                      assignment_loading
                        ? classes.BtnConfirmFalse
                        : classes.BtnConfirm
                    }
                    onClick={assignment_loading ? null : handleConfirmAssigment}
                  >
                    <Typography
                      className={classes.BtnConfirmText}
                      variant="body1"
                      component="p"
                    >
                      Confirm Assignment
                    </Typography>
                  </Box>
                ) : null}
              </Box>
            </Grid>
          </Grid>
          <Box className={classes.borderLine} />
          <Grid md={12}>
            {_renderButtonAction(apinvoice_detail?.status)}

            {/* <ButtonTransparent
              setShowModalConfirm={setShowModalConfirm}
              isComplete={true}
              text={'Cancel AP Invoice'}
            /> */}
            {/* <ButtonQrCode
          showModal={() => setShowModalQRCode(true)}
          handleCanclePR={() => setShowModalConfirm(true)}
        /> */}
            {/* <ButtonCancel handleCanclePR={() => setShowModalConfirm(true)} /> */}
          </Grid>
          <HistoryTableDetail
            showHistory={isStatusNeed}
            data={apinvoice_detail?.approval_transactions}
          />
        </>
      )}
    </>
  );
};

const useStyles = makeStyles({
  formTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  borderLine: {
    borderBottom: '1px solid #E7E7E8',
  },
  formNumber: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#1B2030',
  },
  formStatus: {
    fontSize: 14,
    lineHeight: '26px',
    color: '#969BAB',
  },
  // styling status
  statusNeed: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#F12B2C',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  statusWaiting: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#FFB800',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  statusClose: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    background: '#969BAB',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  statusApprove: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    background: '#27AE60',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  statusReject: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#F12B2C',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },

  wrapperInformation: {
    paddingTop: 27,
    paddingBottom: 59,
    backgroundColor: '#fff',
  },
  informationContent: {
    // marginTop: 27,
    paddingLeft: 20,
    paddingRight: 18.5,
    backgroundColor: '#fff',
    // marginBottom: 58,
    borderRight: '1px solid #E7E7E8',
  },
  informationHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  informationHeaderLink: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
  },

  informationHeaderLinkSecondary: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: 15,
  },

  headerText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  headerLink: {
    fontSize: 14,
    lineHeight: '26px',
    fontWeight: 'bold',
    color: '#127EEE',
    marginLeft: 10,
  },
  infromationBody: {
    marginTop: 18.5,
    marginLeft: -10,
    marginRight: -10,
  },
  wrapperBody: {
    paddingRight: 10,
    paddingLeft: 10,
  },
  bodyTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
    marginBottom: 12,
  },
  bodyData: {
    width: '100%',
    backgroundColor: '#EBEFF0',
    padding: '12px 20px',
    border: '1px solid #E7E7E8',
    borderRadius: 8,
  },
  wrapperBodyGrandTotal: {},
  infromationAttachment: {
    marginTop: 30,
  },
  AttachmentTitle: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    fontWeight: 'bold',
  },
  AttachmentNotif: {
    marginTop: 20,
    marginBottom: 20,
    padding: 10,
    borderRadius: 4,
    backgroundColor: '#FFE9E8',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  AttachmentMessage: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#F12B2C',
  },
  btnUploadContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  btnUploadText: {
    fontSize: 14,
    lineHeight: '26px',
    color: '#1B2030',
  },
  btnUpload: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: '#127EEE',
    paddingLeft: 19,
    paddingRight: 19,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 8,
  },
  btnUploadValue: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
    marginLeft: 13,
  },
  assignApprovalContent: {
    paddingTop: 26,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 26,
    backgroundColor: '#fff',
    // borderBottom: '1px solid #E7E7E8',
  },
  headerTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  filterActifityContent: {
    marginTop: 20,
  },
  formControl: {
    // marginTop: 6,
    width: '100%',
    backgroundColor: '#EBEFF0',
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    paddingTop: 12,
    paddingBottom: 8,
    paddingLeft: 20,
    paddingRight: 20,
    '&.MuiFormControl-root .MuiInput-underline::before': {
      border: 'none',
      transition: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root.MuiSelect-select':
      {
        paddingTop: 2,
        paddingBottom: 2,
      },
  },
  sizeBox: {
    marginTop: 20,
  },
  btnOption: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  wrapperMandatory: {
    paddingLeft: 20,
    paddingTop: 15,
    paddingRight: 30,
    paddingBottom: 15,
    border: '1px solid #E7E7E8',
    borderRadius: 8,
  },
  sizeBoxTwo: {
    marginTop: 30,
  },
  textTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
  },
  titleContent: {
    marginBottom: 12,
  },
  supportContent: {
    marginTop: 20,
    marginBottom: 12,
  },
  uploadTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
  },
  bodyDataTextBlue: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#127EEE',
    fontWeight: 'bold',
  },
  btnAddMoreUpload: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: 15,
    cursor: 'pointer',
  },
  bodyUrlText: {
    marginLeft: 10,
    fontSize: 16,
    lineHeight: '21px',
    textDecorationLine: 'underline',
    color: '#1B2030',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 110,
  },
  wrapperLink: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  wrapperLinkFile: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    width: 250,
  },
  textFileCount: {
    marginLeft: 2,
    fontSize: 16,
    lineHeight: '21px',
    textDecorationLine: 'underline',
    color: '#1B2030',
    width: 140,
  },
  sizeTitle: {
    marginTop: 21.5,
  },
  sizeSubLink: {
    marginTop: 31,
  },
  boxDepartment: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  bodyDataSubText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    marginLeft: 4,
    marginTop: 2,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  BtnConfirm: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 14.5,
    paddingBottom: 14.5,
    backgroundColor: '#1B2030',
    borderRadius: 8,
    maxWidth: 193,
    marginTop: 20,
    cursor: 'pointer',
  },
  BtnConfirmFalse: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 14.5,
    paddingBottom: 14.5,
    backgroundColor: '#969BAB',
    borderRadius: 8,
    maxWidth: 193,
    marginTop: 20,
  },
  BtnConfirmText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
  },
  wrapperStatusForm: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
});

export default APInvoiceFormApproval;
