import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  withStyles,
  Box,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useRouter } from '../../utils/useRouter';
import Pagination from '@material-ui/lab/Pagination';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../redux/ClientVendor/actions';
import * as actions_2 from '../../redux/Utility/actions';
import { SortingASC, SortingDESC } from '../../utils/SortFunc';

// compontent
import { Row } from './TableCustom/index';
import ModalSyncSAP from '../ModalSyncSAP';
import ModalConfirm from '../ModalComfirm';
import { TabelLoading, TableNoData } from '../Loading/TableLoading';
import { CircleLoadingSecondary } from '../Loading/CircleLoading';
import CustomizedSnackbars from '../PopupMessage';
import DesktopPagination from '../Pagination/DesktopPagination';
import SortArrow from '../SortArrow';

// Icons
import Export from '../../assets/Icons/export.png';
import PlusBtn from '../../assets/Icons/plusBtn.png';

// Svg
import { DownArrow } from '../../assets/Icons/svg/DownArrow';

// generate fixed table
const StickyTableCell = withStyles((theme) => ({
  head: {
    right: 0,
    // position: 'sticky',
    // zIndex: theme.zIndex.appBar + 2,
  },
  body: {
    backgroundColor: '#ddd',
    minWidth: '50px',
    right: 0,
    position: 'sticky',
    zIndex: 99,
  },
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ClientVendorTableData = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispacth = useDispatch();

  // Create format for table and pagination
  const [rowData, setRowData] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(1);
  const [fromRow, setFromRow] = useState(1);
  const [toRow, setToRow] = useState(1);
  const [TotalRow, setTotalRow] = useState(1);
  const [limit, setLimit] = useState(10);

  const [categoryFilter, setCategoryFilter] = useState('All Category');
  const [groupcodeFilter, setGroupcodeFilter] =
    useState('All Group Code');
  const [statusFilter, setStatusFilter] = useState('All status');

  const [groupCodeData, setGroupCodeData] = useState([]);

  const [rowSAP, setRowSAP] = useState([]);
  const [tempId, setTempId] = useState(null);

  const [date, setDate] = useState({
    start: null,
    end: null,
  });

  const [showModalSync, setModalSyncSAP] = useState(false);
  const [showModalConfirm, setModalConfirm] = useState(false);

  // Global Variable
  const { access_token } = useSelector((state) => state.auth);
  const { groupcode_data, supcode_data, csv_loading } = useSelector(
    (state) => state.utility
  );
  const { getMessage, delMessage, getIsloading, data } = useSelector(
    (state) => state.clientVendor
  );

  const handleChangeCategory = (event) => {
    setCategoryFilter(event.target.value);
  };
  const handleChangeGroupCode = (event) => {
    setGroupcodeFilter(event.target.value);
  };

  const handleChangeStatus = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleChangePagination = (event, value) => {
    setPagination(value);
  };

  const handleShowModal = () => {
    setModalSyncSAP(true);
  };
  const handleCloseModal = () => {
    setModalSyncSAP(false);
  };

  const handleShowConfirm = () => {
    setModalConfirm(true);
    setModalSyncSAP(false);
  };

  const handleChangeLimit = (event) => {
    setLimit(event.target.value);
  };

  const handleCloseModalConfirm = () => {
    setModalConfirm(false);
  };

  const handleAddVendorData = () => {
    router.push('/client-vendor-create');
  };

  const handleEditVendorData = (id) => {
    router.push(`/client-vendor-edit/vendor=${id}`);
  };

  // handle message
  const [show, setShow] = useState(false);
  const [type, setType] = useState('');
  const [notifText, setNotifText] = useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };
  // end handle message

  // function request

  const handleGETClientdata = () => {
    let params = {
      status: statusFilter,
      page: pagination,
      category: categoryFilter,
      group_code_id: groupcodeFilter,
      limit: limit,
    };

    if (statusFilter === 'All status') delete params.status;

    if (categoryFilter === 'All Category') delete params.category;

    if (groupcodeFilter === 'All Group Code') delete params.group_code_id;

    dispacth(actions.getClientVendor(access_token, params));
  };

  const handleDelete = (id) => {
    dispacth(actions.deleteClientVendor(access_token, id));
  };

  const handleExportCsv = () => {
    let params = {
      doc_type: 'Client',
      status: statusFilter,
    };

    if (statusFilter === 'All status') delete params.status;

    dispacth(actions_2.exportCSV(access_token, params));
  };

  useEffect(() => {
    let params = {
      page: 1,
    };
    dispacth(actions.getClientVendor(access_token, params));
  }, []);

  useEffect(() => {
    dispacth(actions_2.getDistributorGroupCode(access_token));
    dispacth(actions_2.getSupplierGroupCode(access_token));
  }, []);

  useEffect(() => {
    handleGETClientdata();
  }, [statusFilter, pagination, groupcodeFilter, categoryFilter, limit]);

  useEffect(() => {
    let tempArr = [];

    data?.client?.data?.map((data, index) => {
      let tempData = {
        id: data?.id,
        code: data?.vendor_code ?? '-',
        name: data?.name,
        address: data?.address,
        email: data?.email,
        category: data?.category,
        groupCode: data?.group_code_id,
        status: data?.status,
        date: data?.created_at,
      };
      tempArr.push(tempData);
    });

    let countPage = Math.ceil(data?.client?.total / limit);
    setPage(countPage);
    if (pagination > countPage) {
      setPagination(countPage);
    }
    setFromRow(data?.client?.from);
    setToRow(data?.client?.to);
    setTotalRow(data?.client?.total);
    setRowData(tempArr);
  }, [data]);

  useEffect(() => {
    if (categoryFilter === 'supplier') {
      setGroupCodeData(supcode_data?.suppliergc);
    } else if (categoryFilter === 'distributor') {
      setGroupCodeData(groupcode_data?.distributorgc);
    } else {
      setGroupCodeData([]);
    }
  }, [categoryFilter]);

  useEffect(() => {
    if (delMessage) {
      if (delMessage === 'Delete client Success') {
        handleGETClientdata();
        setType('success');
        setShow(true);
        setNotifText('item deleted successfully');
      }
      dispacth(actions.setBlankDelMesage());
    }
  }, [delMessage]);

  return (
    <>
      <Grid container md={12} style={{ maxWidth: '100vw' }}>
        <Grid md={12}>
          <CustomizedSnackbars
            show={show}
            handleClose={handleClose}
            message={notifText}
            type={type}
          />
          <ModalConfirm
            open={showModalConfirm}
            setModalConfirm={setModalConfirm}
            handleCloseModalConfirm={handleCloseModalConfirm}
          />
          <ModalSyncSAP
            open={showModalSync}
            setModalSyncSAP={setModalSyncSAP}
            handleCloseModal={handleCloseModal}
            handleShowConfirm={handleShowConfirm}
            data={rowSAP}
          />
          <div className={classes.titleTableContent}>
            <Typography
              className={classes.titleTable}
              variant="body1"
              component="p"
            >
              CLIENTS/VENDOR
            </Typography>
            <div className={classes.contentRigth}>
              <div className={classes.btnAdd} onClick={handleAddVendorData}>
                <img src={PlusBtn} alt="image not found" />
                <Typography
                  className={classes.btnText}
                  variant="body1"
                  component="p"
                >
                  Add Vendor
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Box className={classes.filterContent}>
          <Box className={classes.filterActivityContent}>
            <FormControl className={classes.formControl}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={categoryFilter}
                IconComponent={() => <DownArrow />}
                onChange={handleChangeCategory}
              >
                <MenuItem value={'All Category'}>All Category</MenuItem>

                <MenuItem value={'distributor'}>
                  <Typography className={classes.bodyDataText}>
                    Distributor
                  </Typography>
                </MenuItem>
                <MenuItem value={'supplier'}>
                  <Typography className={classes.bodyDataText}>
                    Supplier
                  </Typography>
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.filterCurrencyContent}>
            <FormControl className={classes.formControl}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={groupcodeFilter}
                IconComponent={() => <DownArrow />}
                onChange={handleChangeGroupCode}
              >
                <MenuItem value={'All Group Code'}>All Group Code</MenuItem>
                {groupCodeData?.map((cat, index) => (
                  <MenuItem key={index} value={cat.id}>
                    <Typography className={classes.bodyDataText}>
                      {cat.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.filterStatusContent}>
            <FormControl className={classes.formControl}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                IconComponent={() => <DownArrow />}
                value={statusFilter}
                onChange={handleChangeStatus}
              >
                <MenuItem value={'All status'}>All Status</MenuItem>
                <MenuItem value={'valid'}>Valid</MenuItem>
                <MenuItem value={'invalid'}>Invalid</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.filterBlankArea}>{/* black area */}</Box>
          <Box className={classes.exportContent}>
            <div className={classes.btnExportBlue} onClick={handleExportCsv}>
              {csv_loading ? (
                <Box className={classes.loadingExport}>
                  <CircleLoadingSecondary />
                </Box>
              ) : (
                <img src={Export} alt="image not found" />
              )}
              <Typography
                className={classes.btnText}
                variant="body1"
                component="p"
              >
                Export .csv
              </Typography>
            </div>
          </Box>
        </Box>

        <div style={{ width: '100%' }}>
          <Grid md={12}>
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHead}>
                        ID.
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'id', setRowData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'id', setRowData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHead}>
                        Code No.
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'code', setRowData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'code', setRowData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHead}>
                        Name
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'name', setRowData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'name', setRowData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHead}>
                        Address
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'address', setRowData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'address', setRowData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHead}>
                        Email
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'email', setRowData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'email', setRowData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHead}>
                        Category
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'category', setRowData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'category', setRowData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHead}>
                        Group Code
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'groupCode', setRowData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'groupCode', setRowData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHead}>
                        Status
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'status', setRowData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'status', setRowData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell className={classes.head} numeric>
                      <Box className={classes.wrapperTableHead}>
                        Date
                        <SortArrow
                          pressUp={() =>
                            SortingASC(rowData, 'date', setRowData)
                          }
                          pressDown={() =>
                            SortingDESC(rowData, 'date', setRowData)
                          }
                        />
                      </Box>
                    </StyledTableCell>
                    <StickyTableCell className={classes.head}>
                      <StyledTableCell className={classes.head} numeric>
                        Action
                      </StyledTableCell>
                    </StickyTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getIsloading ? null : rowData.length > 0 ? (
                    rowData?.map((row, index) => (
                      <Row
                        key={index}
                        index={index}
                        row={row}
                        handleDelete={handleDelete}
                        handleEdit={handleEditVendorData}
                      />
                    ))
                  ) : (
                    <TableNoData colspan={12} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {getIsloading ? <TabelLoading /> : null}
          </Grid>
        </div>
        {getIsloading ? null : rowData?.length > 0 ? (
          <DesktopPagination
            toRow={toRow}
            fromRow={fromRow}
            TotalRow={TotalRow}
            page={page}
            handleChangePagination={handleChangePagination}
            limit={limit}
            handleChangeLimit={handleChangeLimit}
            pagination={pagination}
          />
        ) : null}
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  // cutome table
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  wrapperTableHead: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  head: {
    backgroundColor: '#fff',
    fontSize: 16,
    lineHeight: '30px',
    fontWeight: 'bold',
    color: '#000000',
    borderBottom: 'none',
    '&:nth-child(1)': {
      minWidth: 90,
      paddingLeft: '20px !important',
      borderBottom: 'none',
    },
    '&:nth-child(2)': {
      minWidth: 120,
    },
    '&:nth-child(3)': {
      minWidth: 180,
    },
    '&:nth-child(4)': {
      minWidth: 214,
    },
    '&:nth-child(5)': {
      minWidth: 133,
    },
    '&:nth-child(6)': {
      minWidth: 133,
    },
    '&:nth-child(7)': {
      minWidth: 150,
    },
    '&:nth-child(8)': {
      minWidth: 120,
    },
    '&:nth-child(9)': {
      minWidth: 180,
    },
  },
  tableContainer: {
    // maxHeight: '400px',
    Width: '100%',
    '&.MuiTableContainer-root .MuiTableRow-root .MuiTableCell-root.MuiTableCell-head':
      {
        paddingLeft: 8,
      },
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
  },
  cell: {
    minWidth: '100px',
  },
  // end custom table
  titleTableContent: {
    paddingRight: 22,
    paddingLeft: 22,
    paddingTop: 27,
    paddingBottom: 27,
    backgroundColor: '#ffff',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleTable: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  contentRigth: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  btnAdd: {
    backgroundColor: '#1B2030',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '14px 14px',
    borderRadius: 8,
    cursor: 'pointer',
  },
  btnExport: {
    backgroundColor: '#127EEE',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '14px 20px',
    borderRadius: 8,
    cursor: 'pointer',
  },
  btnExportBlue: {
    backgroundColor: '#127EEE',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 14px',
    borderRadius: 8,
    cursor: 'pointer',
    maxHeight: 40,
    maxWidth: 154,
  },
  btnText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
    marginLeft: 8,
  },
  notifContent: {
    marginRight: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  notifText: {
    fontWeight: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#1B2030',
    marginLeft: 13,
  },

  iconAction: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 20,
  },
  Action: {
    cursor: 'pointer',
  },
  tableContent: {
    backgroundColor: '#fff',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderBottom: 'none !important',
    position: 'relative',
    maxWidth: 1440,
  },
  tableHead: {
    fontSize: 16,
    lineHeight: '30px',
    color: '#000000',
    fontWeight: 'bold',
    // position: 'relative',
    // maxWidth: 1440,
    overflowX: 'scroll',

    '&.MuiTableRow-root .MuiTableCell-root': {
      borderBottom: 'none',
      fontSize: 16,
      lineHeight: '30px',
      color: '#000000',
      fontWeight: 'bold',
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 16,
      paddingBottom: 16,
    },
    '&.MuiTableRow-root .MuiTableCell-head:nth-last-child(1)': {
      position: '-webkit-sticky',
      position: 'sticky',
      background: '#fff',
      left: 0,
      zIndex: 1,
    },
  },
  // Pagination styling
  descTable: {
    paddingTop: 11.5,
    paddingBottom: 11.5,
  },
  descTableText: {
    color: '#969BAB',
    fontSize: 16,
    lineHeight: '21px',
  },
  paginationContent: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pagination: {
    borderRadius: 0,
    '&.MuiPagination-root .MuiPagination-ul .MuiPaginationItem-root': {
      margin: 0,
    },
    borderRadius: 0,
    '&.MuiPagination-root .MuiPagination-ul .MuiPaginationItem-rounded': {
      borderRadius: 0,
    },
    '&.MuiPagination-root .MuiPagination-ul .MuiButtonBase-root': {
      backgroundColor: '#fff',
      color: '#969BAB',
    },
    '&.MuiPagination-root .MuiPagination-ul .MuiButtonBase-root.Mui-selected': {
      backgroundColor: '#1B2030',
      color: '#fff',
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-child(1)': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-child(1) button': {
      color: '#1B2030',
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-last-child(1)': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
    '&.MuiPagination-root .MuiPagination-ul li:nth-last-child(1) button': {
      color: '#1B2030',
    },
  },
  wrapperMenuContent: {
    width: '100%',
  },
  // Filter Content
  filterContent: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: '#fff',
    borderTop: '1px solid #E7E7E8',
    borderBottom: '1px solid #E7E7E8',
    width: '100%',
    overflow: 'auto',
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&::-webkit-scrollbar': {
      width: '0.4em',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
    },
    // position: 'relative',
  },
  dateContent: {
    width: '100%',
    maxWidth: 225,
    // backgroundColor: 'red',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingRight: 5,
  },
  dateRangeIcon: {
    width: 58,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E7E7E8',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  containerDate: {
    width: `100%`,
    maxWidth: `calc(100% - 58px)`,
  },
  // styling Form select
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 5,
    paddingBottom: 5,
    // maxWidth: 220,
    width: '100%',
    // minWidth: 160,
    maxHeight: 40,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      // marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#969BAB',
      paddingRight: 2,
      background: 'none',
    },
  },
  filterActivityContent: {
    paddingLeft: 5,
    paddingRight: 5,
    width: '100%',
    maxWidth: 170,
    minWidth: 170,
  },

  filterCurrencyContent: {
    paddingLeft: 5,
    paddingRight: 5,
    width: '100%',
    maxWidth: 170,
    minWidth: 170,
  },
  filterStatusContent: {
    paddingLeft: 5,
    paddingRight: 5,
    width: '100%',
    maxWidth: 170,
    minWidth: 170,
  },
  exportContent: {
    paddingRight: 20,
    width: '100%',
    maxWidth: 164,
    minWidth: 154,
    position: 'absolute',
    right: 0,
    zIndex: 90,
    backgroundColor: '#fff',
  },
  filterBlankArea: {
    minWidth: 150,
  },
  // styling menu select
  boxDepartment: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  bodyDataSubText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    marginLeft: 4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  loadingExport: {
    width: 20,
    height: 20,
    maxHeight: 20,
    maxWidth: 20,
  },
}));

const rows = [
  {
    id: '00001',
    code: 'V059009',
    name: 'Citra Van Titipan, PT',
    address: 'Jl Randen Saleh Raya No.2 Kec Cigugur Tengah Kota Cimahi',
    email: 'citravtk@support.id',
    category: 'Distributor',
    groupCode: 'Distributors',
    department: 'FINC',
    entity: 'DKTI',
    activity: 'TV Commercial',
    currency: 'IDR',
    total: '3150000',
    status: 'Valid',
    date: '12/07/2021 09:34',
  },
  {
    id: '00002',
    code: 'V059009',
    name: 'Citra Van Titipan, PT',
    address: 'Jl Randen Saleh Raya No.2 Kec Cigugur Tengah Kota Cimahi',
    email: 'citravtk@support.id',
    category: 'Distributor',
    groupCode: 'Distributors',
    department: 'FINC',
    entity: 'DKTI',
    activity: 'TV Commercial',
    currency: 'IDR',
    total: '3150000',
    status: 'Valid',
    date: '12/07/2021 09:34',
  },
  {
    id: '00003',
    code: 'V059009',
    name: 'Citra Van Titipan, PT',
    address: 'Jl Randen Saleh Raya No.2 Kec Cigugur Tengah Kota Cimahi',
    email: 'citravtk@support.id',
    category: 'Distributor',
    groupCode: 'Distributors',
    department: 'FINC',
    entity: 'DKTI',
    activity: 'TV Commercial',
    currency: 'IDR',
    total: '3150000',
    status: 'invalid',
    date: '12/07/2021 09:34',
  },
];
export default ClientVendorTableData;
