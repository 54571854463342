import React from 'react';
import { makeStyles, Grid, Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useRouter } from '../../utils/useRouter';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../redux/PR/actions';
import * as actions_2 from '../../redux/Utility/actions';
import * as settings from 'src/redux/Settings/actions';
import * as userManagement from '../../redux/UserManagement/actions';
import { formatFileCount, formatRupiah } from '../../utils/formatter';
import _, { get } from 'lodash';
import axios from 'axios';
import { END_POINT } from '../../config/EndPoint';
import { donwloadDoc } from '../../utils/donwloadDoc';

// Components
import ButtonTransparent from '../Button/ButtonTransparent';
import HistoryTableDetail from '../HistoryTableDetail';
import ButtonQrCode from '../Button/ButtonQrCode';
import ButtonCancel from '../Button/ButtonCancel';
import ModalCancelPr from '../ModalCancelPr';
import PRpreview from '../PDFpreview/PRpreview';
import ModalSuccess from '../ModalSuccess';
import ModalQRCode from '../ModalQRCode';
import ModalUpload from '../ModalUpload';
import ModalConfirmUpload from '../ModalConfirmUpload';
import { CircleLoading } from '../Loading/CircleLoading';
import SKPRDetail from '../Skeleton/SKPRDetail';
import CustomizedSnackbars from '../PopupMessage';
import TrashDelete from '../TrashDelete';
import ModalDeleteItem from '../ModalDeleteItem';
import SelectFilter from '../SelectFilter';
import { ContentText } from '../../libs/Typography';

//config
import globalConfig from 'src/config/global';

// Icons
import PdfFile from '../../assets/Icons/pdfIcons.png';
import Cross from '../../assets/Icons/cross.png';
import UploadBtn from '../../assets/Icons/uploadBtn.png';
import Paper from '../../assets/Icons/paper.png';
import CirclePlus from '../../assets/Icons/circlePlus.png';
import Lib from 'src/utils/Lib';

const PrFormApproval = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispacth = useDispatch();
  const { id } = router.query;

  // Global Variable
  const { access_token, data } = useSelector((state) => state.auth);
  const {
    pr_detail,
    detail_message,
    detail_isLoading,
    cancel_prMessage,
    cancel_prLoading,
    assignment_prloading,
    assigment_prmessage,
  } = useSelector((state) => state.pr);
  const {
    qrcode_data,
    qrcode_loading,
    read_loading,
    read_data,
    del_file_isLoading,
    del_file_message,
  } = useSelector((state) => state.utility);
  const { internal_all_data, internal_all_IsLoading } = useSelector(
    (state) => state.userManagement
  );

  // initial state
  const [fileData, setFileData] = React.useState([]);
  const [showFileData, setShowFileData] = React.useState(false);

  const [aprrovalOne, setArpprovalOne] = React.useState(null);
  const [aprrovalTwo, setArpprovalTwo] = React.useState(null);
  const [aprrovalThree, setArpprovalThree] = React.useState(null);
  const [aprrovalFour, setArpprovalFour] = React.useState(null);

  const [tempDeleteId, setTempDeleteId] = React.useState(0);
  const [company, setCompany] = React.useState(null);
  const [rowApproval, setRowApproval] = React.useState(null);
  const [rowApprovalFirstTwo, setRowApprovalFirstTwo] = React.useState(null);

  // handle Render By Status
  const [isNeed, seIsNeed] = React.useState(false);
  const [btnQRon, setBtnQRon] = React.useState(false);
  const [canDownloadPDF, setCanDownloadPDF] = React.useState(false);
  const [canUpload, setCanUpload] = React.useState(false);
  const [canDelete, setCanDelete] = React.useState(false);
  const [IsFileLoading, setIsFileLoading] = React.useState(false);

  // handleshow modal
  const [showModalConfirm, setShowModalConfirm] = React.useState(false);
  const [showModalSuccess, setShowModalSuccess] = React.useState(false);
  const [showModalSuccessUpload, setShowModalSuccessUpload] =
    React.useState(false);
  const [showModalQRCode, setShowModalQRCode] = React.useState(false);
  const [showModalUpload, setShowModalUpload] = React.useState(false);
  const [PreviewPDF, setPreviewPDF] = React.useState(false);
  const [isStatusNeed, setIsStatusNeed] = React.useState(false);
  const [showModalConfirmUpload, setShowModalConfirmUpload] =
    React.useState(false);
  const [showModalDelete, setShowModalDelete] = React.useState(false);
  const [showModalSuccesDel, setShowModalSuccessDel] = React.useState(false);

  // handle message
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };

  // end handle Message

  // configure to handle File for upload PR
  const [imageColletion, setImageCollection] = React.useState([]);
  const [uploadLoading, setUploadLoading] = React.useState(false);
  const [percent, setPercent] = React.useState(0);
  const [totalFile, setTotalFile] = React.useState(0);
  const [uploadedFile, setUploadedFile] = React.useState(0);

  const onFileChange = (e) => {
    let tempFile = e.target.files;

    setImageCollection((prev) => [...prev, ...tempFile]);
  };

  const handleRemoveFile = (indexOf) => {
    let filteImage = imageColletion.filter((img, index) => index !== indexOf);

    setImageCollection(filteImage);
  };

  const handleUploadFile = () => {
    if (imageColletion) {
      try {
        setUploadLoading(true);

        const sendData = new FormData();
        imageColletion.map((img, index) => {
          sendData.append('file[]', img);
        });

        const config = {
          headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${access_token}`,
          },
          onUploadProgress: (progressEvent) => {
            var percentComplete = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setPercent(percentComplete);
          },
        };

        axios
          .post(
            `${END_POINT}files?doc_type=${'PR'}&doc_id=${
              pr_detail?.id
            }&entity_code=${pr_detail?.entity?.code}`,
            sendData,
            config
          )
          .then((res) => {
            setPercent(0);
            setUploadLoading(false);
            setShowModalConfirmUpload(false);
            setShowModalUpload(false);
            setImageCollection([]);
            handleGETFilePR();
            setShowModalSuccessUpload(true);
          });
      } catch (error) {
        console.log('error upload file');
        setUploadLoading(false);
      }
    } else {
      console.log('chosses an image');
    }
  };

  // end configure file upload

  // handel modal
  const handleCloseModalConfirm = () => {
    setShowModalConfirm(false);
  };
  const handleCloseModalSuccess = () => {
    setShowModalSuccess(false);
  };
  const handleCloseModalSuccessUpload = () => {
    setShowModalSuccessUpload(false);
  };
  const handleCloseUploading = () => {
    setShowModalUpload(false);
    setShowModalConfirmUpload(false);
    setShowModalSuccessUpload(false);
  };
  const handleCloseModalQRCode = () => {
    setShowModalQRCode(false);
  };
  const handleCloseModalUpload = () => {
    setShowModalUpload(false);
  };
  const handleCloseModalConfirmUpload = () => {
    setShowModalConfirmUpload(false);
  };
  const handleClosePreviewPDF = () => {
    setPreviewPDF(false);
  };

  const baseHandleChangeApproval = (newValue, setFn) => {
    const setVal = newValue?.id ?? null;
    setFn(setVal);
  }

  // handle option select admin
  const handleChangeApprovalOne = (event, newValue) => {
    baseHandleChangeApproval(newValue, setArpprovalOne);
  };

  const handleChangeApprovalTwo = (event, newValue) => {
    baseHandleChangeApproval(newValue, setArpprovalTwo);
  };

  const handleChangeApprovalThree = (event, newValue) => {
    baseHandleChangeApproval(newValue, setArpprovalThree);
  };

  const handleChangeApprovalFour = (event, newValue) => {
    baseHandleChangeApproval(newValue, setArpprovalFour);
  };

  // conigure btn action
  const handleCancelPR = (id) => {
    if (data?.user?.role.length > 0) {
      if (data?.user?.role[0] == 'admin' || data?.user?.role[0] == 'staff') {
        setType('error');
        setShow(true);
        setNotifText('you not have permission to take this action');
      } else {
        dispacth(actions.cancelPR(access_token, id));
      }
    }
  };

  const handleSeeImage = (url) => {
    window.open(url, '_blank');
  };

  const handleCloseQR = () => {
    setShowModalQRCode(false);
    dispacth(actions.getPRDetail(access_token, id));
  };

  const handleGETFilePR = () => {
    setIsFileLoading(true);
    let readData = {
      doc_type: 'PR',
      doc_id: pr_detail?.id,
      entity_code: pr_detail?.entity?.code,
    };

    dispacth(actions_2.readFile(access_token, readData));
  };

  const handleConfirmAssigment = () => {
    const data = [];

    if (aprrovalFour) {
      data.push({
        transaction_type_id: '2',
        doc_id: pr_detail?.id,
        user_id: aprrovalFour,
        level: '4',
      });
    }

    if (aprrovalThree) {
      data.push({
        transaction_type_id: '2',
        doc_id: pr_detail?.id,
        user_id: aprrovalThree,
        level: '3',
      });
    }

    if (aprrovalTwo) {
      data.push({
        transaction_type_id: '2',
        doc_id: pr_detail?.id,
        user_id: aprrovalTwo,
        level: '2',
      });
    }

    if (aprrovalOne) {
      data.push({
        transaction_type_id: '2',
        doc_id: pr_detail?.id,
        user_id: aprrovalOne,
        level: '1',
      });
    }

    dispacth(actions.assigmentPR(access_token, pr_detail?.id, data));
  };

  const handleDelete = (id) => {
    setTempDeleteId(id);
    setShowModalDelete(true);
  };
  const reqDeleteFile = () => {
    dispacth(actions_2.delFile(access_token, tempDeleteId));
  };
  const handleSuccesDelete = () => {
    handleGETFilePR();
    setShowModalSuccessDel(false);
  };

  React.useEffect(() => {
    dispacth(actions_2.NullFile());
    setIsFileLoading(false);
    setFileData([]);
    dispacth(actions.getPRDetail(access_token, id));
  }, []);

  React.useEffect(() => {
    if (
      pr_detail?.status !== 'need_confirm' &&
      pr_detail?.approval_transactions?.length > 0
    ) {
      setIsStatusNeed(true);
    } else {
      setIsStatusNeed(false);
    }

    if (
      pr_detail?.status === 'need_confirm' ||
      pr_detail?.status === 'rejected'
    ) {
      seIsNeed(true);
    } else {
      seIsNeed(false);
    }

    if (
      pr_detail?.status === 'need_confirm' ||
      pr_detail?.status === 'rejected'
    ) {
      setCanDelete(true);
      setCanUpload(true);
    } else {
      setCanDelete(false);
      setCanUpload(false);
    }

    pr_detail?.qr_code ? setBtnQRon(false) : setBtnQRon(true);
    pr_detail?.qr_code ? setCanDownloadPDF(true) : setCanDownloadPDF(false);

    let tempCompany = {};

    handleGETFilePR();
    if(pr_detail?.entity?.color){
      dispacth(settings.setBackgroundColor(pr_detail?.entity?.color))
    }
    // for get user internal
    dispacth(userManagement.getInternalAllUser(access_token));
  }, [pr_detail]);

  //unmount
  React.useEffect(()=>{
    return () => dispacth(settings.setBackgroundColor(''))
  },[])

  // Generarte Qr
  // Change with url Stating or Production late
  let url = `https://dkt.senisites.com/purchase-requistor-detail/prId=${pr_detail?.id}`;

  const generateQrCode = () => {
    let sendData = {
      doc_type: 'PR',
      entity_id: pr_detail?.entity_id,
      doc_id: pr_detail?.id,
      url,
    };

    dispacth(actions_2.getQrCode(access_token, sendData));

    setShowModalQRCode(true);
  };

  React.useEffect(() => {
    if (cancel_prMessage) {
      if (
        cancel_prMessage === 'Update cancel status purchase requisition Success'
      ) {
        setShowModalConfirm(false);
        setShowModalSuccess(true);
      } else if (
        cancel_prMessage !== '' &&
        cancel_prMessage !== 'Update cancel status purchase requisition Success'
      ) {
        setType('error');
        setShow(true);
        setNotifText(cancel_prMessage);
      }
      dispacth(actions.setBlankCancel());
    }
  }, [cancel_prMessage]);

  React.useEffect(() => {
    if (read_data && read_data.length > 0) {
      setShowFileData(true);
      setFileData(read_data);
    } else {
      setShowFileData(false);
      setFileData([]);
    }
    setIsFileLoading(false);
  }, [read_data]);

  React.useEffect(() => {
    if (assigment_prmessage) {
      router.push('/purchase-requistor');
      dispacth(actions.setAssigmentMessage());
    }
  }, [assigment_prmessage]);

  React.useEffect(() => {
    if (del_file_message) {
      if (del_file_message === 'Delete file Success') {
        setShowModalDelete(false);
        setShowModalSuccessDel(true);
      } else if (
        del_file_message !== '' &&
        del_file_message !== 'Delete file Success'
      ) {
        setType('error');
        setShow(true);
        setNotifText(del_file_message);
      }
      dispacth(actions_2.setBlankDelFile());
    }
  }, [del_file_message]);

  const setApprovalDropdownAutocomplete = () => {
    const filterUser = internal_all_data?.filter(
      (user) =>
        user?.id !== aprrovalOne &&
        user?.id !== aprrovalTwo &&
        user?.id !== aprrovalThree &&
        user?.id !== aprrovalFour
    );

    const filterFirstTwo = internal_all_data?.filter(
      (user) =>
        user?.id !== aprrovalThree &&
        user?.id !== aprrovalFour
    );

    setRowApproval(filterUser);
    setRowApprovalFirstTwo(filterFirstTwo);
  }

  const findApprovalTransactionUserId = (level) => {
    return pr_detail?.approval_transactions?.find(v=>v.level===level)?.user?.id;
  }

  React.useEffect(() => {
    if (internal_all_data) {
      setArpprovalOne(
        findApprovalTransactionUserId(1) ?? null
      );
      setArpprovalTwo(findApprovalTransactionUserId(2) ?? null);
      setArpprovalThree(
        findApprovalTransactionUserId(3) ?? null
      );
      setArpprovalFour(
        findApprovalTransactionUserId(4) ?? null
      );
    }
  }, [internal_all_data]);

  React.useEffect(() => {
    setApprovalDropdownAutocomplete();
  }, [aprrovalOne, aprrovalTwo, aprrovalThree, aprrovalFour]);

  // for render styling
  const _renderStatus = (status) => {
    if (status === 'need_confirm') {
      return (
        <>
          <ContentText
            content={'Confirm Approval'}
            styling={classes.statusNeed}
          />
        </>
      );
    } else if (status === 'waiting') {
      return (
        <>
          <ContentText
            content={'Waiting Approval'}
            styling={classes.statusWaiting}
          />
        </>
      );
    } else if (status === 'cancelled' || status === 'closed') {
      return (
        <>
          <ContentText content={status} styling={classes.statusClose} />
        </>
      );
    } else if (status === 'approved') {
      return <ContentText content={status} styling={classes.statusApprove} />;
    } else if (status === 'rejected') {
      return <ContentText content={status} styling={classes.statusReject} />;
    }
  };

  const _renderButtonAction = (status) => {
    
    if (status === 'closed' || status === 'approved') {
      return null
      // return (
      //   <ButtonQrCode
      //     isOn={btnQRon}
      //     onPress={generateQrCode}
      //     showModal={() => setShowModalQRCode(true)}
      //     handleCanclePR={() => setShowModalConfirm(true)}
      //     hiddenCancel={globalConfig.cancelButton.hideOnStatus.includes(status) || !Lib.allowCancel(data)}
      //     title="PR"
      //   />
      // );
    } else if (status === 'need_confirm' || status === 'waiting') {
      return (
        <ButtonTransparent
          onSubmit={() => setShowModalConfirmUpload(true)}
          setShowModalConfirm={setShowModalConfirm}
          user={data?.user}
          hiddenCancel={!Lib.allowCancel(data)}
        />
      );
    } else if (status === 'rejected' && Lib.allowCancel(data)) {
      return (
        <ButtonCancel
          title={'PR'}
          handleCanclePR={() => setShowModalConfirm(true)}
          approval={pr_detail?.approval_transactions}
        />
      );
    }
  };

  return (
    <>
      {detail_isLoading ? (
        <SKPRDetail />
      ) : (
        <>
          <Grid container style={{ background: '#fff' }}>
            <CustomizedSnackbars
              show={show}
              handleClose={handleClose}
              message={notifText}
              type={type}
            />
            {/* modal delete */}
            <ModalDeleteItem
              open={showModalDelete}
              close={() => setShowModalDelete(false)}
              id={tempDeleteId}
              onPress={reqDeleteFile}
              isLoading={del_file_isLoading}
            />
            <ModalSuccess
              open={showModalSuccesDel}
              onPress={handleSuccesDelete}
            />
            {/* for cancel pr */}
            <ModalSuccess
              open={showModalSuccess}
              setModalConfirm={setShowModalSuccess}
              // handleCloseModalSuccess={handleCloseModalSuccess}
              onPress={() => router.push('/purchase-requistor')}
            />
            {/* for uploading file */}
            <ModalSuccess
              open={showModalSuccessUpload}
              setModalConfirm={setShowModalSuccessUpload}
              // handleCloseModalSuccess={handleCloseModalSuccessUpload}
              onPress={handleCloseUploading}
            />
            <ModalCancelPr
              open={showModalConfirm}
              setModalConfirm={setShowModalConfirm}
              handleCloseModalConfirm={handleCloseModalConfirm}
              onPress={() => handleCancelPR(pr_detail?.id)}
              isLoading={cancel_prLoading}
            />
            <PRpreview
              open={PreviewPDF}
              close={handleClosePreviewPDF}
              data={pr_detail}
            />
            <ModalQRCode
              open={showModalQRCode}
              // handleCloseModalQRCode={handleCloseModalQRCode}
              onSeePriview={() => setPreviewPDF(true)}
              onClose={handleCloseQR}
              title="PR"
              qrCode={qrcode_data}
              isLoading={qrcode_loading}
              no={pr_detail?.doc_num ?? '-'}
              name={pr_detail?.purpose_of_request ?? '-'}
              activity={pr_detail?.items[0]?.activity_name ?? '-'}
              total={pr_detail?.grand_total ?? '0'}
            />
            <ModalUpload
              open={showModalUpload}
              handleCloseModalUpload={handleCloseModalUpload}
              onPress={() => setShowModalConfirmUpload(true)}
              imageColletion={imageColletion}
              handleRemoveFile={handleRemoveFile}
              onFileChange={onFileChange}
            />
            <ModalConfirmUpload
              open={showModalConfirmUpload}
              handleCloseModalConfirmUpload={handleCloseModalConfirmUpload}
              onPress={handleUploadFile}
              uploadPercent={percent}
              isLoading={uploadLoading}
              title="PR"
            />
            <Grid container>
              <Grid md={6}>
                <Box className={classes.formTitle}>
                  <ContentText
                    content={`PR No. ${pr_detail?.doc_num}`}
                    styling={classes.formNumber}
                  />
                </Box>
              </Grid>
              <Grid md={6}>
                <Box className={classes.descTitle}>
                  <ContentText
                    content={`${pr_detail?.doc_date} ${pr_detail?.doc_time}`}
                    styling={classes.bodyDataText}
                  />
                  {_renderStatus(pr_detail?.status)}
                </Box>
              </Grid>
              <Box className={classes.borderLine} />
            </Grid>
            <Grid md={8} style={{ backgroundColor: '#fff' }}>
              <Box className={classes.wrapperInformation}>
                <Box className={classes.informationContent}>
                  <Box className={classes.informationHeader}>
                    <ContentText
                      content={`PR Information`}
                      styling={classes.headerText}
                    />
                    {canDownloadPDF ? (
                      <Box
                        className={classes.informationHeaderLink}
                        onClick={() => handleSeeImage(pr_detail?.qr_code)}
                      >
                        <img
                          className={classes.bellImage}
                          src={PdfFile}
                          alt="image not found"
                        />
                        <ContentText
                          content={`View PR`}
                          styling={classes.headerLink}
                        />
                      </Box>
                    ) : (
                      <Box
                        className={classes.informationHeaderLink}
                        onClick={() => setPreviewPDF(true)}
                      >
                        <img
                          className={classes.bellImage}
                          src={PdfFile}
                          alt="image not found"
                        />
                        <ContentText
                          content={`View PR`}
                          styling={classes.headerLink}
                        />
                      </Box>
                    )}
                  </Box>
                  <Box className={classes.infromationBody}>
                    <Grid container md={12}>
                      <Grid md={8} sm={6} xs={6}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <ContentText
                              content={`Purpose of Request`}
                              styling={classes.bodyTitle}
                              variant={'body1'}
                            />
                          </Box>
                          <Box className={classes.bodyData}>
                            <ContentText
                              content={pr_detail?.purpose_of_request ?? '-'}
                              styling={classes.bodyDataText}
                              variant={'body1'}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid md={4} sm={6} xs={6}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <ContentText
                              content={`Grand Total`}
                              styling={classes.bodyTitle}
                              variant={'body1'}
                            />
                          </Box>
                          <Box className={classes.bodyData}>
                            <ContentText
                              content={
                                pr_detail?.currency == 'IDR'
                                  ? formatRupiah(pr_detail?.grand_total ?? '0')
                                  : pr_detail?.grand_total
                              }
                              styling={classes.bodyDataText}
                              variant={'body1'}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classes.infromationBody}>
                    <Grid container md={12}>
                      <Grid md={4} sm={6} xs={6}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <ContentText
                              content={`Department`}
                              styling={classes.bodyTitle}
                              variant={'body1'}
                            />
                          </Box>
                          <Box className={classes.bodyData}>
                            <Box className={classes.boxDepartment}>
                              <ContentText
                                content={pr_detail?.department?.code}
                                styling={classes.bodyDataText}
                                variant={'body1'}
                              />
                              <ContentText
                                content={`- ${pr_detail?.department?.name}`}
                                styling={classes.bodyDataSubText}
                                variant={'body1'}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid md={4} sm={6} xs={6}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <ContentText
                              content={`Entity`}
                              styling={classes.bodyTitle}
                              variant={'body1'}
                            />
                          </Box>
                          <Box className={classes.bodyData}>
                            <ContentText
                              content={pr_detail?.entity?.name}
                              styling={classes.bodyDataText}
                              variant={'body1'}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid md={4} xs={6}>
                        <Box className={classes.wrapperBody}>
                          <Box className={classes.bodyContent}>
                            <ContentText
                              content={`Activity`}
                              styling={classes.bodyTitle}
                              variant={'body1'}
                            />
                          </Box>
                          <Box className={classes.bodyData}>
                            <ContentText
                              content={pr_detail?.items[0]?.activity_name}
                              styling={classes.bodyDataTextActivity}
                              variant={'body1'}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  {pr_detail?.approval_code ? (
                    <Box className={classes.infromationBody}>
                      <Grid container md={12}>
                        <Grid md={4}>
                          <Box className={classes.wrapperBody}>
                            <Box className={classes.bodyContent}>
                              <ContentText
                                content={`Approval Code`}
                                styling={classes.bodyTitle}
                                variant={'body1'}
                              />
                            </Box>
                            <Box className={classes.bodyData}>
                              <Box className={classes.boxDepartment}>
                                <ContentText
                                  content={pr_detail?.approval_code ?? '-'}
                                  styling={classes.bodyDataText}
                                  variant={'body1'}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : null}
                  <Box className={classes.infromationAttachment}>
                    <Grid container>
                      <Grid md={12}>
                        {!isNeed ? (
                          <ContentText
                            content={'Attachments'}
                            styling={classes.AttachmentTitle}
                          />
                        ) : (
                          <Box>
                            <ContentText
                              content={'PR Attachment'}
                              styling={classes.AttachmentTitle}
                            />
                          </Box>
                        )}
                        <Box className={classes.sizeTitle} />
                      </Grid>
                      {read_loading || IsFileLoading ? null : (
                        <Grid md={12}>
                          {fileData.length < 1 ? (
                            <Box className={classes.AttachmentNotif}>
                              <ContentText
                                content={`No document attached.`}
                                styling={classes.AttachmentMessage}
                                variant={'body1'}
                              />
                              <Box>
                                <img
                                  className={classes.bellImage}
                                  src={Cross}
                                  alt="image not found"
                                />
                              </Box>
                            </Box>
                          ) : null}
                        </Grid>
                      )}
                      <Grid md={12}>
                        {read_loading || IsFileLoading ? (
                          <CircleLoading />
                        ) : !showFileData &&
                          fileData?.length < 1 &&
                          canUpload ? (
                          <>
                            <Box className={classes.btnUploadContent}>
                              <Box
                                className={classes.btnUpload}
                                onClick={() => setShowModalUpload(true)}
                              >
                                <Box>
                                  <img
                                    className={classes.bellImage}
                                    src={UploadBtn}
                                    alt="image not found"
                                  />
                                </Box>
                                <ContentText
                                  content={`Upload File`}
                                  styling={classes.btnUploadValue}
                                  variant={'body1'}
                                />
                              </Box>
                            </Box>
                            <Box className={classes.sizeSubLink} />
                          </>
                        ) : (
                          fileData?.map((data, index) => (
                            <>
                              <Box key={index} className={classes.wrapperLink}>
                                <Box className={classes.wrapperLink}>
                                  <Box
                                    className={classes.wrapperLinkFile}
                                    onClick={() => handleSeeImage(data?.url)}
                                  >
                                    <Box>
                                      <img src={Paper} alt="image not found" />
                                    </Box>
                                    <ContentText
                                      content={`${
                                        data.file_name
                                      } ${formatFileCount(data?.size)} MB`}
                                      styling={classes.bodyUrlText}
                                      variant={'body1'}
                                    />
                                  </Box>
                                  {canDelete ? (
                                    <TrashDelete
                                      onPress={() => handleDelete(data?.id)}
                                    />
                                  ) : null}
                                </Box>
                                {index + 1 === fileData.length && canUpload ? (
                                  <Box
                                    className={classes.btnAddMoreUpload}
                                    onClick={() => setShowModalUpload(true)}
                                  >
                                    <img
                                      style={{ marginRight: 8 }}
                                      src={CirclePlus}
                                      alt="image not found"
                                    />
                                    <ContentText
                                      content={`Add More Document`}
                                      styling={classes.bodyDataTextBlue}
                                      variant={'body1'}
                                    />
                                  </Box>
                                ) : null}
                              </Box>
                              <Box className={classes.sizeSubLink} />
                            </>
                          ))
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Grid>
            {/* <Box className={classes.borderSide} /> */}
            <Grid md={4} sm={12} xs={12}>
              <Box className={classes.assignApprovalContent}>
                <Box className={classes.assignHeader}>
                  <ContentText
                    content={`Approve By`}
                    styling={classes.headerTitle}
                    variant={'body1'}
                  />
                </Box>
                <Box className={classes.filterActifityContent}>
                  <Box>
                    <Box className={classes.bodyContent}>
                      <ContentText
                        content={`Prepared`}
                        styling={classes.bodyTitle}
                        variant={'body1'}
                      />
                    </Box>
                    {internal_all_IsLoading ? (
                      <Skeleton variant="text" width={'100%'} />
                    ) : (
                      <SelectFilter
                        masterData={internal_all_data}
                        data={rowApprovalFirstTwo}
                        defaultValue={aprrovalOne}
                        onChange={handleChangeApprovalOne}
                        disabled={
                          pr_detail?.status == 'need_confirm' ||
                          pr_detail?.status == 'rejected'
                            ? false
                            : true
                        }
                      />
                    )}
                  </Box>
                  <Box className={classes.sizeBox} />
                  <Box>
                    <Box className={classes.bodyContent}>
                      <ContentText
                        content={`Requestor/Manager Approval`}
                        styling={classes.bodyTitle}
                        variant={'body1'}
                      />
                    </Box>
                    {internal_all_IsLoading ? (
                      <Skeleton variant="text" width={'100%'} />
                    ) : (
                      <SelectFilter
                        masterData={internal_all_data}
                        data={rowApprovalFirstTwo}
                        defaultValue={aprrovalTwo}
                        onChange={handleChangeApprovalTwo}
                        disabled={
                          pr_detail?.status == 'need_confirm' ||
                          pr_detail?.status == 'rejected'
                            ? false
                            : true
                        }
                      />
                    )}
                  </Box>
                  <Box className={classes.sizeBox} />
                  <Box>
                    <Box className={classes.bodyContent}>
                      <ContentText
                        content={`Department Head Approval`}
                        styling={classes.bodyTitle}
                        variant={'body1'}
                      />
                    </Box>
                    {internal_all_IsLoading ? (
                      <Skeleton variant="text" width={'100%'} />
                    ) : (
                      <SelectFilter
                        masterData={internal_all_data}
                        data={rowApproval}
                        defaultValue={aprrovalThree}
                        onChange={handleChangeApprovalThree}
                        disabled={
                          pr_detail?.status == 'need_confirm' ||
                          pr_detail?.status == 'rejected'
                            ? false
                            : true
                        }
                      />
                    )}
                  </Box>
                  <Box className={classes.sizeBox} />
                  <Box>
                    <Box className={classes.bodyContent}>
                      <ContentText
                        content={`Director Approval`}
                        styling={classes.bodyTitle}
                        variant={'body1'}
                      />
                    </Box>
                    {internal_all_IsLoading ? (
                      <Skeleton variant="text" width={'100%'} />
                    ) : (
                      <SelectFilter
                        masterData={internal_all_data}
                        data={rowApproval}
                        defaultValue={aprrovalFour}
                        onChange={handleChangeApprovalFour}
                        disabled={
                          pr_detail?.status == 'need_confirm' ||
                          pr_detail?.status == 'rejected'
                            ? false
                            : true
                        }
                      />
                    )}
                  </Box>
                </Box>
                {pr_detail?.status === 'need_confirm' ? (
                  <Box
                    className={
                      assignment_prloading
                        ? classes.BtnConfirmFalse
                        : classes.BtnConfirm
                    }
                    onClick={
                      assignment_prloading ? null : handleConfirmAssigment
                    }
                  >
                    <ContentText
                      content={`Confirm Assignment`}
                      styling={classes.BtnConfirmText}
                      variant={'body1'}
                    />
                  </Box>
                ) : null}
              </Box>
            </Grid>
          </Grid>
          <Box className={classes.borderLine} />
          <Grid md={12}>
            {_renderButtonAction(pr_detail?.status)}
            {/* <ButtonSubmit
          onSubmit={() => setShowModalConfirmUpload(true)}
          setShowModalConfirm={setShowModalConfirm}
        /> */}
            {/* <ButtonQrCode
          showModal={() => setShowModalQRCode(true)}
          handleCanclePR={() => setShowModalConfirm(true)}
        /> */}
            {/* <ButtonCancel handleCanclePR={() => setShowModalConfirm(true)} /> */}
          </Grid>
          <HistoryTableDetail
            showHistory={isStatusNeed}
            data={pr_detail?.approval_transactions}
          />
        </>
      )}
    </>
  );
};

const useStyles = makeStyles({
  formTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingTop: 16,
    paddingBottom: 26,
    paddingLeft: 20,
    paddingRight: 20,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  descTitle: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingTop: 16,
    paddingBottom: 26,
    paddingLeft: 20,
    paddingRight: 20,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  borderLine: {
    borderBottom: '1px solid #E7E7E8',
  },
  formNumber: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#1B2030',
  },
  formStatus: {
    fontSize: 14,
    lineHeight: '26px',
    color: '#969BAB',
  },
  wrapperInformation: {
    paddingTop: 27,
    // paddingBottom: 79,
    backgroundColor: '#fff',
  },
  informationContent: {
    // marginTop: 27,
    paddingLeft: 20,
    paddingRight: 18.5,
    backgroundColor: '#fff',
    // marginBottom: 58,
    borderRight: '1px solid #E7E7E8',
  },
  informationHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  informationHeaderLink: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
  },
  headerText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  headerLink: {
    fontSize: 14,
    lineHeight: '26px',
    fontWeight: 'bold',
    color: '#127EEE',
    marginLeft: 10,
  },
  infromationBody: {
    marginTop: 18.5,
  },
  wrapperBody: {
    paddingRight: 10,
  },
  bodyTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
    marginBottom: 12,
  },
  bodyData: {
    width: '100%',
    backgroundColor: '#EBEFF0',
    padding: '12px 20px',
    border: '1px solid #E7E7E8',
    borderRadius: 8,
  },
  bodyDataText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
  },
  bodyDataTextBlue: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#127EEE',
    fontWeight: 'bold',
  },
  bodyDataTextActivity: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // maxWidth: 100,
  },
  wrapperBodyGrandTotal: {},
  infromationAttachment: {
    marginTop: 30,
  },
  AttachmentTitle: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    fontWeight: 'bold',
  },
  AttachmentNotif: {
    marginTop: 20,
    marginBottom: 20,
    padding: 10,
    borderRadius: 4,
    backgroundColor: '#FFE9E8',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  AttachmentMessage: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#F12B2C',
  },
  btnUploadContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  btnUploadText: {
    fontSize: 14,
    lineHeight: '26px',
    color: '#1B2030',
  },
  btnUpload: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: '#127EEE',
    paddingLeft: 19,
    paddingRight: 19,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 8,
    marginTop: 29,
  },
  btnUploadValue: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
    marginLeft: 13,
  },
  assignApprovalContent: {
    paddingTop: 26,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 26,
    backgroundColor: '#fff',
    // borderBottom: '1px solid #E7E7E8',
  },
  headerTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  filterActifityContent: {
    marginTop: 20,
  },
  // styling Form select
  formControl: {
    backgroundColor: '#F8FAFB',
    border: '1px solid #E7E7E8',
    paddingLeft: 8,
    paddingRight: 4,
    paddingTop: 5,
    paddingBottom: 5,
    // maxWidth: 220,
    width: '100%',
    // minWidth: 160,
    maxHeight: 50,
    borderRadius: 8,
    '&.MuiFormControl-root .MuiInputBase-root::before': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiInputBase-root::after': {
      borderBottom: 'none',
    },
    '&.MuiFormControl-root .MuiSvgIcon-root': {
      // marginTop: 6,
    },
    '&.MuiFormControl-root .MuiInputBase-root .MuiSelect-root': {
      fontSize: 16,
      lineHeight: '21px',
      color: '#969BAB',
      paddingRight: 2,
      backgroundColor: '#F8FAFB',
    },
  },
  sizeBox: {
    marginTop: 20,
  },
  btnOption: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  boxDepartment: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textTransform: 'ellipsis',
    maxWidth: 320,
    paddingRight: 20,
  },
  bodyDataSubText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    marginLeft: 4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  hideWhiteSpace: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 100,
  },
  // styling status
  statusNeed: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#F12B2C',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  statusWaiting: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#FFB800',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  statusClose: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    background: '#969BAB',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  statusApprove: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    background: '#27AE60',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
  statusReject: {
    fontSize: 12,
    lineHeight: '26px',
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#F12B2C',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 100,
    marginLeft: 10,
    textTransform: 'uppercase',
  },

  wrapperStatusForm: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  BtnConfirm: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 14.5,
    paddingBottom: 14.5,
    backgroundColor: '#1B2030',
    borderRadius: 8,
    maxWidth: 193,
    marginTop: 20,
    cursor: 'pointer',
  },
  BtnConfirmFalse: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 14.5,
    paddingBottom: 14.5,
    backgroundColor: '#969BAB',
    borderRadius: 8,
    maxWidth: 193,
    marginTop: 20,
    cursor: 'pointer',
  },
  BtnConfirmText: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#fff',
  },
  wrapperLink: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  wrapperLinkFile: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    width: 250,
  },
  bodyUrlText: {
    marginLeft: 10,
    fontSize: 16,
    lineHeight: '21px',
    textDecorationLine: 'underline',
    color: '#1B2030',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  textFileCount: {
    marginLeft: 2,
    fontSize: 16,
    lineHeight: '21px',
    textDecorationLine: 'underline',
    color: '#1B2030',
    width: 200,
  },
  sizeSubLink: {
    marginTop: 15,
  },
  sizeTitle: {
    marginTop: 21.5,
  },
  btnAddMoreUpload: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: 26,
    cursor: 'pointer',
  },
});

export default PrFormApproval;
