import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box } from '@material-ui/core';

const Detail = ({ dataModalDetail, title }) => {
  const classes = useStyles();
  return (
    <Box className={classes.wrapperBody}>
      <Grid container>
        <Grid xs={12} md={6}>
          <Box>
            <Typography
              className={classes.bodyTitle}
              variant="body1"
              component="p"
            >
              No.
            </Typography>
            <Typography
              className={classes.bodySubTitle}
              variant="body1"
              component="p"
            >
              {dataModalDetail?.no}
            </Typography>
          </Box>
          <Box className={classes.sizebox} />
          <Box>
            <Typography
              className={classes.bodyTitle}
              variant="body1"
              component="p"
            >
              Purpose of Request
            </Typography>
            <Typography
              className={classes.bodySubTitle}
              variant="body1"
              component="p"
            >
              {dataModalDetail?.name}
            </Typography>
          </Box>
          <Box className={classes.sizebox} />
          <Box>
            <Typography
              className={classes.bodyTitle}
              variant="body1"
              component="p"
            >
              Department
            </Typography>
            <Typography
              className={classes.bodySubTitle}
              variant="body1"
              component="p"
            >
              {`${dataModalDetail?.department?.code} - ${dataModalDetail?.department?.name}`}
            </Typography>
          </Box>
          <Box className={classes.sizebox} />
          <Box>
            <Typography
              className={classes.bodyTitle}
              variant="body1"
              component="p"
            >
              Entity
            </Typography>
            <Typography
              className={classes.bodySubTitle}
              variant="body1"
              component="p"
            >
              {`${dataModalDetail?.entity?.code} - ${dataModalDetail?.entity?.name}`}
            </Typography>
          </Box>
          <Box className={classes.sizebox} />
          <Box>
            <Typography
              className={classes.bodyTitle}
              variant="body1"
              component="p"
            >
              Activity
            </Typography>
            <Typography
              className={classes.bodySubTitle}
              variant="body1"
              component="p"
            >
              {dataModalDetail?.activity}
            </Typography>
          </Box>
          {title !== 'PR' ? (
            <>
              <Box className={classes.sizebox} />
              <Box>
                <Typography
                  className={classes.bodyTitle}
                  variant="body1"
                  component="p"
                >
                  Currency
                </Typography>
                <Typography
                  className={classes.bodySubTitle}
                  variant="body1"
                  component="p"
                >
                  {dataModalDetail?.currency}
                </Typography>
              </Box>
            </>
          ) : null}
          <Box className={classes.sizebox} />
          <Box>
            <Typography
              className={classes.bodyTitle}
              variant="body1"
              component="p"
            >
              Grand Total
            </Typography>
            <Typography
              className={classes.bodySubTitle}
              variant="body1"
              component="p"
            >
              {dataModalDetail?.total}
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} md={6} className={classes.wrapperRigthContent}>
          <Box>
            <Typography
              className={classes.bodyTitle}
              variant="body1"
              component="p"
            >
              Requestor
            </Typography>
            <Typography
              className={classes.bodySubTitle}
              variant="body1"
              component="p"
            >
              {dataModalDetail?.requestor}
            </Typography>
          </Box>
          <Box className={classes.sizebox} />
          <Box>
            <Typography
              className={classes.bodyTitle}
              variant="body1"
              component="p"
            >
              Email Address
            </Typography>
            <Typography
              className={classes.bodySubTitle}
              variant="body1"
              component="p"
            >
              {dataModalDetail?.email}
            </Typography>
          </Box>
          <Box className={classes.sizebox} />
          <Box>
            <Typography
              className={classes.bodyTitle}
              variant="body1"
              component="p"
            >
              Budget Type
            </Typography>
            <Typography
              className={classes.bodySubTitle}
              variant="body1"
              component="p"
            >
              {dataModalDetail?.budget_type}
            </Typography>
          </Box>
          <Box className={classes.sizebox} />
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapperBody: {
    paddingTop: 27,
    paddingLeft: 20,
    paddingRight: 20,
    // paddingBottom: 97
  },
  bodyTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#969BAB',
  },
  bodySubTitle: {
    marginTop: 10,
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
  },
  sizebox: {
    marginTop: 20,
  },
  wrapperRigthContent: {
    paddingLeft: 40,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      marginTop: 20,
    },
  },
}));

export default Detail;
