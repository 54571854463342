import React from 'react';
import { makeStyles, Typography, Box } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
// images
import Danger from '../../assets/Icons/Danger.png';
// Components
import { InputArea } from '../InputArea';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 20,
      paddingRight: 20,
      width: '100vw',
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    paddingTop: 50,
    paddingBottom: 26,
    borderRadius: 18,
    width: 467,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100vw',
    },
  },
  notifText: {
    marginTop: 32,
    fontSize: 36,
    lineHeight: '49px',
    color: '#000000',
    fontWeight: 'bold',
    marginBottom: 22,
  },
  textConfirm: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
  },
  textConfirmSecond: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
    paddingLeft: 15,
    paddingRight: 15,
    textAlign: 'center',
  },
  textConfirmBold: {
    marginLeft: 4,
    fontSize: 16,
    lineHeight: '21px',
    color: '#000000',
    fontWeight: 'bold',
  },
  borderLine: {
    marginTop: 53,
    width: '100%',
    border: '1px solid #E7E7E8',
  },
  btnContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 20,
    paddingTop: 17,
    paddingBottom: 14,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  btnSubmit: {
    width: 183,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1B2030',
    borderRadius: 8,
    cursor: 'pointer',
    marginLeft: 18,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      maxWidth: 150,
    },
  },
  btnSubmitFalse: {
    width: 183,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#969BAB',
    borderRadius: 8,
    marginLeft: 18,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      maxWidth: 150,
    },
  },
  btnSubmitText: {
    fontSize: 16,
    lineHeight: '27px',
    fontWeight: 'bold',
    color: '#fff',
  },
  btnCancel: {
    width: 183,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      maxWidth: 150,
    },
  },
  btnCancelText: {
    fontSize: 16,
    lineHeight: '27px',
    color: '#1B2030',
  },
  wrapperText: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15,
  },
  wrapperTextArea: {
    width: '100%',
    paddingTop: 27,
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

export default function ModalRejectMessage({
  open,
  close,
  onPress,
  isLoading,
  title,
  handleMessageInfo,
}) {
  const classes = useStyles();

  const [note, setTextNote] = React.useState('');

  const handleOnPressing = (note) => {
    onPress(note);
    setTextNote('');
  };

  React.useEffect(() => {
    if (note?.length >= 190) {
      handleMessageInfo(
        'error',
        true,
        'your text is have max limit 190 character'
      );
    }
  }, [note]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={close}
        closeAfterTransition
        BackdropComponent={Backdrop}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div>
              <img
                // className={classes.bellImage}
                src={Danger}
                alt="image not found"
              />
            </div>
            <Typography
              className={classes.notifText}
              variant="body1"
              component="p"
            >
              {`Reject ${title}?`}
            </Typography>
            <Box className={classes.wrapperText}>
              <Typography
                className={classes.textConfirm}
                variant="body1"
                component="p"
              >
                All selected item will be
              </Typography>
              <Typography
                className={classes.textConfirmBold}
                variant="body1"
                component="p"
              >
                {'rejected.'}
              </Typography>
            </Box>
            <Typography
              className={classes.textConfirmSecond}
              variant="body1"
              component="p"
            >
              Please write a note/reason to notify the requestor
            </Typography>
            <Box className={classes.wrapperTextArea}>
              <InputArea value={note} setTextNote={setTextNote} />
            </Box>
            <Typography
              className={classes.textConfirm}
              variant="body1"
              component="p"
            >
              {isLoading ? 'Sending data approval ....' : null}
            </Typography>
            <div className={classes.borderLine}></div>
            <div className={classes.btnContent}>
              <div className={classes.btnCancel} onClick={close}>
                <Typography
                  className={classes.btnCancelText}
                  variant="body1"
                  component="p"
                >
                  Cancel
                </Typography>
              </div>
              <div
                className={
                  isLoading ? classes.btnSubmitFalse : classes.btnSubmit
                }
                onClick={() => handleOnPressing(note)}
              >
                <Typography
                  className={classes.btnSubmitText}
                  variant="body1"
                  component="p"
                >
                  Yes
                </Typography>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
