// notif grpo
export const GRPO_NOTIF_LOADING = 'GRPO/GRPO_NOTIF_LOADING';
export const GRPO_NOTIF_MESSAGE = 'GRPO/GRPO_NOTIF_MESSAGE';
export const GRPO_NOTIF_DATA = 'GRPO/GRPO_NOTIF_DATA';
export const GRPO_NOTIF_DASHBOARD_DATA = 'GRPO/GRPO_NOTIF_DASHBOARD_DATA';

// get data grpo
export const GRPO_DATA_LOADING = 'GRPO/GRPO_DATA_LOADING';
export const GRPO_DATA_MESSAGE = 'GRPO/GRPO_DATA_MESSAGE';
export const GRPO_SET_DATA = 'GRPO/GRPO_SET_DATA';

// get po by id
export const GRPO_DETAIL_LOADING = 'GRPO/GRPO_DETAIL_LOADING';
export const GRPO_DETAIL_MESSAGE = 'GRPO/GRPO_DETAIL_MESSAGE';
export const GRPO_DETAIL_DATA = 'GRPO/GRPO_DETAIL_DATA';

// get PO SAP data
export const GRPO_SYNC_LOADING = 'GRPO/GRPO_SYNC_LOADING';
export const GRPO_SYNC_MESSAGE = 'GRPO/GRPO_SYNC_MESSAGE';
export const GRPO_SYNC_DATA = 'GRPO/GRPO_SYNC_DATA';

// delete PO
export const GRPO_DELETE_LOADING = 'GRPO/GRPO_DELETE_LOADING';
export const GRPO_DELETE_MESSAGE = 'GRPO/GRPO_DELETE_MESSAGE';

// publish PO
export const GRPO_PUBLISH_LOADING = 'GRPO/GRPO_PUBLISH_LOADING';
export const GRPO_PUBLISH_MESSAGE = 'GRPO/GRPO_PUBLISH_MESSAGE';

// Cancel PO
export const GRPO_CANCEL_LOADING = 'GRPO/GRPO_CANCEL_LOADING';
export const GRPO_CANCEL_MESSAGE = 'GRPO/GRPO_CANCEL_MESSAGE';

// confirm Assign
export const GRPO_ASSIGNMENT_LOADING = 'GRPO/GRPO_ASSIGNMENT_LOADING';
export const GRPO_ASSIGNMENT_MESSAGE = 'GRPO/GRPO_ASSIGNMENT_MESSAGE';
