import * as types from './types';

import request from '../../utils/request';

export const getSAPnotification = (token) => (dispatch) => {
  dispatch({ type: types.OP_NOTIF_LOADING, payload: true });

  request({
    method: 'get',
    url: 'outgoing-payment/notification',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get notif');
      dispatch({ type: types.OP_NOTIF_DATA, payload: res?.data?.draft });
      dispatch({
        type: types.OP_NOTIF_DASHBOARD_DATA,
        payload: res?.data?.draft,
      });
      dispatch({ type: types.OP_NOTIF_MESSAGE, payload: res?.message });
      dispatch({ type: types.OP_NOTIF_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get notif OP', err);
      dispatch({ type: types.OP_NOTIF_LOADING, payload: false });
    });
};

export const getOPdata = (token, params) => (dispatch) => {
  dispatch({ type: types.OP_DATA_LOADING, payload: true });

  request({
    method: 'get',
    url: `outgoing-payment`,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get OP data');
      // console.log('grpo', res?.data?.data);

      dispatch({ type: types.OP_SET_DATA, payload: res?.data });
      dispatch({ type: types.OP_DATA_MESSAGE, payload: res?.message });
      dispatch({ type: types.OP_DATA_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get data OP', err);
      dispatch({ type: types.OP_DATA_LOADING, payload: false });
    });
};

export const getOPDetail = (token, id) => (dispatch) => {
  dispatch({ type: types.OP_DETAIL_LOADING, payload: true });

  request({
    method: 'get',
    url: `outgoing-payment/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get op Detail ');
      // console.log(res);
      dispatch({ type: types.OP_DETAIL_DATA, payload: res?.data });
      dispatch({ type: types.OP_DETAIL_MESSAGE, payload: res?.message });
      dispatch({ type: types.OP_DETAIL_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get detail op', err);
      dispatch({ type: types.OP_DETAIL_LOADING, payload: false });
    });
};

export const getOPSAPdata = (token, params) => (dispatch) => {
  dispatch({ type: types.OP_SYNC_LOADING, payload: true });

  request({
    method: 'get',
    url: `outgoing-payment`,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('get SAP OP data');

      dispatch({ type: types.OP_SYNC_DATA, payload: res?.data });
      dispatch({ type: types.OP_NOTIF_MESSAGE, payload: res?.message });
      dispatch({ type: types.OP_SYNC_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get SAP data OP', err);
      dispatch({ type: types.OP_SYNC_LOADING, payload: false });
    });
};

export const publishOP = (token, data) => (dispatch) => {
  dispatch({ type: types.PUBLISH_OP_LOADING, payload: true });

  request({
    method: 'put',
    url: `outgoing-payment/publish`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('publish OP data');
      dispatch({ type: types.PUBLISH_OP_MESSAGE, payload: res?.message });
      dispatch({ type: types.PUBLISH_OP_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error publish OP ', err);
      dispatch({ type: types.PUBLISH_OP_MESSAGE, payload: err?.data?.message });
      dispatch({ type: types.PUBLISH_OP_LOADING, payload: false });
    });
};

export const setBlankPublish = () => (dispatch) => {
  dispatch({ type: types.PUBLISH_OP_MESSAGE, payload: '' });
};
export const confirmhOP = (token, id, data) => (dispatch) => {
  dispatch({ type: types.CONFIRM_OP_LOADING, payload: true });

  request({
    method: 'post',
    url: `outgoing-payment/${id}/confirm-payment`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('confirm OP data');
      dispatch({ type: types.CONFIRM_OP_MESSAGE, payload: res?.message });
      dispatch({ type: types.CONFIRM_OP_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error confirm OP ', err);
      dispatch({ type: types.CONFIRM_OP_MESSAGE, payload: err?.data?.message });
      dispatch({ type: types.CONFIRM_OP_LOADING, payload: false });
    });
};

export const setBlankConfirm = () => (dispatch) => {
  dispatch({ type: types.CONFIRM_OP_MESSAGE, payload: '' });
};

// delete op
export const deleteOP = (token, id) => (dispatch) => {
  dispatch({ type: types.DELETE_OP_LOADING, payload: true });

  request({
    method: 'delete',
    url: `outgoing-payment/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: types.DELETE_OP_MESSAGE, payload: res?.message });
      dispatch({ type: types.DELETE_OP_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error delete OP ', err);
      dispatch({ type: types.DELETE_OP_MESSAGE, payload: err?.data?.message });
      dispatch({ type: types.DELETE_OP_LOADING, payload: false });
    });
};

export const setBlankDelete = () => (dispatch) => {
  dispatch({ type: types.DELETE_OP_MESSAGE, payload: '' });
};
