// notif APINVOICE
export const APINVOICE_NOTIF_LOADING = 'APINVOICE/APINVOICE_NOTIF_LOADING';
export const APINVOICE_NOTIF_MESSAGE = 'APINVOICE/APINVOICE_NOTIF_MESSAGE';
export const APINVOICE_NOTIF_DRAFT = 'APINVOICE/APINVOICE_NOTIF_DRAFT';
export const APINVOICE_NOTIF_DATA = 'APINVOICE/APINVOICE_NOTIF_DATA';
export const APINVOICE_NOTIF_DASHBOARD_DATA =
  'APINVOICE/APINVOICE_NOTIF_DASHBOARD_DATA';

// get data APINVOICE
export const APINVOICE_DATA_LOADING = 'APINVOICE/APINVOICE_DATA_LOADING';
export const APINVOICE_DATA_MESSAGE = 'APINVOICE/APINVOICE_DATA_MESSAGE';
export const APINVOICE_SET_DATA = 'APINVOICE/APINVOICE_SET_DATA';

// get po by id
export const APINVOICE_DETAIL_LOADING = 'APINVOICE/APINVOICE_DETAIL_LOADING';
export const APINVOICE_DETAIL_MESSAGE = 'APINVOICE/APINVOICE_DETAIL_MESSAGE';
export const APINVOICE_DETAIL_DATA = 'APINVOICE/APINVOICE_DETAIL_DATA';

// get PO SAP data
export const APINVOICE_SYNC_LOADING = 'APINVOICE/APINVOICE_SYNC_LOADING';
export const APINVOICE_SYNC_MESSAGE = 'APINVOICE/APINVOICE_SYNC_MESSAGE';
export const APINVOICE_SYNC_DATA = 'APINVOICE/APINVOICE_SYNC_DATA';

// delete PO
export const APINVOICE_DELETE_LOADING = 'APINVOICE/APINVOICE_DELETE_LOADING';
export const APINVOICE_DELETE_MESSAGE = 'APINVOICE/APINVOICE_DELETE_MESSAGE';

// publish PO
export const APINVOICE_PUBLISH_LOADING = 'APINVOICE/APINVOICE_PUBLISH_LOADING';
export const APINVOICE_PUBLISH_MESSAGE = 'APINVOICE/APINVOICE_PUBLISH_MESSAGE';

// Cancel PO
export const APINVOICE_CANCEL_LOADING = 'APINVOICE/APINVOICE_CANCEL_LOADING';
export const APINVOICE_CANCEL_MESSAGE = 'APINVOICE/APINVOICE_CANCEL_MESSAGE';

// confirm Assign
export const APINVOICE_ASSIGNMENT_LOADING =
  'APINVOICE/APINVOICE_ASSIGNMENT_LOADING';
export const APINVOICE_ASSIGNMENT_MESSAGE =
  'APINVOICE/APINVOICE_ASSIGNMENT_MESSAGE';

// set Transaction Approval
export const APINVOICE_TRANSACTION_APPROVAL_LOADING =
  'APINVOICE/APINVOICE_TRANSACTION_APPROVAL_LOADING';
export const APINVOICE_TRANSACTION_APPROVAL_MESSAGE =
  'APINVOICE/APINVOICE_TRANSACTION_APPROVAL_MESSAGE';
