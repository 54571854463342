import React from 'react';
import { makeStyles, Grid, Typography, Box, Chip } from '@material-ui/core';

const Requestor = (props) => {
  const { data } = props;
  const classes = useStyles();
  
  return (
    <>
      <Grid md={12}>
        <Typography className={classes.titleText}>Requestor</Typography>
        <Grid md={12} container className={classes.wrapperRequestor}>
          <Grid md={4} item className={classes.wrapperName}>
            <Box className={classes.wrapperBody}>
              <Box className={classes.bodyContent}>
                <Typography
                  className={classes.bodyTitle}
                  variant="body1"
                  component="p"
                >
                  Name
                </Typography>
              </Box>
              <Box className={classes.bodyData}>
                <Typography
                  className={classes.bodyDataText}
                  variant="body1"
                  component="p"
                >
                  {data?.user?.name ?? '-'}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid md={4} item className={classes.wrapperDepartment}>
            <Box className={classes.wrapperBody}>
              <Box className={classes.bodyContent}>
                <Typography
                  className={classes.bodyTitle}
                  variant="body1"
                  component="p"
                >
                  Department
                </Typography>
              </Box>
              <Box className={classes.bodyData}>
                <Box className={classes.boxDepartment}>
                  <Typography
                    className={classes.bodyDataText}
                    variant="body1"
                    component="p"
                  >
                    {`${data?.user?.departments?.code || data?.user?.department?.code}` ?? '-'}
                  </Typography>
                  <Typography
                    className={classes.bodyDataSubText}
                    variant="body1"
                    component="p"
                  >
                    {`- ${data?.user?.departments?.name || data?.user?.department?.name}` ?? '-'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid md={4} item className={classes.wrapperEntity}>
            <Box className={classes.wrapperBody}>
              <Box className={classes.bodyContent}>
                <Typography
                  className={classes.bodyTitle}
                  variant="body1"
                  component="p"
                >
                  Entity
                </Typography>
              </Box>
              <Box className={classes.bodyMultiData}>
                <Typography
                  className={classes.bodyDataText}
                  variant="body1"
                  component="p"
                >
                  {data?.user?.entity?.map(w =>  <Chip style={{ marginRight: '5px' }} label={w.code} />) || '-'}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    fontWeight: 'bold',
  },
  bodyTitle: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 'bold',
    color: '#1B2030',
    marginBottom: 12,
  },
  bodyData: {
    width: '100%',
    backgroundColor: '#EBEFF0',
    padding: '12px 20px',
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    minHeight: 50,
  },
  bodyMultiData: {
    width: '100%',
    backgroundColor: '#EBEFF0',
    padding: '9px 20px',
    border: '1px solid #E7E7E8',
    borderRadius: 8,
    height: 50,
  },
  bodyDataText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
  },
  wrapperRequestor: {
    marginTop: 15,
  },
  wrapperName: {
    paddingRight: 10,
  },
  wrapperDepartment: {
    paddingRight: 10,
    paddingLeft: 10,
  },
  wrapperEntity: {
    paddingLeft: 10,
  },
  boxDepartment: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  bodyDataSubText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#969BAB',
    marginLeft: 4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export default Requestor;
