import * as types from './types';

import request from '../../utils/request';

export const getDashboardPR = (token, params) => (dispatch) => {
  dispatch({ type: types.DASHBOARD_LOADING, payload: true });

  request({
    method: 'get',
    url: 'dashboard/pr',
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      // console.log(res);
      dispatch({ type: types.PR_DASHBOARD_DATA, payload: res?.data });
      dispatch({ type: types.DASHBOARD_MESSAGE, payload: res?.message });
      dispatch({ type: types.DASHBOARD_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get dashboard PR', err);
      dispatch({ type: types.DASHBOARD_LOADING, payload: false });
    });
};

export const getDashboardPO = (token, params) => (dispatch) => {
  dispatch({ type: types.DASHBOARD_LOADING, payload: true });

  request({
    method: 'get',
    url: 'dashboard/po',
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: types.PO_DASHBOARD_DATA, payload: res?.data });
      dispatch({ type: types.DASHBOARD_MESSAGE, payload: res?.message });
      dispatch({ type: types.DASHBOARD_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get dashboard PO', err);
      dispatch({ type: types.DASHBOARD_LOADING, payload: false });
    });
};

export const getDashboardGRPO = (token, params) => (dispatch) => {
  dispatch({ type: types.DASHBOARD_LOADING, payload: true });

  request({
    method: 'get',
    url: 'dashboard/grpo',
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: types.GRPO_DASHBOARD_DATA, payload: res?.data });
      dispatch({ type: types.DASHBOARD_MESSAGE, payload: res?.message });
      dispatch({ type: types.DASHBOARD_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get dashboard GRPO', err);
      dispatch({ type: types.DASHBOARD_LOADING, payload: false });
    });
};

export const getDashboardINVOICe = (token, params) => (dispatch) => {
  dispatch({ type: types.DASHBOARD_LOADING, payload: true });

  request({
    method: 'get',
    url: 'dashboard/ap-invoice',
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: types.INVOICE_DASHBOARD_DATA, payload: res?.data });
      dispatch({ type: types.DASHBOARD_MESSAGE, payload: res?.message });
      dispatch({ type: types.DASHBOARD_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get dashboard invoice', err);
      dispatch({ type: types.DASHBOARD_LOADING, payload: false });
    });
};

export const getDashboardAPDP = (token, params) => (dispatch) => {
  dispatch({ type: types.DASHBOARD_LOADING, payload: true });

  request({
    method: 'get',
    url: 'dashboard/apdp',
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: types.APDP_DASHBOARD_DATA, payload: res?.data });
      dispatch({ type: types.DASHBOARD_MESSAGE, payload: res?.message });
      dispatch({ type: types.DASHBOARD_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get dashboard APDP', err);
      dispatch({ type: types.DASHBOARD_LOADING, payload: false });
    });
};

export const getDashboardOP = (token, params) => (dispatch) => {
  dispatch({ type: types.DASHBOARD_LOADING, payload: true });

  request({
    method: 'get',
    url: 'dashboard/op',
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch({ type: types.OP_DASHBOARD_DATA, payload: res?.data });
      dispatch({ type: types.DASHBOARD_MESSAGE, payload: res?.message });
      dispatch({ type: types.DASHBOARD_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error get dashboard OP', err);
      dispatch({ type: types.DASHBOARD_LOADING, payload: false });
    });
};
