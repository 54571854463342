import React from 'react';
import { makeStyles, Box } from '@material-ui/core';

// image
import Logo from '../../assets/Images/logo.png';

const LoadingScreen = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.containerPage}>
        <img src={Logo} alt="image not found" />
      </Box>
    </>
  );
};

const useStyles = makeStyles({
  containerPage: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F3F5F6',
  },
});

export default LoadingScreen;
