import React from 'react';
import { Grid, Box, makeStyles, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { SortingASC, SortingDESC } from '../../../../utils/SortFunc';
import SortArrow from '../../../SortArrow';

// Components
import { ContentText } from '../../../../libs/Typography';
import TableCustom from './TableCustom';
import { get } from 'lodash';

// generate fixed table
const StickyTableCell = withStyles((theme) => ({
  head: {
    right: 0,
    position: 'sticky',
    // zIndex: theme.zIndex.appBar + 2,
  },
  body: {
    backgroundColor: '#ddd',
    minWidth: '50px',
    right: 0,
    position: 'sticky',
    // zIndex: 99,
  },
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ApprovalHistory = ({ tempCategory, historyData, tempFileDKT }) => {
  const classes = useStyles();
  
  const cols = get(historyData, '[0].user', [])
  
  return (
    <Grid xs={12} md={12} className={classes.container}>
      <ContentText content={`Approval History`} styling={classes.headerTitle} />
      <Box className={classes.sizeBorder} />
      <Box className={classes.tableContent}>
        <TableContainer className={classes.tableContainer}>
          <Table aria-label="sticky table" className={classes.tableTag}>
            <TableHead>
              <TableRow>
                <StickyTableCell className={classes.head}>
                  <StyledTableCell
                    className={classes.head}
                    align="left"
                    numeric
                  >
                    Name
                  </StyledTableCell>
                </StickyTableCell>
                {cols?.map((item, index) => (
                  <StyledTableCell className={classes.head} key={index} numeric>
                    <Box className={classes.wrapperTableHeader}>
                      <div className={classes.nameGroup}>
                        <span>{item?.user?.name}</span>
                        <span style={{ fontWeight: 400 }}>{item?.user?.positions.name}</span>
                      </div>
                      <SortArrow
                      // pressUp={() =>
                      //   SortingASC(rowData, 'poId', setSortingData)
                      // }
                      // pressDown={() =>
                      //   SortingDESC(rowData, 'poId', setSortingData)
                      // }
                      />
                    </Box>
                  </StyledTableCell>
                ))}
                {/* {tempFileDKT?.map((item, index) => (
                  <StyledTableCell className={classes.head} key={index} numeric>
                    <Box className={classes.wrapperTableHeader}>
                      {item?.name}
                      <SortArrow
                      // pressUp={() =>
                      //   SortingASC(rowData, 'poId', setSortingData)
                      // }
                      // pressDown={() =>
                      //   SortingDESC(rowData, 'poId', setSortingData)
                      // }
                      />
                    </Box>
                  </StyledTableCell>
                ))} */}
              </TableRow>
            </TableHead>
            <TableBody>
              {historyData?.map((item, index) => (
                <TableCustom key={index} index={index} item={item} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  nameGroup: {
    display: 'grid',
    textAlign: 'center',
    lineHeight: 'normal',
  },
  tableTag: {
    width: "100%",
  },
  container: {
    background: '#fff',
    borderRadius: 8,
    marginTop: 20,
  },
  headerTitle: {
    padding: 20,
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 700,
    color: '#969BAB',
  },
  sizeBorder: {
    border: '1px solid #E7E7E8',
  },
  tableContent: {
    width: '100%',
  },
  // custom table
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  wrapperTableHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  wrapperTableHead: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  iconSort: {
    paddingTop: 15,
    width: 10,
    marginLeft: 5,
  },
  head: {
    backgroundColor: '#fff',
    fontSize: 16,
    lineHeight: '30px',
    fontWeight: 'bold',
    color: '#000000',
    borderBottom: 'none',
    minWidth: 220,
    '&:nth-child(1)': {
      minWidth: 270,
      borderBottom: 'none',
    },
    '&:nth-child(2)': {
      minWidth: 270,
    },
    '&:nth-child(3)': {
      minWidth: 250,
    },
    '&:nth-child(4)': {
      minWidth: 250,
    },
    '&:nth-child(5)': {
      minWidth: 250,
    },
    '&:nth-child(6)': {
      minWidth: 250,
    },
  },
  tableContainer: {
    // maxHeight: '400px',
    // zIndex: 80,
    Width: '100%',
    '&.MuiTableContainer-root .MuiTableRow-root .MuiTableCell-root.MuiTableCell-head':
      {
        paddingLeft: 8,
      },
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '3px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    },
  },
  cell: {
    minWidth: '100px',
  },
}));
export default ApprovalHistory;
