import * as types from './types';

// Just Example
export const dashboardReducers = (
  state = {
    isLoading: false,
    message: '',
    dashboard_pr: null,
    dashboard_po: null,
    dashboard_grpo: null,
    dashboard_invoice: null,
    dashboard_apdp: null,
    dashboard_op: null,
  },
  action
) => {
  switch (action.type) {
    // General
    case types.DASHBOARD_LOADING:
      return { ...state, isLoading: action.payload };

    case types.DASHBOARD_MESSAGE:
      return { ...state, message: action.payload };

    // get dahsboard PR
    case types.PR_DASHBOARD_DATA:
      return { ...state, dashboard_pr: action.payload };

    // get dahsboard PO
    case types.PO_DASHBOARD_DATA:
      return { ...state, dashboard_po: action.payload };

    // get dahsboard grpo
    case types.GRPO_DASHBOARD_DATA:
      return { ...state, dashboard_grpo: action.payload };

    // get dahsboard ap invoice
    case types.INVOICE_DASHBOARD_DATA:
      return { ...state, dashboard_invoice: action.payload };

    // get dahsboard apdp
    case types.APDP_DASHBOARD_DATA:
      return { ...state, dashboard_apdp: action.payload };

    // get dahsboard op
    case types.OP_DASHBOARD_DATA:
      return { ...state, dashboard_op: action.payload };

    default:
      return state;
  }
};
