import {
  LOADING,
  FETCHING,
  SET_PROFILE,
  AUTH_LOGIN,
  MESSAGE,
  LOGOUT,
  VERIFY_LOADING,
  VERIFY_MESSSAGE,
  LOGOUT_MESSAGE,
} from './types';
import request from '../../utils/request';
import AsyncStorage from '@callstack/async-storage';
// import axios from 'axios';

// axios.defaults.headers.post['Content-Type'] = 'application/json';
// axios.defaults.headers.post['Accept'] = 'application/json';

export const setMessage = () => (dispatch) => {
  dispatch({ type: MESSAGE, payload: '' });
};

export const setToken = (access_token) => (dispatch) => {
  dispatch({
    type: FETCHING,
    payload: access_token,
  });
};

export const login = (username, password, isEnabled) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });
  request({
    method: 'post',
    url: 'auth/login',
    data: {
      username,
      password,
      // token,
    },
  })
    .then(async (res) => {
      // console.log('----- login data ----------');
      // console.log(res);
      if (res.status === true) {
        if (isEnabled) {
          await AsyncStorage.setItem('token', res.data.accessToken);
          await AsyncStorage.setItem('profile', res.data.user);
          dispatch({
            type: FETCHING,
            payload: res.data.accessToken,
          });
        } else {
          dispatch({
            type: FETCHING,
            payload: res.data.accessToken,
          });
        }
        dispatch({ type: AUTH_LOGIN, payload: true });
      } else {
        dispatch({
          type: FETCHING,
          payload: '',
        });
        dispatch({
          type: MESSAGE,
          payload: res.message,
        });
      }
      dispatch({ type: LOADING, payload: false });
    })
    .catch((err) => {
      // console.log('----- error ----------');
      // console.log(err.data.message);
      dispatch({
        type: MESSAGE,
        payload: err.data.message,
      });
      dispatch({ type: LOADING, payload: false });
    });
};

export const getProfile = (token) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });

  request({
    method: 'get',
    url: 'users/get-profile',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      // console.log('----- get profile -------');
      dispatch({
        type: SET_PROFILE,
        payload: res.data,
      });
      dispatch({ type: AUTH_LOGIN, payload: true });
      dispatch({ type: LOADING, payload: false });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: false });
    });
};

export const logout = (token) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });

  request({
    method: 'post',
    url: 'auth/logout',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(async (res) => {
      // console.log(res);
      await AsyncStorage.removeItem('token');
      await AsyncStorage.removeItem('@token');
      await AsyncStorage.removeItem('@profile');
      await AsyncStorage.removeItem('profile');
      localStorage.removeItem('token');
      localStorage.removeItem('@token');
      localStorage.removeItem('profile');
      localStorage.removeItem('@profile');
      dispatch({ type: AUTH_LOGIN, payload: false });

      dispatch({
        type: LOGOUT,
      });
      dispatch({ type: LOGOUT_MESSAGE, payload: res?.message });
      dispatch({ type: LOADING, payload: false });
    })
    .catch((err) => {
      dispatch({
        type: LOGOUT,
      });
      dispatch({ type: LOADING, payload: false });
    });
};

export const setBlankLogout = () => (dispatch) => {
  dispatch({ type: LOGOUT_MESSAGE, payload: '' });
};

export const cekTokenProfile = (token) => (dispatch) => {
  dispatch({ type: LOADING, payload: true });

  request({
    method: 'get',
    url: 'users/get-profile',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log('----- cek token -------');
      dispatch({
        type: SET_PROFILE,
        payload: res.data,
      });
      dispatch({ type: FETCHING, payload: token });
      dispatch({ type: AUTH_LOGIN, payload: true });
      dispatch({ type: LOADING, payload: false });
    })
    .catch((err) => {
      dispatch({ type: LOADING, payload: false });
      dispatch({
        type: SET_PROFILE,
        payload: null,
      });
    });
};

// verify user
export const verifyUser = (data) => (dispatch) => {
  dispatch({ type: VERIFY_LOADING, payload: true });

  request({
    method: 'post',
    url: 'auth/verify',
    data,
  })
    .then((res) => {
      dispatch({ type: VERIFY_MESSSAGE, payload: res?.message });
      dispatch({ type: VERIFY_LOADING, payload: false });
    })
    .catch((err) => {
      console.log('error verify user', err);
      dispatch({ type: VERIFY_LOADING, payload: false });
    });
};

export const setBlankVerify = () => (dispatch) => {
  dispatch({ type: VERIFY_MESSSAGE, payload: '' });
};
