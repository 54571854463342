import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useRouter } from '../../utils/useRouter';
// icons
import MessageForgot from '../../assets/Icons/messageForgot.png';
import MessageReset from '../../assets/Icons/messageReset.png';

const MessageAuth = ({ reset, onResend }) => {
  const classes = useStyles();
  const router = useRouter();

  return (
    <Box className={classes.wrapperMessage}>
      <Box className={reset ? classes.imageContentReset : classes.imageContent}>
        <img
          className={classes.imageLogo}
          src={reset ? MessageReset : MessageForgot}
          alt="image not found"
        />
      </Box>
      <Typography
        className={reset ? classes.titleMessageReset : classes.titleMessage}
        variant="body1"
        component="p"
      >
        {reset ? 'Success' : 'Email has been sent!'}
      </Typography>
      <Typography
        className={classes.subTitleMessage}
        variant="body1"
        component="p"
      >
        {reset
          ? 'Your Password is already updated'
          : 'Please check your inbox and click in received link to reset password'}
      </Typography>
      <Typography
        className={classes.subTitleMessage}
        variant="body1"
        component="p"
      >
        {reset ? 'Please relogin with your new password' : null}
      </Typography>
      <Box className={reset ? classes.sizeBoxReset : classes.sizeBox} />
      <a className={classes.submitBtn} onClick={() => router.push('/login')}>
        {reset ? 'Login' : 'Ok'}
      </a>
      {reset ? null : (
        <Box className={classes.wrapperLink}>
          <Typography
            className={classes.checkText}
            variant="body1"
            component="p"
          >
            Didn't receive the link?
          </Typography>
          <a className={classes.linkText} onClick={onResend}>
            Resend
          </a>
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  wrapperMessage: {},
  imageLogo: {},
  imageContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 50,
  },
  imageContentReset: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 47.7,
  },
  titleMessage: {
    fontWeight: 'bold',
    fontSize: 21,
    lineHeight: '32px',
    color: '#1B2030',
    textAlign: 'center',
  },
  titleMessageReset: {
    fontWeight: 'bold',
    fontSize: 36,
    lineHeight: '49px',
    color: '#1B2030',
    textAlign: 'center',
  },
  subTitleMessage: {
    fontSize: 16,
    lineHeight: '30px',
    textAlign: 'center',
    color: '#1B2030',
    letterSpacing: '0.01em',
  },
  sizeBox: {
    marginTop: 53.5,
  },
  sizeBoxReset: {
    marginTop: 20,
  },
  submitBtn: {
    flex: 1,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1B2030',
    borderRadius: 8,
    color: '#ffff',
    fontSize: 16,
    lineHeight: '21px',
    textDecorationLine: 'none',
    fontWeight: 'bold',
  },
  checkText: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
  },
  wrapperLink: {
    marginTop: 38,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  linkText: {
    marginLeft: 10,
    fontSize: 16,
    lineHeight: '21px',
    color: '#1B2030',
    textDecorationLine: 'underline',
    fontWeight: 'bold',
  },
});

export default MessageAuth;
