import React from 'react';
import { makeStyles, Grid, Box, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useRouter } from '../../../../utils/useRouter';
import { useSelector, useDispatch } from 'react-redux';
import * as settingAction from '../../../../redux/Settings/actions';

// Components
import SortArrow from '../../../SortArrow';
import DepartmentData from './TableData/DepartmentData';
import CustomizedSnackbars from '../../../PopupMessage';
import { TabelLoading, TableNoData } from '../../../Loading/TableLoading';
import ModalDeleteItem from '../ModalDeleteItem';
import ModalSuccess from '../../../ModalSuccess';

const DepartmentListing = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispacth = useDispatch();

  // Global Variable
  const { access_token } = useSelector((state) => state.auth);
  const {
    department_isLoading,
    department_data,
    department_deleteIsLoading,
    department_deleteMessage,
  } = useSelector((state) => state.settings);

  // for initial state
  const [rowData, setRowData] = React.useState([]);
  const [tempDeleteId, setTempDeleteId] = React.useState(0);

  // state for modal
  const [showModalDelete, setShowModalDelete] = React.useState(false);
  const [showModalSuccess, setShowModalSuccess] = React.useState(false);

  // handle message
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState('');
  const [notifText, setNotifText] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };
  //  end handle Message

  // function register
  const handleDeleteItem = (id) => {
    setTempDeleteId(id);
    setShowModalDelete(true);
  };

  const handleActionSuccess = () => {
    setShowModalSuccess(false);
    setShowModalDelete(false);
    dispacth(settingAction.getDepartment(access_token));
  };

  const handleEditItem = (id) => {
    router.push(`/organize/department/${id}/edit`);
  };

  React.useEffect(() => {
    dispacth(settingAction.getDepartment(access_token));
  }, []);

  React.useEffect(() => {
    let tempArr = [];

    department_data?.departments?.map((dep, index) => {
      let tempData = {
        id: dep?.id,
        entity: dep?.entity?.code,
        code: dep?.code,
        name: dep?.name,
      };
      tempArr.push(tempData);
    });
    setRowData(tempArr);
  }, [department_data]);

  React.useEffect(() => {
    if (department_deleteMessage) {
      if (department_deleteMessage === 'Delete departments Success') {
        setShowModalSuccess(true);
      } else if (department_deleteMessage !== '') {
        setShow(true);
        setType('error');
        setNotifText(department_deleteMessage);
      }
      dispacth(settingAction.setBlankDeleteDepartment());
    }
  }, [department_deleteMessage]);

  return (
    <>
      <CustomizedSnackbars
        show={show}
        handleClose={handleClose}
        message={notifText}
        type={type}
      />
      <ModalDeleteItem
        open={showModalDelete}
        close={() => setShowModalDelete(false)}
        onPress={() =>
          dispacth(settingAction.deleteDepartment(access_token, tempDeleteId))
        }
      />
      <ModalSuccess open={showModalSuccess} onPress={handleActionSuccess} />
      <div style={{ width: '100%' }}>
        <Grid md={12} classes={classes.wrapperTable}>
          <TableContainer className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Box className={classes.wrapperTableHeader}>
                      <Typography className={classes.headeText}>ID</Typography>
                      <SortArrow />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className={classes.wrapperTableHeader}>
                      <Typography className={classes.headeText}>
                        Entity
                      </Typography>
                      <SortArrow />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className={classes.wrapperTableHeader}>
                      <Typography className={classes.headeText}>
                        Code
                      </Typography>
                      <SortArrow />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className={classes.wrapperTableHeader}>
                      <Typography className={classes.headeText}>
                        Department Name
                      </Typography>
                      <SortArrow />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className={classes.wrapperTableHeader}>
                      <Typography className={classes.headeText}>
                        Action
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {department_isLoading ? null : rowData.length > 0 ? (
                  rowData.map((row, index) => (
                    <DepartmentData
                      row={row}
                      index={index}
                      key={index}
                      handleDeleteItem={handleDeleteItem}
                      handleEditItem={handleEditItem}
                    />
                  ))
                ) : (
                  <TableNoData colspan={5} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {department_isLoading ? <TabelLoading /> : null}
        </Grid>
      </div>
    </>
  );
};

const useStyles = makeStyles({
  wrapperTableHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headeText: {
    fontSize: 16,
    lineHeight: '30px',
    fontWeight: 'bold',
    color: '#000000',
  },
  tableContainer: {
    '& .MuiTableRow-root.MuiTableRow-head': {
      backgroundColor: '#fff',
    },
    '& .MuiTableRow-head .MuiTableCell-head': {
      borderBottom: 'none',
    },
    '& .MuiTableRow-head .MuiTableCell-head:nth-child(1)': {
      width: 91,
    },
    '& .MuiTableRow-head .MuiTableCell-head:nth-child(2)': {
      width: 137,
    },
    '& .MuiTableRow-head .MuiTableCell-head:nth-child(3)': {
      width: 230,
    },
    '& .MuiTableRow-head .MuiTableCell-head:nth-child(5)': {
      width: 100,
      paddingLeft: 20,
      paddinRigth: 20,
    },
  },
});

const departmentFake = [
  {
    id: 1,
    entity: 'YDI',
    code: 'OPS',
    name: 'Operation',
  },
  {
    id: 2,
    entity: 'YDI',
    code: 'FIN',
    name: 'Finance',
  },
  {
    id: 1,
    entity: 'YDI',
    code: 'SAL',
    name: 'Sales and Distributor',
  },
  {
    id: 1,
    entity: 'YDI',
    code: 'HRGA',
    name: 'Human Resource and General Administratif',
  },
];

export default DepartmentListing;
