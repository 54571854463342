import React from 'react';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import { makeStyles } from '@material-ui/core';

const DatePicker = ({ placeholder, onDatePick }) => {
  const classes = useStyles();

  const onDateChange = (props) => {
    onDatePick({ start: props[0], end: props[1] });
  };

  return (
    <>
      <DateRangePicker
        format="YYYY-MM-DD"
        suffixIcon={null}
        onChange={onDateChange}
        placeholder={placeholder}
        className={classes.dateContent}
        dat
      />
    </>
  );
};

const useStyles = makeStyles({
  dateContent: {
    height: 40,
    width: '100%',
    // flex: 1,
    // color: 'red',
    '&.rs-picker-daterange .rs-btn.rs-btn-default.rs-picker-toggle': {
      backgroundColor: '#F8FAFB !important',
      height: 40,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      paddingRight: 10,
      paddingTop: 10,
      color: '#969BAB',
    },
    '&.rs-picker-daterange .rs-picker-toggle .rs-picker-toggle-value': {
      backgroundColor: '#F8FAFB !important',
      maxWidth: '98%',
      color: '#969BAB',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    '& .rs-calendar-table-cell-selected .rs-calendar-table-cell-content': {
      color: '#fff',
      backgroundColor: 'black',
    },
    '&.rs-picker-daterange .rs-picker-toggle .rs-picker-toggle-clean': {
      postion: 'absolute',
      right: 0,
      paddingTop: 3,
      paddingRight: 10,
      // display: 'none',
    },
    '&.rs-picker-daterange .rs-picker-toggle-caret': {
      display: 'none',
    },
  },
});

export default DatePicker;
