import * as types from './types';

// Just Example
export const prReducers = (
  state = {
    notifLoading: false,
    notifMesage: '',
    notfiData: null,
    pr_dashboardNotif: null,
    data_pr: null,
    isLoading: false,
    message: '',
    pr_detail: null,
    detail_message: '',
    detail_isLoading: false,
    pr_sap: null,
    pr_sapLoading: false,
    pr_sapMessage: '',
    del_prLoading: false,
    del_prMessage: '',
    publish_prLoading: false,
    publish_prMessage: '',
    cancel_prLoading: false,
    cancel_prMessage: '',
    assignment_prloading: false,
    assigment_prmessage: '',
  },
  action
) => {
  switch (action.type) {
    // notif PR
    case types.PR_NOTIF_LOADING:
      return { ...state, notifLoading: action.payload };

    case types.PR_NOTIF_MESSAGE:
      return { ...state, notifMesage: action.payload };

    case types.PR_NOTIF_DATA:
      return { ...state, notfiData: action.payload };

    case types.PR_NOTIF_DASHBOARD_DATA:
      return { ...state, pr_dashboardNotif: action.payload };

    // PR data
    case types.PR_SET_DATA:
      return { ...state, data_pr: action.payload };

    case types.PR_DATA_MESSAGE:
      return { ...state, message: action.payload };

    case types.PR_DATA_LOADING:
      return { ...state, isLoading: action.payload };

    // detail pr
    case types.PR_DETAIL_DATA:
      return { ...state, pr_detail: action.payload };

    case types.PR_DETAIL_MESSAGE:
      return { ...state, detail_message: action.payload };

    case types.PR_DETAIL_LOADING:
      return { ...state, detail_isLoading: action.payload };

    // Sync SAP pr
    case types.PR_SYNC_DATA:
      return { ...state, pr_sap: action.payload };

    case types.PR_SYNC_MESSAGE:
      return { ...state, pr_sapMessage: action.payload };

    case types.PR_SYNC_LOADING:
      return { ...state, pr_sapLoading: action.payload };

    // delete pr
    case types.PR_DELETE_LOADING:
      return { ...state, del_prLoading: action.payload };

    case types.PR_DELETE_MESSAGE:
      return { ...state, del_prMessage: action.payload };

    // publish pr
    case types.PR_PUBLISH_LOADING:
      return { ...state, publish_prLoading: action.payload };

    case types.PR_PUBLISH_MESSAGE:
      return { ...state, publish_prMessage: action.payload };

    // Cancel pr
    case types.PR_CANCEL_LOADING:
      return { ...state, cancel_prLoading: action.payload };

    case types.PR_CANCEL_MESSAGE:
      return { ...state, cancel_prMessage: action.payload };

    // Assignment PR
    case types.PR_ASSIGNMENT_LOADING:
      return { ...state, assignment_prloading: action.payload };

    case types.PR_ASSIGNMENT_MESSAGE:
      return { ...state, assigment_prmessage: action.payload };

    default:
      return state;
  }
};
